define('knowme-client/adapters/rest', ['exports', 'ember-data/adapters/rest', 'ember-data-url-templates', 'ember-inflector', 'knowme-client/config/environment'], function (exports, _rest, _emberDataUrlTemplates, _emberInflector, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { scheme, host, namespace, version } = _environment.default.APP.API;

  exports.default = _rest.default.extend(_emberDataUrlTemplates.default, {
    namespace: [namespace, version].join('/'),
    authorizer: 'authorizer:json-web-token',
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as,query*}',
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?query*,view_as,paginate}',
    coalesceFindRequests: true,
    pathForType: function (type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    },
    urlSegments: {
      scheme: scheme,
      host() {
        return host || document.location.host;
      },
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      }
    }
  });
});