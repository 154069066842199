define('knowme-client/models/contract-agreement', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend({
		contractVersion: (0, _relationships.belongsTo)('contract-version'),
		account: (0, _relationships.belongsTo)('account'),
		user: (0, _relationships.belongsTo)('user'),
		createdAt: (0, _attr.default)('date'),
		updatedAt: (0, _attr.default)('date')
	});
});