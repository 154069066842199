define('knowme-client/components/incentives/resource-form', ['exports', 'knowme-client/mixins/controllers/status'], function (exports, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_status.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'model.hasDirtyAttributes', function () {
      return this.get('model.validations.isInvalid') || !this.get('model.hasDirtyAttributes');
    }),
    onNameEnter: Ember.observer('model.name', function () {
      if (this.get('model.isNew')) {
        this.get('model').set('body', this.get('model.name'));
      }
    }),
    actions: {
      submit: function () {
        let controller = this;
        this.get('model').save().then(function () {
          this.get('router').transitionTo('account.settings.incentives.index');
        }.bind(this));
      }
    }
  });
});