define('knowme-client/routes/account/users', ['exports', 'lodash', 'knowme-client/mixins/routes/account-setup'], function (exports, _lodash, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    queryParams: {
      status: {
        refreshModel: false
      },
      query: {
        refreshModel: true
      }
    },
    beforeModel: function (transition) {
      return this._super(...arguments).then(function () {
        if (!this.get('features.usersSectionAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Access Unauthorized', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        }
      }.bind(this));
    },
    pagination: {},
    model: function (params) {
      let promise;
      promise = this.get('store').query('user', this.processParams(params));
      promise.then(result => {
        this.set('pagination.total', result.meta.pagination.totalCount);
        this.set('pagination.limit', result.meta.pagination.page.size);
        this.set('pagination.page', result.meta.pagination.page.number);
      });
      return promise;
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);
      return _lodash.default.pickBy(params, _lodash.default.identity);
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
    },
    actions: {
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      }
    }
  });
});