define('knowme-client/routes/invoices/index', ['exports', 'lodash', 'moment', 'knowme-client/mixins/routes/account-setup'], function (exports, _lodash, _moment, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    // features: service(),
    // flashMessages: service(),
    pagination: {},
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel: function (transition) {
      this._super(...arguments).then(function () {
        if (!this.get('session.currentUser.hasAdminRole')) {
          transition.abort();
          return this.transitionTo('dashboard');
        }
      }.bind(this));
    },
    model: function (params) {
      let route = this;
      let promise = this.get('store').query('sales-transaction', _lodash.default.defaults(params, { status: 'paid,sent' }));
      promise.then(response => {
        route.set('pagination.count', response.meta.pagination.totalPages);
        route.set('pagination.total', response.meta.pagination.totalCount);
        route.set('pagination.limit', response.meta.pagination.page.size);
        route.set('pagination.page', response.meta.pagination.page.number);
        route.set('firstInvoiceDate', response.meta.firstInvoiceDate);
      });
      return promise;
    },
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      if (Ember.isPresent(this.get('firstInvoiceDate'))) {
        controller.set('firstInvoiceDate', (0, _moment.default)(this.get('firstInvoiceDate')));
      } else {
        controller.set('firstInvoiceDate', null);
      }
      controller.set('total', this.get('pagination.total'));
      controller.set('count', this.get('pagination.count'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('current', this.get('pagination.page'));
    },
    actions: {
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });
});