define('knowme-client/serializers/contact-email-json', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      contactInfo: { serialize: false },
      surveys: { deserialize: false, serialize: 'records' }
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.relationships = hash.relationships || {};
      hash.relationships['surveys'] = hash.relationships['surveys'] || {};
      hash.relationships['surveys'].links = hash.relationships.links || {};
      hash.relationships['surveys'].links.related = Ember.getOwner(this).lookup('adapter:survey').buildURL('survey', null, null, 'findMany', { contact_email_id: hash.id, paginate: false });

      hash.relationships['incentives-contacts'] = hash.relationships['incentives-contacts'] || {};
      hash.relationships['incentives-contacts'].links = hash.relationships.links || {};
      hash.relationships['incentives-contacts'].links.related = Ember.getOwner(this).lookup('adapter:incentives-contact').buildURL('incentives-contact', null, null, 'findMany', { target_id: hash.id, target_type: 'ContactEmail', paginate: false });

      return this._super.apply(this, arguments);
    }
  });
});