define('knowme-client/helpers/query-params-with-defaults', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let QueryParams;

  exports.default = Ember.Helper.extend({
    init() {
      this._super(...arguments);
      QueryParams = Ember.Object.extend({
        container: Ember.getOwner(this),
        isQueryParams: true,
        values: null
      });
    },
    compute([defaults, updates], updatesHash) {
      // assert('The `query-params-with-defaults` helper only accepts an object as defaults value', typeof defaults === 'object');
      // assert('The `query-params-with-defaults` helper requires 2 params, e.g. (query-params-with-defaults a b)', isPresent(defaults) && isPresent(updates));
      if (defaults.includes('survey_status')) {
        // console.log(defaults);
        // console.log(updates);
        // console.log(updatesHash);
        // console.log("=================");
      }
      if (Ember.isArray(defaults)) {
        let _defaults = {};
        defaults.map(function (el) {
          if (Ember.typeOf(el) === 'object') {
            for (var key in el) {
              _defaults[key] = null;
            }
          } else {
            _defaults[el] = null;
          }
        });
        defaults = _defaults;
      }

      if (Ember.isBlank(updates)) {
        if (Ember.isPresent(updatesHash)) {
          updates = updatesHash;
        } else {
          updates = {};
        }
      } else {
        if (Ember.isPresent(updatesHash)) {
          updates = Ember.merge(updates, updatesHash);
        }
      }

      return QueryParams.create({
        values: Ember.merge(defaults, updates)
      });
    }
  });
});