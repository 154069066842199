define('knowme-client/routes/leads/show', ['exports', 'knowme-client/mixins/routes/account-setup', 'knowme-client/mixins/routes/contacts-subscriber'], function (exports, _accountSetup, _contactsSubscriber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // import CableSetup from '../../mixins/routes/cable-setup'

  let LeadsShowRoute;

  LeadsShowRoute = Ember.Route.extend(_accountSetup.default, _contactsSubscriber.default, /* CableSetup, */{
    // channelName: 'LeadChannel',
    model: function () {
      let params = this.paramsFor('leads.show');
      let lead = this.get('store').find('lead', params.id);
      return lead;
    },
    afterModel: function (model) {
      model.rollbackAttributes();
      let currentUser, usersPromise;
      currentUser = this.get('session.currentUser');
      this.set('subscriptionData', { id: currentUser.get('account.id') });
      usersPromise = currentUser.get('hasAdminRole') ? this.get('store').query('user', { paginate: false, status: 'active' }) : [currentUser];
      return Ember.RSVP.hash({
        incentives: this.get('store').query('incentive', { paginate: false }),
        products: this.get('store').query('product', { paginate: false, sort_by: 'name', order: 'asc', status: 'active' }),
        opportunityFields: this.get('store').query('opportunity-field', { enable: true }),
        salesActions: this.get('store').query('sales-action', { paginate: false, status: 'active' }),
        salesStages: this.get('store').query('sales-stage', { paginate: false, status: 'active' }),
        campaigns: this.get('store').query('campaign', { paginate: false, sort_by: 'name', order: 'asc' }),
        users: usersPromise,
        activities: model.get('opportunities.firstObject.activities')
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
      this.subscribeToContactsChannel(controller.get('model.id'));
      controller.prepareCustomAttributes();
    },
    resetController: function (controller) {
      this._super.apply(this, arguments);
      this.unsubscribeFromContactsChannel();
    },
    renderTemplate: function () /*controller, model*/{
      return this.render('leads/show', {
        into: 'application'
      });
    },
    actions: {
      willTransition: function () /*transition*/{
        let opportunity = this.controller.get('opportunity');
        if (Ember.isPresent(opportunity)) {
          opportunity.rollbackAttributes();
          opportunity.get('orders').filterBy('isNew', true).invoke('deleteRecord');
          opportunity.get('orders').filterBy('isNew', false).invoke('rollbackAttributes');
        }
      }
    }
  });

  exports.default = LeadsShowRoute;
});