define('knowme-client/serializers/product', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ProductSerializer;

  ProductSerializer = _application.default.extend({
    attrs: {
      photos: { embedded: 'always' }
    }
  });

  exports.default = ProductSerializer;
});