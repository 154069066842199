define('knowme-client/routes/leads/show/contacts/edit', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      let lead = this.modelFor('leads.show');
      this.set('lead', lead);

      let contact = this.get('store').find('contact', params.contact_id);
      return contact;
    },
    afterModel: function (model) {
      this.set('lead', model.get('lead'));
      return Ember.RSVP.hash({
        contactFields: this.get('store').query('contact-field', { enable: true })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('lead', this.get('lead'));
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      willTransition: function () /*transition*/{
        this.controller.get('model.customAttributes').filterBy('isNew', true).invoke('deleteRecord');
        this.controller.get('model.customAttributes').filterBy('isNew', false).invoke('rollbackAttributes');
        this.controller.get('model').rollbackAttributes();
        this.controller.get('model.contactInfos').forEach(function (ci) {
          ci.get('contactAddresses').forEach(function (record) {
            if (record.get('isNew')) {
              record.deleteRecord();
            } else {
              record.rollbackAttributes();
            }
          });
          ci.get('contactPhoneNumbers').forEach(function (record) {
            if (record.get('isNew')) {
              record.deleteRecord();
            } else {
              record.rollbackAttributes();
            }
          });
          ci.get('contactEmails').forEach(function (record) {
            if (record.get('isNew')) {
              record.deleteRecord();
            } else {
              record.rollbackAttributes();
            }
          });
          if (ci.get('isNew')) {
            ci.deleteRecord();
          } else {
            ci.rollbackAttributes();
          }
        });
      }
    }
  });
});