define('knowme-client/mirage/factories/contact-url', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		fullUrl: _faker.default.internet.url,
		socialNetwork: _faker.default.random.arrayElement([null, 'facebook', 'twitter', 'linkedin', 'google_plus']),
		createdAt: function () {
			return _faker.default.date.past().toISOString();
		},
		updatedAt: function () {
			return _faker.default.date.past().toISOString();
		}
	});
});