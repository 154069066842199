define('knowme-client/services/authorized-ajax', ['exports', 'ember-ajax/services/ajax', 'knowme-client/config/environment', 'ember-simple-auth-token/mixins/token-authorizer'], function (exports, _ajax, _environment, _tokenAuthorizer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { host } = _environment.default.APP.API;

  exports.default = _ajax.default.extend(_tokenAuthorizer.default, {
    session: Ember.inject.service(),
    trustedHosts: ['localhost'],
    headers: Ember.computed('session.currentUser', {
      get() {
        let headers = {};
        headers[this.get('authorizationHeaderName')] = this.get('session.data.authenticated.token');
        return headers;
      }
    })
  });
});