define('knowme-client/helpers/randomize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var randomize, RandomizeHelper;

  exports.randomize = randomize = function (option) {
    var result;
    switch (option[0]) {
      case 'days':
        result = Math.floor(Math.random() * 30 + 1);
        break;
      case 'plan':
        var plans = ['Basic', 'Internal', 'Free Trial', 'Custom Trial', 'Canceled'];
        var index = Math.floor(Math.random() * plans.length);
        result = plans[index];
        break;
      case 'promo_code':
        var code = "";
        var base = "ABCDEF0123456789";

        for (var i = 0; i < 8; i++) {
          code += base.charAt(Math.floor(Math.random() * base.length));
        }
        result = code;
        break;
    }
    return result;
  };

  RandomizeHelper = Ember.Helper.helper(randomize);

  exports.randomize = randomize;
  exports.default = RandomizeHelper;
});