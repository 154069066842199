define('knowme-client/mixins/models/ember-cp-validations-custom-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    setupAdditionalProperties: Ember.on('init', function () {
      if (!this._internalModel || !this._internalModel._record) {
        this.eachAttribute(function (attribute /*, attributeMeta*/) {
          this.get(`validations.attrs.${attribute}`); // This is called in order to generate the _validator
          let validators = this.get(`validations._validators.${attribute}`);
          let presenceValidator = Ember.isPresent(validators) ? validators.findBy('_type', 'presence') : null;
          let presenceValidatorOptions = Ember.isPresent(presenceValidator) ? presenceValidator.options : {};
          let presenceValidatorDependentKeys = Ember.getWithDefault(presenceValidatorOptions, 'dependentKeys', []);
          presenceValidatorDependentKeys = presenceValidatorDependentKeys.map(function (key) {
            if (key.match(/^model\.(.*)/)) {
              return key.split('.')[1];
            } else {
              return key;
            }
          });
          let isPresenceValidatedDependentKeys = [attribute].concat(presenceValidatorDependentKeys);
          Ember.defineProperty(this, `validations.attrs.${attribute}.isPresenceValidated`, Ember.computed(...isPresenceValidatedDependentKeys, function () {
            if (Ember.isPresent(presenceValidator)) {
              let options = presenceValidator.options;
              let disabled = Ember.getWithDefault(options, 'disabled', false);
              return !disabled;
            } else {
              return false;
            }
          }));
          Ember.defineProperty(this, `validations.attrs.${attribute}.isRequired`, Ember.computed(attribute, function () {
            return this.get(`validations.attrs.${attribute}.isPresenceValidated`) && Ember.isEmpty(this.get(attribute));
          }));
          Ember.defineProperty(this, `validations.attrs.${attribute}.feedback`, Ember.computed(`validations.attrs.${attribute}.isRequired`, `validations.attrs.${attribute}.isInvalid`, `validations.attrs.${attribute}.isValid`, function () {
            let _feedback;
            if (this.get(`validations.attrs.${attribute}.isRequired`)) {
              _feedback = 'required';
            } else if (!this.get(`validations.attrs.${attribute}.isPresenceValidated`)) {
              _feedback = 'optional';
            } else if (this.get(`validations.attrs.${attribute}.isInvalid`)) {
              _feedback = 'invalid';
            } else if (this.get(`validations.attrs.${attribute}.isValid`)) {
              _feedback = 'valid';
            }
            return _feedback;
          }));
        }.bind(this));
      }
    })
  });
});