define('knowme-client/models/chat-touch-point-member', ['exports', 'ember-data/relationships', 'ember-data/model'], function (exports, _relationships, _model) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend({
		user: (0, _relationships.belongsTo)('user', { async: true }),
		chatTouchPoint: (0, _relationships.belongsTo)('chat-touch-point', { async: true })
	});
});