define('knowme-client/routes/leads/show/opportunities/new', ['exports', 'moment', 'knowme-client/mixins/routes/account-setup'], function (exports, _moment, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowOpportunitiesNewRoute;

  LeadsShowOpportunitiesNewRoute = Ember.Route.extend(_accountSetup.default, {
    queryParams: {
      communication: {
        refreshModel: false
      }
    },
    session: Ember.inject.service('session'),
    model: function (params) {
      let lead, opportunity;
      let currentUser = this.get('session.currentUser');
      // retrieve lead
      lead = this.modelFor('leads.show');
      this.set('lead', lead);
      // build opportunity
      let attributes = {
        assignedTo: currentUser
      };
      if (lead.get('opportunities.length') == 0) {
        attributes.name = `Manual - ${(0, _moment.default)().format('L HH:mm:ss')}`;
      }
      opportunity = this.store.createRecord('opportunity', attributes);
      if (Ember.isPresent(params.communication) && ['outbound_call'].includes(params.communication)) {
        opportunity.set('source', params.communication);
      }
      return opportunity;
    },
    afterModel: function (model) {
      let currentUser, usersPromise;
      currentUser = this.get('session.currentUser');
      usersPromise = currentUser.get('hasAdminRole') ? this.get('store').query('user', { paginate: false, status: 'active' }) : [currentUser];

      return Ember.RSVP.hash({
        products: this.get('store').query('product', { paginate: false, sort_by: 'name', order: 'asc', status: 'active' }),
        opportunityFields: this.get('store').query('opportunity-field', { enable: true }),
        salesActions: this.get('store').query('sales-action', { paginate: false, status: 'active' }),
        salesStages: this.get('store').query('sales-stage', { paginate: false, status: 'active' }),
        campaigns: this.get('store').query('campaign', { paginate: false, sort_by: 'name', order: 'asc' }),
        users: usersPromise
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);

      controller.setProperties(this.get('controllerData'));
      controller.set('model.lead', this.get('lead'));
      controller.prepareCustomAttributes();
      controller.set('model.ad', this.get('session.currentUser.account.defaultManualAd'));
      let unqualified = controller.get('salesStages').findBy('key', 'unqualified');
      controller.set('model.salesStage', unqualified);
    },
    renderTemplate: function (controller, model) {
      return this.render('leads/show/opportunities/new', {
        into: 'application',
        model: model
      });
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        if (model.get('isNew')) {
          model.get('orders').filterBy('isNew', true).invoke('deleteRecord');
          model.deleteRecord();
        }
      }
    }
  });

  exports.default = LeadsShowOpportunitiesNewRoute;
});