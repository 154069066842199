define('knowme-client/helpers/truncate-last-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var truncateLastName, TruncateLastNameHelper;

  exports.truncateLastName = truncateLastName = function (params, hash) {
    if (params[0]) {
      let fullName = params[0].split(/\s+/);
      let firstName = fullName[0];
      let lastName = fullName[1];
      return `${firstName} ${lastName[0]}.`;
    } else {
      return null;
    }
  };

  TruncateLastNameHelper = Ember.Helper.helper(truncateLastName);

  exports.truncateLastName = truncateLastName;
  exports.default = TruncateLastNameHelper;
});