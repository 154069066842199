define('knowme-client/controllers/chats/show', ['exports', 'ember-group-by', 'knowme-client/config/environment', 'moment'], function (exports, _emberGroupBy, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    isRecordingEnabled: false,
    isSaving: false,
    activeStorage: Ember.inject.service(),
    unsavedAttachments: [],
    chatMessageAttachments: Ember.computed.mapBy('unsavedAttachments', 'signedId'),
    uploadProgress: 0,
    cableService: Ember.inject.service('cable'),
    chatsController: Ember.inject.controller('chats'),
    confirmRemoveMessageModal: false,
    confirmCloseChatModal: false,
    confirmRemoveAttachmentModal: false,
    messageToBeRemoved: null,
    chatToBeClosed: null,
    messageToBeEdited: null,
    messageToBeQuoted: null,
    recordedMessage: null,
    isRecording: false,
    isRecorded: false,
    sentMenuActions: [{ label: 'Edit', action: 'edit' }, { label: 'Copy', action: 'copy' }, { label: 'Quote', action: 'quote' }, {}, { label: 'Remove', action: 'remove' }],
    inboxMenuActions: [{ label: 'Copy', action: 'copy' }, { label: 'Quote', action: 'quote' }],
    voiceSentMenuActions: [{ label: 'Quote', action: 'quote' }, { label: 'Forward', action: 'forward' }, { label: 'Remove', action: 'remove' }],
    voiceInboxMenuActions: [{ label: 'Quote', action: 'quote' }, { label: 'Forward', action: 'forward' }],
    _interval: null,
    timer: 0,
    setupMessageConsumer() {
      const consumer = this.get('cableService').createConsumer(_environment.default.APP.chat_service.ws);
      let currentUser = this.get('session.currentUser');
      let controller = this;
      consumer.subscriptions.create('ChatMessagesChannel', {
        connected() {
          console.log('ChatMessagesChannel#connected');
          this.perform('follow', { user_id: currentUser.get('id'), room_id: controller.get('model.id') });
        },
        received(data) {
          console.log('ChatMessagesChannel#received');
          // later((function() {
          console.log(data);
          controller.updateMessageStore(data);
          // }), 1000);
        },
        disconnected() {
          console.log("ChatMessagesChannel#disconnected");
        }
      });
      // Set consumer in controller to link up computed props
      this.set('messageConsumer', consumer);
    },
    updateMessageStore(data) {
      let message = null;
      switch (data.action) {
        case 'create':
          console.log('create');
          this.get("store").pushPayload(data.modelName, data.payload);
          message = this.get("store").peekRecord('chat-message', data.payload.data.id);
          this.get('messangesArray').pushObject(message);
          // this.set('messages', this.get("store").peekAll('chat-message').filterBy('room.id', this.get('model.id')).filterBy('isDraft', false));

          let controller = this;
          Ember.run.later(function () {
            controller.get("store").pushPayload('users-chat-message', data.usersChatMessagePayload);
            let usersMessage = controller.get("store").peekAll('users-chat-message').find(function (ucm) {
              return ucm.get('user.id') === controller.get('session.currentUser.id') && ucm.get('message.id') === data.payload.data.id;
            });
            if (Ember.isPresent(usersMessage)) {
              usersMessage.set('readAt', (0, _moment.default)().toDate());
              usersMessage.save().then(function () {
                message.belongsTo('room').reload();
                // controller.get('model').update();
              });
            }
          }, 1000);
          break;
        case 'update':
          console.log('update');
          this.get("store").pushPayload(data.modelName, data.payload);
          break;
        case 'destroy':
          console.log('destroy');
          // this.get('messages').update();
          message = this.get("store").peekRecord('chat-message', data.payload.data.id);
          if (Ember.isPresent(message) && !message.get('isDeleted')) {
            this.get('messangesArray').removeObject(message);
            message.unloadRecord();
          }
          break;
      }
      this.refreshNanoScroller();
    },
    refreshNanoScroller() {
      Ember.run.schedule("afterRender", this, function () {
        Ember.run.later(function () {
          this.get('scroller').nanoScroller();
          this.get('scroller').nanoScroller({ scrollBottom: true });
        }.bind(this), 200);
      });
    },
    loadNewMessages: function () {
      this.get('store').query('chat-message', { room_id: this.get('model.id') }).then(function (messages) {
        this.set('messangesArray', messages.toArray());
        this.refreshNanoScroller();
      }.bind(this));
    },
    sorting: ['createdAt:asc'],
    sortedMessages: Ember.computed.sort('messangesArray', 'sorting'),
    messagesByDay: (0, _emberGroupBy.default)('sortedMessages', 'createdDay'),
    lastReadMessages: Ember.computed('model.users', 'session.currentUser', function () {
      let user = this.get('model.users').find(function (x) {
        return x.get('id') !== this.get('session.currentUser.id');
      }.bind(this));
      if (Ember.isPresent(user)) {
        return this.get('store').query('users-chat-message', {
          user_id: user.get('id'),
          room_id: this.get('model.id'),
          sort_by: 'created_at',
          order: 'desc',
          filter: 'read',
          limit: 1
        });
      } else {
        return [];
      }
    }),
    lastSeenAt: Ember.computed('lastReadMessages.length', function () {
      if (this.get('lastReadMessages.length') > 0) {
        return this.get('lastReadMessages.firstObject.readAt');
      }
      return;
    }),
    members: Ember.computed('model.members', function () {
      return this.get('model.members').join(', ');
    }),
    disabledSend: Ember.computed('isSaving', 'draftMessage.body', 'draftMessage.attachments.length', 'isRecordingEnabled', 'isVoiceRecorded', function () {
      return this.get('isSaving') || Ember.isBlank(this.get('draftMessage.body')) && this.get('draftMessage.attachments.length') === 0 && !(this.get('isRecordingEnabled') && this.get('isVoiceRecorded'));
    }),
    onBodyClear: Ember.observer('draftMessage.body', function () {
      if (Ember.isBlank(this.get('draftMessage.body'))) {
        this.set('messageToBeEdited', null);
      }
    }),
    actions: {
      hideConfirmRemoveModal: function () {
        this.set('messageToBeRemoved', null);
      },
      hideConfirmCloseModal: function () {
        this.set('chatToBeClosed', null);
      },
      closeContactChat: function () {
        if (this.get('confirmCloseChatModal')) {
          this.set('model.isClosed', true);
          this.get('model').save().then(function () {
            this.set('chatToBeClosed', null);
            this.set('confirmCloseChatModal', false);
            this.send('refreshModel');
          }.bind(this));
        } else {
          this.set('confirmCloseChatModal', true);
        }
      },
      remove: function (message) {
        if (this.get('confirmRemoveMessageModal')) {
          message.destroyRecord().then(function () {
            this.get('messangesArray').removeObject(message);
            // message.unloadRecord(); ????
            this.set('messageToBeRemoved', null);
            this.set('confirmRemoveMessageModal', false);
            this.refreshNanoScroller();
            this.send('refreshModel');
          }.bind(this));
        } else {
          this.set('confirmRemoveMessageModal', true);
          this.set('messageToBeRemoved', message);
        }
      },
      addUsersToRoom: function (e, room) {
        e.preventDefault();
        this.get('chatsController').send('addUsersToRoom', this.get('model'));
      },
      updateRoomTitle: function () {
        this.set('enableEdit', false);
        this.get('model').save();
      },
      saveDrafMessage: function () {
        let draftMessage = this.get('draftMessage');
        let needToSave = draftMessage.get('isNew') ? Ember.isPresent(draftMessage.get('body')) : draftMessage.get('hasDirtyAttributes');
        needToSave = needToSave || draftMessage.get('files.length') > 0;
        if (needToSave) {
          draftMessage.save().then(function (message) {
            draftMessage.set('files', []);
            message.belongsTo('room').reload();
          });
        }
      },
      sendMessage: function () {
        this.set('isSaving', true);
        if (this.get('isRecordingEnabled')) {
          this.set('isSendPressed', true);
        } else {
          this.set('chatsController.skipRoomPushing', true);
          this.set('draftMessage.sentAt', (0, _moment.default)().toDate());
          let message = this.get('draftMessage');
          if (Ember.isPresent(this.get('messageToBeEdited'))) {
            message = this.get('messageToBeEdited');
            message.set('body', this.get('draftMessage.body'));
          }
          message.save().then(function (message) {
            this.set('isSaving', false);
            this.set('isSendPressed', false);
            this.set('chatsController.skipRoomPushing', false);
            let draftMessage = this.get('store').createRecord('chat-message', { room: this.get('model'), files: [] });
            this.set('draftMessage', draftMessage);
            message.belongsTo('room').reload();
            this.loadNewMessages();
          }.bind(this));
        }
      },
      textAreaKeyDown: function (v, event) {
        if (event.keyCode === 13 && !event.shiftKey && !this.get('disabledSend')) {
          this.send('sendMessage');
        }
        if (event.keyCode === 27) {
          this.set('messageToBeEdited', null);
          this.set('messageToBeQuoted', null);
          this.set('body', null);
        }
      },
      upload: function (event) {
        const files = event.target.files;
        let url = Ember.getOwner(this).lookup('adapter:chat-attachment').buildURL('uploads');
        if (Ember.isPresent(files)) {
          for (var i = 0; i < files.length; i++) {
            Ember.get(this, 'activeStorage').upload(files.item(i), url, {
              onProgress: progress => {
                Ember.set(this, 'uploadProgress', progress);
              }
            }).then(function (blob) {
              Ember.get(this, 'unsavedAttachments').pushObject(blob);
              if (Ember.isBlank(this.get('draftMessage.files'))) {
                this.set('draftMessage.files', []);
              }
              this.get('draftMessage.files').pushObject(blob.get('signedId'));
              Ember.set(this, 'uploadProgress', 0);
              this.send('saveDrafMessage');
            }.bind(this));
          }
        }
      },
      destroyAttachment: function (attachment) {
        if (this.get('confirmRemoveAttachmentModal')) {
          let message = this.get('store').peekRecord('chat-message', attachment.get('message.id'));
          attachment.destroyRecord().then(function () {
            attachment.unloadRecord();
            if (message.get('attachments.length') == 0 && Ember.isBlank(message.get('body')) && !message.get('isDraft')) {
              message.unloadRecord();
            }
            if (message.get('isDraft')) {
              Ember.$('#message-attachment')[0].value = "";
            }
            this.set('attachmentToBeRemoved', null);
            this.set('confirmRemoveAttachmentModal', false);
          }.bind(this));
        } else {
          this.set('confirmRemoveAttachmentModal', true);
          this.set('attachmentToBeRemoved', attachment);
        }
      },
      removeAttachment: function (attachment) {
        this.get('unsavedAttachments').removeObject(attachment);
        this.get('draftMessage.files').removeObject(attachment.get('signedId'));
      },
      hideConfirmAttachmentRemoveModal: function () {
        this.set('attachmentToBeRemoved', null);
      },
      initRecord: function () {
        this.set('isRecordingEnabled', true);
      },
      cancelRecording: function () {
        this.set('isVoiceRecorded', false);
        this.set('isRecordingEnabled', false);
      },
      afterRecord: function (blob) {
        let url = Ember.getOwner(this).lookup('adapter:chat-attachment').buildURL('uploads');
        var audio = new File([blob], 'voice.wav', { type: blob.type });

        Ember.get(this, 'activeStorage').upload(audio, url, {
          onProgress: progress => {
            Ember.set(this, 'uploadProgress', progress);
          }
        }).then(blob => {
          this.set('uploadProgress', 0);
          this.set('draftMessage.files', [blob.get('signedId')]);
          // this.set('recordedMessage', blob.get('signedId'));
          this.set('isSendPressed', false);
          this.set('isRecordingEnabled', false);
          this.send('sendMessage');
        });
      }
    }
  });
});