define('knowme-client/controllers/emails', ['exports', 'knowme-client/mixins/controllers/components/date-filter', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/queryable', 'lodash'], function (exports, _dateFilter, _pagination, _queryable, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _queryable.default, _pagination.default, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    queryParams: ['type', 'contactId', 'opportunityId'],
    // this param is for opening email from lead page
    contactId: null,
    opportunityId: null,
    singleOpportunity: null,
    type: null,
    selectedEmail: null,
    replyEmail: null,
    confirmRemoveEmailModal: false,
    pageTitle: Ember.computed('type', function () {
      let title = 'Inbox and Sent Emails';
      switch (this.get('type')) {
        case 'draft':
          title = 'Draft Emails';
          break;
        case 'trash':
          title = 'Trash Emails';
          break;
      }
      return title;
    }),
    uniqueContactCount: Ember.computed('model', 'type', function () {
      if (Ember.isPresent(this.get('type'))) {
        return this.get('model').mapBy('contact').uniqBy('id').get('length');
      } else if (Ember.isPresent(this.get('contactId'))) {
        return 1;
      } else {
        return this.get('model.length');
      }
    }),
    modelMessagesTotals: Ember.computed('model.@each.emailsCount', 'type', function () {
      if (Ember.isPresent(this.get('type'))) {
        return [this.get('model.length')];
      } else {
        return this.get('model').mapBy('emailsCount');
      }
    }),
    modelMessagesTotal: Ember.computed.sum('modelMessagesTotals'),
    modelOpportunitiesTotals: Ember.computed('model.@each.opportunitiesCount', 'type', function () {
      if (Ember.isPresent(this.get('type'))) {
        return [this.get('model').mapBy('opportunity').uniqBy('id').get('length')];
      } else if (Ember.isPresent(this.get('opportunityId'))) {
        return [1];
      } else {
        return this.get('model').mapBy('opportunitiesCount');
      }
    }),
    modelOpportunitiesTotal: Ember.computed.sum('modelOpportunitiesTotals'),
    onSearchByName: Ember.observer('query', function () {
      let query = this.get('query');
      let queryParams = this.get('queryParams');
      if (Ember.isPresent(query)) {
        _lodash.default.each(queryParams, function (key) {
          if (key !== 'query') {
            this.set(key, null);
          }
        }.bind(this));
      }
    }),
    actions: {
      reply: function (all) {
        let selectedEmail = this.get("selectedEmail");
        let attributes = {};
        ['subject', 'lead', 'opportunity', 'contact', 'user'].forEach(function (key) {
          attributes[key] = selectedEmail.get(key);
        });
        if (all) {
          attributes.cc = selectedEmail.get("cc");
          attributes.bcc = selectedEmail.get("bcc");
        }
        attributes.knowmeCustomerId = this.get("session.currentUser").get('account').get('id');
        attributes.isDraft = true;

        if (selectedEmail.get("isInbox")) {
          attributes.to = selectedEmail.get("from");
          attributes.from = this.get("session.currentUser.email");
        } else {
          attributes.to = selectedEmail.get("to");
          attributes.from = selectedEmail.get("from");
        }
        let content = Ember.$('.email-details .body');
        attributes.body = "<div><br data-cke-filler='true'></div><div><br data-cke-filler='true'></div>";
        attributes.body += "<div>" + selectedEmail.get("sentAt") + ", " + selectedEmail.get('from') + " wrote:</div>";
        attributes.body += '<blockquote>' + content.html() + '</blockquote>';

        let newMessage = this.get('store').createRecord('message', attributes);
        newMessage.set('contentWidth', Ember.$('.knowme-editor .ck-content').width());
        newMessage.save().then((replyEmail => {
          // remove user's email from cc and bcc
          if (selectedEmail.get("isInbox")) {
            if (Ember.isPresent(replyEmail.get("cc"))) {
              let emails = _lodash.default.split(replyEmail.get("cc"), ",");
              _lodash.default.reject(emails, function (x) {
                return _lodash.default.includes(x, replyEmail.get("from"));
              });
              replyEmail.set('cc', emails.join(','));
            }
            if (Ember.isPresent(replyEmail.get("bcc"))) {
              let emails = _lodash.default.split(replyEmail.get("bcc"), ",");
              _lodash.default.reject(emails, function (x) {
                return _lodash.default.includes(x, replyEmail.get("from"));
              });
              replyEmail.set('bcc', emails.join(','));
            }
          }
          this.set('replyEmail', replyEmail);
        }).bind(this));
      },
      forward: function () {
        let selectedEmail = this.get("selectedEmail");
        let attributes = {};
        ['subject', 'lead', 'opportunity', 'contact', 'user'].forEach(function (key) {
          attributes[key] = selectedEmail.get(key);
        });
        let content = Ember.$('.email-details .body');
        attributes.knowmeCustomerId = this.get("session.currentUser").get('account').get('id');
        attributes.isDraft = true;
        attributes.originalMessageId = selectedEmail.get('id');

        attributes.body = '<div><br data-cke-filler="true"></div><div><br data-cke-filler="true"></div>';
        attributes.body += '<br/>---------- Forwarded message ---------';
        attributes.body += '<br/>From: ' + selectedEmail.get('from');
        attributes.body += '<br/>Date: ' + selectedEmail.get('sentAt');
        attributes.body += '<br/>Subject: ' + selectedEmail.get('subject');
        attributes.body += '<br/>To: ' + selectedEmail.get('to');
        attributes.body += '<blockquote>' + content.html() + '</blockquote>';

        let newMessage = this.get('store').createRecord('message', attributes);
        newMessage.set('contentWidth', Ember.$('.knowme-editor .ck-content').width());
        newMessage.save().then((replyEmail => {
          this.set('replyEmail', replyEmail);
        }).bind(this));
      },
      afterSend: function () {
        this.send('afterSendDraft');
      },
      toggleDetails: function (email) {
        if (email.get('isOwn')) {
          this.set('replyEmail', null);
          this.set('selectedEmail', email);
        }
      },
      junk: function (email) {
        if (email.get('isOwn')) {
          email.set('isJunk', !email.get('isJunk'));
          email.save().then(function () {
            this.send('refreshModel');
          }.bind(this));
        }
      },
      remove: function (email) {
        if (email.get('isOwn')) {
          if ((email.get('isJunk') || email.get('isDraft')) && !this.get('confirmRemoveEmailModal')) {
            this.set('confirmRemoveEmailModal', true);
            this.set('emailToBeRemoved', email);
          } else {
            email.destroyRecord().then(function () {
              this.get('store').unloadRecord(email);
              this.set('emailToBeRemoved', null);
              this.set('confirmRemoveEmailModal', false);
              this.send('refreshModel');
            }.bind(this));
          }
        }
      },
      refreshModelData: function () {
        this.send('refreshModel');
      },
      hideConfirmRemoveModal: function () {
        this.set('emailToBeRemoved', null);
      }
    }
  });
});