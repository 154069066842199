define('knowme-client/controllers/leads/show/contacts/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowContactsNewController;

  LeadsShowContactsNewController = Ember.Controller.extend({
    features: Ember.inject.service(),
    contactsController: Ember.inject.controller('leads.show.contacts')
  });

  exports.default = LeadsShowContactsNewController;
});