define('knowme-client/adapters/campaign', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let AdAdapter;

  AdAdapter = _application.default.extend({
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      paginate: function (type, id, snapshot, query) {
        if (query && Ember.isNone(query.paginate)) {
          return true;
        }
      }
    }
  });

  exports.default = AdAdapter;
});