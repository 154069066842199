define('knowme-client/services/recorder', ['exports', 'ember-cli-recorderjs/services/recorder'], function (exports, _recorder) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _recorder.default;
    }
  });
});