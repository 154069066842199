define('knowme-client/routes/support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let SupportRoute;

  SupportRoute = Ember.Route.extend();

  exports.default = SupportRoute;
});