define('knowme-client/serializers/incentives-contact', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      incentivesContact: { deserialize: 'id', serialize: 'id' },
      contact: { deserialize: 'id', serialize: 'id' },
      respondedContacts: { deserialize: 'id', serialize: 'id' },
      user: { deserialize: 'id', serialize: 'id' },
      opportunity: { deserialize: 'id', serialize: 'id' },
      incentivesContactTarget: { deserialize: 'id', serialize: 'id' }
    },
    serialize: function (snapshot /*, options*/) {
      let json = this._super.apply(this, arguments);
      json['target_type'] = snapshot.record.get('incentivesContactTargetType');
      json['target_id'] = snapshot.record.get('incentivesContactTarget.id');
      return json;
    }
  });
});