define('knowme-client/validators/numericality', ['exports', 'ember-validations/validators/local/numericality'], function (exports, _numericality) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _numericality.default.extend({
    init: function () {
      // this call is necessary, don't forget it!
      this._super();
      let options = [this.property];
      if (Ember.isPresent(this.options.alsoWatch)) {
        this.dependentValidationKeys.pushObject(this.options.alsoWatch);
        options.pushObject(this.options.alsoWatch);
      }
    }
  });
});