define('knowme-client/serializers/did', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalize(typeClass, hash /*, prop*/) {
      hash.links = hash.links || {};
      if (Ember.isPresent(hash.did_allocation_id)) {
        hash.links['didAllocation'] = Ember.getOwner(this).lookup('adapter:did-allocation').buildURL('didAllocation', hash.did_allocation_id, null, 'findRecord', null);
      }
      return this._super.apply(this, arguments);
    }
  });
});