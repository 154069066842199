define('knowme-client/mixins/credit-card-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cardTypes: [{ value: 'American Express', text: 'American Express' }, { value: 'Discover', text: 'Discover' }, { value: 'MasterCard', text: 'Mastercard' }, { value: 'Visa', text: 'Visa' }],
    months: [{ value: '01', text: 'January' }, { value: '02', text: 'February' }, { value: '03', text: 'March' }, { value: '04', text: 'April' }, { value: '05', text: 'May' }, { value: '06', text: 'June' }, { value: '07', text: 'July' }, { value: '08', text: 'August' }, { value: '09', text: 'September' }, { value: '10', text: 'October' }, { value: '11', text: 'November' }, { value: '12', text: 'December' }],
    years: [],
    init() {
      this._super(...arguments);
      if (this.get('years').length == 0) {
        let currentYear = new Date().getFullYear();
        for (let i = 0; i < 5; i++) {
          this.get('years').pushObject({
            value: currentYear + i, text: currentYear + i
          });
        }
      }
    }
  });
});