define('knowme-client/helpers/delivery-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.deliveryDate = undefined;


  var deliveryDate, DeliveryDateHelper;

  exports.deliveryDate = deliveryDate = function (params) {
    let appointmentDate = params[0];
    let timeBefore = params[1];

    if (Ember.isPresent(appointmentDate) && Ember.isPresent(timeBefore)) {
      let date = (0, _moment.default)(appointmentDate).startOf('minute').subtract(timeBefore, 'minutes');
      return date;
    } else {
      return '';
    }
  };

  DeliveryDateHelper = Ember.Helper.helper(deliveryDate);

  exports.deliveryDate = deliveryDate;
  exports.default = DeliveryDateHelper;
});