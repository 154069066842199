define('knowme-client/components/signature-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ckeditorConfig: {},
    authentication: null,
    store: Ember.inject.service(),
    disableSave: Ember.computed('model', 'model.isNew', 'model.hasDirtyAttributes', 'model.isDisabled', 'model.body', function () {
      if (this.get('model.isNew')) {
        return this.get('model.isDisabled') && Ember.isBlank(this.get('model.body'));
      } else {
        return !this.get('model.hasDirtyAttributes');
      }
    }),
    actions: {
      saveSignature: function (signature) {
        signature.set('contentWidth', Ember.$('.knowme-editor .ck-content').width());
        signature.save().then(function (signature) {
          this.set('model', signature);
        }.bind(this));
      }
    }
  });
});