define('knowme-client/components/link-to-opportunity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    className: '',
    opportunity: null,
    isExpanded: false
  });
});