define('knowme-client/helpers/get-mapped-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getName, getNameHelper;

  exports.getName = getName = function (params) {
    let objArray = params[0];
    let objId = params[1];
    let objName = params[2] || 'name';

    let name = objArray.find(x => x.id === objId)[objName];
    return name;
  };

  getNameHelper = Ember.Helper.helper(getName);

  exports.getName = getName;
  exports.default = getNameHelper;
});