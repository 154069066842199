define('knowme-client/serializers/room-json', ['exports', 'knowme-client/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    attrs: {
      roomsUsers: { serialize: 'records' }
    },
    keyForRelationship(key, relationship, method) {
      if (['roomsUsers'].includes(key) && method === 'serialize') {
        return `${Ember.String.underscore(key)}_attributes`;
      }
      return this._super(...arguments);
    },
    serialize(snapshot /*, options*/) {
      let json = this._super(...arguments);
      if (snapshot.record.get('readMessages') || snapshot.record.get('isTouched')) {
        delete json.rooms_users_attributes;
      }
      return json;
    }
  });
});