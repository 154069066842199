define('knowme-client/helpers/is-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isImage = isImage;
  function isImage(params /*, hash*/) {
    return Ember.isPresent(params[0].match(/(https?:\/\/[^\s]+.(?:jpg|gif|png))/g));
  }

  exports.default = Ember.Helper.helper(isImage);
});