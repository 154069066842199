define('knowme-client/routes/account/settings/incentives/new', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    queryParams: {
      from: {
        refreshModel: true
      }
    },
    incetiveAttributes: {},
    beforeModel: function (transition) {
      let incetiveAttributes = this.controllerFor('account.settings.incentives').get('incetiveAttributes');
      if (Ember.isPresent(transition.queryParams.from) && Ember.isPresent(incetiveAttributes)) {
        this.set('incetiveAttributes', incetiveAttributes);
      }
    },
    model: function () {
      return this.store.createRecord('incentive', this.get('incetiveAttributes'));
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        if (model.get('isNew')) {
          model.deleteRecord();
        }
      }
    }
  });
});