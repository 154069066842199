define('knowme-client/mixins/adapters/api-key-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    apiKey: Ember.inject.service(),
    headers: Ember.computed('apiKey.code', 'session.session.content.authenticated.token', function () {
      if (this.get('apiKey.code') || this.get('session.session.content.authenticated.token')) {
        return {
          'Authorization': this.get('apiKey.code') || this.get('session.session.content.authenticated.token')
        };
      }
    })
  });
});