define('knowme-client/controllers/leads/show/edit', ['exports', 'knowme-client/mixins/lead-form'], function (exports, _leadForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_leadForm.default, {
    flashMessages: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    leadsController: Ember.inject.controller('leads.index'),
    contactsController: Ember.inject.controller('leads.show.contacts'),
    deactivationConfirmed: false,
    isSaving: false,
    actions: {
      submit: function () {
        let controller = this;
        if (this.model.get('statusHasChanged') && this.model.get('status') === 'inactive' && !this.get('deactivationConfirmed')) {
          this.set('displayConfirmDeactivateModal', true);
          return;
        }
        this.set('deactivationConfirmed', false);
        let clearResource = function () {
          ['contactInfo', 'contactEmail', 'contactPhoneNumber', 'contactAddress', 'customAttribute'].forEach(function (modelName) {
            controller.get('store').peekAll(modelName).filter(function (_model) {
              return _model.get('isNew');
            }).invoke('deleteRecord');
          });
        };
        this.model.one('didUpdate', clearResource);
        this.set('isSaving', true);
        this.model.save().then(function (lead) {
          if (lead.get('organization.isNew') && Ember.isPresent(lead.get('organizationId'))) {
            let organization = controller.get('store').peekRecord('organization', lead.get('organizationId'));
            lead.set('organization', organization);
          }
          // lead.reload();
          lead.get('contactsContactInfos').forEach(function (contactInfo) {
            contactInfo.get('contactPhoneNumbers').reload();
          });
          controller.setProperties({ emailDirty: null, phoneDirty: null, addressDirty: null });
          controller.get('target._routerMicrolib').refresh();
          controller.transitionToRoute('leads.show.opportunities.show', lead.get('id'), lead.get('opportunities.firstObject.id'));
          controller.set('isSaving', false);
        }).catch(function (reason) {
          controller.set('isSaving', false);
          let message = '';
          if (reason.errors[0].source.pointer === 'data/attributes/contacts.contact_infos.contact_phone_numbers.ten_digit_number' || reason.errors[0].source.pointer === "data/attributes/ten_digit_number") {
            message = `Contact Phone Number ${reason.errors[0].detail} and can not be modified or deleted`;
          } else {
            message = reason.errors[0].detail;
          }
          this.get('flashMessages').warning(message, { destroyOnClick: true, timeout: 12000 });
          console.log("Errors", Ember.inspect(reason));
        }.bind(this));
      },
      confirmDeactivate: function () {
        this.set('deactivationConfirmed', true);
        this.send('submit');
      },
      closedModal: function () {
        this.set('displayConfirmDeactivateModal', false);
      }
    },
    lead: Ember.computed.alias('model'),
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.organization.hasDirtyAttributes', 'model.contacts.@each.hasDirtyAttributes', 'model.organization.customAttributes.@each.hasDirtyAttributes', 'model.organization.customAttributes.@each.value', 'model.primaryContact.hasDirtyAttributes', 'model.primaryContact.customAttributes.@each.hasDirtyAttributes', 'model.primaryContact.customAttributes.@each.value', 'model.contactsContactEmails.@each.contactInfoHasChanged', 'model.contactsPhoneNumbers.@each.contactInfoHasChanged', 'model.contactsAddresses.@each.contactInfoHasChanged', 'model.contactsContactInfos.@each.hasDirtyAttributes', 'model.contactsContactEmails.@each.hasDirtyAttributes', 'model.contactsContactPhoneNumbers.@each.hasDirtyAttributes', 'model.contactsContactAddresses.@each.hasDirtyAttributes', 'model.contactsContactAddresses.@each.isNotFilled', function () {
      let customAttributesHasChanged = false;
      if (this.get('session.currentUser.account.isbtob') && Ember.isPresent(this.get('model.organization.customAttributes'))) {
        this.get('model.organization.customAttributes').forEach(function (attr) {
          let res = attr.get('isNew') ? Ember.isPresent(attr.get('value')) : attr.get('hasDirtyAttributes');
          customAttributesHasChanged = customAttributesHasChanged || res;
        });
      }
      this.get('model.primaryContact.customAttributes').forEach(function (attr) {
        let res = attr.get('isNew') ? Ember.isPresent(attr.get('value')) : attr.get('hasDirtyAttributes');
        customAttributesHasChanged = customAttributesHasChanged || res;
      });

      let contactsContactInfosHasChanged = false;
      this.get('model.contactsContactInfos').forEach(function (info) {
        let res = false;
        if (info.get('isNew')) {
          if (Ember.isPresent(info.get('contactPhoneNumbers')) || Ember.isPresent(info.get('contactEmails'))) {
            res = true;
          } else {
            res = false;
            if (Ember.isPresent(info.get('contactAddresses'))) {
              info.get('contactAddresses').forEach(function (address) {
                res = res || (address.get('isNew') ? !address.get('isNotFilled') : address.get('hasDirtyAttributes'));
              });
            }
          }
        } else {
          res = info.get('hasDirtyAttributes');
        }

        contactsContactInfosHasChanged = contactsContactInfosHasChanged || res;
      });

      let contactsContactAddressesHasChanged = false;
      this.get('model.contactsContactAddresses').forEach(function (address) {
        let res = address.get('isNew') ? !address.get('isNotFilled') : address.get('hasDirtyAttributes');
        contactsContactAddressesHasChanged = contactsContactAddressesHasChanged || res;
      });

      return this.get('model.hasDirtyAttributes') || this.get('model.organization.hasDirtyAttributes') || customAttributesHasChanged || this.get('model.contacts').isAny('hasDirtyAttributes', true) || this.get('model.contactsContactEmails').isAny('contactInfoHasChanged', true) || this.get('model.contactsContactPhoneNumbers').isAny('contactInfoHasChanged', true) ||
      // this.get('model.contactsContactAddresses').isAny('contactInfoHasChanged', true) ||
      contactsContactInfosHasChanged || this.get('model.contactsContactEmails').isAny('hasDirtyAttributes', true) || this.get('model.contactsContactPhoneNumbers').isAny('hasDirtyAttributes', true) || contactsContactAddressesHasChanged;
    }),
    disableSubmit: Ember.computed('model.validations.isInvalid', 'isModelTreeDirty', 'isSaving', function () {
      return this.get('isSaving') || this.get('model.validations.isInvalid') || !this.get('isModelTreeDirty');
    })
  });
});