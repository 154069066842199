define('knowme-client/routes/reviews/new', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ReviewsRoute;

  ReviewsRoute = Ember.Route.extend({
    apiKey: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    account: null,
    beforeModel: function (transition) {
      this._super(...arguments);
      this.set('apiKey.code', transition.queryParams.code);
      let params = this.paramsFor('reviews.new');
      this.set('apiKey.code', transition.queryParams.code);
      let route = this;
      return this.get('store').find('email-touch-point', params.id).then(function (emailTouchPoint) {
        route.set('emailTouchPoint', emailTouchPoint);
        return emailTouchPoint.get('account');
      }).then(function (account) {
        route.set('account', account);
        return account;
      }).catch(function (reason) {
        route.transitionTo('login').then(function () {
          route.get('flashMessages').warning('The URL is invalid or has expired', { destroyOnClick: true, timeout: 5000 });
        });
      });
    },
    model: function (queryParams) {
      return this.get('store').createRecord('customer-experience');
    },
    afterModel: function (model /*, transition*/) {
      let lead = this.store.createRecord('lead');
      if (this.get('account.isbtob')) {
        lead.set('organization', this.store.createRecord('organization'));
      }
      let contact = this.store.createRecord('contact', { lead: lead, primary: true });
      let contactInfo = this.store.createRecord('contactInfo', { category: 'work', contact: contact });
      this.store.createRecord('opportunity', {
        lead: lead,
        ad: this.get('emailTouchPoint.ad'),
        name: `Manual - ${(0, _moment.default)().format('L HH:mm:ss')}`
      });
      this.store.createRecord('contactEmail', { contactInfo: contactInfo });
      this.set('controllerData', {
        contact: contact,
        lead: lead,
        account: this.get('account')
      });
      model.set('contact', contact);
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.set('apiKey.code', null);
      }
    }
  });

  exports.default = ReviewsRoute;
});