define('knowme-client/mixins/models/validations/chat-touch-point', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
    members: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
      min: 1
    })]

  });
});