define('knowme-client/mixins/controllers/components/date-filter-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // queryParams: {
    //   fromDate: 'from_date',
    //   toDate: 'to_date'
    // },
    queryParams: ['from_date', 'to_date'],
    from_date: null,
    to_date: null,
    actions: {
      updateDate(dateKey, value) {
        this.set(dateKey, value);
      }
    }
  });
});