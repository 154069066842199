define('knowme-client/adapters/ad', ['exports', 'knowme-client/adapters/application', 'knowme-client/mixins/adapters/api-key-header'], function (exports, _application, _apiKeyHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let AdAdapter;

  AdAdapter = _application.default.extend(_apiKeyHeader.default, {
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,paginate,campaign_id}',
    pathForType: function () /*type*/{
      return 'ads';
    },
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      campaign_id: function (type, id, snapshot, query = {}) {
        return query.campaign_id;
      },
      paginate: function (type, id, snapshot, query) {
        if (query && Ember.isNone(query.paginate)) {
          return true;
        }
      }
    }
  });

  exports.default = AdAdapter;
});