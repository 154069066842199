define("knowme-client/mixins/us-states", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    usStates: [{ id: "AK", name: "Alaska" }, { id: "AL", name: "Alabama" }, { id: "AR", name: "Arkansas" }, { id: "AZ", name: "Arizona" }, { id: "CA", name: "California" }, { id: "CO", name: "Colorado" }, { id: "CT", name: "Connecticut" }, { id: "DC", name: "District of Columbia" }, { id: "DE", name: "Delaware" }, { id: "FL", name: "Florida" }, { id: "GA", name: "Georgia" }, { id: "HI", name: "Hawaii" }, { id: "IA", name: "Iowa" }, { id: "ID", name: "Idaho" }, { id: "IL", name: "Illinois" }, { id: "IN", name: "Indiana" }, { id: "KS", name: "Kansas" }, { id: "KY", name: "Kentucky" }, { id: "LA", name: "Louisiana" }, { id: "MA", name: "Massachusetts" }, { id: "MD", name: "Maryland" }, { id: "ME", name: "Maine" }, { id: "MI", name: "Michigan" }, { id: "MN", name: "Minnesota" }, { id: "MO", name: "Missouri" }, { id: "MS", name: "Mississippi" }, { id: "MT", name: "Montana" }, { id: "NC", name: "North Carolina" }, { id: "ND", name: "North Dakota" }, { id: "NE", name: "Nebraska" }, { id: "NH", name: "New Hampshire" }, { id: "NJ", name: "New Jersey" }, { id: "NM", name: "New Mexico" }, { id: "NV", name: "Nevada" }, { id: "NY", name: "New York" }, { id: "OH", name: "Ohio" }, { id: "OK", name: "Oklahoma" }, { id: "OR", name: "Oregon" }, { id: "PA", name: "Pennsylvania" }, { id: "RI", name: "Rhode Island" }, { id: "SC", name: "South Carolina" }, { id: "SD", name: "South Dakota" }, { id: "TN", name: "Tennessee" }, { id: "TX", name: "Texas" }, { id: "UT", name: "Utah" }, { id: "VA", name: "Virginia" }, { id: "VT", name: "Vermont" }, { id: "WA", name: "Washington" }, { id: "WI", name: "Wisconsin" }, { id: "WV", name: "West Virginia" }, { id: "WY", name: "Wyoming" }],
    canadianProvinces: [{ id: 'ON', name: 'Ontario' }, { id: 'QC', name: 'Quebec' }, { id: 'BC', name: 'British Columbia' }, { id: 'AB', name: 'Alberta' }, { id: 'MB', name: 'Manitoba' }, { id: 'SK', name: 'Saskatchewan' }, { id: 'NS', name: 'Nova Scotia' }, { id: 'NB', name: 'New Brunswick' }, { id: 'NL', name: 'Newfoundland and Labrador' }, { id: 'PE', name: 'Prince Edward Island' }, { id: 'NT', name: 'Northwest Territories' }, { id: 'NU', name: 'Nunavut' }, { id: 'YT', name: 'Yukon' }],
    countries: [{ id: 'us', name: 'United States of America' }, { id: 'ca', name: 'Canada' }]
  });
});