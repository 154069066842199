define('knowme-client/mixins/routes/unauthenticated-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    beforeModel: function (transition) {
      this._super(...arguments);
      this.get('session').prohibitAuthentication('dashboard');
    }
  });
});