define('knowme-client/routes/email-templates', ['exports', 'lodash', 'knowme-client/mixins/routes/account-setup'], function (exports, _lodash, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    email: Ember.inject.service('email-connect'),
    pagination: {},
    session: Ember.inject.service(),
    beforeModel: function (transition) {
      let self = this;
      //TODO: refactor
      return this._super.apply(this, arguments).then(function () {
        if (!this.get('features.unifiedCommunicationsAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Emails are not included in your plan', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        } else {
          return this.get('session.currentUser').then(function (user) {
            return user.reload();
          }).then(function (user) {
            if (!self.get('features.emailIntegrationHasBeenCompleted')) {
              return self.transitionTo('profile');
            }
            return self.get('store').find('role', user.get('roles.firstObject.id'));
          }).then(function (role) {
            return self.get('email').syncRole(role).then(function (res) {
              return res;
            }).catch(data => {
              if (data.status === 401) {
                return self.transitionTo('profile');
              }
            });
          });
        }
      }.bind(this));
    },
    model: function (params) {
      let route = this;
      let user = this.get('session.currentUser');
      let promise = route.get('store').query('template', _lodash.default.merge(params, { account_id: user.get('account.id') }));
      promise.then(response => {
        route.set('pagination.count', response.meta.pagination.totalPages);
        route.set('pagination.total', response.meta.pagination.totalCount);
        route.set('pagination.limit', response.meta.pagination.limit);
        route.set('pagination.page', response.meta.pagination.currentPage);
      }).catch(data => {
        if (data.status === 401) {
          return route.transitionTo('profile');
        }
      });
      return promise;
    },
    setupController: function (controller /*, emails*/) {
      this._super.apply(this, arguments);

      controller.set('total', this.get('pagination.total'));
      controller.set('count', this.get('pagination.count'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('current', this.get('pagination.page'));
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      queryParamsDidChange: function () {
        this.refresh();
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      },
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      reloadModel: function () {
        this.refresh();
      }
    }
  });
});