define('knowme-client/mirage/factories/ad', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.afterCreate = undefined;
  exports.default = _emberCliMirage.Factory.extend({
    name: _faker.default.company.catchPhrase,
    channel: function () {
      return _faker.default.random.arrayElement(['direct_mail', 'email', 'general', 'mobile', 'online_digital', 'print', 'radio', 'sem', 'social_media', 'telemarketing', 'television']);
    },
    touchPoint: function () {
      return _faker.default.random.arrayElement(['toll_free_number', 'local_number']);
    },
    revenue: function () {
      return _faker.default.datatype.number({ min: 0, max: 2000 });
    },
    startDate: function () {
      return _faker.default.random.arrayElement([_faker.default.date.recent().toISOString(), _faker.default.date.future().toISOString()]);
    },
    endDate: function () {
      return _faker.default.random.arrayElement([_faker.default.date.recent().toISOString(), _faker.default.date.future().toISOString()]);
    },
    cost: function () {
      return _faker.default.datatype.number({ min: 0, max: 2000 });
    },
    leadsCount: function () {
      return _faker.default.datatype.number({ min: 0, max: 10 });
    },
    customersCount: function () {
      return _faker.default.datatype.number({ min: 0, max: 10 });
    },
    costPerLead: function () {
      return _faker.default.datatype.number({ min: 0, max: 200 });
    },
    pipeline: function () {
      return _faker.default.datatype.number({ min: 0, max: 10000 });
    },
    roi: function () {
      return _faker.default.datatype.number({ min: 0, max: 20 });
    },
    campaign_id: function () {
      return this.campaign.id;
    },
    status: function () {
      return 'active';
    }, // faker.random.arrayElement(['active', 'inactive']); },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    }
  });


  let afterCreate = function (ad, options) {
    let server = options.factory.server;
    let campaign = _faker.default.random.arrayElement(server.schema.campaign.all());
    ad.campaignId = campaign.id || 1;
  };

  exports.afterCreate = afterCreate;
});