define('knowme-client/mixins/models/validations/account-setting', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    value: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      dependentKeys: ['model.key', 'model.account'],
      disabled: Ember.computed('model.key', 'model.account.isNew', function () {
        return this.get('model.key') != 'tax_rate' || this.get('model.account.isNew');
      })
    })]
  });
});