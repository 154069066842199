define('knowme-client/serializers/organization-field', ['exports', 'ember-inflector', 'knowme-client/serializers/json-api'], function (exports, _emberInflector, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {},
    normalize(typeClass, hash /*, prop*/) {
      return this._super.apply(this, arguments);
    }
  });
});