define('knowme-client/serializers/chat-message-json', ['exports', 'knowme-client/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    attrs: {
      owner: { deserialize: 'id', serialize: false },
      attachments: { embedded: 'always' }
    }
  });
});