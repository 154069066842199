define('knowme-client/adapters/sales-transaction', ['exports', 'knowme-client/adapters/application', 'knowme-client/mixins/adapters/api-key-header'], function (exports, _application, _apiKeyHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_apiKeyHeader.default, {
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,paginate,contact_id,opportunity_id}',
    urlSegments: {
      paginate: function (type, id, snapshot, query) {
        return !Ember.isArray(id) && query && !Ember.isNone(query.paginate);
      }
    }
  });
});