define('knowme-client/routes/products/show', ['exports', 'knowme-client/mixins/routes/unauthenticated-mixin', 'knowme-client/mixins/products'], function (exports, _unauthenticatedMixin, _products) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  let ProductsRoute;

  ProductsRoute = Ember.Route.extend(_unauthenticatedMixin.default, _products.default, {
    model: function () {
      return this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc' });
    },
    afterModel: function (plans) {
      plans.forEach(function (plan) {
        let parts = plan.get('key').split('.');
        let productKey = parts[0];
        let planKey = parts[1];

        let product = this.get('products').find(function (product) {
          return product.key === productKey;
        });
        if (Ember.isPresent(product) && Ember.isPresent(planKey)) {
          // hide knowme iq basic
          if (productKey === 'knowme_iq' && planKey === 'basic') {
            delete product.settings[planKey];
            return;
          }
          delete product.settings[planKey].annually;
          delete product.settings[planKey].monthly;

          product.enablePromoCode = plan.get('enablePromoCode');

          Ember.assign(product.settings[planKey], plan.get('settings'));
          Ember.assign(product.settings[planKey].monthly, product.settings[planKey]);
          Ember.assign(product.settings[planKey].annually, product.settings[planKey]);
        }
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      let params = this.paramsFor('products.show');
      if (Ember.isPresent(params.product_slug)) {
        let currentProduct = this.get('products').findBy('slug', params.product_slug);
        if (Ember.isPresent(currentProduct)) {
          controller.set('hideProductsBoxes', true);
          controller.send('openSettings', currentProduct);
        }
      }
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.set('currentProduct', null);
        controller.set('hideProductsBoxes', false);
      }
    }
  });

  exports.default = ProductsRoute;
});