define('knowme-client/components/short-messages/table-body/contact-name-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    classNames: ['contact-name'],
    classNameBindings: ['paddingLeft:p-l-lg'],
    paddingLeft: Ember.computed.not('displayContactToogleLink'),
    name: Ember.computed.alias('contact.fullNameOrPhoneNumber'),
    isSelectedContact: Ember.computed('contact.id', 'selectedContact.id', function () {
      return this.get('contact.id') === this.get('selectedContact.id');
    }),
    displayContactToogleLink: true,
    contactIdQueryParamValue: Ember.computed('selectedContact.id', function () {
      let value = Ember.isPresent(this.get('selectedContact.id')) && this.get('selectedContact.opportunitiesCount') > 1 ? this.get('selectedContact.id') : null;
      return value;
    })
  });
});