define('knowme-client/routes/emails', ['exports', 'lodash', 'moment', 'knowme-client/mixins/routes/account-setup', 'knowme-client/mixins/routes/cable-setup'], function (exports, _lodash, _moment, _accountSetup, _cableSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    channelName: 'CommunicationChannel',
    features: Ember.inject.service(),
    email: Ember.inject.service('email-connect'),
    pagination: {},
    session: Ember.inject.service(),
    label: null,
    queryParams: {
      type: {
        refreshModel: true
      },
      contactId: {
        refreshModel: true,
        as: 'contact_id'
      },
      opportunityId: {
        refreshModel: true,
        as: 'opportunity_id'
      },
      singleOpportunity: {
        as: 'single_opportunity'
      }
    },
    beforeModel: function (transition) {
      let self = this;
      return this._super.apply(this, arguments).then(function () {
        if (!this.get('features.unifiedCommunicationsAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Emails are not included in your plan', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        } else {
          return this.get('session.currentUser').then(function (user) {
            return user.reload();
          }).then(function (user) {
            if (!self.get('features.emailIntegrationHasBeenCompleted')) {
              return self.transitionTo('profile');
            }
            return self.get('store').find('role', user.get('roles.firstObject.id'));
          }).then(function (role) {
            return self.get('email').syncRole(role).then(function (res) {
              return res;
            }).catch(data => {
              if (data.status === 401) {
                return self.transitionTo('profile');
              }
            });
          });
        }
      }.bind(this));
    },
    model: function (params) {
      let self = this;
      let user = this.get('session.currentUser');
      this.set('subscriptionData', { id: user.get('id') });
      let emailParams = {
        knowme_customer_id: user.get('account').get('id'),
        contact_id: params.contactId,
        opportunity_id: params.opportunityId
      };
      if (params.type) {
        emailParams.type = params.type;
      }
      if (Ember.isPresent(params.page)) {
        emailParams.page = params.page;
        emailParams.next_page_token = self.get('pagination.next_page_token');
      }
      if (Ember.isPresent(params.from_date)) {
        emailParams.from = (0, _moment.default)(params.from_date, 'MM-DD-YYYY').format('DD-MM-YYYY');
      }
      if (Ember.isPresent(params.to_date)) {
        emailParams.to = (0, _moment.default)(params.to_date, 'MM-DD-YYYY').format('DD-MM-YYYY');
      }
      if (Ember.isPresent(params.query)) {
        emailParams.query = params.query;
        return self.get('store').query('contact', { query: params.query }).then(contacts => {
          return _lodash.default.map(contacts.toArray(), c => {
            return c.id;
          });
        }).then(contact_ids => {
          emailParams.contact_ids = contact_ids;
          return self.fetchEmails(emailParams);
        });
      } else {
        return self.fetchEmails(emailParams);
      }
    },
    fetchEmails: function (params) {
      let self = this;

      let promise = self.get('store').query('message', params);
      promise.then(response => {
        self.set('pagination.count', response.meta.pagination.totalPages);
        self.set('pagination.total', response.meta.pagination.totalCount);
        self.set('pagination.limit', response.meta.pagination.limit);
        self.set('pagination.page', response.meta.pagination.currentPage);
      }).catch(data => {
        if (data.status === 401) {
          return self.transitionTo('profile');
        }
      });
      return promise;
    },
    afterReceived: function (controller, payload /*, action*/) {
      if (payload.data.attributes.resource_type === 'Email' && Ember.isBlank(controller.get('type'))) {
        let params = this.messagesParams(controller);
        // check if contact id and opp the same
        this.model(params).then(function (model) {
          controller.set('model', model);
        });
      }
    },
    messagesParams: function (controller) {
      let params = {};
      _lodash.default.each(controller.get('queryParams'), function (key) {
        params[key] = controller.get(key);
      });
      return params;
    },
    setupController: function (controller /*, emails*/) {
      this._super.apply(this, arguments);

      controller.set('showAllMessages', false);
      if (localStorage['emailId']) {
        let text = controller.get('model').findBy('id', localStorage['emailId']);
        localStorage.removeItem('emailId');
        controller.set('selectedEmail', text);
      }
      controller.set('total', this.get('pagination.total'));
      controller.set('count', this.get('pagination.count'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('current', this.get('pagination.page'));
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    resetController: function (controller /*, isExiting, transition*/) {
      this._super.apply(this, arguments);
      controller.set('selectedEmail', null);
    },
    actions: {
      queryParamsDidChange: function () {
        this.refresh();
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      afterSendDraft: function () {
        if (this.get('controller').get('type') === 'draft') {
          this.refresh();
        } else {
          this.get('controller').set('replyEmail', null);
          this.get('controller').set('selectedEmail', null);
        }
      },
      refreshModel: function () {
        this.refresh();
      }
    }
  });
});