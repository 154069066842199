define('knowme-client/routes/customer-experience', ['exports', 'lodash', 'moment', 'knowme-client/mixins/routes/account-setup', 'knowme-client/mixins/routes/cable-setup'], function (exports, _lodash, _moment, _accountSetup, _cableSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    channelName: 'CustomerExperienceChannel',
    pagination: {},
    params: {},
    beforeModel: function (transition) {
      this._super(...arguments);

      return this.get('session.currentUser').then(function (user) {
        this.set('currentUser', user);
        return user.get('account');
      }.bind(this)).then(function (account) {
        return this.set('subscriptionData', { id: account.get('id') });
      }.bind(this));
    },
    model: function (params) {
      // let route = this;
      let customerExperiencesParams = this.processParams(params);
      this.set('params', customerExperiencesParams);
      let promise = this.get('store').query('contact', this.processParams(params));

      promise.then(result => {
        this.set('pagination.total', result.meta.pagination.total);
        this.set('pagination.limit', result.meta.pagination.limit);
        this.set('pagination.page', result.meta.pagination.page);
        if (params.contact_id) {
          this.set('selectedResource', result.get('firstObject'));
        } else {
          this.set('selectedResource', null);
        }
      });
      return promise;
    },
    afterModel: function (model, transition) {
      let params = Ember.merge(this.processParams(transition.queryParams), { filter: ['with_customer_experiences'], paginate: false, status: 'active', sort_by: 'name', order: 'asc' });
      params.customer_experiences.user_id = null;
      return Ember.RSVP.hash({
        users: this.get('store').query('user', params)
      }).then(function (results) {
        this.set('controllerData', results);
        this.set('controllerData.params', this.processParams(transition.queryParams));
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      controller.set('isLoaded', true);
      controller.set('selectedResource', this.get('selectedResource'));
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this._super(...arguments);
      if (isExiting) {
        controller.set('isLoaded', false);
      }
    },
    afterReceived: function (controller, payload, action) {
      let params = {};
      _lodash.default.each(controller.get('queryParams'), function (key) {
        params[key] = controller.get(key);
      });
      this.model(params).then(function (model) {
        controller.set('model', model);
      });

      let cx = this.get('store').peekRecord('customer-experience', payload.data.id);
      cx.belongsTo('opportunity').reload();
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);

      if (Ember.isPresent(params.contact_id)) {
        params.ids = [params.contact_id];
        delete params.contact_id;
      }
      if (Ember.isPresent(params.filter) || Ember.isPresent(params.satisfaction) || Ember.isPresent(params.survey_status) || Ember.isPresent(params.from_date) && Ember.isPresent(params.to_date) || Ember.isPresent(params.ad_id) || Ember.isPresent(params.user_id) || Ember.isPresent(params.query)) {
        params.customer_experiences = {};
      }
      if (Ember.isPresent(params.satisfaction)) {
        if (params.satisfaction !== 'prompt') {
          params.customer_experiences.satisfaction = params.satisfaction;
        }
        delete params.satisfaction;
      } else {
        params.customer_experiences.satisfaction = 'scored';
      }
      if (Ember.isPresent(params.survey_status)) {
        if (params.survey_status !== 'prompt') {
          params.customer_experiences.survey_status = params.survey_status;
        }
        delete params.survey_status;
      }
      if (Ember.isPresent(params.user_id)) {
        params.customer_experiences.user_id = params.user_id;
        delete params.user_id;
      }
      if (Ember.isPresent(params.lead_id)) {
        params.customer_experiences.lead_id = params.lead_id;
        delete params.lead_id;
      }

      params.filter = 'with_customer_experiences';

      params = _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });

      if (Ember.isPresent(params.from_date) && Ember.isPresent(params.to_date)) {
        params.customer_experiences.from_date = params.from_date;
        params.customer_experiences.to_date = params.to_date;
        delete params.from_date;
        delete params.to_date;
      }

      return params;
    },
    actions: {
      willTransition: function () /*transition*/{
        this.render('loading');
      },
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });
});