define('knowme-client/routes/knowme-iq-product', ['exports', 'knowme-client/mixins/products'], function (exports, _products) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  let KnowmeIQProductRoute;

  KnowmeIQProductRoute = Ember.Route.extend(_products.default, {
    session: Ember.inject.service(),
    model: function () {
      return this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc', key: ['knowme_iq.starter', 'knowme_iq.premium', 'knowme_iq.starter_unlimited', 'knowme_iq.premium_unlimited'] });
    },
    afterModel: function (plans) {
      plans.forEach(function (plan) {
        let parts = plan.get('key').split('.');
        let productKey = parts[0];
        let planKey = parts[1];

        let product = this.get('products').find(function (product) {
          return product.key === productKey;
        });
        if (Ember.isPresent(product) && Ember.isPresent(planKey) && Ember.isPresent(product.settings[planKey])) {
          delete product.settings[planKey].annually;
          delete product.settings[planKey].monthly;

          Ember.set(product, 'enablePromoCode', plan.get('enablePromoCode'));

          Ember.assign(product.settings[planKey], plan.get('settings'));
        }
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      let params = this.paramsFor('products.show');
      controller.initSettings();

      if (Ember.isPresent(params.product_slug)) {
        let currentProduct = this.get('products').findBy('slug', params.product_slug);
        if (Ember.isPresent(currentProduct)) {
          controller.set('hideProductsBoxes', true);
          controller.send('openSettings', currentProduct);
        }
      }
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.set('currentProduct', null);
        controller.set('hideProductsBoxes', false);

        if (!controller.get('transitioningToSignUpForm')) {
          controller.get('accountSettings').forEach(function (key) {
            var _key = key.camelize().capitalize();
            controller.get('settings' + _key).rollbackAttributes();
          }.bind(this));
        }
      }
    }
  });

  exports.default = KnowmeIQProductRoute;
});