define('knowme-client/services/email-connect', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    contentType: 'application/json; charset=utf-8',
    session: Ember.inject.service(),
    init() {},

    isAuthorized(provider) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.ajax(_environment.default.APP.email_service.scheme + '://' + _environment.default.APP.email_service.host + '/' + _environment.default.APP.email_service.namespace + '/authentications/is_authorized', "GET", {
        data: { provider: provider }
      });
    },
    syncRole(role) {
      let data = {
        authentication: {
          user_role: role.get('key')
        }
      };
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.ajax(_environment.default.APP.email_service.scheme + '://' + _environment.default.APP.email_service.host + '/' + _environment.default.APP.email_service.namespace + '/authentications/sync_role', "post", {
        data: data
      });
    },
    disableAuthentication(provider) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.ajax(_environment.default.APP.email_service.scheme + '://' + _environment.default.APP.email_service.host + '/' + _environment.default.APP.email_service.namespace + '/authentications/' + provider, "DELETE");
    },
    list(params) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.ajax(_environment.default.APP.email_service.scheme + '://' + _environment.default.APP.email_service.host + '/' + _environment.default.APP.email_service.namespace + '/gmail/messages', "GET", {
        data: params
      });
    },
    createDraft(data, user) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      data.knowme_customer_id = user.get('account').get('id');
      data.is_draft = true;
      let body = {
        user_id: user.get('id'),
        user_email: user.get('email'),
        message: data
      };
      return adapter.ajax(_environment.default.APP.email_service.scheme + '://' + _environment.default.APP.email_service.host + '/' + _environment.default.APP.email_service.namespace + '/gmail/messages', "post", {
        data: body
      });
    },
    create(data, user) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      data.knowme_customer_id = user.get('account').get('id');
      let body = {
        user_id: user.get('id'),
        user_email: user.get('email'),
        message: data
      };
      return adapter.ajax(_environment.default.APP.email_service.scheme + '://' + _environment.default.APP.email_service.host + '/' + _environment.default.APP.email_service.namespace + '/gmail/messages', "post", {
        data: body
      });
    },
    send(data, user) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      data.knowme_customer_id = user.get('account').get('id');
      let body = {
        user_id: user.get('id'),
        user_email: user.get('email'),
        message: data
      };
      return adapter.ajax(_environment.default.APP.email_service.scheme + '://' + _environment.default.APP.email_service.host + '/' + _environment.default.APP.email_service.namespace + '/gmail/messages/' + data.id + '/send', "post", {
        data: body
      });
    },
    removeAttachment(messageId, id) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let url = `${_environment.default.APP.email_service.scheme}://${_environment.default.APP.email_service.host}/${_environment.default.APP.email_service.namespace}/gmail/messages/${messageId}/attachments/${id}`;
      return adapter.ajax(url, "delete");
    },
    junk(id, isJunk, user) {
      let body = {
        user_id: user.get('id'),
        user_email: user.get('email'),
        message: { is_junk: isJunk }
      };
      return this.update(id, body);
    },
    update(id, params) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.ajax(`${_environment.default.APP.email_service.scheme}://${_environment.default.APP.email_service.host}/${_environment.default.APP.email_service.namespace}/gmail/messages/${id}`, "put", {
        data: params
      });
    },
    remove(id, user) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let url = `${_environment.default.APP.email_service.scheme}://${_environment.default.APP.email_service.host}/${_environment.default.APP.email_service.namespace}/gmail/messages/${id}`;
      return adapter.ajax(url, "delete");
    }
  });
});