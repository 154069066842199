define('knowme-client/routes/solutions/mobile', ['exports', 'knowme-client/mixins/routes/unauthenticated-mixin'], function (exports, _unauthenticatedMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  let SolutionsMobileRoute;

  SolutionsMobileRoute = Ember.Route.extend(_unauthenticatedMixin.default, {});

  exports.default = SolutionsMobileRoute;
});