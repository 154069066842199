define('knowme-client/mixins/models/validations/contact', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		firstName: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 50, allowBlank: true })],
		lastName: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 50, allowBlank: true })],
		title: (0, _emberCpValidations.validator)('length', { max: 50, allowBlank: true }),
		contactInfos: (0, _emberCpValidations.validator)('has-many'),
		contactInfosContactEmails: (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			let result = model.get('contactInfosContactEmails').filterBy('isDeleted', false).get('length') == 0 && model.get('contactInfosContactPhoneNumbers').filterBy('isDeleted', false).get('length') == 0;

			return !result;
		}, {
			dependentKeys: ['model.contactInfosContactPhoneNumbers.length', 'model.contactInfosContactPhoneNumbers.@each.isDeleted', 'model.contactInfosContactEmails.length', 'model.contactInfosContactEmails.@each.isDeleted']
		}),
		contactInfosContactPhoneNumbers: (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			let result = model.get('contactInfosContactEmails').filterBy('isDeleted', false).get('length') == 0 && model.get('contactInfosContactPhoneNumbers').filterBy('isDeleted', false).get('length') == 0;

			return !result;
		}, {
			dependentKeys: ['model.contactInfosContactPhoneNumbers.length', 'model.contactInfosContactPhoneNumbers.@each.isDeleted', 'model.contactInfosContactEmails.length', 'model.contactInfosContactEmails.@each.isDeleted']
		})
	});
});