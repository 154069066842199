define('knowme-client/models/credit-card', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/validations/credit-card', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _relationships, _creditCard, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_creditCard.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    account: (0, _relationships.belongsTo)('account', { async: true, inverse: 'creditCard' }),
    cardType: (0, _attr.default)(),
    number: (0, _attr.default)(),
    month: (0, _attr.default)(),
    year: (0, _attr.default)(),
    expirationDate: (0, _attr.default)(),
    onYearMonthChange: Ember.observer('year', 'month', function () {
      if (Ember.isPresent(this.get('year')) && Ember.isPresent(this.get('month'))) {
        this.set('expirationDate', `${this.get('month')}/${this.get('year')}`);
      } else {
        this.set('expirationDate', null);
      }
    }),
    code: (0, _attr.default)(),
    name: (0, _attr.default)()
  });
});