define('knowme-client/serializers/application', ['exports', 'ember-data/serializers/embedded-records-mixin', 'ember-data/serializers/json'], function (exports, _embeddedRecordsMixin, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  let ApplicationSerializer;

  ApplicationSerializer = _json.default.extend(_embeddedRecordsMixin.default, {
    keyForAttribute(attr /*, method*/) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship(key, relationship, method) {
      if (method === 'serialize') {
        if (relationship === 'belongsTo') {
          return `${Ember.String.underscore(key)}_id`;
        } else {
          return `${Ember.String.underscore(key)}_attributes`;
        }
      } else {
        return Ember.String.underscore(key);
      }
      // return this._super(...arguments);
    }
  });

  exports.default = ApplicationSerializer;
});