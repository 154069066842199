define('knowme-client/serializers/account-json', ['exports', 'knowme-client/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    session: Ember.inject.service(),
    attrs: {
      parentAccount: { serialize: 'id' },
      subscription: { serialize: 'records' },
      settings: { serialize: 'records' },
      dashboardPermissions: { serialize: 'records' },
      timeZoneMapping: { serialize: false },
      customerTypeConfirmed: { serialize: false },
      agreementAcceptedAt: { serialize: false },
      currentWizardStep: { serialize: false },
      idNumber: { serialize: false },
      defaultManualAd: { serialize: false },
      defaultCallerIdsAd: { serialize: false },
      customOpportunityFields: { serialize: false }
    },
    keyForRelationship(key, relationship, method) {
      if (['subscription', 'settings', 'dashboardPermissions'].includes(key) && method === 'serialize') {
        return `${Ember.String.underscore(key)}_attributes`;
      }
      return this._super(...arguments);
    },
    serialize: function () /*snapshot, options*/{
      var json = this._super(...arguments);
      if (this.get('session.isAuthenticated') && !this.get('session.currentUser.hasAdminRole')) {
        delete json.settings_attributes;
      }
      if (!this.get('session.isAuthenticated') || !this.get('session.currentUser.isMasterAdmin')) {
        delete json.parent_account_id;
      }
      return json;
    },
    _generateSerializedHasMany(snapshot, relationship) {
      var hasMany = [];
      Ember.get(snapshot._internalModel._relationships.get(relationship.key), 'members').forEach(function (member) {
        let memberSnapshot = member.createSnapshot();
        hasMany.push(memberSnapshot);
      });
      var manyArray = Ember.A(hasMany);
      var ret = new Array(manyArray.length);

      for (var i = 0; i < manyArray.length; i++) {
        var embeddedSnapshot = manyArray[i];
        var embeddedJson = embeddedSnapshot.serialize({ includeId: true });
        this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
        ret[i] = embeddedJson;
      }

      return ret;
    }
  });
});