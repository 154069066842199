define('knowme-client/routes/leads/show/edit', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    model: function () {
      let route = this;
      let leadsShowParams = route.paramsFor('leads.show');
      let lead = route.get('store').find('lead', leadsShowParams.id);

      return route.get('session.currentUser').then(function (user) {
        return user.get('account');
      }).then(function (account) {
        if (account.get('isbtob') && Ember.isBlank(lead.get('organization'))) {
          route.get('store').createRecord('organization', { lead: lead });
        }
        return lead;
      });
      // lead.then(function(){
      // build contact urls
      // let leadContactUrlsSocialNetworks = lead.get('contactUrls.@each.socialNetwork');
      // let contactInfo = lead.get('primaryWorkContactInfo');
      /*
      [null, 'facebook', 'twitter', 'linkedin', 'google_plus'].forEach(function(socialNetwork) {
        if(!leadContactUrlsSocialNetworks.contains(socialNetwork)) {
          var contactUrl = route.store.createRecord('contactUrl', {socialNetwork: socialNetwork});
          contactUrl.set('contactInfo', contactInfo);
        }
      });
      */
      // });
    },
    afterModel(model) {
      if (model.get('isAnonymous')) {
        this.transitionTo('leads.show', model);
      } else {
        return Ember.RSVP.hash({
          organizationFields: this.get('store').query('organization-field', { enable: true }),
          contactFields: this.get('store').query('contact-field', { enable: true })
        }).then(function (results) {
          console.log('results', results);
          this.set('controllerData', results);
        }.bind(this));
      }
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      console.log(this.get('controllerData'));
      controller.setProperties(this.get('controllerData'));
      // console.log(controller.get('leadFields.length'));
      this.set('controllerData', {});
    },
    renderTemplate: function () /*controller, model*/{
      return this.render('leads/show/edit', {
        into: 'application'
      });
    }, actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        let resource = this.controller.get('resource');
        model.get('contacts').forEach(function (contact) {
          contact.get('contactInfos').forEach(function (contactInfo) {
            contactInfo.get('contactEmails').filterBy('isNew', false).invoke('rollbackAttributes');
            contactInfo.get('contactEmails').filterBy('isNew', true).invoke('deleteRecord');
            contactInfo.get('contactPhoneNumbers').filterBy('isNew', false).filterBy('hasDirtyAttributes', true).invoke('rollbackAttributes');
            contactInfo.get('contactPhoneNumbers').filterBy('isNew', false).filterBy('hasDirtyAttributes', false).invoke('reload');
            contactInfo.get('contactPhoneNumbers').filterBy('isNew', true).invoke('deleteRecord');
            contactInfo.get('contactUrls').filterBy('isNew', false).invoke('rollbackAttributes');
            contactInfo.get('contactUrls').filterBy('isNew', true).invoke('deleteRecord');
            contactInfo.get('contactAddresses').filterBy('isNew', false).invoke('rollbackAttributes');
            contactInfo.get('contactAddresses').filterBy('isNew', true).invoke('deleteRecord');
          });
          contact.get('contactInfos').filterBy('isNew', true).invoke('deleteRecord');
          contact.get('customAttributes').filterBy('isNew', true).invoke('deleteRecord');
          contact.get('customAttributes').filterBy('isNew', false).invoke('rollbackAttributes');
          contact.rollbackAttributes();
        });

        if (Ember.isPresent(model.get('organization'))) {
          model.get('organization.customAttributes').filterBy('isNew', true).invoke('deleteRecord');
          model.get('organization.customAttributes').filterBy('isNew', false).invoke('rollbackAttributes');
          model.get('organization').rollbackAttributes();
        }
        model.rollbackAttributes();
      }
    }
  });
});