define('knowme-client/helpers/social-network-placeholder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var socialNetworkPlaceholder, SocialNetworkPlaceholderHelper;

  exports.socialNetworkPlaceholder = socialNetworkPlaceholder = function (params) {
    var socialNetwork = params[0];
    var placeholder;
    switch (socialNetwork) {
      case 'facebook':
        placeholder = 'Facebook';
        break;
      case 'twitter':
        placeholder = 'Twitter';
        break;
      case 'linkedin':
        placeholder = 'LinkedIn';
        break;
      case 'google_plus':
        placeholder = 'Google+';
        break;
      default:
        placeholder = 'Website';
        break;
    }
    return placeholder;
  };

  SocialNetworkPlaceholderHelper = Ember.Helper.helper(socialNetworkPlaceholder);

  exports.socialNetworkPlaceholder = socialNetworkPlaceholder;
  exports.default = SocialNetworkPlaceholderHelper;
});