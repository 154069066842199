define('knowme-client/components/credit-card-input', ['exports', 'ember-inputmask/components/credit-card-input'], function (exports, _creditCardInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _creditCardInput.default.extend({
    updateCardType: Ember.observer('unmaskedValue', function () {
      console.log('unmaskedValue');
    })
  });
});