define('knowme-client/mirage/helpers/seeds', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.prepareRoles = prepareRoles;
  exports.prepareColumnSettings = prepareColumnSettings;
  exports.prepareNextActions = prepareNextActions;
  exports.prepareSalesStages = prepareSalesStages;
  exports.prepareOpportunityFields = prepareOpportunityFields;
  exports.prepareDashboardWidgets = prepareDashboardWidgets;
  exports.prepareAccountSettinngs = prepareAccountSettinngs;
  function prepareRoles(server) {
    ['master_admin', 'client_admin', 'admin', 'master_manager', 'manager', 'sales_person'].forEach(function (key) {
      server.create('role', { key: key, name: key });
    });
  }

  function prepareColumnSettings(server) {
    ['name', 'date', 'last_activity', 'priority', 'sales_stage', 'pipeline', 'revenue', 'assigned_to', 'due_date', 'actions'].forEach(function (key) {
      server.create('column-setting', { key: key, enable: true });
    });
  }

  function prepareNextActions(server) {
    [{ key: "follow_up", name: "Follow Up" }, { key: "assign", name: "assign" }, { key: "do_not_call", name: "Do Not Call" }].forEach(function (field, index) {
      server.create('sales-action', { key: field.key, name: field.name, position: index + 1 });
    });
  }

  function prepareSalesStages(server) {
    [{ key: "unqualified", name: "Unqualified" }, { key: "qualified", name: "Qualified" }, { key: "appointment", name: "Appointment" }, { key: "activity", name: "Activity" }, { key: "closed", name: "Closed / Won" }].forEach(function (field, index) {
      server.create('sales-stage', { key: field.key, name: field.name, position: index + 1 });
    });
  }

  function prepareOpportunityFields(server) {
    [{ key: "sales_stage", name: "Sales Stage", internal: true }, { key: "appt_date", name: "Appt. Date", internal: true }, { key: "priority", name: "Priority", internal: true }, { key: "activity", name: "Activity", internal: true }, { key: "next_action", name: "Next Action", internal: true }, { key: "campaign_ad", name: "Campaign/Ad", internal: true }, { key: "due_date", name: "Due Date", internal: true }, { key: "due_time", name: "Due Time", internal: true }, { key: "assigned_to", name: "Assigned To", internal: true }, { key: "forecast", name: "Forecast", internal: true }, { key: "custom_1", name: "Custom 1", internal: false }].forEach(function (field, index) {
      server.create('opportunity-field', { key: field.key, name: field.name, displayName: field.name, enable: true, internal: field.internal, position: index + 1 });
    });
  }

  function prepareDashboardWidgets(server) {
    [{ key: 'leads', title: 'Leads' }, { key: 'customers', title: 'Customers' }, { key: 'sales_stages', title: 'Sales Stages' }, { key: 'campaigns', title: 'Campaigns' }, { key: 'reports', title: 'Reports' }, { key: 'contacts_satisfaction', title: 'Contacts Satisfaction' }, { key: 'how_to', title: 'How To' }].forEach(function (field, index) {
      server.create('dashboard-widget', { key: field.key, name: field.name });
    });
  }

  function prepareAccountSettinngs(server, account) {
    _environment.default.APP.SettingsKeys.forEach(key => {
      server.create('account-setting', { key: key, account: account });
    });
  }
});