define('knowme-client/serializers/contact-url', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ContactUrlSerializer;

  ContactUrlSerializer = _application.default.extend({
    attrs: {
      contactInfo: { serialize: false }
    }
  });

  exports.default = ContactUrlSerializer;
});