define('knowme-client/routes/chats/show', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      return this.get('store').find('room', params.id);
    },
    afterModel: function (room) {
      let requests = {
        messages: this.get('store').query('chat-message', { room_id: room.get('id'), status: 'sent' }),
        draftMessages: this.get('store').query('chat-message', { room_id: room.get('id'), status: 'draft', owner_id: this.get('session.currentUser.id') })
      };
      if (!room.get('isGroup')) {
        requests.chatMembers = room.hasMany('users').reload();
      }
      if (room.get('unreadMessagesCount') > 0) {
        room.set('readMessages', true);
        requests.model = room.save();
      }
      if (room.get('isUntouched')) {
        room.set('isTouched', true);
        requests.model = room.save();
      }
      return Ember.RSVP.hash(requests).then(function (data) {
        this.set('controllerData', data);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      controller.set('messangesArray', controller.get('messages').toArray());
      this.set('controllerData', {});
      controller.set('model.readMessages', false);
      controller.set('chatsController.selectedChat', controller.get('model'));
      controller.setupMessageConsumer();
      if (controller.get('draftMessages.length') > 0) {
        controller.set('draftMessage', controller.get('draftMessages.firstObject'));
      } else {
        controller.set('draftMessage', controller.get('store').createRecord('chat-message', { room: controller.get('model'), files: [] }));
      }
      controller.refreshNanoScroller();
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this._super(...arguments);
      if (Ember.isPresent(controller.get('messageConsumer'))) {
        controller.get('messageConsumer').destroy();
        controller.set('isRecordingEnabled', false);
        controller.send('saveDrafMessage');
      }
      if (isExiting) {
        controller.set('chatsController.selectedChat', null);
      }
    },
    actions: {
      edit: function (message) {
        this.controller.set('messageToBeEdited', message);
        this.controller.set('draftMessage.body', message.get('body'));
        Ember.run.later(function () {
          Ember.$('.message-body').focus();
        }, 100);
      },
      copy: function (message) {
        let str = message.get('body');
        function listener(e) {
          e.clipboardData.setData("text/html", str);
          e.clipboardData.setData("text/plain", str);
          e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
      },
      quote: function (message) {
        this.controller.set('draftMessage.originalMessage', message);
        Ember.run.later(function () {
          Ember.$('.message-body').focus();
        }, 100);
      },
      forward: function (message) {
        this.controller.set('chatsController.messageToBeForward', message);
        this.controller.set('chatsController.forwardMessageModal', true);
      },
      remove: function (message) {
        this.controller.set('confirmRemoveMessageModal', true);
        this.controller.set('messageToBeRemoved', message);
      },
      refreshModel: function () {
        this.refresh();
      }
    }
  });
});