define('knowme-client/helpers/feedback-class-for', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var feedbackClassFor, FeedbackClassForHelper;

  exports.feedbackClassFor = feedbackClassFor = function (params, hash) {
    let errors, classes;
    const { model, fieldName, asRequired } = hash;
    if (Ember.isEmpty(model)) {
      // console.log('Helper has-error: Invalid arguments', arguments);
      return 'hide';
    }

    // This block works for ember-cp-validations
    let fieldNameFeedback = model.get(`validations.attrs.${fieldName}.feedback`);
    if (asRequired && model.get(`validations.attrs.${fieldName}.isInvalid`)) {
      fieldNameFeedback = 'required';
    }
    if (Ember.isPresent(fieldNameFeedback)) {
      return {
        required: 'glyphicon-asterisk text-danger sup',
        optional: 'hide',
        invalid: 'glyphicon-remove text-danger',
        valid: 'glyphicon-ok text-success sup'
      }[fieldNameFeedback];
    }

    errors = model.get('errors');

    if (model.get(`${fieldName}IsRequired`)) {
      if (Ember.isPresent(model.get(fieldName))) {
        if (Ember.isPresent(errors.get(fieldName))) {
          classes = 'glyphicon-remove text-danger';
        } else {
          classes = 'glyphicon-ok text-success';
        }
      } else {
        if (model.get(`${fieldName}HasChanged`) && Ember.isPresent(errors.get(fieldName))) {
          classes = 'glyphicon-remove text-danger';
        } else {
          classes = 'glyphicon-asterisk text-danger sup';
        }
      }
    } else {
      classes = 'hide';
    }

    // if required && present && wrong
    //  x
    // if required && non present && not changed
    //  *
    // if required && present && correct 
    //  check
    // if non required 
    //  hide

    return classes;
  };

  FeedbackClassForHelper = Ember.Helper.helper(feedbackClassFor);

  exports.feedbackClassFor = feedbackClassFor;
  exports.default = FeedbackClassForHelper;
});