define('knowme-client/mixins/controllers/pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: ['page'],
    page: null,
    count: null,
    current: null,
    paginatorPageInput: 1,
    showingStart: Ember.computed('current', 'limit', function () {
      return this.get('limit') * (this.get('current') - 1) + 1;
    }),
    showingEnd: Ember.computed('model.length', 'limit', 'count', function () {
      return this.get('limit') * (this.get('current') - 1) + this.get('model.length');
    }),
    onMetaPaginationNumberChange: Ember.observer('model.meta.pagination.{totalCount,totalPages}', 'model.meta.pagination.page.{number,size}', function () {
      if (Ember.isPresent(this.get('model.meta.pagination.page.number'))) {
        this.set('current', this.get('model.meta.pagination.page.number'));
      }
      if (Ember.isPresent(this.get('model.meta.pagination.page.size'))) {
        this.set('limit', this.get('model.meta.pagination.page.size'));
      }
      if (Ember.isPresent(this.get('model.meta.pagination.totalCount'))) {
        this.set('total', this.get('model.meta.pagination.totalCount'));
      }
      if (Ember.isPresent(this.get('model.meta.pagination.totalPages'))) {
        this.set('count', this.get('model.meta.pagination.totalPages'));
      }
    }),
    onCurrentChange: Ember.observer('current', function () {
      this.set('paginatorPageInput', this.get('current'));
    }),
    onPaginatorInputPageChange: Ember.observer('paginatorPageInput', function () {
      let paginatorPageInput = parseFloat(this.get('paginatorPageInput'));
      let newPage;
      if (isNaN(paginatorPageInput) || paginatorPageInput > parseFloat(this.get('count'))) {
        return;
      } else if (paginatorPageInput === 1) {
        newPage = null;
      } else {
        newPage = paginatorPageInput;
      }
      Ember.run.debounce(this, Ember.set, this, 'page', newPage, 600);
    })
  });
});