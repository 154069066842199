define('knowme-client/models/template', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/validations/template', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'knowme-client/models/attachment-resource'], function (exports, _model, _attr, _relationships, _template, _emberCpValidationsCustomProperties, _dirty, _attachmentResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _attachmentResource.default.extend(_template.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    // relationships
    authentication: (0, _relationships.belongsTo)('authentication', { async: true }),
    // account: belongsTo('account', {async: true}),
    //fields
    contentWidth: (0, _attr.default)(),
    name: (0, _attr.default)(),
    body: (0, _attr.default)(),
    status: (0, _attr.default)(),
    createdAt: (0, _attr.default)('date')
  });
});