define('knowme-client/adapters/communication', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,filter,paginate,lead_id}',
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      paginate: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && query.paginate === true) {
          delete query.paginate;
          return true;
        }
      },
      lead_id: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && Ember.isPresent(query.lead_id)) {
          return query.lead_id;
        }
      },
      fields: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && Ember.isPresent(query.fields)) {
          let fields = Ember.isArray(query.fields) ? query.fields.join(',') : query.fields;
          delete query.fields;
          return fields;
        }
      },
      filter: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && Ember.isPresent(query.filter)) {
          let filter = Ember.isArray(query.filter) ? query.filter.join(',') : query.filter;
          delete query.filter;
          return filter;
        }
      }
    }
  });
});