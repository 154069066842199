define('knowme-client/routes/account/clients/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function () {
      let model = this.store.createRecord('account');
      model.set('skip_terms_of_service_validation', true);
      this.store.createRecord('subscription', { account: model });
      return model;
    },
    afterModel: function (model) {
      let billingTerm = this.store.createRecord('account-setting', { key: 'account.billing_term', account: model });
      this.set('billingTerm', billingTerm);
      model.set('settingsAccountBillingTerm.value', 1);

      return Ember.RSVP.hash({
        plans: this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc', key: ['knowme_iq.starter', 'knowme_iq.premium', 'knowme_iq.starter_unlimited', 'knowme_iq.premium_unlimited'] })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      controller.set('billingTerm', this.get('billingTerm'));
      this.set('controllerData', {});
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        if (model.get('isNew')) {
          model.get('settings').filterBy('isNew', true).invoke('rollbackAttributes');
          model.rollbackAttributes();
        }
      }
    }
  });
});