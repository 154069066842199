define('knowme-client/mirage/factories/message', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    from: _faker.default.internet.email,
    to: _faker.default.internet.email,
    subject: _faker.default.lorem.words,
    body: _faker.default.lorem.sentences,
    emailsCount: function () {
      return 1;
    },
    opportunitiesCount: function () {
      return 1;
    },
    isOwn: function () {
      return true;
    },
    isInbox: function () {
      return _faker.default.random.arrayElement([true, false]);
    },
    isDraft: function () {
      return _faker.default.random.arrayElement([true, false]);
    },
    isJunk: function () {
      return _faker.default.random.arrayElement([true, false]);
    },
    sentAt: function () {
      return _faker.default.date.past().toISOString();
    },
    date: function () {
      return _faker.default.date.past().toISOString();
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    afterCreate(message, server) {
      let contact = null;
      let opportunity = null;
      let user = null;
      if (Ember.isBlank(message.contact)) {
        let contactEmail = _faker.default.random.arrayElement(server.schema.contactEmails.all().models);
        contact = contactEmail.contactInfo.contact;
        opportunity = contact.lead.opportunities.models[0];
        user = opportunity.assignedTo;
        message.update({
          opportunity: opportunity,
          contact: contact,
          user: user
        });
      } else {
        contact = message.contact;
        opportunity = message.opportunity;
        user = opportunity.assignedTo;
      }
      // contact.update({
      //   shortMessagesCount: contact.shortMessages.length,
      //   lastShortMessage: contact.shortMessages.models[contact.shortMessages.length - 1],
      //   opportunitiesCount: contact.shortMessages.models.mapBy('opportunity.id').uniq().length
      // });
      // user.update({
      //   shortMessagesCount: user.shortMessages.length,
      //   lastShortMessage: user.shortMessages.models[user.shortMessages.length - 1]
      // });
    }
  });
});