define('knowme-client/models/users-chat-message', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    user: (0, _relationships.belongsTo)('user', { async: true }),
    message: (0, _relationships.belongsTo)('chat-message', { async: true }),
    readAt: (0, _attr.default)('date'),
    isUnread: Ember.computed('readAt', function () {
      return Ember.isBlank(this.get('readAt'));
    })
  });
});