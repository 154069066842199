define('knowme-client/components/lead-activity/activity-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    playable: true,
    formattedCallLength: Ember.computed('activity.call.callLength', function () {
      return this.formatSecondsToMinutesAndSeconds(this.get('activity.call.callLength'));
    }),
    formattedCallRecordingLength: Ember.computed('activity.call.callRecordinglength', function () {
      return this.formatSecondsToMinutesAndSeconds(this.get('activity.call.callRecordingLength'));
    }),
    formatSecondsToMinutesAndSeconds: function (seconds) {
      if (Ember.isBlank(seconds)) {
        return '';
      }
      var calculatedMinutes = Math.floor(seconds / 60);
      var calculatedSeconds = seconds - calculatedMinutes * 60;

      var mDisplay = calculatedMinutes < 10 ? '0' + calculatedMinutes : calculatedMinutes;
      var sDisplay = calculatedSeconds < 10 ? '0' + calculatedSeconds : calculatedSeconds;

      return `[${mDisplay}:${sDisplay}]`;
    },
    isQuoteActivity: Ember.computed('activity.body.opportunity.activity', function () {
      return ['estimate_created', 'revised_quote_sent', 'estimate_revised', 'invoice_created', 'invoice_paid', 'sales_receipt_created', 'quote_sent', 'quote_accepted', 'quote_rejected', 'quote_edited'].includes(this.get('activity.body.opportunity.activity'));
    }),
    isReviewActivity: Ember.computed('activity.body.opportunity.activity', function () {
      return ['review_request_sent', 'review_link_opened'].includes(this.get('activity.body.opportunity.activity'));
    }),
    quoteKey: Ember.computed('activity.body.quote', 'activity.opportunityChargeMethod', 'activity.body.opportunity.activity', function () {
      let activity = this.get('activity.body.opportunity.activity');
      let key = `opportunity.activity_keys.${activity}`;

      if (Ember.isPresent(this.get('activity.opportunityChargeMethod'))) {
        let opportunityChargeMethod = this.get('activity.opportunityChargeMethod');
        switch (activity) {
          case 'quote_sent':
            key = `opportunity.activity_keys.${opportunityChargeMethod}_sent`;
            break;
          case 'invoice_paid':
            key = `opportunity.activity_keys.invoice_paid`;
            break;
          case 'quote_edited':
            key = `opportunity.activity_keys.${opportunityChargeMethod}_edited`;
            break;
          case 'revised_quote_sent':
            key = `opportunity.activity_keys.revised_${opportunityChargeMethod}_sent`;
            break;
          default:

        }
      }

      return key;
    }),
    actions: {
      redirectToSMS: function (sms) {
        if (sms.get('canBeRead')) {
          let textId = sms.get('id');
          this.get('store').findRecord('short-message', textId).then(function (message) {
            let params = {
              from_date: null,
              to_date: null,
              satisfaction: null,
              contact_id: message.get('contact.id'),
              opportunityId: message.get('opportunity.id')
            };
            localStorage.setItem('textId', textId);
            this.get('routing').transitionTo('short-messages', [], params);
          }.bind(this));
        }
      },
      redirectToEmail: function (email) {
        let emailId = email.get('id');
        this.get('store').findRecord('message', emailId).then(function (message) {
          let params = {
            from_date: null,
            to_date: null,
            contactId: message.get('contact.id'),
            opportunityId: message.get('opportunity.id'),
            singleOpportunity: null
          };
          if (message.get('isJunk')) {
            params.type = 'trash';
          }

          if (message.get('opportunity.activities').filterBy('isAEmail', true).length === 1) {
            params.singleOpportunity = true;
          }

          localStorage.setItem('emailId', emailId);
          this.get('routing').transitionTo('emails', [], params);
        }.bind(this));
      },
      startListening: function () {
        this.sendAction('startListening');
      },
      stopListening: function () {
        this.sendAction('stopListening');
      },
      callListened: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return;
        }
        let store = this.get('store');
        let currentUser = this.get('session.currentUser');
        let call = this.get('activity.call');
        if (Ember.isPresent(call.then)) {
          call.then(function (call) {
            if (!call.get('listened')) {
              call.set('listened', true);
              return call.save().then(function (call) {
                let callListener = store.createRecord('callListener', { call: call, user: currentUser });
                callListener.save().then(function () {
                  call.get('listeners').reload();
                });
              });
            }
          });
        } else {
          if (!call.get('listened')) {
            call.set('listened', true);
            return call.save().then(function (call) {
              let callListener = store.createRecord('callListener', { call: call, user: currentUser });
              callListener.save().then(function () {
                call.get('listeners').reload();
              });
            });
          }
        }
      },
      showActivities: function (type) {
        this.get('routing').transitionTo('leads.show.opportunities.show', [this.get('activity.lead.id'), this.get('activity.opportunity.id')]).then(function () /*transition*/{
          Ember.run.schedule('afterRender', this, function () {
            Ember.$('.history-summary #' + type + '-activities').click();
          });
        }.bind(this));
      }
    }
  });
});