define('knowme-client/routes/campaign/ads/new', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    model: function () {
      let campaign = this.modelFor('campaign');
      let captureMode = 'automated';
      if (!this.get('features.campaignsAutomatedCaptureAvailable')) {
        captureMode = 'manual';
      }
      return this.store.createRecord('ad', { campaign: campaign, captureMode: captureMode });
    },
    afterModel: function () {
      let adapter = Ember.getOwner(this).lookup('adapter:application');

      let accountSummaryUrl = adapter.buildURL('accounts/summary');
      let accountSummaryRequest = adapter.ajax(accountSummaryUrl, 'GET');

      return Ember.RSVP.hash({
        accountSummary: accountSummaryRequest.then(function (accountSummary) {
          return accountSummary;
        })
      }).then(function (results) {
        let serializer = this.get("store").serializerFor('json-api');
        serializer.camelizeKeys(results.accountSummary);
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      //check if tfn or local numbers have enough values
      let unusedNumberOfTollFreeNumbers = controller.accountSummary.unusedNumberOfTollFreeNumbers;
      let unusedNumberOfLocalNumbers = controller.accountSummary.unusedNumberOfLocalNumbers;
      let message = '';
      let messages = {
        all: 'You have used all available Numbers (Toll Free and Local numbers) in your Service plan.To get a new Number, you can take a Number off an existing ad that is no longer being used OR an Admin can purchase additional Numbers under the Upgrade section in Client Information.',
        tfn: 'You have used all available Toll Free Numbers (TFNs) in your Service plan. To get a new TFN, you can take a TFN off an existing ad that is no longer being used OR an Admin can purchase additional TFNs under the Upgrade section in Client Information.',
        local: 'You have used all available Local numbers. To get a new Local number, you can take a Local number off an existing ad that is no longer being used OR an Admin can purchase additional Local numbers under the Upgrade section in Client Information.'
      };
      if (unusedNumberOfTollFreeNumbers === 0) {
        controller.set('disableTollFreeDropdown', true);
        message = messages.tfn;
      }
      if (unusedNumberOfLocalNumbers === 0) {
        controller.set('disableLocalDropdown', true);
        message = messages.local;
      }
      if (unusedNumberOfTollFreeNumbers === 0 && unusedNumberOfLocalNumbers === 0) {
        message = messages.all;
      }
      controller.set('message', message);

      this.set('controllerData', {});
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        if (model.get('isNew')) {
          model.deleteRecord();
        }
        this.store.peekAll('callTouchPoint').filter(function (callTouchPoint) {
          return callTouchPoint.get('isNew');
        }).invoke('deleteRecord');
        this.get('flashMessages').clearMessages();
        this.set('disableTollFreeDropdown', null);
        this.set('disableLocalDropdown', null);
      }
    }
  });
});