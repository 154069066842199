define('knowme-client/mirage/factories/sales-action', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.default.Factory.extend({
		name: function () {
			return _faker.default.random.arrayElement(['Assign', 'Follou Up']);
		},
		key: function () {
			return _faker.default.random.arrayElement(['assign', 'follow_up']);
		},
		internal: function () {
			return _faker.default.random.arrayElement([true, false]);
		},
		editable: function () {
			return _faker.default.random.arrayElement([true, false]);
		}
	});
});