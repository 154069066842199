define('knowme-client/authenticators/json-web-token', ['exports', 'ember-simple-auth/authenticators/devise', 'knowme-client/config/environment'], function (exports, _devise, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    // serverTokenEndpoint: config.AuthorizationService.serverTokenEndpoint,
    identificationAttributeName: 'email',
    restore(data) {
      var _getProperties = this.getProperties('tokenAttributeName');

      var tokenAttributeName = _getProperties.tokenAttributeName;

      var tokenAttribute = data[tokenAttributeName];

      if (Ember.isPresent(tokenAttribute)) {
        return Ember.RSVP.Promise.resolve(data);
      } else {
        return Ember.RSVP.Promise.reject();
      }
    },
    makeRequest(data, options) {
      data = JSON.stringify(data);

      if (Ember.isBlank(options)) {
        options = {};
      }
      let self = this;
      options.contentType = 'application/json';
      options.dataType = 'json';
      options.xhr = function () {
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            self.set('controller.loginProgress', percentComplete * 100);
          }
        }, false);
        xhr.addEventListener("progress", function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            self.set('controller.loginProgress', percentComplete * 100);
          }
        }, false);
        return xhr;
      };

      return this._super.apply(this, [data, options]);
    },
    authenticate(identification, password, controller) {
      this.set('controller', controller);
      return this._super.apply(this, [identification, password]);
    },
    serverTokenEndpoint: Ember.computed(function () {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.buildURL('authentication_tokens');
    })
  });
});