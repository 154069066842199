define('knowme-client/torii-providers/outlook-oauth2', ['exports', 'torii/providers/oauth2-code', 'torii/lib/query-string', 'torii/configuration'], function (exports, _oauth2Code, _queryString, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OutlookOauth2 = _oauth2Code.default.extend({

    name: 'outlook-oauth2',
    baseUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',

    requiredUrlParams: ['client_id', 'redirect_uri', 'response_type'],
    // no need encode
    nonEncodeURIComponents: ['scope'],

    requestVisibleActions: (0, _configuration.configurable)('requestVisibleActions', ''),

    responseType: (0, _configuration.configurable)('responseType', 'code'),

    responseParams: ['code'], //, 'state'],

    redirectUri: (0, _configuration.configurable)('redirectUri'),

    buildQueryString: function () {
      var requiredParams = this.get('requiredUrlParams'),
          optionalParams = this.get('optionalUrlParams');

      var qs = _queryString.default.create({
        provider: this,
        requiredParams: requiredParams
      });
      // skip encoding encodeURIComponents
      var keyValuePairs = Ember.A([]);
      this.get('nonEncodeURIComponents').forEach(function (paramName) {
        var camelizedName = Ember.String.camelize(paramName),
            paramValue = this.get(camelizedName);

        if (paramValue || paramValue === false) {
          keyValuePairs.push([paramName, paramValue]);
        }
      }.bind(this));
      var str = keyValuePairs.map(function (pair) {
        return pair.join('=');
      }).join('&');
      //optionalParams: optionalParams

      return qs.toString() + '&' + str;
    }
  });

  exports.default = OutlookOauth2;
});