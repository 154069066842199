define('knowme-client/routes/user-activities', ['exports', 'knowme-client/mixins/routes/account-setup', 'lodash', 'moment'], function (exports, _accountSetup, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let UserActivitiesIndexRoute;

  UserActivitiesIndexRoute = Ember.Route.extend(_accountSetup.default, {
    pagination: {},
    features: Ember.inject.service(),
    beforeModel: function (transition) {
      // this._super(...arguments);
      return this._super.apply(this, arguments).then(function () {
        if (this.get('features.activityUserAvailable')) {
          return this.get('session.account.subscription.plan');
        } else {
          transition.abort();
          return this.transitionTo('dashboard');
        }
      }.bind(this));
    },
    model: function (params) {
      params = _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value /*, key*/) {
        return value;
      });

      params.fields = [];
      params.paginate = false;
      params.status = 'active';
      params.fields.push('leads_count');
      params.fields.push('leads_pipeline');
      params.fields.push('customers_count');
      params.fields.push('customers_revenue');
      params.fields.push('leads_with_action_required');
      params.fields.push('leads_with_past_due_action');

      if (!this.get('features.isBasicCrmOrKnowmeIQStarter')) {
        params.fields.push('outbound_calls_count');
      }
      if (params.from_date && params.to_date) {
        params.opportunities = {
          from_date: (0, _moment.default)(params.from_date, "MM-DD-YYYY").format('DD-MM-YYYY'),
          to_date: (0, _moment.default)(params.to_date, "MM-DD-YYYY").format('DD-MM-YYYY')
        };
        params.calls = {
          from_date: (0, _moment.default)(params.from_date, "MM-DD-YYYY").format('DD-MM-YYYY'),
          to_date: (0, _moment.default)(params.to_date, "MM-DD-YYYY").format('DD-MM-YYYY')
        };
      }

      let currentUser = this.get('session.currentUser');
      let users = [];

      if (currentUser.get('isSalesPerson')) {
        users = [currentUser];
      } else if (currentUser.get('isManager')) {
        params.exclude_admins = true;
        users = this.get('store').query('user', params);
      } else {
        users = this.get('store').query('user', params);
      }
      return users;
    },
    actions: {
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      },
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });

  exports.default = UserActivitiesIndexRoute;
});