define('knowme-client/routes/account/settings/products', ['exports', 'lodash', 'knowme-client/mixins/routes/account-setup'], function (exports, _lodash, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    queryParams: {
      status: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      },
      kind: {
        refreshModel: true,
        as: 'type'
      }
    },
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    pagination: {},
    beforeModel: function (transition) {
      return this._super(...arguments).then(function () {
        if (!this.get('features.productsSectionAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Access Unauthorized', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        } else {
          if (Ember.isBlank(transition.queryParams.status)) {
            this.replaceWith('account.settings.products', { queryParams: { status: 'active' } });
          }
        }
      }.bind(this));
    },
    model: function (params) {
      let promise;
      promise = this.get('store').query('product', this.processParams(params));
      promise.then(result => {
        this.set('pagination.total', result.meta.pagination.total);
        this.set('pagination.limit', result.meta.pagination.limit);
        this.set('pagination.page', result.meta.pagination.page);
      });
      return promise;
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);
      if (Ember.isBlank(params.kind) || params.kind === 'null') {
        delete params.kind;
      }
      return _lodash.default.pickBy(params, _lodash.default.identity);
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
    }
  });
});