define('knowme-client/mirage/models/opportunity', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    lead: (0, _emberCliMirage.belongsTo)(),
    user: (0, _emberCliMirage.belongsTo)(),
    orders: (0, _emberCliMirage.hasMany)(),
    customAttributes: (0, _emberCliMirage.hasMany)(),
    activities: (0, _emberCliMirage.hasMany)('lead-activity'),
    assignedTo: (0, _emberCliMirage.belongsTo)('user'),
    salesStage: (0, _emberCliMirage.belongsTo)(),
    salesAction: (0, _emberCliMirage.belongsTo)(),
    lastActivity: (0, _emberCliMirage.belongsTo)('lead-activity', { inverse: null }),
    ad: (0, _emberCliMirage.belongsTo)(),
    attrs: ['id', 'name', 'createdAt', 'assignedToId', 'campaign', 'adId']
  });
});