define('knowme-client/routes/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		beforeModel: function () {
			this._super(...arguments);
			if (this.get('session.isAuthenticated')) {
				this.transitionTo('dashboard');
			} else {
				this.transitionTo('solutions');
			}
		}
	});
});