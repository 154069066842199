define('knowme-client/controllers/wizard/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    step: 'knowmeIQ',
    actions: {
      transitionToNextStep: function () /*account*/{
        this.set('skipRedirectToDashboard', true);
        this.transitionToRoute('wizard.products');
      }
    }
  });
});