define('knowme-client/mixins/controllers/product-photo', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		uploadButtonName: Ember.computed('photoUrl', function () {
			if (Ember.isPresent(this.get('photoUrl'))) {
				return 'Change photo';
			} else {
				return 'Add photo';
			}
		}),
		photoUrl: Ember.computed('base64Data', 'model.isNew', 'model.photos.length', function () {
			// return this.get('unsavedPhotos.firstObject.directUploadData.url')
			if (this.get('model.isNew')) {
				return this.get('base64Data');
			} else {
				return this.get('model.photos.firstObject.shortUrl');
			}
		}),
		productPhotos: Ember.computed.mapBy('unsavedPhotos', 'signedId'),
		actions: {
			addPhotoToProduct: function (blob) {
				this.get('unsavedPhotos').pushObject(blob);
				if (this.get('model.isNew')) {
					let reader = new FileReader();
					let file = this.get('unsavedPhotos.firstObject.file');
					reader.onload = function (e) {
						this.set('base64Data', e.target.result);
					}.bind(this);

					reader.readAsDataURL(file);
				} else {
					if (this.get('model.photos.length') > 0) {
						this.get('model.photos.firstObject').destroyRecord().then(function () {
							this.get('model').set('files', this.get('productPhotos'));
							this.get('model').save();
						}.bind(this));
					} else {
						this.get('model').set('files', this.get('productPhotos'));
						this.get('model').save();
					}
				}
			}
		}
	});
});