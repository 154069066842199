define('knowme-client/serializers/chat-touch-point-json', ['exports', 'knowme-client/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    attrs: {
      chatTouchPointMembers: { serialize: 'records' }
    },
    keyForRelationship(key, relationship, method) {
      if (['chatTouchPointMembers'].includes(key) && method === 'serialize') {
        return `${Ember.String.underscore(key)}_attributes`;
      }
      return this._super(...arguments);
    },
    serialize: function () /*snapshot, options*/{
      var json = this._super(...arguments);
      delete json.api_key;
      return json;
    },
    _generateSerializedHasMany(snapshot, relationship) {
      var hasMany = [];
      Ember.get(snapshot._internalModel._relationships.get(relationship.key), 'members').forEach(function (member) {
        let memberSnapshot = member.createSnapshot();
        hasMany.push(memberSnapshot);
      });
      var manyArray = Ember.A(hasMany);
      var ret = new Array(manyArray.length);

      for (var i = 0; i < manyArray.length; i++) {
        var embeddedSnapshot = manyArray[i];
        var embeddedJson = embeddedSnapshot.serialize({ includeId: true });
        this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
        ret[i] = embeddedJson;
      }

      return ret;
    }
  });
});