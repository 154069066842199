define('knowme-client/mixins/models/validations/credit-card', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    cardType: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    number: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
      let result = 'cannot be lower than start date';
      let cardType = model.get('cardType');
      if (Ember.isBlank(cardType) || Ember.isPresent(cardType) && cardType == 'American Express') {
        result = value && value.length === 15;
      } else {
        result = value && value.length === 16;
      }
      return result || 'please enter valid card number';
    }, {
      dependentKeys: ['cardType']
    })],
    month: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    expirationDate: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    year: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    code: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { min: 3, allowBlank: true })]
  });
});