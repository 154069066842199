define('knowme-client/serializers/template', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    normalize(typeClass, hash /*, prop*/) {
      hash.relationships = hash.relationships || {};
      hash.relationships['attachments'] = hash.relationships['attachments'] || {};
      hash.relationships['attachments'].links = hash.relationships.links || {};
      hash.relationships['attachments'].links.related = Ember.getOwner(this).lookup('adapter:attachment').buildURL('attachment', null, null, 'findMany', { record_id: hash.id, record_type: 'Template', paginate: false });
      return this._super.apply(this, arguments);
    }
  });
});