define('knowme-client/routes/account/settings/appointment-reminders/edit', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      let resource = this.get('store').find('reminder', params.id);
      return resource;
    },
    afterModel: function () /*model*/{
      if (this.get('session.currentUser.hasAdminRole')) {
        return Ember.RSVP.hash({
          users: this.get('store').query('user', { paginate: false, status: 'active', sort_by: 'name', order: 'asc' })
        }).then(function (results) {
          this.set('controllerData', results);
        }.bind(this));
      }
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      willTransition: function () /*transition*/{
        this.controller.set('displayForm', false);
        this.controller.get('model').rollbackAttributes();
      },
      didTransition: function () /*transition*/{
        this.controller.set('displayForm', true);
      }
    }
  });
});