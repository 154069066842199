define('knowme-client/routes/sales-stages', ['exports', 'lodash', 'moment', 'knowme-client/mixins/routes/account-setup'], function (exports, _lodash, _moment, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    pagination: {},
    params: {},
    beforeModel: function (transition) {
      this._super(...arguments);
      if (Ember.isPresent(transition.queryParams.sales_stage_id)) {
        return this.get('store').find('sales-stage', transition.queryParams.sales_stage_id).then(function (selectedStage) {
          return this.set('selectedStage', selectedStage);
        }.bind(this));
      }
    },
    model: function (params) {
      let route = this;
      let opportunitiesParams = this.processParams(params);
      this.set('params', opportunitiesParams);

      let opportunitiesPromise = route.get('store').query('opportunity', opportunitiesParams);
      opportunitiesPromise.then(result => {
        this.set('pagination.total', result.meta.pagination.total);
        this.set('pagination.limit', result.meta.pagination.limit);
        this.set('pagination.page', result.meta.pagination.page);
      });
      return opportunitiesPromise;
    },
    afterModel: function () /*model, transition*/{
      return Ember.RSVP.hash({
        users: this.get('store').query('user', { filter: ['with_opportunities'], opportunities: this.get('params'), paginate: false, status: 'active', sort_by: 'name', order: 'asc' }),
        salesStages: this.get('store').query('sales-stage', { filter: ['with_opportunities'], opportunities: this.get('params'), paginate: false, status: 'active' })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      // when opportunity is appointment but has other sales stage then sales stages dropdown should include appointment
      if (Ember.isPresent(this.get('selectedStage')) && !this.get('controllerData.salesStages').includes(this.get('selectedStage'))) {
        this.set('controllerData.salesStages', [this.get('selectedStage')]);
      }
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    processParams: function (_params) {
      let params = _lodash.default.omit(_params, function (value, key, object) {
        return Ember.isBlank(value);
      });

      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    },
    actions: {
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });
});