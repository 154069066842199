define('knowme-client/models/contact-info', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr', 'knowme-client/mixins/models/validations/contact-info', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _relationships, _attr, _contactInfo, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ContactInfo;

  ContactInfo = _model.default.extend(_contactInfo.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    contactAddresses: (0, _relationships.hasMany)('contact-address', { async: true }),
    contactPhoneNumbers: (0, _relationships.hasMany)('contact-phone-number', { async: true }),
    contactEmails: (0, _relationships.hasMany)('contact-email', { async: true }),
    contactUrls: (0, _relationships.hasMany)('contact-url', { async: true }),
    contact: (0, _relationships.belongsTo)('contact', { async: true }),
    category: (0, _attr.default)(),
    firstContactPhoneNumber: Ember.computed('contactPhoneNumbers.@each.tenDigitNumber', function () {
      return this.get('contactPhoneNumbers.firstObject');
    }),
    firstContactEmail: Ember.computed('contactEmails.@each.email', function () {
      return this.get('contactEmails.firstObject');
    }),
    categoryFirstLetter: Ember.computed('category', function () {
      return this.get('category').charAt(0);
    }),
    contactEmailsLength: Ember.computed.alias('contactEmails.length'),
    contactPhoneNumbersLength: Ember.computed.alias('contactPhoneNumbers.length'),
    contactAddressesLength: Ember.computed.alias('contactAddresses.length'),
    nonDeletedContactEmailsLength: Ember.computed('contactEmails.@each.isDeleted', function () {
      return this.get('contactEmails').filterBy('isDeleted', false).get('length');
    }),
    nonDeletedContactPhoneNumbersLength: Ember.computed('contactPhoneNumbers.@each.isDeleted', function () {
      return this.get('contactPhoneNumbers').filterBy('isDeleted', false).get('length');
    }),
    nonDeletedContactAddressesLength: Ember.computed('contactAddresses.@each.isDeleted', function () {
      return this.get('contactAddresses').filterBy('isDeleted', false).get('length');
    }),
    formattedCategory: Ember.computed('category', function () {
      return this.get('category').capitalize();
    }),
    truncatedCategory: Ember.computed('category', function () {
      return this.get('formattedCategory')[0];
    })
  });

  exports.default = ContactInfo;
});