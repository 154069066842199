define('knowme-client/mirage/factories/alert', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    key: _faker.default.company.catchPhrase,
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    }
  });
});