define('knowme-client/serializers/contact-info', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      contactEmails: { embedded: 'always' },
      contactAddresses: { embedded: 'always' },
      contactPhoneNumbers: { embedded: 'always' },
      contactUrls: { embedded: 'always' },
      contact: { serialize: false }
    },
    keyForRelationship: function (key, relationship, method) {
      if (['contactPhoneNumbers'].includes(key) && Ember.isNone(relationship) && Ember.isNone(method)) {
        return key;
      }
      return this._super(...arguments);
    },
    _generateSerializedHasMany: function _generateSerializedHasMany(snapshot, relationship) {
      var hasMany = this.snapshotHasMany(snapshot, relationship.key);

      var manyArray = Ember.A(hasMany);
      var ret = new Array(manyArray.length);

      for (var i = 0; i < manyArray.length; i++) {
        var embeddedSnapshot = manyArray[i];
        if (embeddedSnapshot.record.get('isNew') || embeddedSnapshot.record.get('isDeleted') || !Ember.isEmpty(Object.keys(embeddedSnapshot.changedAttributes())) || Ember.get(embeddedSnapshot.type, 'relationshipsByName')._keys.list.any(function (relationshipName) {
          return embeddedSnapshot.record.get(`${relationshipName}HasChanged`);
        })) {
          var embeddedJson = embeddedSnapshot.serialize({ includeId: true });
          if (embeddedSnapshot.record.get('isDeleted')) {
            embeddedJson['_destroy'] = true;
          }
          this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
          ret[i] = embeddedJson;
        }
      }

      return ret;
    },
    snapshotHasMany: function (snapshot, keyName, options) {
      var ids = options && options.ids;
      var relationship, members, hasData;
      var results;

      // if (ids && keyName in snapshot._hasManyIds) {
      //   return snapshot._hasManyIds[keyName];
      // }

      // if (!ids && keyName in snapshot._hasManyRelationships) {
      //   return snapshot._hasManyRelationships[keyName];
      // }

      relationship = snapshot._internalModel._relationships.get(keyName);
      if (!(relationship && relationship.relationshipMeta.kind === 'hasMany')) {
        throw new Ember.Error("Model '" + Ember.inspect(snapshot.record) + "' has no hasMany relationship named '" + keyName + "' defined.");
      }

      hasData = Ember.get(relationship, 'hasData');
      members = Ember.get(relationship, 'members');

      if (hasData) {
        results = [];
        members.forEach(function (member) {
          // if (!member.isDeleted()) {
          if (ids) {
            results.push(member.id);
          } else {
            results.push(member.createSnapshot());
          }
          // }
        });
      }

      if (ids) {
        snapshot._hasManyIds[keyName] = results;
      } else {
        snapshot._hasManyRelationships[keyName] = results;
      }

      return results;
    },
    normalize(typeClass, hash /*,prop*/) {
      hash.links = {
        contact: Ember.getOwner(this).lookup('adapter:contact').buildURL('contact', hash.contact_id, null, 'findRecord', null)
      };
      return this._super.apply(this, arguments);
    }
  });
});