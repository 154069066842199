define('knowme-client/mirage/config', ['exports', 'ember-cli-mirage', 'mock-socket', 'knowme-client/config/environment', 'lodash', 'knowme-client/mirage/data', 'ember-inflector', 'knowme-client/mirage/helpers/factory', 'knowme-client/mirage/helpers/json-web-token'], function (exports, _emberCliMirage, _mockSocket, _environment, _lodash, _data, _emberInflector, _factory, _jsonWebToken) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    let _this = this;
    _factory.factory.server = this;

    if (_environment.default.environment === "test") {
      window.WebSocket = WebSocket;
    }

    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    this.urlPrefix = `${scheme}://${host}`; // make this `http://localhost:8080`, for example, if your API is on a different server
    this.namespace = namespace; // make this `api`, for example, if your API is namespaced
    // this.logging = true;
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing
    this.passthrough('http://stream1.knowme.net/**');
    this.passthrough('https://cdn.plyr.io/2.0.11/**');

    // Enable routes to work with backend
    if (enableBackend) {
      this.passthrough('http://localhost:4200/**');
      this.passthrough([version, 'accounts'].join('/'), ['get', 'post']);
      this.passthrough([version, 'accounts', ':id'].join('/'), ['get', 'put']);
      this.passthrough([version, 'ads'].join('/'), ['get', 'post']);
      this.passthrough([version, 'ads', ':id'].join('/'), ['get', 'put']);
      this.passthrough([version, 'authentication_tokens'].join('/'), ['post']);
      this.passthrough([version, 'authentications'].join('/'), ['get']);
      this.passthrough([version, 'calls'].join('/'), ['get', 'post']);
      this.passthrough([version, 'calls', ':id'].join('/'), ['get', 'put']);
      this.passthrough([version, 'call_touch_points'].join('/'), ['get']);
      this.passthrough([version, 'call_touch_points', ':id'].join('/'), ['get']);
      this.passthrough([version, 'campaigns'].join('/'), ['get', 'post']);
      this.passthrough([version, 'campaigns', ':id'].join('/'), ['get', 'put']);
      this.passthrough([version, 'campaigns', 'summary'].join('/'), ['get']);
      this.passthrough([version, 'communications'].join('/'), ['get']);
      this.passthrough([version, 'communications', ':id'].join('/'), ['get', 'put']);
      this.passthrough([version, 'contacts'].join('/'), ['get', 'post']);
      this.passthrough([version, 'contacts', ':id'].join('/'), ['get', 'put']);
      this.passthrough([version, 'contract_agreements'].join('/'), ['get', 'post']);
      this.passthrough([version, 'contract_agreements', ':id'].join('/'), ['get']);
      this.passthrough([version, 'contract_versions'].join('/'), ['get']);
      this.passthrough([version, 'customers', 'summary'].join('/'), ['get']);
      this.passthrough([version, 'dids'].join('/'), ['get']);
      this.passthrough([version, 'events'].join('/'), ['get']);
      this.passthrough([version, 'leads', 'summary'].join('/'), ['get']);
      this.passthrough([version, 'leads'].join('/'), ['get', 'post']);
      this.passthrough([version, 'leads', ':id'].join('/'), ['get', 'put']);
      this.passthrough([version, 'leads', ':id', 'opp_history'].join('/'), ['get']);
      this.passthrough([version, 'leads', 'import'].join('/'), ['post']);
      this.passthrough([version, 'lead_activities'].join('/'), ['get']);
      this.passthrough([version, 'lead_activities', ':id'].join('/'), ['get']);
      this.passthrough([version, 'opportunities'].join('/'), ['get', 'post']);
      this.passthrough([version, 'opportunities', ':id'].join('/'), ['get', 'put']);
      this.passthrough([version, 'opportunities', ':id', 'history'].join('/'), ['get']);
      this.passthrough([version, 'orders'].join('/'), ['get']);
      this.passthrough([version, 'packages'].join('/'), ['get']);
      this.passthrough([version, 'plans'].join('/'), ['get']);
      this.passthrough([version, 'products'].join('/'), ['get', 'post']);
      this.passthrough([version, 'products', ':id'].join('/'), ['get', 'put', 'delete']);
      this.passthrough([version, 'reports', 'leads', 'by_time_of_day'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'customers', 'by_time_of_day'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'leads', 'by_day_of_week'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'customers', 'by_day_of_week'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'leads', 'by_sales_rep'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'customers', 'by_sales_rep'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'leads', 'pipeline'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'customers', 'pipeline'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'customers', 'revenue'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'campaigns', 'leads.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'campaigns', 'customers.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'campaigns', 'roi_summary.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'top_campaigns', 'leads.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'top_campaigns', 'customers.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'leads', 'by_time_of_day.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'customers', 'by_time_of_day.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'leads', 'by_day_of_week.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'customers', 'by_day_of_week.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'leads', 'by_sales_rep.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'customers', 'by_sales_rep.pdf'].join('/'), ['get']);
      this.passthrough([version, 'reports', 'opportunities.pdf'].join('/'), ['get']);
      this.passthrough([version, 'report_schedules'].join('/'), ['get', 'post']);
      this.passthrough([version, 'report_schedules', ':id'].join('/'), ['get', 'put', 'delete']);
      this.passthrough([version, 'roles'].join('/'), ['get']);
      this.passthrough([version, 'rooms'].join('/'), ['get']);
      this.passthrough([version, 'sales_actions'].join('/'), ['get', 'post']);
      this.passthrough([version, 'sales_actions', ':id'].join('/'), ['get', 'put', 'delete']);
      this.passthrough([version, 'sales_stages'].join('/'), ['get', 'post']);
      this.passthrough([version, 'sales_stages', ':id'].join('/'), ['get', 'put', 'delete']);
      this.passthrough([version, 'subscriptions', ':id'].join('/'), ['get']);
      this.passthrough([version, 'users'].join('/'), ['get', 'post']);
      this.passthrough([version, 'users', ':id'].join('/'), ['get', 'put', 'delete']);
    } else {
      // Session
      this.post([version, 'authentication_tokens'].join('/'), function (schema, request) {
        var attrs = JSON.parse(request.requestBody);
        var users = schema.users.where({ email: attrs.user.email, password: attrs.user.password });
        var response;
        if (isEmpty(users)) {
          response = new _emberCliMirage.default.Response(401, {}, { error: 'Unauthorized.' });
        } else {
          let user = users.models[0];
          let token = (0, _jsonWebToken.decode)(user);

          response = new _emberCliMirage.default.Response(200, {}, { token: token });
        }
        return response;
      });

      this.post([version, 'authentication_tokens', 'refresh'].join('/'), function (schema, request) {
        let params = JSON.parse(request.requestBody);
        let payload = params.token.split('.')[1];
        let data = JSON.parse(decodeURIComponent(window.escape(atob(payload))));
        let user = schema.users.find(data.id);

        let token = (0, _jsonWebToken.decode)(user);
        let response = new _emberCliMirage.default.Response(200, {}, { token: token });

        return response;
      });

      // Endpoints

      // Dashboard
      this.get([version, 'campaigns', 'summary'].join('/'), function () {
        return _data.summary.campaigns;
      });

      this.get([version, 'leads', 'summary'].join('/'), function () {
        return _data.summary.leads;
      });

      this.get([version, 'customers', 'summary'].join('/'), function () {
        return _data.summary.customers;
      });

      this.get([version, 'customer_experiences', 'summary'].join('/'), function () {
        return _data.summary.customerExperiences;
      });

      this.get([version, 'opportunities', 'summary'].join('/'), function () {
        return _data.summary.salesStages;
      });

      // Models for JSON Serialization
      A(['ad', 'call_touch_point', 'call', 'campaign', 'chat_touch_point', 'communication', 'contact', 'contract_agreements', 'contract_version', 'customer', 'dashboard_widget', 'did', 'event', 'lead', 'opportunity', 'order', 'packages', 'product', 'role', 'sales_action', 'sales_stage', 'users_dashboard_widget']).forEach(function (resourceName) {
        let pluralResourceName = (0, _emberInflector.pluralize)(resourceName);

        // JSON Serializer
        _this.get([version, pluralResourceName].join('/'), function (schema, request) {
          if (resourceName === "customer") {
            resourceName = 'contact';
          }
          var params = request.params;
          if (_environment.default.environment === "development") {
            console.log('Request params', params);
          }
          // var records = schema[pluralize(resourceName.camelize())].all().models;
          var records = schema[(0, _emberInflector.pluralize)(resourceName.camelize())].all();
          // filter
          var queryParams = request.queryParams;
          var where = {};
          _lodash.default.forEach(queryParams, function (value, key) {
            let skippedParams = ['query', 'sort_by', 'order', 'paginate', 'page', 'status', 'filter', 'ids', 'show_as', 'fields', 'calls[from_date]', 'calls[to_date]', 'short_messages[from_date]', 'short_messages[to_date]', 'short_messages[paginate]'];
            if (!skippedParams.includes(key) && isPresent(value)) {
              where[key.camelize()] = value;
            }
          });
          var resources = server.schema[(0, _emberInflector.pluralize)(resourceName.camelize())].where(where).models;

          if (resourceName === "contact") {
            if (queryParams.filter && queryParams.filter == 'with_inbound_calls') {
              resources = _lodash.default.filter(resources, function (resource) {
                return resource.callsCount > 0;
              });
            }
            if (queryParams.filter && queryParams.filter == 'with_short_messages') {
              resources = _lodash.default.filter(resources, function (resource) {
                return resource.shortMessagesCount > 0;
              });
            }
          }

          if (resourceName === "call") {
            where = {};
            if (queryParams.contact_id) {
              where.contactId = queryParams.contact_id;
            }
            if (queryParams.filter && queryParams.filter.length) {
              where.callType = queryParams.filter[0];
            }
            if (queryParams.user_id) {
              where.userId = queryParams.user_id;
            }
            resources = server.schema[(0, _emberInflector.pluralize)(resourceName.camelize())].where(where).models;
          }
          if (queryParams.ids) {
            resources = _lodash.default.filter(resources, function (resource) {
              return queryParams.ids.includes(resource.id);
            });
          }

          var query = queryParams.query;
          resources = query ? _lodash.default.filter(resources, function (resource) {
            return isPresent(resource.name) && resource.name.match(new RegExp(query, 'i'));
          }) : resources;
          // sort
          if (isPresent(queryParams.sort_by)) {
            let order = queryParams.order || 'desc';
            if (['createdAt', 'updatedAt'].includes(queryParams.sort_by)) {
              resources = _lodash.default.sortBy(resources, function (r) {
                return moment(r[queryParams.sort_by]);
              }, [order]);
            } else {
              resources = _lodash.default.sortBy(resources, [queryParams.sort_by], [order]);
            }
            if (order == 'desc') {
              records.models = _lodash.default.reverse(resources);
            } else {
              records.models = resources;
            }
          }
          // paginate
          var total, page, limit;
          total = records.length;

          if (queryParams.paginate === undefined || queryParams.paginate === "true") {
            page = queryParams.page ? parseInt(queryParams.page, 10) : 1;
            limit = queryParams.limit ? parseInt(queryParams.limit, 10) : 20;
            var offset = (page - 1) * limit;
            resources = resources.slice(offset, offset + limit);
            records.models = resources;
          } else {
            page = 1;
            limit = total;
          }
          // response
          var response = new _emberCliMirage.default.Response(200, { 'X-Total': total, 'X-Page': page, 'X-Per-Page': limit });
          // records.models = resources;
          response.data = this.serialize(records, resourceName);

          return response;
        });

        _this.get([version, pluralResourceName, ':id'].join('/'), function (schema, request) {
          if (_environment.default.environment === "development") {
            var params = request.params;
            console.log('Request params', params);
          }
          var resource = schema[(0, _emberInflector.pluralize)(resourceName.camelize())].find(request.params.id);
          var response = this.serialize(resource, resourceName); // serialize

          return response[resourceName] || response;
        });

        _this.post([version, pluralResourceName].join('/'), function (schema, request) {
          var queryParams;
          if (_environment.default.environment === "development") {
            queryParams = copy(request.queryParams);
            var requestBody = JSON.parse(request.requestBody);
            console.log('Request queryParams', queryParams);
            console.log('Request body', requestBody);
          }
          queryParams = _lodash.default.mapKeys(queryParams, function (value, key) {
            if (_lodash.default.includes(['campaign_id', 'lead_id'], key)) {
              return camelize(key);
            }
            return key;
          });

          let params = JSON.parse(request.requestBody);
          var instance = server.create(resourceName, _lodash.default.merge(params, { createdAt: new Date() }));
          var response = new _emberCliMirage.default.Response(200, {}, this.serialize(instance, resourceName));
          return response;
        });

        _this.put([version, pluralResourceName, ':id'].join('/'), function (schema, request) {
          // var params = request.params;
          let params = JSON.parse(request.requestBody);
          if (_environment.default.environment === "development") {
            var requestBody = JSON.parse(request.requestBody);
            console.log('Request params', params);
            console.log('Request body', requestBody);
          }
          var instance = schema[(0, _emberInflector.pluralize)(resourceName.camelize())].find(request.params.id);
          for (var key in params) {
            if (key.includes('_attributes') && isPresent(params[key])) {
              let relation = (0, _emberInflector.singularize)(key.replace('_attributes', ''));
              let attributes = params[key];
              attributes.forEach(function (attr) {
                attr[resourceName] = instance;
                server.create(relation, attr);
              }.bind(this));
              delete params[key];
            }
          }

          instance.update(_lodash.default.merge(params, { updatedAt: new Date() }));

          var response = new _emberCliMirage.default.Response(200, {}, instance);
          return response;
        });
      });

      // Models
      A(['account', 'account_setting', 'alert', 'authentication', 'chat_touch_point', 'column_setting', 'contact_field', 'did_allocation', 'incentive', 'lead_activity', 'notification', 'opportunity_field', 'organization_field', 'plan', 'review_link', 'room', 'short_message', 'subscription', 'user']).forEach(function (resourceName) {
        let pluralResourceName = (0, _emberInflector.pluralize)(resourceName);

        // JSON Serializer
        _this.get([version, pluralResourceName].join('/'), function (schema, request) {
          if (resourceName === "customer") {
            resourceName = 'contact';
          }
          var params = request.params;
          if (_environment.default.environment === "development") {
            console.log('Request params', params);
          }
          var records = schema[(0, _emberInflector.pluralize)(resourceName.camelize())].all();
          // filter
          var queryParams = request.queryParams;
          var query = queryParams.query;
          // var resources = query ? _.filter(records, function(resource) { return isPresent(resource.name) && resource.name.match(new RegExp(query, 'i')); }) : records;
          var resources = records.models;
          // sort
          // if(isPresent(queryParams.sort_by) && isPresent(queryParams.order)) {
          //   resources = _.sortBy(resources, [queryParams.sort_by], [queryParams.order]);
          // }
          // paginate
          if (resourceName === "short_message") {
            let where = {};
            if (queryParams.contact_id) {
              where.contactId = queryParams.contact_id;
            }
            if (queryParams.filter && queryParams.filter.length) {
              where.callType = queryParams.filter[0];
            }
            if (queryParams.user_id) {
              where.userId = queryParams.user_id;
            }
            resources = server.schema[(0, _emberInflector.pluralize)(resourceName.camelize())].where(where).models;
          }
          if (resourceName === "user") {
            if (queryParams.filter && queryParams.filter == 'with_outbound_calls') {
              resources = _lodash.default.filter(resources, function (resource) {
                return resource.callsCount > 0;
              });
            }
          }
          if (resourceName == 'lead_activity') {
            let where = {};
            if (queryParams.opportunity_id && !queryParams.filter) {
              where.opportunityId = queryParams.opportunity_id;
              resources = server.schema[(0, _emberInflector.pluralize)(resourceName.camelize())].where(where).models;
            } else {
              resources = server.schema[(0, _emberInflector.pluralize)(resourceName.camelize())].all().models;
            }
          }
          if (queryParams.ids) {
            resources = _lodash.default.filter(resources, function (resource) {
              return queryParams.ids.includes(resource.id);
            });
          }
          var total, page, limit;
          total = records.length;
          if (queryParams.paginate === undefined || queryParams.paginate === "true") {
            page = queryParams.page ? parseInt(queryParams.page, 10) : 1;
            limit = queryParams.limit ? parseInt(queryParams.limit, 10) : 20;
            var offset = (page - 1) * limit;
            resources = resources.slice(offset, offset + limit);
          } else {
            page = 1;
            limit = total;
          }
          // response
          var response = new _emberCliMirage.default.Response(200, { 'X-Total': total, 'X-Page': page, 'X-Per-Page': limit });
          records.models = resources;
          response.data = this.serialize(records, resourceName);
          return response;
        });

        _this.get([version, pluralResourceName, ':id'].join('/'), function (schema, request) {
          if (_environment.default.environment === "development") {
            var params = request.params;
            console.log('Request params', params);
          }
          var resource = schema[(0, _emberInflector.pluralize)(resourceName.camelize())].find(request.params.id);
          if (resourceName == 'user') {
            resource.update('isKnowmeIqRequired', resource.isKnowmeIqSupport && !resource.didAllocation);
          }
          var response = this.serialize(resource, resourceName); // serialize
          if (resourceName == 'user') {
            resource.update('isKnowmeIqRequired', resource.isKnowmeIqSupport && !resource.didAllocation);
            delete response.data.attributes.password;
          }
          return response;
        });

        _this.post([version, pluralResourceName].join('/'), function (schema, request) {
          if (_environment.default.environment === "development") {
            var params = request.params;
            console.log('Request params', params);
          }
          let attributes = JSON.parse(request.requestBody);

          var resource = schema[(0, _emberInflector.pluralize)(resourceName.camelize())].create(attributes);
          var response = this.serialize(resource, resourceName); // serialize
          return response;
        });

        _this.put([version, pluralResourceName, ':id'].join('/'), function (schema, request) {
          let attributes = JSON.parse(request.requestBody);
          if (resourceName == 'account') {
            attributes.customerTypeConfirmed = true;
          }

          var resource = schema[(0, _emberInflector.pluralize)(resourceName.camelize())].find(request.params.id);
          if (resourceName == 'user') {
            for (var key in attributes) {
              if (key.includes('_attributes') && isPresent(attributes[key])) {
                let relation = (0, _emberInflector.singularize)(key.replace('_attributes', ''));
                let relationAttributes = attributes[key];

                if (key == "users_dashboard_widgets_attributes") {
                  relationAttributes = [];
                }
                if (key == "did_allocation_attributes") {
                  if (!relationAttributes.did_id) {
                    let did = server.schema.dids.where({ number: relationAttributes.did_number }).models[0];
                    relationAttributes.did_id = did.id;
                    relationAttributes.did = did;
                  }
                }
                if (!Ember.isArray(relationAttributes)) {
                  relationAttributes = [relationAttributes];
                }

                relationAttributes.forEach(function (attr) {
                  attr[resourceName] = resource;
                  if (attr.id) {
                    //TODO: update
                  } else {
                    server.create(relation, attr);
                  }
                }.bind(this));
                delete attributes[key];
              }
            }
            attributes.is_knowme_iq_required = resource.isKnowmeIqSupport && !resource.didAllocation;
          }

          resource.update(attributes);
          var response = this.serialize(resource, resourceName); // serialize
          return response;
        });
      });

      this.get([version, 'leads', ':id', 'opp_history'].join('/'), function () {
        return [];
      });

      this.post([version, 'leads', 'import'].join('/'), function () {
        return [];
      });

      this.get([version, 'opportunities', ':id', 'history'].join('/'), function () {
        return [];
      });

      this.get([version, 'gmail', 'messages'].join('/'), function (schema, request) {
        let records = schema.messages.all();
        var queryParams = request.queryParams;
        if (isPresent(queryParams.type)) {
          switch (queryParams.type) {
            case 'draft':
              records = schema.messages.where({ isDraft: true, isJunk: false });
              break;
            case 'trash':
              records = schema.messages.where({ isDraft: false, isJunk: true });
              break;
            default:

          }
        } else {
          records = schema.messages.where({ isDraft: false, isJunk: false });
        }
        let resources = records.models;
        let total = records.length;
        let page = 1;
        let limit = total;
        let response = new _emberCliMirage.default.Response(200, { 'X-Total': total, 'X-Page': page, 'X-Per-Page': limit });
        records.models = resources;
        response.data = this.serialize(records, 'message');
        return response;
      });

      this.get([version, 'gmail', 'messages', ':id'].join('/'), function (schema, request) {
        let resource = schema.messages.find(request.params.id);
        var response = this.serialize(resource, 'message'); // serialize
        return response;
      });

      this.get([version, 'gmail', 'attachments'].join('/'), function (schema, request) {
        return { data: [] };
      });

      this.put([version, 'gmail', 'messages', ':id'].join('/'), function (schema, request) {
        let params = JSON.parse(request.requestBody);
        let attributes = {};
        attributes = _lodash.default.mapKeys(params, function (value, key) {
          return camelize(key);
        });
        let resource = schema.messages.find(request.params.id);
        resource.update(attributes);
        var response = this.serialize(resource, 'message'); // serialize
        return response;
      });
    }

    /*
      Route shorthand cheatsheet
    */
    /*
      GET shorthands
       // Collections
      this.get('/contacts');
      this.get('/contacts', 'users');
      this.get('/contacts', ['contacts', 'addresses']);
       // Single objects
      this.get('/contacts/:id');
      this.get('/contacts/:id', 'user');
      this.get('/contacts/:id', ['contact', 'addresses']);
    */

    /*
      POST shorthands
       this.post('/contacts');
      this.post('/contacts', 'user'); // specify the type of resource to be created
    */

    /*
      PUT shorthands
       this.put('/contacts/:id');
      this.put('/contacts/:id', 'user'); // specify the type of resource to be updated
    */

    /*
      DELETE shorthands
       this.del('/contacts/:id');
      this.del('/contacts/:id', 'user'); // specify the type of resource to be deleted
       // Single object + related resources. Make sure parent resource is first.
      this.del('/contacts/:id', ['contact', 'addresses']);
    */

    /*
      Function fallback. Manipulate data in the db via
         - db.{collection}
        - db.{collection}.find(id)
        - db.{collection}.where(query)
        - db.{collection}.update(target, attrs)
        - db.{collection}.remove(target)
       // Example: return a single object with related models
      this.get('/contacts/:id', function(db, request) {
        var contactId = +request.params.id;
         return {
          contact: db.contacts.find(contactId),
          addresses: db.addresses.where({contact_id: contactId})
        };
      });
     */
  };

  // import { adapters } from '@rails/actioncable';
  const { isEmpty, isPresent, copy, A } = Ember;
  const { camelize } = Ember.String;
  const { scheme, host, namespace, version, enableBackend } = _environment.default.APP.API;

  /*
  You can optionally export a config that is only loaded during tests
  export function testConfig() {
  
  }
  */
  if (_environment.default.environment === "test") {
    const mockServer = new _mockSocket.Server('ws://localhost:3000/cable');
    const mockServer1 = new _mockSocket.Server('ws://localhost:3002/chat-cable');

    // mockServer.on('connection', socket => {
    //   socket.on('message', (data) => {
    //     console.log(JSON.parse(data).command, JSON.parse(data).identifier);
    //     if (JSON.parse(data).command == 'subscribe') {
    //       // debugger
    //       socket.send(JSON.stringify({
    //         identifier: JSON.parse(data).identifier,
    //         type: "confirm_subscription"
    //       }));
    //     } else {
    //       // debugger
    //       socket.send(JSON.stringify({
    //         identifier: JSON.parse(data).identifier,
    //         message: {
    //           modelName: 'notification',
    //           payload: {
    //             data: {
    //               id: 1,
    //               type: 'notification',
    //               attributes: {
    //                 action: 'call'
    //               }
    //             }
    //           }
    //         }
    //       }));
    //     }
    //   });
    //   socket.on('close', () => {});
    //
    //
    //   // debugger
    //   // socket.close();
    // });

    // mockServer.clients(); // array of all connected clients
    // mockServer.emit('room', 'message');
    // mockServer.stop(optionalCallback);

    // mockServer1.on('connection', socket => {
    //   // debugger
    //   console.log('conn chat-cable');
    //   socket.on('message', () => {});
    //   socket.on('close', () => {});
    //
    //   // socket.send('message');
    //   // socket.close();
    // });
    //
    // mockServer1.clients(); // array of all connected clients
    // mockServer1.emit('room', 'message');
    // mockServer1.stop(optionalCallback);
  }
});