define('knowme-client/serializers/user', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    normalize(typeClass, hash /*, prop*/) {
      hash.relationships = hash.relationships || {};
      hash.relationships['devices'] = hash.relationships['devices'] || {};
      hash.relationships['did-allocation'] = hash.relationships['did-allocation'] || {};
      hash.relationships['last-call'] = hash.relationships['last-call'] || {};
      hash.relationships['account'] = hash.relationships['account'] || {};
      hash.relationships['authentications'] = hash.relationships['authentications'] || {};
      hash.relationships['users-dashboard-widgets'] = hash.relationships['users-dashboard-widgets'] || {};
      hash.relationships['devices'].links = hash.relationships['devices'].links || {};
      hash.relationships['did-allocation'].links = hash.relationships.links || {};
      hash.relationships['last-call'].links = hash.relationships.links || {};
      hash.relationships['account'].links = hash.relationships.links || {};
      hash.relationships['authentications'].links = hash.relationships.links || {};
      hash.relationships['users-dashboard-widgets'].links = hash.relationships.links || {};
      if (Ember.isPresent(hash.relationships['did-allocation'].data)) {
        hash.relationships['did-allocation'].links.related = Ember.getOwner(this).lookup('adapter:did-allocation').buildURL('didAllocation', hash.relationships['did-allocation'].data.id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.relationships['last-call'].data)) {
        hash.relationships['last-call'].links.related = Ember.getOwner(this).lookup('adapter:call').buildURL('call', hash.relationships['last-call'].data.id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.relationships['account'].data)) {
        hash.relationships['account'].links.related = Ember.getOwner(this).lookup('adapter:account').buildURL('account', hash.relationships['account'].data.id, null, 'findRecord', null);
      }
      hash.relationships['authentications'].links.related = Ember.getOwner(this).lookup('adapter:authentication').buildURL('authentication', null, null, 'findMany', { user_id: hash.id });
      if (Ember.isPresent(hash.relationships['devices'].data)) {
        hash.relationships['devices'].links.related = Ember.getOwner(this).lookup('adapter:device').buildURL('device', null, null, 'findMany', { owner_id: hash.id, paginate: false });
      }
      hash.relationships['users-dashboard-widgets'].links.related = Ember.getOwner(this).lookup('adapter:users-dashboard-widget').buildURL('users-dashboard-widget', null, null, 'findMany', { user_id: hash.id });
      return this._super.apply(this, arguments);
    }
  });
});