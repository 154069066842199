define('knowme-client/helpers/plan-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute([planKey = '']) {
      let intl = this.get('intl');
      let [productName, planName] = planKey.split('.');
      let name = 'Loading...';

      name = Ember.isPresent(planName) ? `${intl.t(`products.${productName}.name`)} ${intl.t(`products.${productName}.plans.${planName}.name`)}` : intl.t(`plans.keys.${productName}`);

      return name;
    }
  });
});