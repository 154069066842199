define('knowme-client/controllers/leads/show/opportunities/new', ['exports', 'ember-local-storage', 'knowme-client/mixins/controllers/email-integration', 'knowme-client/mixins/controllers/opportunity-fields'], function (exports, _emberLocalStorage, _emailIntegration, _opportunityFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowOpportunitiesNewController;

  LeadsShowOpportunitiesNewController = Ember.Controller.extend(_emailIntegration.default, _opportunityFields.default, {
    features: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    contactsController: Ember.inject.controller('leads.show.contacts'),
    isSaving: false,
    actions: {
      submit: function () {
        let controller = this;
        this.model.one('didCreate', function () {
          ['order', 'custom-attribute', 'opportunity'].forEach(function (modelName) {
            controller.get('store').peekAll(modelName).filter(function (_model) {
              return _model.get('isNew');
            }).invoke('deleteRecord');
          });
        });
        this.get('model').set('lead', this.get('lead'));
        this.set('isSaving', true);
        controller.model.save().then(function (opportunity) {
          controller.set('isSaving', false);
          if (opportunity.get('isClosed')) {
            controller.transitionToRoute('leads.show.opportunities', controller.get('lead'));
          } else {
            controller.transitionToRoute('leads.show.opportunities.show', controller.get('lead'), opportunity, { queryParams: { page: null } });
          }
        }).catch(function (reason) {
          controller.set('isSaving', false);
          console.log("Errors", reason);
        });
      },
      toggleContactsSelector: function () {
        this.set('displayContactSelector', !this.get('displayContactSelector'));
      },
      selectContact: function (contact) {
        this.set('contact', contact);
      }
    },
    lead: Ember.computed('model.lead', function () {
      return this.get('model.lead');
    }),
    contact: Ember.computed('model.lead', 'model.lead.primaryContact', function () {
      return this.get('model.lead.primaryContact');
    }),
    opportunity: Ember.computed('model', function () {
      return this.get('model');
    }),
    lastOpportunity: (0, _emberLocalStorage.storageFor)('last-opportunity'),
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.orders.@each.hasDirtyAttributes', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.orders').isAny('hasDirtyAttributes', true);
    }),
    disableSubmit: Ember.computed('isSaving', 'model.validations.isValid', 'isModelTreeDirty', function () {
      return this.get('isSaving') || this.get('model.validations.isInvalid') || !this.get('isModelTreeDirty');
    })
  });

  exports.default = LeadsShowOpportunitiesNewController;
});