define('knowme-client/controllers/account/settings/actions', ['exports', 'knowme-client/mixins/controllers/status', 'knowme-client/mixins/controllers/pagination'], function (exports, _status, _pagination) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_status.default, _pagination.default, {
		onStatusChanged: Ember.observer('status', function () {
			if (Ember.isPresent(this.get('page'))) {
				this.set('page', null);
			}
		}),
		actions: {
			destroy: function (resource) {
				resource.destroyRecord();
				this.transitionToRoute('account.settings.actions');
				this.get('target._routerMicrolib').refresh();
			}
		}
	});
});