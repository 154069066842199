define('knowme-client/mixins/routes/contact-phone-number-subscriber', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    cableService: Ember.inject.service('cable'),
    contactPhoneSubscription: null,
    contactPhoneConsumer: null,
    subscribeToContactPhoneChannel: function (leadId) {
      this.get('session').get('currentUser').then(function (currentUser) {
        this.createConsumer({ lead_id: leadId, user_id: currentUser.get('id') });
      }.bind(this));
    },
    createConsumer: function (data) {
      let path = `${_environment.default.APP.Websocket.protocol}://${_environment.default.APP.Websocket.host}/cable`;
      let consumer = this.get('cableService').createConsumer(path);
      let store = this.get('store');
      const subscription = consumer.subscriptions.create('ContactPhoneNumberChannel', {
        connected() {
          this.perform("follow", data);
        },
        received(resp) {
          let payload = resp.payload;
          if (typeof payload === 'string') {
            payload = JSON.parse(resp.payload);
          }
          Ember.run.later(function () {
            if (resp.isSimpleJSON) {
              let modelClass = store.modelFor(resp.modelName);
              let serializer = store.serializerFor(resp.modelName);
              let normalizedPayload = serializer.normalizeSingleResponse(store, modelClass, payload, null, true);
              store.push(normalizedPayload);
            } else {
              store.pushPayload(resp.modelName, payload);
            }
          }, 1000);
        },
        disconnected() {
          console.debug("disconnected");
        }
      });
      this.set('contactPhoneSubscription', subscription);
      this.set('contactPhoneConsumer', consumer);
    },
    unsubscribeFromContactPhoneChannel: function () {
      this.get('contactPhoneSubscription').unsubscribe();
      this.get('contactPhoneConsumer').destroy();
    }
  });
});