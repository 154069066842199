define('knowme-client/mirage/factories/plan', ['exports', 'faker', 'ember-cli-mirage', 'knowme-client/mirage/plan-settings'], function (exports, _faker, _emberCliMirage, _planSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    key: function () {
      return _faker.default.random.arrayElement(['free']);
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    settings: function () {
      return {
        servicePlanFee: null,
        numberOfAdditionalUsers: null,
        additionalUserFee: null,
        numberOfDids: null,
        storagePeruser: null,
        numberOfMultiRingDids: null,
        forwardToNumbersLimitForCallTouchPoints: null,
        numberOfTollFreeNumbers: null,
        numberOfLocalNumbers: null,
        numberOfUserMinutes: null,
        numberOfMarketingMinutes: null,
        numberOfUserShortMessages: null,
        numberOfMarketingShortMessages: null,
        billingPeriod: null,
        term: null,
        setupFee: null,
        percentageDiscount: null
      };
    },
    //settings
    starter: (0, _emberCliMirage.trait)({
      key: 'knowme_iq.starter',
      settings: function () {
        return _planSettings.planSettings['knowme_iq.starter'];
      }
    }),
    starter_unlimited: (0, _emberCliMirage.trait)({
      key: 'knowme_iq.starter_unlimited',
      settings: function () {
        return _planSettings.planSettings['knowme_iq.starter_unlimited'];
      }
    }),
    premium: (0, _emberCliMirage.trait)({
      key: 'knowme_iq.premium',
      settings: function () {
        return _planSettings.planSettings['knowme_iq.premium'];
      }
    }),
    premium_unlimited: (0, _emberCliMirage.trait)({
      key: 'knowme_iq.premium_unlimited',
      settings: function () {
        return _planSettings.planSettings['knowme_iq.premium_unlimited'];
      }
    })
  });
});