define('knowme-client/mixins/models/validations/alert', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		key: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })],
		recipients: [(0, _emberCpValidations.validator)('presence', {
			presence: true,
			ignoreBlank: true,
			dependentKeys: ['model.user.id', 'model.recipientsEmails'],
			disabled: Ember.computed('model.user.id', 'model.recipientsEmails', function () {
				return Ember.isPresent(this.get('model.user.id')) || Ember.isPresent(this.get('model.recipientsEmails'));
			})
		})],
		recipientsEmails: [(0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			if (Ember.isPresent(model.get('user.id'))) {
				return true;
			}
			let valid = true;
			let recipientsEmails = [];
			if (Ember.isPresent(value)) {
				recipientsEmails = value.split(',').map(function (x) {
					return x.trim();
				});
			} else {
				return false;
			}
			recipientsEmails.forEach(function (email) {
				if (Ember.isPresent(email) && !email.match(/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i)) {
					valid = false;
				}
			});
			return valid;
		}, {
			dependentKeys: ['model.user.id']
		})],
		campaign: [(0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			return Ember.isPresent(model.get('campaign.id')) ? true : "";
		}, {
			dependentKeys: ['model.campaign.id', 'model.allCampaigns', 'model.user.id'],
			disabled: Ember.computed('model.allCampaigns', 'model.user.id', function () {
				return this.get('model.allCampaigns') || Ember.isPresent(this.get('model.user.id'));
			})
		})],
		ad: [(0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			return Ember.isPresent(model.get('ad.id')) ? true : "";
		}, {
			dependentKeys: ['model.ad.id', 'model.allAds', 'model.user.id'],
			disabled: Ember.computed('model.allAds', 'model.user.id', function () {
				return this.get('model.allAds') || Ember.isPresent(this.get('model.user.id'));
			})
		})],
		user: [(0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			return Ember.isPresent(model.get('user.id')) ? true : "";
		}, {
			dependentKeys: ['model.user.id', 'model.campaign.id', 'model.allCampaigns', 'model.ad.id', 'model.allAds'],
			disabled: Ember.computed('model.campaign.id', 'model.allCampaigns', 'model.ad.id', 'model.allAds', function () {
				return Ember.isPresent(this.get('model.campaign.id')) || this.get('model.allCampaigns') || Ember.isPresent(this.get('model.ad.id')) || this.get('model.allAds');
			})
		}), (0, _emberCpValidations.validator)('ds-error')]
	});
});