define('knowme-client/routes/solutions/dentists', ['exports', 'knowme-client/mixins/routes/unauthenticated-mixin', 'knowme-client/mixins/products'], function (exports, _unauthenticatedMixin, _products) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  let ProductsRoute;

  ProductsRoute = Ember.Route.extend(_unauthenticatedMixin.default, _products.default, {
    model: function () {
      return this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc' });
    },
    afterModel: function (plans) {
      plans.forEach(function (plan) {
        let parts = plan.get('key').split('.');
        let productKey = parts[0];
        let planKey = parts[1];

        let product = this.get('products').find(function (product) {
          return product.key === productKey;
        });
        if (Ember.isPresent(product) && Ember.isPresent(planKey)) {
          delete product.settings[planKey].annually;
          delete product.settings[planKey].monthly;

          Ember.assign(product.settings[planKey], plan.get('settings'));
        }
      }.bind(this));
    },
    renderTemplate: function (_controller, model) {
      var controller = this.controllerFor('products.show');
      controller.set('model', model);
      controller.set('currentIndustry', 'dental');
      let currentProduct = this.get('products').findBy('slug', 'knowme_sales_marketing_and_feedback');
      controller.set('currentProduct', currentProduct);
      controller.set('hideProductsBoxes', true);
      controller.send('openSettings', currentProduct);
      this.render('products/show', {
        controller: controller
      });
    }
  });

  exports.default = ProductsRoute;
});