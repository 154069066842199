define('knowme-client/router', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('reviews.new', { path: "/reviews/:id" });
    this.route('incentives.new', { path: "/incentives/:id" });
    this.route('feedback', { path: "/feedback/:id" });
    this.route('quote', { path: "/quote/:id" });
    this.route('checkout', function () {
      this.route('success', { path: "/success/:invoice_id" });
      this.route('canceled');
    });
    this.route('dashboard');
    this.route('roles');
    this.route('profile');

    this.route('promo-codes', { path: 'promo-codes' }, function () {
      this.route('new');
      this.route('edit', { path: "/:id/edit" });
    });
    // By adding a function as a second or third parameter to a route, it creates an index function, so for campaigns show page, there will be a route campaigns.show.index
    this.route('account', { path: '/admin' }, function () {
      this.route('users', function () {
        this.route('new');
        this.route('edit', { path: "/:id/edit" });
      });
      this.route('clients', function () {
        this.route('new');
        this.route('edit', { path: "/:id/edit" });
      });
      this.route('settings', function () {
        this.route('alerts', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
        });
        this.route('appointment-reminders', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
        });
        this.route('products', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
        });
        this.route('actions', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
        });
        this.route('incentives', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
          this.route('show', { path: "/:id" });
        });
        this.route('reviews', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
          this.route('show', { path: "/:id" });
        });
        this.route('stages', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
        });
        this.route('opportunity-fields', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
        });
        this.route('contact-fields', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
        });
        this.route('organization-fields', function () {
          this.route('new');
          this.route('edit', { path: "/:id/edit" });
        });
      });
    });

    this.route('campaigns');
    this.route('campaigns.new', { path: "/campaigns/new" });
    this.route('campaign', { path: "/campaigns/:id" }, function () {
      this.route('ads', function () {
        this.route('new');
        this.route('edit', { path: "/:ad_id/edit" });
      });
    });

    this.route('sales-stages');
    this.route('customer-experience');

    this.route('user-leads', { path: 'user_leads' });
    this.route('user-customers', { path: 'user_customers' });
    this.route('user-activities', { path: 'user_activities' });
    this.route('leads', function () {});
    this.route('leads.new', { path: '/leads/new' });
    this.route('leads.show', { path: "/leads/:id" }, function () {
      this.route('edit');
      this.route('contacts', function () {
        this.route('new');
        this.route('edit', { path: "/:contact_id/edit" });
      });

      this.route('opportunities', { path: 'opportunities' }, function () {});
      this.route('opportunities.new', { path: 'opportunities/new' });
      this.route('opportunities.show', { path: "opportunities/:opportunity_id" });
    });

    this.route('invoices', function () {
      this.route('show', { path: "/:id" });
    });

    this.route('calls');
    this.route('short-messages', { path: 'texts' });
    this.route('chat');
    this.route('chats', function () {
      this.route('new');
      // this.route('edit', {path: "/:id/edit"});
      this.route('show', { path: "/:id" });
    });

    this.route('reports', function () {
      this.route('scheduler', function () {
        this.route('new');
        this.route('edit', { path: "/:id/edit" });
      });
    });

    this.route('support');
    this.route('login');
    this.route('agreement');
    this.route('privacy');
    this.route('terms');
    this.route('about');
    this.route('contact');
    this.route('contact', { path: '/contact/help' });
    this.route('contact', { path: '/contact/form' });

    this.route('five_products_solutions', { path: '/5products_solutions' });
    this.route('solutions', { path: '/solutions' }, function () {
      this.route('mobile', { path: '/mobile' });
      this.route('dentists', { path: '/dentists' });
      this.route('gmail-integration', { path: '/gmail-integration' });
    });

    this.route('five_products', { path: '/5products_pricing' });
    this.route('knowme-iq-product', { path: '/knowme_iq_pricing' });
    this.route('products', { path: '/pricing' }, function () {
      this.route('show', { path: '/:product_slug' });
    });
    this.route('signup');
    this.route('congratulations');
    this.route('emails', function () {
      this.route('show', { path: "/:email_id" });
    });
    this.route('email-templates', function () {
      this.route('new');
      this.route('edit', { path: "/:id/edit" });
    });
    this.route('wizard', function () {
      this.route('client', { path: 'client' });
      this.route('profile');
      this.route('products');
      this.route('users');
      this.route('campaigns');
    });
    this.route('communications');
  });

  exports.default = Router;
});