define('knowme-client/components/products/product-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    promoCode: null,
    promoCodeLength: 8,
    servicePlans: [{ text: 'Basic', value: 'basic' }, { text: 'Standard', value: 'standard' }, { text: 'Elite', value: 'elite' }, { text: 'Premium', value: 'premium' }],
    accountSettings: ['account.billing_term', 'additional.users', 'additional.users.dids.local', 'additional.minutes', 'additional.short_messages', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local', 'additional.contacts'],
    transitioningToSignUpForm: false,
    initSettings() {
      this._super(...arguments);
      let _this = this;
      this.accountSettings.forEach(function (key) {
        let _key = key.camelize().capitalize();
        if (key === 'account.billing_term') {
          _this.set(`settings${_key}`, _this.get('store').createRecord('AccountSetting', { key: key, value: _this.get('interval') === 'annually' ? 12 : 1 }));
        } else {
          _this.set(`settings${_key}`, _this.get('store').createRecord('AccountSetting', { key: key, value: 0 }));
        }
        if (key !== 'account.billing_term') {
          var settingKey = key;
          Ember.defineProperty(_this, `settings${_key}Cost`, Ember.computed('currentProduct.key', 'currentPlan', `settings${_key}.value`, 'promoCode', () => {
            if (_this.get('isCurrentProductBasicCRM') && ['additional.users.dids.local', 'additional.minutes', 'additional.short_messages', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local'].includes(settingKey)) {
              return 0;
            }
            var blockUnit = 1,
                unitPrice;
            if (Ember.isPresent(_this.get('planSettings')[_key.camelize() + 'Block'])) {
              blockUnit = _this.get('planSettings')[_key.camelize() + 'Block'];
              unitPrice = _this.get('planSettings')[_key.camelize() + 'BlockFee'];
            } else {
              if (['additional.users.dids.local', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local'].includes(settingKey)) {
                unitPrice = _this.get('planSettings')['additionalDidsFee'];
              } else {
                unitPrice = _this.get('planSettings')[_key.camelize() + 'Fee'];
              }
            }

            return parseInt(_this.get(`settings${_key}.value`), 10) / blockUnit * unitPrice;
          }));
        }
      });
      // let settingsDependendProperties = this.accountSettings.without('account.billing_term').map(function(accountSetting) {
      //   return `settings${accountSetting.camelize().capitalize()}Cost`;
      // });
      // let computedDependentProperties = settingsDependendProperties.concat(['selectedPlan.key', 'planSettings.planFee', 'interval']);
      // defineProperty(_this, 'totalFee', computed(...computedDependentProperties, function() {
      //   let costs = this.accountSettings.without('account.billing_term').reduce(function(previousValue, item) {
      //     let _key = item.camelize().capitalize();
      //     return previousValue + _this.get(`settings${_key}Cost`);
      //   }, 0);
      //   return this.get('planSettings').planFee + costs;
      // }));
    },
    extraIncludedCallTouchPointsDidsLocal: Ember.computed('product.key', 'settingsAdditionalUsers.value', function () {
      if (this.get('product.key') !== 'knowme_iq' || this.get('settingsAdditionalUsers.value') === 0) {
        return 0;
      }
      return this.get('settingsAdditionalUsers.value');
    }),
    extraIncludedUsers: Ember.computed('product.key', 'settingsAdditionalUsers.value', function () {
      return 0;
    }),
    extraIncludedUsersDidsLocal: Ember.computed('product.key', 'settingsAdditionalUsers.value', function () {
      if (this.get('product.key') !== 'knowme_iq' || this.get('settingsAdditionalUsers.value') === 0) {
        return 0;
      }
      return this.get('settingsAdditionalUsers.value');
    }),
    extraIncludedMinutes: Ember.computed('product.key', 'selectedPlan.planKey', 'settingsAdditionalUsers.value', function () {
      if (this.get('product.key') !== 'knowme_iq' || this.get('settingsAdditionalUsers.value') === 0) {
        return 0;
      }
      let perBlock = this.get(`product.settings.${this.get('selectedPlan.planKey')}.${this.get('interval')}.additionalMinutesBlock`);
      return perBlock * this.get('settingsAdditionalUsers.value');
    }),
    extraIncludedShortMessages: Ember.computed('product.key', 'selectedPlan.planKey', 'settingsAdditionalUsers.value', function () {
      if (this.get('product.key') !== 'knowme_iq' || this.get('settingsAdditionalUsers.value') === 0) {
        return 0;
      }
      let perBlock = this.get(`product.settings.${this.get('selectedPlan.planKey')}.${this.get('interval')}.additionalShortMessagesBlock`);
      return perBlock * 2 * this.get('settingsAdditionalUsers.value');
    }),
    extraIncludedContacts: Ember.computed('product.key', 'selectedPlan.planKey', 'settingsAdditionalUsers.value', function () {
      if (!['knowme_iq', 'basic_crm'].includes(this.get('product.key')) || this.get('settingsAdditionalUsers.value') === 0) {
        return 0;
      }
      let numOfBlocks = this.get('selectedPlan.planKey') === 'basic' && this.get('product.key') === 'knowme_iq' ? 2 : 1;
      let perBlock = this.get(`product.settings.${this.get('selectedPlan.planKey')}.${this.get('interval')}.additionalContactsBlock`);
      if (this.get('product.key')) {
        perBlock = this.get(`product.settings.${this.get('selectedPlan.planKey')}.${this.get('interval')}.includedContacts`);
      }
      return perBlock * numOfBlocks * this.get('settingsAdditionalUsers.value');
    }),
    subscriptionFee: Ember.computed("currentProduct.key", "currentPlan", "interval", "settingsAdditionalUsersCost", "settingsAdditionalUsersDidsLocalCost", "settingsAdditionalMinutesCost", "settingsAdditionalShortMessagesCost", "settingsAdditionalCallTouchPointsDidsTollFreeCost", "settingsAdditionalCallTouchPointsDidsLocalCost", "settingsAdditionalContactsCost", "planSettings.planFee", "promoCode", function () {
      let total = ["settingsAdditionalUsersCost", "settingsAdditionalUsersDidsLocalCost", "settingsAdditionalMinutesCost", "settingsAdditionalShortMessagesCost", "settingsAdditionalCallTouchPointsDidsTollFreeCost", "settingsAdditionalCallTouchPointsDidsLocalCost", "settingsAdditionalContactsCost", "planSettings.planFee"].reduce(function (previousValue, item) {
        if (item === 'planSettings.planFee' && Ember.isPresent(this.get('promoCode'))) {
          let withDiscount = this.get(item) - this.get(item) * this.get('promoCode.percentOff') / 100;
          return previousValue + withDiscount;
        } else {
          return previousValue + this.get(item);
        }
      }.bind(this), 0);

      return total;
    }),
    selectedPlan: Ember.computed('currentProduct.key', 'currentPlan', function () {
      return this.get('plans').findBy('key', `${this.get('currentProduct.key')}.${this.get('currentPlan')}`);
    }),
    planSettings: Ember.computed('selectedPlan.key', 'interval', 'settingsAdditionalUsers.value', 'promoCode', function () {
      if (this.get('selectedPlan.productKey') === "knowme_iq") {
        let settings = (this.get('selectedPlan.settings') || {})[this.get('interval')];
        let currentFee = settings.planFee;
        // apply discount for additional users fee
        if (Ember.isBlank(this.get('promoCode')) || this.get('promoCode.percentOff') < 15) {
          for (let discount in settings.additionalUsersDiscounts) {
            if (this.get('settingsAdditionalUsers.value') + settings.includedUsers >= settings.additionalUsersDiscounts[discount].number) {
              if (currentFee > settings.additionalUsersDiscounts[discount].fee) {
                currentFee = settings.additionalUsersDiscounts[discount].fee;
              }
            }
          }
        }
        // apply promo code for additional users fee
        if (Ember.isPresent(this.get('promoCode'))) {
          currentFee = currentFee - currentFee * this.get('promoCode.percentOff') / 100;
        }

        Ember.set(settings, 'additionalUsersFee', currentFee);

        return settings;
      } else {
        return (this.get('selectedPlan.settings') || {})[this.get('interval')];
      }
    }),
    onPlanChanged: Ember.observer('currentPlan', function () {
      this.set('promoCode', null);
      this.set('promoCodeId', null);
    }),
    features: {
      mainTools: [{
        title: "Included users",
        key: 'includedUsers',
        type: 'integer'
      }, {
        title: 'Included Knowme IQ number(s)',
        tooltipLabel: '?',
        tooltipMessage: 'Knowme IQ number acts like user\'s business mobile number. User can make and receive calls and texts from Knowme\'s Web and Mobile app and have them automatically logged in. When sending texts or making calls from Knowme\'s Web and Mobile app it shows as user\'s caller id.',
        key: 'includedUsersDidsLocal',
        type: 'integer'
      }, {
        title: "Included Local phone numbers for marketing",
        tooltipLabel: '?',
        tooltipMessage: 'With each user Client gets one local phone number, to be provisioned under Campaigns. It can be used in ads to capture leads and track marketing effectiveness.',
        key: 'includedCallTouchPointsDidsLocal',
        type: 'integer'
      }, {
        title: "Included minutes/month",
        key: 'includedMinutes',
        type: 'integer'
      }, {
        title: "Included text messages/month",
        key: 'includedShortMessages',
        type: 'integer'
      }, {
        title: "Included contacts",
        key: 'includedContacts',
        type: 'integer'
      }, {
        title: "<a class='blue-link hover-underline' href='/solutions/gmail-integration'>Gmail or G-Suite</a> and Outlook integration",
        key: 'emailsIntegration',
        type: 'boolean'
      }, {
        title: "Business Text messaging",
        key: 'textMessagingPlatform',
        type: 'boolean'
      }, {
        title: "E-mail templates",
        key: 'emailTemplates',
        type: 'boolean'
      }, {
        title: "Chat",
        key: 'knowmeChat',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'Chat, send files, videos and voice recordings to one user or group of users in your account.'
      }, {
        title: "Knowme IQ iOS App. (Coming soon)",
        key: 'mobileApp',
        type: 'tooltip',
        value: 'When Knowme IQ – iOS App. is released each user will have an access to it. Users will be able to interact with their business contacts from Knowme iOS App.'
      }, {
        title: "CRM (<span class='hint--top' data-hint='Displays leads &amp; customers as individuals (e.g. Boris Smith)'><abbr title=''>B2C</abbr></span> or <span class='hint--top' data-hint='Displays leads &amp; customers as organizations (e.g. Knowme Inc)'><abbr title=''>B2B</abbr></span>)",
        key: 'crm',
        type: 'boolean'
      }, {
        title: "Customer Experience",
        tooltipLabel: '?',
        tooltipMessage: 'Capture Customer Experience in real time of the phone call, text and email or in person. Get real time alerts of happy and unhappy customers.',
        key: 'customerExperience',
        type: 'boolean'
      }, {
        title: "Call tracking",
        key: 'callTracking',
        type: 'boolean'
      }],
      salesTools: [{
        title: "Leads and customers import/export",
        key: 'csvImport',
        type: 'boolean'
      }, {
        title: "Multiple Dashboards",
        key: 'multipleDashboards',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'Organize dashboards so they best fit your needs, see vital data and quickly get to detailed view.'
      }, {
        title: "Tasks, opportunities, pipeline management",
        key: 'tasksOpportunitiesPipelineManagement',
        type: 'boolean'
      }, {
        title: "Multiple opportunities pipeline",
        key: 'multipleOpportunitiesPipeline',
        type: 'boolean'
      }, {
        title: "Appointments, To do and Past Due activities",
        key: 'appointmentsToDoAndPastDueActivities',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'Easily see Appointments and Opportunities that need immediate action and those that have been not attended to in required time.'
      }, {
        title: "Communication tracking per opportunity, automated",
        key: 'automatedCommunicationTrackingPerOpportunity',
        type: 'boolean'
      }, {
        title: "Automated Leads capture",
        key: 'automatedLeadCapture',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: "Knowme IQ automatically captures contact information when prospect calls, texts, fills a Web form, responds to a survey or claims an incentive."
      }, {
        title: "Revenue and ROI tracking",
        key: 'revenueTracking',
        type: 'boolean'
      }, {
        title: "Appointment reminders",
        key: 'appointmentReminders',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'Get Email and/or Text notification of your scheduled appointments'
      }, {
        title: "Dialer and Telesales",
        key: 'dialerAndTelesales',
        type: 'boolean'
      }, {
        title: "Integrated inbound and outbound calling",
        key: 'callsIntegration',
        type: 'boolean'
      }, {
        title: "Integrated inbound and outbound texting",
        key: 'textsIntegration',
        type: 'boolean'
      }, {
        title: "Inbound call recording",
        key: 'inboundCallRecording',
        type: 'boolean'
      }, {
        title: "Company, user management and insights",
        key: 'companyAndContactInsights',
        type: 'boolean'
      }, {
        title: "Data mapping",
        key: 'dataMapping',
        type: 'boolean'
      }, {
        title: "Products and Services",
        key: 'accountSettings',
        type: 'boolean'
      }, {
        title: "Leads scoring",
        key: 'leadsScoring',
        type: 'boolean'
      }, {
        title: "Contact management and insights",
        key: 'contactManagment',
        type: 'boolean'
      }, {
        title: "Reports (real time and scheduled delivery of reports)",
        key: 'reports',
        type: 'boolean'
      }],
      marketingTools: [{
        title: "Multi-Ring numbers  ",
        key: 'includedCallTouchPointsMultiRing',
        tooltipLabel: '?',
        tooltipMessage: 'Multi-ring number can be any of your available numbers. Admin and Managers can set it to ring to more than 1 phone number at the same time.',
        type: 'boolean'
      }, {
        title: "Purchase and provision toll free numbers (TFNs) in real time",
        key: 'provisionAndPurchasePhoneNumbers',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'If you want to use Toll free numbers in your marketing, you can purchase them on Sign-up page or under Upgrades in your account.'
      }, {
        title: "Provision and purchase Local phone numbers in real time",
        key: 'provisionAndPurchasePhoneNumbers',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'If you want to purchase additional Local phone numbers for use in marketing, you can purchase them on Sign-up page or under Upgrades in your account.'
      }, {
        title: "Unique e-mail addresses for Webform lead capture",
        key: 'webformLeadCapture',
        type: 'boolean'
      }, {
        title: "Referral Marketing and Incentives",
        key: 'dynamicInsertionNumbers',
        type: 'boolean'
      }, {
        title: "Campaigns and ads response capture, automated",
        key: 'campaignsWithAutomatedLeadSource',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'Knowme IQ automatically captures contact information when prospect calls, texts, fills a Web form, responds to a survey or claims an incentive.'
      }, {
        title: "Offline and Online ad(s) and Campaign(s) attribution",
        key: 'attributionPerAd',
        type: 'boolean'
      }, {
        title: "Automated cost per lead calculation",
        key: 'costLeadCalculation',
        type: 'boolean'
      }, {
        title: "Campaigns and ads ROI calculation",
        key: 'roiCalculation',
        type: 'boolean'
      }],
      customerSatisfactionTools: [{
        title: "Request customer's satisfaction via phone, text or e-mail",
        key: 'customersSatisfactionScore',
        type: 'boolean'
      }, {
        title: "Capture customer's satisfaction, in real-time, via phone, text or e-mail",
        key: 'customersSatisfactionScore',
        type: 'boolean'
      }, {
        title: "Instant unhappy customers notifications (save deals and reputation)",
        key: 'instantUnhappyCustomers',
        type: 'boolean'
      }, {
        title: "Instant happy customers notifications (get more positive reviews and sales)",
        key: 'instantHappyCustomers',
        type: 'boolean'
      }, {
        title: "Customer experience improvement or regression tracking",
        key: 'improvementOrRegressionTracking',
        type: 'boolean'
      }, {
        title: "View entire customer's satisfaction history",
        key: 'customersSatisfactionHistory',
        type: 'boolean'
      }, {
        title: "Anonymous feedback survey",
        key: 'anonimousFeedbackSurvey',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'Present contacts an option to provide feedback and remain anonymous (name or phone numbers are masked)'
      }, {
        title: "After hours answering service replacement",
        key: 'answeringServiceReplacement',
        type: 'boolean',
        tooltipLabel: '?',
        tooltipMessage: 'Identify after hours calls on user\'s mobile phone before answering. Receive them, with recording, via e-mail as soon as they are completed.'
      }, {
        title: "Referral sources discovery",
        key: 'referralSourcesDiscovery',
        type: 'boolean'
      }],
      addOns: [{
        title: "Block of additional 200 minutes",
        key: 'additionalMinutes',
        type: 'currency'
      }, {
        title: "Block of additional 200 text messages",
        key: 'additionalTexts',
        type: 'currency'
      }, {
        title: "Additional 1000 contacts",
        key: 'additional1000Contacts',
        type: 'currency'
      }, {
        title: "Additional user fee (includes 300 contacts)",
        key: 'additionalUserFee',
        type: 'currency'
      }]
    },
    addOnsSections: [{
      key: 'usersCount',
      section: 'isAdditionalUserOpened'
    }, {
      key: 'minutesCount',
      section: 'isAdditionalMinutesOpened'
    }, {
      key: 'textMessagesCount',
      section: 'isAdditionalMessagesOpened'
    }, {
      key: 'localNumbersCount',
      section: 'isAdditionalNumbersOpened'
    }, {
      key: 'tollNumbersCount',
      section: 'isAdditionalTollNumbersOpened'
    }, {
      key: 'contactsCount',
      section: 'isAdditionalContactsOpened'
    }],
    salesTools: true,
    marketingTools: false,
    customerSatisfactionTools: false,
    didReceiveAttrs() {
      this._super(...arguments);
      this.initSettings();
      if (this.get('product.key') === 'marketing_optimizer') {
        this.set('salesTools', false);
        this.set('marketingTools', true);
        this.set('customerSatisfactionTools', false);
      }
      if (this.get('product.key') === 'customer_satisfaction') {
        this.set('salesTools', false);
        this.set('marketingTools', false);
        this.set('customerSatisfactionTools', true);
      }
      if (Ember.isPresent(this.get('product.settings.elite'))) {
        this.set('currentPlan', 'elite');
      } else {
        this.set('currentPlan', 'basic');
      }
      this.get('addOnsSections').forEach(function (item) {
        if (Ember.isPresent(this.get(`addOns.${item.key}`)) && this.get(`addOns.${item.key}`) > 0) {
          this.set(item.section, true);
        }
      }.bind(this));
    },
    willDestroyElement() {
      if (!this.get('transitioningToSignUpForm')) {
        this.accountSettings.forEach(function (key) {
          var _key = key.camelize().capitalize();
          this.get('settings' + _key).rollbackAttributes();
        }.bind(this));
      }
    },
    isCurrentProductBasicCRM: Ember.computed('product.key', function () {
      return this.get('product.key') === "basic_crm";
    }),
    displayNumbersAddOns: Ember.computed('product', function () {
      return ['marketing_optimizer_crm', 'customer_satisfaction', 'sales_accelerator_marketing_optimizer_crm', 'knowme_iq'].includes(this.get('product.key'));
    }),
    displayMarketingTools: Ember.computed('product', function () {
      return ['marketing_optimizer_crm', 'customer_satisfaction', 'sales_accelerator_marketing_optimizer_crm', 'knowme_iq'].includes(this.get('product.key'));
    }),
    displayCustomerSatisfactionTools: Ember.computed('product', function () {
      return ['customer_satisfaction', 'sales_accelerator_marketing_optimizer_crm', 'knowme_iq'].includes(this.get('product.key'));
    }),
    actions: {
      toggleSection: function (sectionName) {
        ['salesTools', 'marketingTools', 'customerSatisfactionTools'].forEach(function (key) {
          if (key !== sectionName) {
            this.set(key, false);
          }
        }.bind(this));
        this.set(sectionName, !this.get(sectionName));
      },
      toggleInteval: function () {
        if (this.get('interval') === 'annually') {
          this.set('interval', 'monthly');
        } else {
          this.set('interval', 'annually');
        }
      },
      applyPromoCode: function (promoCodeId) {
        if (Ember.isPresent(promoCodeId)) {
          let controller = this;
          this.get('store').query('promo-code', { coupon_id: promoCodeId, plan_id: this.get('selectedPlan.id'), billing_period: this.get('interval') }).then(function (promoCodes) {
            if (promoCodes.get('length') > 0) {
              controller.set('promoCode', promoCodes.get('firstObject'));
            } else {
              controller.get('flashMessages').warning(`Promo Code ${promoCodeId} does not exist`, { destroyOnClick: true, timeout: 12000 });
            }
          }, function (resp) {
            controller.get('flashMessages').warning(resp.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
          });
        }
      },
      openSignUpForm: function (plan) {
        if (this.get('currentPlan') === plan) {
          let signupData = {
            currentIndustry: this.get('currentIndustry'),
            interval: this.get('interval'),
            plan: this.get('selectedPlan'),
            promoCode: this.get('promoCode')
          };
          this.accountSettings.forEach(function (key) {
            let _key = key.camelize().capitalize();
            signupData[`settings${_key}`] = this.get(`settings${_key}`);
          }.bind(this));
          this.set('transitioningToSignUpForm', true);
          this.sendAction('setSignupData', signupData);
          this.get('routing').transitionTo('signup').then(newRoute => {
            window.scrollTo(0, 0);
          });
        }
      }
    }
  });
});