define('knowme-client/mixins/models/validations/contact-address', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		street: [(0, _emberCpValidations.validator)('presence', {
			presence: true,
			ignoreBlank: true,
			dependentKeys: ['model.isDeleted'],
			disabled: Ember.computed.bool('model.isDeleted')
		}), (0, _emberCpValidations.validator)('length', { max: 75 })],
		city: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		state: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		country: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })
	});
});