define('knowme-client/adapters/contact-phone-number', ['exports', 'knowme-client/adapters/application', 'knowme-client/mixins/adapters/api-key-header'], function (exports, _application, _apiKeyHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_apiKeyHeader.default, {
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?query*,view_as,paginate}',
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      paginate: function (type, id, snapshot, query) {
        if (Ember.isArray(id) || Ember.isPresent(query.contact_info_id)) {
          return false;
        }
        return true;
      }
    },
    findRecord: function (store, type, id, snapshot) {
      let adapterOptions = Ember.get(snapshot, 'adapterOptions');
      if (adapterOptions && adapterOptions.opportunity_id) {
        let url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
        let query = {
          opportunity_id: adapterOptions.opportunity_id
        };
        return this.ajax(url, 'GET', { data: query });
      } else {
        return this._super(...arguments);
      }
    }
  });
});