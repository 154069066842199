define('knowme-client/models/subscription', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/validations/subscription', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _relationships, _subscription, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_subscription.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    account: (0, _relationships.belongsTo)('account', { async: true, inverse: 'subscription' }),
    plan: (0, _relationships.belongsTo)('plan', { async: true, inverse: 'subscription' }),
    promoCode: (0, _relationships.belongsTo)('promo-code', { async: true }),
    details: (0, _attr.default)('json'),
    expiresAt: (0, _attr.default)('date'),
    subscriptionId: (0, _attr.default)(),
    status: (0, _attr.default)(),
    kind: (0, _attr.default)(),
    failureMessage: (0, _attr.default)(),
    previousKind: (0, _attr.default)(),
    isTrial: Ember.computed.equal('status', 'trial'),
    isActive: Ember.computed.equal('status', 'active'),
    isError: Ember.computed('status', function () {
      return this.get('status') === 'error';
    }),
    isInactive: Ember.computed.equal('status', 'inactive')
  });
});