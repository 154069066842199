define('knowme-client/adapters/sent-reminder', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let SentReminderAdapter;

	SentReminderAdapter = _application.default.extend({
		queryUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,filter[],user_id,page,sort_by,order,field,from_date,to_date,sales_stage_id,query}'
	});

	exports.default = SentReminderAdapter;
});