define('knowme-client/components/reviews/resource-form', ['exports', 'knowme-client/mixins/controllers/status'], function (exports, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_status.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    confirmRemoveDefaultLink: false,
    reviews: Ember.computed.alias('reviewLinksController.model'),
    defaultReviewLinks: Ember.computed.filter('reviews', function (reviewLink) {
      return reviewLink.get('default');
    }),
    numberOfDefaultReviewLinks: Ember.computed.alias('defaultReviewLinks.length'),
    didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (Ember.isPresent(this.get('reviewLink.name'))) {
        let option = this.get('reviewWebSiteOptions').findBy('name', this.get('reviewLink.name'));
        this.set('reviewWebSiteOption', Ember.isPresent(option) ? option.id : 'custom');
      } else {
        this.set('reviewWebSiteOption', null);
      }
    },
    reviewWebSiteOptions: [{ id: 'google-business', name: 'Google My Business' }, { id: 'amazon', name: 'Amazon' }, { id: 'facebook', name: 'Facebook' }, { id: 'yelp', name: 'Yelp' }, { id: 'trip-advisor', name: 'Trip Advisor' }, { id: 'bbb', name: 'BBB (Better Business Bureau)' }, { id: 'yellowpages', name: 'Yellowpages' }, { id: 'manta', name: 'Manta' }, { id: 'angies-list', name: 'Angies List' }, { id: 'foursquare', name: 'Foursquare' }, { id: 'my-website', name: 'My Website (via Knowme IQ Review)' }, { id: 'custom', name: 'Custom' }],
    onReviewSiteSelected: Ember.observer('reviewWebSiteOption', function () {
      if (Ember.isPresent(this.get('reviewWebSiteOption'))) {
        if (this.get('reviewWebSiteOption') === 'custom') {
          this.set('reviewLink.name', null);
        } else {
          let option = this.get('reviewWebSiteOptions').findBy('id', this.get('reviewWebSiteOption'));
          this.set('reviewLink.name', option.name);
        }
      }
    }),
    disableSubmit: Ember.computed('reviewLink', 'reviewLink.validations.isValid', 'reviewLink.hasDirtyAttributes', function () {
      return this.get('reviewLink.validations.isInvalid') || !this.get('reviewLink.hasDirtyAttributes');
    }),
    actions: {
      submit: function () {
        let controller = this;
        let makingInactiveLastDefaultLink = !this.get('reviewLink.isNew') && this.get('reviewLink.status') === 'inactive' && this.get('reviewLink.default') && this.get('numberOfDefaultReviewLinks') == 1;
        if (makingInactiveLastDefaultLink && !this.get('confirmRemoveDefaultLink')) {
          this.set('confirmRemoveDefaultLink', true);
        } else {
          this.get('reviewLink').save().then(function () {
            controller.get('router').transitionTo('account.settings.reviews.index');
            controller.set('confirmRemoveDefaultLink', false);
          });
        }
      },
      rollbackLinkChanges: function () {
        this.get('reviewLink').rollbackAttributes();
      }
    }
  });
});