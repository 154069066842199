define('knowme-client/adapters/account', ['exports', 'knowme-client/adapters/application', 'knowme-client/mixins/adapters/api-key-header'], function (exports, _application, _apiKeyHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_apiKeyHeader.default, {
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,filter,paginate,call_id,account_id}',
    queryUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,fields,filter,paginate}',
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      paginate: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && query.paginate === true) {
          delete query.paginate;
          return true;
        }
      },
      fields: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && Ember.isPresent(query.fields)) {
          let fields = Ember.isArray(query.fields) ? query.fields.join(',') : query.fields;
          delete query.fields;
          return fields;
        }
      },
      filter: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && Ember.isPresent(query.filter)) {
          let filter = Ember.isArray(query.filter) ? query.filter.join(',') : query.filter;
          delete query.filter;
          return filter;
        }
      },
      status: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && Ember.isPresent(query.status)) {
          let status = Ember.copy(query.status);
          delete query.status;
          return status;
        }
      }
    },
    validate(model) {
      const url = this.buildURL('account');
      let data = model.serialize();
      data.validate_only = true;
      return this.ajax(url, 'POST', {
        data: data
      });
    },
    creditCard(model) {
      let url = this.buildURL('account');
      url = `${url}/${model.get('id')}/credit_card`;
      return this.ajax(url, 'GET');
    }
  });
});