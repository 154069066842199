define('knowme-client/controllers/account/clients/new', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    additionalSettings: ['additional.users', 'additional.users.dids.local', 'additional.minutes', 'additional.short_messages', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local', 'additional.contacts'],
    addOns: Ember.computed('model.subscription.plan.key', function () {
      let plan = this.get('model.subscription.plan');
      if (Ember.isPresent(plan) && Ember.isPresent(plan.get('key'))) {
        if (plan.get('key').includes('knowme_iq.premium')) {
          return {
            includedUsers: null,
            additionalUsers: null,
            includedUsersDidsLocal: null,
            additionalUsersDidsLocal: null,
            includedCallTouchPointsDidsLocal: null,
            additionalCallTouchPointsDidsLocal: null,
            includedCallTouchPointsDidsTollFree: null,
            additionalCallTouchPointsDidsTollFree: null,
            includedContacts: null,
            additionalContacts: null,
            includedShortMessages: null,
            additionalShortMessages: null,
            includedMinutes: null,
            additionalMinutes: null,
            includedStorage: null,
            additionalStorage: null
          };
        } else {
          return {
            includedUsers: null,
            additionalUsers: null,
            includedContacts: null,
            additionalContacts: null,
            includedStorage: null,
            additionalStorage: null
          };
        }
      } else {
        return {};
      }
    }),
    planSettings: Ember.computed('billingTerm.value', 'model.subscription.plan.key', function () {
      let plan = this.get('model.subscription.plan');
      let billingTerm = this.get('billingTerm.value');
      if (Ember.isPresent(plan) && Ember.isPresent(plan.get('key'))) {
        switch (billingTerm) {
          case 1:
            return plan.get('settings').monthly;
            break;
          case 12:
            return plan.get('settings').annually;
            break;
        }
      } else {
        return {};
      }
    }),
    onPlanChanged: Ember.observer('model.subscription.plan.key', function () {
      if (Ember.isPresent(this.get('model.subscription.plan.key')) && this.get('model.subscription.plan.key').includes('unlimited')) {
        this.set('billingTerm.value', 1);
      }
    }),
    onPlanSettingsChanged: Ember.observer('planSettings', function () {
      let planSettings = this.get('planSettings');
      if (Ember.isPresent(planSettings)) {
        let _this = this;
        _environment.default.APP.SettingsKeys.forEach(key => {
          if (key === 'account.billing_term') {
            return;
          }

          if (Object.keys(_this.get('addOns')).includes(key.camelize())) {
            _this.set(`addOns.${key.camelize()}`, planSettings[key.camelize()] || 0);
          }
        });
      }
    }),
    actions: {
      transitionToIndex: function () {
        this.transitionToRoute('account.clients');
      }
    }
  });
});