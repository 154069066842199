define('knowme-client/controllers/calls', ['exports', 'lodash', 'moment', 'knowme-client/mixins/controllers/components/date-filter', 'knowme-client/mixins/controllers/queryable', 'knowme-client/mixins/controllers/pagination'], function (exports, _lodash, _moment, _dateFilter, _queryable, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _queryable.default, _pagination.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    queryParams: ['type', 'filter', 'satisfaction', 'user_id', 'campaign_id', 'resource_id', 'call_id', 'assigned_id'],
    defaultsQueryParams: {
      from_date: (0, _moment.default)().subtract(6, 'day').format('MM-DD-YYYY'),
      to_date: (0, _moment.default)().format('MM-DD-YYYY')
    },
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    filter: null,
    satisfaction: null,
    campaign_id: null,
    user_id: null,
    assigned_id: null,
    resource_id: null,
    call_id: null,
    openConfirmRemoveCall: false,
    isRemoving: false,
    isDeleting: false,
    metricsFilterInboundOptions: [{ id: 'calls_lt_1_minute', name: 'Calls < 1 Minute' }, { id: 'calls_gt_1_lt_5_minutes', name: 'Calls > 1 < 5 Minutes' }, { id: 'calls_gt_5_minutes', name: 'Calls > 5 Minutes' }, { id: 'assigned_calls', name: 'Assigned Calls' }, { id: 'unassigned_calls', name: 'Unassigned Calls' }, { id: 'recorded_calls', name: 'Recorded Calls' }, { id: 'non_recorded_calls', name: 'Non Recorded Calls' }, { id: 'listened_to', name: 'Listened To' }, { id: 'not_listened_to', name: 'Not Listened To' }, { id: 'training_calls', name: 'Training Calls' }, { id: 'wrong_calls', name: 'Wrong Calls' }],
    metricsFilterOutboundOptions: [{ id: 'calls_lt_1_minute', name: 'Calls < 1 Minute' }, { id: 'calls_gt_1_lt_5_minutes', name: 'Calls > 1 < 5 Minutes' }, { id: 'calls_gt_5_minutes', name: 'Calls > 5 Minutes' }, { id: 'recorded_calls', name: 'Recorded Calls' }, { id: 'non_recorded_calls', name: 'Non Recorded Calls' }, { id: 'listened_to', name: 'Listened To' }, { id: 'not_listened_to', name: 'Not Listened To' }, { id: 'training_calls', name: 'Training Calls' }],
    allCampaignsOption: { id: 'all', name: 'All Campaigns' },
    allUsersOption: { id: 'all', name: 'All Users' },
    campaignsFilterOptions: Ember.computed('campaigns.@each.id', function () {
      return this.get('campaigns').toArray().insertAt(0, this.get('allCampaignsOption'));
    }),
    satisfactionFilterOptions: ['none', 'very_dissatisfied', 'dissatisfied', 'neutral', 'satisfied', 'very_satisfied'],
    promptOption: 'prompt',
    onSearchByName: Ember.observer('query', function () {
      // reset all filter
      let query = this.get('query');
      let queryParams = this.get('queryParams');
      if (Ember.isPresent(query)) {
        _lodash.default.each(queryParams, function (key) {
          if (!_lodash.default.includes(['query', 'type'], key)) {
            this.set(key, null);
          }
        }.bind(this));
      }
    }),
    filteredModel: Ember.computed.filter('model', function (resource) {
      if (Ember.isPresent(this.get('selectedResource'))) {
        return this.get('selectedResource.id') === resource.get('id');
      } else {
        return true;
      }
    }).property('model', 'selectedResource'),
    callMetricLabel: Ember.computed('filter', 'type', function () {
      let label;
      let filter = this.get('filter');
      let type = this.get('type');
      switch (filter) {
        case 'recorded_calls':
          label = 'Recorded';
          break;
        case 'listened_to':
          label = 'Listened to';
          break;
        case 'not_listened_to':
          label = 'Not Listened';
          break;
        case 'training_calls':
          label = 'Training';
          break;
        case 'wrong_calls':
          label = 'Wrong Calls';
          break;
        default:
          if (type === 'inbound') {
            label = 'Received';
          } else {
            label = 'Made';
          }
      }
      return label;
    }),
    modelCallsTotals: Ember.computed('filteredModel.length', 'filteredModel.@each.callsCount', function () {
      return this.get('filteredModel').mapBy('callsCount');
    }),
    modelCallsTotal: Ember.computed.sum('modelCallsTotals'),
    showUsersFilter: Ember.computed('features.plan.productKey', 'session.currentUser.role.name', 'type', function () {
      return (this.get('session.currentUser.hasAdminRole') || this.get('session.currentUser.isManager')) && ['sales_accelerator_crm', 'sales_accelerator_marketing_optimizer_crm', 'knowme_iq'].includes(this.get('features.plan.productKey')) && this.get('type') === 'outbound';
    }),
    showCampaignsFilter: Ember.computed('features.plan.productKey', 'session.currentUser.role.name', 'type', function () {
      return (this.get('session.currentUser.hasAdminRole') || this.get('session.currentUser.isManager')) && this.get('features.plan.productKey') === 'marketing_optimizer_crm';
    }),
    showUsersAndCampaignsFilter: Ember.computed('features.plan.productKey', 'session.currentUser.role.name', 'type', function () {
      return (this.get('session.currentUser.hasAdminRole') || this.get('session.currentUser.isManager')) && ['enterprise', 'sales_accelerator_marketing_optimizer_crm', 'knowme_iq'].includes(this.get('features.plan.productKey')) && this.get('type') === 'inbound';
    }),
    usersFilterOptions: Ember.computed('type', function () {
      let options;
      switch (this.get('type')) {
        case 'inbound':
          options = this.get('usersWithInboundCalls');
          break;
        case 'outbound':
          options = this.get('usersWithOutboundCalls');
          break;
        default:
          options = this.get('users');
      }
      return options;
    }),
    selectedTypeResource: Ember.computed('campaign_id', 'user_id', 'assigned_id', function () {
      if (Ember.isPresent(this.get('campaign_id'))) {
        if (this.get('campaign_id') === 'all') {
          return { id: 'all', name: 'All Campaigns' };
        }
        return this.get('campaigns').findBy('id', this.get('campaign_id'));
      }
      if (Ember.isPresent(this.get('user_id'))) {
        return this.get('users').findBy('id', this.get('user_id'));
      }
      if (Ember.isPresent(this.get('assigned_id'))) {
        return this.get('users').findBy('id', this.get('assigned_id'));
      }
    }),
    actions: {
      startListening: function () {
        this.send('pauseRefreshing');
      },
      stopListening: function () {
        this.send('continueRefreshing');
      },
      toggleResource(resource) {
        let resourceId = this.get('resource_id');

        if (Ember.isPresent(resourceId) && resourceId === resource.get('id')) {
          this.set('resource_id', null);
        } else {
          this.set('resource_id', resource.id);
        }
      },
      callListened: function (resource) {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return;
        }
        let store = this.get('store');
        let currentUser = this.get('session.currentUser');
        let currentUserHasListenedCall = resource.get('listeners').mapBy('id').includes(currentUser.get('id'));
        if (!resource.get('listened') || !currentUserHasListenedCall) {
          resource.set('listened', true);
          store.find('call', resource.get('id')).then(function (call) {
            call.set('listened', true);
            return call.save();
          });
        }
        let callListener = store.createRecord('callListener', { call: resource, user: currentUser });
        return callListener.save().then(function () {
          resource.get('listeners').reload();
        });
      },
      tagWith: function (call, tag) {
        call.set('tagList', [tag]);
      },
      removeTags: function (call) {
        call.set('tagList', []);
      },
      // Computed actions
      filteredCalls: function (type, resource, limit, loaded) {
        if (!loaded) {
          return;
        }
        let options = {};
        options[`${type}_id`] = resource.get('id');
        if (type === 'contact') {
          options.filter = ['inbound'];
        } else if (type === 'user') {
          options.filter = ['outbound'];
        }
        if (Ember.isPresent(this.get('filter'))) {
          options.filter.push(this.get('filter'));
        }
        if (Ember.isPresent(this.get('user_id'))) {
          if (this.get('user_id') === 'all') {
            options.users = 'all';
          } else {
            options.user_id = this.get('user_id');
          }
        }
        if (Ember.isPresent(this.get('assigned_id'))) {
          if (this.get('assigned_id') !== 'all') {
            options.assigned_id = this.get('assigned_id');
          }
        }
        if (Ember.isPresent(this.get('campaign_id'))) {
          if (this.get('campaign_id') === 'all') {
            options.campaigns = 'all';
          } else {
            options.campaign_id = this.get('campaign_id');
          }
        }
        if (Ember.isPresent(this.get('satisfaction'))) {
          options.satisfaction = this.get('satisfaction');
        }
        let dateOptions = { from_date: this.get('from_date'), to_date: this.get('to_date') };
        let formattedDateOptions = this.processDateFilterParams(dateOptions);
        if (Ember.isPresent(formattedDateOptions.from_date) && Ember.isPresent(formattedDateOptions.to_date)) {
          Ember.assign(options, formattedDateOptions);
        }
        options.paginate = false;
        if (limit) {
          options.limit = limit;
        }
        let promise = this.get('store').query('call', options);
        promise.then(function (calls) {
          calls.forEach(function (call) {
            call.set('editing', false);
            call.rollbackAttributes();
          });
        });
        return promise;
      },
      refreshModelData: function () {
        this.send('refreshModel');
      },
      setSatisfaction: function (call, satisfaction) {
        if (satisfaction === 'none') {
          call.set('satisfaction', null);
        } else {
          call.set('satisfaction', satisfaction);
        }
      },
      save: function (call) {
        if (call.then) {
          // when call object is PromiseObject
          call = this.get('store').peekRecord('call', call.get('id'));
        }
        let reloadAssignedUsers = Ember.isPresent(call.changedRelationships().assignedTo) && Ember.isPresent(call.changedRelationships().assignedTo[0]) && Ember.isPresent(call.changedRelationships().assignedTo[1]);
        call.save().then(function (call) {
          call.set('editing', false);
          if (call.get('tagList').includes('wrong')) {
            this.get('flashMessages').info("This call is being moved into Wrong Calls under Filter By Call Metric", { destroyOnClick: true, timeout: 12000 });
            this.send('refreshModelData');
          }
          if (Ember.isPresent(this.get('satisfaction')) && this.get('satisfaction') !== call.get('satisfaction')) {
            this.send('refreshModelData');
          }
          call.belongsTo('opportunity').reload();
          if (reloadAssignedUsers) {
            this.get('store').query('user', { paginate: false, filter: ['with_assigned_calls'], sort_by: 'name', order: 'asc' }).then(function (res) {
              this.set('assignedUsers', res);
            }.bind(this));
          }
        }.bind(this));
      },
      confirmRemoveCall: function (call) {
        this.send('pauseRefreshing');
        this.set('openConfirmRemoveCall', true);
        this.set('callToBeRemoved', call);
      },
      continueRefreshingWS: function () {
        this.send('continueRefreshing');
      },
      deleteCall: function () {
        this.send('continueRefreshing');
        this.set('isDeleting', true);
        if (this.get('callToBeRemoved').then) {
          this.get('callToBeRemoved').then(function (call) {
            this.send('deleteCallRecord', call);
          }.bind(this));
        } else {
          this.send('deleteCallRecord', this.get('callToBeRemoved'));
        }
      },
      deleteCallRecord: function (call) {
        call.deleteCall().then(function () {
          this.set('isDeleting', false);
          this.set('openConfirmRemoveCall', false);
          if (Ember.isPresent(this.get('resource_id')) && this.get('selectedResource.callsCount') <= 2) {
            this.set('resource_id', null);
          } else {
            this.send('refreshModelData');
          }
        }.bind(this));
      },
      removeCall: function () {
        this.send('continueRefreshing');
        this.set('isRemoving', true);
        if (this.get('callToBeRemoved').then) {
          this.get('callToBeRemoved').then(function (call) {
            this.send('destroyCallRecord', call);
          }.bind(this));
        } else {
          this.send('destroyCallRecord', this.get('callToBeRemoved'));
        }
      },
      destroyCallRecord: function (call) {
        call.destroyRecord().then(function () {
          this.set('isRemoving', false);
          this.set('openConfirmRemoveCall', false);
          this.set('resource_id', null);
          this.send('refreshModelData');
        }.bind(this));
      }
    },
    processDateFilterParams: function (params) {
      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    }
  });
});