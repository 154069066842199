define('knowme-client/formats', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hhmmss = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };

  exports.default = {
    time: {
      hhmmss: hhmmss
    },
    date: {
      hhmmss: hhmmss
    },
    number: {
      EUR: { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 },
      USD: { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }
    }
  };
});