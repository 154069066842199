define('knowme-client/components/opportunities/activity-version', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['opportunity'],
    store: Ember.inject.service(),
    actions: {
      openPlayer: function (activity) {
        if (Ember.isPresent(activity.get('call.callAudio'))) {
          activity.set('play', true);
          this.sendAction('selectActivity', activity);
        }
      },
      closePlayer: function () {
        // this.get('store').findAll('activity').then(function(currentActivity) {
        //   currentActivity.set('selected', false);
        // });
      }
    }
  });
});