define('knowme-client/helpers/has-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var hasError, HasErrorHelper;

  exports.hasError = hasError = function (params, hash) {
    const { model, fieldName } = hash;

    if (Ember.isEmpty(model)) {
      console.log('Helper has-error: Invalid arguments', arguments);
      return false;
    }

    var errors = model.get('errors');
    return model.get(`${fieldName}HasChanged`) && !Ember.isEmpty(errors.get(fieldName));
  };

  HasErrorHelper = Ember.Helper.helper(hasError);

  exports.hasError = hasError;
  exports.default = HasErrorHelper;
});