define('knowme-client/models/contact-url', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let ContactUrl;

	ContactUrl = _model.default.extend({
		contactInfo: (0, _relationships.belongsTo)('contact-info', { async: true }),
		fullUrl: (0, _attr.default)(),
		protocol: (0, _attr.default)(),
		port: (0, _attr.default)(),
		domain: (0, _attr.default)(),
		socialNetwork: (0, _attr.default)()
	});

	exports.default = ContactUrl;
});