define('knowme-client/routes/quote', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    apiKey: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function (queryParams) {
      let params = this.paramsFor('quote');
      this.set('apiKey.code', queryParams.code);

      return this.get('store').find('sales-transaction', params.id);
    },
    afterModel: function (model) {
      return model.belongsTo('contact').reload().then(function (contact) {
        this.set('controllerData', { contact: contact });
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.set('apiKey.code', null);
      }
    }
  });
});