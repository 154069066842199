define('knowme-client/components/communications-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    sortedCommunications: Ember.computed.sort('communications', 'sortDefinition'),
    sortDefinition: ['createdAt'],
    lastContactCommunication: Ember.computed('sortedCommunications.@each.communicationResourceContactId', 'contact.id', function () {
      let contact = this.get('contact');
      let contactCommunications = this.get('sortedCommunications').filter(function (item) {
        return item.get('communicationResourceContactId') === contact.get('id');
      });
      return contactCommunications.get('lastObject');
    }),
    didRender: function () {
      return this.$('.panel-body').scrollTop(this.$('.panel-body')[0].scrollHeight);
    },
    actions: {
      sendMessage() {
        let shortMessage = this.get('store').createRecord('short-message', { body: this.get('message') });
        let contactPhoneNumber = this.get('lastContactCommunication.communicationResource.contactPhoneNumber');
        let opportunity = this.get('lastContactCommunication.communicationResource.opportunity');
        shortMessage.setProperties({ contactPhoneNumber: contactPhoneNumber, opportunity: opportunity });
        shortMessage.save().then(function (shortMessage) {
          this.set('message', null);
          this.get('communications').pushObject(shortMessage.get('communication'));
        }.bind(this));
      }
    }
  });
});