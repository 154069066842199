define('knowme-client/routes/incentives/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let IncentivesRoute;

  IncentivesRoute = Ember.Route.extend({
    apiKey: Ember.inject.service(),
    store: Ember.inject.service(),
    account: null,
    beforeModel: function (transition) {
      this._super(...arguments);
      this.set('apiKey.code', transition.queryParams.code);
      let params = this.paramsFor('incentives.new');
      return this.get('store').find('incentives-contact', params.id).then(function (incentivesContact) {
        this.set('incentivesContact', incentivesContact);
        return incentivesContact.get('incentive');
      }.bind(this)).then(function (incentive) {
        return incentive.get('account');
      }).then(function (account) {
        this.set('account', account);
        return account;
      }.bind(this));
    },
    model: function (queryParams) {
      if (queryParams.claim) {
        return this.get('incentivesContact.contact');
      } else {
        this.set('lead', this.store.createRecord('lead'));
        return this.get('store').createRecord('contact', { incentivesContact: this.get('incentivesContact'), lead: this.get('lead'), primary: true });
      }
    },
    afterModel: function (model, transition) {
      if (transition.queryParams.claim) {
        let hasClaimed = this.get('incentivesContact.claimed');

        model.set('incentivesContact', this.get('incentivesContact'));

        return Ember.RSVP.hash({
          lead: model.get('lead'),
          incentivesContactTarget: this.get('incentivesContact.incentivesContactTarget')
        }).then(function (data) {
          this.set('controllerData', {
            claim: true,
            hasClaimed: hasClaimed,
            incentivesContact: this.get('incentivesContact'),
            incentivesContactTarget: data.incentivesContactTarget,
            lead: data.lead,
            account: this.get('account')
          });
        }.bind(this));
      } else {
        if (this.get('account.isbtob')) {
          this.store.createRecord('organization', { lead: model.get('lead') });
        }
        this.set('controllerData', {
          incentivesContact: model.get('incentivesContact'),
          lead: this.get('lead'),
          account: this.get('account')
        });
      }
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.set('apiKey.code', null);
      }
    }
  });

  exports.default = IncentivesRoute;
});