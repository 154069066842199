define('knowme-client/serializers/users-chat-message', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      user: { deserialize: 'id', serialize: false },
      message: { deserialize: 'id', serialize: false }
    }
  });
});