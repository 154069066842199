define('knowme-client/serializers/campaign-json', ['exports', 'knowme-client/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    attrs: {
      ads: { serialize: 'records', deserialize: false }
    }
  });
});