define('knowme-client/mirage/factories/sales-stage', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.default.Factory.extend({
		name: function () {
			return _faker.default.random.arrayElement(['Unqualified', 'Appointment', 'Closed/Won', 'Closed/Lost']);
		},
		key: function () {
			return _faker.default.random.arrayElement(['unqualified', 'appointment', 'closed', 'lost']);
		},
		internal: function () {
			return _faker.default.random.arrayElement([true, false]);
		},
		editable: function () {
			return _faker.default.random.arrayElement([true, false]);
		}
	});
});