define('knowme-client/mirage/factories/contact', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    firstName: _faker.default.name.firstName,
    lastName: _faker.default.name.lastName,
    title: function () {
      return _faker.default.random.arrayElement([_faker.default.internet.url(), null]);
    },
    website: function () {
      return _faker.default.random.arrayElement([_faker.default.internet.url(), null]);
    },
    facebook: function () {
      return _faker.default.random.arrayElement([_faker.default.internet.url(), null]);
    },
    twitter: function () {
      return _faker.default.random.arrayElement([_faker.default.internet.url(), null]);
    },
    linkedin: function () {
      return _faker.default.random.arrayElement([_faker.default.internet.url(), null]);
    },
    google: function () {
      return _faker.default.random.arrayElement([_faker.default.internet.url(), null]);
    },
    primary: _faker.default.random.boolean,
    callsCount: function () {
      return 0;
    },
    shortMessagesCount: function () {
      return 0;
    },
    opportunitiesCount: function () {
      return 0;
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    }
  });
});