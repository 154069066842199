define('knowme-client/controllers/leads/show/opportunities/show', ['exports', 'knowme-client/mixins/controllers/email-integration', 'knowme-client/mixins/controllers/opportunity-fields'], function (exports, _emailIntegration, _opportunityFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowOpportunitiesShowController;

  LeadsShowOpportunitiesShowController = Ember.Controller.extend(_emailIntegration.default, _opportunityFields.default, {
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    contactsController: Ember.inject.controller('leads.show.contacts'),
    accountService: Ember.inject.service('account'),
    queryParams: [{ contactEmailId: 'contact_email_id' }, { contactPhoneNumberId: 'contact_phone_number_id' }, { contactId: 'contact_id' }], // queryParams that trigger model reload
    selectedEmail: null,
    draftMessage: null,
    contactPhoneNumberId: null,
    contactEmailId: null,
    contactId: null,
    action: null,
    autoCall: false,
    unsavedAttachments: [],
    isSaving: false,
    init: function (action) {
      this._super.apply(this, arguments);
      this.set('draftMessage', null);
      this.set('selectedEmail', null);

      Ember.run.schedule("afterRender", this, function () {
        if (Ember.isPresent(action)) {
          this.doAction(action);
        } else if (Ember.isPresent(this.get('contactId'))) {
          let selectedContact = this.get('store').peekRecord('contact', this.get('contactId'));
          this.set('selectedContact', selectedContact);
        }
        Ember.$("body").tooltip({ selector: "table [data-toggle='tooltip']" });
      });
    },
    doAction: function (action) {
      let controller = this;

      switch (action) {
        case 'call':
        case 'sms':
          if (Ember.isBlank(controller.get('contactPhoneNumberId'))) {
            break;
          }
          let contactPhoneNumber = controller.get('store').findRecord('contactPhoneNumber', controller.get('contactPhoneNumberId'));
          contactPhoneNumber.then(function (contactPhoneNumber) {
            controller.set('selectedPhoneNumber', contactPhoneNumber);
            return contactPhoneNumber.get('contactInfo');
          }).then(function (contactInfo) {
            return contactInfo.get('contact');
          }).then(function (contact) {
            controller.set('selectedContact', contact);
            if (action === 'call') {
              controller.set('autoCall', true);
            } else {
              controller.set('autoText', true);
            }
          });
          break;
        case 'email':
          if (Ember.isBlank(controller.get('contactEmailId'))) {
            break;
          }
          let selectedContactEmail = controller.get('store').peekRecord('contact-email', controller.get('contactEmailId'));
          controller.set('selectedContact', selectedContactEmail.get('contactInfo.contact'));
          this.set('selectedContactEmail', selectedContactEmail);
        default:
          console.log(`action ${action} does not apply`);
      }
    },
    actions: {
      startListening: function () {
        this.send('pauseRefreshing');
      },
      stopListening: function () {
        this.send('continueRefreshing');
      },
      reRenderContactActions: function () {
        let controller = this;
        controller.set('isContactActionRendering', true);
        Ember.run.later(function () {
          controller.set('isContactActionRendering', false);
        }, 5);
      },
      submit: function () {
        let controller = this;
        this.model.one('didUpdate', function () {
          ['order', 'custom-attribute', 'sales-transaction'].forEach(function (modelName) {
            controller.get('store').peekAll(modelName).filter(function (_model) {
              return _model.get('isNew');
            }).invoke('deleteRecord');
          });
          controller.model.get('lead').then(function (lead) {
            lead.reload();
          });
          controller.set('unsavedAttachments', []);
          // controller.prepareCustomAttributes();
        }.bind(this));
        this.set('isSaving', true);
        controller.model.save().then(function (opportunity) {
          controller.set('isSaving', false);
          opportunity.belongsTo('lastSalesTransaction').reload().then(function (lastSalesTransaction) {
            controller.set('lastSalesTransaction', lastSalesTransaction);
          });
          Ember.$("#opportunity-history-table").collapse('show');

          let assignedTo = opportunity.get('assignedTo');
          let currentUser = controller.get('session.currentUser');

          if (Ember.isPresent(assignedTo.get('id'))) {
            if (assignedTo.get('id') !== currentUser.get('id')) {
              assignedTo.get('roles').reload().then(function (roles) {
                let noNeedToRedirect = true;
                if (currentUser.get('isAdmin') && !currentUser.get('sameRoleDataAccess')) {
                  noNeedToRedirect = assignedTo.get('isMasterManager') || assignedTo.get('isManager') || assignedTo.get('isSalesPerson');
                } else if (currentUser.get('isMasterManager')) {
                  noNeedToRedirect = assignedTo.get('isManager') || assignedTo.get('isSalesPerson');
                } else if (currentUser.get('isManager')) {
                  noNeedToRedirect = assignedTo.get('isSalesPerson');
                }
                if (!noNeedToRedirect) {
                  controller.transitionToRoute('dashboard');
                }
              });
            }
          } else {
            if (currentUser.get('isSalesPerson')) {
              controller.transitionToRoute('dashboard');
            }
          }

          opportunity.get('orders').forEach(function (order) {
            if (Ember.isPresent(order.get('product.quantity'))) {
              order.belongsTo('product').reload();
            }
          });
        }).catch(function (reason) {
          controller.set('isSaving', false);
          controller.get('flashMessages').warning(reason.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
          console.log("Errors", reason);
        });
      },
      toggleContactsSelector: function () {
        this.set('displayContactSelector', !this.get('displayContactSelector'));
      },
      selectContact: function (contact) {
        this.set('contact', contact);
      },
      afterSend: function () {}
    },
    opportunity: Ember.computed.alias('model'),
    activitiesTotal: Ember.computed.alias('activities.length'),
    callsTotal: Ember.computed.alias('opportunity.callActivities.length'),
    emailsTotal: Ember.computed.alias('opportunity.emailActivities.length'),
    smsTotal: Ember.computed.alias('opportunity.smsActivities.length'),
    anyTotal: Ember.computed.alias('opportunity.anyActivities.length'),
    lead: Ember.computed.alias('opportunity.lead'),
    contact: Ember.computed.alias('lead.primaryContact'),
    filteredActivitiesMeta: Ember.computed('activities.meta', function () {
      return this.get('activities.meta');
    }),
    filteredActivities: Ember.computed('activities.length', 'activitiesFilter', function () {
      let activitiesFilter = this.get('activitiesFilter');
      let activities;
      if (Ember.isPresent(activitiesFilter) && activitiesFilter !== 'all' && ['calls', 'short_messages', 'emails', 'quotes', 'others'].includes(activitiesFilter) && this.get('activities.isLoaded')) {
        activities = this.get('store').query('lead-activity', { filter: activitiesFilter, opportunity_id: this.get('opportunity.id') });
      } else {
        activities = this.get('activities');
      }
      this.set('selectedActivity', null);

      return activities;
    }),
    hasUnsavedAttachments: Ember.computed('unsavedAttachments.length', function () {
      return this.get('unsavedAttachments.length') > 0;
    }),
    sorting: ['createdAt:desc'],
    sortedActivities: Em.computed.sort('filteredActivities', 'sorting'),
    isModelTreeDirty: Ember.computed('opportunity.customAttributes.@each.value', 'opportunity.hasDirtyAttributes', 'opportunity.salesStageHasChanged', 'opportunity.salesActionHasChanged', 'opportunity.assignedToHasChanged', 'opportunity.customAttributes.@each.hasDirtyAttributes', 'opportunity.orders.@each.hasDirtyAttributes', 'opportunity.orders.@each.productHasChanged', function () {
      // console.log(this.get('opportunity.customAttributes') && this.get('opportunity.customAttributes').isAny('valueHasChanged', true));
      return this.get('opportunity.hasDirtyAttributes') || this.get('opportunity.salesStageHasChanged') || this.get('opportunity.salesActionHasChanged') || this.get('opportunity.assignedToHasChanged') || this.get('opportunity.customAttributes') && this.get('opportunity.customAttributes').isAny('hasDirtyAttributes', true) && this.get('opportunity.customAttributes').isAny('valueHasChanged', true) || this.get('opportunity.orders') && this.get('opportunity.orders').isAny('hasDirtyAttributes', true) || this.get('opportunity.orders') && this.get('opportunity.orders').isAny('productHasChanged', true);
    }),
    disableSubmit: Ember.computed('opportunity.validations.isValid', 'isModelTreeDirty', 'hasUnsavedAttachments', 'isSaving', function () {
      return this.get('isSaving') || this.get('opportunity.validations.isInvalid') || !this.get('isModelTreeDirty') && !this.get('hasUnsavedAttachments');
    })
  });

  exports.default = LeadsShowOpportunitiesShowController;
});