define('knowme-client/serializers/survey', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      contactEmail: { deserialize: 'id', serialize: 'id' },
      opportunity: { deserialize: 'id', serialize: 'id' }
    },
    keyForRelationship: function (key, relationship, method) {
      if (['contactEmail', 'opportunity'].includes(key)) {
        return `${Ember.String.underscore(key)}_id`;
      }

      return this._super(...arguments);
    },
    serialize(snapshot /*, options*/) {
      let json = this._super(...arguments);
      if (snapshot.id) {
        delete json.contact_email_id;
        delete json.opportunity_id;
      }
      return json;
    }
  } // normalize(typeClass, hash/*, prop*/) {
  //   console.log(hash)
  //   hash.relationships = hash.relationships || {};

  //   let models = ['opportunity', 'contact-email'];
  //   models.forEach(function(model){
  //     hash.relationships[model] = hash.relationships[model] || {};
  //     hash.relationships[model].links = hash.relationships.links || {};

  //     if(isPresent(hash.relationships[model].data)) {
  //       hash.relationships[model].links.related = getOwner(this).lookup(`adapter:${model}`).buildURL(model, hash.relationships[model].data.id, null, 'findRecord', null);
  //     }
  //   }.bind(this));

  //   return this._super.apply(this, arguments);
  // }
  );
});