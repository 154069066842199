define('knowme-client/routes/agreement', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_accountSetup.default, {
		model: function () {
			return this.get('store').query('contract-agreement', { order: { created_at: 'desc' } });
		},
		setupController: function (controller, model) {
			this._super.apply(this, arguments);
			// Model is already set, so we have to explicitly update controller
			console.log(model.get('firstObject'));
			controller.set('currentAgreement', model.get('firstObject'));
		}
	});
});