define('knowme-client/validators/date', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    intl: Ember.inject.service(),
    init: function () {
      // this call is necessary, don't forget it!
      this._super();
      if (Ember.isPresent(this.options.compareWith)) {
        this.dependentValidationKeys.pushObject(this.options.compareWith);
      }
    },
    call: function () {
      if (!this.compare(Ember.get(this.model, this.property), Ember.get(this.model, this.options.compareWith), this.options.operator)) {
        this.errors.pushObject(this.operatorErrorMessage(this.options.operator));
      }
    },
    operatorErrorMessage: function (operator) {
      let intl = this.get('intl');
      return intl.t("validations.date.errors.messages." + operator, { field: intl.t('fields.' + this.options.compareWith) });
    }
  });
});