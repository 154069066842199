define('knowme-client/routes/leads/show/contacts', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    queryParams: {
      status: {
        refreshModel: true
      }
    },
    model: function (params) {
      let lead = this.modelFor('leads.show');
      this.set('lead', lead);
      return lead.get('contacts');
    },
    afterModel: function (model, transition) {
      if (Ember.isPresent(transition.queryParams.contact_id)) {
        this.set('selectedContact', model.findBy('id', transition.queryParams.contact_id));
      } else {
        this.set('selectedContact', null);
      }
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('lead', this.get('lead'));
      controller.set('selectedContact', this.get('selectedContact'));
    },
    renderTemplate: function (controller, model) {
      return this.render('leads/show/contacts', {
        into: 'application',
        model: model
      });
    },
    actions: {
      willTransition: function () /*transition*/{
        this.controller.setProperties({ contact: null, phone: null, email: null, address: null });
      }
    }
  });
});