define('knowme-client/controllers/reports/index', ['exports', 'knowme-client/mixins/reports-popover', 'knowme-client/mixins/controllers/components/date-filter-select', 'knowme-client/mixins/controllers/pagination', 'knowme-client/config/environment', 'moment', 'lodash'], function (exports, _reportsPopover, _dateFilterSelect, _pagination, _environment, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { host, namespace, version } = _environment.default.APP.API;

  let ReportsIndexController;

  ReportsIndexController = Ember.Controller.extend(_dateFilterSelect.default, _reportsPopover.default, _pagination.default, {
    features: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    queryParams: ['campaign_id', { reportTypeSelected: 'type' }],
    defaultsQueryParams: {
      reportTypeSelected: 'leads_pipeline'
    },
    reportTypeSelected: null,
    adId: null,
    campaign_id: null,
    time: null,
    weekday: null,
    salesRepresentant: null,
    filterByCampaignId: null,
    pagination: {},
    campaigns: [],
    ads: [],
    loadingChart: false,
    loadingPDF: false,
    chartData: Ember.computed('model', 'reportTypeSelected', function () {
      let model = this.get('model');
      let modelValue = model.get('modelName') === 'campaign' ? 'leadsCount' : 'callsCount';
      let array = model.map(function (item) {
        return { key: Ember.get(item, 'name'), value: Ember.get(item, modelValue), id: Ember.get(item, 'id') };
      }.bind(this));
      if (Ember.isEmpty(array)) {
        return [{ key: '', value: 0 }];
      }
      return array;
    }),
    // properties
    popover: null,
    reportTypes: Ember.computed('accountService.customersText', 'features.uniqueLeadsReportsAvailable', 'features.outboundCallsReportsAvailable', function () {
      let customersText = this.get('accountService.customersText');
      let types = [{ id: 'leads_pipeline', name: 'Leads Pipeline' }, { id: 'customers_pipeline', name: `${customersText} Pipeline` }, { id: 'customers_and_leads_pipeline', name: `${customersText} and Leads Pipeline` }, { id: 'customers_revenue', name: `${customersText} Revenue` }];
      if (this.get('features.uniqueLeadsReportsAvailable')) {
        types.push({ id: 'unique_leads_per_campaign', name: 'Unique Leads Per Campaign' });
        types.push({ id: 'unique_leads_per_campaign_details', name: 'Unique Leads Per Campaign - Details' });
      }
      if (this.get('features.outboundCallsReportsAvailable')) {
        types.push({ id: 'outbound_calls_per_user', name: 'Outbound Calls Per User' });
      }
      return types;
    }),
    reportTypeFieldMapping: {
      leads_pipeline: { table: 'leads', endpoint: 'leads/pipeline' },
      customers_pipeline: { table: 'customers', endpoint: 'customers/pipeline' },
      customers_and_leads_pipeline: { table: 'customers-and-leads', endpoint: 'customers/pipeline' },
      customers_revenue: { table: 'customer-revenue', endpoint: 'customers/revenue' },
      unique_leads_per_campaign: { table: 'unique-leads-per-campaign', endpoint: 'campaigns/leads' },
      unique_leads_per_campaign_details: { table: 'unique-leads-per-campaign-details', endpoint: 'campaigns/leads' },
      outbound_calls_per_user: { table: 'outbound-calls-per-user', endpoint: 'calls/user' }
    },
    dateFilterPromptText: Ember.computed('filter', function () {
      let text;
      switch (this.get('filter')) {
        case 'action_required':
          text = 'Filter by Due Date';
          break;
        case 'past_due_action':
          text = 'Filter by Due Date';
          break;
        case 'converted':
          text = 'Filter by Conversion Date';
          break;
        default:
          text = 'Filter by Date';
      }
      return text;
    }),
    filtering: Ember.computed('campaign_id', 'filter', 'from_date', 'to_date', function () {
      return Ember.isPresent(this.get('campaign_id')) || Ember.isPresent(this.get('filter')) || Ember.isPresent(this.get('from_date')) || Ember.isPresent(this.get('to_date'));
    }),
    onReportTypeSelected: Ember.observer('reportTypeSelected', function () {
      if (['outbound_calls_per_user'].includes(this.get('reportTypeSelected'))) {
        this.set('campaign_id', null);
      }
      // user 30 days for Unique Leads per Campaign (Graph or Details) and Outbound calls per User
      if (Ember.isBlank(this.get('from_date')) && Ember.isBlank(this.get('from_date')) && ['outbound_calls_per_user', 'unique_leads_per_campaign', 'unique_leads_per_campaign_details'].includes(this.get('reportTypeSelected'))) {
        this.setProperties({
          from_date: (0, _moment.default)().subtract(29, 'day').format('MM-DD-YYYY'),
          to_date: (0, _moment.default)().format('MM-DD-YYYY')
        });
      }
    }),
    onDataChanged: Ember.observer('data', function () {
      if (this.get('popover')) {
        this.get('popover').style('display', 'none');
      }
    }),
    leads: Ember.computed.filter('model', function (resource) {
      return !resource.get('isConverted');
    }),
    customers: Ember.computed.filter('model', function (resource) {
      return resource.get('isConverted');
    }),
    table: Ember.computed('reportTypeSelected', function () {
      let tableName,
          reportType = this.get('reportTypeSelected');
      tableName = this.get(`reportTypeFieldMapping.${reportType}.table`);
      return `reports/${tableName}-table`;
    }),
    pipelineTotal: Ember.computed('model.@each.pipeline', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('pipeline');
      }, 0);
    }),
    revenueTotal: Ember.computed('model.@each.revenue', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('revenue');
      }, 0);
    }),
    totalOpportunitiesConverted: Ember.computed('model.@each.opportunitiesConverted', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('opportunitiesConverted.length');
      }, 0);
    }),
    totalOpportunitiesNotConverted: Ember.computed('model.@each.opportunitiesNotConverted', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('opportunitiesNotConverted.length');
      }, 0);
    }),
    reportUrl: Ember.computed('model', 'reportTypeSelected', function () {
      let reportType = this.get('reportTypeSelected');
      let mapping = this.get(`reportTypeFieldMapping.${reportType}`);
      return `${host}/${namespace}/${version}/reports/${mapping.endpoint}`;
    }),
    pdfUrl: Ember.computed('reportTypeSelected', function () {
      return `${this.get('reportUrl')}?format=pdf`;
    }),
    csvUrl: Ember.computed('reportTypeSelected', function () {
      return `${this.get('reportUrl')}.csv`;
    }),
    xlsUrl: Ember.computed('reportTypeSelected', function () {
      return `${this.get('reportUrl')}.xls`;
    }),
    // computed properties for data
    jsonData: Ember.computed('model', 'reportTypeSelected', function () {
      let array = this.get('model').map(function (item) {
        return { key: Ember.get(item, 'name'), value: Ember.get(item, 'leadsCount') };
      }.bind(this));
      if (Ember.isEmpty(array)) {
        return [{ key: '', value: 0 }];
      }
      return array;
    }),
    processDateFilterParams: function (params) {
      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    },
    actions: {
      filteredLeads: function (campaign) {
        let options = {
          campaign_id: campaign.get('id'),
          filter: ['all']
        };
        let dateOptions = { from_date: this.get('from_date'), to_date: this.get('to_date') };
        let formattedDateOptions = this.processDateFilterParams(dateOptions);
        if (Ember.isPresent(formattedDateOptions.from_date) && Ember.isPresent(formattedDateOptions.to_date)) {
          Ember.assign(options, formattedDateOptions);
        }

        return this.get('store').query('lead', options);
      },
      onClick: function (modelName, resourceId) {
        if (modelName == 'campaign') {
          this.transitionToRoute('leads.index', {
            queryParams: {
              campaign_id: resourceId,
              filter: 'all_leads_and_customers',
              from_date: this.get('from_date'),
              to_date: this.get('to_date')
            }
          });
        } else if (modelName == 'user') {
          this.transitionToRoute('calls', {
            queryParams: {
              type: 'outbound',
              user_id: resourceId,
              from_date: this.get('from_date'),
              to_date: this.get('to_date')
            }
          });
        }
      },
      sort: function (toSortBy) {
        var sortBy = this.get('sort_by') || 'name';
        var order = this.get('order');
        if (sortBy === toSortBy) {
          order = order === 'asc' ? 'desc' : 'asc';
        } else {
          sortBy = toSortBy;
          order = 'desc';
        }
        this.set('sort_by', sortBy);
        this.set('order', order);
        this.send('setOrder', sortBy, order);
      },
      remove: function (resource) {
        resource.deleteRecord();
      },
      printReport: function () {
        window.print();
      },
      pdfReport: function (format) {
        let controller = this;
        let options = {
          format: format,
          report_type: controller.get('reportTypeSelected')
        };
        if (Ember.isPresent(controller.get('from_date')) && Ember.isPresent(controller.get('to_date'))) {
          options.from_date = (0, _moment.default)(controller.get('from_date'), "MM-DD-YYYY").format('DD-MM-YYYY');
          options.to_date = (0, _moment.default)(controller.get('to_date'), "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        if (Ember.isPresent(controller.get('campaign_id'))) {
          options.campaign_id = controller.get('campaign_id');
        }

        let adapter = Ember.getOwner(controller).lookup('adapter:report');
        let reportUrl = adapter.buildURL(`reports/${controller.get(`reportTypeFieldMapping.${controller.get('reportTypeSelected')}.endpoint`)}`, null, null, null, options);

        let reportRequest = adapter.ajax(reportUrl, 'GET', { processData: false, dataType: 'text' });
        controller.set('loadingPDF', true);
        reportRequest.then(function (result) {
          console.log(result);
          controller.set('loadingPDF', false);
          window.open(result);
        }, function () {
          controller.set('loadingPDF', false);
          console.log(arguments);
        });
      }
    }
  });

  exports.default = ReportsIndexController;
});