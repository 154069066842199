define('knowme-client/controllers/account/settings/incentives/show', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    contactsLimit: 5,
    showAllSentTo: false,
    showAllClaimed: false,
    showAllNewContacts: false,
    incentivesContactSentViaEmail: Ember.computed.filter('incentivesContacts', function (ic) {
      return ic.get('incentivesContactTargetType') === 'ContactEmail';
    }),
    incentivesContactSentViaMobile: Ember.computed.filter('incentivesContacts', function (ic) {
      return ic.get('incentivesContactTargetType') === 'ContactPhoneNumber';
    }),
    emailTo: Ember.computed.mapBy('incentivesContactSentViaEmail', 'contact'),
    textedTo: Ember.computed.mapBy('incentivesContactSentViaMobile', 'contact'),
    claimedContacts: Ember.computed('incentivesContacts', function () {
      return this.get('incentivesContacts').filterBy('claimed', true); //.mapBy('contact');
    }),
    respondedContacts: Ember.computed('incentivesContacts', function () {
      return this.get('incentivesContacts').map(function (x) {
        return x.get('respondedContacts').toArray();
      });
    }),
    newContacts: Ember.computed('respondedContacts', function () {
      return _lodash.default.flatten(this.get('respondedContacts'));
    })
  });
});