define('knowme-client/serializers/lead-activity', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      body: 'data',
      owner: { deserialize: 'id', serialize: false },
      communication: { deserialize: 'id', serialize: false },
      opportunity: { deserialize: 'id', serialize: false },
      lead: { deserialize: 'id', serialize: false }
    },
    normalize(typeClass, hash /*, prop*/) {
      this.camelizeKeys(hash.attributes.data);
      return this._super(...arguments);
    }
  });
});