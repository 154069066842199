define('knowme-client/components/date-filter-select', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function formatDate(date) {
    return date.format('MM-DD-YYYY'); //FIXME: Rails expects DD-MM-YYYY instead of MM-DD-YY
  }

  exports.default = Ember.Component.extend({
    fromDate: null,
    toDate: null,
    customFromDate: null,
    customToDate: null,
    dateFilter: null,
    dateFilterCustomSelectorDisplayed: false,
    placeholder: 'Filter by Date',
    dateFilterOptions: ['placeholder', 'today', 'todayAndYesterday', 'todayAndPrevious2Days', 'todayAndPrevious6Days', 'todayAndPrevious29Days', 'previousMonth', 'yearToDate', 'previousYear', 'custom'],
    month: null,
    year: null,
    didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('month', (0, _moment.default)().subtract(1, 'month'));
      this.set('year', (0, _moment.default)().subtract(1, 'year'));
      if (Ember.isPresent(this.get('fromDate')) || Ember.isPresent(this.get('toDate'))) {
        let today = (0, _moment.default)();
        let fromDate = (0, _moment.default)(this.get('fromDate'), "MM-DD-YYYY");
        let toDate = (0, _moment.default)(this.get('toDate'), "MM-DD-YYYY");
        let toDateIsToday = today.diff(toDate, 'days') === 0;
        let diffDays = toDate.diff(fromDate, 'days');
        let diffMonths = today.diff(fromDate, 'months');
        let diffYears = today.diff(fromDate, 'years');
        let dateChanged = false;
        switch (diffDays) {
          case 0:
            this.set('dateFilter', 'today');
            break;
          case 1:
            this.set('dateFilter', 'todayAndYesterday');
            break;
          case 2:
            this.set('dateFilter', 'todayAndPrevious2Days');
            break;
          case 6:
            this.set('dateFilter', 'todayAndPrevious6Days');
            break;
          case 29:
            this.set('dateFilter', 'todayAndPrevious29Days');
            break;
          default:
            dateChanged = true;
            break;
        }
        if (diffMonths === 1 && diffDays < 31 && this.set('dateFilter') !== 'todayAndPrevious29Days') {
          this.set('dateFilter', 'previousMonth');
          dateChanged = false;
        }
        if (toDateIsToday && fromDate.diff((0, _moment.default)().startOf('year')) === 0) {
          this.set('dateFilter', 'yearToDate');
          dateChanged = false;
        }
        if (today.year() - toDate.year() === 1 && toDate.year() === fromDate.year() && toDate.format('DD-MM') == '31-12' && fromDate.format('DD-MM') == '01-01') {
          this.set('dateFilter', 'previousYear');
          dateChanged = false;
        }
        if (dateChanged) {
          this.set('dateFilter', 'custom');
        }
      }
    },
    dateFilterChanged: Ember.observer('dateFilter', function () {
      var _ref = this;
      var filter = _ref.get('dateFilter');
      if (filter !== 'custom') {
        this.setProperties({ customFromDate: null, customToDate: null });
      }
      this.set('dateFilterChangedCustomSelectorDisplayed', false);
      var options = {
        // Past
        todayAndYesterday: function () {
          var fromDate = (0, _moment.default)().subtract(1, 'day').startOf('day');
          var toDate = (0, _moment.default)();
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        todayAndPrevious2Days: function () {
          var fromDate = (0, _moment.default)().subtract(2, 'day').startOf('day');
          var toDate = (0, _moment.default)();
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        todayAndPrevious6Days: function () {
          var fromDate = (0, _moment.default)().subtract(6, 'day').startOf('day');
          var toDate = (0, _moment.default)();
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        todayAndPrevious29Days: function () {
          var fromDate = (0, _moment.default)().subtract(29, 'day').startOf('day');
          var toDate = (0, _moment.default)();
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        previous3Days: function () {
          var fromDate = (0, _moment.default)().subtract(3, 'day').startOf('day');
          var toDate = (0, _moment.default)().subtract(1, 'day').endOf('day');
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        previousMonth: function () {
          var fromDate = (0, _moment.default)().subtract(1, 'months').startOf('month').startOf('day');
          var toDate = (0, _moment.default)().subtract(1, 'months').endOf('month').endOf('day');
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        yearToDate: function () {
          var fromDate = (0, _moment.default)().startOf('year');
          var toDate = (0, _moment.default)();
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        previousYear: function () {
          var fromDate = (0, _moment.default)().subtract(1, 'year').startOf('year');
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(fromDate.endOf('year')) });
        },
        // Future
        todayAndNext6Days: function () {
          var fromDate = (0, _moment.default)().startOf('day');
          var toDate = (0, _moment.default)().add(6, 'day').endOf('day');
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        todayAndNext29Days: function () {
          var fromDate = (0, _moment.default)().startOf('day');
          var toDate = (0, _moment.default)().add(29, 'day').endOf('day');
          return _ref.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        // Others
        today: function () {
          var today = (0, _moment.default)();
          return _ref.setProperties({ fromDate: formatDate(today), toDate: formatDate(today) });
        },
        custom: function () {
          return _ref.set('dateFilterCustomSelectorDisplayed', !_ref.get('dateFilterCustomSelectorDisplayed'));
        }
      };
      var fnc = options[filter];
      if (Ember.isPresent(fnc)) {
        if (this.get('dateFilterOptions').indexOf('placeholder') === -1) {
          this.get('dateFilterOptions').unshiftObject('placeholder');
        }
        return fnc();
      } else {
        this.get('dateFilterOptions').removeObject('placeholder');
        return _ref.setProperties({ fromDate: null, toDate: null });
      }
    }),
    customFromDateStartDate: Ember.computed('filter', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        val = (0, _moment.default)().format('L');
      }
      if (filter === 'past_due_action') {
        val = null;
      }
      return val;
    }),
    customFromDateEndDate: Ember.computed('filter', 'customToDate', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = null;
        }
      }
      if (filter === 'past_due_action') {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = (0, _moment.default)().subtract(1, 'day').endOf('day').format('L');
        }
      }
      if (filter === null) {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = (0, _moment.default)().endOf('day').format('L');
        }
      }
      return val;
    }),
    customToDateStartDate: Ember.computed('customFromDate', function () {
      let val;
      if (Ember.isPresent(this.get('customFromDate'))) {
        val = (0, _moment.default)(this.get('customFromDate')).format('L');
      } else {
        val = null;
      }
      return val;
    }),
    customToDateEndDate: Ember.computed('customToDate', function () {
      let val;
      if (Ember.isPresent(this.get('customToDate'))) {
        val = (0, _moment.default)(this.get('customToDate')).format('L');
      } else {
        val = null;
      }
      return val;
    }),
    onFromDateChange: Ember.observer('fromDate', function () {
      this.updateDate('from_date', this.get('fromDate'));
      if (Ember.isBlank(this.get('fromDate')) && Ember.isBlank(this.get('toDate'))) {
        this.set('dateFilter', null);
        this.set('dateFilterCustomSelectorDisplayed', false);
      }
    }),
    onToDateChange: Ember.observer('toDate', function () {
      this.updateDate('to_date', this.get('toDate'));
      if (Ember.isBlank(this.get('fromDate')) && Ember.isBlank(this.get('toDate'))) {
        this.set('dateFilter', null);
        this.set('dateFilterCustomSelectorDisplayed', false);
      }
    }),
    actions: {
      setCustomDates: function () {
        var fromDate = this.get('customFromDate');
        if (Ember.isPresent(fromDate)) {
          fromDate = formatDate((0, _moment.default)(fromDate));
        }
        var toDate = this.get('customToDate');
        if (Ember.isPresent(toDate)) {
          toDate = formatDate((0, _moment.default)(toDate));
        }
        this.setProperties({ fromDate: fromDate, toDate: toDate });
        return this.set('dateFilterCustomSelectorDisplayed', false);
      },
      closeDateFilterCustomSelector: function () {
        return this.set('dateFilterCustomSelectorDisplayed', false);
      }
    }
  });
});