define('knowme-client/helpers/format-seconds', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatSeconds = formatSeconds;
  function formatSeconds(params /*, hash*/) {
    let seconds = parseInt(params[0] || 0);
    let minutes = 0;
    if (seconds > 59) {
      minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${minutes}:${seconds}`;
  }

  exports.default = Ember.Helper.helper(formatSeconds);
});