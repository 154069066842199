define('knowme-client/controllers/leads/show/contacts/selector', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let LeadsShowContactsSelectorController;

	LeadsShowContactsSelectorController = Ember.Controller.extend({
		contactId: null,
		contacts: Ember.computed('model.contacts', function () {
			return this.get('model.contacts');
		}),
		selectedContact: Ember.computed('parentController.contact', function () {
			return this.get('parentController.contact');
		}),
		actions: {
			selectContact: function () {
				return true;
			}
		}
	});

	exports.default = LeadsShowContactsSelectorController;
});