define('knowme-client/components/chat-touch-point/resource-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    daysOfWeek: [{ key: 'mon', title: 'Mon' }, { key: 'tue', title: 'Tue' }, { key: 'wed', title: 'Wed' }, { key: 'thu', title: 'Thu' }, { key: 'fri', title: 'Fri' }, { key: 'sat', title: 'Sat' }, { key: 'sun', title: 'Sun' }],
    defaultWorkTimes: {
      mon: {
        open: true,
        from: { h: '09', m: '00', ampm: 'AM' },
        to: { h: '06', m: '00', ampm: 'PM' }
      },
      tue: {
        open: true,
        from: { h: '09', m: '00', ampm: 'AM' },
        to: { h: '06', m: '00', ampm: 'PM' }
      },
      wed: {
        open: true,
        from: { h: '09', m: '00', ampm: 'AM' },
        to: { h: '06', m: '00', ampm: 'PM' }
      },
      thu: {
        open: true,
        from: { h: '09', m: '00', ampm: 'AM' },
        to: { h: '06', m: '00', ampm: 'PM' }
      },
      fri: {
        open: true,
        from: { h: '09', m: '00', ampm: 'AM' },
        to: { h: '06', m: '00', ampm: 'PM' }
      },
      sat: {
        open: false,
        from: { h: '09', m: '00', ampm: 'AM' },
        to: { h: '06', m: '00', ampm: 'PM' }
      },
      sun: {
        open: false,
        from: { h: '09', m: '00', ampm: 'AM' },
        to: { h: '06', m: '00', ampm: 'PM' }
      }
    },
    hours: Array.apply(null, Array(12)).map(function (v, i) {
      if (i < 9) {
        return '0' + (i + 1);
      } else {
        return i + 1;
      }
    }),
    minutes: Array.apply(null, Array(12)).map(function (v, i) {
      if (i < 2) {
        return '0' + i * 5;
      } else {
        return i * 5;
      }
    }),
    amPm: ['AM', 'PM'],
    didReceiveAttrs() {
      this._super.apply(this, arguments);
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      this.set('host', adapter.buildURL(null, null, null, "host"));
      if (this.get('model.isNew') || Ember.isBlank(this.get('model.workTimes')) || Object.keys(this.get('model.workTimes')).length === 0) {
        this.set('model.workTimes', this.get('defaultWorkTimes'));
      }
    },
    users: Ember.computed('model', function () {
      return this.get('store').query('user', { paginate: false, status: 'active', sort_by: 'name', order: 'asc' });
    }),
    selectedMemberNames: Ember.computed('model.chatTouchPointMembers.@each.isDeleted', 'model.chatTouchPointMembers.@each.user.fullName', function () {
      return this.get('model.chatTouchPointMembers').filterBy('isDeleted', false).mapBy('user.fullName').join(', ');
    }),
    memberIds: Ember.computed('model.chatTouchPointMembers.@each.isDeleted', 'model.chatTouchPointMembers.@each.user', function () /*resource*/{
      return this.get('model.chatTouchPointMembers').filterBy('isDeleted', false).mapBy('user.id');
    }),
    actions: {
      toggleDay: function (day) {
        this.set(`model.workTimes.${day}.open`, !this.get(`model.workTimes.${day}.open`));
      },
      addUser: function (user) {
        let member = this.get('model.chatTouchPointMembers').findBy('user.id', user.get('id'));
        if (Ember.isPresent(member)) {
          member.rollbackAttributes();
        } else {
          this.get('store').createRecord('chat-touch-point-member', { chatTouchPoint: this.get('model'), user: user });
        }
      },
      removeUser: function (user) {
        let member = this.get('model.chatTouchPointMembers').findBy('user.id', user.get('id'));
        member.deleteRecord();
      }
    }
  });
});