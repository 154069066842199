define('knowme-client/models/alert', ['exports', 'ember-data/model', 'ember-data/attr', 'knowme-client/mixins/models/validations/alert', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'knowme-client/mixins/validate-belongs-to', 'ember-data/relationships'], function (exports, _model, _attr, _alert, _emberCpValidationsCustomProperties, _dirty, _validateBelongsTo, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_alert.default, _emberCpValidationsCustomProperties.default, _dirty.default, _validateBelongsTo.default, {
    key: (0, _attr.default)(),
    recipients: (0, _attr.default)(),
    recipientsEmails: (0, _attr.default)(),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    allCampaigns: (0, _attr.default)('boolean'),
    allAds: (0, _attr.default)('boolean'),
    campaign: (0, _relationships.belongsTo)('campaign', { async: true }),
    ad: (0, _relationships.belongsTo)('ad', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true })
  });
});