define('knowme-client/routes/account/settings/appointment-reminders', ['exports', 'moment', 'lodash', 'knowme-client/mixins/routes/account-setup', 'knowme-client/lib/poller'], function (exports, _moment, _lodash, _accountSetup, _poller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    interval: 60, //in seconds
    poller: null,
    pausePoller: false,
    queryParams: {
      user_id: {
        refreshModel: true
      },
      delivery_status: {
        refreshModel: true
      },
      reminder_type: {
        refreshModel: true
      },
      from_date: { refreshModel: true },
      to_date: { refreshModel: true }
    },
    pagination: {},
    appointmentStage: null,
    session: Ember.inject.service(),
    model: function (params) {
      let promise = null;
      if (Ember.isPresent(params) && ['emailed', 'texted'].includes(params.delivery_status)) {
        if (this.get('session.currentUser.hasAdminRole')) {
          let options = { reminders: this.processParams(params), filter: 'with_sent_reminders' };
          promise = this.get('store').query('user', options);
        } else {
          promise = this.get('store').query('sent-reminder', this.processParams(params));
        }
      } else {
        promise = this.get('store').query('reminder', this.processParams(params));
      }
      promise.then(function (result) {
        this.set('pagination.total', result.meta.pagination.totalCount);
        this.set('pagination.limit', result.meta.pagination.page.size);
        this.set('pagination.page', result.meta.pagination.page.number);
      }.bind(this));
      return promise;
    },
    afterModel: function (model, transition) {
      if (this.get('session.currentUser.hasAdminRole')) {
        let filter = ['emailed', 'texted'].includes(transition.queryParams.delivery_status) ? 'with_sent_reminders' : 'with_reminders';
        let options = { paginate: false, status: 'active', sort_by: 'name', order: 'asc', reminders: this.processParams(transition.queryParams), filter: filter };
        options.user_id = null;
        return Ember.RSVP.hash({
          userAppointments: this.get('store').query('user', options)
        }).then(function (results) {
          this.set('controllerData', results);
        }.bind(this));
      }
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      let isFormPage = ["/admin/settings/appointment-reminders/new", "/admin/settings/appointment-reminders/edit"].includes(this.router.currentURL);
      // Model is already set, so we have to explicitly update controller
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
      if (controller.get('isDeliveredStatus')) {
        controller.calcSentReminders();
      }
      controller.set('isLoaded', true);
      // setup auto refreshing
      if (Ember.isPresent(this.get('poller'))) {
        this.get('poller').stop();
      } else {
        this.set('poller', new _poller.default());
      }

      let poller = this.get('poller');
      let interval = 30;
      if (!controller.get('isDeliveredStatus')) {
        // setup poller to execute every minutes from start of minute
        let date = new Date();
        let now = (0, _moment.default)(date);
        let endOfMinute = (0, _moment.default)(date);

        endOfMinute.endOf('minute').add(2, 'seconds');
        let interval = endOfMinute.diff(now, 'seconds');

        if (interval === 0) {
          interval = 60;
        }
      }
      console.log(`${(0, _moment.default)().format('HH:mm:ss')}`, 'calculated interval:', interval);

      poller.setInterval(interval * 1000);
      poller.start(this, function () {
        this.refresh();
      });
      if (isFormPage) {
        this.set('pausePoller', true);
      }
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.get('poller').stop();
        controller.set('isLoaded', false);
      }
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);
      if (Ember.isBlank(params.user_id)) {
        delete params.user_id;
      }
      if (Ember.isBlank(params.delivery_status)) {
        params.delivery_status = 'not_delivered';
      }

      params = _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
      return params;
    },
    actions: {
      willTransition: function (transition) {
        if (["account.settings.appointment-reminders.new", "account.settings.appointment-reminders.edit"].includes(transition.targetName)) {
          this.set('pausePoller', true);
        }
      },
      didTransition: function (transition) {
        this.set('pausePoller', false);
      }
    }
  });
});