define('knowme-client/transforms/json', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize(serialized) {
      return serialized;
    },

    serialize(deserialized) {
      return deserialized;
    }
  });
});