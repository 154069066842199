define('knowme-client/controllers/account/settings/contact-fields', ['exports', 'knowme-client/mixins/controllers/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, {
    selectedFields: Ember.computed.filter('model', function (resource) {
      return resource.get('enable');
    }),
    positionOptions: Ember.computed('selectedFields', 'selectedFields.length', function () {
      return Array.from({ length: this.get('selectedFields.length') }, (v, k) => k + 1);
    }),
    onEnable: Ember.observer('model.@each.enable', function () {
      let length = this.get('model').filterBy('enable', true).get('length');
      this.set('positionOptions', Array.from({ length: length }, (v, k) => k + 1));
    }),
    actions: {
      saveField: function (resource) {
        resource.set('enable', !resource.get('enable'));
        resource.save().then(function () {
          this.send('reloadModel');
        }.bind(this));
      },
      updatePosition: function (resource) {
        resource.save().then(function () {
          this.send('reloadModel');
        }.bind(this));
      },
      destroy: function (resource) {
        resource.destroyRecord();
        this.transitionToRoute('account.settings.organization-fields');
        this.get('target._routerMicrolib').refresh();
      }
    }
  });
});