define('knowme-client/models/attachment', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'knowme-client/config/environment'], function (exports, _relationships, _attr, _model, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // message: belongsTo('message', {async: true, inverse: 'attachments'}),
    record: (0, _relationships.belongsTo)('attachment-resource', { async: true, polymorphic: true, inverse: 'attachments' }),
    name: (0, _attr.default)(),
    url: (0, _attr.default)(),
    fileSize: (0, _attr.default)(),
    recordType: Ember.computed('record', function () {
      if (this.get('record').content) {
        return this.get('record').content.constructor.modelName.camelize().capitalize();
      } else {
        return this.get('record').constructor.modelName.camelize().capitalize();
      }
    }),
    fullUrl: Ember.computed('url', function () {
      return `${_environment.default.APP.email_service.scheme}://${_environment.default.APP.email_service.host}${this.get('url')}`;
    })
  });
});