define('knowme-client/mixins/models/validations/order', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    quantity: [(0, _emberCpValidations.validator)('presence', true, { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0,
      lte: Ember.computed('model.quantity', 'model.product.quantity', 'model.product.soldNumber', 'model.opportunity.isClosed', function () {
        if (this.get('model.opportunity.isClosed')) {
          let quantity_was = this.get('model.quantity');
          if (Ember.isPresent(this.get('model').changedAttributes().quantity)) {
            let changes = this.get('model').changedAttributes().quantity;
            quantity_was = changes[0];
          }
          return this.get('model.product.quantity') - this.get('model.product.soldNumber') + quantity_was;
        } else {
          return this.get('model.product.quantity') - this.get('model.product.soldNumber');
        }
      }),
      disabled: Ember.computed('model.product.quantity', function () {
        return Ember.isBlank(this.get('model.product.quantity'));
      }),
      message: Ember.computed('model.quantity', function () {
        if (Ember.isBlank(this.get('model.quantity')) || isNaN(this.get('model.quantity'))) {
          return 'This field can\'t be blank';
        }
        if (this.get('model.quantity') <= 0) {
          return 'Cannot be less than zero';
        }
        return 'You don\'t have sufficient inventory. There are only {lte} left';
      })
    })],
    dealPrice: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      dependentKeys: ['model.product.fixedPrice', 'model.quantity', 'model.product.id'],
      disabled: Ember.computed('model.product.fixedPrice', 'model.quantity', 'model.product.id', function () {
        return !this.get('model.product.fixedPrice') || Ember.isBlank(this.get('model.quantity')) && Ember.isBlank(this.get('model.product.id'));
      })
    }), (0, _emberCpValidations.validator)('length', { max: 18, allowBlank: true })],
    discount: (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
      return !model.get('product.fixedPrice') || value <= model.get('product.maxDiscount') ? true : 'cannot be greater than product max discount';
    }, {
      dependentKeys: ['model.product.maxDiscount']
    }),
    product: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)(function (value, options, model, attribute) {
      if (model.get('opportunity.isNew')) {
        return model.get('product.isSoldOut') ? 'This product has been sold out' : true;
      }

      let initialStage = null;
      if (model.get('opportunity.salesStageHasChanged')) {
        initialStage = model.get('opportunity').get('sortedActivities.firstObject').get('body.opportunity').salesStage.key;
      } else {
        initialStage = model.get('opportunity.salesStageKey');
      }
      return model.get('product.isSoldOut') && initialStage && initialStage !== 'closed' ? 'This product has been sold out' : true;
    })]
  });
});