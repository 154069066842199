define('knowme-client/controllers/leads/show/opportunities/index', ['exports', 'ember-local-storage', 'knowme-client/mixins/controllers/email-integration'], function (exports, _emberLocalStorage, _emailIntegration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowOpportunitiesIndexController;

  LeadsShowOpportunitiesIndexController = Ember.Controller.extend(_emailIntegration.default, {
    queryParams: ['contact_phone_number_id', 'contact_id', 'opportunity_id'],
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    applicationController: Ember.inject.controller('application'),
    contactsController: Ember.inject.controller('leads.show.contacts'),
    features: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    init: function () {
      this._super.apply(this, arguments);
      Ember.run(function () {
        Ember.run.schedule("afterRender", this, function () {
          Ember.$("body").tooltip({ selector: "table [data-toggle='tooltip']" });
        });
      });
    },
    isOpportunityMerging: Ember.computed('opportunity_id', function () {
      return Ember.isPresent(this.get('opportunity_id'));
    }),
    onMerging: Ember.observer('opportunity_id', function () {
      if (Ember.isPresent(this.get('opportunity_id'))) {
        this.set('applicationController.isOpportunityMerging', true);
        this.set('applicationController.opportunityId', this.get('opportunity_id'));
      } else {
        this.set('applicationController.isOpportunityMerging', null);
        this.set('applicationController.opportunityId', null);
      }
    }),
    opportunity: Ember.computed('lead.opportunities.firstObject', function () {
      return this.get('lead.opportunities.firstObject');
    }),
    selectedOpportunity: Ember.computed('model', function () {
      if (this.get('model.length') === 1) {
        return this.get('model.firstObject');
      } else {
        return null;
      }
    }),
    lastOpportunity: (0, _emberLocalStorage.storageFor)('last-opportunity'),
    contact: Ember.computed('lead', 'lead.primaryContact', function () {
      return this.get('lead.primaryContact');
    }),
    closedOpportunities: Ember.computed.filter('model', function (opportunity) {
      return opportunity.get('isClosed');
    }),
    nonClosedOpportunities: Ember.computed.filter('model', function (opportunity) {
      return !opportunity.get('isClosed');
    }),
    sorting: ['lastActivityDate:desc'],
    sortedNonClosedOpportunities: Em.computed.sort('nonClosedOpportunities', 'sorting'),
    actions: {
      merge: function (resource, opportunityId) {
        resource.set('opportunityId', opportunityId);
        resource.save().then(function () {
          this.get('lead').reload().then(function () {
            this.set('opportunity_id', null);
            this.transitionToRoute('leads.show.opportunities.show', resource.get('id'));
          }.bind(this));
        }.bind(this));
      },
      startListening: function () {
        this.send('pauseRefreshing');
      },
      stopListening: function () {
        this.send('continueRefreshing');
      },
      toggleContactsSelector: function () {
        this.set('displayContactSelector', !this.get('displayContactSelector'));
      },
      selectContact: function (contact) {
        this.set('contact', contact);
      },
      openPlayer: function (opportunity, activity) {
        let controller = this;
        controller.transitionToRoute('leads.show.opportunities.show', this.get('lead.id'), opportunity.id).then(function () {
          Ember.run.schedule('afterRender', this, function () {
            this.set('selectedActivity', activity);
            activity.set('play', true);
          });
        }.bind(this));
      },
      afterSend: function () {}
    },
    collapsedRevenueTable: true,
    collapsedUnassignedCommunicationsTable: true
  });

  exports.default = LeadsShowOpportunitiesIndexController;
});