define('knowme-client/controllers/leads/show/opportunities/form', ['exports', 'knowme-client/controllers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowOpportunitiesFormController;

  LeadsShowOpportunitiesFormController = _application.default.extend();

  exports.default = LeadsShowOpportunitiesFormController;
});