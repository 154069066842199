define('knowme-client/adapters/plan', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let LeadAdapter;

	LeadAdapter = _application.default.extend({
		queryUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?sort_by,order,key[],paginate}'
	});

	exports.default = LeadAdapter;
});