define('knowme-client/services/is-mobile', ['exports', 'ismobilejs/services/is-mobile'], function (exports, _isMobile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isMobile.default;
    }
  });
});