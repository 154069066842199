define('knowme-client/mixins/dirty', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let DirtyMixin;

  DirtyMixin = Ember.Mixin.create({
    setupHasChanged: Ember.on('init', function () {
      let _this = this;
      this.eachAttribute(function (attribute /*, attributeMeta*/) {
        Ember.defineProperty(_this, `${attribute}HasChanged`, Ember.computed(attribute, function () {
          let newData = this.get(`_internalModel._inFlightAttributes.${attribute}`) || this.get(`_internalModel._attributes.${attribute}`);
          return Ember.isPresent(newData) && this.get(`_internalModel._data.${attribute}`) !== newData;
        }));
      });
      this.eachRelationship(function (relationship, relationshipMeta) {
        if (relationshipMeta.kind === 'belongsTo') {
          const basePath = `_internalModel._relationships.initializedRelationships.${relationship}`;
          Ember.defineProperty(_this, `${relationship}HasChanged`, Ember.computed(relationship, 'currentState.stateName', `${basePath}.canonicalMembers.list.0.id`, `${relationship}.id`, function () {
            let newId = this.get(`${relationship}.id`); // This must be before accessing canonicalMembers
            let initialId = this.get(`${basePath}.canonicalMembers.list.0.id`);

            return initialId !== newId;
          }));
          // Ember.defineProperty(_this, `${relationship}HasChanged`, Ember.computed(relationship, `${relationship}.id`, function () {
          //   let internalRelationship = this.get('_internalModel._relationships').get(relationship);
          //   let canonicalStateId = isPresent(internalRelationship.canonicalState) ? internalRelationship.canonicalState.id : undefined;
          //   return canonicalStateId !== this.get(`${relationship}.id`);
          // }));
        }
        if (relationshipMeta.kind === 'hasMany') {
          Ember.defineProperty(_this, `${relationship}HasChanged`, Ember.computed(`${relationship}.@each.id`, function () {
            let internalRelationship = this.get('_internalModel._relationships').get(relationship);
            let canonicalStateIds = Ember.isPresent(internalRelationship.canonicalState) ? internalRelationship.canonicalState.mapBy('id') : [];
            let relationshipIds = this.get(`${relationship}`).mapBy('id');
            return !_lodash.default.isEqual(canonicalStateIds, relationshipIds);
          }));
        }
      });
    }),
    changedRelationships() {
      //ids are always undefined until initialized
      if (this.get('_internalModel.dataHasInitialized') === false) {
        return;
      }

      let relationships = {};

      this.eachRelationship((name, meta) => {
        let basePath = `_internalModel._relationships.initializedRelationships.${name}`;
        let hasCanonical = this.get(`${basePath}.canonicalMembers.list.length`) > 0;

        if (meta.kind === 'belongsTo') {
          let current = this.get(`${name}`);

          let initial;
          if (hasCanonical) {
            let firstObject = this.get(`${basePath}.canonicalMembers.list`)[0];
            if (firstObject) {
              initial = firstObject;
            }
          }
          if (Ember.isBlank(initial) && Ember.isPresent(current) || Ember.isPresent(initial) && initial.id !== current.get('id')) {
            relationships[name] = [initial, current];
          }
        }
      });

      return relationships;
    }
  });

  exports.default = DirtyMixin;
});