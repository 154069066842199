define('knowme-client/serializers/users-dashboard-widget', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      dashboardWidget: { serialize: 'id', deserialize: 'records' },
      user: { serialize: false }
    },
    serialize: function (snapshot /*, options*/) {
      var json = this._super(...arguments);
      if (snapshot.record.get('isDeleted')) {
        json._destroy = true;
      }
      return json;
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.links = {
        dashboardWidget: Ember.getOwner(this).lookup('adapter:dashboard-widget').buildURL('dashboard-widget', hash.dashboard_widget.id, null, 'findRecord', null)
      };
      return this._super.apply(this, arguments);
    },
    keyForRelationship(key, relationship, method) {
      if (key === 'dashboard_widget' && method === 'serialize') {
        return `${underscore(key)}_attributes`;
      }
      return this._super(...arguments);
    }
  });
});