define('knowme-client/components/audio-reproducer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hifi: Ember.inject.service(),
    classNames: ["display-inline-block"],
    pauseButtonClass: "btn btn-xs btn-default",
    playButtonClass: "btn btn-xs btn-default",
    pauseIconClass: "glyphicon glyphicon-pause",
    playIconClass: "glyphicon glyphicon-play",
    playable: true,
    willDestroyElement: function () {
      this._super(...arguments);
      if (this.get('hifi.isPlaying')) {
        this.get('hifi').pause();
        this.set('hifi.position', 0);
      }
    },
    actions: {
      play() {
        if (this.get('playable')) {
          this.startListening();
          this.listened();
          this.get('hifi').play(this.get('url')).then(({ sound }) => {
            this.set('audio', sound);
            this.get('audio').on('audio-ended', function () {
              this.stopListening();
            }.bind(this));
          }).catch(error => {
            this.set('audio', null);
            console.log('Error', error);
          });
        }
      },
      pause() {
        this.stopListening();
        this.get('audio').pause();
      }
    }
  });
});