define('knowme-client/controllers/reports/scheduler', ['exports', 'knowme-client/mixins/controllers/report-schedule', 'knowme-client/mixins/controllers/pagination'], function (exports, _reportSchedule, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, _reportSchedule.default, {
    features: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    queryParams: ['reportType'],
    reportType: null,
    reportTypesOptions: Ember.computed('accountService.customersText', 'features.uniqueLeadsReportsAvailable', 'features.outboundCallsReportsAvailable', function () {
      let customersText = this.get('accountService.customersText');
      let types = [{ id: 'leads_pipeline', name: 'Leads Pipeline' }, { id: 'customers_pipeline', name: `${customersText} Pipeline` }, { id: 'customers_and_leads_pipeline', name: `${customersText} and Leads Pipeline` }, { id: 'customers_revenue', name: `${customersText} Revenue` }];
      if (this.get('features.activityUserAvailable')) {
        types.push({ id: 'activity_by_user', name: 'Activity by User' });
      }
      if (this.get('features.uniqueLeadsReportsAvailable')) {
        types.push({ id: 'unique_leads_per_campaign', name: 'Unique Leads Per Campaign' });
        types.push({ id: 'unique_leads_per_campaign_details', name: 'Unique Leads Per Campaign - Details' });
      }
      if (this.get('features.outboundCallsReportsAvailable')) {
        types.push({ id: 'outbound_calls_per_user', name: 'Outbound Calls Per User' });
      }
      return types;
    }),
    actions: {
      destroy: function (reportSchedule) {
        reportSchedule.destroyRecord().then(function () {
          this.transitionToRoute('reports.scheduler');
          this.get('target._routerMicrolib').refresh();
        }.bind(this)).catch(function () /*error*/{
          reportSchedule.rollbackAttributes();
        });
      }
    }
  });
});