define('knowme-client/controllers/knowme-iq-product', ['exports', 'knowme-client/mixins/products'], function (exports, _products) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KnowmeIQProductController;

  KnowmeIQProductController = Ember.Controller.extend(_products.default, {
    scroller: Ember.inject.service(),
    currentProduct: null,
    hideProductsBoxes: false,
    interval: 'annually',
    addOns: {
      usersCount: 0,
      contactsCount: 0,
      minutesCount: 0,
      textMessagesCount: 0,
      storageCount: 0,
      localNumbersCount: 0,
      tollNumbersCount: 0
    },
    selectedPlan: Ember.computed('currentProduct.key', 'currentPlan', 'model', function () {
      return this.get('model').findBy('key', `${this.get('currentProduct.key')}.${this.get('currentPlan')}`);
    }),
    product: Ember.computed.alias('currentProduct'),
    starter: true,
    starter_unlimited: false,
    premium: true,
    premium_unlimited: false,
    unlimited: false,
    plans: Ember.computed.filter('model', function (resource) {
      return ['starter', 'premium', 'starter_unlimited', 'premium_unlimited'].includes(resource.get('planKey'));
    }),
    promoCode: null,
    promoCodeLength: 8,
    servicePlans: [{ text: 'Starter', value: 'starter' }, { text: 'Starter Unlimited', value: 'starter_unlimited' }, { text: 'Premium', value: 'premium' }, { text: 'Premium Unlimited', value: 'premium_unlimited' }],
    accountSettings: ['account.billing_term', 'additional.users', 'additional.users.dids.local', 'additional.minutes', 'additional.short_messages', 'additional.storage', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local', 'additional.contacts'],
    transitioningToSignUpForm: false,
    unlimited: Ember.computed('starter_unlimited', 'premium_unlimited', function () {
      return this.get('starter_unlimited') || this.get('premium_unlimited');
    }),
    initSettings() {
      let _this = this;
      this.set('currentProduct', this.get('products').findBy('key', 'knowme_iq'));
      this.set('currentPlan', 'premium');
      this.setProperties({
        starter: true,
        starter_unlimited: false,
        premium: true,
        premium_unlimited: false
      });
      this.get('addOnsSections').forEach(function (item) {
        if (Ember.isPresent(this.get(`addOns.${item.key}`)) && this.get(`addOns.${item.key}`) > 0) {
          this.set(item.section, true);
        }
      }.bind(this));
      this.accountSettings.forEach(function (key) {
        let _key = key.camelize().capitalize();
        if (key === 'account.billing_term') {
          _this.set(`settings${_key}`, _this.get('store').createRecord('AccountSetting', { key: key, value: _this.get('interval') === 'annually' ? 12 : 1 }));
        } else {
          _this.set(`settings${_key}`, _this.get('store').createRecord('AccountSetting', { key: key, value: 0 }));
        }
        if (key !== 'account.billing_term') {
          var settingKey = key;
          Ember.defineProperty(_this, `settings${_key}Cost`, Ember.computed('currentProduct.key', 'interval', 'currentPlan', `settings${_key}.value`, 'promoCode', () => {
            if (_this.get('isCurrentProductBasicCRM') && ['additional.users.dids.local', 'additional.minutes', 'additional.short_messages', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local'].includes(settingKey)) {
              return 0;
            }
            var blockUnit = 1,
                unitPrice;
            if (Ember.isPresent(_this.get('planSettings')[_key.camelize() + 'Block'])) {
              blockUnit = _this.get('planSettings')[_key.camelize() + 'Block'];
              unitPrice = _this.get('planSettings')[_key.camelize() + 'BlockFee'];
            } else {
              if (['additional.users.dids.local', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local'].includes(settingKey)) {
                unitPrice = _this.get('planSettings')['additionalDidsFee'];
              } else {
                unitPrice = _this.get('planSettings')[_key.camelize() + 'Fee'];
              }
            }

            return parseInt(_this.get(`settings${_key}.value`), 10) / blockUnit * unitPrice;
          }));
        }
      });
    },
    subscriptionFee: Ember.computed("currentProduct.key", "currentPlan", "interval", "settingsAdditionalUsersCost", "settingsAdditionalUsersDidsLocalCost", "settingsAdditionalMinutesCost", "settingsAdditionalShortMessagesCost", "settingsAdditionalStorageCost", "settingsAdditionalCallTouchPointsDidsTollFreeCost", "settingsAdditionalCallTouchPointsDidsLocalCost", "settingsAdditionalContactsCost", "planSettings.planFee", "promoCode", function () {
      let total = ["settingsAdditionalUsersCost", "settingsAdditionalUsersDidsLocalCost", "settingsAdditionalMinutesCost", "settingsAdditionalShortMessagesCost", "settingsAdditionalStorageCost", "settingsAdditionalCallTouchPointsDidsTollFreeCost", "settingsAdditionalCallTouchPointsDidsLocalCost", "settingsAdditionalContactsCost", "planSettings.planFee"].reduce(function (previousValue, item) {
        if (item === 'planSettings.planFee' && Ember.isPresent(this.get('promoCode'))) {
          let withDiscount = this.get(item) - this.get(item) * this.get('promoCode.percentOff') / 100;
          return previousValue + withDiscount;
        } else {
          if (!isNaN(this.get(item))) {
            return previousValue + this.get(item);
          } else {
            return previousValue;
          }
        }ˇ;
      }.bind(this), 0);

      return total;
    }),
    planSettings: Ember.computed('selectedPlan.key', 'interval', 'promoCode', function () {
      if (this.get('selectedPlan.productKey') === "knowme_iq") {
        let settings = (this.get('selectedPlan.settings') || {})[this.get('interval')];
        let currentFee = settings.planFee;
        let additionalUsersFee = settings.additionalUsersFee;

        // apply promo code for additional users fee
        if (Ember.isPresent(this.get('promoCode'))) {
          currentFee = currentFee - currentFee * this.get('promoCode.percentOff') / 100;
          additionalUsersFee = additionalUsersFee - additionalUsersFee * this.get('promoCode.percentOff') / 100;
        }

        Ember.set(settings, 'additionalUsersFee', additionalUsersFee);

        return settings;
      } else {
        return (this.get('selectedPlan.settings') || {})[this.get('interval')];
      }
    }),
    onPlanChanged: Ember.observer('currentPlan', function () {
      this.set('promoCode', null);
      this.set('promoCodeId', null);
      if (this.get('currentPlan') === 'starter' || this.get('currentPlan') === 'starter_unlimited') {
        this.set('premium_unlimited', false);
        this.set('premium', true);
      }
      if (this.get('currentPlan') === 'premium' || this.get('currentPlan') === 'premium_unlimited') {
        this.set('starter_unlimited', false);
        this.set('starter', true);
      }
    }),
    features: {
      mainTools: [{
        title: "Number of users",
        key: 'includedUsers',
        type: 'integer'
      }, {
        key: 'includedUsersDidsLocal',
        type: 'integer',
        hasTooltip: true
      }, {
        key: 'includedCallTouchPointsDidsLocal',
        type: 'integer',
        hasTooltip: true
      }, {
        key: 'includedMinutes',
        type: 'integer'
      }, {
        key: 'includedShortMessages',
        type: 'integer'
      }, {
        key: 'includedStorage',
        type: 'integer'
      }, {
        key: 'includedContacts',
        type: 'integer'
      }, {
        key: 'crm',
        type: 'boolean'
      }, {
        key: 'emailsIntegration',
        type: 'boolean'
      }, {
        key: 'emailTemplates',
        type: 'boolean'
      }, {
        key: 'mobileApp',
        type: 'boolean',
        comingSoon: true
      }, {
        key: 'knowmeChat',
        type: 'boolean',
        hasTooltip: true
      }, {
        key: 'messageHistory'
      }, {
        key: 'callTracking',
        type: 'boolean'
      }, {
        key: 'includedCallTouchPointsMultiRing',
        hasTooltip: true,
        type: 'boolean'
      }, {
        key: 'callsIntegration',
        type: 'boolean'
      }, {
        key: 'inboundCallRecording',
        type: 'boolean'
      }, {
        key: 'textMessagingPlatform',
        type: 'boolean'
      }, {
        key: 'webChat',
        type: 'boolean',
        hasTooltip: true
      }, {
        key: 'notificationsAndAlerts',
        type: 'boolean'
      }, {
        key: 'multipleDashboards',
        type: 'boolean',
        hasTooltip: true
      }, {
        key: 'revenueTracking',
        type: 'boolean'
      }, {
        key: 'customerEmailFeedback',
        type: 'boolean'
      }, {
        key: 'csvImport',
        type: 'boolean'
      }, {
        key: 'tasksOpportunitiesPipelineManagement',
        type: 'boolean'
      }, {
        key: 'accountSettings',
        type: 'boolean'
      }, {
        key: 'reports',
        type: 'boolean'
      }, {
        key: 'appointmentReminders',
        type: 'boolean',
        hasTooltip: true
      }, {
        key: 'webformLeadCapture',
        type: 'boolean'
      }, {
        key: 'instantCustomersSatisfactionNotifications',
        type: 'boolean'
      }, {
        key: 'quickbooksIntegration',
        type: 'boolean',
        comingSoon: true
      }, {
        key: 'automatedLeadCaptureViaIncentive',
        type: 'boolean'
      }, {
        key: 'reviewsBooster',
        type: 'boolean'
      }, {
        key: 'automatedLeadCapture',
        type: 'boolean',
        hasTooltip: true
      }, {
        key: 'costLeadCalculation',
        type: 'boolean',
        hasTooltip: true
      }, {
        key: 'answeringServiceReplacement',
        type: 'boolean',
        hasTooltip: true
      }, {
        key: 'automatedCommunicationTrackingPerOpportunity',
        type: 'boolean'
      }, {
        key: 'provisionAndPurchasePhoneNumbers',
        type: 'boolean',
        hasTooltip: true
      }]
    },
    addOnsSections: [{
      key: 'usersCount',
      section: 'isAdditionalUserOpened'
    }, {
      key: 'minutesCount',
      section: 'isAdditionalMinutesOpened'
    }, {
      key: 'textMessagesCount',
      section: 'isAdditionalMessagesOpened'
    }, {
      key: 'storageCount',
      section: 'isAdditionalStorageOpened'
    }, {
      key: 'localNumbersCount',
      section: 'isAdditionalNumbersOpened'
    }, {
      key: 'tollNumbersCount',
      section: 'isAdditionalTollNumbersOpened'
    }, {
      key: 'contactsCount',
      section: 'isAdditionalContactsOpened'
    }],
    isCurrentProductBasicCRM: Ember.computed('product.key', function () {
      return this.get('product.key') === "basic_crm";
    }),
    displayNumbersAddOns: Ember.computed('product', function () {
      return ['marketing_optimizer_crm', 'customer_satisfaction', 'sales_accelerator_marketing_optimizer_crm', 'knowme_iq'].includes(this.get('product.key'));
    }),
    displayMarketingTools: Ember.computed('product', function () {
      return ['marketing_optimizer_crm', 'customer_satisfaction', 'sales_accelerator_marketing_optimizer_crm', 'knowme_iq'].includes(this.get('product.key'));
    }),
    displayCustomerSatisfactionTools: Ember.computed('product', function () {
      return ['customer_satisfaction', 'sales_accelerator_marketing_optimizer_crm', 'knowme_iq'].includes(this.get('product.key'));
    }),
    actions: {
      toggleUnlimited: function (e) {
        this.set(e.target.name, e.target.checked);
        if (e.target.name === 'starter_unlimited') {
          this.set('interval', 'monthly');
          this.set('starter', !e.target.checked);
        }
        if (e.target.name === 'premium_unlimited') {
          this.set('interval', 'monthly');
          this.set('premium', !e.target.checked);
        }
        if (e.target.checked) {
          this.set('currentPlan', e.target.name);
        }
      },
      setSignupData: function (signupData) {
        this.set('signupData', signupData);
      },
      openSettings: function (product) {
        this.set('currentProduct', product);
        if (!this.get('hideProductsBoxes')) {
          let heightOfHeader = 90;
          Ember.run.later(this, function () {
            let target = Ember.$('#' + product.key);
            if (Ember.$('html').scrollTop() == 0 && Ember.$('body').scrollTop() > 0) {
              let scrollable = Ember.$('body');
              scrollable.animate({
                scrollTop: scrollable.scrollTop() - scrollable.offset().top + target.offset().top - (window.pageYOffset + heightOfHeader)
              }, 200);
            } else {
              this.get('scroller').scrollVertical(target, { duration: 200, offset: -1 * (window.pageYOffset + heightOfHeader) });
            }
          }, 300);
        }
      },
      toggleInteval: function () {
        if (this.get('interval') === 'annually') {
          this.set('interval', 'monthly');
        } else {
          this.set('interval', 'annually');
        }
      },
      toggleSection: function (sectionName) {
        ['salesTools', 'marketingTools', 'customerSatisfactionTools'].forEach(function (key) {
          if (key !== sectionName) {
            this.set(key, false);
          }
        }.bind(this));
        this.set(sectionName, !this.get(sectionName));
      },
      toggleInteval: function () {
        if (this.get('interval') === 'annually') {
          this.set('interval', 'monthly');
        } else {
          this.set('interval', 'annually');
        }
      },
      applyPromoCode: function (promoCodeId) {
        if (Ember.isPresent(promoCodeId)) {
          let controller = this;
          this.get('store').query('promo-code', { coupon_id: promoCodeId, plan_id: this.get('selectedPlan.id'), billing_period: this.get('interval') }).then(function (promoCodes) {
            if (promoCodes.get('length') > 0) {
              controller.set('promoCode', promoCodes.get('firstObject'));
            } else {
              controller.get('flashMessages').warning(`Promo Code ${promoCodeId} does not exist`, { destroyOnClick: true, timeout: 12000 });
            }
          }, function (resp) {
            controller.get('flashMessages').warning(resp.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
          });
        }
      },
      openSignUpForm: function (plan) {
        if (this.get('currentPlan') === plan) {
          let signupData = {
            currentIndustry: this.get('currentIndustry'),
            interval: this.get('interval'),
            plan: this.get('selectedPlan'),
            promoCode: this.get('promoCode')
          };
          this.accountSettings.forEach(function (key) {
            let _key = key.camelize().capitalize();
            signupData[`settings${_key}`] = this.get(`settings${_key}`);
          }.bind(this));
          this.set('transitioningToSignUpForm', true);
          // this.sendAction('setSignupData', signupData);
          this.set('signupData', signupData);
          this.transitionToRoute('signup').then(newRoute => {
            window.scrollTo(0, 0);
          });
        }
      }

    }
  });

  exports.default = KnowmeIQProductController;
});