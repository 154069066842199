define('knowme-client/mirage/factories/did-allocation', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    // phoneNumber: function() {return faker.phone.phoneNumberFormat(0).replace(/-/g, ''); },
    // number: function() {return faker.phone.phoneNumberFormat(0).replace(/-/g, ''); },
    // kind: function() {return faker.random.arrayElement(['toll_free', 'local']); },
    // type: function() {return faker.random.arrayElement(['toll_free', 'local']); },
    // createdAt: function() { return faker.date.past().toISOString(); },
    // updatedAt: function() { return faker.date.past().toISOString(); }
  });
});