define('knowme-client/serializers/ad', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AdSerializer;

  AdSerializer = _application.default.extend({
    attrs: {
      campaign: { serialize: 'id', deserialize: 'id' },
      callTouchPoints: { deserialize: false, serialize: 'records' },
      formTouchPoints: { deserialize: false, serialize: 'records' },
      emailTouchPoints: { deserialize: false, serialize: 'records' },
      chatTouchPoints: { deserialize: false, serialize: 'records' }
    },
    keyForRelationship: function (key, relationship, method) {
      if (['callTouchPoints', 'formTouchPoints', 'emailTouchPoints', 'chatTouchPoints'].includes(key) && Ember.isNone(relationship) && Ember.isNone(method)) {
        return key;
      }
      if (['campaign'].includes(key) && method === 'deserialize') {
        return `${Ember.String.underscore(key)}_id`;
      }
      return this._super(...arguments);
    },
    serialize(snapshot /*, options*/) {
      let json = this._super(...arguments);
      if (snapshot.record.get('captureMode') === 'manual') {
        delete json.channel;
        delete json.call_touch_points_attributes;
        delete json.form_touch_points_attributes;
        delete json.email_touch_points_attributes;
        delete json.chat_touch_points_attributes;
      }
      return json;
    },
    normalize(typeClass, hash) {
      hash.links = {
        callTouchPoints: Ember.getOwner(this).lookup('adapter:call-touch-point').buildURL('call-touch-point', null, null, 'findMany', { ad_id: hash.id, paginate: false }),
        formTouchPoints: Ember.getOwner(this).lookup('adapter:form-touch-point').buildURL('form-touch-point', null, null, 'findMany', { ad_id: hash.id, paginate: false }),
        emailTouchPoints: Ember.getOwner(this).lookup('adapter:email-touch-point').buildURL('email-touch-point', null, null, 'findMany', { ad_id: hash.id, paginate: false }),
        chatTouchPoints: Ember.getOwner(this).lookup('adapter:chat-touch-point').buildURL('chat-touch-point', null, null, 'findMany', { ad_id: hash.id, paginate: false }),
        campaign: Ember.getOwner(this).lookup('adapter:campaign').buildURL('campaign', hash.campaign_id, null, 'findRecord', null)
      };
      return this._super.apply(this, arguments);
    }
  });

  exports.default = AdSerializer;
});