define('knowme-client/components/navigation-partial', ['exports', 'lodash'], function (exports, _lodash) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		router: Ember.inject.service(),
		session: Ember.inject.service(),
		store: Ember.inject.service(),
		features: Ember.inject.service(),
		chatNotification: Ember.inject.service('chat-notification'),
		accountService: Ember.inject.service('account'),
		unreadRoomsCount: 0,
		unreadContactRoomsCount: 0,
		untouchedContactRoomsCount: 0,
		unreadAllRoomsCount: Ember.computed('unreadRoomsCount', 'unreadContactRoomsCount', 'untouchedContactRoomsCount', function () {
			return this.get('unreadContactRoomsCount') + this.get('untouchedContactRoomsCount') + this.get('unreadRoomsCount');
		}),
		customerExperienceSectionOpened: true,
		communicationsSectionOpened: true,
		crmSectionOpened: true,
		marketingSectionOpened: true,
		didReceiveAttrs() {
			this._super.apply(this, arguments);
			this.get('chatNotification').setContext(this);
		},
		openAllSections: function () {
			['customerExperienceSectionOpened', 'communicationsSectionOpened', 'crmSectionOpened', 'marketingSectionOpened'].forEach(function (section) {
				this.set(section, true);
			}.bind(this));
		},
		resetSections: function () {
			['communicationsSectionOpened', 'crmSectionOpened'].forEach(function (section) {
				this.set(section, true);
			}.bind(this));
			['customerExperienceSectionOpened', 'marketingSectionOpened'].forEach(function (section) {
				this.set(section, false);
			}.bind(this));
		},
		updateScroller: function () {
			Ember.run.later(function () {
				Ember.$(window).trigger('resize');
			}, 500);
		},
		onAnySectionOpened: Ember.observer('leadsSectionOpened', 'customersSectionOpened', 'emailsSectionOpened', 'callsSectionOpened', 'referralSectionOpened', 'customerExperienceSectionOpened', 'communicationsSectionOpened', 'crmSectionOpened', 'marketingSectionOpened', function () {
			this.updateScroller();
		}),
		actions: {
			startEmailIntegration: function () {
				this.sendAction('startEmailIntegration');
			},
			openLeadsPage: function () {
				let queryParams = {};
				this.get('leadsController.queryParams').forEach(function (key) {
					queryParams[key] = null;
				});

				if (Ember.$('#container.mainnav-sm').length) {
					let adapter = Ember.getOwner(this).lookup('adapter:application');
					adapter.ajax(adapter.buildURL('lead/summary')).then(function (res) {
						if (res.leadsSummary.withPipeline > 0) {
							this.get('router').transitionTo('leads.index', { queryParams: _lodash.default.defaults({ filter: 'pipeline_opportunity', sort_by: 'updated_at' }, queryParams) });
						} else {
							this.get('router').transitionTo('leads.index', { queryParams: _lodash.default.defaults({ filter: 'active' }, queryParams) });
						}
					}.bind(this));
				}
			},
			openCustomersPage: function () {
				let queryParams = {};
				this.get('leadsController.queryParams').forEach(function (key) {
					queryParams[key] = null;
				});

				if (Ember.$('#container.mainnav-sm').length) {
					let adapter = Ember.getOwner(this).lookup('adapter:application');
					adapter.ajax(adapter.buildURL('customer/summary')).then(function (res) {
						if (res.customersSummary.withPipeline > 0) {
							this.get('router').transitionTo('leads.index', { queryParams: _lodash.default.defaults({ converted: '1', filter: 'customers_and_leads_pipeline', sort_by: 'updated_at' }, queryParams) });
						} else {
							this.get('router').transitionTo('leads.index', { queryParams: _lodash.default.defaults({ converted: '1', filter: 'active' }, queryParams) });
						}
					}.bind(this));
				}
			}
		}
	});
});