define('knowme-client/helpers/short-messages-to-call-touch-points', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    compute([contactPhoneNumber, opportunity, activitiesCount]) {
      // assert('The `query-params-with-defaults` helper only accepts an object as defaults value', typeof defaults === 'object');
      // assert('The `query-params-with-defaults` helper requires 2 params, e.g. (query-params-with-defaults a b)', isPresent(defaults) && isPresent(updates));

      return this.get('store').query('short-message', { filter: 'inbound,to_call_touch_point', contact_phone_number_id: contactPhoneNumber.get('id'), opportunity_id: opportunity.get('id') });
    }
  });
});