define('knowme-client/components/call-touch-point/resource-form', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    tollFreeDids: Ember.computed('model.ad.touchPoint', function () {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let didsUrl = adapter.buildURL('dids');
      let didsRequest = adapter.ajax(didsUrl, 'GET', { data: { type: 'toll_free', filter: 'unassigned', show_as: 'numbers' } });
      return didsRequest.then(function (dids) {
        return dids.map(function (did) {
          return did.number;
        });
      });
    }),
    onForwardTo1Changed: Ember.observer('model.forwardTo1', function () {
      if (Ember.isBlank(this.get('model.forwardTo1')) && Ember.isPresent(this.get('model.forwardTo2'))) {
        let phoneNumber = this.get('model.forwardTo2');
        this.set('model.forwardTo2', "");
        this.set('model.forwardTo1', phoneNumber);
      }
    }),
    onForwardTo2Changed: Ember.observer('model.forwardTo2', function () {
      if (Ember.isBlank(this.get('model.forwardTo2')) && Ember.isPresent(this.get('model.forwardTo3'))) {
        let phoneNumber = this.get('model.forwardTo3');
        this.set('model.forwardTo3', "");
        this.set('model.forwardTo2', phoneNumber);
      }
    }),
    onForwardTo3Changed: Ember.observer('model.forwardTo3', function () {
      if (Ember.isBlank(this.get('model.forwardTo3')) && Ember.isPresent(this.get('model.forwardTo4'))) {
        let phoneNumber = this.get('model.forwardTo4');
        this.set('model.forwardTo4', "");
        this.set('model.forwardTo3', phoneNumber);
      }
    }),
    init() {
      this._super(...arguments);
      this.loadOwnedLocalNumbers();
      this.loadOwnedTollFreeNumbers();
    },
    searchDids: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(800);

      let dids = Ember.A([]);

      if (this.get('model.ad.touchPoint') == 'local_number') {
        dids.pushObject(this.get('ownedLocalNumbers'));
        dids.pushObject(this.searchLocalNumbers(term));
      } else {
        dids.pushObject(this.get('ownedTollFreeNumbers'));
        dids.pushObject(this.searchTollFreeNumbers(term));
      }

      return Ember.RSVP.all(dids).then(function (response) {
        return [{
          groupName: 'Released Numbers',
          options: response[0]
        }, {
          groupName: 'New Numbers',
          options: response[1]
        }];
      });
    }),
    searchLocalNumbers(term) {
      return this.searchNumbers(term, 'local');
    },
    searchTollFreeNumbers(term) {
      return this.searchNumbers(term, 'toll_free');
    },
    searchNumbers(term, type) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let didsUrl = adapter.buildURL('dids');

      let didsRequest = adapter.ajax(didsUrl, 'GET', { data: { type: type, filter: 'unassigned', query: term, show_as: 'numbers', live: true } });
      return didsRequest.then(function (dids) {
        return dids.map(function (did) {
          return did.number;
        });
      });
    },
    ownedLocalNumbersOptions: Ember.computed('ownedLocalNumbers.length', function () {
      return [{
        groupName: 'Released Numbers',
        options: this.get("ownedLocalNumbers")
      }];
    }),
    ownedTollFreeNumbersOptions: Ember.computed('ownedTollFreeNumbers.length', function () {
      return [{
        groupName: 'Released Numbers',
        options: this.get("ownedTollFreeNumbers")
      }];
    }),
    loadOwnedLocalNumbers() {
      return this.loadOwnedNumbers('local').then(function (numbers) {
        this.set('ownedLocalNumbers', numbers);
        return numbers;
      }.bind(this));
    },
    loadOwnedTollFreeNumbers() {
      return this.loadOwnedNumbers('toll_free').then(function (numbers) {
        this.set('ownedTollFreeNumbers', numbers);
        return numbers;
      }.bind(this));
    },
    loadOwnedNumbers(type) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let didsUrl = adapter.buildURL('dids');

      let didsRequest = adapter.ajax(didsUrl, 'GET', { data: { type: type, filter: 'unassigned,call_touch_point_allocatable', show_as: 'numbers' }, traditional: true });
      return didsRequest.then(function (dids) {
        return dids.map(function (did) {
          return did.number;
        });
      });
    }
  });
});