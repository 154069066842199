define('knowme-client/controllers/leads/new', ['exports', 'ember-local-storage', 'knowme-client/mixins/controllers/opportunity-fields'], function (exports, _emberLocalStorage, _opportunityFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_opportunityFields.default, {
    lastOpportunity: (0, _emberLocalStorage.storageFor)('last-opportunity'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    lead: Ember.computed.alias('model'),
    contact: Ember.computed.alias('lead.contacts.firstObject'),
    opportunity: Ember.computed.alias('lead.opportunities.firstObject'),
    queryParams: ['converted'], // queryParams that trigger model reload
    converted: null,
    isOpportunityOppened: false,
    isSaving: false,
    actions: {
      toggleOpportunityForm: function () {
        this.set('isOpportunityOppened', !this.get('isOpportunityOppened'));
        if (this.get('isOpportunityOppened') && Ember.isBlank(this.get('opportunity'))) {
          let opportunity = this.get('store').createRecord('opportunity', { lead: this.get('lead'), assignedTo: this.get('session.currentUser'), ad: this.get('session.account.defaultManualAd') });
          if (this.get('converted') === "1") {
            opportunity.set('salesStage', this.get('salesStages').findBy('key', 'closed'));
          }
          this.prepareCustomAttributes();
        } else {
          this.get('opportunity').unloadRecord();
        }
      },
      submit: function () {
        let controller = this;
        this.model.one('didCreate', function () {
          ['contact', 'contactInfo', 'contactEmail', 'contactPhoneNumber', 'contactAddress', 'contactUrl', 'opportunity', 'order' /*, 'organization'*/].forEach(function (modelName) {
            // TODO: FIX! - organization is being deleted completely including the fetched from the server
            controller.get('store').peekAll(modelName).filter(function (_model) {
              return _model.get('isNew');
            }).invoke('deleteRecord');
          });
        });
        this.set('isSaving', true);
        this.model.save().then(function (lead) {
          controller.set('isSaving', false);
          controller.setProperties({ emailDirty: null, phoneDirty: null, addressDirty: null });
          controller.get('target._routerMicrolib').refresh();
          if (Ember.isPresent(controller.get('opportunity'))) {
            let opportunity = lead.get('opportunities.firstObject');
            controller.set('lastOpportunity.id', opportunity.get('id'));
            controller.transitionToRoute('leads.show.opportunities.show', lead, opportunity);
          } else {
            controller.transitionToRoute('leads.show.opportunities.new', lead);
          }
        }).catch(function (error) {
          controller.set('isSaving', false);
          console.log("Errors", Ember.inspect(error));
          let errorsMessage = error.errors.map(function (error) {
            return { ten_digit_number: 'Phone Number', email: 'Email' }[error.source.pointer.split('.').get('lastObject')] + " " + error.detail;
          });
          controller.get('flashMessages').danger(errorsMessage.join(', '), { destroyOnClick: true, timeout: 12000 });
        });
      }
    },
    isModelWithoutOpportunityTreeDirty: Ember.computed('lead.hasDirtyAttributes', 'contact.hasDirtyAttributes', 'lead.contactsContactInfos.@each.hasDirtyAttributes', 'contact.contactInfosContactPhoneNumbers.@each.hasDirtyAttributes', 'contact.contactInfosContactEmails.@each.hasDirtyAttributes', function () {
      return this.get('lead.hasDirtyAttributes') || this.get('contact.hasDirtyAttributes') || this.get('lead.contactsContactInfos').isAny('hasDirtyAttributes', true) || this.get('contact.contactInfosContactPhoneNumbers').isAny('hasDirtyAttributes', true) || this.get('contact.contactInfosContactEmails').isAny('hasDirtyAttributes', true);
    }),
    isModelTreeDirty: Ember.computed('isModelWithoutOpportunityTreeDirty', 'opportunity.hasDirtyAttributes', function () {
      return this.get('isModelWithoutOpportunityTreeDirty') || this.get('opportunity.hasDirtyAttributes');
    }),
    disableModelWithoutOpportunitySubmit: Ember.computed('lead.validations.isValid', 'contact.validations.isValid', 'lead.contactsContactInfos.@each.isInvalid', 'contact.contactInfosContactPhoneNumbers.@each.isInvalid', 'contact.contactInfosContactEmails.@each.isInvalid', 'opportunity.validations.isInvalid', 'isModelWithoutOpportunityTreeDirty', function () {
      return this.get('lead.validations.isInvalid') || this.get('contact.validations.isInvalid') || this.get('lead.contactsContactInfos').isAny('isInvalid', true) || this.get('contact.contactInfosContactPhoneNumbers').isAny('isInvalid', true) || this.get('contact.contactInfosContactEmails').isAny('isInvalid', true) || this.get('opportunity.validations.isInvalid') || !this.get('isModelWithoutOpportunityTreeDirty');
    }),
    disableSubmit: Ember.computed('isSaving', 'disableModelWithoutOpportunitySubmit', 'isModelTreeDirty', function () {
      return this.get('isSaving') || this.get('disableModelWithoutOpportunitySubmit') || !this.get('isModelTreeDirty');
    })
  });
});