define('knowme-client/mirage/factories/lead-activity', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		data: function () {
			return {
				opportunity: {
					id: 176,
					pipeline: 51.96,
					priority: "medium",
					due_date: null,
					forecast_date: null,
					activity: null,
					name: "cachan",
					created_at: "2016-12-27T06:20:22.573-08:00",
					updated_at: "2016-12-27T06:20:22.852-08:00",
					note: "nai",
					assigned_to: { full_name: 'Test User Name' },
					salesStage: { id: 1, name: 'Unqualified', key: 'unqualified' },
					salesAction: { id: 1, name: 'Assign' }
				}
			};
		},
		key: function () {
			return _faker.default.random.arrayElement(['opportunity.create', 'opportunity.update', 'opportunity.communication']);
		},
		createdAt: function () {
			return _faker.default.date.past().toISOString();
		},
		updatedAt: function () {
			return _faker.default.date.past().toISOString();
		}
	});
});