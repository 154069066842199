define('knowme-client/mixins/models/validations/subscription', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		plan: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		kind: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })
	});
});