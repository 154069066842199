define('knowme-client/mixins/controllers/status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: ['status'],
    status: null,
    statusOptions: [{ value: 'active', text: 'Active' }, { value: 'inactive', text: 'Inactive' }],
    statusIncentiveOptions: [{ value: 'active', text: 'Active' }, { value: 'expired', text: 'Expired' }],
    statusInvoiceOptions: [{ value: 'sent', text: 'Sent' }, { value: 'paid', text: 'Paid' }],
    statusProductOptions: [{ value: 'active', text: 'Active' }, { value: 'inactive', text: 'Inactive' }, { value: 'sold-out', text: 'Sold Out' }],
    statusActiveAndArchivedOptions: [{ value: 'all', text: 'Active and Archived' }, { value: 'active', text: 'Active' }, { value: 'inactive', text: 'Archived' }],
    statusArchivedOptions: [{ value: 'active', text: 'Active' }, { value: 'inactive', text: 'Archived' }],
    statusFilterOptions: [{ value: 'active', text: 'Active' }, { value: 'inactive', text: 'Inactive' }, { value: 'all', text: 'All' }],
    statusMasterAdminFilterOptions: [{ value: 'active-b2b', text: 'Active Businesses' }, { value: 'active-b2c', text: 'Active Individuals' }, { value: 'inactive-b2b', text: 'Inactive Businesses' }, { value: 'inactive-b2c', text: 'Inactive Individuals' }, { value: 'all', text: 'All' }]
  });
});