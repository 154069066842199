define('knowme-client/serializers/contact-address', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ContactAddressSerializer;

  ContactAddressSerializer = _application.default.extend({
    attrs: {
      contactInfo: { serialize: false }
    },
    serialize: function (snapshot /*, options*/) {
      var json = this._super(...arguments);
      if (snapshot.record.get('isDeleted')) {
        json._destroy = true;
      }
      return json;
    }
  });

  exports.default = ContactAddressSerializer;
});