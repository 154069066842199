define('knowme-client/models/opportunity-field', ['exports', 'ember-data/relationships', 'knowme-client/mixins/models/custom-field'], function (exports, _relationships, _customField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _customField.default.extend({
    account: (0, _relationships.belongsTo)('account', { async: true }),
    partialName: Ember.computed('key', function () {
      if (this.get('internal')) {
        return `opportunity-fields/${this.get('key').dasherize()}`;
      } else {
        return 'opportunity-fields/custom-field';
      }
    })
  });
});