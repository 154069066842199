define('knowme-client/controllers/leads/show', ['exports', 'knowme-client/mixins/controllers/email-integration', 'knowme-client/mixins/controllers/opportunity-fields'], function (exports, _emailIntegration, _opportunityFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowController;

  LeadsShowController = Ember.Controller.extend(_emailIntegration.default, _opportunityFields.default, {
    contactsController: Ember.inject.controller('leads.show.contacts'),
    lead: Ember.computed.alias('model'),
    contact: Ember.computed.alias('model.primaryContact'),
    opportunity: Ember.computed.alias('model.opportunities.firstObject'),
    accountService: Ember.inject.service('account'),
    features: Ember.inject.service(),
    actions: {
      startListening: function () {},
      stopListening: function () {},
      submit: function () {
        let controller = this;
        let opportunity = controller.get('opportunity');
        opportunity.one('didUpdate', function () {
          ['order'].forEach(function (modelName) {
            controller.get('store').peekAll(modelName).filterBy('isNew', true).invoke('deleteRecord');
          });
        });
        opportunity.save().then(function (opportunity) {
          opportunity.get('activities').reload();
          Ember.$("#opportunity-history-table").collapse('show');
        }).catch(function (reason) {
          console.log("Errors", reason);
        });
      },
      toggleContactsSelector: function () {
        this.set('displayContactSelector', !this.get('displayContactSelector'));
      },
      selectContact: function (contact) {
        this.set('contact', contact);
      }
    },
    activitiesTotal: Ember.computed.alias('opportunity.activities.length'),
    callsTotal: Ember.computed.alias('opportunity.callActivities.length'),
    emailsTotal: Ember.computed.alias('opportunity.emailActivities.length'),
    smsTotal: Ember.computed.alias('opportunity.smsActivities.length'),
    anyTotal: Ember.computed.alias('opportunity.anyActivities.length'),
    sorting: ['createdAt:desc'],
    sortedActivities: Em.computed.sort('filteredActivities', 'sorting'),
    filteredActivities: Ember.computed('opportunity.activities', 'activitiesFilter', function () {
      let activitiesFilter = this.get('activitiesFilter');
      let activities;
      if (Ember.isPresent(activitiesFilter) && activitiesFilter !== 'all' && ['calls', 'short_messages', 'quotes', 'others'].includes(activitiesFilter) && this.get('opportunity.activities.isFulfilled')) {
        activities = this.get('store').query('lead-activity', { filter: activitiesFilter, opportunity_id: this.get('opportunity.id') });
      } else {
        activities = this.get('opportunity.activities');
      }
      activities.then(function (_activities) {
        this.set('filteredActivitiesMeta', _activities.get('meta'));
      }.bind(this));
      this.set('selectedActivity', null);
      return activities;
    }),
    isModelTreeDirty: Ember.computed('opportunity.hasDirtyAttributes', 'opportunity.salesStageHasChanged', 'opportunity.salesActionHasChanged', 'opportunity.assignedToHasChanged', 'opportunity.orders.@each.hasDirtyAttributes', 'opportunity.orders.@each.productHasChanged', function () {
      return this.get('opportunity.hasDirtyAttributes') || this.get('opportunity.salesStageHasChanged') || this.get('opportunity.salesActionHasChanged') || this.get('opportunity.assignedToHasChanged') || this.get('opportunity.orders') && this.get('opportunity.orders').isAny('hasDirtyAttributes', true) || this.get('opportunity.orders') && this.get('opportunity.orders').isAny('productHasChanged', true);
    }),
    disableSubmit: Ember.computed('opportunity.validations.isValid', 'isModelTreeDirty', function () {
      return this.get('opportunity.validations.isInvalid') || !this.get('isModelTreeDirty');
    })
  });

  exports.default = LeadsShowController;
});