define('knowme-client/components/table-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['table-header'],
    sorting: Ember.computed('sort_by', 'order', 'column', function () {
      return Ember.isPresent(this.get('sort_by')) && this.get('sort_by') === this.get('column');
    }),
    sortClass: Ember.computed('sort_by', 'order', 'column', function () {
      let sortBy = this.get('sort_by'),
          order = this.get('order'),
          column = this.get('column');
      if (sortBy === column && order === 'desc') {
        return 'fas fa-angle-down gray';
      } else if (sortBy === column && order === 'asc') {
        return 'fas fa-angle-up gray';
      }
    })
  });
});