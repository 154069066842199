define('knowme-client/components/modal-image-link', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			openedModal: function () {
				this.sendAction('openedModal');
			},
			closedModal: function () {
				this.sendAction('closedModal');
			}
		}
	});
});