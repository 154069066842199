define("knowme-client/mirage/plan-settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  let planSettings = exports.planSettings = {
    'knowme_iq.premium': {
      "monthly": {
        "plan.fee": 70, "mobile-app": true, "mobile-app.fee": 0, "included.users": 2, "additional.users.fee": 60, "included.users.dids.local": 1, "included.users.dids.local.per-user": true, "included.call-touch-points.dids.toll-free": 0, "included.call-touch-points.dids.local": 1, "included.call-touch-points.dids.local.per-user": true, "included.call-touch-points.multi-ring": 2, "included.call-touch-points.multi-ring.forward-to": 3, "included.call-touch-points.anonymous": "inf", "additional.dids.fee": 5, "additional.call-touch-points.dids.local.fee": 5, "additional.call-touch-points.dids.toll-free.fee": 5, "included.minutes": 200, "included.minutes.per-user": true, "additional.minutes.block": 200, "additional.minutes.block.fee": 12, "included.short-messages": 200, "included.short-messages.per-user": true, "additional.short-messages.block": 200, "additional.short-messages.block.fee": 5, "included.contacts": 1000, "included.contacts.per-user": true, "additional.contacts.block": 1000, "additional.contacts.block.fee": 10, "included.storage": 100, "included.storage.per-user": true, "additional.storage.block.fee": 6, "additional.storage.block": 25, "included.emails-for-webform": 2
      },
      "annually": {
        "plan.fee": 55, "mobile-app": true, "mobile-app.fee": 0, "included.users": 2, "additional.users.fee": 50, "included.users.dids.local": 1, "included.users.dids.local.per-user": true, "included.call-touch-points.dids.toll-free": 0, "included.call-touch-points.dids.local": 1, "included.call-touch-points.dids.local.per-user": true, "included.call-touch-points.multi-ring": 2, "included.call-touch-points.multi-ring.forward-to": 3, "included.call-touch-points.anonymous": "inf", "additional.dids.fee": 5, "additional.call-touch-points.dids.local.fee": 5, "additional.call-touch-points.dids.toll-free.fee": 5, "included.minutes": 200, "included.minutes.per-user": true, "additional.minutes.block": 200, "additional.minutes.block.fee": 12, "included.short-messages": 200, "included.short-messages.per-user": true, "additional.short-messages.block": 200, "additional.short-messages.block.fee": 5, "included.contacts": 1000, "included.contacts.per-user": true, "additional.contacts.block": 1000, "additional.contacts.block.fee": 10, "included.storage": 100, "included.storage.per-user": true, "additional.storage.block.fee": 6, "additional.storage.block": 25, "included.emails-for-webform": 2
      }
    },
    'knowme_iq.premium_unlimited': {
      "monthly": {
        "plan.fee": 1349, "mobile-app": true, "mobile-app.fee": 0, "included.users": "inf", "included.users.unlimited": true, "included.users.dids.local": "inf", "included.users.dids.local.unlimited": true, "included.users.dids.local.per-user": true, "included.call-touch-points.dids.toll-free": 0, "included.call-touch-points.dids.local": 20, "included.call-touch-points.multi-ring": "inf", "included.call-touch-points.multi-ring.unlimited": true, "included.call-touch-points.multi-ring.forward-to": 3, "included.call-touch-points.anonymous": "inf", "included.call-touch-points.anonymous.unlimited": true, "additional.dids.fee": 5, "additional.call-touch-points.dids.local.fee": 5, "additional.call-touch-points.dids.toll-free.fee": 5, "included.minutes": 4000, "additional.minutes.block": 200, "additional.minutes.block.fee": 12, "included.short-messages": 4000, "additional.short-messages.block": 200, "additional.short-messages.block.fee": 5, "included.contacts": 40000, "additional.contacts.block": 1000, "additional.contacts.block.fee": 10, "included.storage": 5120, "additional.storage.block.fee": 6, "additional.storage.block": 25, "included.emails-for-webform": 2
      }
    },
    'knowme_iq.starter': {
      "monthly": {
        "plan.fee": 18, "mobile-app": true, "mobile-app.fee": 0, "included.users": 2, "additional.users.fee": 15, "included.users.dids.local": null, "included.call-touch-points.dids.toll-free": null, "included.call-touch-points.dids.local": null, "included.call-touch-points.multi-ring": null, "included.call-touch-points.multi-ring.forward-to": null, "included.call-touch-points.anonymous": null, "additional.dids.fee": null, "included.minutes": null, "additional.minutes.block": null, "additional.minutes.block.fee": null, "included.short-messages": null, "additional.short-messages.block": null, "additional.short-messages.block.fee": null, "included.contacts": 500, "included.contacts.per-user": true, "additional.contacts.block": 1000, "additional.contacts.block.fee": 15, "included.storage": 25, "included.storage.per-user": true, "additional.storage.block.fee": 6, "additional.storage.block": 25, "included.emails-for-webform": null
      },
      "annually": {
        "plan.fee": 14, "mobile-app": true, "mobile-app.fee": 0, "included.users": 2, "additional.users.fee": 12, "included.users.dids.local": null, "included.call-touch-points.dids.toll-free": null, "included.call-touch-points.dids.local": null, "included.call-touch-points.multi-ring": null, "included.call-touch-points.multi-ring.forward-to": null, "included.call-touch-points.anonymous": null, "additional.dids.fee": null, "included.minutes": null, "additional.minutes.block": null, "additional.minutes.block.fee": null, "included.short-messages": null, "additional.short-messages.block": null, "additional.short-messages.block.fee": null, "included.contacts": 500, "included.contacts.per-user": true, "additional.contacts.block": 1000, "additional.contacts.block.fee": 15, "included.storage": 25, "included.storage.per-user": true, "additional.storage.block.fee": 6, "additional.storage.block": 25, "included.emails-for-webform": null
      }
    },
    'knowme_iq.starter_unlimited': {
      "monthly": {
        "plan.fee": 99, "mobile-app": true, "mobile-app.fee": 0, "included.users": "inf", "included.users.unlimited": true, "included.users.dids.local": null, "included.call-touch-points.dids.toll-free": null, "included.call-touch-points.dids.local": null, "included.call-touch-points.multi-ring": null, "included.call-touch-points.multi-ring.forward-to": null, "included.call-touch-points.anonymous": null, "additional.dids.fee": null, "included.minutes": null, "additional.minutes.block": null, "additional.minutes.block.fee": null, "included.short-messages": null, "additional.short-messages.block": null, "additional.short-messages.block.fee": null, "included.contacts": 5000, "included.storage": 500, "additional.contacts.block": 1000, "additional.contacts.block.fee": 15, "additional.storage.block.fee": 6, "additional.storage.block": 25, "included.emails-for-webform": null
      }
    }
  };
});