define('knowme-client/controllers/application', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ApplicationController;

  ApplicationController = Ember.Controller.extend({
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    invoicesController: Ember.inject.controller('invoices.index'),
    customerExperiencesController: Ember.inject.controller('customer-experience'),
    leadsController: Ember.inject.controller('leads.index'),
    callsController: Ember.inject.controller('calls'),
    chatsController: Ember.inject.controller('chats'),
    shortMessagesController: Ember.inject.controller('short-messages'),
    campaignsController: Ember.inject.controller('campaigns'),
    reportsController: Ember.inject.controller('reports.index'),
    emailsController: Ember.inject.controller('emails'),
    incentivesController: Ember.inject.controller('account.settings.incentives'),
    reviewsController: Ember.inject.controller('account.settings.reviews'),
    promoCodesController: Ember.inject.controller('promo-codes'),
    remindersController: Ember.inject.controller('account.settings.appointment-reminders'),
    clientsController: Ember.inject.controller('account.clients'),
    usersController: Ember.inject.controller('account.users'),
    productsController: Ember.inject.controller('account.settings.products'),
    stagesController: Ember.inject.controller('account.settings.stages'),
    contactFieldsController: Ember.inject.controller('account.settings.contact-fields'),
    organizationFieldsController: Ember.inject.controller('account.settings.organization-fields'),
    opportunityFieldsController: Ember.inject.controller('account.settings.opportunity-fields'),
    actionsController: Ember.inject.controller('account.settings.actions'),
    init: function () {
      this._super();
      Ember.run.schedule("afterRender", this, function () {
        this.setupNifty();
      });
    },
    setupNifty: function () {
      if (this.get('session.isAuthenticated') && !nifty.knowmeReady) {
        nifty.container = Ember.$('#container');
        nifty.contentContainer = Ember.$('#content-container');
        nifty.navbar = Ember.$('#navbar');
        nifty.mainNav = Ember.$('#mainnav-container');
        nifty.aside = Ember.$('#aside-container');
        nifty.footer = Ember.$('#footer');
        nifty.scrollTop = Ember.$('#scroll-top');
        nifty.menulink = Ember.$('#mainnav-menu > li > a, #mainnav-menu-wrap .mainnav-widget a[data-toggle="menu-widget"]');
        nifty.mainNavHeight = Ember.$('#mainnav').height();
        nifty.document.trigger('nifty.ready');
        nifty.knowmeReady = true;
      }
    },
    updateScroller: function () {
      Ember.run.later(function () {
        Ember.$(window).trigger('resize');
      }, 500);
    },
    currentYear: (0, _moment.default)().format('YYYY'),
    actions: {
      leadsWithThisSalesStage: function () {
        let id = this.get('session.salesStageId');
        this.transitionToRoute('leads.index', { queryParams: { filter: 'sales_stage', sales_stage_id: id } });
      },
      startEmailIntegration: function (e) {
        if (e) {
          e.preventDefault();
        }
        this.set('startEmailIntegration', true);
        this.transitionToRoute('profile');
        this.get('target._routerMicrolib').refresh();
      }
    }
  });

  exports.default = ApplicationController;
});