define('knowme-client/mixins/models/validations/product', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
    kind: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    taxable: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    taxRate: [(0, _emberCpValidations.validator)(function (value /*, options, model, attribute*/) {
      return value > 0 || !this.get('model.taxable');
    }, {
      dependentKeys: ['model.taxable']
    })],
    price: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true,
      allowBlank: true
    })],
    cost: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true,
      allowBlank: true
    }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
      let price = model.get('price');
      if (Ember.isPresent(price) && parseInt(value) > parseInt(price)) {
        return 'can\'t be greater than Rate';
      }
      return true;
    }, {
      dependentKeys: ['model.price']
    })],
    description: [(0, _emberCpValidations.validator)('length', { max: 60, allowBlank: true })]
  });
});