define('knowme-client/helpers/delivery-status', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.deliveryStatus = undefined;


  var deliveryStatus, DeliveryStatusHelper;

  exports.deliveryStatus = deliveryStatus = function (params) {
    let deliveryDate = params[0];
    if (Ember.isPresent(deliveryDate)) {
      let now = (0, _moment.default)();
      return now.isBefore(deliveryDate) ? 'pending' : 'delivered';
    } else {
      return '';
    }
  };

  DeliveryStatusHelper = Ember.Helper.helper(deliveryStatus);

  exports.deliveryStatus = deliveryStatus;
  exports.default = DeliveryStatusHelper;
});