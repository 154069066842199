define('knowme-client/mixins/models/validations/promo-code', ['exports', 'ember-cp-validations', 'moment'], function (exports, _emberCpValidations, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
		percentOff: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
		plan: [(0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			return Ember.isPresent(model.get('plan.id')) ? true : "";
		})],
		startDate: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		endDate: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			let result = 'cannot be lower than start date';
			let startDate = model.get('startDate');
			if (Ember.isBlank(startDate) || Ember.isPresent(startDate) && (0, _moment.default)(value).isSameOrAfter((0, _moment.default)(startDate))) {
				result = true;
			}
			return result;
		}, {
			dependentKeys: ['startDate']
		})]
	});
});