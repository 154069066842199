define('knowme-client/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/config.js should pass ESLint\n\n3:23 - \'MockWebSocket\' is defined but never used. (no-unused-vars)\n5:10 - \'underscore\' is defined but never used. (no-unused-vars)\n11:19 - \'create\' is defined but never used. (no-unused-vars)\n13:9 - Use import { isEmpty } from \'@ember/utils\'; instead of using Ember destructuring (ember/new-module-imports)\n13:18 - Use import { isPresent } from \'@ember/utils\'; instead of using Ember destructuring (ember/new-module-imports)\n13:29 - Use import { copy } from \'@ember/object/internals\'; instead of using Ember destructuring (ember/new-module-imports)\n13:35 - Use import { A } from \'@ember/array\'; instead of using Ember destructuring (ember/new-module-imports)\n18:9 - \'mockServer\' is assigned a value but never used. (no-unused-vars)\n19:9 - \'mockServer1\' is assigned a value but never used. (no-unused-vars)\n256:11 - Unexpected console statement. (no-console)\n285:25 - \'server\' is not defined. (no-undef)\n307:23 - \'server\' is not defined. (no-undef)\n319:66 - \'moment\' is not defined. (no-undef)\n354:11 - Unexpected console statement. (no-console)\n367:11 - Unexpected console statement. (no-console)\n368:11 - Unexpected console statement. (no-console)\n378:24 - \'server\' is not defined. (no-undef)\n388:11 - Unexpected console statement. (no-console)\n389:11 - Unexpected console statement. (no-console)\n398:15 - \'server\' is not defined. (no-undef)\n442:11 - Unexpected console statement. (no-console)\n447:13 - \'query\' is assigned a value but never used. (no-unused-vars)\n466:23 - \'server\' is not defined. (no-undef)\n477:25 - \'server\' is not defined. (no-undef)\n479:25 - \'server\' is not defined. (no-undef)\n506:11 - Unexpected console statement. (no-console)\n523:11 - Unexpected console statement. (no-console)\n551:29 - \'server\' is not defined. (no-undef)\n565:19 - \'server\' is not defined. (no-undef)\n625:76 - \'request\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/data.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/data.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/account-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/account-setting.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/account.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/account.js should pass ESLint\n\n81:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)\n82:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)\n83:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)\n85:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)\n86:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)\n110:15 - \'didAllocation\' is assigned a value but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/ad.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/ad.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/alert.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/alert.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/call-touch-point.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/call-touch-point.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/call.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/call.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/campaign.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/campaign.js should pass ESLint\n\n8:10 - \'Factory\' is defined but never used. (no-unused-vars)\n24:11 - \'role\' is assigned a value but never used. (no-unused-vars)\n25:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)');
  });

  QUnit.test('mirage/factories/chat-touch-point.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/chat-touch-point.js should pass ESLint\n\n2:19 - \'trait\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/column-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/column-setting.js should pass ESLint\n\n2:19 - \'trait\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/communication.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/communication.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/contact-address.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/contact-address.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/contact-email.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/contact-email.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/contact-field.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/contact-field.js should pass ESLint\n\n1:8 - \'faker\' is defined but never used. (no-unused-vars)\n2:19 - \'trait\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/contact-info.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/contact-info.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/contact-phone-number.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/contact-phone-number.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/contact-url.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/contact-url.js should pass ESLint\n\n8:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)');
  });

  QUnit.test('mirage/factories/contact.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/contact.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/custom-attribute.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/custom-attribute.js should pass ESLint\n\n2:19 - \'trait\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/customer.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/customer.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/dashboard-widget.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/dashboard-widget.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/did-allocation.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/did-allocation.js should pass ESLint\n\n7:8 - \'faker\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/did.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/did.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/incentive.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/incentive.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/lead-activity.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/lead-activity.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/lead.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/lead.js should pass ESLint\n\n10:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)\n11:9 - \'organization\' is assigned a value but never used. (no-unused-vars)\n15:2 - Mixed spaces and tabs. (no-mixed-spaces-and-tabs)\n46:36 - \'opportunity\' is defined but never used. (no-unused-vars)\n47:11 - \'product\' is assigned a value but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/notification.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/notification.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/opportunity-field.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/opportunity-field.js should pass ESLint\n\n1:8 - \'faker\' is defined but never used. (no-unused-vars)\n2:19 - \'trait\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/opportunity.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/opportunity.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/order.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/order.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/organization-field.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/organization-field.js should pass ESLint\n\n1:8 - \'faker\' is defined but never used. (no-unused-vars)\n2:19 - \'trait\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/organization.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/organization.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/plan.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/plan.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/product.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/product.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/review-link.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/factories/review-link.js should pass ESLint\n\n2:8 - \'faker\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/factories/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/role.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/room.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/room.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/sales-action.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/sales-action.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/sales-stage.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/sales-stage.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/short-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/short-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/subscription.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/subscription.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/users-dashboard-widget.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/users-dashboard-widget.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/helpers/factory.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/helpers/factory.js should pass ESLint\n\n7:8 - Use import { merge } from \'@ember/polyfills\'; instead of using Ember destructuring (ember/new-module-imports)');
  });

  QUnit.test('mirage/helpers/json-web-token.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/helpers/json-web-token.js should pass ESLint\n\n10:19 - \'moment\' is not defined. (no-undef)\n37:7 - \'signatureRefresh\' is assigned a value but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/helpers/seeds.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/helpers/seeds.js should pass ESLint\n\n2:10 - \'isBlank\' is defined but never used. (no-unused-vars)\n65:29 - \'index\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/account-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/account-setting.js should pass ESLint\n\n1:28 - \'hasMany\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/account.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/account.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/ad.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/ad.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/alert.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/alert.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/authentication.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/authentication.js should pass ESLint\n\n1:28 - \'hasMany\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/call-touch-point.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/call-touch-point.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/call.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/call.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/campaign.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/campaign.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/chat-touch-point.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/chat-touch-point.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/column-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/column-setting.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/communication.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/communication.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contact-address.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contact-address.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contact-email.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contact-email.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contact-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contact-field.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contact-info.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contact-info.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contact-phone-number.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contact-phone-number.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contact-url.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contact-url.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contact.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contact.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contract-agreement.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contract-agreement.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/contract-version.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/contract-version.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/custom-attribute.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/custom-attribute.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/customer-experience.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/customer-experience.js should pass ESLint\n\n1:28 - \'hasMany\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/dashboard-widget.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/dashboard-widget.js should pass ESLint\n\n1:17 - \'hasMany\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/did-allocation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/did-allocation.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/did.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/did.js should pass ESLint\n\n1:17 - \'belongsTo\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/event.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/event.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/incentive.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/incentive.js should pass ESLint\n\n1:17 - \'hasMany\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/lead-activity.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/lead-activity.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/lead.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/lead.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/message.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/message.js should pass ESLint\n\n1:28 - \'hasMany\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/note.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/note.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/notification.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/notification.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/opportunity-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/opportunity-field.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/opportunity.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/opportunity.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/order.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/order.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/organization-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/organization-field.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/organization.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/organization.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/plan.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/plan.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/product.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/product.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/review-link.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/review-link.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/role.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/role.js should pass ESLint\n\n1:17 - \'belongsTo\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/room.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/room.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/sales-action.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/sales-action.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/sales-stage.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/sales-stage.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/short-message.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/short-message.js should pass ESLint\n\n1:28 - \'hasMany\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/models/subscription.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/subscription.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/users-dashboard-widget.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/models/users-dashboard-widget.js should pass ESLint\n\n1:28 - \'hasMany\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/plan-settings.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/plan-settings.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/scenarios/default.js should pass ESLint\n\n1:9 - \'faker\' is defined but never used. (no-unused-vars)\n2:10 - \'factory\' is defined but never used. (no-unused-vars)\n2:19 - \'createList\' is defined but never used. (no-unused-vars)\n2:31 - \'create\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/serializers/account-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/account-setting.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/account.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/account.js should pass ESLint\n\n4:2 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/active-model-serializer.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/active-model-serializer.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/ad.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/ad.js should pass ESLint\n\n2:10 - \'underscore\' is defined but never used. (no-unused-vars)\n5:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/alert.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/alert.js should pass ESLint\n\n2:10 - \'underscore\' is defined but never used. (no-unused-vars)\n5:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/authentication.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/authentication.js should pass ESLint\n\n4:2 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/call-touch-point.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/call-touch-point.js should pass ESLint\n\n2:10 - \'underscore\' is defined but never used. (no-unused-vars)\n5:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/call.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/call.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/campaign.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/campaign.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)\n5:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/chat-touch-point.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/chat-touch-point.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/column-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/column-setting.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/contact-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/contact-field.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/contact-info.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/contact-info.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/contact.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/contact.js should pass ESLint\n\n2:15 - \'set\' is defined but never used. (no-unused-vars)\n6:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)\n7:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/contract-version.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/contract-version.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/custom-attribute.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/custom-attribute.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/dashboard-widget.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/dashboard-widget.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/did-allocation.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/did-allocation.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/did.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/did.js should pass ESLint\n\n2:10 - \'underscore\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/serializers/incentive.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/incentive.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/lead-activity.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/lead-activity.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/lead.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/lead.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)\n5:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/message.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/message.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/notification.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/notification.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/opportunity-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/opportunity-field.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/opportunity.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/opportunity.js should pass ESLint\n\n2:15 - \'set\' is defined but never used. (no-unused-vars)\n6:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)\n7:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/order.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/order.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/organization-field.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/organization-field.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/plan.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/plan.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/product.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/product.js should pass ESLint\n\n2:10 - \'underscore\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/serializers/review-link.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/review-link.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/role.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/room.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/room.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/sales-action.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/sales-action.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/sales-stage.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/sales-stage.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/short-message.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/short-message.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/subscription.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/subscription.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/user.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/user.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });

  QUnit.test('mirage/serializers/users-dashboard-widget.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/serializers/users-dashboard-widget.js should pass ESLint\n\n4:3 - Only string, number, symbol, boolean, null, undefined, and function are allowed as default properties (ember/avoid-leaking-state-in-ember-objects)');
  });
});