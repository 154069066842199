define('knowme-client/mixins/models/validations/lead', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		organization: (0, _emberCpValidations.validator)('belongs-to', {
			disabled: Ember.computed.bool('model.isbtoc')
		}),
		primaryContacts: (0, _emberCpValidations.validator)('has-many'),
		opportunities: (0, _emberCpValidations.validator)('has-many')
	});
});