define('knowme-client/helpers/capitalize-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var capitalizeString, CapitalizeStringHelper;

  exports.capitalizeString = capitalizeString = function (params) {
    var string = params[0] || '';
    return string.capitalize();
  };

  CapitalizeStringHelper = Ember.Helper.helper(capitalizeString);

  exports.capitalizeString = capitalizeString;
  exports.default = CapitalizeStringHelper;
});