define('knowme-client/helpers/member-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var memberStatus, MemberStatusHelper;

  exports.memberStatus = memberStatus = function (params) {
    let members = params[0];
    let currentUser = params[1];
    let member = members.filter(function (m) {
      return m.get('id') !== currentUser.get('id');
    })[0];
    return member.get('isOnline') ? 'online' : 'away';
  };

  MemberStatusHelper = Ember.Helper.helper(memberStatus);

  exports.memberStatus = memberStatus;
  exports.default = MemberStatusHelper;
});