define('knowme-client/mirage/factories/product', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: _faker.default.commerce.productName,
    price: _faker.default.commerce.price,
    kind: function () {
      return _faker.default.random.arrayElement(['product', 'service']);
    },
    fixedPrice: function () {
      return _faker.default.random.arrayElement([true, false]);
    },
    maxDiscount: function () {
      return _faker.default.datatype.number({ min: 0, max: 100 });
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    }
    // discount: function() { return faker.datatype.number({min: 0, max: 100}); }

    // name: 'Pete',                         // strings
    // age: 20,                              // numbers
    // tall: true,                           // booleans
    // firstName: faker.name.firstName,       // using faker
    // lastName: faker.name.firstName,
    // zipCode: faker.address.zipCode
  });
});