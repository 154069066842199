define('knowme-client/instance-initializers/ember-hifi', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    const { emberHifi, environment = 'development' } = _environment.default;
    const options = { emberHifi, environment };
    application.register('config:hifi', options, { instantiate: false });
    application.inject('service:hifi', 'options', 'config:hifi');
  }

  exports.default = {
    name: 'ember-hifi',
    initialize
  };
});