define('knowme-client/serializers/did-allocation', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      did: { serialize: false, deserialize: 'id' },
      didNumber: { deserialize: false },
      allocatable: { deserialize: 'id', serialize: 'id' }
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.relationships = hash.relationships || {};
      hash.relationships['did'] = hash.relationships['did'] || {};
      hash.relationships['did'].links = hash.relationships.links || {};
      if (Ember.isPresent(hash.relationships['did'].data)) {
        hash.relationships['did'].links.related = Ember.getOwner(this).lookup('adapter:did').buildURL('did', hash.relationships['did'].data.id, null, 'findRecord', null);
      }
      return this._super.apply(this, arguments);
    }
  });
});