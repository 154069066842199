define('knowme-client/mirage/models/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		account: (0, _emberCliMirage.belongsTo)(),
		roles: (0, _emberCliMirage.hasMany)(),
		calls: (0, _emberCliMirage.hasMany)('call'),
		lastCall: (0, _emberCliMirage.belongsTo)('call', { inverse: null }),
		shortMessages: (0, _emberCliMirage.hasMany)('short-message'),
		lastShortMessage: (0, _emberCliMirage.belongsTo)('short-message', { inverse: null }),
		usersDashboardWidgets: (0, _emberCliMirage.hasMany)(),
		didAllocation: (0, _emberCliMirage.belongsTo)(),
		attrs: ['id', 'name', 'email', 'token', 'account', 'lastActivityAt', 'isOnline']
	});
});