define('knowme-client/models/incentives-contact', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    incentive: (0, _relationships.belongsTo)('incentive', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    contact: (0, _relationships.belongsTo)('contact', { async: true, inverse: 'incentivesContacts' }),
    respondedContacts: (0, _relationships.hasMany)('contact', { async: true, inverse: 'incentivesContact' }),
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
    incentivesContactTarget: (0, _relationships.belongsTo)('incentives-contact-target', { async: true, polymorphic: true, inverse: 'incentivesContacts' }),
    incentivesContactTargetType: Ember.computed('incentivesContactTarget', function () {
      if (this.get('incentivesContactTarget').content) {
        return this.get('incentivesContactTarget').content.constructor.modelName.camelize().capitalize();
      } else {
        return this.get('incentivesContactTarget').constructor.modelName.camelize().capitalize();
      }
    }),
    isSentViaText: Ember.computed('incentivesContactTargetType', function () {
      return this.get('incentivesContactTargetType') === 'ContactPhoneNumber';
    }),
    isSentViaEmail: Ember.computed('incentivesContactTargetType', function () {
      return this.get('incentivesContactTargetType') === 'ContactEmail';
    }),
    shortenedUrl: (0, _attr.default)(),
    createAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    claimed: (0, _attr.default)('boolean')
  });
});