define('knowme-client/controllers/leads/form', ['exports', 'knowme-client/mixins/lead-form'], function (exports, _leadForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsFormController;

  LeadsFormController = Ember.Controller.extend(_leadForm.default, {});

  exports.default = LeadsFormController;
});