define('knowme-client/routes/wizard/profile', ['exports', 'knowme-client/mixins/routes/cable-setup'], function (exports, _cableSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let WizardClientRoute;

  WizardClientRoute = Ember.Route.extend(_cableSetup.default, {
    channelName: 'UserChannel',
    session: Ember.inject.service(),
    model: function () /*params*/{
      return this.get('session.currentUser').then(function (user) {
        this.set('subscriptionData', { id: user.get('id') });
        return user.reload();
      }.bind(this)).then(function (user) {
        if (Ember.isBlank(user.get('didAllocation.id'))) {
          this.store.createRecord('did-allocation', { allocatable: user });
        }
        return user;
      }.bind(this));
    },
    afterModel: function () {
      return Ember.RSVP.hash({
        plans: this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc' })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    afterReceived: function (controller, data, action) {
      if (!controller.get('skipRedirectToDashboard') && !this.get('session.currentUser.isProfileWizardRequired') && Ember.getOwner(this).lookup('controller:application').currentPath === 'wizard.profile') {
        this.transitionTo('dashboard');
      }
    }
  });

  exports.default = WizardClientRoute;
});