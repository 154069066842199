define('knowme-client/helpers/sort-class-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var sortClassName, SortClassNameHelper;

  exports.sortClassName = sortClassName = function (params) {
    var sortBy = params[0],
        order = params[1],
        column = params[2];
    if (Ember.isEmpty(sortBy)) {
      return '';
    }
    if (sortBy === column && order === 'desc') {
      return 'fa fa-angle-down gray';
    } else if (sortBy === column && order === 'asc') {
      return 'fa fa-angle-up gray';
    }
  };

  SortClassNameHelper = Ember.Helper.helper(sortClassName);

  exports.sortClassName = sortClassName;
  exports.default = SortClassNameHelper;
});