define('knowme-client/mirage/serializers/lead-activity', ['exports', 'knowme-client/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    relationships: ['opportunity', 'lead'],
    alwaysIncludeLinkageData: true
  });
});