define('knowme-client/helpers/percentage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.percentage = percentage;
  function percentage(pct, opts) {
    opts = opts || {};

    let value = (pct || 0) * 100;
    let numDecimals = opts.decimals || 0;

    return `${value.toFixed(value % 1 === 0 ? 0 : numDecimals)}%`;
  }

  exports.default = Ember.Helper.helper(percentage);
});