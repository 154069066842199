define('knowme-client/helpers/math-op', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var mathOp, MathOpHelper;

	exports.mathOp = mathOp = function (params) {
		let operator = params[0],
		    lvalue = params[1],
		    rvalue = params[2];
		let operations = {
			'+': function (lvalue, rvalue) {
				return lvalue + rvalue;
			},
			'-': function (lvalue, rvalue) {
				return lvalue - rvalue;
			},
			'*': function (lvalue, rvalue) {
				return lvalue * rvalue;
			},
			'/': function (lvalue, rvalue) {
				return lvalue / rvalue;
			},
			'-%': function (lvalue, rvalue) {
				return lvalue - lvalue * rvalue / 100;
			}
		};
		let result = operations[operator](lvalue, rvalue);
		return result;
	};

	MathOpHelper = Ember.Helper.helper(mathOp);

	exports.mathOp = mathOp;
	exports.default = MathOpHelper;
});