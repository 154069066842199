define('knowme-client/routes/account/clients', ['exports', 'lodash', 'knowme-client/mixins/routes/account-setup'], function (exports, _lodash, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    queryParams: {
      status: { refreshModel: true },
      query: { refreshModel: true },
      type: { refreshModel: true },
      filter: { refreshModel: true }
    },
    pagination: {},
    beforeModel: function (transition) {
      this._super(...arguments);
      if (Ember.isBlank(transition.queryParams.status)) {
        return this.replaceWith('account.clients', { queryParams: { status: 'active', filter: 'paid_and_trial', type: null } });
        // this.transitionTo({queryParams: {status: 'active'}});
      }
      // let self = this;
      return this.get('session.currentUser').then(function (user) {
        return user.get('roles');
      });
      // .then(function(){
      //   console.log(self.get('session.currentUser.isMasterAdmin'));
      // });
    },
    model: function (params) {
      return this.get('store').query('account', this.processParams(params));
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);
      params.include = "admin_users";
      return _lodash.default.pickBy(params, _lodash.default.identity);
    },
    actions: {
      queryParamsDidChange: function () {
        switch (this.get('router.currentPath')) {
          case 'account.clients.new':
          case 'account.clients.edit':
            this.transitionTo('account.clients');
            break;
        }
        this._super(...arguments);
        this.refresh();
      }
    }
  });
});