define('knowme-client/serializers/contact-email', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ContactEmailSerializer;

  ContactEmailSerializer = _application.default.extend({
    attrs: {
      contactInfo: { serialize: false },
      surveys: { deserialize: false, serialize: 'records' }
    },
    normalize(typeClass, hash) {
      hash.links = {
        surveys: Ember.getOwner(this).lookup('adapter:survey').buildURL('survey', null, null, 'findMany', { contact_email_id: hash.id, paginate: false }),
        incentivesContacts: Ember.getOwner(this).lookup('adapter:incentives-contact').buildURL('incentives-contact', null, null, 'findMany', { target_id: hash.id, target_type: 'ContactEmail', paginate: false }),
        reviewLinksContacts: Ember.getOwner(this).lookup('adapter:review-links-contact').buildURL('review-links-contact', null, null, 'findMany', { target_id: hash.id, target_type: 'ContactEmail', paginate: false })
      };
      return this._super.apply(this, arguments);
    }
  });

  exports.default = ContactEmailSerializer;
});