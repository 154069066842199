define('knowme-client/models/message', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/models/attachment-resource'], function (exports, _model, _attr, _relationships, _attachmentResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _attachmentResource.default.extend({
    // relationships
    contact: (0, _relationships.belongsTo)('contact', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
    lead: (0, _relationships.belongsTo)('lead', { async: true }),
    // attachments: hasMany('attachment', {async: true, inverse: 'message'}),
    //fields
    contentWidth: (0, _attr.default)(),
    lead_id: (0, _attr.default)(),
    contact_id: (0, _attr.default)(),
    user_id: (0, _attr.default)(),
    opportunity_id: (0, _attr.default)(),
    template_id: (0, _attr.default)(),
    previousCount: Ember.computed('previous', function () {
      return this.get('previous').length;
    }),
    originalMessageId: (0, _attr.default)(),
    knowmeCustomerId: (0, _attr.default)(),
    from: (0, _attr.default)(),
    to: (0, _attr.default)(),
    subject: (0, _attr.default)(),
    subjectOrPlaceholder: Ember.computed('subject', function () {
      return Ember.isBlank(this.get('subject')) ? '(no subject)' : this.get('subject');
    }),
    body: (0, _attr.default)(),
    cc: (0, _attr.default)(),
    bcc: (0, _attr.default)(),
    emailsCount: (0, _attr.default)(),
    opportunitiesCount: (0, _attr.default)(),
    isInbox: (0, _attr.default)('boolean'),
    isOwn: (0, _attr.default)('boolean'),
    isDraft: (0, _attr.default)('boolean'),
    isJunk: (0, _attr.default)('boolean'),
    date: (0, _attr.default)('date'),
    sentAt: (0, _attr.default)('date'),
    deletedAt: (0, _attr.default)('date')
  });
});