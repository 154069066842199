define('knowme-client/models/chat-message-attachment', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    message: (0, _relationships.belongsTo)('chat-message', { async: true }),
    filename: (0, _attr.default)(),
    shortUrl: (0, _attr.default)(),
    attachmentUrl: (0, _attr.default)(),
    byteSize: (0, _attr.default)(),
    duration: (0, _attr.default)(),
    isAudio: (0, _attr.default)('boolean', { defaultValue: false })
  });
});