define('knowme-client/torii-providers/yelp-oauth2', ['exports', 'torii/providers/oauth2-code', 'torii/lib/query-string', 'torii/configuration'], function (exports, _oauth2Code, _queryString, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var get = Ember.String.get;

  var IntuitOauth2 = _oauth2Code.default.extend({

    name: 'yelp-oauth2',
    baseUrl: 'https://biz.yelp.com/oauth2/authorize',

    requiredUrlParams: ['client_id', 'redirect_uri', 'response_type', 'api_key'],
    // no need encode
    nonEncodeURIComponents: ['scope'],

    requestVisibleActions: (0, _configuration.configurable)('requestVisibleActions', ''),

    responseType: (0, _configuration.configurable)('responseType', 'code'),

    responseParams: ['code', 'state', 'realmId'],

    redirectUri: (0, _configuration.configurable)('redirectUri'),

    buildQueryString: function () {
      var requiredParams = this.requiredUrlParams,
          optionalParams = this.optionalUrlParams;

      var qs = _queryString.default.create({
        provider: this,
        requiredParams: requiredParams
      });
      // skip encoding encodeURIComponents
      var keyValuePairs = Ember.A([]);
      this.nonEncodeURIComponents.forEach(function (paramName) {
        var camelizedName = Ember.String.camelize(paramName),
            paramValue = this.get(camelizedName);

        if (paramValue || paramValue === false) {
          keyValuePairs.push([paramName, paramValue]);
        }
      }.bind(this));
      var str = keyValuePairs.map(function (pair) {
        return pair.join('=');
      }).join('&');
      //optionalParams: optionalParams

      return qs.toString() + '&' + str;
    },

    open: function (options) {
      var name = this.name,
          url = this.buildUrl(),
          redirectUri = this.redirectUri,
          responseParams = this.responseParams,
          responseType = this.responseType,
          state = this.state,
          shouldCheckState = responseParams.indexOf('state') !== -1;

      return this.popup.open(url, responseParams, options).then(function (authData) {
        var missingResponseParams = [];

        responseParams.forEach(function (param) {
          if (authData[param] === undefined) {
            missingResponseParams.push(param);
          }
        });

        if (missingResponseParams.length) {
          throw new Error("The response from the provider is missing " + "these required response params: " + missingResponseParams.join(', '));
        }

        if (shouldCheckState && authData.state !== state) {
          throw new Error('The response from the provider has an incorrect ' + 'session state param: should be "' + state + '", ' + 'but is "' + authData.state + '"');
        }

        return authData;
      });
    }
  });

  exports.default = IntuitOauth2;
});