define('knowme-client/helpers/command-messanges-to-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    compute([contactPhoneNumber, opportunity, user]) {
      return this.get('store').query('short-message', {
        filter: 'inbound,to_user,command_messanges',
        contact_phone_number_id: contactPhoneNumber.get('id'),
        user_id: user.get('id'),
        sort_by: 'short_message_date',
        order: 'desc',
        size: 1
      });
    }
  });
});