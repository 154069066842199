define('knowme-client/serializers/order', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      opportunity: { serialize: false },
      product: { serialize: 'id', deserialize: 'id' }
    },
    serialize: function (snapshot /*, options*/) {
      var json = this._super(...arguments);
      delete json.total;
      if (snapshot.record.get('product.fixedPrice')) {
        delete json.deal_price;
      }
      if (snapshot.record.get('isDeleted')) {
        json._destroy = true;
      }
      if (snapshot.record.get('isNew') && snapshot._internalModel.__attributes.dealPrice != snapshot.record.get('dealPrice')) {
        json.deal_price = snapshot._internalModel.__attributes.dealPrice;
      }
      if (snapshot.record.get('isNew') && snapshot._internalModel.__attributes.quantity != snapshot.record.get('quantity')) {
        json.quantity = snapshot._internalModel.__attributes.quantity;
      }
      return json;
    },
    keyForRelationship: function (key, relationship, method) {
      if (['product'].includes(key) && method === 'deserialize') {
        return `${Ember.String.underscore(key)}_id`;
      }
      return this._super(...arguments);
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.links = {
        product: Ember.getOwner(this).lookup('adapter:product').buildURL('product', hash.product_id, null, 'findRecord', null)
      };
      return this._super.apply(this, arguments);
    }
  });
});