define('knowme-client/serializers/invoice', ['exports', 'ember-inflector', 'knowme-client/serializers/json-api'], function (exports, _emberInflector, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      // contact: { deserialize: 'id', serialize: 'id' },
      // opportunity: { deserialize: 'id', serialize: 'id' },
      // contact: { embedded: 'always' },
      // opportunity: { embedded: 'always' },
    },
    normalize(typeClass, hash /*, prop*/) {
      hash = hash || {};
      hash.relationships = hash.relationships || {};

      let models = ['opportunity', 'contact', 'user'];
      models.forEach(function (model) {
        hash.relationships[model] = hash.relationships[model] || {};
        hash.relationships[model].links = hash.relationships.links || {};

        if (Ember.isPresent(hash.relationships[model].data)) {
          hash.relationships[model].links.related = Ember.getOwner(this).lookup(`adapter:${model}`).buildURL(model, hash.relationships[model].data.id, null, 'findRecord', null);
        }
      }.bind(this));

      return this._super.apply(this, arguments);
    }
  });
});