define('knowme-client/routes/user-customers', ['exports', 'knowme-client/mixins/routes/account-setup', 'lodash'], function (exports, _accountSetup, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let UserCustomersIndexRoute;

  UserCustomersIndexRoute = Ember.Route.extend(_accountSetup.default, {
    pagination: {},
    model: function (params) {
      params = _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value /*, key*/) {
        return value;
      });

      params.fields = [];
      params.paginate = false;
      params.status = 'active';
      params.converted = '1';
      params.fields.push('calls_count');

      let currentUser = this.get('session.currentUser');
      let users = [];

      if (currentUser.get('isSalesPerson')) {
        users = [currentUser];
      } else if (currentUser.get('isManager')) {
        params.exclude_admins = true;
        users = this.get('store').query('user', params);
      } else {
        users = this.get('store').query('user', params);
      }
      return users;
    },
    actions: {
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      },
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });

  exports.default = UserCustomersIndexRoute;
});