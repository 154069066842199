define('knowme-client/mirage/data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var data = {
    summary: {
      campaigns: {
        campaignsSummary: {
          totalActive: 50,
          totalCost: 149645.00,
          totalLeads: 138,
          averageCostLead: 1084.38,
          campaignPipeline: 25061.55,
          totalCustomers: 10,
          totalRevenue: 3641.83,
          averageROI: -0.98
        }
      },
      leads: {
        leadsSummary: {
          actionRequired: 15,
          appointments: 3,
          pastDue: 3,
          highPriority: 25,
          assigned_leads: 0,
          unassigned_leads: 2,
          active_leads: 9,
          leads_by_users: 0,
          allLeads: 9,
          withPipeline: 2,
          inactive_leads: 0,
          with_opportunities: 14,
          generatedToday: 0,
          convertedToday: 0,
          newYTD: 128,
          convertedYTD: 6,
          leadPipeline: 37834.68
        }
      },
      customers: {
        customersSummary: {
          actionRequired: 0,
          appointments: 0,
          pastDue: 3,
          highPriority: 11,
          assignedCustomers: 22,
          customers_by_users: 303,
          active_customers: 304,
          inactive_customers: 0,
          allCustomers: 304,
          averageSale: 403.63,
          lifetimeRevenue: 123106.2,
          withPipeline: 4,
          customerPipeline: 64191.0,
          allWithPipeline: 20,
          allPipeline: 137978.0
        }
      },
      customerExperiences: {
        customerExperiencesSummary: {
          veryDissatisfied: 3,
          dissatisfied: 1,
          neutral: 3,
          satisfied: 10,
          verySatisfied: 16,
          unhappy: 7,
          happy: 26,
          improved: 2,
          regressed: 6,
          none: 9,
          surveyRequested: 35,
          respondedToSurvey: 32,
          callsGradedByUser: 3,
          callsGradedByCaller: 4,
          noResponse: 3
        }
      },
      salesStages: {
        salesStagesSummary: [{ id: 1, name: 'Unqualified', opportunities_count: 185, funnel_visible: true }, { id: 2, name: 'Qualified', opportunities_count: 13, funnel_visible: true }, { id: 3, name: 'Demo / Evaluation', opportunities_count: 2, funnel_visible: true }, { id: 4, name: 'Appointment', opportunities_count: 0, funnel_visible: true }, { id: 5, name: 'Estimate Sent', opportunities_count: 4, funnel_visible: true }, { id: 6, name: 'Closed / Won', opportunities_count: 305, funnel_visible: true }, { id: 7, name: 'Estimate Rejected', opportunities_count: 1, funnel_visible: true }, { id: 8, name: 'Closed / Lost', opportunities_count: 1, funnel_visible: true }, { id: 9, name: 'Negotiating Terms', opportunities_count: 2, funnel_visible: true }, { id: 10, name: 'Closed / Wrong', opportunities_count: 0, funnel_visible: true }, { id: 11, name: 'Early Shopper', opportunities_count: 0, funnel_visible: true }, { id: 12, name: 'Call Next Week', opportunities_count: 0, funnel_visible: true }]
      },
      reports: {}
    }
  };

  let summary = exports.summary = data.summary;
});