define('knowme-client/helpers/start-case', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.startCase = startCase;
  function startCase(params) {
    return _lodash.default.startCase(params[0]);
  }

  exports.default = Ember.Helper.helper(startCase);
});