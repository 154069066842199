define('knowme-client/mixins/contactable', ['exports', 'ember-data/relationships'], function (exports, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    contacts: (0, _relationships.hasMany)('contact', { async: true }),
    primaryContacts: Ember.computed.filter('contacts', function (contact /*, index, array*/) {
      return contact.get('primary');
    }).property('contacts.@each.primary'),
    primaryContact: Ember.computed.alias('primaryContacts.firstObject'),
    primaryContactInfos: Ember.computed('primaryContact', function () {
      return this.get('primaryContact').get('contactInfos');
    }),
    primaryContactPhone: Ember.computed('primaryContactInfos', function () {
      return this.get('primaryContactInfos.firstObject').get('contactPhoneNumbers.firstObject.formattedTenDigitNumber');
    }),
    primaryContactFullName: Ember.computed('primaryContact', function () {
      return this.get('primaryContact.firstName') + " " + this.get('primaryContact.lastName');
    }),
    primaryWorkContactInfo: Ember.computed('primaryContactInfos.length', function () {
      return this.get('primaryContactInfos').findBy('category', 'work');
    }),
    contactsContactInfos: Ember.computed('contacts.@each.contactInfos', function () {
      return this.get('contacts').reduce(function (a, b) {
        return a.concat(b.get('contactInfos').toArray());
      }, []);
    }),
    contactsContactEmails: Ember.computed('contacts.@each.contactInfosContactEmails', function () {
      return this.get('contacts').reduce(function (a, b) {
        return a.concat(b.get('contactInfosContactEmails'));
      }, []);
    }),
    contactsContactPhoneNumbers: Ember.computed('contacts.@each.contactInfosContactPhoneNumbers', function () {
      return this.get('contacts').reduce(function (a, b) {
        return a.concat(b.get('contactInfosContactPhoneNumbers'));
      }, []);
    }),
    contactsContactAddresses: Ember.computed('contacts.@each.contactInfosContactAddresses', function () {
      return this.get('contacts').reduce(function (a, b) {
        return a.concat(b.get('contactInfosContactAddresses'));
      }, []);
    })
  });
});