define('knowme-client/components/masked-input', ['exports', 'ember-mask-input/ember-mask-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    _reverse: Ember.computed('reverse', function () {
      if (Ember.isPresent(this.get('reverse'))) {
        return this.get('reverse');
      }
      return false;
    }),
    didInsertElement() {
      this._super(...arguments);
      if (Ember.isPresent(this.get('_mask'))) {
        this.$().mask(this.get('_mask'), {
          clearIfNotMatch: this.get('_clearIfNotMatch'),
          selectOnFocus: this.get('_selectOnFocus'),
          reverse: this.get('_reverse')
        });
      }
    }
  });
});