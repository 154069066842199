define('knowme-client/mixins/models/original-data', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		setupOriginalData: Ember.on('init', function () {
			let originalDataComputedOptions = [];
			originalDataComputedOptions = originalDataComputedOptions.concat(['currentState.stateName']);
			// originalDataComputedOptions = originalDataComputedOptions.concat([`_internalModel._data.{${get(this.constructor, 'attributes')._keys.list.join(',')}}`]);
			originalDataComputedOptions = originalDataComputedOptions.concat(Ember.get(this.constructor, 'attributes')._keys.list);
			originalDataComputedOptions = originalDataComputedOptions.concat([function () {
				return Ember.copy(this.get('_internalModel._data'));
			}]);
			Ember.defineProperty(this, 'originalData', Ember.computed.apply(this, originalDataComputedOptions));
		})
	});
});