define('knowme-client/mixins/lead-form', ['exports', 'knowme-client/mixins/controllers/validations/leads/contacts-form', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/controllers/status'], function (exports, _contactsForm, _emberCpValidationsCustomProperties, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadFormMixin;

  LeadFormMixin = Ember.Mixin.create(_contactsForm.default, _emberCpValidationsCustomProperties.default, _status.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    email: null,
    emailDirty: false,
    phone: null,
    phoneDirty: false,
    address: null,
    addressDirty: false,
    eachAttribute: function (iterator) {
      ['email', 'phone', 'address'].forEach(function (attribute) {
        iterator.call(this, attribute);
      });
    },
    defaults: {
      category: {
        'b2b': { email: 'work', phone: 'work', address: 'billing' },
        'b2c': { email: 'home', phone: 'work', address: 'billing' }
      },
      contactInfo: { email: 'contactEmail', phone: 'contactPhoneNumber', address: 'contactAddress' }
    },
    contactOptions: {
      btob: {
        email: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }],
        phone: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }],
        address: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }]
      },
      btoc: {
        email: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }],
        phone: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }, { id: 'mobile', name: 'Mobile' }],
        address: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }]
      }
    },

    didReceiveAttrs(options) {
      this.get('organizationFields').forEach(function (field) {
        if (field.get('internal') || !field.get('enable')) {
          return;
        }
        let customAttribute = this.get('model.organization.customAttributes').findBy('name', field.get('key'));
        if (Ember.isPresent(customAttribute)) {
          customAttribute.setProperties({ 'position': field.get('position'), displayName: field.get('name') });
        } else {
          let ca = this.get('store').createRecord('custom-attribute', {
            customizableResource: this.get('model.organization'),
            name: field.get('key'),
            displayName: field.get('name'),
            value: ""
            // position: field.get('position')
          });
        }
      }.bind(this));
      this.get('contactFields').forEach(function (field) {
        if (field.get('internal') || !field.get('enable')) {
          return;
        }
        let customAttribute = this.get('model.primaryContact.customAttributes').findBy('name', field.get('key'));
        if (Ember.isPresent(customAttribute)) {
          customAttribute.setProperties({ 'position': field.get('position'), displayName: field.get('name') });
        } else {
          let ca = this.get('store').createRecord('custom-attribute', {
            customizableResource: this.get('model.primaryContact'),
            name: field.get('key'),
            displayName: field.get('name'),
            value: ""
            // position: field.get('position')
          });
        }
      }.bind(this));
    },
    addOrRemoveShippingAddress: Ember.observer('shippingAddressIsTheSame', function () {
      if (this.get('shippingAddressIsTheSame')) {
        let primaryContactInfos = this.get('model.primaryContactInfos');
        let contactInfo = primaryContactInfos.findBy('category', 'shipping');
        if (Ember.isPresent(contactInfo)) {
          let shippingContactAddress = contactInfo.get('contactAddresses.firstObject');
          this.set('addressToBeRemoved', shippingContactAddress);
          this.send('removeContactAddress');
        }
      } else {
        if (this.get('addressesCount') == 1) {
          this.send('addContactAddress');
        }
      }
    }),
    openPrimaryContactCustomFields: Ember.computed('model.primaryContact.customAttributes', function () {
      let open = false;
      this.get('model.primaryContact.customAttributes').forEach(function (attr) {
        open = Ember.isPresent(attr.get('value')) || open;
      });
      return open;
    }),
    openOrganizationCustomFields: Ember.computed('model.organization.customAttributes', function () {
      let open = false;
      if (Ember.isBlank(this.get('model.organization.customAttributes'))) {
        return open;
      }
      this.get('model.organization.customAttributes').forEach(function (attr) {
        open = Ember.isPresent(attr.get('value')) || open;
      });
      return open;
    }),
    actions: {
      addContactEmail: function () {
        if (this.get('emailsCount') >= 3) {
          return false;
        }
        var email = this.get('email'); // get value for email
        if (email === "" || !Ember.isEmpty(this.get('validations.attrs.email.errors'))) {
          return false;
        }
        var primaryContactInfos = this.get('model.primaryContactInfos');
        var type = this.get('model.leadType');
        var category = this.defaults.category[type].email; // Select default category for b2b/b2c and email

        var contactEmail = this.get('store').createRecord('contactEmail', { email: email }); // build contact email
        var contactInfo;
        if (Ember.isEmpty(primaryContactInfos)) {
          contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
          primaryContactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
        } else {
          contactInfo = primaryContactInfos.findBy('category', category);
          if (!Ember.isPresent(contactInfo)) {
            contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
            primaryContactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
          }
        }
        var contactEmails = contactInfo.get('contactEmails'); // get contact emails from contact info
        contactEmails.pushObject(contactEmail); // add contact email to contact emails
        this.set('email', '');
      },
      contactEmailInputKeyUp: function (contactEmail, value, event) {
        if (event.which === 8 && value === "") {
          event.preventDefault();
          contactEmail.deleteRecord();
          this.set('email', null);
          let element = event.currentTarget.parentElement.parentElement;
          Ember.$(element.nextElementSibling || element.previousElementSibling).find('input').focus(); // this is really ugly but the way to go is to create a input component that interacts with others
          if (this.get('phonesCount') + this.get('emailsCount') == 0) {
            this.get('flashMessages').warning('Contact should have at least one phone number or email', { destroyOnClick: true, timeout: 12000 });
          }
        }
      },
      addContactPhoneNumber: function () {
        if (this.get('phonesCount') >= 3) {
          return false;
        }
        var phone = this.get('phone'); // get value for email
        if (phone === "" || phone === null || !Ember.isEmpty(this.get('validations.attrs.phone.errors'))) {
          return false;
        }
        var primaryContactInfos = this.get('model.primaryContactInfos');
        var type = this.get('model.leadType');
        var category = this.defaults.category[type].phone; // Select default category for b2b/b2c and phone

        var contactPhoneNumber = this.get('store').createRecord('contactPhoneNumber', { tenDigitNumber: phone }); // build contact phone number
        var contactInfo;
        if (Ember.isEmpty(primaryContactInfos)) {
          contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
          primaryContactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
        } else {
          contactInfo = primaryContactInfos.findBy('category', category);
          if (!Ember.isPresent(contactInfo)) {
            contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
            primaryContactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
          }
        }
        var contactPhoneNumbers = contactInfo.get('contactPhoneNumbers'); // get contact phone numbers from contact info
        contactPhoneNumbers.pushObject(contactPhoneNumber); // add contact email to contact emails
        this.set('phone', '');
      },
      contactPhoneNumberInputKeyUp: function (contactPhoneNumber, value, event) {
        if (event.which === 8 && value === "") {
          event.preventDefault();
          if (contactPhoneNumber.get('deletable')) {
            contactPhoneNumber.deleteRecord();
            this.set('phone', null);
            let element = event.currentTarget.parentElement.parentElement;
            Ember.$(element.nextElementSibling || element.previousElementSibling).find('input').focus(); // this is really ugly but the way to go is to create a input component that interacts with others
            if (this.get('phonesCount') + this.get('emailsCount') == 0) {
              this.get('flashMessages').warning('Contact should have at least one phone number or email', { destroyOnClick: true, timeout: 12000 });
            }
          } else {
            contactPhoneNumber.rollbackAttributes();
            this.get('flashMessages').info('This number can not be deleted', { destroyOnClick: true });
          }
        }
      },
      confirmRemoveContactAddress: function (contactAddress, contactInfo) {
        this.set('addressToBeRemoved', contactAddress);
      },
      removeContactAddress: function () {
        if (Ember.isPresent(this.get('addressToBeRemoved'))) {
          let contactAddress = this.get('addressToBeRemoved');
          var contactInfo = contactAddress.get('contactInfo');
          if (contactAddress.get('isNew')) {
            var contactAddresses = contactInfo.get('contactAddresses');
            contactAddresses.removeObject(contactAddress);
          }

          if (contactInfo.get('contactAddresses.length') === 0) {
            if (contactInfo.deleteRecord) {
              contactInfo.deleteRecord();
            } else {
              contactInfo.content.deleteRecord();
            }
          }
          contactAddress.deleteRecord();
        }
      },
      addContactAddress: function () {
        if (this.get('addressesCount') >= 2) {
          return false;
        }
        var primaryContactInfos = this.get('model.primaryContactInfos');
        var type = this.get('model.leadType');
        var category = this.defaults.category[type].address; // Select default category for b2b/b2c and phone
        if (this.get('addressesCount') > 0) {
          category = 'shipping';
        }
        var contactAddress = this.get('store').createRecord('contactAddress', {}); // build contact address
        var contactInfo;
        if (Ember.isEmpty(primaryContactInfos)) {
          contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
          primaryContactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
        } else {
          contactInfo = primaryContactInfos.findBy('category', category);
          if (!Ember.isPresent(contactInfo)) {
            contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
            primaryContactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
          }
        }
        var contactAddresses = contactInfo.get('contactAddresses'); // get contact phone numbers from contact info
        contactAddresses.pushObject(contactAddress); // add contact email to contact emails

        let index = this.get('addressesCount') - 1;
        Ember.run.later(function () {
          Ember.$(Ember.$('.street')[index]).find('input').focus();
        }, 200);
      },
      displaySelector: function (contactResource) {
        contactResource.set('displaySelector', !contactResource.get('displaySelector'));
      },
      changeCategory: function (category, contactResource) {
        contactResource.set('displaySelector', false);
        var primaryContactInfos = this.get('model.primaryContactInfos');
        var contactInfo = primaryContactInfos.findBy('category', category);
        if (!Ember.isPresent(contactInfo)) {
          contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
          primaryContactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
        }
        contactResource.set('contactInfo', contactInfo);
      },
      submit: function () {
        let controller = this;
        this.model.one('didCreate', function () {
          ['contact', 'contactInfo', 'contactEmail', 'contactPhoneNumber', 'contactAddress', 'contactUrl', 'note', 'opportunity', 'order' /*, 'organization'*/].forEach(function (modelName) {
            // TODO: FIX! - organization is being deleted completely including the fetched from the server
            controller.store.peekAll(modelName).filterBy('id', null).invoke('deleteRecord');
          });
        });
        this.model.save().then(function (lead) {
          controller.setProperties({ emailDirty: null, phoneDirty: null, addressDirty: null });
          controller.get('target._routerMicrolib').refresh();
          if (controller.get('newOpportunity.isClosed')) {
            controller.transitionToRoute('leads.show.opportunities', lead);
          } else {
            controller.transitionToRoute('leads.show', lead.id);
          }
        }).catch(function (reason) {
          this.get('flashMessages').warning(reason.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
          console.log("Errors", Ember.inspect(reason));
        }.bind(this));
      },
      resetChanges: function () {
        this.model.get('contacts').forEach(function (contact) {
          contact.get('contactInfos').forEach(function (contactInfo) {
            contactInfo.get('contactEmails').filterBy('isNew', false).invoke('rollbackAttributes');
            contactInfo.get('contactEmails').filterBy('isNew', true).invoke('deleteRecord');
            contactInfo.get('contactPhoneNumbers').filterBy('isNew', false).filterBy('hasDirtyAttributes', true).invoke('rollbackAttributes');
            contactInfo.get('contactPhoneNumbers').filterBy('isNew', false).filterBy('hasDirtyAttributes', false).invoke('reload');
            contactInfo.get('contactPhoneNumbers').filterBy('isNew', true).invoke('deleteRecord');
            contactInfo.get('contactUrls').filterBy('isNew', false).invoke('rollbackAttributes');
            contactInfo.get('contactUrls').filterBy('isNew', true).invoke('deleteRecord');
            contactInfo.get('contactAddresses').filterBy('isNew', false).invoke('rollbackAttributes');
            contactInfo.get('contactAddresses').filterBy('isNew', true).invoke('deleteRecord');
          });
          contact.get('contactInfos').filterBy('isNew', true).invoke('deleteRecord');
          contact.get('customAttributes').filterBy('isNew', true).invoke('deleteRecord');
          contact.get('customAttributes').filterBy('isNew', false).invoke('rollbackAttributes');
          contact.rollbackAttributes();
        });

        if (Ember.isPresent(this.model.get('organization'))) {
          this.model.get('organization.customAttributes').filterBy('isNew', true).invoke('deleteRecord');
          this.model.get('organization.customAttributes').filterBy('isNew', false).invoke('rollbackAttributes');
          this.model.get('organization').rollbackAttributes();
        }
        this.model.rollbackAttributes();
      }
    },
    // relationship properties
    emailsCount: Ember.computed('model.primaryContact.contactInfosContactEmails.length', 'model.primaryContact.contactInfosContactEmails.@each.isDeleted', function () {
      return Ember.isPresent(this.get('model')) ? this.get('model.primaryContact.contactInfosContactEmails').filterBy('isDeleted', false).length : 0;
    }),
    phonesCount: Ember.computed('model.primaryContact.contactInfosContactPhoneNumbers.length', 'model.primaryContact.contactInfosContactPhoneNumbers.@each.isDeleted', function () {
      return Ember.isPresent(this.get('model')) ? this.get('model.primaryContact.contactInfosContactPhoneNumbers').filterBy('isDeleted', false).length : 0;
    }),
    addressesCount: Ember.computed('model.primaryContact.contactInfosContactAddresses.length', 'model.primaryContact.contactInfosContactAddresses.@each.isDeleted', function () {
      let count = Ember.isPresent(this.get('model')) ? this.get('model.primaryContact.contactInfosContactAddresses').filterBy('isDeleted', false).length : 0;
      this.set('shippingAddressIsTheSame', count === 1);
      return count;
    }),
    newOpportunity: Ember.computed('model.opportunities.length', function () {
      return this.get('model.opportunities.firstObject');
    }),
    orders: Ember.computed('model.opportunities.@each.ordersLength', function () {
      return this.get('model.opportunities.firstObject.orders');
    }),
    states: [{ id: "AK", name: "Alaska" }, { id: "AL", name: "Alabama" }, { id: "AR", name: "Arkansas" }, { id: "AZ", name: "Arizona" }, { id: "CA", name: "California" }, { id: "CO", name: "Colorado" }, { id: "CT", name: "Connecticut" }, { id: "DC", name: "District of Columbia" }, { id: "DE", name: "Delaware" }, { id: "FL", name: "Florida" }, { id: "GA", name: "Georgia" }, { id: "HI", name: "Hawaii" }, { id: "IA", name: "Iowa" }, { id: "ID", name: "Idaho" }, { id: "IL", name: "Illinois" }, { id: "IN", name: "Indiana" }, { id: "KS", name: "Kansas" }, { id: "KY", name: "Kentucky" }, { id: "LA", name: "Louisiana" }, { id: "MA", name: "Massachusetts" }, { id: "MD", name: "Maryland" }, { id: "ME", name: "Maine" }, { id: "MI", name: "Michigan" }, { id: "MN", name: "Minnesota" }, { id: "MO", name: "Missouri" }, { id: "MS", name: "Mississippi" }, { id: "MT", name: "Montana" }, { id: "NC", name: "North Carolina" }, { id: "ND", name: "North Dakota" }, { id: "NE", name: "Nebraska" }, { id: "NH", name: "New Hampshire" }, { id: "NJ", name: "New Jersey" }, { id: "NM", name: "New Mexico" }, { id: "NV", name: "Nevada" }, { id: "NY", name: "New York" }, { id: "OH", name: "Ohio" }, { id: "OK", name: "Oklahoma" }, { id: "OR", name: "Oregon" }, { id: "PA", name: "Pennsylvania" }, { id: "RI", name: "Rhode Island" }, { id: "SC", name: "South Carolina" }, { id: "SD", name: "South Dakota" }, { id: "TN", name: "Tennessee" }, { id: "TX", name: "Texas" }, { id: "UT", name: "Utah" }, { id: "VA", name: "Virginia" }, { id: "VT", name: "Vermont" }, { id: "WA", name: "Washington" }, { id: "WI", name: "Wisconsin" }, { id: "WV", name: "West Virginia" }, { id: "WY", name: "Wyoming" }],
    countries: [{ id: 'us', name: 'US' }, { id: 'ca', name: 'Canada' }],
    statuses: [{ id: "active", name: "Active" }, { id: "inactive", name: "Inactive" }],
    contactEmailKindOptions: Ember.computed('model.leadType', function () {
      let kind = this.get('model.leadType');
      let map = { 'b2b': 'btob', 'b2c': 'btoc' };
      return this.get('contactOptions')[map[kind]].email;
    }),
    //feedback properties
    newEmailPlaceholder: Ember.computed('emailsCount', function () {
      return this.get('emailsCount') > 0 ? 'Add Email' : 'Email';
    }),
    newPhonePlaceholder: Ember.computed('phonesCount', function () {
      return this.get('phonesCount') > 0 ? 'Add Phone' : 'Phone';
    }),
    newAddressPlaceholder: Ember.computed('addressesCount', function () {
      return this.get('addressesCount') > 0 ? 'Add Address' : 'Enter Address';
    }),
    emailFeedbackClass: Ember.computed('email', 'phonesCount', function () {
      var classes;

      if (Ember.isPresent(this.get('email'))) {
        this.set('emailDirty', true);
      }
      if (this.get('emailDirty')) {
        if (Ember.isEmpty(this.get('validations.attrs.email.errors'))) {
          if (Ember.isPresent(this.get('email'))) {
            classes = 'glyphicon-ok-sign text-success';
          } else {
            classes = 'hide';
          }
        } else {
          if (Ember.isBlank(this.get('email'))) {
            classes = 'glyphicon-asterisk text-danger sup';
          } else {
            classes = 'glyphicon-remove text-danger';
          }
        }
      } else {
        if (this.get('validations.attrs.email.isRequired')) {
          // if required
          classes = 'glyphicon-asterisk text-danger sup';
        } else {
          classes = 'hide';
        }
      }

      return classes;
    }),
    phoneFeedbackClass: Ember.computed('phone', 'emailsCount', function () {
      var classes;

      if (Ember.isPresent(this.get('phone'))) {
        this.set('phoneDirty', true);
      }
      if (this.get('phoneDirty')) {
        if (Ember.isEmpty(this.get('validations.attrs.phone.errors'))) {
          if (Ember.isPresent(this.get('phone'))) {
            classes = 'glyphicon-ok-sign text-success';
          } else {
            classes = 'hide';
          }
        } else {
          if (Ember.isBlank(this.get('phone'))) {
            classes = 'glyphicon-asterisk text-danger sup';
          } else {
            classes = 'glyphicon-remove text-danger';
          }
        }
      } else {
        if (this.get('validations.attrs.phone.isRequired')) {
          // if required
          classes = 'glyphicon-asterisk text-danger sup';
        } else {
          classes = 'hide';
        }
      }
      return classes;
    })
  });

  exports.default = LeadFormMixin;
});