define('knowme-client/components/voice-recorder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    URL,
    Blob,
    Promise,
    navigator,
    FileReader,
    AudioContext
  } = window;

  const crypto = {
    createURL(blob) {
      return URL.createObjectURL(blob);
    },
    fromBlob(blob, type = 'text') {
      let func = type === 'data' ? 'readAsDataURL' : 'readAsText';

      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader[func](blob);
        reader.onerror = error => reject(error);
        reader.onload = () => resolve(reader.result);
      });
    },
    toBlob(dataURI) {
      let { buffer, mimeString } = this.toArrayBuffer(dataURI);

      // write the ArrayBuffer to a blob, and you're done
      return new Blob([buffer], { type: mimeString });
    }
  };

  exports.default = Ember.Component.extend({
    classNames: ['voice-recorder'],
    activeStorage: Ember.inject.service(),
    timer: 0,
    gumStream: null,
    rec: null,
    recordingTime: 60000 * 3,
    isSendPressed: false,
    isRecording: false,
    isPaused: false,
    isStoped: false,
    recordProgress: 0,
    timetInterval: null,
    autoStopLater: null,
    hifi: Ember.inject.service(),
    didReceiveAttrs() {
      this._super(...arguments);

      if (!this.get('isRecording')) {
        this.startRecording();
      }
    },
    willDestroyElement: function () {
      this._super(...arguments);
      this.send('cancelRecording');
    },
    startRecording: function () {
      this.set('isRecording', true);
      this.set('isStoped', false);
      let constraints = {
        audio: true,
        video: false
      };
      let input;
      let AudioContext = window.AudioContext || window.webkitAudioContext;
      let audioContext = null;
      let that = this;
      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        that.set('gumStream', stream);
        audioContext = new AudioContext();
        input = audioContext.createMediaStreamSource(stream);
        let rec = new Recorder(input, {
          numChannels: 1
        });
        that.set('rec', rec);
        rec.record();
        console.log("Recording started");
        let timetInterval = setInterval(function () {
          if (!that.get('isPaused')) {
            that.set('timer', that.get('timer') + 1);
            let recordProgress = that.get('timer') * 1000 / that.get('recordingTime') * 100;
            that.set('recordProgress', recordProgress);
          }
        }, 1000);
        that.set('timetInterval', timetInterval);
        that.scheduleStopRecording(that.get('recordingTime'));
      }).catch(function (err) {
        console.log(err);
      });
    },
    scheduleStopRecording: function (ms) {
      let that = this;
      let autoStopLater = Ember.run.later(function () {
        that.set('timer', that.get('recordingTime') / 1000);
        that.set('recordProgress', 100);
        that.send('stopRecording');
      }, ms);
      that.set('autoStopLater', autoStopLater);
    },
    onSendMessage: Ember.observer('isSendPressed', function () {
      if (this.get('isSendPressed')) {
        this.sendAction('afterRecord', this.get('blob'));
      }
    }),
    actions: {
      playRecording: function () {
        this.get('hifi').play(this.get('audioURL')).then(({ sound }) => {
          // sound object

        }).catch(error => {});
      },
      pauseRecording: function () {
        console.log("pause/resume");
        if (this.get('isPaused')) {
          this.set('isPaused', false);
          this.get('rec').record();
          this.scheduleStopRecording(this.get('recordingTime') - this.get('timer') * 1000);
        } else {
          this.set('isPaused', true);
          this.get('rec').stop();
          Ember.run.cancel(this.get('autoStopLater'));
        }
      },
      stopRecording: function () {
        console.log("Recording stoped");
        this.set('isStoped', true);
        this.set('recordProgress', 100);
        Ember.run.cancel(this.get('autoStopLater'));

        this.get('rec').stop();
        this.get('gumStream').getAudioTracks()[0].stop();
        clearInterval(this.get('timetInterval'));
        this.get('rec').exportWAV(blob => {
          let audioURL = crypto.createURL(blob);
          this.set('audioURL', audioURL);
          this.set('blob', blob);
        });
      },
      cancelRecording: function () {
        console.log("close record toolbar");
        this.set('isStoped', true);
        Ember.run.cancel(this.get('autoStopLater'));

        this.get('rec').stop();
        this.get('gumStream').getAudioTracks()[0].stop();
        clearInterval(this.get('timetInterval'));

        this.set('audioURL', null);
        this.set('blob', null);

        this.sendAction('cancelRecording');
      }
    }
  });
});