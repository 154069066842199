define('knowme-client/routes/promo-codes/edit', ['exports', 'moment', 'knowme-client/mixins/routes/account-setup'], function (exports, _moment, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    store: Ember.inject.service(),
    model: function (params) {
      return this.get('store').find('promo-code', params.id);
    },
    afterModel: function (model) {
      let now = (0, _moment.default)();
      if (!model.get('published') && now.isBefore(model.get('endDate'))) {
        model.set('startDate', (0, _moment.default)().toDate());
      }
    }
  });
});