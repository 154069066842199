define('knowme-client/components/campaign/resources-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    adsTotal: Ember.computed('model.length', 'model.@each.adsLength', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return parseFloat(previousValue) + parseFloat(currentItem.get('ads.length'));
      }, 0);
    }),
    pipelineTotal: Ember.computed('model.@each.pipeline', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return parseFloat(previousValue) + parseFloat(currentItem.get('pipeline'));
      }, 0);
    }),
    revenueTotal: Ember.computed('model.@each.revenue', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return parseFloat(previousValue) + parseFloat(currentItem.get('revenue'));
      }, 0);
    }),
    leadsTotal: Ember.computed('model.@each.leadsCount', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return parseInt(previousValue) + parseInt(currentItem.get('leadsCount'));
      }, 0);
    }),
    customersTotal: Ember.computed('model.@each.customersCount', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return parseInt(previousValue) + parseInt(currentItem.get('customersCount'));
      }, 0);
    })
  });
});