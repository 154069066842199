define('knowme-client/serializers/notification', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      notifiable: { deserialize: 'id', serialize: false }
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.relationships = hash.relationships || {};
      if (hash.attributes.action === 'assigned') {
        hash.relationships['notifiable'] = hash.relationships['notifiable'] || {};
        hash.relationships['notifiable'].links = hash.relationships.links || {};
        if (Ember.isPresent(hash.relationships['notifiable'].data)) {
          hash.relationships['notifiable'].links.related = Ember.getOwner(this).lookup('adapter:opportunity').buildURL('opportunity', hash.relationships['notifiable'].data.id, null, 'findRecord', null);
        }
      }

      return this._super.apply(this, arguments);
    }
  });
});