define('knowme-client/mirage/factories/account', ['exports', 'faker', 'ember-cli-mirage', 'knowme-client/mirage/helpers/seeds'], function (exports, _faker, _emberCliMirage, _seeds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    customerType: function () {
      return _faker.default.random.arrayElement(['b2b', 'b2c']);
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    customerTypeConfirmed: true,
    agreementAcceptedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    name: _faker.default.company.catchPhrase,
    contactFirstName: _faker.default.name.firstName,
    contactLastName: _faker.default.name.lastName,
    contactTitle: _faker.default.company.catchPhrase,
    contactEmail: _faker.default.internet.email,
    phoneNumber: function () {
      return _faker.default.phone.phoneNumberFormat(0).replace(/-/g, '');
    },
    addressMainStreet: _faker.default.address.streetAddress,
    addressCity: _faker.default.address.cityName,
    addressState: _faker.default.address.state,
    addressZip: _faker.default.address.zipCode,
    addressCountry: _faker.default.address.country,
    timeZone: _faker.default.address.timeZone,
    industry: function () {
      return 'general';
    },
    billingType: function () {
      return 'automatic';
    },
    status: function () {
      return 'active';
    },
    activeSettings: function () {
      return { users: 3 };
    },
    afterCreate(account, server) {
      if (Ember.isBlank(account.subscription)) {
        let plan = server.schema.plans.first();
        server.create('subscription', { account: account, plan: plan });
      }
      (0, _seeds.prepareRoles)(server);

      (0, _seeds.prepareColumnSettings)(server);
      // sales stages
      (0, _seeds.prepareSalesStages)(server);
      // next actions
      (0, _seeds.prepareNextActions)(server);
      //opportunity fields
      (0, _seeds.prepareOpportunityFields)(server);
      //dashboardWidgets
      (0, _seeds.prepareDashboardWidgets)(server);
      (0, _seeds.prepareAccountSettinngs)(server, account);
      account.activeSettings = {
        'billing-term': "annually",
        'call-touch-points-dids-local-limit': 12,
        'call-touch-points-dids-toll-free-limit': 4,
        'call-touch-points-multi-ring-forward-to-limit': 3,
        'call-touch-points-multi-ring-limit': 1,
        'contacts-additional': 0,
        'contacts-block': 1000,
        'contacts-block-fee': "15.0",
        'contacts-limit': 14000,
        'dids-additional-fee': "5.0",
        'minutes-additional': 200,
        'minutes-block': 200,
        'minutes-block-fee': "14.0",
        'minutes-limit': 1600,
        'plan-fee': "69.0",
        'short-messages-additional': 800,
        'short-messages-block': 200,
        'short-messages-block-fee': "4.0",
        'short-messages-limit': 3400,
        'storage-additional': 0,
        'storage-block': null,
        'storage-block-fee': null,
        'storage-limit': 0,
        'tax-rate': "8.25",
        'users-additional-fee': "65.0",
        'users-count': 5,
        'users-limit': 6,
        'users-dids-local-limit': 7
      };

      server.createList('user', 2, 'admin', { account: account });
      server.createList('user', 2, 'master_manager', { account: account });
      server.createList('user', 2, 'manager', { account: account });
      server.createList('user', 2, 'sales_person', { account: account });

      let campaign = server.create('campaign', { account: account });
      let defaultCallerIdsAd = server.create('ad', { campaign: campaign, name: 'Caller IDs' });
      let defaultManualAd = server.create('ad', { campaign: campaign, name: 'Manual' });

      account.defaultCallerIdsAdId = defaultCallerIdsAd.id;
      account.defaultManualAdId = defaultManualAd.id;
      account.update('defaultCallerIdsAd', defaultCallerIdsAd);
      account.update('defaultManualAd', defaultManualAd);
    },
    starter: (0, _emberCliMirage.trait)({
      afterCreate(account, server) {
        var plan = server.create('plan', 'starter');
        server.create('subscription', { account: account, plan: plan });
      }
    }),
    starter_unlimited: (0, _emberCliMirage.trait)({
      afterCreate(account, server) {
        var plan = server.create('plan', 'starter_unlimited');
        server.create('subscription', { account: account, plan: plan });
      }
    }),
    premium: (0, _emberCliMirage.trait)({
      afterCreate(account, server) {
        var plan = server.create('plan', 'premium');
        server.create('subscription', { account: account, plan: plan });
        account.users.models.forEach(function (user) {
          user.update('isKnowmeIqSupport', true);
          if (account.customerTypeConfirmed) {
            let did = server.create('did');
            let didAllocation = server.create('did-allocation', { did: did, user: user, allocatable: user });
            // user.update({didAllocation: didAllocation});
            // did.update({didAllocation: didAllocation});
          }
        });
        server.createList('did', 8);
      }
    }),
    premium_unlimited: (0, _emberCliMirage.trait)({
      afterCreate(account, server) {
        var plan = server.create('plan', 'premium_unlimited');
        server.create('subscription', { account: account, plan: plan });
        account.users.models.forEach(function (user) {
          user.update('isKnowmeIqSupport', true);
          if (account.customerTypeConfirmed) {
            let did = server.create('did');
            server.create('did-allocation', { did: did, user: user, allocatable: user });
          }
        });
        server.createList('did', 8);
      }
    })
  });
});