define('knowme-client/helpers/read-path', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var readPath, ReadPathHelper;

  exports.readPath = readPath = function (params) {
    let object = params[0],
        path = params[1];
    if (Ember.isBlank(object)) {
      return null;
    }
    return Ember.get(object, path);
  };

  ReadPathHelper = Ember.Helper.helper(readPath);

  exports.readPath = readPath;
  exports.default = ReadPathHelper;
});