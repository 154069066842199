define('knowme-client/serializers/json-api', ['exports', 'ember-data/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    serialize: function () /*snapshot, options*/{
      let modelName = this._debugContainerKey.split(':')[1];
      let serializer = this.store.serializerFor(modelName + '-json');
      if (Ember.isBlank(serializer)) {
        let serializer = this.store.serializerFor('json');
      }
      return serializer.serialize(...arguments);
    },
    normalizeArrayResponse() /*store, primaryModelClass, payload, id, requestType*/{
      let normalizedDocument = this._super(...arguments);

      if (Ember.isPresent(normalizedDocument.meta)) {
        this.camelizeKeys(normalizedDocument.meta);
      }

      return normalizedDocument;
    },
    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      let existing = store.peekRecord(primaryModelClass.modelName, payload.data.id);
      if (existing) {
        store.unloadRecord(existing);
      }
      return this._super(...arguments);
    },
    extractRelationship() /*relationshipHash*/{
      let normalizedRelationship = this._super(...arguments);

      if (Ember.isPresent(normalizedRelationship.meta)) {
        this.camelizeKeys(normalizedRelationship.meta);
      }

      return normalizedRelationship;
    },
    camelizeKeys(object) {
      Object.keys(object).map(key => {
        let value = object[key];
        if (Ember.typeOf(value) === 'object') {
          this.camelizeKeys(value);
        }
        delete object[key];
        Ember.set(object, Ember.String.camelize(key), value);
      });
    }
  });
});