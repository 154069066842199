define('knowme-client/components/experience-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    improved: null,
    didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    value: Ember.computed('improved', function () {
      if (Ember.isPresent(this.get('improved'))) {
        return this.get('improved') ? 'Improved' : 'Regressed';
      } else {
        return 'Unchanged';
      }
    })
  });
});