define('knowme-client/mirage/factories/room', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    title: function () {
      return _faker.default.random.arrayElement(['text', 'call', 'assign']);
    },
    kind: function () {
      return _faker.default.random.arrayElement(['internal', 'contact']);
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    }
  });
});