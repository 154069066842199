define('knowme-client/controllers/email-templates', ['exports', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/queryable', 'knowme-client/mixins/controllers/status'], function (exports, _pagination, _queryable, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_status.default, _queryable.default, _pagination.default, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    currentAuthentication: Ember.computed.alias('session.currentUser.authentications.firstObject'),
    queryParams: ['status', 'sort_by', 'order', 'filter'],
    status: 'active',
    sort_by: 'created_at',
    order: 'desc',
    filterOptions: [{ value: 'my-templates', text: 'My Templates' }, { value: 'all-templates', text: 'All Templates' }],
    statusValues: ['active', 'inactive'],
    filter: 'all-templates',
    actions: {
      sort: function (toSortBy) {
        var sortBy = this.get('sort_by') || 'name';
        var order = this.get('order');
        if (sortBy === toSortBy) {
          order = order === 'asc' ? 'desc' : 'asc';
        } else {
          sortBy = toSortBy;
          order = 'desc';
        }
        this.set('sort_by', sortBy);
        this.set('order', order);
        this.send('setOrder', sortBy, order);
      },
      updateTemplate: function (emailTemplate) {
        emailTemplate.set('contentWidth', Ember.$('.knowme-editor .ck-content').width());
        emailTemplate.save().then(function () {
          this.send('reloadModel');
        }.bind(this));
      },
      removeTemplate: function (emailTemplate) {
        emailTemplate.destroyRecord().then(function () {
          this.transitionToRoute('email-templates');
          this.get('target._routerMicrolib').refresh();
        }.bind(this));
      }
    }
  });
});