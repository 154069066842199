define('knowme-client/controllers/account/settings/stages', ['exports', 'knowme-client/mixins/controllers/status', 'knowme-client/mixins/controllers/pagination'], function (exports, _status, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_status.default, _pagination.default, {
    sorting: ['position:asc'],
    sortedModel: Ember.computed.sort('model', 'sorting'),
    onStatusChanged: Ember.observer('status', function () {
      if (Ember.isPresent(this.get('page'))) {
        this.set('page', null);
      }
    }),
    selectedStages: Ember.computed.filter('model', function (resource) {
      return resource.get('funnelVisible');
    }),
    positionOptions: Ember.computed('selectedStages', 'selectedStages.length', function () {
      return Array.from({ length: this.get('selectedStages.length') }, (v, k) => k + 1);
    }),
    onFunnelChange: Ember.observer('model.@each.funnelVisible', function () {
      let length = this.get('model').filterBy('funnelVisible', true).get('length');
      this.set('positionOptions', Array.from({ length: length }, (v, k) => k + 1));
    }),
    actions: {
      saveStage: function (resource) {
        resource._internalModel.setId = function (id) {
          this._record.set('id', id);
        };
        resource.set('funnelVisible', !resource.get('funnelVisible'));
        resource.save().then(function () {
          this.send('reloadModel');
        }.bind(this));
      },
      updatePosition: function (resource) {
        resource._internalModel.setId = function (id) {
          this._record.set('id', id);
        };

        resource.save().then(function () {
          this.send('reloadModel');
        }.bind(this));
      },
      destroy: function (resource) {
        resource.destroyRecord();
        this.transitionToRoute('account.settings.stages');
        this.get('target._routerMicrolib').refresh();
      }
    }
  });
});