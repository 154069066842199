define('knowme-client/routes/short-messages', ['exports', 'lodash', 'moment', 'knowme-client/mixins/routes/account-setup', 'knowme-client/lib/poller'], function (exports, _lodash, _moment, _accountSetup, _poller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function formatDate(date) {
    return date.format('MM-DD-YYYY'); //FIXME: Rails expects DD-MM-YYYY instead of MM-DD-YY
  }

  exports.default = Ember.Route.extend(_accountSetup.default, {
    interval: 1000 * 30, // 30 secs
    poller: null,
    queryParams: {
      type: { refreshModel: true },
      filter: { refreshModel: true },
      satisfaction: { refreshModel: true },
      from_date: { refreshModel: true },
      to_date: { refreshModel: true },
      userId: { refreshModel: true, as: 'user_id' },
      contactId: { refreshModel: true, as: 'contact_id' },
      opportunityId: { refreshModel: true, as: 'opportunity_id' },
      adId: { refreshModel: true, as: 'ad_id' },
      query: { refreshModel: true }
    },
    pagination: {},
    beforeModel: function (transition) {
      return this._super.apply(this, arguments).then(function () {
        if (transition.queryParams.satisfaction === 'prompt') {
          return this.transitionTo({ queryParams: { satisfaction: null } });
        }
      }.bind(this));
    },
    model: function (params) {
      let promise;
      if (Ember.isPresent(params.opportunityId)) {
        promise = this.get('store').query('short-message', this.processParamsForShortMessages(params));
        // }else if(isPresent(params.contactId)) {
      } else {
        promise = this.get('store').query('contact', this.processParams(params));
      }

      promise.then(result => {
        this.set('pagination.total', result.meta.pagination.total);
        this.set('pagination.limit', result.meta.pagination.limit);
        this.set('pagination.page', result.meta.pagination.page);
        if (params.contactId) {
          this.set('selectedResource', result.get('firstObject'));
        } else {
          this.set('selectedResource', null);
        }
      });
      return promise;
    },
    afterModel: function (model, transition) {
      let options = {};
      options.filter = ['with_short_messages'];
      options.paginate = false;

      let adsOptions = { paginate: false, sort_by: 'name', order: 'asc', filter: 'with_short_messages' };
      if (transition.queryParams.type) {
        options.type = transition.queryParams.type;
      }

      let promises = {
        users: this.get('store').query('user', options),
        ads: this.get('store').query('ad', adsOptions)
      };

      if (Ember.isPresent(transition.queryParams.opportunity_id)) {
        promises['selectedOpportunity'] = this.get('store').findRecord('opportunity', transition.queryParams.opportunity_id);
      }
      if (Ember.isPresent(transition.queryParams.contact_id)) {
        promises['selectedContact'] = this.get('store').findRecord('contact', transition.queryParams.contact_id, { short_messages: this.processParamsForShortMessages(transition.queryParams) });
      } else {
        promises['selectedContact'] = null;
      }

      return Ember.RSVP.hash(promises).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);

      if (Ember.isPresent(params.contactId)) {
        params.ids = [params.contactId];
        delete params.contactId;
      }
      if (Ember.isPresent(params.filter) || Ember.isPresent(params.satisfaction) || Ember.isPresent(params.from_date) && Ember.isPresent(params.to_date) || Ember.isPresent(params.adId) || Ember.isPresent(params.userId) || Ember.isPresent(params.query)) {
        params.short_messages = {};
      }

      params.short_messages = this.processParamsForShortMessages(params);

      params.filter = 'with_short_messages';

      let dateParams = this.processDatesParams({ from_date: params.from_date, to_date: params.to_date });

      if (Ember.isPresent(dateParams.from_date) && Ember.isPresent(dateParams.to_date)) {
        params.short_messages.from_date = dateParams.from_date;
        params.short_messages.to_date = dateParams.to_date;
        delete dateParams.from_date;
        delete dateParams.to_date;
      }

      return params;
    },
    processParamsForShortMessages(_params) {
      let params = { contact_id: _params.contactId, opportunity_id: _params.opportunityId, paginate: false };
      if (Ember.isPresent(_params.satisfaction) && _params.satisfaction !== 'prompt') {
        params.satisfaction = _params.satisfaction;
        delete _params.satisfaction;
      }
      if (Ember.isPresent(_params.query)) {
        params.query = _params.query;
        delete _params.query;
      }
      if (Ember.isPresent(_params.filter)) {
        params.filter = _params.filter;
        delete _params.filter;
      }
      if (Ember.isPresent(_params.userId)) {
        params.user_id = _params.userId;
        delete _params.userId;
      }
      if (Ember.isPresent(_params.adId)) {
        params.ad_id = _params.adId;
        delete _params.adId;
      }
      Ember.merge(params, this.processDatesParams({ from_date: _params.from_date, to_date: _params.to_date }));
      return params;
    },
    processDatesParams(params) {
      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('selectedResource', this.get('selectedResource'));
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
      if (localStorage['textId']) {
        this.get('store').findRecord('short-message', localStorage['textId']).then(text => {
          localStorage.removeItem('textId');
          controller.set('selectedMessage', text);
          Ember.run.later(this, function () {
            controller.set('selectedMessage', null);
          }, 7000);
        });
      }
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});

      let route = this;
      let poller = new _poller.default();
      poller.setInterval(this.get('interval'));
      poller.start(this, function () {
        route.refresh();
      });

      this.set('poller', poller);
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.get('poller').stop();
      }
    },
    actions: {
      willTransition: function () {
        this.render('loading');
      },
      pauseRefreshing: function () {
        this.set('pausePoller', true);
      },
      continueRefreshing: function () {
        this.set('pausePoller', false);
      },
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      },
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      refreshModel: function () {
        this.refresh();
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });
});