define('knowme-client/serializers/contract-agreement', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      account: { serialize: false, deserialize: 'records' },
      user: { serialize: false, deserialize: 'records' },
      contractVersion: { serialize: 'id', deserialize: 'records' }
    },
    normalize(typeClass, hash /*, prop*/) {
      let original_hash = Ember.copy(hash);
      hash = this._super.apply(this, arguments);
      hash.data.relationships = hash.data.relationships || {};
      hash.data.relationships.user = hash.data.relationships.user || {};
      hash.data.relationships.user.links = hash.data.relationships.user.links || {};
      hash.data.relationships.user.links.related = Ember.getOwner(this).lookup('adapter:user').buildURL('user', original_hash.user_id, null, 'findRecord', null);
      return hash;
    }
  });
});