define('knowme-client/mirage/serializers/active-model-serializer', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.ActiveModelSerializer.extend({
		root: false,
		embed: true, // Mirage: You cannot have a serializer that sideloads (embed: false) and disables the root (root: false)
		serializeIds: 'always'
	});
});