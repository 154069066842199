define("knowme-client/models/call-touch-point", ["exports", "ember-data/relationships", "ember-data/attr", "knowme-client/models/allocatable", "knowme-client/mixins/models/validations/call-touch-point", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty"], function (exports, _relationships, _attr, _allocatable, _callTouchPoint, _emberCpValidationsCustomProperties, _dirty) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _allocatable.default.extend(_callTouchPoint.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    ad: (0, _relationships.belongsTo)("ad", { async: true }),
    forwardTo1: (0, _attr.default)(),
    forwardTo2: (0, _attr.default)(),
    forwardTo3: (0, _attr.default)(),
    forwardTo4: (0, _attr.default)(),
    extension: (0, _attr.default)("string", { defaultValue: "" }),
    anonymity: (0, _attr.default)("boolean", { defaultValue: false }),
    transcriptionEnabled: (0, _attr.default)("boolean", { defaultValue: false }),
    hasMultiForward: Ember.computed("forwardTo2", function () {
      return Ember.isPresent(this.get("forwardTo2"));
    }),
    formattedForwardTo: Ember.computed("forwardTo1", "forwardTo2", "forwardTo3", "forwardTo4", function () {
      return [this.get("forwardTo1"), this.get("forwardTo2"), this.get("forwardTo3"), this.get("forwardTo4")].compact().filter(function (e) {
        return e;
      }).map(function (number) {
        number = number || "";
        if (number.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
          return number.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
        } else {
          return number;
        }
      }).join(", ");
    })
  });
});