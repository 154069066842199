define('knowme-client/models/custom-attribute', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _relationships, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomAttribute;

  CustomAttribute = _model.default.extend(_dirty.default, {
    // Relationships
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
    customizableResource: (0, _relationships.belongsTo)('customizable-resource', { async: true, polymorphic: true, inverse: 'customAttributes' }),
    // Attributes
    displayName: (0, _attr.default)(),
    name: (0, _attr.default)(),
    value: (0, _attr.default)()
    // position: attr('number'),
  });

  exports.default = CustomAttribute;
});