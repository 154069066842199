define('knowme-client/helpers/parse-webform-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseWebformString = parseWebformString;
  function parseWebformString(params) {
    var obj = {};
    var property = [];
    var key,
        value,
        subitems = '';
    var items = params[0].split(',');
    items.map(function (item) {
      property = item.split(':');
      key = property[0].trim();
      value = property[1].trim();
      if (value.includes(';')) {
        obj[key] = value.split(';');
      } else {
        obj[key] = value;
      }
    });
    return obj;
  }

  exports.default = Ember.Helper.helper(parseWebformString);
});