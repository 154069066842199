define('knowme-client/mixins/models/validations/user', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    lastName: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    email: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('format', { type: 'email', regex: /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i, allowBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
    outboundCallPhoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      dependentKeys: ['model.isKnowmeIqRequired'],
      disabled: Ember.computed.not('model.isKnowmeIqRequired')
    }), (0, _emberCpValidations.validator)('length', { is: 12, allowBlank: true, message: "This field is invalid." }), (0, _emberCpValidations.validator)('exclusion', { in: ['0000000000'], message: 'This value is not allowed.' })],
    mobilePhoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      dependentKeys: ['model.isKnowmeIqRequired'],
      disabled: Ember.computed.not('model.isKnowmeIqRequired')
    }), (0, _emberCpValidations.validator)('length', { is: 12, allowBlank: true, message: "This field is invalid." }), (0, _emberCpValidations.validator)('exclusion', { in: ['0000000000'], message: 'This value is not allowed.' })],
    didAllocation: (0, _emberCpValidations.validator)('belongs-to', {
      dependentKeys: ['model.isKnowmeIqRequired'],
      disabled: Ember.computed.not('model.isKnowmeIqRequired')
    }),
    password: (0, _emberCpValidations.validator)('length', { min: 8, allowBlank: true }),
    passwordConfirmation: [(0, _emberCpValidations.validator)('length', { min: 8, allowBlank: true }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
      let result = true;
      let password = model.get('password');
      if (Ember.isPresent(password) && Ember.isBlank(value)) {
        result = 'This field can\'t be blank';
      } else if (password !== value) {
        result = 'Your password and confirmation password do not match';
      }
      return result;
    }, {
      dependentKeys: ['model.password']
    })]
  });
});