define('knowme-client/mirage/factories/customer', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: _faker.default.lorem.sentence,
    createdAt: function () {
      return new Date();
    },
    updatedAt: function () {
      return new Date();
    }
  });
});