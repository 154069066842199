define('knowme-client/serializers/contact', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ContactSerializer;

  ContactSerializer = _application.default.extend({
    attrs: {
      lead: { serialize: false },
      callsCount: { serialize: false },
      shortMessagesCount: { serialize: false },
      calls: { embedded: 'always' },
      shortMessages: { embedded: 'always' },
      contactInfos: { embedded: 'always' },
      lastCall: { deserialize: 'id', serialize: false },
      lastShortMessage: { deserialize: 'id', serialize: false },
      lastCustomerExperience: { deserialize: 'id', serialize: false },
      incentivesContact: { deserialize: false, serialize: 'id' },
      customAttributes: { embedded: 'always' },
      firstName: 'fname',
      lastName: 'lname'
    },
    keyForRelationship(key, relationship, method) {
      let models = ['lastCall', 'lastShortMessage', 'lastCustomerExperience', 'incentivesContact'];
      if (models.includes(key) && method === 'deserialize') {
        return `${Ember.String.underscore(key)}_id`;
      }
      if (key === 'customAttributes' && method === 'serialize') {
        return `${Ember.String.underscore(key)}_attributes`;
      }
      return this._super(...arguments);
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.links = {
        calls: Ember.getOwner(this).lookup('adapter:call').buildURL('call', null, null, 'findMany', { contact_id: hash.id }),
        shortMessages: Ember.getOwner(this).lookup('adapter:short-message').buildURL('short-message', null, null, 'findMany', { contact_id: hash.id }),
        lead: Ember.getOwner(this).lookup('adapter:lead').buildURL('lead', hash.lead_id, null, 'findRecord', null),
        incentivesContacts: Ember.getOwner(this).lookup('adapter:incentives-contact').buildURL('incentives-contact', null, null, 'findMany', { contact_id: hash.id, paginate: false }),
        reviewLinksContacts: Ember.getOwner(this).lookup('adapter:review-links-contact').buildURL('review-links-contact', null, null, 'findMany', { contact_id: hash.id, paginate: false })
      };

      if (Ember.isPresent(hash.last_call_id)) {
        hash.links.lastCall = Ember.getOwner(this).lookup('adapter:call').buildURL('call', hash.last_call_id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.last_short_message_id)) {
        hash.links.lastShortMessage = Ember.getOwner(this).lookup('adapter:short-message').buildURL('short-message', hash.last_short_message_id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.last_customer_experience_id)) {
        hash.links.lastCustomerExperience = Ember.getOwner(this).lookup('adapter:customer-experience').buildURL('customer-experience', hash.last_customer_experience_id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.last_incentives_contact_id)) {
        hash.links.lastIncentivesContact = Ember.getOwner(this).lookup('adapter:incentives-contact').buildURL('incentives-contact', hash.last_incentives_contact_id, null, 'findRecord', null);
      }
      return this._super.apply(this, arguments);
    }
  });

  exports.default = ContactSerializer;
});