define('knowme-client/helpers/format-listeners', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var formatListeners, FormatListenersHelper;

  exports.formatListeners = formatListeners = function (params) {
    let listeners = params[0];
    let text = '';
    listeners.forEach(function (item, index) {
      if (index === 0) {
        text += item.get('name');
      } else {
        text += ', ' + item.get('name');
      }
    });
    return text;
  };

  FormatListenersHelper = Ember.Helper.helper(formatListeners);

  exports.formatListeners = formatListeners;
  exports.default = FormatListenersHelper;
});