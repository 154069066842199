define('knowme-client/controllers/account/users', ['exports', 'knowme-client/mixins/controllers/status', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/queryable'], function (exports, _status, _pagination, _queryable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_status.default, _pagination.default, _queryable.default, {
    features: Ember.inject.service(),
    queryParams: ['sort_by', 'order'],
    sort_by: null,
    order: null,
    defaultsQueryParams: {
      status: 'active',
      sort_by: 'last_login',
      order: 'desc'
    },
    onStatusChanged: Ember.observer('status', function () {
      if (Ember.isPresent(this.get('page'))) {
        this.set('page', null);
      }
    }),
    actions: {
      sort: function (toSortBy) {
        var sortBy = this.get('sort_by') || 'name';
        var order = this.get('order');
        if (sortBy === toSortBy) {
          order = order === 'asc' ? 'desc' : 'asc';
        } else {
          sortBy = toSortBy;
          order = 'desc';
        }
        this.set('sort_by', sortBy);
        this.set('order', order);
        this.send('setOrder', sortBy, order);
      }
    }
  });
});