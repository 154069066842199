define('knowme-client/controllers/promo-codes', ['exports', 'knowme-client/mixins/controllers/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ['plan_id', 'status'],
    plan_id: null,
    statusOptions: [{ value: 'active', text: 'Active' }, { value: 'inactive', text: 'Inactive' }],
    billingPeriodOptions: ['monthly_and_annual', 'monthly'],
    onPlanOrStatusChanged: Ember.observer('plan_id', 'status', function () {
      this.set('page', null);
    }),
    onStatusChanged: Ember.observer('status', function () {
      this.set('plan_id', null);
    }),
    actions: {
      togglePromoCode: function (plan) {
        let enablePromoCode = !plan.get('enablePromoCode');
        let plans = this.get('store').peekAll('plan').filterBy('productKey', plan.get('productKey')).forEach(function (x) {
          x.set('enablePromoCode', enablePromoCode);
          x.save();
        });
      },
      updateBillingPeriod: function (resource) {
        resource.save();
      }
    }
  });
});