define('knowme-client/components/bs-popover', ['exports', 'ember-bootstrap/components/bs-popover'], function (exports, _bsPopover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bsPopover.default.extend({
    // bs-contextual uses willRemoveElement
    willDestroyElement() {
      this._super(...arguments);
      this.removeListeners();
    }
  });
});