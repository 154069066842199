define('knowme-client/controllers/account/settings/appointment-reminders', ['exports', 'moment', 'knowme-client/mixins/controllers/components/date-filter', 'knowme-client/mixins/controllers/pagination'], function (exports, _moment, _dateFilter, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _pagination.default, {
    session: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    queryParams: ['user_id', 'delivery_status', 'reminder_type'],
    pastDateFilters: ['today', 'todayAndYesterday', 'todayAndPrevious2Days', 'todayAndPrevious6Days', 'todayAndPrevious29Days', 'previousMonth', 'yearToDate', 'previousYear'],
    futureDateFilters: ['today', 'todayAndNext6Days', 'todayAndNext29Days'],
    deliveryStatusOptions: [{ id: 'texted', name: 'Texted' }, { id: 'emailed', name: 'E-mailed' }, { id: 'pending_text', name: 'Pending Text' }, { id: 'pending_email', name: 'Pending E-mail' }],
    reminderTypes: [{ id: 'appointment', name: 'Appointments' }, { id: 'reminder', name: 'My To Do' }],
    sentAppointmentsCount: 0,
    sentToDosCount: 0,
    user_id: null,
    delivery_status: null,
    reminder_type: null,
    isLoaded: false,
    dateFilters: Ember.computed('delivery_status', function () {
      if (['emailed', 'texted'].includes(this.get('delivery_status'))) {
        return this.get('pastDateFilters');
      } else {
        return this.get('futureDateFilters');
      }
    }),
    modelOpportunitiesTotals: Ember.computed('model.@each.appointmentOpportunitiesCount', function () {
      return this.get('model').mapBy('appointmentOpportunitiesCount');
    }),
    modelOpportunitiesTotal: Ember.computed.sum('modelOpportunitiesTotals'),
    modelRemindersTotals: Ember.computed('model.@each.appointmentOpportunitiesCount', function () {
      return this.get('model').mapBy('sentReminders.length');
    }),
    calcSentReminders: function () {
      this.set('sentAppointmentsCount', 0);
      this.set('sentToDosCount', 0);
      this.get('model').forEach(function (user) {
        user.hasMany('sentReminders').reload().then(function (sentReminders) {
          sentReminders.forEach(function (sentReminder) {
            if (sentReminder.get('reminderType') === 'appointment') {
              this.set('sentAppointmentsCount', this.get('sentAppointmentsCount') + 1);
            } else {
              this.set('sentToDosCount', this.get('sentToDosCount') + 1);
            }
          }.bind(this));
        }.bind(this));
      }.bind(this));
    },
    appointments: Ember.computed.filterBy('model', 'reminderType', 'appointment'),
    todos: Ember.computed.filterBy('model', 'reminderType', 'reminder'),
    appointmentsCount: Ember.computed.alias('appointments.length'),
    todosCount: Ember.computed.alias('todos.length'),
    modelRemindersTotal: Ember.computed.sum('modelRemindersTotals'),
    isDeliveredStatus: Ember.computed('delivery_status', function () {
      return Ember.isPresent(this.get('delivery_status')) && ['emailed', 'texted'].includes(this.get('delivery_status'));
    }),
    resetDateFilter: Ember.observer('isDeliveredStatus', function () {
      this.set('isLoaded', false);
      if (Ember.isPresent(this.get('from_date')) && Ember.isPresent(this.get('to_date')) && this.get('from_date') !== this.get('to_date')) {
        let now = (0, _moment.default)();
        if (this.get('isDeliveredStatus') && now.isBefore((0, _moment.default)(this.get('to_date')))) {
          this.setProperties({
            from_date: null,
            to_date: null
          });
        }

        if (!this.get('isDeliveredStatus') && now.isAfter((0, _moment.default)(this.get('from_date')))) {
          this.setProperties({
            from_date: null,
            to_date: null
          });
        }
      }
    }),
    actions: {
      toggleUserOpportunities: function (reminder) {
        reminder.set('opportunitiesDisplayed', !reminder.get('opportunitiesDisplayed'));
      },
      destroy(reminder) {
        reminder.destroyRecord().then(function () {
          this.transitionToRoute('account.settings.appointment-reminders');
          this.get('target._routerMicrolib').refresh();
        }.bind(this));
      }
    }
  });
});