define('knowme-client/adapters/report', ['exports', 'knowme-client/adapters/application', 'ember-inflector'], function (exports, _application, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,format,from_date,to_date,campaign_id,report_type}',
    pathForType: function (type) {
      if (Ember.isNone(type)) {
        type = '';
      }
      if (type.indexOf('/') > -1) {
        return (0, _emberInflector.pluralize)(type.split('/')[0]) + '/' + type.split('/')[1] + '/' + type.split('/')[2];
      }
      return (0, _emberInflector.pluralize)(type).underscore();
    },
    ajaxOptions: function ajaxOptions() /*url, type, options*/{
      var hash = this._super(...arguments);
      hash.dataType = 'text';
      hash.contentType = false;
      return hash;
    },
    handleResponse: function (status, headers, payload /*, requestData*/) {
      return payload;
    }
  });
});