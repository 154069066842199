define('knowme-client/components/drag-sort-item', ['exports', 'ember-drag-sort/components/drag-sort-item'], function (exports, _dragSortItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dragSortItem.default;
    }
  });
});