define('knowme-client/mirage/models/did-allocation', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    did: (0, _emberCliMirage.belongsTo)(),
    user: (0, _emberCliMirage.belongsTo)(),
    allocatable: (0, _emberCliMirage.belongsTo)()
  });
});