define('knowme-client/adapters/form-touch-point', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?query*,view_as,paginate}',
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      paginate: function (type, id, snapshot, query) {
        if (Ember.isArray(id) || Ember.isPresent(query.contact_id) || Ember.isPresent(query.user_id) || Ember.isPresent(query.did_id)) {
          return false;
        }
        return true;
      }
    }
  });
});