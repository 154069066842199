define('knowme-client/lib/query-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    isQueryParams: true,
    values: null
  });
});