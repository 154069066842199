define('knowme-client/components/opportunity-fields/custom-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attribute: Ember.computed('field', 'opportunity', function () {
      return this.get('opportunity.customAttributes').findBy('name', this.get('field.key'));
    }),
    displayQuestionMark: Ember.computed('attribute', function (opportunityField) {
      return Ember.isPresent(this.get('attribute.displayName').match(/^Custom\s\d+$/));
    })
  });
});