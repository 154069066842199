define('knowme-client/serializers/organization', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let OrganizationSerializer;

  OrganizationSerializer = _application.default.extend({
    attrs: {
      lead: { serialize: false },
      customAttributes: { embedded: 'always' }
    },
    keyForRelationship(key, relationship, method) {
      if (key === 'customAttributes' && method === 'serialize') {
        return `${Ember.String.underscore(key)}_attributes`;
      }
      return this._super(...arguments);
    }
  });

  exports.default = OrganizationSerializer;
});