define('knowme-client/controllers/account/settings/reviews/show', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    contactsLimit: 5,
    showAllSentTo: false,
    showAllClaimed: false,
    showAllNewContacts: false,
    reviewLinkOpenedViaEmail: Ember.computed.filter('reviewLinksContacts', function (rl) {
      return rl.get('opened');
    }),
    reviewLinkSentViaEmail: Ember.computed.filter('reviewLinksContacts', function (rl) {
      return rl.get('reviewLinksContactTargetType') === 'ContactEmail';
    }),
    reviewLinkSentViaMobile: Ember.computed.filter('reviewLinksContacts', function (rl) {
      return rl.get('reviewLinksContactTargetType') === 'ContactPhoneNumber';
    }),
    openedBy: Ember.computed.mapBy('reviewLinkOpenedViaEmail', 'contact'),
    emailTo: Ember.computed.mapBy('reviewLinkSentViaEmail', 'contact'),
    textedTo: Ember.computed.mapBy('reviewLinkSentViaMobile', 'contact')
  });
});