define('knowme-client/helpers/prevent-default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.preventDefault = preventDefault;
  function preventDefault([action]) {
    return function (event) {
      event.preventDefault();
      return action(event);
    };
  }

  exports.default = Ember.Helper.helper(preventDefault);
});