define('knowme-client/routes/leads/index', ['exports', 'knowme-client/mixins/routes/account-setup', 'knowme-client/mixins/routes/cable-setup', 'lodash', 'moment'], function (exports, _accountSetup, _cableSetup, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsIndexRoute;

  LeadsIndexRoute = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    session: Ember.inject.service('session'),
    features: Ember.inject.service('features'),
    channelName: 'LeadChannel',
    currentUser: null,
    beforeModel: function (transition) {
      return this._super(...arguments).then(function () {
        if (Ember.isPresent(transition.queryParams.filter) && ['pipeline_by_user', 'assigned_to'].includes(transition.queryParams.filter) && Ember.isBlank(transition.queryParams.user_id)) {
          this.transitionTo({ queryParams: { filter: null } });
        }
        if (Ember.isPresent(transition.queryParams.user_id) && !['pipeline_by_user', 'assigned_to'].includes(transition.queryParams.filter)) {
          this.transitionTo({ queryParams: { user_id: null } });
        }

        this.set('currentUser', this.get('session.currentUser'));
        this.set('subscriptionData', { id: this.get('session.currentUser.account.id') });

        return this.get('currentUser.didAllocation.did');
      }.bind(this));
    },
    pagination: {},
    resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        let queryParams = controller.get('queryParams');
        // If query params were defined as hash params they come through in an Object
        // that needs to be handled slightly differently (there is probably a better way to do this)
        if (queryParams.toString() === "[object Object]") {
          queryParams = Object.keys(queryParams[0]);
        }
        // loop through all the query params and set them to null on the controller
        queryParams.forEach(param => {
          controller.set(param, '');
        });
      }
    },
    model: function (params) {
      let route = this;
      let leadsPromise = route.get('store').query('lead', this.processParams(params));
      leadsPromise.then(result => {
        result.forEach(function (item) {
          item.set('opportunitiesDisplayed', true);
        });
        this.set('pagination.total', result.meta.pagination.total);
        this.set('pagination.limit', result.meta.pagination.limit);
        this.set('pagination.page', result.meta.pagination.page);
      });
      return leadsPromise;
    },
    filterToSortMapping: {
      //   high_priority: 'priority',
      assigned_by_sales_rep: 'sales_representative',
      number_of_opportunities: 'number_of_opportunities'
    },
    afterModel: function (model, transition) {
      this.set('skipSubscription', Ember.isPresent(transition.queryParams.lead_id));

      return Ember.RSVP.hash({
        columns: this.get('store').query('column-setting', { enable: true, target_name: transition.queryParams.converted === '1' ? 'customers' : 'leads' }),
        role: this.get('store').find('role', this.get('currentUser').get('roles.firstObject.id')),
        manualCaptureCampaigns: this.get('store').query('campaign', { paginate: false, sort_by: 'name', order: 'asc', ads: { capture_mode: 'manual' } })
      }).then(function (data) {
        this.set('currentRole', data.role);
        this.set('columns', data.columns);
        this.set('manualCaptureCampaigns', data.manualCaptureCampaigns);
        this.set('params', this.processParams(transition.queryParams));
      }.bind(this));
    },
    afterReceived: function (controller) {
      let params = {};
      // refresh meta data
      _lodash.default.each(controller.get('queryParams'), function (key) {
        params[key] = controller.get(key);
      });
      this.model(params).then(function (model) {
        controller.set('model', model);
      });
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);

      // let filterToSort = this.filterToSortMapping[params.filter];
      // if(isPresent(filterToSort)) {
      //   delete params.filter;
      // }

      if (Ember.isPresent(params.filter) && typeof params.filter === 'string') {
        params.filter = Ember.A(params.filter.split(','));
      } else {
        params.filter = Ember.A([]);
      }

      // default ordering by Filter
      if (params.filter.includes('past_due_action')) {
        params.sort_by = 'due_date';
        params.order = params.order || 'desc';
      } else if (params.filter.includes('action_required')) {
        params.sort_by = 'due_date';
        params.order = params.order || 'desc';
      } else if (params.filter.includes('priority')) {
        params.sort_by = 'priority';
        params.order = params.order || 'asc';
      } else if (params.sort_by === null) {
        params.sort_by = 'updated_at';
        params.order = params.order || 'desc';
      }

      if (params.converted === '1' && !params.filter.includes('all_leads_and_customers') && !params.filter.includes('customers_and_leads_pipeline') && !params.filter.includes('pipeline_by_user')) {
        params.filter.pushObject('customers');
      }

      if (params.converted !== '1' && !params.filter.includes('all_leads_and_customers') && !params.filter.includes('customers_and_leads_pipeline') && !params.filter.includes('pipeline_by_user') && Ember.isBlank(['acquired_today', 'acquired_ytd', 'converted_today', 'converted_ytd', 'converted'].filter(function (n) {
        return (params.filter || Ember.A([])).includes(n);
      }))) {
        params.filter.pushObject('leads');
      }

      if (params.filter.includes('pipeline_by_user')) {
        params.filter.removeObject('pipeline_by_user');
        params.filter.pushObject('pipeline_opportunity');
        params.filter.pushObject('assigned_to');
        params.filter.pushObject('all');
      }

      if (!params.filter.includes('all') && (params.filter.includes('all_leads_and_customers') || params.filter.includes('customers_and_leads_pipeline')) || Ember.isPresent(['converted_today', 'converted_ytd'].filter(function (n) {
        return (params.filter || Ember.A([])).includes(n);
      }))) {
        params.filter.pushObject('all');
        if (params.filter.includes('customers_and_leads_pipeline')) {
          params.filter.pushObject('pipeline_opportunity');
        }
      }

      delete params.converted;
      params.filter.removeObject('customers_and_leads_pipeline');
      params.filter.removeObject('all_leads_and_customers');

      // if(params.converted === '1') {
      //   if(isBlank(params.filter)) {
      //     params.filter = ['customers'];
      //   } else if(isArray(params.filter)) {
      //     params.filter.push('customers');
      //   }
      //   delete params.converted;
      // } else {
      //   if(isBlank(params.filter)) {
      //     params.filter = ['leads'];
      //   } else if(isArray(params.filter) && isBlank(['acquired_today', 'acquired_ytd', 'converted_today', 'converted_ytd', 'converted'].filter(function(n) {return (params.filter || []).contains(n);})) ) {
      //     params.filter.push('leads');
      //   } else if(isArray(params.filter) && isPresent(['converted_today', 'converted_ytd'].filter(function(n) {return (params.filter || []).contains(n);})) ) {
      //     params.filter.push('all');
      //   }
      // }

      if (Ember.isPresent(['past_due_action', 'action_required'].filter(function (n) {
        return (params.filter || []).includes(n);
      })) && (Ember.isPresent(params.from_date) || Ember.isPresent(params.to_date))) {
        params.field = 'due_date';
      }

      if (Ember.isPresent(['converted'].filter(function (n) {
        return (params.filter || []).includes(n);
      })) && (Ember.isPresent(params.from_date) || Ember.isPresent(params.to_date))) {
        params.field = 'conversion_date';
      }

      if (Ember.isPresent(['appointments'].filter(function (n) {
        return (params.filter || []).includes(n);
      })) && (Ember.isPresent(params.from_date) || Ember.isPresent(params.to_date))) {
        params.field = 'appointment_date';
      }

      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
      controller.set('columns', this.get('columns'));
      controller.set('manualCaptureCampaigns', this.get('manualCaptureCampaigns'));
      controller.set('params', this.get('params'));
      this.set('params', {});

      let adsOptions = { paginate: false, sort_by: 'name', order: 'asc' };
      if (Ember.isPresent(controller.get('campaign_id'))) {
        adsOptions.campaign_id = controller.get('campaign_id');
      }

      let users = [];
      if (this.get('currentUser').get('isSalesPerson')) {
        users = [this.get('currentUser')];
      } else if (this.get('currentUser').get('isManager')) {
        users = this.get('store').query('user', { exclude_admins: true, exclude_master_managers: true, paginate: false, status: 'active' });
      } else {
        users = this.get('store').query('user', { paginate: false, status: 'active' });
      }

      Ember.RSVP.hash({
        users: users,
        stages: this.get('store').query('sales_stage', { paginate: false }),
        campaigns: this.get('store').query('campaign', { paginate: false, sort_by: 'name', order: 'asc' }),
        ads: this.get('store').query('ad', adsOptions)
      }).then(function (response) {
        controller.setProperties(response);
      });
    },
    renderTemplate: function (controller, model) {
      return this.render('leads/index', {
        into: 'application'
      });
    },
    actions: {
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      },
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      queryParamsDidChange: function () {
        this.refresh();
      },
      willTransition: function (transition) {
        if (this.get('controller.converted') !== transition.queryParams.converted) {
          this.render('loading', { into: 'application' });
        }
      }
    }
  });

  exports.default = LeadsIndexRoute;
});