define('knowme-client/helpers/social-network-icon-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var socialNetworkIconClass, SocialNetworkIconClassHelper;

  exports.socialNetworkIconClass = socialNetworkIconClass = function (params) {
    var socialNetwork = params[0];
    var iconClass;
    switch (socialNetwork) {
      case 'facebook':
        iconClass = 'fa-facebook-square';
        break;
      case 'twitter':
        iconClass = 'fa-twitter-square';
        break;
      case 'linkedin':
        iconClass = 'fa-linkedin-square';
        break;
      case 'google_plus':
        iconClass = 'fa-google-plus-square';
        break;
      default:
        iconClass = '';
        break;
    }
    return iconClass;
  };

  SocialNetworkIconClassHelper = Ember.Helper.helper(socialNetworkIconClass);

  exports.socialNetworkIconClass = socialNetworkIconClass;
  exports.default = SocialNetworkIconClassHelper;
});