define('knowme-client/adapters/attachment', ['exports', 'knowme-client/adapters/application', 'knowme-client/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { namespace, host } = _environment.default.APP.email_service;

  exports.default = _application.default.extend({
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,paginate,message_id,record_id,record_type}',
    namespace: [namespace, 'gmail'].join('/'),
    urlSegments: {
      host() {
        return host || document.location.host;
      }
    }
  });
});