define('knowme-client/models/column-setting', ['exports', 'ember-data/attr', 'ember-data/relationships', 'ember-data/model', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _attr, _relationships, _model, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_emberCpValidationsCustomProperties.default, _dirty.default, {
    user: (0, _relationships.belongsTo)('user', { async: true }),
    key: (0, _attr.default)(),
    name: (0, _attr.default)(),
    targetName: (0, _attr.default)(),
    internal: (0, _attr.default)('boolean'),
    enable: (0, _attr.default)('boolean'),
    position: (0, _attr.default)('number'),
    isInternal: Ember.computed.alias('internal'),
    isEnable: Ember.computed.alias('enable')
  });
});