define('knowme-client/helpers/collection-without', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var collectionWithout, CollectionWithoutHelper;

  exports.collectionWithout = collectionWithout = function (params) {
    var collection = params[0],
        instance = params[1];
    return collection.without(instance);
  };

  CollectionWithoutHelper = Ember.Helper.helper(collectionWithout);

  exports.collectionWithout = collectionWithout;
  exports.default = CollectionWithoutHelper;
});