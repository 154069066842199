define('knowme-client/storages/integration', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const StorageObj = _object.default.extend();

  // Uncomment if you would like to set initialState
  StorageObj.reopenClass({
    initialState() {
      return {};
    }
  });

  exports.default = StorageObj;
});