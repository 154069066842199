define('knowme-client/routes/campaign/ads', ['exports', 'knowme-client/mixins/routes/account-setup', 'knowme-client/mixins/routes/cable-setup'], function (exports, _accountSetup, _cableSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    channelName: 'AdChannel',
    pagination: {},
    queryParams: {
      status: {
        refreshModel: true
      }
    },
    beforeModel: function (transition) {
      this._super(...arguments);
    },
    model: function (_params) {
      return this.get('store').find('campaign', this.modelFor('campaign').id);
    },
    afterModel: function (model) {
      this.set('subscriptionData', { id: model.get('id') });
      this.set('controllerData', {});
      this.set('campaign', model);
      return Ember.RSVP.hash({
        ads: this.get('store').query('ad', { paginate: false, campaign_id: model.get('id') })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    afterReceived(controller, payload, action) {
      let ad = controller.get('store').peekRecord('ad', payload.id);
      if (action === 'create' && !controller.get('ads').includes(ad)) {
        controller.get('ads.content').pushObject(ad._internalModel);
      }
    },
    setupController: function (controller) {
      this._super(...arguments); // Call _super for default behavior
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});

      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
    },
    actions: {
      willTransition: function (transition) {
        // clear cached records
        if (transition.targetName === 'campaigns') {
          this.get('store').peekAll('ad').invoke('unloadRecord');
          this.get('store').peekAll('campaign').invoke('unloadRecord');
        }
      }

    }
  });
});