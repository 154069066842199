define('knowme-client/components/lead-activity/note-overview', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    note: null,
    printThis: Ember.inject.service(),
    attributesBlackList: ['from', 'to', 'cc', 'bcc'],
    commonAttributesWitheList: ['name', 'firstName', 'lastName', 'email', 'phone', 'subject', 'message'],
    toggleButtonSelector: null,
    hash: Ember.computed('note', function () {
      var obj = {};
      var property = [];
      var key,
          value,
          subitems = '',
          note = this.get('note') || '';
      if (Ember.isBlank(note)) {
        return {};
      }
      var items = note.split(',\n');
      items.map(function (item) {
        let matches = item.match(/(.*?)\:(.*)/);
        key = matches[1].trim().replace(/\s/g, '-');
        value = matches[2].trim(); //.replace(/\.*(,)?/g, '');
        if (value.includes(';')) {
          obj[key] = value.split(';');
        } else if (value.match(/^\[.*\]$/)) {
          obj[key] = eval(value).join(', ');
        } else {
          obj[key] = value;
        }
      });
      return obj;
    }),
    commonAttributes: Ember.computed('hash', function () {
      let commonAttributes = {};
      let commonAttributesWitheList = this.get('commonAttributesWitheList');
      let attributesBlackList = this.get('attributesBlackList');
      let hash = this.get('hash');
      for (var key in hash) {
        if (commonAttributesWitheList.includes(key) && !attributesBlackList.includes(key)) {
          commonAttributes[key] = hash[key];
        }
      }
      return commonAttributes;
    }),
    sortedCommonAttributes: Ember.computed('commonAttributes', function () {
      let commonAttributesWitheList = this.get('commonAttributesWitheList');
      let sortedCommonAttributes = _lodash.default.toPairs(this.get('commonAttributes')).sort(function (a, b) {
        return commonAttributesWitheList.indexOf(a[0]) - commonAttributesWitheList.indexOf(b[0]);
      });
      return sortedCommonAttributes;
    }),
    customAttributes: Ember.computed('hash', 'customAttributes', function () {
      let customAttributes = {};
      let commonAttributes = this.get('commonAttributes');
      let attributesBlackList = this.get('attributesBlackList');
      let hash = this.get('hash');
      for (var key in hash) {
        if (!Object.keys(commonAttributes).includes(key) && !attributesBlackList.includes(key) && !Ember.isBlank(hash[key])) {
          customAttributes[key] = hash[key];
        }
      }
      return customAttributes;
    }),
    actions: {
      hideSection: function () {
        Ember.$(this.get('toggleButtonSelector')).click();
      },
      doPrint: function () {
        const selector = 'table.note-table';
        const options = {
          printDelay: 500,
          importCSS: true,
          importStyle: true
        };

        this.get('printThis').print(selector, options);
      }
    }
  });
});