define('knowme-client/models/dashboard-permission', ['exports', 'ember-data/model', 'ember-data/relationships', 'knowme-client/mixins/dirty'], function (exports, _model, _relationships, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_dirty.default, {
    account: (0, _relationships.belongsTo)('account', { async: true }),
    role: (0, _relationships.belongsTo)('role', { async: true }),
    dashboardWidget: (0, _relationships.belongsTo)('dashboard-widget', { async: true })
  });
});