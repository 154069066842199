define('knowme-client/mixins/controllers/queryable', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		queryParams: ['query'],
		query: null,
		queryValue: null,
		millisecondsToWait: 600,
		onQueryChange: Ember.observer('query', function () {
			if (this.get('query') !== this.get('queryValue')) {
				this.set('queryValue', this.get('query'));
			}
		}),
		onQueryValueChange: Ember.observer('queryValue', function () {
			let queryValue = this.get('queryValue');
			let value = queryValue;
			if (queryValue === "") {
				value = null;
			}
			Ember.run.debounce(this, Ember.set, this, 'query', value, this.get('millisecondsToWait'));
		})
	});
});