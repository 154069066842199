define('knowme-client/controllers/leads/show/detail', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let LeadsShowDetailController;

	LeadsShowDetailController = Ember.Controller.extend({
		features: Ember.inject.service(),
		session: Ember.inject.service(),
		queryParams: ['contact_id'],
		lead: Ember.computed('model', function () {
			return this.get('model');
		})
	});

	exports.default = LeadsShowDetailController;
});