define('knowme-client/internal-result-object', ['exports', 'validations/error', 'utils/utils'], function (exports, _error, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    model: null,
    isValid: true,
    isValidating: false,
    message: null,
    warningMessage: null,
    attribute: '',

    _promise: null,
    _validator: null,
    _type: Ember.computed.readOnly('_validator._type'),

    init() {
      this._super(...arguments);

      if (this.isAsync) {
        this._handlePromise();
      }
    },

    isWarning: Ember.computed.readOnly('_validator.isWarning'),
    isInvalid: Ember.computed.not('isValid'),
    isNotValidating: Ember.computed.not('isValidating'),
    isTruelyValid: Ember.computed.and('isNotValidating', 'isValid'),
    isTruelyInvalid: Ember.computed.and('isNotValidating', 'isInvalid'),

    isAsync: Ember.computed('_promise', function () {
      return (0, _utils.isPromise)(this._promise);
    }),

    messages: Ember.computed('message', function () {
      return Ember.makeArray(this.message);
    }),

    error: Ember.computed('isInvalid', 'type', 'message', 'attribute', function () {
      if (this.isInvalid) {
        return _error.default.create({
          type: this._type,
          message: this.message,
          attribute: this.attribute
        });
      }

      return null;
    }),

    errors: Ember.computed('error', function () {
      return Ember.makeArray(this.error);
    }),

    warningMessages: Ember.computed('warningMessage', function () {
      return Ember.makeArray(this.warningMessage);
    }),

    warning: Ember.computed('isWarning', 'type', 'warningMessage', 'attribute', function () {
      if (this.isWarning && !Ember.isNone(this.warningMessage)) {
        return _error.default.create({
          type: this._type,
          message: this.warningMessage,
          attribute: this.attribute
        });
      }

      return null;
    }),

    warnings: Ember.computed('warning', function () {
      return Ember.makeArray(this.warning);
    }),

    _handlePromise() {
      Ember.set(this, 'isValidating', true);

      this._promise.finally(() => {
        Ember.set(this, 'isValidating', false);
      });
    }
  });
});