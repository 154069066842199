define('knowme-client/mirage/models/message', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		contact: (0, _emberCliMirage.belongsTo)(),
		user: (0, _emberCliMirage.belongsTo)('user', { inverse: 'calls' }),
		opportunity: (0, _emberCliMirage.belongsTo)(),
		lead: (0, _emberCliMirage.belongsTo)()
	});
});