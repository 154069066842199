define('knowme-client/models/audit', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend({
		user: (0, _relationships.belongsTo)('user', { async: true }),
		auditedChanges: (0, _attr.default)('json'),
		revision: (0, _attr.default)('json'),
		action: (0, _attr.default)(),
		createdAt: (0, _attr.default)('date')
	});
});