define('knowme-client/components/opportunity-form', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    toggleNotes: 'hide',
    toggleAttachments: 'hide',
    appointmentDate: null,
    confirmConvertOppModal: false,
    confirmedConvertOppModal: false,
    attachmentsCount: Ember.computed.alias('model.attachments.length'),
    opportunity: Ember.computed.alias('model'),
    campaignAdSelectIsOpen: false,
    showQuoteForm: false,
    isCreatingEstimate: false,
    init: function () {
      this._super.apply(this, arguments);
      Ember.run.schedule("afterRender", this, function () {
        Ember.$("#opportunity-orders [data-toggle='tooltip']").tooltip();

        // Open time picker for appt. date when date is selected
        Ember.$("#appt-datetimepicker").on("dp.show", function () /*e*/{
          Ember.$(".day").on('click', function () {
            Ember.$("a[data-action='togglePicker']").trigger('click');
          });
        });
        Ember.$("#appt-datetimepicker").on("dp.change", function () /*e*/{
          Ember.$(".day").on('click', function () {
            Ember.$("a[data-action='togglePicker']").trigger('click');
          });
        });
      });
    },
    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('toggleNotes') === 'show') {
        Ember.run.later(function () {
          this.send('makeRejectedNotesRead');
        }.bind(this), 1000);
      }
    },
    lastSalesTransaction: Ember.computed('model.salesTransactions.length', 'model.lastSalesTransaction', function () {
      return this.get('model.lastSalesTransaction');
    }),
    enableOpportunityFields: Ember.computed.filter('opportunityFields', function (opportunityField) {
      return opportunityField.get('enable');
    }),
    salesTransactionType: Ember.computed('lastSalesTransaction.type', function () {
      let salesTransactionType = 'Estimate';
      if (Ember.isPresent(this.get('lastSalesTransaction.type'))) {
        salesTransactionType = this.get('lastSalesTransaction.type').camelize().capitalize();
      }
      return salesTransactionType;
    }),
    salesTransactionLink: Ember.computed('model.isClosed', 'model.salesStageHasChanged', 'lastSalesTransaction.isDraft', 'salesTransactionType', function () {
      let text = '';
      if (this.get('lastSalesTransaction.isEstimate') || Ember.isBlank(this.get('lastSalesTransaction.idNumber'))) {
        if (this.get('lastSalesTransaction.isDraft')) {
          if (this.get('model.salesTransactions.length') > 1) {
            text = `Resend ${this.get('salesTransactionType')}`;
          } else {
            text = `Send ${this.get('salesTransactionType')}`;
          }
        } else {
          text = `Last Sent ${this.get('salesTransactionType')}`;
        }
      } else {
        if (this.get('lastSalesTransaction.isDraft')) {
          text = `Send ${this.get('salesTransactionType')} # ${this.get('lastSalesTransaction.idNumber')}`;
        } else {
          if (this.get('lastSalesTransaction.isPaid')) {
            text = `Resend / Print ${this.get('salesTransactionType')} # ${this.get('lastSalesTransaction.idNumber')}`;
          } else {
            text = `Resend ${this.get('salesTransactionType')} # ${this.get('lastSalesTransaction.idNumber')}`;
          }
        }
      }

      return text;
    }),
    minApptDate: Ember.computed('opportunity.appointmentDate', function () {
      let now = (0, _moment.default)();
      if (now.isAfter(this.get('opportunity.appointmentDate'))) {
        return this.get('opportunity.appointmentDate');
      } else {
        return (0, _moment.default)();
      }
    }),
    minForecastDate: Ember.computed('opportunity.forecastDate', 'opportunity.dueDate', function () {
      let date = (0, _moment.default)();
      if (date.isAfter(this.get('opportunity.forecastDate'))) {
        date = (0, _moment.default)(this.get('opportunity.forecastDate'));
      } else {
        date = (0, _moment.default)();
      }

      if (Ember.isPresent(this.get('opportunity.dueDate')) && date.isAfter(this.get('opportunity.dueDate'))) {
        return date.toDate();
      } else {
        return this.get('opportunity.dueDate');
      }
    }),
    datesForecastDisabled: Ember.computed('opportunity.forecastDate', function () {
      let now = (0, _moment.default)();
      if (now.isAfter(this.get('opportunity.forecastDate'))) {
        let days = [];
        let date = (0, _moment.default)(this.get('opportunity.forecastDate'));
        while (now.isAfter(date)) {
          days.push(date.toDate());
          date.add(1, 'day');
        }
        return days;
      } else {
        return [];
      }
    }),
    minDueDate: Ember.computed('opportunity.dueDate', function () {
      let now = (0, _moment.default)();
      if (now.isAfter(this.get('opportunity.dueDate'))) {
        return this.get('opportunity.dueDate');
      } else {
        return (0, _moment.default)().toDate();
      }
    }),
    minDueTime: Ember.computed('opportunity.dueDate', function () {
      let now = (0, _moment.default)();
      if (now.isAfter(this.get('opportunity.dueDate'))) {
        return this.get('opportunity.dueDate');
      } else {
        return (0, _moment.default)().toDate();
      }
    }),
    datesDueDisabled: Ember.computed('opportunity.dueDate', function () {
      let now = (0, _moment.default)();
      if (now.isAfter(this.get('opportunity.dueDate'))) {
        let days = [];
        let date = (0, _moment.default)(this.get('opportunity.dueDate'));
        while (now.isAfter(date)) {
          days.push(date.toDate());
          date.add(1, 'day');
        }
        return days;
      } else {
        return [];
      }
    }),
    opportunityAttachments: Ember.computed.mapBy('unsavedAttachments', 'signedId'),
    priorities: Ember.computed('model', function () {
      let _priorities = [{ id: 'low', name: 'Low' }, { id: 'medium', name: 'Medium' }, { id: 'high', name: 'High' }];
      return _priorities;
    }),
    priorityPromt: Ember.computed('model', function () {
      return this.get('model.isNew') ? 'Select a Priority' : '(none)';
    }),
    priorityOptions: [{ id: "low", name: "Low" }, { id: "medium", name: "Medium" }, { id: "high", name: "High" }],
    activityOptions: Ember.computed('features.isBasicCrmOrKnowmeIQStarter', function () {
      let options = [];
      if (this.get('features.isBasicCrmOrKnowmeIQStarter')) {
        options = ["inbound_call", "outbound_call", "inbound_email", "outbound_email", "inbound_sms", "outbound_sms"];
      }
      options = options.concat(["update", "sent_call_audio", "form", "in_person", "referral", "adwords", "inbound_fax", "outbound_fax", "other"]);
      return options;
    }),
    disableAssignedToSelect: Ember.computed('currentUser.roles.@each.name', function () {
      return !this.get('session.currentUser.hasAdminRole') || !this.get('session.currentUser.isManager');
    }),
    disableSalesStageSelect: Ember.computed('model', 'model.salesStage.key', function () {
      return this.get('model.isNew') && this.get('model.salesStage.key') === 'closed';
    }),
    hasTaxableProduct: Ember.computed('model.orders.@each.productId', function () {
      return this.get('model.orders') && this.get('model.orders').any(function (order) {
        return order.get('product.taxable');
      });
    }),
    missingProduct: Ember.computed('model.nonDeletedOrders.@each.productId', 'model.nonDeletedOrders.@each.quantity', function () {
      let hasInvalidProduct = this.get('opportunity.validations.attrs.nonDeletedOrders.errors').any(function (error) {
        return error.attribute === 'product';
      });
      return this.get('model.nonDeletedOrders') && hasInvalidProduct;
    }),
    hasSoldOutProducts: Ember.computed('model.nonDeletedOrders.@each.productId', function () {
      return this.get('model.nonDeletedOrders') && this.get('model.nonDeletedOrders').any(function (order) {
        return order.get('product.isSoldOut');
      });
    }),
    missingQuantity: Ember.computed('model.nonDeletedOrders.@each.quantity', 'model.nonDeletedOrders.@each.productId', function () {
      return this.get('model.nonDeletedOrders') && this.get('model.nonDeletedOrders').any(function (order) {
        return Ember.isPresent(order.get('validations.attrs.quantity.error.message'));
      });
    }),
    maxDiscountExceeded: Ember.computed('model.nonDeletedOrders.@each.maxDiscountPercentageExceeded', function () {
      return this.get('model.nonDeletedOrders') && this.get('model.nonDeletedOrders').isAny('maxDiscountPercentageExceeded', true);
    }),
    invalidOrders: Ember.computed('model.nonDeletedOrders.@each.isValid', function () {
      return this.get('model.nonDeletedOrders') && this.get('model.nonDeletedOrders').isAny('isValid', false);
    }),
    disableAddOrder: Ember.computed.alias('invalidOrders'),
    appointmentDateIsBlank: Ember.computed('opportunity.salesStage', 'opportunity.appointmentDate', function () {
      return this.get('opportunity.salesStage.key') === 'appointment' && Ember.isBlank(this.get('opportunity.appointmentDate'));
    }),
    openedOppHasSoldOutProducts: Ember.computed('hasSoldOutProducts', function () {
      return this.get('hasSoldOutProducts') && !this.get('wasOpportunityClosed');
    }),
    ordersProductsFeedbackClass: Ember.computed('missingProduct', 'openedOppHasSoldOutProducts', function () {
      return this.get('missingProduct') || this.get('openedOppHasSoldOutProducts') ? 'glyphicon-asterisk text-danger sup' : 'glyphicon-ok text-success sup';
    }),
    ordersQuantitiesFeedbackClass: Ember.computed('missingQuantity', function () {
      return this.get('missingQuantity') ? 'glyphicon-asterisk text-danger sup' : 'glyphicon-ok text-success sup';
    }),
    wasOpportunityClosed: Ember.computed('model', 'model.salesStageHasChanged', function () {
      if (this.get('opportunity.isNew')) {
        return false;
      }

      if (this.get('opportunity.salesStageHasChanged')) {
        return this.get('opportunity').get('sortedActivities.firstObject').get('body.opportunity').salesStage.key === 'closed';
      } else {
        return this.get('opportunity.isClosed');
      }
    }),
    maxDiscountFeedbackClass: Ember.computed('maxDiscountExceeded', function () {
      return this.get('maxDiscountExceeded') ? 'glyphicon-asterisk text-danger sup' : 'glyphicon-ok text-success sup';
    }),
    opportunityPipeline: Ember.computed('model.nonDeletedOrders.@each.total', function () {
      return this.get('model.nonDeletedOrders').reduce(function (acc, order) {
        return acc + order.get('total');
      }, 0);
    }),
    disableEdit: Ember.computed('model', 'model.salesStage.key', function () {
      return !this.get('model.isNew') && this.get('model.salesStage.key') === 'closed' && !this.get('session.currentUser.hasAdminRole');
    }),
    dueTimeDate: Ember.computed('model.dueDate', function () {
      return this.get('model.dueDate') === undefined ? null : this.get('model.dueDate');
    }),
    onDueDateChange: Ember.observer('model.dueDate', function () {
      let dueDateBefore = this.get('model')._internalModel._data.dueDate;
      let date = (0, _moment.default)().toDate();
      if (Ember.isPresent(dueDateBefore)) {
        date = dueDateBefore;
      }
      if (Ember.isPresent(this.get('model').get('dueDate'))) {
        this.model.get('dueDate').setHours(date.getHours());
        this.model.get('dueDate').setMinutes(date.getMinutes());
        if ((0, _moment.default)(this.get('model.dueDate')).isAfter((0, _moment.default)(this.get('model.forecastDate')))) {
          this.set('model.forecastDate', this.get('model.dueDate'));
          Ember.$("#opportunity-forecast-date").focus();
        }
      }
    }),
    rejectedNotes: Ember.computed('filledNoteActivities', function () {
      return this.get('filledNoteActivities').filter(function (activity) {
        return activity.get('key') === 'quote_rejected' && Ember.isPresent(activity.get('body.opportunity.note')) && Ember.isBlank(activity.get('body.quote.readAt'));
      });
    }),
    actions: {
      onDueTimeSelected: function (dates) {
        let date = dates[0];
        if (date) {
          let dueDate = this.model.get('dueDate');
          dueDate.setHours(date.getHours());
          dueDate.setMinutes(date.getMinutes());
          this.set('model.dueDate', dueDate);
        }
      },
      toggleCalendar: function () {
        this.get('flatpickrRef').toggle();
      },
      addOrder: function () {
        this.get('store').createRecord('order', { opportunity: this.get('opportunity') });
      },
      setRelationshipValue: function (object, relationshipName, event) {
        object.set(relationshipName, event.target.value);
      },
      selectClosed: function (selectId, state) {
        console.log(selectId, state);
      },
      confirmRemoveOrder: function (order) {
        this.set('orderToBeRemoved', order);
      },
      removeOrder: function () {
        this.get('orderToBeRemoved').destroyRecord();
      },
      openQuoteForm: function () {
        if (Ember.isPresent(this.get('lastSalesTransaction'))) {
          this.set('showQuoteForm', true);
        } else {
          let target;
          if (this.get('selectedContact.contactInfosContactEmails.length') > 0) {
            target = this.get('selectedContact.firstContactEmail');
          } else {
            target = this.get('selectedContact.firstContactPhoneNumber');
          }
          let salesTransaction = this.get('store').createRecord('sales-transaction', {
            type: this.salesTransactionType,
            opportunity: this.model,
            contact: this.selectedContact,
            salesTransactionTarget: target
          });
          this.set('isCreatingEstimate', true);
          salesTransaction.save().then(function (st) {
            this.set('lastSalesTransaction', st);
            this.set('showQuoteForm', true);
            this.set('isCreatingEstimate', false);
          }.bind(this));
        }
      },
      setDefaultDiscount: function (order) {
        if (Ember.isBlank(order.get('discount'))) {
          order.set('discount', '0');
        }
      },
      updateSalesStage: function (salesStage) {
        if (this.get('opportunity.salesStage.key') === 'appointment') {
          this.set('appointmentDate', this.get('opportunity.appointmentDate'));
          let now = (0, _moment.default)().startOf('day');
          let appointmentDate = (0, _moment.default)(this.get('opportunity.appointmentDate')).startOf('day');
          if (now.isAfter(appointmentDate)) {
            this.set('opportunity.appointmentDate', null);
          }
        } else if (salesStage.get('key') === 'appointment' && Ember.isPresent(this.get('appointmentDate'))) {
          this.set('opportunity.appointmentDate', this.get('appointmentDate'));
          this.set('appointmentDate', null);
        }
        this.set('opportunity.salesStage', salesStage);
      },
      submit: function () {
        this.set('opportunity.files', this.get('opportunityAttachments'));
        this.set('appointmentDate', null);
        let displaySaleTypeConfirmation = false; // do not show confirm charge type
        displaySaleTypeConfirmation = displaySaleTypeConfirmation && Ember.isBlank(this.get('opportunity.chargeType'));
        displaySaleTypeConfirmation = displaySaleTypeConfirmation && this.get('opportunity.salesStage.key') === 'closed' && this.get('opportunity.ordersLength') > 0;
        if (displaySaleTypeConfirmation && !this.get('confirmedConvertOppModal')) {
          this.set('confirmConvertOppModal', true);
        } else {
          this.send('hideConfirmConvertOppModal');
          this.submit();
        }
      },
      hideConfirmConvertOppModal: function () {
        this.set('confirmConvertOppModal', false);
        this.set('confirmedConvertOppModal', false);
      },
      toggleNotes: function () {
        let klass = this.get('toggleNotes') === 'hide' ? 'show' : 'hide';
        if (klass == 'show') {
          this.send('makeRejectedNotesRead');
        }
        this.set('toggleNotes', klass);
      },
      makeRejectedNotesRead: function () {
        this.get('rejectedNotes').forEach(function (activity) {
          activity.set('body.quote.readAt', (0, _moment.default)());
          activity.save();
        });
      },
      toggleAttachments: function () {
        let klass = this.get('toggleAttachments') === 'hide' ? 'show' : 'hide';
        this.set('toggleAttachments', klass);
      },
      addAttachmentToOpportunity: function (blob) {
        this.get('unsavedAttachments').pushObject(blob);
      },
      removeAttachment: function (attachment) {
        this.get('unsavedAttachments').removeObject(attachment);
      },
      destroyAttachment: function (attachment) {
        attachment.destroyRecord().then(function () {
          this.get('model').reload();
        }.bind(this));
      }
    }
  });
});