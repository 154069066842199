define('knowme-client/models/email-touch-point', ['exports', 'ember-data/model', 'ember-data/attr', 'knowme-client/mixins/models/validations/email-touch-point', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'ember-data/relationships'], function (exports, _model, _attr, _emailTouchPoint, _emberCpValidationsCustomProperties, _dirty, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_emailTouchPoint.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    ad: (0, _relationships.belongsTo)('ad', { async: true }),
    account: (0, _relationships.belongsTo)('account', { async: true }),
    shortenedUrl: (0, _attr.default)(),
    status: (0, _attr.default)(),
    name: (0, _attr.default)(),
    isActive: Ember.computed.equal('status', 'active'),
    isInactive: Ember.computed.equal('status', 'inactive')
  });
});