define('knowme-client/components/nav-bar-hover', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let NavBarHoverComponent;

  NavBarHoverComponent = Ember.Component.extend({
    didInsertElement: function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        var correspondingCOlor = null;
        Ember.$("ul.navbar-nav li a").mouseover(function () {
          correspondingCOlor = Ember.$(this).parent().attr('color-code');
          Ember.$(this).css('color', correspondingCOlor);
        });
        Ember.$("ul.navbar-nav li a").mouseout(function () {
          if (!Ember.$(this).hasClass('active')) {
            Ember.$(this).css('color', '#777');
          }
        });
      });
    }
  });

  exports.default = NavBarHoverComponent;
});