define('knowme-client/services/cable', ['exports', 'ember-cable/services/cable'], function (exports, _cable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cable.default;
    }
  });
});