define('knowme-client/models/review-links-contact', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    reviewLink: (0, _relationships.belongsTo)('review-link', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    contact: (0, _relationships.belongsTo)('contact', { async: true, inverse: 'reviewLinksContacts' }),
    // respondedContacts: hasMany('contact', {async: true, inverse: 'incentivesContact'}),
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
    reviewLinksContactTarget: (0, _relationships.belongsTo)('review-links-contact-target', { async: true, polymorphic: true, inverse: 'reviewLinksContacts' }),
    reviewLinksContactTargetType: Ember.computed('reviewLinksContactTarget', function () {
      if (this.get('reviewLinksContactTarget').content) {
        return this.get('reviewLinksContactTarget').content.constructor.modelName.camelize().capitalize();
      } else {
        return this.get('reviewLinksContactTarget').constructor.modelName.camelize().capitalize();
      }
    }),
    opened: (0, _attr.default)('boolean'),
    createAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date')
  });
});