define('knowme-client/lib/poller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Poller = Ember.Object.extend({

    _interval: 1000,
    _currentlyExecutedFunction: null,

    start: function (context, pollingFunction) {
      console.log('start');
      this.set('_currentlyExecutedFunction', this._schedule(context, pollingFunction, [].slice.call(arguments, 2)));
    },

    stop: function () {
      console.log('stop');
      Ember.run.cancel(this.get('_currentlyExecutedFunction'));
    },

    _schedule: function (context, func, args) {
      if (!Ember.testing) {
        return Ember.run.later(this, function () {
          this.set('_currentlyExecutedFunction', this._schedule(context, func, args));
          if (context.get('pausePoller')) {
            console.log('skip execution');
          } else {
            console.log(`${(0, _moment.default)().format('HH:mm:ss')}`, 'execute');
            func.apply(context, args);
          }
        }, this.get('_interval'));
      }
    },

    setInterval: function (interval) {
      this.set('_interval', interval);
    }

  });

  exports.default = Poller;
});