define('knowme-client/components/charts/stacked-horizontal-bar-chart', ['exports', 'd3-selection', 'd3-scale', 'd3-scale-chromatic', 'd3-axis', 'd3-array'], function (exports, _d3Selection, _d3Scale, _d3ScaleChromatic, _d3Axis, _d3Array) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function () {
            this._super.apply(this, arguments);
            this.draw();
        },
        draw() {
            let self = this;
            var data = this.get('data');
            var barHeight = 15;
            var interval = 5;

            if (data) {
                var h = 20 * data.length + 100;

                var margin = { top: 50, right: 50, bottom: 50, left: 150 },
                    width = 1200 - margin.left - margin.right,
                    height = (barHeight + interval) * data.length;

                var color = (0, _d3Scale.scaleOrdinal)(_d3ScaleChromatic.schemeCategory10).domain([0, 1, 2, 3]);

                data.sort(function (a, b) {
                    return a.value - b.value;
                });

                var x = (0, _d3Scale.scaleLinear)().range([0, width * 0.8]);
                var y = (0, _d3Scale.scaleBand)().range([height, 0]);

                var svg = (0, _d3Selection.select)("#chart-graph").append("svg").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

                x.domain([0, (0, _d3Array.max)(data, function (d) {
                    return d.value;
                })]);
                y.domain(data.map(function (d) {
                    return d.key;
                }));

                // labels, axis Y
                svg.append("g").attr("class", "y-label").attr("transform", "translate(" + margin.left + ",-" + 0 + ")").attr("style", "font-size: 11px;").call((0, _d3Axis.axisLeft)(y).tickSize(0));
                svg.selectAll(".y-label text").attr('x', '-5').attr('dy', '0');

                // axis X
                svg.append("g").attr("transform", "translate(" + margin.left + "," + height + ")").call((0, _d3Axis.axisBottom)(x));

                // bars
                var bars = svg.selectAll(".bar").data(data).enter().append("g");

                bars.append("rect").attr("class", "bar").attr("x", margin.left).attr("height", barHeight).attr("y", function (d) {
                    return y(d.key);
                }).attr("dy", ".15em").attr("width", function (d) {
                    return x(d.value);
                }).style("fill", function (d, i) {
                    return color(i);
                }).on("click", function () {
                    console.log('clicked');
                });

                // clickable value after bar
                bars.append("text").attr("style", "cursor: pointer;").attr("class", "value").attr("y", function (d) {
                    return y(d.key) + 12;
                }).attr("x", function (d) {
                    return x(d.value) + margin.left + 5;
                }).text(function (d) {
                    return d.value;
                }).on("click", function (x) {
                    self.sendAction('onClick', self.get('modelName'), x.id);
                });
            }
        }
    });
});