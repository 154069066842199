define('knowme-client/helpers/customer-experiences-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute([], hash) {
      let intl = this.get('intl');

      if (Ember.isPresent(hash.satisfaction) && !['prompt', 'none'].includes(hash.satisfaction)) {
        if (hash.total > hash.count && Ember.isBlank(hash.selectedResource)) {
          if (['very_dissatisfied', 'dissatisfied', 'neutral', 'satisfied', 'very_satisfied'].includes(hash.satisfaction)) {
            return `${hash.showingStart}-${hash.showingEnd} ${intl.t('contacts.summary', { modelLength: hash.count })} of ${hash.total} with ${intl.t('customer_experiences.filters.satisfaction.' + hash.satisfaction)} Experience`;
          } else {
            return `Showing: ${hash.showingStart}-${hash.showingEnd} ${intl.t('customer_experiences.filters.satisfaction.' + hash.satisfaction)} ${intl.t('contacts.summary', { modelLength: hash.count })} of ${hash.total}`;
          }
        } else {
          if (['very_dissatisfied', 'dissatisfied', 'neutral', 'satisfied', 'very_satisfied'].includes(hash.satisfaction)) {
            return `${hash.count} ${intl.t('contacts.summary', { modelLength: hash.count })} with ${intl.t('customer_experiences.filters.satisfaction.' + hash.satisfaction)} Experience`;
          } else {
            return `Showing: ${hash.count} ${intl.t('customer_experiences.filters.satisfaction.' + hash.satisfaction)} ${intl.t('contacts.summary', { modelLength: hash.count })}`;
          }
        }
      } else {
        if (hash.total > hash.count && Ember.isBlank(hash.selectedResource)) {
          return `Showing: ${hash.showingStart}-${hash.showingEnd} Unique ${intl.t('contacts.summary', { modelLength: hash.count })} of ${hash.total}`;
        } else {
          return `Showing: ${hash.count} Unique ${intl.t('contacts.summary', { modelLength: hash.count })}`;
        }
      }
    }
  });
});