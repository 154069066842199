define('knowme-client/mirage/serializers/campaign', ['exports', 'knowme-client/mirage/serializers/active-model-serializer'], function (exports, _activeModelSerializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelSerializer.default.extend({
    relationships: ['ads'],
    include: ['ads']
  });
});