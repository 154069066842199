define('knowme-client/components/date-filter', ['exports', 'moment', 'lodash'], function (exports, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function formatDate(date) {
    return date.format('MM-DD-YYYY'); //FIXME: Rails expects DD-MM-YYYY instead of MM-DD-YY
  }

  function trackPropertyChanges(defaultValue) {
    var value = defaultValue;

    function updateChangedProperty(model, name, property, value) {
      var changedProperties = model.get('changedProperties');
      if (!changedProperties) {
        changedProperties = Ember.Object.create();
        model.set('changedProperties', changedProperties);
      }

      var propertyObject = changedProperties.get(property);
      if (!propertyObject) {
        propertyObject = Ember.Object.create();
        changedProperties.set(property, propertyObject);
      }

      propertyObject.set(name, value);
    }

    return Ember.computed({
      get: function () {
        return value;
      },

      set: function (propertyName, propertyValue, oldProperyValue) {
        updateChangedProperty(this, 'old', propertyName, oldProperyValue);
        updateChangedProperty(this, 'new', propertyName, propertyValue);

        value = propertyValue;
        return propertyValue;
      }
    });
  }

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    fromDate: null,
    toDate: null,
    customFromDate: null,
    customToDate: null,
    selectedLabel: null,
    isWrongPeriod: false,
    dateFilterSelected: trackPropertyChanges(null),
    dateFilterCustomSelectorDisplayed: false,
    dateFilterPromptText: 'Filter by Date',
    defaultDateFilter: null,
    dateFilters: ['today', 'todayAndYesterday', 'todayAndPrevious2Days', 'todayAndPrevious6Days', 'todayAndPrevious29Days', 'previousMonth', 'yearToDate', 'previousYear'],
    dateFilterOptions: Ember.computed('dateFilters', function () {
      let self = this;
      return this.get('dateFilters').map(function (x) {
        return { id: x, name: this.get('intl').t(`date.filter.options.${x}`, {
            month: (0, _moment.default)().subtract(1, 'month').format('MMMM YYYY'),
            year: (0, _moment.default)().subtract(1, 'year').format('YYYY')
          })
        };
      }.bind(this));
    }),
    customOption: Ember.computed('dateFilterSelected', 'fromDate', 'toDate', function () {
      let custom = { id: 'custom', name: 'Custom' };
      let selectedOption = this.get('dateFilterSelected');
      let previousOption = this.get('_oldDateFilterSelected');

      let previousIsCustom = Ember.isBlank(previousOption) || previousOption.id == 'custom';
      if (selectedOption) {
        // clear custom dates if custom is not selected
        if (selectedOption.id != 'custom') {
          this.set('customFromDate', null);
          this.set('customToDate', null);
        } else if (previousIsCustom && (Ember.isPresent(this.get('fromDate')) || Ember.isPresent(this.get('toDate')))) {
          let from = 'start';
          let to = 'now';

          if (Ember.isPresent(this.get('fromDate'))) {
            let date = (0, _moment.default)(this.get('fromDate'), 'MM-DD-YYYY');
            this.set('customFromDate', date.toDate());
            from = date.format('MM/DD/YY');
          }
          if (Ember.isPresent(this.get('toDate'))) {
            let date = (0, _moment.default)(this.get('toDate'), 'MM-DD-YYYY');
            this.set('customToDate', date.toDate());
            to = date.format('MM/DD/YY');
          }
          selectedOption.name = `Custom: ${from} - ${to}`;
        }
      }
      return custom;
    }),
    onDatesPresent: Ember.observer('fromDate', 'toDate', function () {
      let selectedOption = this.get('dateFilterSelected');
      if (selectedOption) {
        if (Ember.isBlank(this.get('dateFilterSelected.id')) && (Ember.isPresent(this.get('from_date')) || Ember.isPresent(this.get('to_date')))) {
          this.set('dateFilterSelected', this.get('customOption'));
        }
      } else {
        // clear query params on load
        this.set('from_date', null);
        this.set('to_date', null);
      }
    }),
    dateFilterChanged: Ember.observer('dateFilterSelected', function (model, propertyName) {
      var oldValue = this.get('changedProperties.' + propertyName + '.old');
      var newValue = this.get('changedProperties.' + propertyName + '.new');
      this.set('_oldDateFilterSelected', oldValue);

      var controller = this;

      var filter = controller.get('dateFilterSelected.id');
      this.set('dateFilterCustomSelectorDisplayed', false);
      var options = {
        // Past
        todayAndYesterday: function () {
          var fromDate = (0, _moment.default)().subtract(1, 'day').startOf('day');
          var toDate = (0, _moment.default)();
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        todayAndPrevious2Days: function () {
          var fromDate = (0, _moment.default)().subtract(2, 'day').startOf('day');
          var toDate = (0, _moment.default)();
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        todayAndPrevious6Days: function () {
          var fromDate = (0, _moment.default)().subtract(6, 'day').startOf('day');
          var toDate = (0, _moment.default)();
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        todayAndPrevious29Days: function () {
          var fromDate = (0, _moment.default)().subtract(29, 'day').startOf('day');
          var toDate = (0, _moment.default)();
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        previous3Days: function () {
          var fromDate = (0, _moment.default)().subtract(3, 'day').startOf('day');
          var toDate = (0, _moment.default)().subtract(1, 'day').endOf('day');
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        previousMonth: function () {
          var fromDate = (0, _moment.default)().subtract(1, 'months').startOf('month').startOf('day');
          var toDate = (0, _moment.default)().subtract(1, 'months').endOf('month').endOf('day');
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        yearToDate: function () {
          var fromDate = (0, _moment.default)().startOf('year');
          var toDate = (0, _moment.default)();
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        previousYear: function () {
          var fromDate = (0, _moment.default)().subtract(1, 'year').startOf('year');
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(fromDate.endOf('year')) });
        },
        // Future
        todayAndNext6Days: function () {
          var fromDate = (0, _moment.default)().startOf('day');
          var toDate = (0, _moment.default)().add(6, 'day').endOf('day');
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        todayAndNext29Days: function () {
          var fromDate = (0, _moment.default)().startOf('day');
          var toDate = (0, _moment.default)().add(29, 'day').endOf('day');
          return controller.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
        },
        // Others
        today: function () {
          var today = (0, _moment.default)();
          return controller.setProperties({ fromDate: formatDate(today), toDate: formatDate(today) });
        },
        custom: function () {
          return controller.set('dateFilterCustomSelectorDisplayed', !controller.get('dateFilterCustomSelectorDisplayed'));
        }
      };
      let key = this.get('dateFilterSelected.id');
      this.set('selectedLabel', this.get('dateFilterOptions')[key]);
      var fnc = options[filter];
      if (Ember.isPresent(fnc)) {
        return fnc();
      } else {
        return controller.setProperties({ fromDate: null, toDate: null });
      }
    }),
    customFromDateStartDate: Ember.computed('filter', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        val = (0, _moment.default)().format('L');
      }
      if (filter === 'past_due_action') {
        val = null;
      }
      return val;
    }),
    customFromDateEndDate: Ember.computed('filter', 'customToDate', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = null;
        }
      }
      if (filter === 'past_due_action') {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = (0, _moment.default)().subtract(1, 'day').endOf('day').format('L');
        }
      }
      if (filter === null) {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = (0, _moment.default)().endOf('day').format('L');
        }
      }
      return val;
    }),
    customToDateStartDate: Ember.computed('customFromDate', function () {
      let val;
      if (Ember.isPresent(this.get('customFromDate'))) {
        val = (0, _moment.default)(this.get('customFromDate')).format('L');
      } else {
        val = null;
      }
      return val;
    }),
    customToDateEndDate: Ember.computed('customToDate', function () {
      let val;
      if (Ember.isPresent(this.get('customToDate'))) {
        val = (0, _moment.default)(this.get('customToDate')).format('L');
      } else {
        val = null;
      }
      return val;
    }),
    resetDateFilter: Ember.observer('from_date', 'to_date', function () {
      if (Ember.isEmpty(this.get('from_date')) && Ember.isEmpty(this.get('to_date'))) {
        this.set('dateFilterSelected', null);
      }
    }),
    onFromDateChange: Ember.observer('fromDate', function () {
      let period = this.get('dateFilterSelected') && this.get('dateFilterSelected').id;
      this.sendAction('updateDate', 'from_date', this.get('fromDate'), period);
      if (Ember.isBlank(this.get('fromDate')) && Ember.isBlank(this.get('toDate'))) {
        this.set('dateFilter', null);
        this.set('dateFilterCustomSelectorDisplayed', false);
      }
    }),
    onToDateChange: Ember.observer('toDate', function () {
      let period = this.get('dateFilterSelected') && this.get('dateFilterSelected').id;
      this.sendAction('updateDate', 'to_date', this.get('toDate'), period);
      if (Ember.isBlank(this.get('fromDate')) && Ember.isBlank(this.get('toDate'))) {
        this.set('dateFilter', null);
        this.set('dateFilterCustomSelectorDisplayed', false);
      }
    }),
    didReceiveAttrs() {
      this._super.apply(this, arguments);
      let options = this.get('dateFilterOptions');
      if (Ember.isPresent(this.get('fromDate')) || Ember.isPresent(this.get('toDate'))) {
        let today = (0, _moment.default)();
        let fromDate = (0, _moment.default)(this.get('fromDate'), "MM-DD-YYYY");
        let toDate = (0, _moment.default)(this.get('toDate'), "MM-DD-YYYY");
        if (Ember.isPresent(this.get('period')) && this.get('period') == 'custom') {
          return this.set('dateFilterSelected', { id: 'custom', name: 'Custom' });
        }
        let diffDays = toDate.diff(fromDate, 'days');
        let toDateIsToday = today.diff(toDate, 'days') === 0;
        let fromDateIsToday = today.diff(fromDate, 'days') === 0;
        let fromDateIsYesterday = today.diff(fromDate, 'days') === 1;
        let diffYears = today.diff(fromDate, 'years');
        let dateChanged = false;
        let option = null;

        if (toDateIsToday) {
          switch (diffDays) {
            case 0:
              option = _lodash.default.find(options, { id: 'today' });
              break;
            case 1:
              option = _lodash.default.find(options, { id: 'todayAndYesterday' });
              break;
            case 2:
              option = _lodash.default.find(options, { id: 'todayAndPrevious2Days' });
              break;
            case 6:
              option = _lodash.default.find(options, { id: 'todayAndPrevious6Days' });
              break;
            case 29:
              option = _lodash.default.find(options, { id: 'todayAndPrevious29Days' });
              break;
            default:
              dateChanged = true;
              break;
          }

          if (toDateIsToday && fromDate.diff((0, _moment.default)().startOf('year')) === 0) {
            option = _lodash.default.find(options, { id: 'yearToDate' });
            this.set('dateFilterSelected', option);
            dateChanged = false;
          }
        } else if (fromDateIsToday) {
          switch (diffDays) {
            case 6:
              option = _lodash.default.find(options, { id: 'todayAndNext6Days' });
              break;
            case 29:
              option = _lodash.default.find(options, { id: 'todayAndNext29Days' });
              break;
            default:
              dateChanged = true;
              break;
          }

          if (toDateIsToday && fromDate.diff((0, _moment.default)().startOf('year')) === 0) {
            option = _lodash.default.find(options, { id: 'yearToDate' });
            this.set('dateFilterSelected', option);
            dateChanged = false;
          }
        } else {
          dateChanged = true;

          if (diffDays < 31) {
            let startOfMonth = fromDate;
            startOfMonth = startOfMonth.startOf('month');
            let endOfMonth = fromDate;
            endOfMonth = endOfMonth.endOf('month');
            if (startOfMonth.format('MM/DD/YYYY') == fromDate.format('MM/DD/YYYY') && endOfMonth.format('MM/DD/YYYY') == toDate.format('MM/DD/YYYY')) {
              option = _lodash.default.find(options, { id: 'previousMonth' });
              this.set('dateFilterSelected', option);
              dateChanged = false;
            }
          }
          if (today.year() - toDate.year() === 1 && toDate.year() === fromDate.year() && toDate.format('DD-MM') == '31-12' && fromDate.format('DD-MM') == '01-01') {
            option = _lodash.default.find(options, { id: 'previousYear' });
            dateChanged = false;
          }
        }

        if (option) {
          this.set('dateFilterSelected', option);
        }
        if (dateChanged) {
          this.set('dateFilterSelected', { id: 'custom', name: 'Custom' });
        }
      } else {
        this.set('dateFilterSelected', null);
      }
    },
    actions: {
      focusOut: function (elm, event) {
        let key = event.target.id;
        let val = event.target.value;
        let date = (0, _moment.default)(val, 'MM/DD/YYYY');
        let previous = this.get(key);

        if (date.isValid() && (!previous || !date.isSame((0, _moment.default)(previous)))) {
          this.set(key, date.toDate());
        }
      },
      setCustomDates: function () {
        var fromDate = this.get('customFromDate');
        if (Ember.isPresent(fromDate)) {
          fromDate = (0, _moment.default)(fromDate);
        }
        var toDate = this.get('customToDate');
        if (Ember.isPresent(toDate)) {
          toDate = (0, _moment.default)(toDate);
        }
        if (Ember.isPresent(fromDate) && Ember.isPresent(toDate) && fromDate.isAfter(toDate)) {
          this.set('isWrongPeriod', true);
        } else {
          this.set('isWrongPeriod', false);
          //this.set('selectedLabel', 'custom');
          this.setProperties({ fromDate: formatDate(fromDate), toDate: formatDate(toDate) });
          return this.set('dateFilterCustomSelectorDisplayed', false);
        }
      },
      closeDateFilterCustomSelector: function () {
        return this.set('dateFilterCustomSelectorDisplayed', false);
      }
    }
  });
});