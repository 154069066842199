define('knowme-client/controllers/account/clients', ['exports', 'knowme-client/mixins/controllers/status', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/queryable'], function (exports, _status, _pagination, _queryable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_status.default, _pagination.default, _queryable.default, {
    onStatusChanged: Ember.observer('status', 'type', 'filter', function () {
      if (Ember.isPresent(this.get('page'))) {
        this.set('page', null);
      }
    }),
    session: Ember.inject.service(),
    queryParams: ['type', 'filter'],
    type: null,
    filter: null,
    typeOptions: [{ value: 'businesses', text: 'Businesses' }, { value: 'individuals', text: 'Individuals' }],
    filterOptions: [{ value: 'paid_and_trial', text: 'Paid and Free Trial' }, { value: 'internal', text: 'Internal' }, { value: 'trial', text: 'Free Trial' }, { value: 'paid', text: 'Paid' }, { value: 'cancelled', text: 'Cancelled' }],
    actions: {
      impersonate: function (user) {
        this.get('session').set('impersonatedUser', user);
        this.transitionToRoute('dashboard');
      },
      toggleNestedResources: function (resource) {
        resource.set('nestedResourcesDisplayed', !resource.get('nestedResourcesDisplayed'));
      }
    }
  });
});