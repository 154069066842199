define('knowme-client/models/campaign-event', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let CampaignEvent;

  CampaignEvent = _model.default.extend({
    campaign: (0, _relationships.belongsTo)('campaign', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    eventType: (0, _relationships.belongsTo)('event-type', { async: true }),
    description: (0, _attr.default)(),
    createdAt: (0, _attr.default)('date')
  });

  exports.default = CampaignEvent;
});