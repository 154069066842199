define('knowme-client/components/drag-sort-list', ['exports', 'ember-drag-sort/components/drag-sort-list'], function (exports, _dragSortList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dragSortList.default;
    }
  });
});