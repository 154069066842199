define('knowme-client/serializers/contract-version', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		attrs: {
			body: 'content'
		}
	});
});