define('knowme-client/models/role', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Role;

  Role = _model.default.extend({
    key: (0, _attr.default)(),
    name: (0, _attr.default)()
  });

  exports.default = Role;
});