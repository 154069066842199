define('knowme-client/helpers/parameterized-call-audio-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parameterizedCallAudioUrl = parameterizedCallAudioUrl;
  function parameterizedCallAudioUrl(params /*, hash*/) {
    let url = params[0];
    let audioUrl = null;
    if (Ember.isPresent(url)) {
      if (/stream1.knowme.net\/getaudio.php/.test(url)) {
        audioUrl = `${params[0]}&type=mp3`;
      } else {
        audioUrl = url;
      }
    }

    return audioUrl;
  }

  exports.default = Ember.Helper.helper(parameterizedCallAudioUrl);
});