define('knowme-client/components/inputs/phone-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    controlClass: '',
    showMaskOnHover: false,
    unmaskedValue: null,
    disabled: false,
    value: null,
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('unmaskedValue', this.get('valueWithoutCountryCode'));
    },
    onUnmaskedValueChanged: Ember.observer('unmaskedValue', function () {
      let unmaskedValue = this.get('unmaskedValue');
      if (Ember.isBlank(unmaskedValue)) {
        this.set('value', '');
      } else {
        this.set('value', `+1${unmaskedValue}`);
      }
    }),
    valueWithoutCountryCode: Ember.computed('value', function () {
      let matches = (this.get('value') || "").match(/\+1(\d+)/);
      if (Ember.isPresent(matches)) {
        return matches[1];
      } else {
        return '';
      }
    }),
    actions: {
      keyDown(maskedValue, event) {
        if (Ember.isPresent(this.get('onKeyDown'))) {
          this.get('onKeyDown')(this.get('unmaskedValue'), event);
        }
        return true;
      },
      keyUp(maskedValue, event) {
        if (Ember.isPresent(this.get('onKeyUp'))) {
          this.get('onKeyUp')(this.get('unmaskedValue'), event);
        }
        return true;
      },
      insertNewLine() {
        if (Ember.isPresent(this.get('onInsertNewLine'))) {
          this.get('onInsertNewLine')(this.get('unmaskedValue'), event);
        }
        return true;
      },
      focusOut() {
        if (Ember.isPresent(this.get('onFocusOut'))) {
          this.get('onFocusOut')(this.get('unmaskedValue'), event);
        }
        return true;
      }
    }
  });
});