define('knowme-client/components/campaign/resources-table-controls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    actions: {
      onSelect: function () {
        this.onSelect();
      },
      focusOut: function (elm, event) {
        this.focusOutCustomDate(event.target, event);
      },
      setCustomDates: function () {
        this.setCustomDates();
      },
      closeDateFilterCustomSelector: function () {
        this.closeDateFilterCustomSelector();
      }
    }
  });
});