define('knowme-client/mixins/reports-popover', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    let ReportsPopoverMixin, popoverBuilders;

    let setupPopover = function (controller, d3Context) {
        let chart = d3Context.internal.selectChart;
        if (!chart.select('.popover')[0][0]) {
            let _popover = chart.append('div');
            _popover.attr('class', 'popover');
            controller.set('popover', _popover);
        }
        let popover = controller.get('popover');
        popover.html('');
        return popover;
    };

    let setupPopoverPosition = function (popover, d, d3Context) {
        let path = d3Context.internal.main.select('.' + d3Context.internal.CLASS.shape + '-' + d.index)[0][0];
        let position = d3Context.internal.tooltipPosition([d], popover.property('offsetWidth'), popover.property('offsetHeight'), path);
        popover.style("top", position.top + "px").style("left", position.left + 'px');
        popover.style('display', 'block');
        return popover;
    };

    popoverBuilders = {
        leads_by_campaign: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.leads')}: ${object.get('leadsCount')}`);
            popover.append('p').text(`${intl.t('reports.popover.pipeline')}: ${intl.formatNumber(object.get('funnel'), { style: 'currency', currency: 'USD' })}`);

            let campaignId = object.get('id');
            popover.append('a').attr('href', `/reports?campaign_id=${campaignId}&type=leads_by_campaign`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { campaignId: campaignId } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        customers_by_campaign: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.customers')}: ${object.get('customersCount')}`);
            popover.append('p').text(`${intl.t('reports.popover.revenue')}: ${intl.formatNumber(object.get('revenue'), { style: 'currency', currency: 'USD' })}`);

            let campaignId = object.get('id');
            popover.append('a').attr('href', `/reports?campaign_id=${campaignId}&type=customers_by_campaign`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { campaignId: campaignId } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        top_campaigns_by_leads: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.leads')}: ${object.get('leadsCount')}`);
            popover.append('p').text(`${intl.t('reports.popover.pipeline')}: ${intl.formatNumber(object.get('funnel'), { style: 'currency', currency: 'USD' })}`);

            let campaignId = object.get('id');
            popover.append('a').attr('href', `/reports?campaign_id=${campaignId}&type=top_campaigns_by_leads`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { campaignId: campaignId } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        top_campaigns_by_customers: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.customers')}: ${object.get('customersCount')}`);
            popover.append('p').text(`${intl.t('reports.popover.revenue')}: ${intl.formatNumber(object.get('revenue'), { style: 'currency', currency: 'USD' })}`);

            let campaignId = object.get('id');
            popover.append('a').attr('href', `/reports?campaign_id=${campaignId}&type=top_campaigns_by_customers`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { campaignId: campaignId } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        leads_by_time_of_the_day: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.leads')}: ${Ember.get(object, 'leads_count')}`);
            popover.append('p').text(`${intl.t('reports.popover.pipeline')}: ${intl.formatNumber(Ember.get(object, 'funnel'), { style: 'currency', currency: 'USD' })}`);

            let time = Ember.get(object, 'time');
            popover.append('a').attr('href', `/reports?time=${encodeURIComponent(time)}&type=leads_by_time_of_the_day`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { time: time } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        customers_by_time_of_the_day: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.customers')}: ${Ember.get(object, 'customers_count')}`);
            popover.append('p').text(`${intl.t('reports.popover.revenue')}: ${intl.formatNumber(Ember.get(object, 'revenue'), { style: 'currency', currency: 'USD' })}`);

            let time = Ember.get(object, 'time');
            popover.append('a').attr('href', `/reports?time=${encodeURIComponent(time)}&type=leads_by_time_of_the_day`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { time: time } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        leads_by_day_of_the_week: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.leads')}: ${Ember.get(object, 'leads_count')}`);
            popover.append('p').text(`${intl.t('reports.popover.pipeline')}: ${intl.formatNumber(Ember.get(object, 'funnel'), { style: 'currency', currency: 'USD' })}`);

            let weekday = Ember.get(object, 'weekday');
            popover.append('a').attr('href', `/reports?weekday=${encodeURIComponent(weekday)}&type=leads_by_day_of_the_week`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { weekday: weekday } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        customers_by_day_of_the_week: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.customers')}: ${Ember.get(object, 'customers_count')}`);
            popover.append('p').text(`${intl.t('reports.popover.revenue')}: ${intl.formatNumber(Ember.get(object, 'revenue'), { style: 'currency', currency: 'USD' })}`);

            let weekday = Ember.get(object, 'weekday');
            popover.append('a').attr('href', `/reports?weekday=${encodeURIComponent(weekday)}&type=customers_by_day_of_the_week`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { weekday: weekday } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        leads_by_sales_rep: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.leads')}: ${Ember.get(object, 'leads_count')}`);
            popover.append('p').text(`${intl.t('reports.popover.pipeline')}: ${intl.formatNumber(Ember.get(object, 'funnel'), { style: 'currency', currency: 'USD' })}`);

            let salesRepName = Ember.get(object, 'name');
            popover.append('a').attr('href', `/reports?sales_representant=${encodeURIComponent(salesRepName)}&type=leads_by_sales_rep`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { salesRepresentant: salesRepName } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        },
        customers_by_sales_rep: function (d, d3Context) {
            let controller = this;
            let popover = setupPopover(this, d3Context);

            let object = this.get('model').objectAt(d.x);
            let intl = this.get('intl');

            popover.append('p').text(`${intl.t('reports.popover.customers')}: ${Ember.get(object, 'customers_count')}`);
            popover.append('p').text(`${intl.t('reports.popover.revenue')}: ${intl.formatNumber(Ember.get(object, 'revenue'), { style: 'currency', currency: 'USD' })}`);

            let salesRepName = Ember.get(object, 'name');
            popover.append('a').attr('href', `/reports?sales_representant=${encodeURIComponent(salesRepName)}&type=customers_by_day_of_the_week`).text('Details').on('click', function () {
                d3Context.internal.d3.event.preventDefault();
                controller.set('object', object);
                controller.transitionToRoute({ queryParams: { salesRepresentant: salesRepName } });
            });

            setupPopoverPosition(popover, d, d3Context);
            return popover;
        }
    };

    ReportsPopoverMixin = Ember.Mixin.create({
        intl: Ember.inject.service(),
        buildPopoverFor: function (reportType, d, d3Context) {
            if (Ember.isPresent(popoverBuilders[reportType])) {
                popoverBuilders[reportType].bind(this)(d, d3Context);
            }
        }
    });

    exports.default = ReportsPopoverMixin;
});