define('knowme-client/helpers/is-internal-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isInternalUrl = isInternalUrl;
  function isInternalUrl(params /*, hash*/) {
    return Ember.isPresent(params[0].match(/https?:\/\/(.+?\.)?(knowme|knowmeiq)\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?/));
  }

  exports.default = Ember.Helper.helper(isInternalUrl);
});