define('knowme-client/serializers/sales-transaction', ['exports', 'ember-inflector', 'knowme-client/serializers/json-api'], function (exports, _emberInflector, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {},
    normalize(typeClass, hash /*, prop*/) {
      hash = hash || {};
      hash.relationships = hash.relationships || {};

      let models = ['opportunity', 'contact', 'user', 'account'];
      models.forEach(function (model) {
        hash.relationships[model] = hash.relationships[model] || {};
        hash.relationships[model].links = hash.relationships.links || {};

        if (Ember.isPresent(hash.relationships[model].data)) {
          hash.relationships[model].links.related = Ember.getOwner(this).lookup(`adapter:${model}`).buildURL(model, hash.relationships[model].data.id, null, 'findRecord', null);
        }
      }.bind(this));

      return this._super.apply(this, arguments);
    },
    serialize: function (snapshot /*, options*/) {
      let json = this._super.apply(this, arguments);
      json['target_type'] = snapshot.record.get('salesTransactionTargetType');
      json['target_id'] = snapshot.record.get('salesTransactionTarget.id');
      return json;
    }
  });
});