define('knowme-client/components/forms/alerts-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    features: Ember.inject.service(),
    channelTypes: ['alert_from_user', 'alert_from_ad'],
    keys: [
    //'missed_call',
    'inbound_call_with_audio', 'inbound_call_without_audio', 'unhappy_customer_text', 'unhappy_customer_text_via_text', 'happy_customer_text', 'happy_customer_text_via_text', 'inbound_short_message'],
    chatTouchPoints: Ember.computed('model', function () {
      return this.get('store').query('chat-touch-point', { paginate: false, status: 'active', sort_by: 'name', order: 'asc' });
    }),
    isWebChatWidgetCreated: Ember.computed('chatTouchPoints.length', function () {
      return this.get('chatTouchPoints.length') > 0;
    }),
    alertKeys: Ember.computed('recipientType', 'keys', 'isWebChatWidgetCreated', 'features.isKnowmeIQStarter', function () {
      if (this.get('recipientType') === 'alert_from_review') {
        return ['review_link_opened'];
      }
      let keys = this.get('keys');
      if (this.get('features.isKnowmeIQStarter')) {
        keys = ['unhappy_customer_feedback', 'happy_customer_feedback'];
      }

      if (this.get('recipientType') === 'alert_from_ad' && this.get('isWebChatWidgetCreated')) {
        return keys.concat(['contact_callback_request']);
      }
      return keys;
    }),
    loadData() {
      if (this.get('model.isNew')) {
        this.set('recipientType', 'alert_from_ad');
      } else {
        if (this.get('model.key') === 'review_link_opened') {
          this.set('recipientType', 'alert_from_review');
        } else if (Ember.isPresent(this.get('model.user.id'))) {
          this.set('recipientType', 'alert_from_user');
        } else {
          this.set('recipientType', 'alert_from_ad');
        }
      }
      if (Ember.isPresent(this.get('model.campaign.id'))) {
        this.set('selectedCampaignOption', this.get('model.campaign'));
      } else if (this.get('model.allCampaigns')) {
        this.set('selectedCampaignOption', 'all');
      }

      if (Ember.isPresent(this.get('model.ad.id'))) {
        this.set('selectedAdOption', this.get('model.ad'));
      } else if (this.get('model.allAds')) {
        this.set('selectedAdOption', 'all');
      }

      if (Ember.isPresent(this.get('model.user.id'))) {
        this.set('selectedUserOption', this.get('model.user'));
      }
    },
    didReceiveAttrs() {
      this.loadData();
      return this._super(...arguments);
    },
    didUpdateAttrs() {
      this.loadData();
      return this._super(...arguments);
    },
    textMethodSelected: Ember.computed('model.key', function () {
      return ['inbound_short_message', 'unhappy_customer_text_via_text', 'happy_customer_text_via_text'].includes(this.get('model.key'));
    }),
    campaignsOptions: Ember.computed('model.key', 'textMethodSelected', function () {
      if (Ember.isPresent(this.get('model.key'))) {
        let options = {
          paginate: false,
          status: 'active'
        };
        if (this.get('model.key') === 'contact_callback_request') {
          options.ads = { touch_point: 'chat' };
        } else if (['inbound_call_with_audio', 'inbound_call_without_audio'].includes(this.get('model.key')) || this.get('textMethodSelected')) {
          options.ads = { touch_point: 'local_number,toll_free_number' };
        } else if (this.get('model.key') !== 'review_link_opened') {
          options.filter = 'exclude_default';
        }

        return this.get('store').query('campaign', options);
      } else {
        return Ember.A([]);
      }
    }),
    adsOptions: Ember.computed('model.campaign.id', 'model.key', 'textMethodSelected', function () {
      if (Ember.isPresent(this.get('model.campaign.id'))) {
        let options = {
          paginate: false,
          status: 'active',
          campaign_id: this.get('model.campaign.id')
        };
        if (this.get('model.key') === 'contact_callback_request') {
          options.touch_point = ['chat'];
        } else if (['inbound_call_with_audio', 'inbound_call_without_audio'].includes(this.get('model.key')) || this.get('textMethodSelected')) {
          options.touch_point = ['local_number', 'toll_free_number'];
        }

        return this.get('store').query('ad', options);
      } else {
        return Ember.A([]);
      }
    }),
    onRecipientTypeChange: Ember.observer('recipientType', function () {
      this.set('model.key', null);
    }),
    onModelKeyChange: Ember.observer('model.key', function () {
      this.set('selectedUserOption', null);
      this.set('selectedCampaignOption', null);
      this.set('model.recipients', null);
      this.set('model.recipientsEmails', null);
    }),
    onSelectedCampaignOptionChange: Ember.observer('selectedCampaignOption', function () {
      let selectedCampaignOption = this.get('selectedCampaignOption');

      if (Ember.isPresent(selectedCampaignOption)) {
        if (selectedCampaignOption === 'all') {
          this.set('model.campaign', null);
          this.set('model.allCampaigns', true);
        } else {
          this.set('model.campaign', selectedCampaignOption);
          this.set('model.allCampaigns', false);
        }
      } else {
        this.set('model.campaign', null);
        this.set('model.allCampaigns', false);
      }

      this.set('selectedAdOption', null);
    }),
    onSelectedAdOptionChange: Ember.observer('selectedAdOption', function () {
      let selectedAdOption = this.get('selectedAdOption');
      if (Ember.isPresent(selectedAdOption)) {
        if (selectedAdOption === 'all') {
          this.set('model.ad', null);
          this.set('model.allAds', true);
        } else {
          this.set('model.ad', selectedAdOption);
          this.set('model.allAds', false);
        }
      } else {
        this.set('model.ad', null);
        this.set('model.allAds', false);
      }
    }),
    onSelectedUserOptionChange: Ember.observer('selectedUserOption', function () {
      let selectedUserOption = this.get('selectedUserOption');

      if (Ember.isPresent(selectedUserOption)) {
        this.set('model.user', selectedUserOption);
      } else {
        this.set('model.user', null);
      }
    }),
    recipientsEmails: Ember.computed('model.recipientsEmails', function () {
      if (Ember.isPresent(this.get('model.recipientsEmails'))) {
        return this.get('model.recipientsEmails').split(',').map(function (x) {
          return x.trim();
        });
      } else {
        return Ember.A([]);
      }
    }),
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.userHasChanged', 'model.adHasChanged', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.userHasChanged') || this.get('model.adHasChanged');
    }),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'isModelTreeDirty', function () {
      return !(this.get('model.validations.isValid') && this.get('isModelTreeDirty'));
    }),
    usersWithMobileNumbers: Ember.computed.filter('users', function (resource) {
      return Ember.isPresent(resource.get('mobilePhoneNumber'));
    }),
    availableUsers: Ember.computed('recipientType', 'model.key', function () {
      if (this.get('recipientType') === 'alert_from_user') {
        if (['inbound_call_with_audio', 'inbound_call_without_audio'].includes(this.get('model.key'))) {
          return this.get('usersWithKnowmeIQ');
        } else if (['unhappy_customer_text', 'happy_customer_text', 'unhappy_customer_feedback', 'happy_customer_feedback'].includes(this.get('model.key'))) {
          return this.get('users');
        } else {
          return this.get('usersWithMobileNumbers');
        }
      }
    }),
    actions: {
      addUserEmail(user) {
        let recipients = Ember.isBlank(this.get('model.recipients')) ? user.get('email') : `${this.get('model.recipients')}, ${user.get('email')}`;
        let recipientsEmails = Ember.isBlank(this.get('model.recipientsEmails')) ? user.get('email') : `${this.get('model.recipientsEmails')}, ${user.get('email')}`;
        this.set('model.recipients', recipients);
        this.set('model.recipientsEmails', recipientsEmails);
      },
      addUserToRecipients(user) {
        let recipients = Ember.isBlank(this.get('model.recipients')) ? user.get('fullName') : `${this.get('model.recipients')}, ${user.get('fullName')}`;
        let recipientsEmails = Ember.isBlank(this.get('model.recipientsEmails')) ? user.get('email') : `${this.get('model.recipientsEmails')}, ${user.get('email')}`;
        this.set('model.recipients', recipients);
        this.set('model.recipientsEmails', recipientsEmails);
      },
      submit(step) {
        if (this.get('recipientType') === 'alert_from_ad' || this.get('recipientType') === 'alert_from_review') {
          this.set('model.recipients', this.get('model.recipientsEmails'));
        }
        this.get('model').save().then(function () {
          if (step === 'close') {
            this.get('router').transitionTo('account.settings.alerts');
          }
          this.get('router._router._routerMicrolib').refresh();
        }.bind(this)).catch(function (reason) {
          this.get('flashMessages').warning(reason.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
          console.log("Errors", reason);
        }.bind(this));
      }
    }

  });
});