define('knowme-client/routes/feedback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let FeedbackRoute;

  FeedbackRoute = Ember.Route.extend({
    apiKey: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function (queryParams) {
      let params = this.paramsFor('feedback');
      this.set('apiKey.code', queryParams.code);

      return this.get('store').find('customer-experience', params.id);
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.set('apiKey.code', null);
      }
    }
  });

  exports.default = FeedbackRoute;
});