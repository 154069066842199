define('knowme-client/mirage/scenarios/default', ['exports', 'ember-cli-mirage', 'knowme-client/mirage/helpers/factory'], function (exports, _emberCliMirage, _factory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (hooks, plan, attrs) {
    hooks.beforeEach(function () {
      attrs = attrs || {};
      attrs.customerType = attrs.customerType || 'b2b';
      let account = this.server.create('account', attrs, plan);
      // Products
      this.server.createList('product', 5, { account: account });
      // Leads
      this.server.createList('lead', 22, { leadType: attrs.customerType });
    });
  };
});