define('knowme-client/serializers/attachment', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    serialize: function (snapshot /*, options*/) {
      let json = this._super.apply(this, arguments);
      json['record_type'] = snapshot.record.get('recordType');
      json['record_id'] = snapshot.record.get('record.id');
      return json;
    }
  });
});