define('knowme-client/models/device', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    owner: (0, _relationships.belongsTo)('user', { async: true }),
    model: (0, _attr.default)(),
    platform: (0, _attr.default)(),
    status: (0, _attr.default)(),
    verifiedPhoneNumber: (0, _attr.default)()
  });
});