define('knowme-client/adapters/lead-import', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let LeadImportAdapter;

	LeadImportAdapter = _application.default.extend({
		urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as,converted,ad_id,assigned_to_id}'
	});

	exports.default = LeadImportAdapter;
});