define('knowme-client/components/forms/appointment-reminders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    deliveryTypeOptions: ['text', 'email'],
    timeBeforeOptions: [{ text: '24 hours before', value: 1440 }, { text: '12 hours before', value: 720 }, { text: '6 hours before', value: 360 }, { text: '3 hours before', value: 180 }, { text: '1 hour before', value: 60 }, { text: '30 minutes before', value: 30 }, { text: '5 minutes before', value: 5 }],
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.userHasChanged', 'model.adHasChanged', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.userHasChanged') || this.get('model.adHasChanged');
    }),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'isModelTreeDirty', function () {
      return !(this.get('model.validations.isValid') && this.get('isModelTreeDirty'));
    }),
    availableUsers: Ember.computed('users', function () {
      return this.get('users');
    }),
    onTypeChanged: Ember.observer('model.reminderType', function () {
      if (!this.get('session.currentUser.hasAdminRole') || this.get('model.reminderType') === 'reminder') {
        this.set('model.user', this.get('session.currentUser'));
      } else {
        this.set('model.user', null);
      }
    }),
    actions: {
      submit(step) {
        this.get('model').save().then(function () {
          if (step === 'close') {
            localStorage.removeItem('reminderType');
            this.get('router').transitionTo('account.settings.appointment-reminders');
          } else {
            localStorage.setItem('reminderType', this.get('model.reminderType'));
          }
          this.get('router._router._routerMicrolib').refresh();
        }.bind(this)).catch(function (reason) {
          let message = reason.errors[0].detail;
          if (reason.errors[0].source.pointer.split('/')[2] === 'user_id') {
            message = `User ${this.get('model.user.fullName')} has reminder with ${this.get('model.formattedTimeBefore')}`;
          }
          this.get('flashMessages').warning(message, { destroyOnClick: true, timeout: 12000 });
        }.bind(this));
      }
    }
  });
});