define('knowme-client/routes/account/clients/edit', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function (params) {
      let model = this.get('store').find('account', params.id);
      return model;
    },
    afterModel: function (model) {
      model.set('skip_terms_of_service_validation', true);
      let _this = this;

      model.get('settings').then(function () {
        _environment.default.APP.SettingsKeys.forEach(key => {
          if (Ember.isBlank(model.get(`settings${key.camelize().capitalize()}`))) {
            // it makes Update button active
            _this.store.createRecord('account-setting', { key: key, account: model });
          }
        });
      });

      return Ember.RSVP.hash({
        plans: this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc' }),
        roles: this.get('store').query('role', { paginate: false, sort_by: 'name', order: 'asc' })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      willTransition: function () /*transition*/{
        let scrollDuration = 200;
        var scrollStep = -window.scrollY / (scrollDuration / 15),
            scrollInterval = setInterval(function () {
          if (window.scrollY != 0) {
            window.scrollBy(0, scrollStep);
          } else {
            clearInterval(scrollInterval);
          }
        }, 15);

        if (this.controller.get('model.hasDirtyAttributes')) {
          this.controller.get('model').rollbackAttributes();
        }
      }
    }
  });
});