define('knowme-client/controllers/campaign/ads', ['exports', 'knowme-client/mixins/controllers/status', 'lodash'], function (exports, _status, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_status.default, {
    leadsController: Ember.inject.controller('leads.index'),
    accountService: Ember.inject.service('account'),
    features: Ember.inject.service(),
    collapsedCampaignsHistory: true,
    campaign: Ember.computed.alias('model'),
    actions: {
      save: function (resource) {
        if (!_lodash.default.isEmpty(resource.changedAttributes()) || Ember.isPresent(resource.get('callTouchPoints.firstObject')) && !_lodash.default.isEmpty(resource.get('callTouchPoints.firstObject').changedAttributes())) {
          resource.save().catch(function (reason) {
            console.log("Errors", reason);
          });
        }
        resource.set('editing', false);
      },
      displayStatusSelector: function (resource) {
        resource.set('displayStatusSelector', !resource.get('displayStatusSelector'));
      },
      changeAdStatus: function (resource, newStatus) {
        resource.set('status', newStatus);
        resource.set('displayStatusSelector', false);
      }
    }
  });
});