define('knowme-client/helpers/last-experience', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    compute([contact, opportunity, type]) {
      return this.get('store').query('customer-experience', {
        contact_id: contact.get('id'),
        lead_id: opportunity.get('leadId'),
        survey_status: 'responded_to_survey',
        size: 1,
        sort: '-updated_at',
        paginate: true
      });
    }
  });
});