define('knowme-client/components/chats/chat-title', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    roomsUsers: Ember.computed.filter('chat.roomsUsers', function (resource) {
      return this.get('currentUser.id') !== resource.get('user.id');
    }).property('currentUser', 'chat.roomsUsers.@each.user.id')
  });
});