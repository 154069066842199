define('knowme-client/models/rooms-user', ['exports', 'ember-data/attr', 'ember-data/relationships', 'ember-data/model', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _attr, _relationships, _model, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_emberCpValidationsCustomProperties.default, _dirty.default, {
    room: (0, _relationships.belongsTo)('room', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true })
  });
});