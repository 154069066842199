define('knowme-client/models/customizable-resource', ['exports', 'ember-data/relationships', 'ember-data/model'], function (exports, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    customAttributes: (0, _relationships.hasMany)('custom-attribute', { async: true, inverse: 'customizableResource' }),
    attributesSorting: ['position:asc'],
    customAttributesSorted: Ember.computed.sort('customAttributes', 'attributesSorting')
  });
});