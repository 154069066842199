define('knowme-client/mixins/routes/contacts-subscriber', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cableService: Ember.inject.service('cable'),
    contactsSubscription: null,
    contactsConsumer: null,
    subscribeToContactsChannel: function (leadId) {
      this.createContactsConsumer({ lead_id: leadId });
    },
    createContactsConsumer: function (data) {
      let path = `${_environment.default.APP.Websocket.protocol}://${_environment.default.APP.Websocket.host}/cable`;
      let consumer = this.get('cableService').createConsumer(path);
      let store = this.get('store');
      const subscription = consumer.subscriptions.create('ContactsChannel', {
        connected() {
          this.perform("follow", data);
        },
        received(resp) {
          let payload = resp.payload;
          if (typeof payload === 'string') {
            payload = JSON.parse(resp.payload);
          }
          Ember.run.later(function () {
            let modelClass = store.modelFor(resp.modelName);
            let serializer = store.serializerFor(resp.modelName);
            let normalizedPayload = serializer.normalizeSingleResponse(store, modelClass, payload, null, true);
            store.push(normalizedPayload);
          }, 1000);
        },
        disconnected() {
          console.debug("disconnected");
        }
      });
      this.set('contactsSubscription', subscription);
      this.set('contactsConsumer', consumer);
    },
    unsubscribeFromContactsChannel: function () {
      this.get('contactsSubscription').unsubscribe();
      this.get('contactsConsumer').destroy();
    }
  });
});