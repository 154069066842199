define('knowme-client/mirage/helpers/json-web-token', ['exports', 'CryptoJS'], function (exports, _CryptoJS) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.decode = decode;


  function base64urlEncode(obj) {
    let base64url = _CryptoJS.default.enc.Utf8.parse(JSON.stringify(obj)).toString(_CryptoJS.default.enc.Base64);
    base64url = base64url.replace(/=/g, '').replace(/\//g, '_').replace(/\+/g, '-'); // crypto-js doesn't have base64url encoding; we must manually make the tokens URL safe
    return base64url;
  }

  function decode(user) {
    let expiresAt = moment().add(10, 'hours');
    let expire = expiresAt.unix();

    let tokenObjBase = {
      'typ': 'JWT',
      'alg': 'HS256'
    };
    let tokenObjAccess = {
      'id': user.attrs.id,
      'email': user.attrs.email,
      'expire': expire
    };
    let tokenObjRefresh = {
      'id': user.attrs.id,
      'email': user.attrs.email,
      'expire': expire
    };

    let secretKey = '9dbf8cc31624a8d1d943747bc1e1eb12c27d23fa1c92311952824d069f44f275e6286b79e08cfef2834d439b065b3f3603991d19dd039a6efa4d8eed4391b9cf';

    let tokenBase = base64urlEncode(tokenObjBase);
    let tokenAccess = base64urlEncode(tokenObjAccess);
    let tokenRefresh = base64urlEncode(tokenObjRefresh);

    let signatureAccessArray = _CryptoJS.default.HmacSHA256(tokenBase + '.' + tokenAccess, secretKey); // crypto-js returns a "wordarray" which must be stringified back to human readable text with a specific encoding
    let signatureAccess = signatureAccessArray.toString(_CryptoJS.default.enc.Base64).replace(/=+$/, '').replace(/\//g, '_').replace(/\+/g, '-'); // crypto-js doesn't have base64url encoding; we must manually make the tokens URL safe
    let signatureRefreshArray = _CryptoJS.default.HmacSHA256(tokenBase + '.' + tokenRefresh, secretKey);
    let signatureRefresh = signatureRefreshArray.toString(_CryptoJS.default.enc.Base64).replace(/=+$/, '').replace(/\//g, '_').replace(/\+/g, '-'); // crypto-js doesn't have base64url encoding; we must manually make the tokens URL safe

    return tokenBase + '.' + tokenAccess + '.' + signatureAccess;
  }
});