define('knowme-client/components/bootstrap-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let BootstrapModalComponent;

  BootstrapModalComponent = Ember.Component.extend({
    classNames: ['modal', 'fade'],
    attributeBindings: ['role'],
    open: false,
    didInsertElement: function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return this.$().modal({ show: false }).on("hide.bs.modal", function (_this) {
          return function () {
            _this.set('open', false);
            return _this.sendAction("closedModal");
          };
        }(this));
      });
    },
    onOpenChange: Ember.observer('open', function () {
      let action = this.get('open') ? 'show' : 'hide';
      this.$().modal(action);
    })
  });

  exports.default = BootstrapModalComponent;
});