define('knowme-client/mirage/factories/short-message', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    from: function () {
      return _faker.default.phone.phoneNumberFormat(0).replace(/-/g, '');
    },
    to: function () {
      return _faker.default.phone.phoneNumberFormat(0).replace(/-/g, '');
    },
    shortMessageType: function () {
      return _faker.default.random.arrayElement(['inbound', 'outbound']);
    },
    shortMessageDate: function () {
      return _faker.default.date.past().toISOString();
    },
    body: function () {
      return _faker.default.lorem.sentences();
    },
    canBeRead: function () {
      return _faker.default.random.arrayElement([true, false]);
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    afterCreate(shortMessage, server) {
      let contact = null;
      let opportunity = null;
      let user = null;
      if (Ember.isBlank(shortMessage.contact)) {
        let contactPhoneNumber = _faker.default.random.arrayElement(server.schema.contactPhoneNumbers.all().models);
        contact = contactPhoneNumber.contactInfo.contact;
        opportunity = contact.lead.opportunities.models[0];
        user = opportunity.assignedTo;
        shortMessage.update({
          opportunity: opportunity,
          contact: contact,
          contactPhoneNumber: contactPhoneNumber,
          user: user
        });
      } else {
        contact = shortMessage.contact;
        opportunity = shortMessage.opportunity;
        user = opportunity.assignedTo;
      }
      contact.update({
        shortMessagesCount: contact.shortMessages.length,
        lastShortMessage: contact.shortMessages.models[contact.shortMessages.length - 1],
        opportunitiesCount: contact.shortMessages.models.mapBy('opportunity.id').uniq().length
      });
      user.update({
        shortMessagesCount: user.shortMessages.length,
        lastShortMessage: user.shortMessages.models[user.shortMessages.length - 1]
      });
    }
  });
});