define('knowme-client/components/contact/resource-form', ['exports', 'knowme-client/mixins/us-states', 'knowme-client/mixins/controllers/status', 'knowme-client/mixins/controllers/validations/leads/contacts-form', 'knowme-client/mixins/models/ember-cp-validations-custom-properties'], function (exports, _usStates, _status, _contactsForm, _emberCpValidationsCustomProperties) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_contactsForm.default, _emberCpValidationsCustomProperties.default, _status.default, _usStates.default, {
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    eachAttribute: function (iterator) {
      ['email', 'phone', 'address'].forEach(function (attribute) {
        iterator.call(this, attribute);
      });
    },
    defaults: {
      category: {
        'b2b': { email: 'work', phone: 'work', address: 'billing' },
        'b2c': { email: 'home', phone: 'work', address: 'billing' }
      },
      contactInfo: { email: 'contactEmail', phone: 'contactPhoneNumber', address: 'contactAddress' }
    },
    contactOptions: {
      btob: {
        email: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }],
        phone: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }],
        address: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }]
      },
      btoc: {
        email: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }],
        phone: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }, { id: 'mobile', name: 'Mobile' }],
        address: [{ id: 'work', name: 'Work' }, { id: 'home', name: 'Home' }]
      }
    },
    states: [{ id: "AK", name: "Alaska" }, { id: "AL", name: "Alabama" }, { id: "AR", name: "Arkansas" }, { id: "AZ", name: "Arizona" }, { id: "CA", name: "California" }, { id: "CO", name: "Colorado" }, { id: "CT", name: "Connecticut" }, { id: "DC", name: "District of Columbia" }, { id: "DE", name: "Delaware" }, { id: "FL", name: "Florida" }, { id: "GA", name: "Georgia" }, { id: "HI", name: "Hawaii" }, { id: "IA", name: "Iowa" }, { id: "ID", name: "Idaho" }, { id: "IL", name: "Illinois" }, { id: "IN", name: "Indiana" }, { id: "KS", name: "Kansas" }, { id: "KY", name: "Kentucky" }, { id: "LA", name: "Louisiana" }, { id: "MA", name: "Massachusetts" }, { id: "MD", name: "Maryland" }, { id: "ME", name: "Maine" }, { id: "MI", name: "Michigan" }, { id: "MN", name: "Minnesota" }, { id: "MO", name: "Missouri" }, { id: "MS", name: "Mississippi" }, { id: "MT", name: "Montana" }, { id: "NC", name: "North Carolina" }, { id: "ND", name: "North Dakota" }, { id: "NE", name: "Nebraska" }, { id: "NH", name: "New Hampshire" }, { id: "NJ", name: "New Jersey" }, { id: "NM", name: "New Mexico" }, { id: "NV", name: "Nevada" }, { id: "NY", name: "New York" }, { id: "OH", name: "Ohio" }, { id: "OK", name: "Oklahoma" }, { id: "OR", name: "Oregon" }, { id: "PA", name: "Pennsylvania" }, { id: "RI", name: "Rhode Island" }, { id: "SC", name: "South Carolina" }, { id: "SD", name: "South Dakota" }, { id: "TN", name: "Tennessee" }, { id: "TX", name: "Texas" }, { id: "UT", name: "Utah" }, { id: "VA", name: "Virginia" }, { id: "VT", name: "Vermont" }, { id: "WA", name: "Washington" }, { id: "WI", name: "Wisconsin" }, { id: "WV", name: "West Virginia" }, { id: "WY", name: "Wyoming" }],
    countries: [{ id: 'us', name: 'US' }],
    disableName: false,
    disableFirstName: Ember.computed('lead.isAnonymous', function () {
      return this.get('disableName') || this.get('lead.isAnonymous');
    }),
    disableLastName: Ember.computed('lead.isAnonymous', function () {
      return this.get('disableName') || this.get('lead.isAnonymous');
    }),
    disableTitle: Ember.computed('currentContact.{isActive,primary}', 'lead.isAnonymous', function () {
      return this.get('lead.isAnonymous');
    }),
    disableStatus: Ember.computed('currentContact.{isActive,primary}', 'lead.isAnonymous', function () {
      return this.get('currentContact.isActive') && this.get('currentContact.primary');
    }),
    openCustomFields: Ember.computed('model.customAttributes', function () {
      let open = false;
      this.get('model.customAttributes').forEach(function (attr) {
        open = Ember.isPresent(attr.get('value')) || open;
      });
      return open;
    }),
    isResourceTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.customAttributes.@each.hasDirtyAttributes', 'model.customAttributes.@each.value', 'model.contactInfosContactEmails.@each.contactInfoHasChanged', 'model.contactInfosContactPhoneNumbers.@each.contactInfoHasChanged', 'model.contactInfosContactAddresses.@each.contactInfoHasChanged', 'model.contactInfos.@each.hasDirtyAttributes', 'model.contactInfosContactEmails.@each.hasDirtyAttributes', 'model.contactInfosContactPhoneNumbers.@each.hasDirtyAttributes', 'model.contactInfosContactAddresses.@each.hasDirtyAttributes', function () {
      let customAttributesHasChanged = false;
      this.get('model.customAttributes').forEach(function (attr) {
        let res = attr.get('isNew') ? Ember.isPresent(attr.get('value')) : attr.get('hasDirtyAttributes');
        customAttributesHasChanged = customAttributesHasChanged || res;
      });

      return this.get('model.hasDirtyAttributes') || customAttributesHasChanged || this.get('model.contactInfosContactEmails').isAny('contactInfoHasChanged', true) || this.get('model.contactInfosContactPhoneNumbers').isAny('contactInfoHasChanged', true) || this.get('model.contactInfosContactAddresses').isAny('contactInfoHasChanged', true) || this.get('model.contactInfos').isAny('hasDirtyAttributes', true) || this.get('model.contactInfosContactEmails').isAny('hasDirtyAttributes', true) || this.get('model.contactInfosContactPhoneNumbers').isAny('hasDirtyAttributes', true) || this.get('model.contactInfosContactAddresses').isAny('hasDirtyAttributes', true);
    }),
    disableSubmit: Ember.computed('model.validations.isInvalid', 'isResourceTreeDirty', function () {
      return this.get('model.validations.isInvalid') || !this.get('isResourceTreeDirty');
    }),
    emailsCount: Ember.computed('model.contactInfosContactEmails.length', 'model.contactInfosContactEmails.@each.isDeleted', function () {
      return Ember.isPresent(this.get('model')) ? this.get('model.contactInfosContactEmails').filterBy('isDeleted', false).length : 0;
    }),
    phonesCount: Ember.computed('model.contactInfosContactPhoneNumbers.length', 'model.contactInfosContactPhoneNumbers.@each.isDeleted', function () {
      return Ember.isPresent(this.get('model')) ? this.get('model.contactInfosContactPhoneNumbers').filterBy('isDeleted', false).length : 0;
    }),
    addressesCount: Ember.computed('model.contactInfosContactAddresses.length', 'model.contactInfosContactAddresses.@each.isDeleted', function () {
      return Ember.isPresent(this.get('model')) ? this.get('model.contactInfosContactAddresses').filterBy('isDeleted', false).length : 0;
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.get('contactFields').forEach(function (field) {
        if (field.get('internal') || !field.get('enable')) {
          return;
        }
        let customAttribute = this.get('model.customAttributes').findBy('name', field.get('key'));
        if (Ember.isPresent(customAttribute)) {
          customAttribute.setProperties({ 'position': field.get('position'), displayName: field.get('name') });
        } else {
          this.get('store').createRecord('custom-attribute', {
            customizableResource: this.get('model'),
            name: field.get('key'),
            displayName: field.get('name'),
            value: ""
            // position: field.get('position')
          });
        }
      }.bind(this));
    },
    actions: {
      submit: function () {
        let controller = this;
        let resource = controller.get('model');
        if (resource.get('primary') && resource.get('isInactive')) {
          return this.set('displayDeactivateNotAllowedModal', true);
        }
        let clearResource = function () {
          ['contactInfo', 'contactEmail', 'contactPhoneNumber', 'contactAddress'].forEach(function (modelName) {
            controller.get('store').peekAll(modelName).filter(function (_model) {
              return _model.get('isNew');
            }).invoke('deleteRecord');
          });
        };
        resource.one('didCreate', clearResource);
        resource.one('didUpdate', clearResource);
        resource.save().then(function () {
          resource.belongsTo('lead').reload();
          resource.get('contactInfos').forEach(function (contactInfo) {
            contactInfo.get('contactPhoneNumbers').reload();
          });
          controller.setProperties({ email: null, emailDirty: null, phone: null, phoneDirty: null, address: null, addressDirty: null, contact: null });
          controller.get('router').transitionTo('leads.show.contacts');
        }).catch(function (reason) {
          let errorsMessage = reason.errors.map(function (error) {
            return { ten_digit_number: 'Phone Number', email: 'Email' }[error.source.pointer.split('.').get('lastObject')] + " " + error.detail;
          });
          controller.get('flashMessages').danger(errorsMessage.join(', '), { destroyOnClick: true, timeout: 12000 });
          console.log("Errors", reason);
        });
      },
      resetChanges: function () {
        this.get('model.customAttributes').filterBy('isNew', true).invoke('deleteRecord');
        this.get('model.customAttributes').filterBy('isNew', false).invoke('rollbackAttributes');
        this.get('model').rollbackAttributes();
        this.get('model.contactInfos').forEach(function (ci) {
          ci.get('contactAddresses').forEach(function (record) {
            if (record.get('isNew')) {
              record.deleteRecord();
            } else {
              record.rollbackAttributes();
            }
          });
          ci.get('contactPhoneNumbers').forEach(function (record) {
            if (record.get('isNew')) {
              record.deleteRecord();
            } else {
              record.rollbackAttributes();
            }
          });
          ci.get('contactEmails').forEach(function (record) {
            if (record.get('isNew')) {
              record.deleteRecord();
            } else {
              record.rollbackAttributes();
            }
          });
          if (ci.get('isNew')) {
            ci.deleteRecord();
          } else {
            ci.rollbackAttributes();
          }
        });
      },
      addContactPhoneNumber: function () {
        if (this.get('phonesCount') >= 3) {
          return false;
        }
        var phone = this.get('phone'); // get value for email
        if (phone === "" || phone === null || !Ember.isEmpty(this.get('validations.attrs.phone.errors'))) {
          return false;
        }
        var contactInfos = this.get('model.contactInfos');
        var type = this.get('session.currentUser.account.customerType');
        var category = this.defaults.category[type].phone; // Select default category for b2b/b2c and phone

        var contactPhoneNumber = this.get('store').createRecord('contactPhoneNumber', { tenDigitNumber: phone }); // build contact phone number
        var contactInfo;
        if (Ember.isEmpty(contactInfos)) {
          contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
          contactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
        } else {
          contactInfo = contactInfos.findBy('category', category);
          if (!Ember.isPresent(contactInfo)) {
            contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
            contactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
          }
        }
        var contactPhoneNumbers = contactInfo.get('contactPhoneNumbers'); // get contact phone numbers from contact info
        contactPhoneNumbers.pushObject(contactPhoneNumber); // add contact email to contact emails
        this.set('phone', '');
      },
      addContactEmail: function () {
        if (this.get('emailsCount') >= 3) {
          return false;
        }
        var email = this.get('email'); // get value for email
        if (email === "" || !Ember.isEmpty(this.get('validations.attrs.email.errors'))) {
          return false;
        }
        var contactInfos = this.get('model.contactInfos');
        var type = this.get('session.currentUser.account.customerType');
        var category = this.defaults.category[type].email; // Select default category for b2b/b2c and email

        var contactEmail = this.get('store').createRecord('contactEmail', { email: email }); // build contact email
        var contactInfo;
        if (Ember.isEmpty(contactInfos)) {
          contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
          contactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
        } else {
          contactInfo = contactInfos.findBy('category', category);
          if (!Ember.isPresent(contactInfo)) {
            contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
            contactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
          }
        }
        var contactEmails = contactInfo.get('contactEmails'); // get contact emails from contact info
        contactEmails.pushObject(contactEmail); // add contact email to contact emails
        this.set('email', '');
      },
      displaySelector: function (contactResource) {
        contactResource.set('displaySelector', !contactResource.get('displaySelector'));
      },
      changeCategory: function (category, contactResource) {
        contactResource.set('displaySelector', false);
        var contactInfos = this.get('model.contactInfos');
        var contactInfo = contactInfos.findBy('category', category);
        if (!Ember.isPresent(contactInfo)) {
          contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
          contactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
        }
        contactResource.set('contactInfo', contactInfo);
      },
      confirmRemoveContactAddress: function (contactAddress) {
        this.set('addressToBeRemoved', contactAddress);
      },
      removeContactAddress: function () {
        if (Ember.isPresent(this.get('addressToBeRemoved'))) {
          let contactAddress = this.get('addressToBeRemoved');
          if (contactAddress.get('isNew')) {
            var contactInfo = contactAddress.get('contactInfo');
            var contactAddresses = contactInfo.get('contactAddresses');
            contactAddresses.removeObject(contactAddress);
          }

          contactAddress.deleteRecord();
        }
      },
      addContactAddress: function () {
        if (this.get('addressesCount') >= 2) {
          return false;
        }

        var contactInfos = this.get('model.contactInfos');
        var type = this.get('session.currentUser.account.customerType');
        var category = this.defaults.category[type].address; // Select default category for b2b/b2c and phone

        var contactAddress = this.get('store').createRecord('contactAddress', {}); // build contact address
        var contactInfo;
        if (Ember.isEmpty(contactInfos)) {
          contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
          contactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
        } else {
          contactInfo = contactInfos.findBy('category', category);
          if (!Ember.isPresent(contactInfo)) {
            contactInfo = this.get('store').createRecord('contactInfo', { category: category }); // build contact info for category
            contactInfos.pushObject(contactInfo); // add contact info to contact infos from primary contact
          }
        }
        var contactAddresses = contactInfo.get('contactAddresses'); // get contact phone numbers from contact info
        contactAddresses.pushObject(contactAddress); // add contact email to contact emails

        let index = this.get('addressesCount') - 1;
        Ember.run.later(function () {
          Ember.$(Ember.$('.street')[index]).find('input').focus();
        }, 200);
      },
      contactEmailInputKeyUp: function (contactEmail, value, event) {
        if (event.which === 8 && value === "") {
          event.preventDefault();
          contactEmail.deleteRecord();
          this.set('email', null);
          let element = event.currentTarget.parentElement.parentElement;
          Ember.$(element.nextElementSibling || element.previousElementSibling).find('input').focus(); // this is really ugly but the way to go is to create a input component that interacts with others
          if (this.get('phonesCount') + this.get('emailsCount') == 0) {
            this.get('flashMessages').warning('Contact should have at least one phone number or email', { destroyOnClick: true, timeout: 12000 });
          }
        }
      },
      contactPhoneNumberInputKeyUp: function (contactPhoneNumber, value, event) {
        if (event.which === 8 && value === "") {
          event.preventDefault();
          contactPhoneNumber.deleteRecord();
          this.set('phone', null);
          let element = event.currentTarget.parentElement.parentElement;
          Ember.$(element.nextElementSibling || element.previousElementSibling).find('input').focus(); // this is really ugly but the way to go is to create a input component that interacts with others
          if (this.get('phonesCount') + this.get('emailsCount') == 0) {
            this.get('flashMessages').warning('Contact should have at least one phone number or email', { destroyOnClick: true, timeout: 12000 });
          }
        }
      }
    }
  });
});