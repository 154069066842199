define('knowme-client/mixins/models/validations/account', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    customerType: (0, _emberCpValidations.validator)('presence', true),
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('ds-error')],
    contactEmail: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('format', { type: 'email', regex: /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i, allowBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
    contactFirstName: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    contactLastName: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    phoneNumber: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { is: 10, allowBlank: true }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true
    }), (0, _emberCpValidations.validator)('exclusion', { in: ['0000000000'], message: 'This value is not allowed.' })],
    billingPhone: [(0, _emberCpValidations.validator)('length', { is: 10, allowBlank: true }), (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      integer: true
    }), (0, _emberCpValidations.validator)('exclusion', { in: ['0000000000'], message: 'This value is not allowed.' })],
    billingEmail: [(0, _emberCpValidations.validator)('format', { type: 'email', regex: /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i, allowBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
    ccBillingEmail: [(0, _emberCpValidations.validator)('format', { type: 'email', regex: /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i, allowBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
    addressMainStreet: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    addressCity: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    addressState: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    addressCountry: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    timeZone: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: Ember.computed.bool('model.skipTimeZone')
    }),
    // timeZone: validator('presence', {presence: true, ignoreBlank: true}),
    subscription: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    currency: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    billingType: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    industry: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    termsOfService: (0, _emberCpValidations.validator)(function (value /*, options, model, attribute*/) {
      let result = 'You need to accept the Terms and Conditions';
      if (value) {
        result = true;
      }
      return result;
    }, {
      dependentKeys: ['model.skip_terms_of_service_validation', 'model.agreementAcceptedAt'],
      disabled: Ember.computed('model.{skip_terms_of_service_validation,agreementAcceptedAt}', function () {
        return this.get('model.skip_terms_of_service_validation') || Ember.isPresent(this.get('model.agreementAcceptedAt'));
      })
    }),
    settings: (0, _emberCpValidations.validator)('has-many')
  });
});