define('knowme-client/serializers/form-touch-point', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    serialize: function () /*snapshot, options*/{
      let json = this._super.apply(this, arguments);
      delete json.email;
      return json;
    }
  });
});