define('knowme-client/helpers/how-to-intl', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    compute([name, key]) {
      return this.get('intl').t(`how_to.${name}.${key}`, {
        Customers: this.get('accountService.customersText'),
        customers: this.get('accountService.customersText').toLowerCase(),
        Customer: this.get('accountService.customerText'),
        customer: this.get('accountService.customerText').toLowerCase()
      });
    }
  });
});