define('knowme-client/components/short-messages/table-body/opportunity-name-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    classNames: ['opp-name']
  });
});