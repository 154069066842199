define('knowme-client/serializers/contact-phone-number', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      contactInfo: { serialize: false }
    },
    serialize() /*snapshot, options*/{
      let json = this._super(...arguments);
      if (json.masked) {
        delete json.ten_digit_number;
      }
      return json;
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.relationships = hash.relationships || {};
      hash.relationships['contact-info'] = hash.relationships['contact-info'] || {};
      hash.relationships['incentives-contacts'] = hash.relationships['incentives-contacts'] || {};
      hash.relationships['review-links-contacts'] = hash.relationships['review-links-contacts'] || {};
      hash.relationships['contact-info'].links = hash.relationships.links || {};
      hash.relationships['incentives-contacts'].links = hash.relationships.links || {};
      hash.relationships['review-links-contacts'].links = hash.relationships.links || {};
      hash.relationships['contact-info'].links.related = Ember.getOwner(this).lookup('adapter:contact-info').buildURL('contactInfo', hash.relationships['contact-info'].data.id, null, 'findRecord', null);
      hash.relationships['incentives-contacts'].links.related = Ember.getOwner(this).lookup('adapter:incentives-contact').buildURL('incentives-contact', null, null, 'findMany', { target_id: hash.id, target_type: 'ContactPhoneNumber', paginate: false });
      hash.relationships['review-links-contacts'].links.related = Ember.getOwner(this).lookup('adapter:review-links-contact').buildURL('review-links-contact', null, null, 'findMany', { target_id: hash.id, target_type: 'ContactPhoneNumber', paginate: false });
      return this._super.apply(this, arguments);
    }
  });
});