define('knowme-client/models/communication', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend({
		lead: (0, _relationships.belongsTo)('lead', { async: true, inverse: 'communications' }),
		opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
		communicationResource: (0, _relationships.belongsTo)('communication-resource', { async: true, polymorphic: true, inverse: 'communication' }),
		activity: (0, _relationships.belongsTo)('lead-activity', { async: true }),
		resourceType: (0, _attr.default)(),
		resourceId: (0, _attr.default)(),
		createdAt: (0, _attr.default)('date'),
		updatedAt: (0, _attr.default)('date'),
		communicationResourceContactId: Ember.computed.alias('communicationResource.contact.id')
	});
});