define('knowme-client/routes/chats', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    store: Ember.inject.service(),
    kind: null,
    queryParams: {
      query: { refreshModel: true },
      kind: { refreshModel: true }
    },
    model: function (params, transition) {
      if (transition.targetName !== 'chats.show') {
        params.limit = 1;
      }
      this.set('kind', params.kind);
      return this.get('store').query('room', params);
    },
    afterModel: function (model, transition) {
      if (model.get('length') === 0 && Ember.isBlank(this.get('kind'))) {
        return this.transitionTo('chats', { queryParams: { kind: 'general' } });
      }
      if (transition.targetName === 'chats.show' || model.get('length') === 0) {
        let users = this.get('store').query('user', { paginate: false, status: 'active', sort_by: 'name', order: 'asc' });
        let chatTouchPoints = this.get('store').query('chat-touch-point', { paginate: false, status: 'active', sort_by: 'name', order: 'asc' });
        let promises = {
          users: users,
          chatTouchPoints: chatTouchPoints
        };
        return Ember.RSVP.hash(promises).then(function (results) {
          this.set('controllerData', results);
        }.bind(this));
      } else {
        return this.transitionTo('chats.show', model.get('firstObject.id'), { queryParams: { kind: model.get('firstObject.kind') } });
      }
    },
    setupController: function (controller /*, model*/) {
      this._super.apply(this, arguments);
      controller.setProperties(this.get('controllerData'));
      controller.setupRoomConsumer();
      this.set('controllerData', {});
    },
    resetController: function (controller /*, isExiting, transition*/) {
      this._super(...arguments);
      if (Ember.isPresent(controller.get('roomConsumer'))) {
        controller.get('roomConsumer').destroy();
      }
    },
    actions: {
      refreshModel: function () {
        this.refresh();
      },
      leaveGroup: function (room) {
        room.get('roomsUsers').then(function (roomsUsers) {
          let roomsUser = roomsUsers.findBy('user.id', this.get('session.currentUser.id'));
          this.controller.set('confirmLeaveRoomModal', true);
          this.controller.set('roomToBeLeaved', roomsUser);
        }.bind(this));
      },
      deleteGroup: function (room) {
        this.controller.set('confirmRemoveRoomModal', true);
        this.controller.set('roomToBeRemoved', room);
      }
    }
  });
});