define('knowme-client/models/chat-message', ['exports', 'ember-data/attr', 'ember-data/relationships', 'ember-data/model', 'moment', 'knowme-client/mixins/dirty'], function (exports, _attr, _relationships, _model, _moment, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_dirty.default, {
    owner: (0, _relationships.belongsTo)('user', { async: true }),
    room: (0, _relationships.belongsTo)('room', { async: true, inverse: 'messages' }),
    originalMessage: (0, _relationships.belongsTo)('chat-message', { async: true, inverse: 'messages' }),
    messages: (0, _relationships.hasMany)('chat-message', { async: true, inverse: 'originalMessage' }),
    ownerType: (0, _attr.default)(),
    body: (0, _attr.default)(),
    status: (0, _attr.default)(),
    attachments: (0, _relationships.hasMany)('chat-message-attachment', { async: true }),
    files: (0, _attr.default)('json'),
    versions: (0, _attr.default)('json'),
    isVoiceMessage: Ember.computed('attachments.firstObject.isAudio', function () {
      return this.get('attachments.length') === 1 && this.get('attachments.firstObject.isAudio');
    }),
    isAttachmentMessage: Ember.computed('attachments.length', 'body', function () {
      return this.get('attachments.length') > 0 && Ember.isBlank(this.get('body'));
    }),
    humanizedDate: Ember.computed('createdAt', function () {
      let date = (0, _moment.default)(this.get('createdAt'));
      let result = date.format('MM/DD/YY');
      let today = (0, _moment.default)();
      let diffDays = today.diff(date, 'days');
      switch (diffDays) {
        case 0:
          result = date.format('h:mm a');
          break;
        case 1:
          result = 'Yesterday';
          break;
        case 2:
        case 3:
        case 5:
        case 6:
        // result = date.format('ddd');
        // break;
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
          result = 'a weak ago';
          break;
      }
      return result;
    }),
    isEdited: (0, _attr.default)('boolean', { defaultValue: false }),
    isDeleted: (0, _attr.default)('boolean', { defaultValue: false }),
    createdDay: Ember.computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('MM/DD/YY');
    }),
    isDraft: Ember.computed('sentAt', function () {
      return Ember.isBlank(this.get('sentAt'));
    }),
    preview: Ember.computed('body', 'isVoiceMessage', 'attachments.length', 'originalMessage.id', 'isDraft', function () {
      let preview = this.get('body');
      if (Ember.isBlank(preview) && this.get('attachments.length') > 0) {
        if (this.get('isVoiceMessage')) {
          preview = "<i class='fas fa-microphone'></i> voice message";
        } else {
          preview = "<i class='las la-file-medical'></i> " + this.get('attachments.firstObject.filename');
        }
      }
      if (Ember.isBlank(preview) && Ember.isPresent(this.get('originalMessage'))) {
        return "<i class='fa fa-quote-left'></i> quoted messsage";
      }
      if (this.get('isDraft')) {
        preview = `[draft] ${preview}`;
      }
      return preview;
    }),
    deletedAt: (0, _attr.default)('date'),
    sentAt: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date')
  });
});