define('knowme-client/serializers/chat-touch-point-member', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    serialize: function (snapshot /*, options*/) {
      var json = this._super(...arguments);
      if (snapshot.record.get('isDeleted')) {
        json._destroy = true;
      }
      return json;
    }
  });
});