define('knowme-client/controllers/reviews/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReviewsController;

  ReviewsController = Ember.Controller.extend({
    queryParams: ['code'],
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedScore: null,
    isSaving: false,
    satisfactionOptions: ['very_dissatisfied', 'dissatisfied', 'neutral', 'satisfied', 'very_satisfied'],
    isResourceTreeDirty: Ember.computed('contact.hasDirtyAttributes', 'contact.contactInfosContactEmails.@each.contactInfoHasChanged', 'contact.contactInfos.@each.hasDirtyAttributes', 'contact.contactInfosContactEmails.@each.hasDirtyAttributes', function () {
      return this.get('contact.hasDirtyAttributes') || this.get('contact.contactInfosContactEmails').isAny('contactInfoHasChanged', true) || this.get('contact.contactInfos').isAny('hasDirtyAttributes', true) || this.get('contact.contactInfosContactEmails').isAny('hasDirtyAttributes', true);
    }),
    disableSaveButton: Ember.computed('contact.validations.isInvalid', 'isResourceTreeDirty', function () {
      return this.get('contact.validations.isInvalid') || !this.get('isResourceTreeDirty');
    }),
    actions: {
      selectScore: function (score) {
        this.set('selectedScore', score);
      },
      saveReviews: function (feedback) {
        let controller = this;
        let model = this.get('model');
        let email = controller.get('contact.contactInfos.firstObject.contactEmails.firstObject.email');
        this.set('isSaving', true);
        let contact = null;
        let lead = null;
        controller.get('lead').save().then(function (_lead) {
          lead = _lead;
          return controller.get('store').query('contact', { query: email, lead_id: lead.get('id') });
        }).then(function (contacts) {
          return contacts.get('firstObject');
        }).then(function (_contact) {
          contact = _contact;
          return controller.get('store').query('customer-experience', { contact_id: contact.get('id'), lead_id: lead.get('id') });
        }).then(function (customerExperiences) {
          if (customerExperiences.get('length') > 0) {
            controller.set('model', customerExperiences.get('firstObject'));
            controller.set('model.satisfaction', controller.get('selectedScore'));
          } else {
            model.setProperties({
              contact: contact,
              satisfaction: controller.get('selectedScore'),
              opportunity: contact.get('lead').get('opportunities.firstObject')
            });
          }
          return controller.get('model').save();
        }).then(function () {
          controller.set('isSaving', false);
        }).catch(function (response) {
          controller.set('isSaving', false);
          controller.get('flashMessages').warning(response.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
        });
      }
    }
  });

  exports.default = ReviewsController;
});