define('knowme-client/controllers/account/settings/products', ['exports', 'knowme-client/mixins/controllers/status', 'knowme-client/mixins/controllers/product-types', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/queryable'], function (exports, _status, _productTypes, _pagination, _queryable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_status.default, _pagination.default, _queryable.default, _productTypes.default, {
    onStatusChanged: Ember.observer('status', function () {
      if (Ember.isPresent(this.get('page'))) {
        this.set('page', null);
      }
    }),
    actions: {
      destroy: function (product) {
        product.destroyRecord().then(function () {
          this.transitionToRoute('account.settings.products');
          this.get('target._routerMicrolib').refresh();
        }.bind(this)).catch(function () /*error*/{
          product.rollbackAttributes();
        });
      }
    }
  });
});