define('knowme-client/serializers/lead', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadSerializer;

  LeadSerializer = _application.default.extend({
    attrs: {
      organization: { embedded: 'always' },
      customAttributes: { embedded: 'always' },
      opportunities: { embedded: 'always' },
      contacts: { embedded: 'always' }
    },
    keyForRelationship(key, relationship, method) {
      if ((key === 'organization' || key === 'customAttributes') && method === 'serialize') {
        return `${Ember.String.underscore(key)}_attributes`;
      }
      return this._super(...arguments);
    },
    serialize: function (snapshot /*, options*/) {
      let json = this._super.apply(this, arguments);

      if (snapshot.record.get('isbtoc')) {
        delete json.organization_attributes;
      }

      return json;
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.links = {
        // communications: getOwner(this).lookup('adapter:communication').buildURL('communication', null, Ember.Object.create({belongsTo: function() { return hash.id; }}), 'findMany', {})
        unassignedCommunications: Ember.getOwner(this).lookup('adapter:communication').buildURL('communication', null, null, 'findMany', { lead_id: hash.id, filter: 'unassigned' })
      };
      return this._super.apply(this, arguments);
    },
    _generateSerializedHasMany(snapshot, relationship) {
      var hasMany = snapshot.hasMany(relationship.key);
      var manyArray = Ember.A(hasMany);
      var ret = new Array();

      for (var i = 0; i < manyArray.length; i++) {
        var embeddedSnapshot = manyArray[i];

        if (['opportunity'].includes(embeddedSnapshot.modelName) && Ember.isEmpty(Object.keys(embeddedSnapshot.changedAttributes()))) {
          continue;
        }
        var embeddedJson = embeddedSnapshot.serialize({ includeId: true });
        if (embeddedSnapshot.record.get('isDeleted')) {
          embeddedJson['_destroy'] = true;
        }
        this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
        ret[i] = embeddedJson;
      }

      return ret;
    }
  });

  exports.default = LeadSerializer;
});