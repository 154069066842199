define('knowme-client/mixins/controllers/components/date-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: ['from_date', 'to_date', 'period'],
    from_date: null,
    to_date: null,
    period: null,
    actions: {
      updateDate(dateKey, value, period) {
        if (period == 'custom') {
          this.set('period', period);
        } else {
          this.set('period', null);
        }
        this.set('page', null);
        this.set(dateKey, value);
      }
    }
  });
});