define('knowme-client/helpers/includes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.includes = includes;
  function includes(params /*, hash*/) {
    let arr = params[0];
    let item = params[1];
    if (arr) {
      return arr.includes(item);
    } else {
      return false;
    }
  }

  exports.default = Ember.Helper.helper(includes);
});