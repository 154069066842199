define('knowme-client/components/lead/unassigned-communications', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		// websocket: Ember.inject.service(),
		cookies: Ember.inject.service(),
		session: Ember.inject.service(),
		store: Ember.inject.service(),
		routing: Ember.inject.service('-routing'),
		communicationsSorting: ['createdAt:desc'],
		sortedCommunications: Ember.computed.sort('communications', 'communicationsSorting'),
		didInsertElement: function () {
			this._super();
			// var websocket = this.get('websocket');
			// websocket.connect();
			// var channel = websocket.dispatcher.subscribe_private(`lead_${this.get('lead.id')}_communications`);
			// channel.bind('created', this.receiveCommunication.bind(this));
		},
		receiveCommunication: function (message) {
			this.get('store').push(this.get('store').normalize('communication', message));
		},
		actions: {
			assign: function (communication) {
				let communicationResource = communication.get('communicationResource');
				communicationResource.set('opportunity', communication.get('opportunity'));
				communicationResource.save().then(function (communicationResource) {
					this.get('routing').transitionTo('leads.show.opportunities.show', [this.get('lead'), communicationResource.get('opportunity')]);
					communication.get('opportunity.activities').reload();
					communication.get('lead.communications').reload();
				}.bind(this));
			}
		}
	});
});