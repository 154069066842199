define('knowme-client/serializers/call', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      opportunity: { deserialize: 'id', serialize: 'id' },
      contact: { deserialize: 'id', serialize: false },
      contactPhoneNumber: { deserialize: 'id', serialize: false },
      user: { deserialize: 'id', serialize: false },
      did: { deserialize: 'id', serialize: false },
      assignedTo: { deserialize: 'id', serialize: 'id' }
    },
    keyForRelationship(key, relationship, method) {
      let models = ['contact', 'user', 'assignedTo', 'did'];
      if (models.includes(key) && (method === 'deserialize' || method === 'serialize')) {
        return `${Ember.String.underscore(key)}_id`;
      }
      return this._super(...arguments);
    },
    serialize: function () /*snapshot, options*/{
      var json = this._super(...arguments);
      if (Ember.isBlank(json.opportunity_id)) {
        delete json.opportunity_id;
      }
      return json;
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.links = {
        opportunity: Ember.getOwner(this).lookup('adapter:opportunity').buildURL('opportunity', hash.opportunity_id, null, 'find', {}),
        contact: Ember.getOwner(this).lookup('adapter:contact').buildURL('contact', hash.id, null, 'find', {}),
        did: Ember.getOwner(this).lookup('adapter:did').buildURL('did', hash.id, null, 'find', {}),
        listeners: Ember.getOwner(this).lookup('adapter:user').buildURL('user', null, null, 'findMany', { call_id: hash.id, paginate: false })
      };
      if (Ember.isPresent(hash.assigned_to_id)) {
        hash.links.assignedTo = Ember.getOwner(this).lookup('adapter:user').buildURL('user', null, null, 'find', { call_id: hash.assigned_to_id, paginate: false });
      }
      return this._super.apply(this, arguments);
    }
  });
});