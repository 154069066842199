define('knowme-client/controllers/email-templates/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function () {
      let _this = this;
      this._super(...arguments);
      Ember.run.schedule("afterRender", this, function () {
        Ember.$('.form-control.name').focus();
      });
    }
  });
});