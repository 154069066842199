define('knowme-client/models/stripe-account', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    status: (0, _attr.default)(),
    accountLink: (0, _attr.default)(),
    payoutsEnabled: (0, _attr.default)(),
    disabledReason: (0, _attr.default)('json'),
    isActive: Ember.computed.equal('status', 'active')
  });
});