define('knowme-client/mirage/serializers/opportunity', ['exports', 'knowme-client/mirage/serializers/active-model-serializer'], function (exports, _activeModelSerializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelSerializer.default.extend({
    relationships: ['orders', 'assignedTo', 'salesAction', 'ad', 'salesStage', 'customAttributes'], //, 'lastActivity'],
    include: ['orders', 'salesAction', 'salesStage', 'customAttributes'],
    _hashForModel(model, removeForeignKeys, didSerialize = {}) {
      let attrs = this._attrsForModel(model);

      let newDidSerialize = Object.assign({}, didSerialize);
      newDidSerialize[model.modelName] = newDidSerialize[model.modelName] || {};
      newDidSerialize[model.modelName][model.id] = true;

      this.getKeysForIncluded().forEach(key => {
        let associatedResource = model[key];
        if (associatedResource && !Ember.get(newDidSerialize, `${associatedResource.modelName}.${associatedResource.id}`)) {
          let [associatedResourceHash] = this.getHashForResource(associatedResource, true, newDidSerialize, true);
          let formattedKey = this.keyForEmbeddedRelationship(key);
          attrs[formattedKey] = associatedResourceHash;

          if (this.isModel(associatedResource)) {
            let fk = `${Ember.String.camelize(key)}Id`;
            delete attrs[fk];
          }
        }
      });

      attrs = this._maybeAddAssociationIds(model, attrs);

      for (var key in attrs) {
        if (key.includes('_id') && !['ad_id', 'assigned_to_id', 'last_activity_id', 'lead_id', 'last_short_message_id'].includes(key)) {
          delete attrs[key];
        }
      }

      return attrs;
    }
  });
});