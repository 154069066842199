define('knowme-client/components/reminders/reminder-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['fade', 'inOrHide'],
    fade: Ember.computed('isFirstRecord', function () {
      if (this.get('isFirstRecord')) {
        return 'fade';
      }
    }),
    inOrHide: Ember.computed('user.remindersDisplayed', 'isFirstRecord', function () {
      if (this.get('isFirstRecord') || this.get('user.remindersDisplayed')) {
        return 'in';
      } else {
        return 'hide';
      }
    }),
    isRecordLoaded: Ember.computed('sentReminder.appointmentDate', function () {
      return Ember.isPresent(this.get('sentReminder.appointmentDate'));
    })
  });
});