define('knowme-client/routes/communications', ['exports', 'moment', 'lodash'], function (exports, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      contact_id: { refreshModel: true },
      opportunity_id: { refreshModel: true },
      filter: { refreshModel: true }
    },
    model(params) {
      return this.store.query('communication', this.processParams(params));
    },
    afterModel(model, transition) {
      let promises = { communications: model.toArray() };
      if (Ember.isPresent(transition.queryParams.contact_id)) {
        promises['contact'] = this.store.findRecord('contact', transition.queryParams.contact_id);
      }
      return Ember.RSVP.hash(promises).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments); // Call _super for default behavior
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', null);
    },
    processParams(_params) {
      let params = Ember.copy(_params);

      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    }
  });
});