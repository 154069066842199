define('knowme-client/services/features', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    account: Ember.computed.alias('session.account'),
    plan: Ember.computed.alias('account.subscription.plan'),
    activeSettings: Ember.computed.alias('account.activeSettings'),
    opportunityCustomFields: Ember.computed.alias('account.customOpportunityFields'),
    // Flags
    marketingOptimizerSidebar: Ember.computed.alias('plan.isMarketingOptimizer'),
    isSalesPerson: Ember.computed.alias('session.currentUser.isSalesPerson'),
    filterByChannelAvailable: Ember.computed.or('plan.{isMarketingOptimizer,isSalesAcceleratorAndMarketingOptimizer,isKnowmeIQ}'),
    emailUnifiedCommAvailable: Ember.computed('session.currentUser.subscription.plan', function () {
      return true;
    }),
    accountSettingsAvailable: Ember.computed.or('session.currentUser.isMasterAdmin'),
    accountBillingTypeAvailable: Ember.computed.or('session.currentUser.isMasterAdmin'),
    costFieldAvailable: Ember.computed.or('session.currentUser.{isMasterManager,hasAdminRole}'),
    activityUserAvailable: Ember.computed.not('session.currentUser.isSalesPerson'),
    knowmeIQAvailable: Ember.computed.not('isBasicCrmOrKnowmeIQStarter'),
    isMobileAppReady: true,
    usersAvailable: Ember.computed('activeSettings.{usersCount,usersLimit}', function () {
      return this.get('activeSettings.usersCount') < this.get('activeSettings.usersLimit');
    }),
    oneUserAvailable: Ember.computed('activeSettings.{usersCount,usersLimit}', function () {
      return this.get('activeSettings.usersLimit') - this.get('activeSettings.usersCount') === 1;
    }),
    callTouchPointsAvailable: Ember.computed('session.account.subscription.plan.key', function () {
      return this.available(this.get('plan.key'), 'callTouchPoints', 'create');
    }),
    formTouchPointsAvailable: Ember.computed('session.account.subscription.plan.productKey', function () {
      return this.available(this.get('plan.productKey'), 'formTouchPoints', 'create');
    }),
    outboundCallsAvailable: Ember.computed('session.account.subscription.plan.productKey', 'isBasicCrmOrKnowmeIQStarter', function () {
      return !this.get('isBasicCrmOrKnowmeIQStarter') && this.available(this.get('plan.productKey'), 'calls', 'create');
    }),
    unifiedCommunicationsAvailable: Ember.computed('plan.productKey', 'formTouchPointsAvailable', 'isBasicCrmOrKnowmeIQStarter', function () {
      return this.available(this.get('plan.productKey'), 'calls', 'create') || this.get('formTouchPointsAvailable') || this.get('plan.productKey') === 'marketing_optimizer_crm' || this.get('isBasicCrmOrKnowmeIQStarter');
    }),
    inboundCallsAvailable: Ember.computed('session.account.subscription.plan.productKey', function () {
      return this.available(this.get('plan.productKey'), 'calls', 'create');
    }),
    uniqueLeadsReportsAvailable: Ember.computed('session.account.subscription.plan.productKey', 'session.currentUser.{isManager,hasAdminRole}', function () {
      return this.available(this.get('plan.productKey'), 'showReports', 'uniqueLeads') && (this.get('session.currentUser.isManager') || this.get('session.currentUser.hasAdminRole'));
    }),
    reportScheduleAvailable: Ember.computed('session.account.subscription.plan.productKey', 'session.currentUser.{isManager,hasAdminRole}', function () {
      return this.available(this.get('plan.productKey'), 'showReports', 'uniqueLeads') && (this.get('session.currentUser.isManager') || this.get('session.currentUser.hasAdminRole'));
    }),
    outboundCallsReportsAvailable: Ember.computed('session.account.subscription.plan.productKey', 'session.currentUser.{isManager,hasAdminRole}', function () {
      return this.available(this.get('plan.productKey'), 'showReports', 'outboundCalls') && (this.get('session.currentUser.isManager') || this.get('session.currentUser.hasAdminRole'));
    }),
    usersSectionAvailable: Ember.computed('isBasicCrmOrKnowmeIQStarter', 'session.currentUser.{isManager,hasAdminRole}', function () {
      return this.get('isBasicCrmOrKnowmeIQStarter') && this.get('session.currentUser.isManager') || this.get('session.currentUser.hasAdminRole');
    }),
    customerExperienceDashboardAvailable: Ember.computed('session.account.subscription.plan.productKey', function () {
      return this.available(this.get('plan.productKey'), 'dashboards', 'customersExperience');
    }),
    customerExperienceSectionAvailable: Ember.computed('customersExperienceToolsAvailable', function () {
      return this.get('customersExperienceToolsAvailable');
    }),
    customersExperienceToolsAvailable: Ember.computed('session.account.subscription.plan.key', function () {
      return this.available(this.get('plan.key'), 'customersExperienceTools', 'available');
    }),
    emailTouchPointsAvailable: Ember.computed('customersExperienceToolsAvailable', function () {
      return this.get('customersExperienceToolsAvailable');
    }),
    surveyButtonAvailable: Ember.computed('customersExperienceToolsAvailable', function () {
      return this.get('customersExperienceToolsAvailable');
    }),
    onlyAdminRoleAvailable: Ember.computed('isBasicCrmOrKnowmeIQStarter', function () {
      return this.get('isBasicCrmOrKnowmeIQStarter');
    }),
    emailIntegrationHasBeenCompleted: Ember.computed('session.authentications.length', function () {
      return this.get('session.authentications.length') > 0;
    }),
    isBasicCrm: Ember.computed.alias('session.account.subscription.plan.isBasicCrm'),
    isKnowmeIQStarter: Ember.computed.alias('session.account.subscription.plan.isKnowmeIQStarter'),
    isKnowmeIQStarterUnlimited: Ember.computed.alias('session.account.subscription.plan.isKnowmeIQStarterUnlimited'),
    isBasicCrmOrKnowmeIQStarter: Ember.computed.or('isBasicCrm', 'isKnowmeIQStarter', 'isKnowmeIQStarterUnlimited'),
    isMarketingUser: Ember.computed('plan.{isMarketingOptimizer,isSalesAcceleratorAndMarketingOptimizer,isKnowmeIQ}', 'isKnowmeIQStarter', function () {
      return this.get('plan.isMarketingOptimizer') || this.get('plan.isSalesAcceleratorAndMarketingOptimizer') || this.get('plan.isKnowmeIQ') && !this.get('isKnowmeIQStarter');
    }),
    automaticCommunicationsViewAvailable: Ember.computed.or('plan.{isMarketingOptimizer,isSalesAccelerator,isSalesAcceleratorAndMarketingOptimizer,isKnowmeIQ}'),
    campaignsSectionAvailable: Ember.computed.or('session.currentUser.{hasAdminRole,isManager,isSalesPerson}'),
    campaignsAutomatedCaptureAvailable: Ember.computed('plan', 'isSalesPerson', 'isBasicCrm', function () {
      return !this.get('isBasicCrm') && !this.get('plan.isSalesAccelerator') && !this.get('isSalesPerson');
    }),
    leadActionsAvailable: Ember.computed('plan', 'emailIntegrationHasBeenCompleted', 'isBasicCrmOrKnowmeIQStarter', function () {
      return !this.get('isBasicCrmOrKnowmeIQStarter') || this.get('emailIntegrationHasBeenCompleted');
    }),
    reportsLinksAvailable: Ember.computed('isBasicCrmOrKnowmeIQStarter', 'isSalesPerson', function () {
      return !this.get('isBasicCrmOrKnowmeIQStarter') && !this.get('isSalesPerson');
    }),
    channelAvailable: Ember.computed.or('callTouchPointsAvailable', 'formTouchPointsAvailable'),
    costAvailable: Ember.computed.or('callTouchPointsAvailable', 'formTouchPointsAvailable'),
    administrationSectionAvailable: Ember.computed.or('session.currentUser.hasAdminRole', 'session.currentUser.isManager'),
    clientInformationSectionAvailable: Ember.computed.or('session.currentUser.hasAdminRole'),
    importantNoticeAvailable: Ember.computed.or('session.currentUser.isAdmin', 'session.currentUser.isClientAdmin', 'session.currentUser.isManager'),
    productsSectionAvailable: Ember.computed.or('session.currentUser.{hasAdminRole,isManager}'),
    salesStagesSectionAvailable: Ember.computed.or('session.currentUser.hasAdminRole', 'session.currentUser.isManager'),
    incentivesSectionAvailable: Ember.computed('session.currentUser.{hasAdminRole,isManager}', 'isBasicCrmOrKnowmeIQStarter', function () {
      return !this.get('isBasicCrmOrKnowmeIQStarter') && (this.get('session.currentUser.hasAdminRole') || this.get('session.currentUser.isManager'));
    }),
    reviewsSectionAvailable: Ember.computed('session.currentUser.{hasAdminRole,isManager}', 'isBasicCrmOrKnowmeIQStarter', function () {
      return !this.get('isBasicCrmOrKnowmeIQStarter') && (this.get('session.currentUser.hasAdminRole') || this.get('session.currentUser.isManager'));
    }),
    contactFieldsSectionAvailable: Ember.computed.or('session.currentUser.hasAdminRole', 'session.currentUser.isManager'),
    organizationFieldsSectionAvailable: Ember.computed('session.currentUser.{hasAdminRole,isManager,account.isbtob}', function () {
      return this.get('session.currentUser.account.isbtob') && (this.get('session.currentUser.hasAdminRole') || this.get('session.currentUser.isManager'));
    }),
    opportunityFieldsSectionAvailable: Ember.computed.or('session.currentUser.hasAdminRole', 'session.currentUser.isManager'),
    salesActionsSectionAvailable: Ember.computed.or('session.currentUser.hasAdminRole', 'session.currentUser.isManager'),
    alertsSectionAvailable: Ember.computed('session.currentUser.{hasAdminRole}', 'session.account.subscription.plan.key', 'isBasicCrmOrKnowmeIQStarter', function () {
      return this.get('session.currentUser.hasAdminRole') && !this.get('isBasicCrm');
    }),
    reminderSectionAvailable: Ember.computed.alias('session.isAuthenticated'),
    settingsSectionAvailable: Ember.computed.or('productsSectionAvailable', 'salesStagesSectionAvailable', 'salesActionsSectionAvailable', 'emailsSectionAvailable'),
    dashboardSectionAvailable: Ember.computed.or('session.currentUser.hasAdminRole', 'session.currentUser.isManager'),
    totalProfitAvailable: Ember.computed.or('session.currentUser.hasAdminRole', 'session.currentUser.isManager'),
    communicationsServiceAvailable: Ember.computed.alias('account.communicationsServiceAvailable'),
    available(planKey, resource, action) {
      if (Ember.isBlank(planKey)) {
        return false;
      }
      let planName = planKey.split('.')[0];

      let features = this.get('features');
      return features[planName.camelize()][resource][action];
    },
    assignedToAvailable: Ember.computed('isSalesPerson', 'account.singleUser', function () {
      return !this.get('isSalesPerson') && !this.get('account.singleUser');
    }),
    features: {
      basicCrm: {
        users: {
          create: true
        },
        calls: {
          create: false
        },
        shortMessages: {
          create: false
        },
        emails: {
          create: false
        },
        campaigns: {
          create: true
        },
        callTouchPoints: {
          create: false
        },
        formTouchPoints: {
          create: false
        },
        customersExperienceTools: {
          available: false
        },
        dashboards: {
          leads: true,
          customers: true,
          campaigns: true,
          reports: true,
          customersExperience: false,
          howTo: true
        },
        showReports: {
          leadsPipeline: true,
          customersPipeline: true,
          customersRevenue: true,
          uniqueLeads: true,
          outboundCalls: false
        },
        reports: []
      },
      salesAcceleratorCrm: {
        users: {
          create: true
        },
        calls: {
          create: true
        },
        shortMessages: {
          create: true
        },
        emails: {
          create: true
        },
        campaigns: {
          create: true
        },
        callTouchPoints: {
          create: false
        },
        formTouchPoints: {
          create: false
        },
        customersExperienceTools: {
          available: false
        },
        dashboards: {
          leads: true,
          customers: true,
          campaigns: true,
          reports: true,
          customersExperience: false,
          howTo: true
        },
        showReports: {
          leadsPipeline: true,
          customersPipeline: true,
          customersRevenue: true,
          uniqueLeads: true,
          outboundCalls: true
        },
        reports: ['callsCountByUser']
      },
      marketingOptimizerCrm: {
        users: {
          create: true
        },
        calls: {
          create: false
        },
        shortMessages: {
          create: false
        },
        emails: {
          create: false
        },
        campaigns: {
          create: true
        },
        callTouchPoints: {
          create: true
        },
        formTouchPoints: {
          create: true
        },
        customersExperienceTools: {
          available: false
        },
        dashboards: {
          leads: true,
          customers: true,
          campaigns: true,
          reports: true,
          customersExperience: false,
          howTo: true
        },
        showReports: {
          leadsPipeline: true,
          customersPipeline: true,
          customersRevenue: true,
          uniqueLeads: true,
          outboundCalls: false
        },
        reports: ['callsByCampaignGraph', 'callsByTimeOfTheDayGraph', 'callsByDayOfTheWeek']
      },
      salesAcceleratorMarketingOptimizerCrm: {
        users: {
          create: true
        },
        calls: {
          create: true
        },
        shortMessages: {
          create: true
        },
        emails: {
          create: true
        },
        campaigns: {
          create: true
        },
        callTouchPoints: {
          create: true
        },
        formTouchPoints: {
          create: true
        },
        customersExperienceTools: {
          available: true
        },
        dashboards: {
          leads: true,
          customers: true,
          campaigns: true,
          reports: true,
          customersExperience: true,
          howTo: true
        },
        showReports: {
          leadsPipeline: true,
          customersPipeline: true,
          customersRevenue: true,
          uniqueLeads: true,
          outboundCalls: true
        },
        reports: ['callsCountByUser', 'callsByCampaignGraph', 'callsByTimeOfTheDayGraph', 'callsByDayOfTheWeek']
      },
      customerSatisfaction: {
        users: {
          create: true
        },
        calls: {
          create: false
        },
        shortMessages: {
          create: false
        },
        emails: {
          create: false
        },
        campaigns: {
          create: true
        },
        callTouchPoints: {
          create: true
        },
        formTouchPoints: {
          create: true
        },
        customersExperienceTools: {
          available: true
        },
        dashboards: {
          leads: true,
          customers: true,
          campaigns: true,
          reports: true,
          customersExperience: true,
          howTo: true
        },
        showReports: {
          leadsPipeline: true,
          customersPipeline: true,
          customersRevenue: true,
          uniqueLeads: true,
          outboundCalls: false
        },
        reports: ['callsByCampaignGraph', 'callsByTimeOfTheDayGraph', 'callsByDayOfTheWeek']
      },
      knowmeIq: {
        users: {
          create: true
        },
        calls: {
          create: true
        },
        shortMessages: {
          create: true
        },
        emails: {
          create: true
        },
        campaigns: {
          create: true
        },
        callTouchPoints: {
          create: true
        },
        formTouchPoints: {
          create: true
        },
        customersExperienceTools: {
          available: true
        },
        dashboards: {
          leads: true,
          customers: true,
          campaigns: true,
          reports: true,
          customersExperience: true,
          howTo: true
        },
        showReports: {
          leadsPipeline: true,
          customersPipeline: true,
          customersRevenue: true,
          uniqueLeads: true,
          outboundCalls: true
        },
        reports: ['callsCountByUser', 'callsByCampaignGraph', 'callsByTimeOfTheDayGraph', 'callsByDayOfTheWeek']
      }
    }
  });
});