define('knowme-client/mirage/models/ad', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    campaign: (0, _emberCliMirage.belongsTo)(),
    attrs: ['id', 'name', 'pipeline', 'revenue', 'channel', 'cost', 'cost_per_lead', 'created_at', 'customers_count', 'end_date', 'leads_count', 'roi', 'start_date', 'status', 'touch_point', 'updated_at', 'campaign_id']
  });
});