define('knowme-client/serializers/message', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let MessageSerializer;

  MessageSerializer = _jsonApi.default.extend({
    attrs: {
      attachments: { deserialize: 'id', serialize: 'id' },
      contact: { deserialize: 'id', serialize: 'id' },
      opportunity: { deserialize: 'id', serialize: 'id' },
      lead: { deserialize: 'id', serialize: 'id' },
      user: { deserialize: 'id', serialize: 'id' }
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.relationships = hash.relationships || {};
      hash.relationships['attachments'] = hash.relationships['attachments'] || {};
      hash.relationships['attachments'].links = hash.relationships.links || {};
      hash.relationships['attachments'].links.related = Ember.getOwner(this).lookup('adapter:attachment').buildURL('attachment', null, null, 'findMany', { record_id: hash.id, record_type: 'Message', paginate: false });
      return this._super.apply(this, arguments);
    }
  });

  exports.default = MessageSerializer;
});