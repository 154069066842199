define('knowme-client/components/email-touch-point/resource-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    buttonHtml: Ember.computed('model.name', 'model.shortenedUrl', function () {
      return `<a
      href=${this.get('model.shortenedUrl')}
      style="
        background-color: #3670B0;
        border-color: #3670B0;
        color: #ffffff;
        padding: 6px 30px;
        text-decoration: none;
    ">${this.get('model.name')}</a>`;
    }),
    actions: {
      copyToClip: function () {
        let str = this.get('buttonHtml');
        function listener(e) {
          e.clipboardData.setData("text/html", str);
          e.clipboardData.setData("text/plain", str);
          e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
        this.get('flashMessages').info("Button is copied", { destroyOnClick: true, timeout: 3000 });
      }
    }
  });
});