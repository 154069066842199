define('knowme-client/controllers/user-leads', ['exports', 'knowme-client/mixins/date-filter', 'knowme-client/mixins/controllers/queryable', 'knowme-client/mixins/controllers/pagination', 'moment', 'ember-uploader/uploaders'], function (exports, _dateFilter, _queryable, _pagination, _moment, _uploaders) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserLeadsIndexController;

  UserLeadsIndexController = Ember.Controller.extend(_dateFilter.default, _queryable.default, _pagination.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    ajax: Ember.inject.service('authorized-ajax'),
    callsController: Ember.inject.controller('calls'),
    queryParams: ['sort_by', 'order', 'on_date', 'campaign_id', 'ad_id', 'filter', 'converted', 'converted_at', 'user_id', 'due_date', 'sales_stage_id'], // queryParams that trigger model reload
    sort_by: null,
    order: null,
    on_date: null,
    campaign_id: null,
    ad_id: null,
    filter: null,
    converted: null,
    converted_at: null,
    user_id: null,
    due_date: null,
    sales_stage_id: null,
    // collections
    users: [],
    stages: [],
    campaigns: [],
    ads: [],
    files: null,
    actions: {
      sort: function (toSortBy) {
        var sortBy = this.get('sort_by') || 'name';
        var order = this.get('order');
        if (sortBy === toSortBy) {
          order = order === 'asc' ? 'desc' : 'asc';
        } else {
          sortBy = toSortBy;
          order = 'desc';
        }
        this.set('sort_by', sortBy);
        this.set('order', order);
        this.send('setOrder', sortBy, order);
      },
      toggleOpportunities: function (lead) {
        lead.set('opportunitiesDisplayed', !lead.get('opportunitiesDisplayed'));
      },
      upload: function (files) {
        let url = Ember.getOwner(this).lookup('adapter:lead').buildURL('leads/import');
        const uploader = _uploaders.Uploader.create({ url: url });
        let ajaxSettings = { headers: this.get('ajax.headers') };
        uploader.set('ajaxSettings', ajaxSettings);
        uploader.upload(files[0]).then(() => {
          // Handle success
          Ember.$('#import-leads').modal('hide');
          this.get('flashMessages').info('Your file is being processed, you will receive an email when the import is completed', { destroyOnClick: true });
        }, error => {
          // Handle failure
          Ember.$('#import-leads').modal('hide');
          this.get('flashMessages').danger(error.responseJSON.error, { destroyOnClick: true });
        });
      }
    },
    dateFilterPromptText: Ember.computed('filter', function () {
      let text;
      switch (this.get('filter')) {
        case 'action_required':
          text = 'Filter by Due Date';
          break;
        case 'past_due_action':
          text = 'Filter by Due Date';
          break;
        case 'converted':
          text = 'Filter by Conversion Date';
          break;
        default:
          text = 'Filter by Date';
      }
      return text;
    }),
    dateFilterOptions: Ember.computed('filter', function () {
      let options;
      switch (this.get('filter')) {
        case 'action_required':
          options = [{ id: 'today', name: 'Today Only' }, { id: 'todayAndNext6Days', name: 'Today and Next 6 Days' }, { id: 'todayAndNext29Days', name: 'Today and Next 29 Days' }];
          break;
        case 'past_due_action':
          options = [{ id: 'previous3Days', name: 'Previous 3 Days' }];
          break;
        default:
          options = [{ id: 'today', name: 'Today Only' }, { id: 'todayAndYesterday', name: 'Since Yesterday' }, { id: 'todayAndPrevious2Days', name: 'Last 3 Days' }, { id: 'todayAndPrevious6Days', name: 'Last 7 Days' }, { id: 'todayAndPrevious29Days', name: 'Last 30 Days' }, { id: 'previousMonth', name: 'Last Month: ' + (0, _moment.default)().subtract(1, 'months').format('MMMM YYYY') }, { id: 'yearToDate', name: 'Year to Date (YTD)' }];
      }
      return options;
    }),
    customFromDateStartDate: Ember.computed('filter', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        val = (0, _moment.default)().format('L');
      }
      if (filter === 'past_due_action') {
        val = null;
      }
      return val;
    }),
    customFromDateEndDate: Ember.computed('filter', 'customToDate', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = null;
        }
      }
      if (filter === 'past_due_action') {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = (0, _moment.default)().subtract(1, 'day').endOf('day').format('L');
        }
      }
      if (filter === null) {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = (0, _moment.default)().endOf('day').format('L');
        }
      }
      return val;
    }),
    customToDateStartDate: Ember.computed('filter', 'customFromDate', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        if (Ember.isPresent(this.get('customFromDate'))) {
          val = (0, _moment.default)(this.get('customFromDate')).format('L');
        } else {
          val = null;
        }
      }
      if (filter === 'past_due_action') {
        if (Ember.isPresent(this.get('customFromDate'))) {
          val = (0, _moment.default)(this.get('customFromDate')).format('L');
        } else {
          val = null;
        }
      }
      if (filter === null) {
        if (Ember.isPresent(this.get('customFromDate'))) {
          val = (0, _moment.default)(this.get('customFromDate')).format('L');
        } else {
          val = null;
        }
      }
      return val;
    }),
    customToDateEndDate: Ember.computed('filter', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        val = null;
      }
      if (filter === 'past_due_action') {
        val = (0, _moment.default)().subtract(1, 'day').endOf('day').format('L');
      }
      if (filter === null) {
        val = (0, _moment.default)().endOf('day').format('L');
      }
      return val;
    }),
    leadFilters: Ember.computed('model', 'filterByAssignedToText', 'accountService.customersText', function () {
      let customersText = this.get('accountService.customersText');
      return [{ id: 'action_required', name: 'To Do' }, { id: 'past_due_action', name: 'Past Due Opportunities' }, { id: 'pipeline_opportunity', name: this.get('converted') ? 'Pipeline Opp & Revenue' : 'Pipeline Opportunity' }, { id: 'high_priority', name: 'High Priority Opp' }, { id: 'sales_stage', name: this.get('filterBySalesStage') }, { id: 'assigned_to', name: this.get('filterByAssignedToText') }, { id: 'assigned_by_sales_rep', name: 'Assigned by Sales Rep' }, { id: 'converted', name: this.get('converted') ? 'Conversion Date' : 'Converted Leads' }, { id: 'acquired_by_campaigns', name: 'Acquired by Campaigns' }, { id: 'acquired_manually', name: 'Acquired by Manual Entry' }, { id: 'number_of_opportunities', name: 'Number of Opportunities' }, { id: 'with_opportunities', name: 'With Opportunities' }, { id: 'with_open_opportunities', name: `${customersText} with Open Opps` }, { id: 'acquired_today', name: 'Generated Today' }, { id: 'acquired_ytd', name: 'Generated YTD' }, { id: 'converted_today', name: 'Converted Today' }, { id: 'converted_ytd', name: 'Converted YTD' }, { id: 'active', name: this.get('converted') ? `Active ${customersText}` : 'Active Leads' }, { id: 'inactive', name: this.get('converted') ? `Inactive ${customersText}` : 'Inactive Leads' }, { id: 'all', name: this.get('converted') ? `All ${customersText}` : 'All Leads' }, { id: 'all_leads_and_customers', name: `All Leads & ${customersText}` }];
    }),
    filtering: Ember.computed('dateFilterSelected', 'campaign_id', 'ad_id', 'filter', function () {
      return Ember.isPresent(this.get('dateFilterSelected')) || Ember.isPresent(this.get('campaign_id')) || Ember.isPresent(this.get('ad_id')) || Ember.isPresent(this.get('filter'));
    }),
    pipelineTotal: Ember.computed('model.@each.leadsPipeline', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('leadsPipeline');
      }, 0);
    }),
    revenueTotal: Ember.computed('model.@each.convertedRevenue', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('convertedRevenue');
      }, 0);
    }),
    totalLeads: Ember.computed('model.@each.leadsCount', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('leadsCount');
      }, 0);
    }),
    totalConverted: Ember.computed('model.@each.converted', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('leadsConverted');
      }, 0);
    }),
    totalCalls: Ember.computed('model.@each.callsCount', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('callsCount');
      }, 0);
    }),
    totalLeadsWithActionRequired: Ember.computed('model.@each.leadsWithActionRequired', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('leadsWithActionRequired');
      }, 0);
    }),
    totalLeadsWithPastDueAction: Ember.computed('model.@each.leadsWithPastDueAction', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('leadsWithPastDueAction');
      }, 0);
    }),
    filterByAssignedToText: Ember.computed('model', 'users.@each.name', 'user_id', 'accountService.customersText', function () {
      let converted = this.get('converted');
      let leadsOrCustomers = converted !== "1" ? 'Leads' : this.get('accountService.customersText');
      if (this.get('session.currentUser.id') === this.get('user_id')) {
        return `My ${leadsOrCustomers}`;
      } else {
        let user = this.get('store').peekRecord('user', this.get('user_id'));
        let name = Ember.isPresent(user) ? user.get('name') : '...';
        return `${leadsOrCustomers} by ${name}`;
      }
    }),
    filterBySalesStage: Ember.computed('model', 'sales_stages.@each.name', 'sales_stage_id', function () {
      let stage = this.get('store').peekRecord('sales_stage', this.get('sales_stage_id'));
      let name = Ember.isPresent(stage) ? stage.get('name') : '...';
      return `Sales stage: ${name}`;
    }),
    onFilterChange: Ember.observer('filter', function () {
      let filter = this.get('filter');
      this.setProperties({ customFromDate: null, customToDate: null });
      if (filter === 'action_required') {
        this.set('customFromDate', (0, _moment.default)().startOf('day').toDate());
      }
      if (filter === 'past_due_action') {
        this.set('customToDate', (0, _moment.default)().subtract(1, 'day').endOf('day').toDate());
      }
      if (filter === 'converted_ytd') {
        this.set('dateFilterSelected', { id: 'yearToDate', name: 'Year to Date (YTD)' });
      }
    }),
    uploadFileButtonDisabled: Ember.computed('files.length', function () {
      return Ember.isBlank(this.get('files'));
    })
  });

  exports.default = UserLeadsIndexController;
});