define('knowme-client/mixins/controllers/email-integration', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Mixin.create({
				applicationController: Ember.inject.controller('application'),
				actions: {
						startEmailIntegration: function (e) {
								if (e) {
										e.preventDefault();
								}
								this.get('applicationController').set('startEmailIntegration', true);
								this.transitionToRoute('profile');
								this.get('target._routerMicrolib').refresh();
						}
				}
		});
});