define('knowme-client/routes/account/settings/incentives/edit', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      return this.get('store').find('incentive', params.id);
    },
    afterModel: function (model, transition) {
      if (model.get('hasExpired')) {
        return this.transitionTo('account.settings.incentives.index');
      }
    },
    actions: {
      willTransition: function () /*transition*/{
        this.controller.get('model').rollbackAttributes();
      }
    }
  });
});