define('knowme-client/routes/promo-codes', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    store: Ember.inject.service(),
    model: function (queryParams) {
      let params = {};
      if (Ember.isPresent(queryParams.plan_id)) {
        params.plan_id = queryParams.plan_id;
      }
      if (Ember.isPresent(queryParams.page)) {
        params.page = queryParams.page;
      }
      if (Ember.isPresent(queryParams.status)) {
        params.status = queryParams.status;
      }
      return this.get('store').query('promo-code', params);
    },
    afterModel: function (model, transition) {
      console.log(transition.queryParams);
      return this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc', promo_codes: { status: transition.queryParams.status } }).then(function (plans) {
        this.set('controllerData', { plans: plans });
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      controller.setProperties(this.get('controllerData'));
      if (Ember.isPresent(controller.get('plan_id'))) {
        let selectedPlan = controller.get('plans').findBy('id', controller.get('plan_id'));
        controller.set('plan', selectedPlan);
      } else {
        controller.set('plan', null);
      }
      this.set('controllerData', {});
    },
    actions: {
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });
});