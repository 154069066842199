define('knowme-client/authorizers/json-web-token', ['exports', 'ember-simple-auth/authorizers/oauth2-bearer'], function (exports, _oauth2Bearer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2Bearer.default.extend({
    authorize: function authorize(data, block) {
      let token = data['token'];

      if (!Ember.isEmpty(token)) {
        block('Authorization', token);
      }
    }
  });
});