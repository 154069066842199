define('knowme-client/components/actions/resource-form', ['exports', 'knowme-client/mixins/controllers/status'], function (exports, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_status.default, {
    router: Ember.inject.service(),
    action: Ember.computed.alias('model'),
    flashMessages: Ember.inject.service(),
    actions: {
      submit: function (step) {
        let controller = this;
        let resource = controller.get('model');
        resource.save().then(function () {
          if (step === 'close') {
            this.get('router').transitionTo('account.settings.actions');
          }
          this.get('router._router._routerMicrolib').refresh();
        }.bind(this)).catch(function (reason) {
          console.log("Errors", reason);
        });
      },
      clear: function () {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('account.settings.actions');
      },
      destroy: function (resource) {
        resource.destroyRecord().then(function () {
          this.get('router').transitionTo('account.settings.actions');
        }.bind(this)).catch(function (error) {
          resource.rollbackAttributes();
          this.get('flashMessages').danger(error.errors);
        }.bind(this));
      }
    },
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', function () {
      return this.get('model.hasDirtyAttributes');
    }),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'isModelTreeDirty', function () {
      return !(this.get('model.validations.isValid') && this.get('isModelTreeDirty'));
    })
  });
});