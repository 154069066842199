define('knowme-client/serializers/call-touch-point', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      ad: { serialize: 'id', deserialize: false },
      didAllocation: { serialize: 'records', deserialize: 'id' }
    },
    keyForRelationship(key, relationship, method) {
      if (key === 'didAllocation' && method === 'serialize') {
        return `${Ember.String.underscore(key)}_attributes`;
      }
      if (key === 'didAllocation' && method === 'deserialize') {
        return `${Ember.String.underscore(key)}_id`;
      }
      return this._super(...arguments);
    },
    serialize() /*snapshot, options*/{
      let json = this._super(...arguments);
      let numbers = [json.forward_to1, json.forward_to2, json.forward_to3, json.forward_to4];
      json.forward_to = numbers.compact().filter(function (e) {
        return e;
      }).join(',');
      delete json.forward_to1;
      delete json.forward_to2;
      delete json.forward_to3;
      delete json.forward_to4;
      return json;
    },
    normalize(typeClass, hash) {
      hash.forward_to1 = hash.forward_to_1;
      hash.forward_to2 = hash.forward_to_2;
      hash.forward_to3 = hash.forward_to_3;
      hash.forward_to4 = hash.forward_to_4;
      delete hash.forward_to_1;
      delete hash.forward_to_2;
      delete hash.forward_to_3;
      delete hash.forward_to_4;
      hash.links = {
        did: Ember.getOwner(this).lookup('adapter:did').buildURL('did', hash.did_id, null, 'findRecord', null),
        didAllocation: Ember.getOwner(this).lookup('adapter:did-allocation').buildURL('did-allocation', hash.did_allocation_id, null, 'findRecord', null),
        ad: Ember.getOwner(this).lookup('adapter:ad').buildURL('ad', hash.ad_id, null, 'findRecord', null)
      };
      return this._super.apply(this, arguments);
    }
  });
});