define('knowme-client/controllers/feedback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FeedbackController;

  FeedbackController = Ember.Controller.extend({
    queryParams: ['code'],
    store: Ember.inject.service(),
    satisfactionOptions: ['very_dissatisfied', 'dissatisfied', 'neutral', 'satisfied', 'very_satisfied'],
    isScored: false,
    actions: {
      saveFeedback: function (feedback) {
        this.set('model.satisfaction', feedback);
        this.get('model').save().then(function () {
          this.set('isScored', true);
        }.bind(this));
      },
      updateFeedback: function () {
        this.set('isScored', false);
      }
    }
  });

  exports.default = FeedbackController;
});