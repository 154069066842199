define('knowme-client/routes/leads/edit', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsEditRoute;

  LeadsEditRoute = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      // let route = this;
      let lead = this.get('store').find('lead', params.id);
      // lead.then(function(){
      // build contact urls
      // let leadContactUrlsSocialNetworks = lead.get('contactUrls.@each.socialNetwork');
      // let contactInfo = lead.get('primaryWorkContactInfo');
      /*
      [null, 'facebook', 'twitter', 'linkedin', 'google_plus'].forEach(function(socialNetwork) {
        if(!leadContactUrlsSocialNetworks.contains(socialNetwork)) {
          var contactUrl = route.store.createRecord('contactUrl', {socialNetwork: socialNetwork});
          contactUrl.set('contactInfo', contactInfo);
        }
      });
      */
      // });
      return lead;
    },
    renderTemplate: function () /*controller, model*/{
      return this.render('leads/edit', {
        into: 'application'
      });
    }
  });

  exports.default = LeadsEditRoute;
});