define('knowme-client/helpers/to-upper-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toUpperCase = toUpperCase;
  function toUpperCase(params /*, hash*/) {
    return params[0].toUpperCase();
  }

  exports.default = Ember.Helper.helper(toUpperCase);
});