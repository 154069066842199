define('knowme-client/controllers/account/settings/alerts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      destroy(alert) {
        alert.destroyRecord().then(function () {
          this.transitionToRoute('account.settings.alerts');
          this.get('target._routerMicrolib').refresh();
        }.bind(this));
      }
    }
  });
});