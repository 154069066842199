define('knowme-client/adapters/audit', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findHasManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+auditable_id}/{+pathForType}{?paginate}',
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      pathForType: function (type, id, snapshot, query) {
        if (query.auditableId) {
          return `opportunities/${query.auditableId}/history`;
        } else {
          return `leads/${query.leadId}/opp_history`;
        }
      },
      lead_id: function (type, id, snapshot, query) {
        return query.leadId;
      },
      auditable_id: function (type, id, snapshot, query) {
        if (query.auditableId) {
          return `${query.auditableId}/history`;
        }
        return null;
      },
      paginate: function (type, id, snapshot, query) {
        if (query && Ember.isNone(query.paginate)) {
          return true;
        }
      },
      query: function (type, id, snapshot, query) {
        delete query.auditableId;
        delete query.leadId;
        return query;
      }
    }
  });
});