define('knowme-client/components/email-drag-box', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    email: Ember.inject.service('email-connect'),
    draftMessage: null,
    classNames: ['email-drag-box'],
    attributeBindings: ['draggable'],
    draggable: true,
    sending: false,
    x: 0,
    y: 0,
    selectedEmail: null,
    selectedOpportunity: null,
    selectedLead: null,
    stopAutoSaving: false,
    isHeaderDragging: false,
    confirmNoSubjectEmail: false,
    noSubjectEmailConfirmed: false,
    positionChanged: Ember.observer('x', 'y', function () {
      this.$().css({
        left: `${this.get('x')}px`,
        top: `${this.get('y')}px`
      });
    }),
    ckeditorConfig: {
      height: 160,
      toolbar: {
        items: ['fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'uploadAttach', 'link', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', '|', 'heading']
      }
    },
    paddingBottom: Ember.computed('draftMessage.attachments.length', function () {
      if (this.get('draftMessage.attachments.length') > 0) {
        return 10 + this.get('draftMessage.attachments.length') * 17;
      } else {
        return 0;
      }
    }),
    onTemplateSelected: Ember.observer('selectedTemplateId', function () {
      if (Ember.isPresent(this.get('selectedTemplateId'))) {
        this.get("draftMessage").set('template_id', this.get('selectedTemplateId'));
        this.set('stopAutoSaving', true);
        this.get("draftMessage").save().then(function () {
          this.set('loadedTemplateId', this.get('selectedTemplateId'));
          this.set('stopAutoSaving', false);
        }.bind(this));
      } else {
        this.get("draftMessage").set('body', '');
      }
    }),
    emailTemplates: Ember.computed('selectedEmail', function () {
      if (Ember.isPresent(this.get('selectedEmail'))) {
        return this.get('store').query('template', { status: 'active', filter: 'all', pagination: false, account_id: this.get('session.currentUser.account.id') });
      }
    }),
    contactEmails: Ember.computed('selectedLead', function () {
      return this.get('selectedLead.contactsContactEmails');
    }),
    dragStart(e) {
      if (!this.get('isHeaderDragging')) {
        e.preventDefault();
      }
      var x = parseInt(this.$().css('left')) - e.originalEvent.clientX;
      var y = parseInt(this.$().css('top')) - e.originalEvent.clientY;
      e.originalEvent.dataTransfer.setData("position", `${x},${y}`);
    },
    windowDrop(e) {
      var xy = e.dataTransfer.getData("position").split(',');
      this.setProperties({
        x: `${e.originalEvent.clientX + parseInt(xy[0])}`,
        y: `${e.originalEvent.clientY + parseInt(xy[1])}`
      });
    },
    windowDragOver(e) {
      e.originalEvent.preventDefault();
    },
    mouseDown(e) {
      if (Ember.$(e.target).closest('.knowme-editor').length || this.get('position') === 'fixed') {
        this.set('draggable', false);
      } else {
        this.set('draggable', true);
      }
      this.set('isHeaderDragging', e.target.className.indexOf && e.target.className.indexOf('popup-header') >= 0);
    },
    didReceiveAttrs() {
      this._super.apply(this, arguments);
      let ckeditorConfig = this.get('ckeditorConfig');
      if (Ember.isPresent(this.get('toolbarPosition'))) {
        ckeditorConfig.toolbar.position = this.get('toolbarPosition');
      } else {
        ckeditorConfig.toolbar.position = 'bottom';
      }
      ckeditorConfig.onAttachmentUpload = function (data) {
        this.get("draftMessage").hasMany("attachments").reload();
      }.bind(this);
    },
    didRender() {
      var self = this;
      Ember.$(document).on('mousedown', Ember.$.proxy(self.mouseDown, self)).on('drop', Ember.$.proxy(self.windowDrop, self)).on('dragover', Ember.$.proxy(self.windowDragOver, self));

      if (Ember.isBlank(this.get('draftMessage.to'))) {
        Ember.$('.form-control.to').focus();
      }
      if (this.get('position') === 'fixed') {
        this.set('draggable', false);
      }
    },
    // Remember to unbind the document event handlers
    didDestroyElement() {
      Ember.$(document).off('mousedown').off('drop').off('dragover');
      this.setProperties({ selectedEmail: null, draftMessage: null });
    },
    init() {
      this._super(...arguments);
      this.autoSaving();
    },
    willDestroyElement: function () {
      this._super(...arguments);
      this.set('stopAutoSaving', true);
    },
    autoSaving() {
      if (this.get('stopAutoSaving')) {
        return;
      }
      if (this.get("draftMessage").get('hasDirtyAttributes') && !this.get("draftMessage").get('isDeleted')) {
        console.log('saving...', this.get('draftMessage.id'));
        this.get("draftMessage").set('contentWidth', Ember.$('.knowme-editor .ck-content').width());
        this.get("draftMessage").save().then(function (response) {
          console.log('saved');
          Ember.run.later(this, this.autoSaving, 2000);
        }.bind(this));
      } else {
        Ember.run.later(this, this.autoSaving, 2000);
      }
    },
    actions: {
      addUserEmail: function (ce, field) {
        let message = this.get('draftMessage');
        if (Ember.isBlank(message.get(field))) {
          message.set(field, ce.get('email'));
        } else {
          message.set(field, `${message.get(field)}, ${ce.get('email')}`);
        }
      },
      sendEmail() {
        let self = this;
        let currentUser = self.get('session.currentUser');
        let email = this.get("draftMessage");
        if (Ember.isBlank(email.get('to'))) {
          return alert('Please specify at least one recipient.');
        }
        if (Ember.isBlank(email.get('subject')) && !this.get('noSubjectEmailConfirmed')) {
          this.set('confirmNoSubjectEmail', true);
          return;
        }
        let data = {
          id: email.get('id'),
          to: email.get('to'),
          cc: email.get('cc'),
          bcc: email.get('bcc'),
          subject: email.get('subject'),
          body: email.get('body')
        };
        self.set('sending', true);
        self.get('email').send(data, currentUser).then(message => {
          self.set('confirmNoSubjectEmail', false);
          self.set('noSubjectEmailConfirmed', false);
          self.sendAction('afterSend');
          self.set('selectedEmail', null);
          let opportunity = self.get('selectedOpportunity');
          if (opportunity) {
            opportunity.set('assignedTo', currentUser);
            if (opportunity.get('assignedToHasChanged')) {
              opportunity.save();
            }
          }
        });
      },
      removeEmail() {
        this.get('draftMessage').destroyRecord().then(function () {
          this.set('draftMessage', null);
          this.set('selectedEmail', null);
          if (this.get('refreshModel')) {
            this.sendAction('refreshModel');
          }
        }.bind(this));
      },
      onMaximize() {
        this.$().removeClass('minimized');
      },
      onMinimize() {
        this.$().addClass('minimized');
      }
    }

  });
});