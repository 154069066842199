define('knowme-client/mirage/factories/opportunity', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: _faker.default.company.catchPhrase,
    // stage: function() { return faker.random.arrayElement(['Unqualified']); },
    priority: function () {
      return _faker.default.random.arrayElement(['low', 'medium', 'high']);
    },
    total: function () {
      return _faker.default.datatype.number({ min: 0, max: 2000 });
    },
    pipeline: function () {
      return _faker.default.datatype.number({ min: 0, max: 2000 });
    },
    source: function () {
      return _faker.default.random.arrayElement(['outbound_sms']);
    },
    // nextAction: function() { return faker.random.arrayElement(['Follow Up']); },
    dueDate: function () {
      return _faker.default.random.arrayElement([null, _faker.default.date.future().toISOString()]);
    },
    // forecastDate: function() { return faker.random.arrayElement([null, faker.date.future().toISOString()]); },
    activity: function () {
      return null;
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    afterCreate(opportunity, server) {
      let ad = _faker.default.random.arrayElement(server.schema.ads.all().models);
      let salesStage = _faker.default.random.arrayElement(server.schema.salesStages.all().models);
      let salesAction = _faker.default.random.arrayElement(server.schema.salesActions.all().models);
      let activity = server.create('lead-activity', { opportunity: opportunity, lead: opportunity.lead });
      opportunity.update({
        ad: ad,
        salesStage: salesStage,
        salesAction: salesAction,
        lastActivity: activity
      });
    },
    qualified: (0, _emberCliMirage.trait)({
      afterCreate(opportunity, server) {
        let salesStage = server.schema.salesStages.findBy({ key: 'qualified' });
        opportunity.update({ salesStage: salesStage });
      }
    }),
    closed: (0, _emberCliMirage.trait)({
      afterCreate(opportunity, server) {
        let salesStage = server.schema.salesStages.findBy({ key: 'closed' });
        opportunity.update({ salesStage: salesStage });
      }
    })
  });
});