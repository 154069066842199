define('knowme-client/models/did-allocation', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/dirty', 'knowme-client/mixins/models/validations/did-allocation', 'knowme-client/mixins/models/ember-cp-validations-custom-properties'], function (exports, _model, _attr, _relationships, _dirty, _didAllocation, _emberCpValidationsCustomProperties) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_didAllocation.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    allocatable: (0, _relationships.belongsTo)('allocatable', { async: true, inverse: 'didAllocation' }),
    did: (0, _relationships.belongsTo)('did', { async: true, inverse: 'didAllocations' }),
    didNumber: (0, _attr.default)(),
    didNumberType: (0, _attr.default)(),
    status: (0, _attr.default)(),
    isActive: Ember.computed.equal('status', 'active'),
    isInactive: Ember.computed.equal('status', 'inactive')
  });
});