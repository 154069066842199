define('knowme-client/mixins/models/validations/incentive', ['exports', 'ember-cp-validations', 'moment'], function (exports, _emberCpValidations, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
		body: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
		startDate: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		endDate: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			let result = 'cannot be lower than start date';
			let startDate = model.get('startDate');
			let today = (0, _moment.default)().startOf('day').toDate();
			let end = (0, _moment.default)(value).endOf('day');

			if (Ember.isBlank(startDate) || Ember.isPresent(startDate) && end.isAfter((0, _moment.default)(startDate)) && end.isAfter(today)) {
				result = true;
			}
			if (Ember.isPresent(startDate) && !end.isAfter(today)) {
				result = 'cannot be lower than today';
			}
			return result;
		}, {
			dependentKeys: ['startDate']
		})]
	});
});