define('knowme-client/adapters/lead', ['exports', 'knowme-client/adapters/application', 'knowme-client/mixins/adapters/api-key-header'], function (exports, _application, _apiKeyHeader) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let LeadAdapter;


	LeadAdapter = _application.default.extend(_apiKeyHeader.default, {
		urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
		findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,converted,ad_id,assigned_to_id}',
		queryUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,filter[],converted,campaign_id,ad_id,user_id,page,sort_by,order,field,from_date,to_date,on_date,converted_at}'
	});

	exports.default = LeadAdapter;
});