define('knowme-client/routes/account/settings/reviews', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    pagination: {},
    queryParams: {
      query: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    beforeModel: function (transition) {
      return this._super(...arguments).then(function () {
        if (!this.get('features.reviewsSectionAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Access Unauthorized', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        }
      }.bind(this));
    },
    model: function (params) {
      let promise = this.get('store').query('review-link', params);
      promise.then(result => {
        this.set('pagination.total', result.meta.pagination.totalCount);
        this.set('pagination.limit', result.meta.pagination.page.size);
        this.set('pagination.page', result.meta.pagination.page.number);
      });
      return promise;
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      // controller.set('selectedResource', this.get('selectedResource'));
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
    },
    actions: {
      reloadModel: function () {
        this.refresh();
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });
});