define('knowme-client/components/km-ed-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let KmEdSelectComponent;

  KmEdSelectComponent = Ember.Component.extend({
    content: null,
    selectedValue: null,
    prompt: null,
    valuePath: 'id',
    labelPath: 'name',
    disabled: false,

    init() /*attrs*/{
      this._super(...arguments);
      var content = this.get('content');
      if (!content) {
        this.set('content', []);
      }
    },
    actions: {
      change() {
        // const changeAction = this.get('action');
        const selectedEl = this.$('select')[0];
        const selectedIndex = this.get('prompt') ? selectedEl.selectedIndex - 1 : selectedEl.selectedIndex;
        const content = this.get('content');
        const selectedValue = content.objectAt(selectedIndex);

        this.set('selectedValue', selectedValue);
        // changeAction(selectedValue);
      }
    },
    optionName: function (option) {
      return option.get(this.labelPath);
    }
  });

  exports.default = KmEdSelectComponent;
});