define('knowme-client/routes/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ReportsRoute;

  ReportsRoute = Ember.Route.extend();

  exports.default = ReportsRoute;
});