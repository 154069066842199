define('knowme-client/routes/account/users/new', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    model: function () {
      let user = this.store.createRecord('user');
      user.set('usersDashboardWidgets', []);
      user.set('roles', []);
      this.store.createRecord('did-allocation', { allocatable: user });
      return user;
    },
    afterModel: function () {
      if (this.get('session.currentUser.hasAdminRole') || this.get('session.currentUser.isManager')) {
        return Ember.RSVP.hash({
          roles: this.get('store').query('role', { paginate: false, sort_by: 'name', order: 'asc' })
        }).then(function (results) {
          this.set('controllerData', results);
        }.bind(this));
      }
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        if (model.get('isNew')) {
          // model.get('didAllocation').deleteRecord();
          // model.deleteRecord();
        }
      }
    }
  });
});