define('knowme-client/controllers/leads/show/contacts/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowContactsEditController;

  LeadsShowContactsEditController = Ember.Controller.extend({
    features: Ember.inject.service(),
    contactsController: Ember.inject.controller('leads.show.contacts')
  });

  exports.default = LeadsShowContactsEditController;
});