define('knowme-client/helpers/toggle-order-for', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var toggleOrderFor, ToggleOrderForHelper;

  exports.toggleOrderFor = toggleOrderFor = function (params) {
    var sortBy = params[0],
        order = params[1],
        column = params[2];
    if (Ember.isEmpty(sortBy)) {
      return 'desc';
    }
    if (sortBy === column && order === 'desc') {
      return 'asc';
    } else {
      return 'desc';
    }
  };

  ToggleOrderForHelper = Ember.Helper.helper(toggleOrderFor);

  exports.toggleOrderFor = toggleOrderFor;
  exports.default = ToggleOrderForHelper;
});