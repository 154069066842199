define('knowme-client/controllers/incentives/new', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IncentivesController;

  IncentivesController = Ember.Controller.extend({
    queryParams: ['code', 'claim', 'uid'],
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    printThis: Ember.inject.service(),
    session: Ember.inject.service(),
    claim: false,
    isSaving: false,
    isSaved: false,
    isSentViaText: Ember.computed.alias('incentivesContact.isSentViaText'),
    isSentViaEmail: Ember.computed.alias('incentivesContact.isSentViaEmail'),
    firstContactEmail: Ember.computed.alias('emailInfo.contactEmails.firstObject'),
    firstContactNumber: Ember.computed.alias('phoneNumberInfo.contactPhoneNumbers.firstObject'),
    isResourceTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.contactInfosContactEmails.@each.contactInfoHasChanged', 'model.contactInfosContactPhoneNumbers.@each.contactInfoHasChanged', 'model.contactInfos.@each.hasDirtyAttributes', 'model.contactInfosContactEmails.@each.hasDirtyAttributes', 'model.contactInfosContactPhoneNumbers.@each.hasDirtyAttributes', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.contactInfosContactEmails').isAny('contactInfoHasChanged', true) || this.get('model.contactInfosContactPhoneNumbers').isAny('contactInfoHasChanged', true) || this.get('model.contactInfos').isAny('hasDirtyAttributes', true) || this.get('model.contactInfosContactEmails').isAny('hasDirtyAttributes', true) || this.get('model.contactInfosContactPhoneNumbers').isAny('hasDirtyAttributes', true);
    }),
    disableSaveButton: Ember.computed('model.validations.isInvalid', 'isResourceTreeDirty', 'incentivesContact', 'session.isAuthenticated', function () {
      return this.get('session.isAuthenticated') || this.get('model.validations.isInvalid') || !this.get('isResourceTreeDirty') && !this.get('claim') && Ember.isBlank(this.get('incentivesContact'));
    }),
    subjectForShare: Ember.computed('incentivesContact', function () {
      return encodeURIComponent(this.get('incentivesContact.incentive.name'));
    }),
    textForShare: Ember.computed('model', 'incentivesContact.incentive', function () {
      return encodeURIComponent(`${this.get('model.fullName')} is forwarding to you this incentive: ${this.get('incentivesContact.incentive.body')} ${this.get('incentivesContact.shortenedUrl')}`);
    }),
    phoneNumberInfo: Ember.computed('incentivesContactTarget', 'model', 'model.contactInfos.@each.contactPhoneNumbers', function () {
      if (!this.get('claim')) {
        if (Ember.isPresent(this.get('model.contactInfos').filterBy('category', 'mobile'))) {
          return this.get('model.contactInfos').filterBy('category', 'mobile').get('firstObject');
        } else {
          return this.get('store').createRecord('contact-info', { category: 'mobile', contact: this.get('model') });
        }
      }

      if (this.get('incentivesContact.isSentViaText')) {
        return this.get('incentivesContactTarget.contactInfo');
      } else {
        if (this.get('model.contactInfosContactPhoneNumbers.length') > 0) {
          if (this.get('model.mobileContactInfos.length') > 0 && this.get('model.mobileContactInfos').any(function (x) {
            return x.get('contactPhoneNumbersLength') > 0;
          })) {
            return this.get('model.mobileContactInfos.firstObject');
          }
          if (this.get('model.workContactInfos.length') > 0 && this.get('model.workContactInfos').any(function (x) {
            return x.get('contactPhoneNumbersLength') > 0;
          })) {
            return this.get('model.workContactInfos.firstObject');
          }
          if (this.get('model.homeContactInfos.length') > 0 && this.get('model.homeContactInfos').any(function (x) {
            return x.get('contactPhoneNumbersLength') > 0;
          })) {
            return this.get('model.homeContactInfos.firstObject');
          }
        } else {
          return this.get('store').createRecord('contact-info', { category: 'mobile', contact: this.get('model') });
        }
      }
    }),
    emailInfo: Ember.computed('incentivesContactTarget', 'model', function () {
      if (!this.get('claim')) {
        if (Ember.isPresent(this.get('model.contactInfos').filterBy('category', 'work'))) {
          return this.get('model.contactInfos').filterBy('category', 'work').get('firstObject');
        } else {
          return this.get('store').createRecord('contact-info', { category: 'work', contact: this.get('model') });
        }
      }

      if (this.get('incentivesContact.isSentViaEmail')) {
        return this.get('incentivesContactTarget.contactInfo');
      } else {
        if (this.get('model.contactInfosContactEmails.length') > 0) {
          if (this.get('model.workContactInfos.length') > 0 && this.get('model.workContactInfos').any(function (x) {
            return x.get('contactEmailsLength') > 0;
          })) {
            return this.get('model.workContactInfos.firstObject');
          }
          if (this.get('model.homeContactInfos.length') > 0 && this.get('model.homeContactInfos').any(function (x) {
            return x.get('contactEmailsLength') > 0;
          })) {
            return this.get('model.homeContactInfos.firstObject');
          }
        } else {
          return this.get('store').createRecord('contact-info', { category: 'work', contact: this.get('model') });
        }
      }
    }),
    steps: Ember.computed('incentivesContact.incentive', function () {
      return [{ setFontSize: 12 }, { text: [25, 25, `${(0, _moment.default)().format('MM/DD/YY HH:mm')} Generated by Manual Addition through Incentive offer acceptance.`] }, { setFontSize: 10 }, { text: [25, 35, this.get('incentivesContact.incentive.body'), { maxWidth: 180 }] }];
    }),
    actions: {
      displaySelector: function (contactResource) {
        contactResource.set('displaySelector', !contactResource.get('displaySelector'));
      },
      changeCategory: function (category, contactResource) {
        contactResource.set('displaySelector', false);
        let contactInfo = contactResource.get('contactInfo');
        contactInfo.set('category', category);
      },
      addContactPhoneNumber: function () {
        let phone = this.get('phone');
        if (Ember.isPresent(phone) && phone.length === 12) {
          this.get('store').createRecord('contactPhoneNumber', { tenDigitNumber: phone, contactInfo: this.get('phoneNumberInfo') });
          this.set('phone', '');
          Ember.run.later(function () {
            Ember.$('.phone-input input').focus();
          }, 200);
        }
      },
      addContactEmail: function () {
        var email = this.get('email');

        if (Ember.isPresent(email) && email.match(/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i)) {
          this.get('store').createRecord('contactEmail', { email: email, contactInfo: this.get('emailInfo') });
          this.set('email', '');
          Ember.run.later(function () {
            Ember.$('.email-input input').focus();
          }, 200);
        }
      },
      removeContactEmailIfEmpty: function (contactEmail) {
        if (Ember.isBlank(contactEmail.get('email'))) {
          contactEmail.deleteRecord();
        }
      },
      removeContactPhoneIfEmpty: function (contactPhoneNumber) {
        if (Ember.isBlank(contactPhoneNumber.get('tenDigitNumber'))) {
          contactPhoneNumber.deleteRecord();
        }
      },
      doPrint: function () {
        const selector = '.incentiveContent';
        const options = {
          printDelay: 500,
          importCSS: true,
          importStyle: true
        };

        this.get('printThis').print(selector, options);
      },
      printAndAccept: function () {
        this.send('saveContact', true);
      },
      saveContact: function (print) {
        let controller = this;
        this.set('isSaving', true);
        this.get('lead').save().then(function () {
          controller.set('isSaving', false);
          controller.set('isSaved', true);
          if (controller.get('claim')) {
            controller.set('incentivesContact.claimed', true);
            controller.get('incentivesContact').save();
          }
          if (print) {
            Ember.run.later(function () {
              controller.send('doPrint');
            }, 1200);
          }
        }, function (reason) {
          controller.set('isSaving', false);
          controller.get('flashMessages').warning(reason.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
        });
      }
    }
  });

  exports.default = IncentivesController;
});