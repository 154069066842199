define('knowme-client/components/ad/resource-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    features: Ember.inject.service(),
    channelKeys: ['sem', 'mobile', 'social_media', 'online_digital', 'print', 'radio', 'television', 'direct_mail', 'email', 'telemarketing', 'general'],
    touchPointKeys: ['local_number', 'toll_free_number', 'form', 'email', 'chat'], //, 'contact_us'],
    channelOptions: Ember.computed('channelKeys', function () {
      let intl = this.get('intl');
      return this.get('channelKeys').map(function (key) {
        return { id: key, name: intl.t(`ad.channels.${key}`) };
      });
    }),
    touchPointOptions: Ember.computed('features.isKnowmeIQStarter', 'features.customersExperienceToolsAvailable', 'touchPointKeys', function () {
      let intl = this.get('intl');

      let touchPointOptions = Ember.copy(this.get('touchPointKeys'));
      if (!this.get('features.customersExperienceToolsAvailable')) {
        touchPointOptions = touchPointOptions.filter(function (x) {
          return x != 'email';
        });
      }

      if (this.get('features.isKnowmeIQStarter')) {
        touchPointOptions.removeObject('local_number');
        touchPointOptions.removeObject('toll_free_number');
        // this.get('touchPointOptions').removeObject('contact_us');
        // } else {
        // this.get('touchPointOptions').removeObject('chat');
      }

      return touchPointOptions.map(function (key) {
        let disabled = false;
        let message = '';
        if (key === 'toll_free_number' && this.get('disableTollFreeDropdown') && !this.get('features.isKnowmeIQStarter')) {
          disabled = true;
          message = 'Your Service plan does not include Toll Free Numbers, but Admin users can purchase them under Client Information/Upgrades';
        }
        if (key === 'local_number' && this.get('disableLocalDropdown') && !this.get('features.isKnowmeIQStarter')) {
          disabled = true;
          message = 'You have used all available Local numbers. Admin users can purchase them under Client Information/Upgrades.';
        }
        return { id: key, name: intl.t(`ad.touchPoints.${key}`), disabled: disabled, message: message };
      }.bind(this));
    })
  });
});