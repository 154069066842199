define('knowme-client/services/websocket', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		dispatcher: null,
		connect: function () {
			this.dispatcher = new WebSocketRails(_environment.default.APP.Websocket.host + "/websocket");
		}
	});
});