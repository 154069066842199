define('knowme-client/routes/wizard/client', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let WizardClientRoute;

  WizardClientRoute = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function () {
      return this.get('session.currentUser').then(function (currentUser) {
        return currentUser.get('account');
      }).then(function (account) {
        return account.reload();
      });
    },
    afterModel: function () {
      return Ember.RSVP.hash({
        plans: this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc' })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    }
  });

  exports.default = WizardClientRoute;
});