define('knowme-client/mirage/models/campaign', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		account: (0, _emberCliMirage.belongsTo)(),
		ads: (0, _emberCliMirage.hasMany)(),
		events: (0, _emberCliMirage.hasMany)(),
		attrs: ['id', 'name', 'ads']
	});
});