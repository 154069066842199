define('knowme-client/routes/checkout/success', ['exports', 'ember-stripe-service/utils/load-script', 'knowme-client/config/environment', 'knowme-client/lib/poller'], function (exports, _loadScript, _environment, _poller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { publishableKey } = _environment.default.stripe;

  exports.default = Ember.Route.extend({
    interval: 1000 * 5, // 30 secs
    poller: null,
    store: Ember.inject.service(),
    apiKey: Ember.inject.service(),
    model: function (params) {
      this.set('apiKey.code', params.code);
      return this.get('store').findRecord('sales-transaction', params.invoice_id);
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      let route = this;
      let poller = new _poller.default();
      poller.setInterval(this.get('interval'));
      if (!controller.get('model.isPaid') && !controller.get('model.isPaymentFailed')) {
        poller.start(this, function () {
          route.refresh();
        });
      }
      if (controller.get('model.isPaid') || controller.get('model.isPaymentFailed')) {
        poller.stop();
      }
      this.set('poller', poller);
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.set('apiKey.code', null);
        this.get('poller').stop();
      }
    }
  });
});