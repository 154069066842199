define('knowme-client/mixins/how-to-items', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    intl: Ember.inject.service(),
    items: [{
      key: 'my_set_up',
      subitems: [{ key: 'knowme_iq_number', permission: 'knowmeIQAvailable' }, { key: 'gmail_integration', permission: 'emailUnifiedCommAvailable' }, { key: 'outlook_integration', permission: 'emailUnifiedCommAvailable' }, { key: 'dashboards_order' }, { key: 'leads_and_customer_table_settings' }, { key: 'notifications_and_alerts', permission: 'alertsSectionAvailable' }, { key: 'reminders', permission: 'reminderSectionAvailable' }, { key: 'scheduled_reports', permission: 'reportScheduleAvailable' }]
    }, {
      key: 'user_setup_and_management',
      subitems: [{ key: 'add_users_and_select_roles', permission: 'usersSectionAvailable' }, { key: 'release_users_knowmeiq_number', permission: 'knowmeIQAvailable' }, { key: 'setup_notifications_and_alerts', permission: 'alertsSectionAvailable' }, { key: 'setup_scheduled_reports', permission: 'reportScheduleAvailable' }]
    }, {
      key: 'settings',
      subitems: [{ key: 'setup_products_and_services', permission: 'productsSectionAvailable' }, { key: 'setup_sales_stages_and_next_actions', permission: 'salesStagesSectionAvailable' }, { key: 'setup_opportunity_fields', permission: 'opportunityFieldsSectionAvailable' }, { key: 'setup_email_templates', permission: 'emailUnifiedCommAvailable' }, { key: 'service_plan_limits_and_upgrades', permission: 'clientInformationSectionAvailable' }, { key: 'set_dashboards_for_each_user_role', permission: 'clientInformationSectionAvailable' }, { key: 'create_campaigns_and_ads_touchpoints', permission: 'campaignsSectionAvailable' }]
    }],
    howToItems: Ember.computed('features', function () {
      let res = [];

      this.get('items').forEach(function (item) {
        if (item.key === 'user_setup_and_management' && !this.get('session.currentUser.hasAdminRole')) {
          return;
        }
        let i = {
          key: item.key,
          subitems: []
        };
        item.subitems.forEach(function (subitem) {
          if (Ember.isBlank(subitem.permission) || this.get(`features.${subitem.permission}`)) {
            if (subitem.key === 'create_campaigns_and_ads_touchpoints' && !this.get('features.campaignsAutomatedCaptureAvailable')) {
              i.subitems.push(`${subitem.key}.sales_persons`);
              return;
            }
            let title = this.get('intl').t(`how_to.${subitem.key}.title`, {
              Customers: this.get('accountService.customersText'),
              customers: this.get('accountService.customersText').toLowerCase(),
              Customer: this.get('accountService.customerText'),
              customer: this.get('accountService.customerText').toLowerCase()
            });
            if (title.match(/^missing translation:(.*)$/i)) {
              if (this.get('session.currentUser.hasAdminRole')) {
                i.subitems.push(`${subitem.key}.admins`);
              } else if (this.get('session.currentUser.hasManagerRole')) {
                i.subitems.push(`${subitem.key}.managers`);
              } else {
                i.subitems.push(`${subitem.key}.sales_persons`);
              }
            } else {
              i.subitems.push(subitem.key);
            }
          }
        }.bind(this));
        res.push(i);
      }.bind(this));

      return res;
    })
  });
});