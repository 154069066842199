define('knowme-client/controllers/account/index', ['exports', 'lodash', 'moment', 'knowme-client/mixins/credit-card-fields'], function (exports, _lodash, _moment, _creditCardFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_creditCardFields.default, {
    stripe: Ember.inject.service('stripe'),
    flashMessages: Ember.inject.service(),
    features: Ember.inject.service(),
    store: Ember.inject.service(),
    scroller: Ember.inject.service(),
    contractVersion: Ember.computed.alias('contractVersions.firstObject'),
    enableSubmitAgreement: Ember.computed.alias('model.termsOfService'),
    currentPlan: Ember.computed.alias('model.subscription.plan'),
    displayCCForm: Ember.computed.equal('model.subscription.status', 'error'),
    showCCForm: false,
    isUpgrading: false,
    isSaving: false,
    isSavingCC: false,
    invoiceDetailsModal: false,
    dashboardPermissions: [],
    upgrades: {
      additionalUsers: 0,
      additionalCallTouchPointsDidsLocal: 0,
      additionalCallTouchPointsDidsTollFree: 0,
      additionalContacts: 0,
      additionalShortMessages: 0,
      additionalMinutes: 0,
      additionalStorage: 0
    },
    isPlanDetailsOpened: true,
    isPlanLimitOpened: false,
    isUpgradesOpened: false,
    isUsageDetailsOpened: false,
    isUsageFeesOpened: false,
    isDashboardSettingsOpened: false,
    isPaymentLinkSettingsOpened: false,
    stripeLoad: function () {
      this.get('stripe').load();
    },
    upgradesFee: Ember.computed('upgrades.additionalUsers', 'upgrades.additionalCallTouchPointsDidsTollFree', 'upgrades.additionalCallTouchPointsDidsLocal', 'upgrades.additionalContacts', 'upgrades.additionalShortMessages', 'upgrades.additionalStorage', 'upgrades.additionalMinutes', 'features.activeSettings.usersAdditionalFee', 'features.activeSettings.didsAdditionalFee', 'features.activeSettings.contactsBlockFee', 'features.activeSettings.shortMessagesBlockFee', 'features.activeSettings.storageBlockFee', 'features.activeSettings.minutesBlockFee', 'features.activeSettings.minutesAdditional', 'originalAdditionalMinutesBlockFee', function () {

      let additionalMinutes = this.get('upgrades.additionalMinutes') / 200 * (this.get('features.activeSettings.minutesBlockFee') || 0);
      let additionalStorage = this.get('upgrades.additionalStorage') / 25 * (this.get('features.activeSettings.storageBlockFee') || 0);

      return this.get('upgrades.additionalUsers') * this.get('features.activeSettings.usersAdditionalFee') + this.get('upgrades.additionalContacts') / 1000 * this.get('features.activeSettings.contactsBlockFee') + this.get('upgrades.additionalShortMessages') / 200 * (this.get('features.activeSettings.shortMessagesBlockFee') || 0) + additionalStorage + additionalMinutes + (this.get('upgrades.additionalCallTouchPointsDidsTollFree') * 1 + this.get('upgrades.additionalCallTouchPointsDidsLocal') * 1) * (this.get('features.activeSettings.didsAdditionalFee') || 0);
    }),
    overageContactsBlocks: Ember.computed('accountSummary.overageContacts', 'features.activeSettings.contactsBlock', function () {
      return Math.ceil(this.get('accountSummary.overageContacts') / this.get('features.activeSettings.contactsBlock'));
    }),
    overageMinutesBlocks: Ember.computed('accountSummary.overageMinutes', 'features.activeSettings.minutesBlock', function () {
      return Math.ceil(this.get('accountSummary.overageMinutes') / this.get('features.activeSettings.minutesBlock'));
    }),
    overageMessagesBlocks: Ember.computed('accountSummary.overageMessages', 'features.activeSettings.shortMessagesBlock', function () {
      return Math.ceil(this.get('accountSummary.overageMessages') / this.get('features.activeSettings.shortMessagesBlock'));
    }),
    overageStorageBlocks: Ember.computed('accountSummary.overageStorage', 'features.activeSettings.storageBlock', function () {
      return Math.ceil(this.get('accountSummary.overageStorage') / this.get('features.activeSettings.storageBlock'));
    }),
    additionalUsageFees: Ember.computed('settings.minutesBlockFee', 'overageMinutesBlocks', 'settings.storageBlockFee', 'overageStorageBlocks', 'settings.shortMessagesBlockFee', 'overageMinutesBlocks', 'settings.contactsBlockFee', 'overageContactsBlocks', function () {
      let res = 0;
      if (this.get('settings.minutesBlockFee')) {
        res += this.get('settings.minutesBlockFee') * this.get('overageMinutesBlocks');
      }
      if (this.get('settings.shortMessagesBlockFee')) {
        res += this.get('settings.shortMessagesBlockFee') * this.get('overageMinutesBlocks');
      }
      if (this.get('settings.storageBlockFee')) {
        res += this.get('settings.storageBlockFee') * this.get('overageStorageBlocks');
      }
      if (this.get('settings.contactsBlockFee')) {
        res += this.get('settings.contactsBlockFee') * this.get('overageContactsBlocks');
      }
      return res;
    }),
    upgradesFeeInCurrentPeriod: Ember.computed('upgradesFee', 'model.subscription', 'model.activeSettings.billingTerm', function () {
      let now = (0, _moment.default)().unix();
      let endSubscription = (0, _moment.default)(this.get('model.subscription.expiresAt')).add(1, 'day').unix();

      if (this.get('model.activeSettings.billingTerm') === 'annually') {
        let startDate = (0, _moment.default)(this.get('model.subscription.expiresAt')).subtract(1, 'year');
        let endDate = (0, _moment.default)(this.get('model.subscription.expiresAt'));
        let daysInPeriod = (0, _moment.default)(endDate).diff(startDate, 'days', false);
        let feePerDay = this.get('upgradesFee') * 12 / daysInPeriod;
        let feePerHour = feePerDay / 24;
        let hoursInCurrentPeriod = (endSubscription - now) / 60 / 60;

        return hoursInCurrentPeriod * feePerHour;
      } else {
        let daysInMonth = (0, _moment.default)().daysInMonth();
        let feePerDay = this.get('upgradesFee') / daysInMonth;
        let feePerHour = feePerDay / 24;
        let hoursInCurrentPeriod = (endSubscription - now) / 60 / 60;
        return hoursInCurrentPeriod * feePerHour;
      }
    }),
    disablePurchaseButton: Ember.computed('upgradesFee', function () {
      return this.get('upgradesFee') == 0;
    }),
    disableSignUp: Ember.computed('model.creditCard', 'model.creditCard.validations.isInvalid', function () {
      return this.get('model.creditCard.validations.isInvalid');
    }),
    disableCCForm: Ember.computed('isSavingCC', 'model.creditCard', 'model.creditCard.validations.isInvalid', function () {
      return this.get('isSavingCC') || this.get('model.creditCard.validations.isInvalid');
    }),
    adminRole: Ember.computed('roles', function () {
      return this.get('roles').findBy('key', 'admin');
    }),
    masterManagerRole: Ember.computed('roles', function () {
      return this.get('roles').findBy('key', 'master_manager');
    }),
    managerRole: Ember.computed('roles', function () {
      return this.get('roles').findBy('key', 'manager');
    }),
    salesPersonRole: Ember.computed('roles', function () {
      return this.get('roles').findBy('key', 'sales_person');
    }),
    adminsWidgets: Ember.computed.filter('dashboardPermissions', function (resource) {
      return Ember.isPresent(resource) && resource.get('role.id') == this.get('adminRole.id');
    }).property('dashboardPermissions', 'dashboardPermissions.@each.role', 'adminRole'),
    masterManagersWidgets: Ember.computed.filter('dashboardPermissions', function (resource) {
      return Ember.isPresent(resource) && resource.get('role.id') == this.get('masterManagerRole.id');
    }).property('dashboardPermissions', 'dashboardPermissions.@each.role', 'masterManagerRole'),
    managersWidgets: Ember.computed.filter('dashboardPermissions', function (resource) {
      return Ember.isPresent(resource) && !resource.get('isDeleted') && resource.get('role.id') == this.get('managerRole.id');
    }).property('dashboardPermissions', 'dashboardPermissions.@each.role', 'managerRole'),
    salesPersonsWidgets: Ember.computed.filter('dashboardPermissions', function (resource) {
      return Ember.isPresent(resource) && resource.get('role.id') == this.get('salesPersonRole.id');
    }).property('dashboardPermissions', 'dashboardPermissions.@each.role', 'salesPersonRole'),
    availableWidgetsForAdmins: Ember.computed.filter('dashboardWidgets', function (widget) {
      return !this.get('adminsWidgets').mapBy('dashboardWidget.id').includes(widget.get('id'));
    }).property('dashboardWidgets', 'adminsWidgets'),
    availableWidgetsForMasterManagers: Ember.computed.filter('dashboardWidgets', function (widget) {
      return !this.get('masterManagersWidgets').mapBy('dashboardWidget.id').includes(widget.get('id'));
    }).property('dashboardWidgets', 'masterManagersWidgets'),
    availableWidgetsForManagers: Ember.computed.filter('dashboardWidgets', function (widget) {
      return !this.get('managersWidgets').mapBy('dashboardWidget.id').includes(widget.get('id'));
    }).property('dashboardWidgets', 'managersWidgets'),
    availableWidgetsForSalesPersons: Ember.computed.filter('dashboardWidgets', function (widget) {
      return !this.get('salesPersonsWidgets').mapBy('dashboardWidget.id').includes(widget.get('id'));
    }).property('dashboardWidgets', 'salesPersonsWidgets'),
    disableSubmit: Ember.computed('dashboardPermissionsHasChanged', 'dashboardPermissions.@each.isDeleted', 'dashboardPermissions.@each.hasDirtyAttributes', function () {
      let hasDeletedWidgets = this.get('dashboardPermissions').any(function (udw) {
        return Ember.isPresent(udw) && udw.get('isDeleted');
      });
      let hasAddedWidgets = this.get('dashboardPermissionsHasChanged');

      return !hasAddedWidgets && !hasDeletedWidgets;
    }),
    onBillingSectionToggle: Ember.observer('isPaymentLinkSettingsOpened', function () {
      if (this.get('isPaymentLinkSettingsOpened')) {
        Ember.run.later(this, function () {
          let target = Ember.$('#payment-link');
          this.get('scroller').scrollVertical(target, { duration: 200, offset: -1 * window.pageYOffset });
        }, 100);
      }
    }),
    onBillingEmailDeleted: Ember.observer('model.billingEmail', function () {
      if (Ember.isBlank(this.get('model.billingEmail')) && _lodash.default.keys(this.get('model').changedAttributes()).includes('billingEmail')) {
        this.get('flashMessages').warning('You must have billing email. If you deleted it then enter old one or enter new Billing email', { destroyOnClick: true, timeout: 12000 });
      }
    }),
    disableBillingInformation: Ember.computed('model.hasDirtyAttributes', 'model.displayBillingPhoneHasChanged', 'model.billingPhoneHasChanged', 'model.billingEmailHasChanged', 'model.ccBillingEmailHasChanged', 'model.billingEmail.length', 'model.validations.attrs.billingPhone.isInvalid', 'model.validations.attrs.billingEmail.isInvalid', 'model.validations.attrs.ccBillingEmail.isInvalid', function () {
      let changedAttributes = _lodash.default.keys(this.get('model').changedAttributes());
      let billingInfoHasChanged = changedAttributes.includes('displayBillingPhone') || changedAttributes.includes('billingPhone') || changedAttributes.includes('billingEmail') || changedAttributes.includes('ccBillingEmail');
      let isInvalid = this.get('model.validations.attrs.billingPhone.isInvalid') || Ember.isBlank(this.get('model.billingEmail')) || this.get('model.validations.attrs.billingEmail.isInvalid') || this.get('model.validations.attrs.ccBillingEmail.isInvalid');

      return !billingInfoHasChanged || isInvalid;
    }),
    actions: {
      triggerConfirmForm: function () {
        if (!this.get('showCCForm')) {
          if (this.get('model.hasAttachedCard')) {
            const adapter = this.get('store').adapterFor('account');
            adapter.creditCard(this.get('model')).then(function (creditCard) {
              this.set('creditCard', creditCard);
              this.set('showCCForm', !this.get('showCCForm'));
            }.bind(this));
          } else {
            let creditCard = this.get('store').createRecord('credit-card');
            this.set('model.creditCard', creditCard);
            this.set('showCCForm', !this.get('showCCForm'));
          }
        } else {
          this.set('showCCForm', !this.get('showCCForm'));
          this.set('model.creditCard', null);
        }
      },
      confirmPayment: function () {
        let controller = this;
        for (var key in this.get('upgrades')) {
          let newValue = this.get('upgrades')[key] * 1;
          if (newValue === 0) {
            continue;
          }
          let addOnKey = key.underscore().replace(/\_/g, '.');
          addOnKey = addOnKey.replace('short.messages', 'short_messages');
          addOnKey = addOnKey.replace('call.touch.points', 'call_touch_points');
          addOnKey = addOnKey.replace('toll.free', 'toll_free');
          let setting = this.get('model.settings').findBy('key', addOnKey);
          let currentValue = setting.get('value') * 1;
          setting.set('value', currentValue + newValue);
        }
        this.set('isUpgrading', true);
        controller.get('model').save().then(function (account) {
          account.belongsTo('subscription').reload();
          account.hasMany('settings').reload();
          for (var key in controller.get('upgrades')) {
            controller.set('upgrades.' + key, 0);
          }
          controller.set('showCCForm', false);
          controller.set('isUpgrading', false);
        }, function () {
          controller.set('isUpgrading', false);
        });
      },
      saveCreditCardInfo: function () {
        let controller = this;
        controller.get('stripe').card.createToken({
          number: controller.get('model.creditCard.number'),
          cvc: controller.get('model.creditCard.code'),
          exp_month: controller.get('model.creditCard.month'),
          exp_year: controller.get('model.creditCard.year')
        }).then(function (response) {
          controller.set('model.stripeToken', response.id);
          return controller.get('model').save();
        }).then(function () {
          controller.set('showCCForm', false);
          controller.send('confirmPayment');
        }).catch(function (response) {
          if (response.error && response.error.type === 'card_error') {
            // show the error in the form or something
            controller.get('flashMessages').warning(response.error.message, { destroyOnClick: true });
          } else {
            let messages = [];
            response.errors.forEach(function (error) {
              messages.push(`${error.source.pointer.split('/')[2].replace(/_/g, ' ').capitalize()} ${error.detail[0]}`);
            });
            controller.get('flashMessages').warning(messages.join('. '), { destroyOnClick: true, timeout: 12000 });
          }
        });
      },
      changeCreditCard: function () {
        let controller = this;
        this.set('isSavingCC', true);
        controller.get('stripe').card.createToken({
          number: controller.get('model.creditCard.number'),
          cvc: controller.get('model.creditCard.code'),
          exp_month: controller.get('model.creditCard.month'),
          exp_year: controller.get('model.creditCard.year')
        }).then(function (response) {
          controller.set('model.stripeToken', response.id);
          return controller.get('model').save();
        }).then(function (account) {
          return account.belongsTo('subscription').reload();
        }).then(function (subscription) {
          this.set('isSavingCC', false);
        }).catch(function (response) {
          this.set('isSavingCC', false);
          if (response.error && response.error.type === 'card_error') {
            // show the error in the form or something
            controller.get('flashMessages').warning(response.error.message, { destroyOnClick: true });
          } else {
            let messages = [];
            response.errors.forEach(function (error) {
              messages.push(`${error.source.pointer.split('/')[2].replace(/_/g, ' ').capitalize()} ${error.detail[0]}`);
            });
            controller.get('flashMessages').warning(messages.join('. '), { destroyOnClick: true, timeout: 12000 });
          }
        });
      },
      saveDashboardChanges: function () {
        let controller = this;
        let account = controller.get('model');
        account.one('didUpdate', function () {
          controller.get('store').peekAll('dashboard-permission').filter(function (_model) {
            return _model.get('isNew');
          }).invoke('deleteRecord');
          controller.get('store').peekAll('dashboard-permission').filter(function (_model) {
            return _model.get('isDeleted');
          }).invoke('unloadRecord');
        }.bind(this));
        account.save().then(function () {
          account.hasMany('dashboardPermissions').reload().then(function (dashboardPermissions) {
            controller.set('dashboardPermissions', dashboardPermissions);
          });
          controller.get('store').query('dashboard-widget', { filter: 'all' }).then(function (dashboardWidgets) {
            controller.set('dashboardWidgets', dashboardWidgets);
          });
        });
      },
      acceptAgreement: function () {
        this.get('store').createRecord('contract-agreement', { contractVersion: this.get('contractVersion') }).save().then(function () {
          this.get('target._routerMicrolib').refresh();
        }.bind(this)).catch(function (reason) {
          console.log("Errors", reason);
        });
      },
      addPaymentMethod: function () {
        let stripeAccount = this.get('store').createRecord('stripe-account');
        stripeAccount.save().then(function (sa) {
          window.open(sa.get('accountLink'), '_self').focus();
        });
      },
      saveBillingInformation: function () {
        this.get('model').save();
      },
      toggleSettingsDisplayBillingInfo: function () {
        if (this.get('model.settingsDisplayBillingInfo.value') === 1) {
          this.set('model.settingsDisplayBillingInfo.value', 0);
        } else {
          this.set('model.settingsDisplayBillingInfo.value', 1);
        }
      },
      dragEndAdminsWidget({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);

        if (!sourceList.includes(sourceItem) || !targetList.includes(sourceItem)) {
          // remove dashboard permission
          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, sourceItem.get('dashboardWidget'));
          sourceItem.deleteRecord();
        }
      },
      dragEndAvailableWidgetForAdmins({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);
        if (!sourceList.includes(sourceItem) || !targetList.includes(sourceItem)) {
          // add dashboard permission
          let account = this.get('model');

          // find if deleted
          let deletedItems = this.get('dashboardPermissions').filterBy('isDeleted', true);
          let permission = _lodash.default.find(deletedItems, function (permission) {
            return permission.get('dashboardWidget.id') == sourceItem.get('id') && permission.get('role.id') == this.get('adminRole.id');
          }.bind(this));
          // restore or create new permission
          if (permission) {
            permission.rollbackAttributes();
          } else {
            permission = this.get('store').createRecord('dashboard-permission', {
              dashboardWidget: sourceItem,
              account: account,
              role: this.get('adminRole')
            });
          }

          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, permission);
        }
      },
      dragEndMasterManagersWidget({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);

        if (!sourceList.includes(sourceItem) || !targetList.includes(sourceItem)) {
          // remove dashboard permission
          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, sourceItem.get('dashboardWidget'));
          sourceItem.deleteRecord();
        }
      },
      dragEndAvailableWidgetForMasterManagers({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);
        if (!sourceList.includes(sourceItem) || !targetList.includes(sourceItem)) {
          // add dashboard permission
          let account = this.get('model');

          // find if deleted
          let deletedItems = this.get('dashboardPermissions').filterBy('isDeleted', true);
          let permission = _lodash.default.find(deletedItems, function (permission) {
            return permission.get('dashboardWidget.id') == sourceItem.get('id') && permission.get('role.id') == this.get('masterManagerRole.id');
          }.bind(this));
          // restore or create new permission
          if (permission) {
            permission.rollbackAttributes();
          } else {
            permission = this.get('store').createRecord('dashboard-permission', {
              dashboardWidget: sourceItem,
              account: account,
              role: this.get('masterManagerRole')
            });
          }

          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, permission);
        }
      },
      dragEndManagersWidget({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);

        if (!sourceList.includes(sourceItem) || !targetList.includes(sourceItem)) {
          // remove dashboard permission
          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, sourceItem.get('dashboardWidget'));
          sourceItem.deleteRecord();
        }
      },
      dragEndAvailableWidgetForManagers({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);
        if (!sourceList.includes(sourceItem) || !targetList.includes(sourceItem)) {
          // add dashboard permission
          let account = this.get('model');

          // find if deleted
          let deletedItems = this.get('dashboardPermissions').filterBy('isDeleted', true);
          let permission = _lodash.default.find(deletedItems, function (permission) {
            return permission.get('dashboardWidget.id') == sourceItem.get('id') && permission.get('role.id') == this.get('managerRole.id');
          }.bind(this));
          // restore or create new permission
          if (permission) {
            permission.rollbackAttributes();
          } else {
            permission = this.get('store').createRecord('dashboard-permission', {
              dashboardWidget: sourceItem,
              account: account,
              role: this.get('managerRole')
            });
          }

          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, permission);
        }
      },
      dragEndSalesPersonsWidget({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);

        if (!sourceList.includes(sourceItem) || !targetList.includes(sourceItem)) {
          // remove dashboard permission
          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, sourceItem.get('dashboardWidget'));
          sourceItem.deleteRecord();
        }
      },
      dragEndAvailableWidgetForSalesPersons({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);
        if (sourceList.includes(sourceItem) && targetList.includes(sourceItem)) {
          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, sourceItem);
        } else {
          // add dashboard permission
          let account = this.get('model');

          // find if deleted
          let deletedItems = this.get('dashboardPermissions').filterBy('isDeleted', true);
          let permission = _lodash.default.find(deletedItems, function (permission) {
            return permission.get('dashboardWidget.id') == sourceItem.get('id') && permission.get('role.id') == this.get('salesPersonRole.id');
          }.bind(this));
          // restore or create new permission
          if (permission) {
            permission.rollbackAttributes();
          } else {
            permission = this.get('store').createRecord('dashboard-permission', {
              dashboardWidget: sourceItem,
              account: account,
              role: this.get('salesPersonRole')
            });
          }

          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, permission);
        }
      }
    }
  });
});