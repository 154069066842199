define('knowme-client/models/room', ['exports', 'ember-data/attr', 'ember-data/relationships', 'ember-data/model', 'moment'], function (exports, _attr, _relationships, _model, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend( /*CPValidationsCustomProperties, DirtyMixin,*/{
    session: Ember.inject.service(),
    owner: (0, _relationships.belongsTo)('user', { async: true }),
    lastMessage: (0, _relationships.belongsTo)('chat-message', { async: true }),
    roomsUsers: (0, _relationships.hasMany)('rooms-user', { async: true }),
    users: (0, _relationships.hasMany)('user', { async: true }),
    messages: (0, _relationships.hasMany)('chat-message', { async: true }),
    title: (0, _attr.default)(),
    kind: (0, _attr.default)(),
    isClosed: (0, _attr.default)('boolean'),
    members: (0, _attr.default)('json'),
    isInternal: Ember.computed('kind', function () {
      return this.get('kind') === 'general';
    }),
    avatarSmallUrl: Ember.computed('isGroup', 'users', function () {
      if (this.get('isGroup')) {
        return null;
      } else {
        let avatarSmallUrl = null;
        this.get('users').forEach(function (user) {
          if (this.get('session.currentUser.id') != user.get('id')) {
            avatarSmallUrl = user.get('avatarSmallUrl');
          }
        }.bind(this));
        return avatarSmallUrl;
      }
    }),
    titleOrName: Ember.computed('title', 'isGroup', 'members', function () {
      if (Ember.isBlank(this.get('title')) && Ember.isBlank(this.get('members'))) {
        return;
      }
      if (Ember.isBlank(this.get('title'))) {
        // exclude currentUser name from chat name
        let members = this.get('members').removeObject(this.get('session.currentUser.name'));
        return members.join(', ');
      } else {
        return this.get('title');
      }
    }),
    humanizedDate: Ember.computed('updatedAt', function () {
      let date = (0, _moment.default)(this.get('updatedAt'));
      let result = date.format('MM/DD/YY');
      let today = (0, _moment.default)();
      let diffDays = today.diff(date, 'days');
      switch (diffDays) {
        case 0:
          result = date.format('h:mm a');
          break;
        case 1:
          result = 'Yesterday';
          break;
        case 2:
        case 3:
        case 5:
        case 6:
        // result = date.format('ddd');
        // break;
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
          result = 'a weak ago';
          break;
      }
      return result;
    }),
    unreadMessagesCount: (0, _attr.default)('number'),
    readMessages: (0, _attr.default)('boolean', { default: false }),
    isGroup: (0, _attr.default)('boolean'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    isUntouched: (0, _attr.default)('boolean'),
    isTouched: (0, _attr.default)('boolean'),
    isOnline: Ember.computed('isGroup', 'users.@each.isOnline', 'session.currentUser', function () {
      if (this.get('isGroup')) {
        return false;
      }
      let member = this.get('users').filter(function (m) {
        return m.get('id') !== this.get('session.currentUser.id');
      }.bind(this))[0];

      return Ember.isPresent(member) && member.get('isOnline');
    }),
    lastMessagePreview: Ember.computed('lastMessage.id', 'lastMessage.preview', function () {
      return this.get('lastMessage.preview');
    })
  });
});