define('knowme-client/models/short-message', ['exports', 'ember-data/relationships', 'ember-data/attr', 'knowme-client/models/communication-resource'], function (exports, _relationships, _attr, _communicationResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _communicationResource.default.extend({
    // relationships
    lead: (0, _relationships.belongsTo)('lead', { async: true }),
    contact: (0, _relationships.belongsTo)('contact', { async: true, inverse: 'shortMessages' }),
    contactPhoneNumber: (0, _relationships.belongsTo)('contact-phone-number', { async: true }),
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true, inverse: 'shortMessages' }),
    user: (0, _relationships.belongsTo)('user', { async: true, inverse: 'shortMessages' }),
    did: (0, _relationships.belongsTo)('did', { async: true, inverse: 'shortMessages' }),
    communication: (0, _relationships.belongsTo)('communication', { async: true }),
    customerExperience: (0, _relationships.belongsTo)('customer-experience', { async: true, inverse: 'customerExperienceResource' }),
    //fields
    attachments: (0, _attr.default)(),
    from: (0, _attr.default)(),
    to: (0, _attr.default)(),
    shortMessageType: (0, _attr.default)(),
    shortMessageDate: (0, _attr.default)('date'),
    body: (0, _attr.default)(),
    satisfaction: (0, _attr.default)(),
    participantsOpportunityShortMessagesCount: (0, _attr.default)(),
    canBeRead: (0, _attr.default)('boolean'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    command: Ember.computed('body', function () {
      let command = null;
      switch (true) {
        case /^stop$/i.test(this.get('body')):
          command = 'stop';
          break;
        case /^start$/i.test(this.get('body')):
          command = 'restart';
          break;
        case /^yes/i.test(this.get('body')):
          command = 'start';
          break;
        default:
          break;
      }
      return command;
    }),
    isInbound: Ember.computed('shortMessageType', function () {
      return this.get('shortMessageType') === 'inbound';
    }),
    formattedTenDigitNumber: Ember.computed('from', 'to', function (number) {
      if (number.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
        return number.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
      } else {
        return number;
      }
    }),
    allocatable: Ember.computed.alias('did.didAllocation.allocatable'),
    allocatableModelName: Ember.computed.alias('did.didAllocation.allocatable._internalModel.modelName')
  });
});