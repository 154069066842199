define('knowme-client/helpers/is-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isUrl = isUrl;
  function isUrl(params /*, hash*/) {
    return Ember.isPresent(params[0].match(/(https?:\/\/[^\s]+)/g));
  }

  exports.default = Ember.Helper.helper(isUrl);
});