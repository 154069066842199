define('knowme-client/components/short-message/display-body', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		urlRegex: /(https?:\/\/[^\s]+)/g,
		classNames: ['display-inline-block'],
		symbolWidth: 6.4,
		hasMultipleOpportunities: false,
		elementIsInserted: false,
		didInsertElement: function () {
			this._super(...arguments);
			this.set('elementIsInserted', true);
		},
		viewAllVisible: Ember.computed('hasMultipleOpportunities', 'elementIsInserted', function () {
			if (this.get('elementIsInserted')) {
				let width = this.$().parent().css('width').replace(/px/g, '');
				width = parseFloat(width);
				width = Math.round(width);
				if (this.get('hasMultipleOpportunities')) {
					width += 20;
				}
				if (this.get('bodyList').length === 1) {
					if (this.get('bodyList')[0].length * this.get('symbolWidth') > width) {
						return true;
					}
				} else {
					let length = 0;
					this.get('bodyList').forEach((item, i) => {
						if (Ember.isPresent(item.match(/(https?:\/\/[^\s]+)/g))) {
							length += 10;
						} else {
							length += item.length;
						}
					});
					if (length * this.get('symbolWidth') > width) {
						return true;
					}
				}
			}
			return false;
		}),
		isIncentiveMessage: Ember.computed('incentiveId', function () {
			return Ember.isPresent(this.get('incentiveId'));
		}),
		bodyList: Ember.computed('body', function () {
			return (this.get('body') || '').split(this.urlRegex);
		}),
		actions: {
			openedModal: function () {
				this.sendAction('openedModal');
			},
			closedModal: function () {
				this.sendAction('closedModal');
			}
		}
	});
});