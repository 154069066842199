define('knowme-client/components/km-ed-group-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let KmEdGroupSelectComponent;

  KmEdGroupSelectComponent = Ember.Component.extend({
    tagName: 'select',
    classNames: ['form-control', 'input-sm'],
    attributeBindings: ['disabled'],
    content: null,
    groupPath: null,
    prompt: false,
    selectedValue: null,
    isOpen: false,
    change: function () {
      const selectedOption = this.$('option:selected');
      const selectedOptGroup = selectedOption.parent();
      const parentIndex = this.get('prompt') ? selectedOptGroup.index() - 1 : selectedOptGroup.index();
      const childrenIndex = selectedOption.index();
      const parent = Ember.isPresent(this.get('content')) ? this.get('content').objectAt(parentIndex) : null;
      let children;
      if (parent) {
        children = parent.get(this.get('groupPath')).objectAt(childrenIndex);
      } else {
        children = null;
      }
      this.set('selectedValue', children);
      this.set('isOpen', false);
    },
    focusIn: function () {
      this.set('isOpen', true);
    },
    focusOut: function () {
      this.set('isOpen', false);
    }
  });

  exports.default = KmEdGroupSelectComponent;
});