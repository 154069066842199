define('knowme-client/mirage/factories/campaign', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: _faker.default.company.catchPhrase,
    revenue: function () {
      return _faker.default.datatype.number({ min: 0, max: 2000 });
    },
    cost: function () {
      return _faker.default.datatype.number({ min: 0, max: 2000 });
    },
    leadsCount: function () {
      return _faker.default.datatype.number({ min: 0, max: 10 });
    },
    customersCount: function () {
      return _faker.default.datatype.number({ min: 0, max: 10 });
    },
    costPerLead: function () {
      return _faker.default.datatype.number({ min: 0, max: 200 });
    },
    pipeline: function () {
      return _faker.default.datatype.number({ min: 0, max: 10000 });
    },
    totalCost: function () {
      return _faker.default.datatype.number({ min: 0, max: 10000 });
    },
    roi: function () {
      return _faker.default.datatype.number({ min: 0, max: 20 });
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    withAd: (0, _emberCliMirage.trait)({
      afterCreate(campaign, server) {
        var role = server.create('ad', { campaign: campaign, name: 'Default' });
        // user.update('roles', [role]);
      }
    })
  });
});