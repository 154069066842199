define('knowme-client/helpers/humanize-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeDate = humanizeDate;
  function humanizeDate(params /*, hash*/) {
    let date = Ember.isBlank(params[0]) ? (0, _moment.default)() : (0, _moment.default)(params[0]);
    let result = date.format('MM/DD/YY');
    let today = (0, _moment.default)();
    let diffDays = today.diff(date, 'days');
    switch (diffDays) {
      case 0:
        result = 'Today';
        break;
      case 1:
        result = 'Yesterday';
        break;
      case 2:
      case 3:
      case 5:
      case 6:
      // result = date.format('dddd');
      // break;
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
        result = date.format('MM/DD/YY');
        break;
    }

    return result;
  }

  exports.default = Ember.Helper.helper(humanizeDate);
});