define('knowme-client/routes/account/settings/organization-fields', ['exports', 'lodash', 'knowme-client/mixins/routes/account-setup'], function (exports, _lodash, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    queryParams: {},
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    pagination: {},
    beforeModel: function (transition) {
      return this._super(...arguments).then(function () {
        if (!this.get('features.organizationFieldsSectionAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Access Unauthorized', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        }
        if (Ember.isBlank(transition.queryParams.status)) {
          this.transitionTo({ queryParams: { status: 'active' } });
        }
      }.bind(this));
    },
    model: function (params) {
      let promise;
      promise = this.get('store').query('organization-field', this.processParams(params));
      promise.then(result => {
        this.set('pagination.total', result.meta.pagination.totalCount);
        this.set('pagination.limit', result.meta.pagination.page.size);
        this.set('pagination.page', result.meta.pagination.page.number);
      });
      return promise;
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);
      params.paginate = false;
      return _lodash.default.pickBy(params, _lodash.default.identity);
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
    },
    actions: {
      queryParamsDidChange: function () {
        this.refresh();
      },
      reloadModel: function () {
        this.refresh();
      }
    }
  });
});