define('knowme-client/components/number-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    keyDown: 'none',
    keyUp: 'none',
    min: 0,
    step: 1,
    actions: {
      none: function () {},
      blockInput: function () {
        this.set('value', 0);
      },
      increase: function () {
        this.set('value', this.get('value') * 1 + this.get('step') * 1);
      },
      decrease: function () {
        let value = this.get('value') * 1 - this.get('step') * 1;
        if (value >= this.get('min')) {
          this.set('value', value);
        }
      }
    }
  });
});