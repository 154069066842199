define('knowme-client/components/forms/scheduler-form', ['exports', 'knowme-client/mixins/controllers/report-schedule'], function (exports, _reportSchedule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_reportSchedule.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    selectedAmPm: 'AM',
    scheduleHours: Array.apply(null, Array(12)).map(function (v, i) {
      if (i < 9) {
        return '0' + (i + 1);
      } else {
        return i + 1;
      }
    }),
    scheduleMinutes: Array.apply(null, Array(12)).map(function (v, i) {
      if (i < 2) {
        return '0' + i * 5;
      } else {
        return i * 5;
      }
    }),
    scheduleAmPm: ['AM', 'PM'],
    setScheduledTime: Ember.observer('selectedHours', 'selectedMinutes', 'selectedAmPm', function () {
      if (Ember.isPresent(this.get('selectedHours')) && Ember.isPresent(this.get('selectedMinutes')) && Ember.isPresent(this.get('selectedAmPm'))) {
        this.get('model').set('scheduledTime', this.get('selectedHours') + ':' + this.get('selectedMinutes') + ' ' + this.get('selectedAmPm'));
      }
    }),
    didReceiveAttrs: function () {
      if (!this.get('model.isNew')) {
        // parse hours and minutes
        let time = this.get('model').get('scheduledTime');
        let parts = time.split(' ');
        let h = parts[0].split(':')[0] * 1;
        let m = parts[0].split(':')[1] * 1;
        if (h < 10) {
          h = '0' + h;
        }
        if (m < 10) {
          m = '0' + m;
        }
        this.set('selectedHours', h);
        this.set('selectedMinutes', m);
        this.set('selectedAmPm', parts[1].toUpperCase());
      }
    },
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.campaignHasChanged', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.campaignHasChanged');
    }),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'isModelTreeDirty', function () {
      return !(this.get('model.validations.isValid') && this.get('isModelTreeDirty'));
    }),
    actions: {
      addUserEmail(user) {
        let recipients = Ember.isBlank(this.get('model.recipients')) ? user.get('email') : `${this.get('model.recipients')}, ${user.get('email')}`;
        this.set('model.recipients', recipients);
      },
      setScheduledDay: function (day, event) {
        if (event.target.checked) {
          this.get('model.scheduledDays').pushObject(day);
        } else {
          this.get('model.scheduledDays').removeObject(day);
        }
      },
      submit: function (step) {
        this.get('model').save().then(function () {
          if (step === 'close') {
            this.get('router').transitionTo('reports.scheduler');
          }
          this.get('router._router._routerMicrolib').refresh();
        }.bind(this)).catch(function (reason) {
          console.log("Errors", reason);
        });
      },
      clear: function () {
        this.get('model').rollbackAttributes();
      }
    }
  });
});