define('knowme-client/components/account/settings-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    selectedPlan: Ember.computed('account.subscription.plan.key', function () {
      return this.get('account.subscription.plan');
    }),
    settingAdditionalUsersCost: Ember.computed('addOns.additionalUsers', 'planSettings.additionalUsersFee', function () {
      return (this.get('addOns.additionalUsers') || 0) * (this.get('planSettings.additionalUsersFee') || 0);
    }),
    settingsAdditionalUsersDidsLocalCost: Ember.computed('addOns.additionalUsersDidsLocal', 'planSettings.additionalDidsFee', function () {
      return (this.get('addOns.additionalUsersDidsLocal') || 0) * (this.get('planSettings.additionalDidsFee') || 0);
    }),
    settingsAdditionalCallTouchPointsDidsTollFreeCost: Ember.computed('addOns.additionalCallTouchPointsDidsTollFree', 'planSettings.additionalDidsFee', function () {
      return (this.get('addOns.additionalCallTouchPointsDidsTollFree') || 0) * (this.get('planSettings.additionalDidsFee') || 0);
    }),
    settingsAdditionalCallTouchPointsDidsLocalCost: Ember.computed('addOns.additionalCallTouchPointsDidsLocal', 'planSettings.additionalDidsFee', function () {
      return (this.get('addOns.additionalCallTouchPointsDidsLocal') || 0) * (this.get('planSettings.additionalDidsFee') || 0);
    }),
    settingsAdditionalMinutesCost: Ember.computed('addOns.additionalMinutes', 'planSettings.additionalMinutesBlock', 'planSettings.additionalMinutesBlockFee', function () {
      if (Ember.isBlank(this.get('planSettings.additionalMinutesBlock')) || Ember.isBlank(this.get('planSettings.additionalMinutesBlockFee'))) {
        return 0;
      }
      return (this.get('addOns.additionalMinutes') || 0) / this.get('planSettings.additionalMinutesBlock') * this.get('planSettings.additionalMinutesBlockFee');
    }),
    settingsAdditionalShortMessagesCost: Ember.computed('addOns.additionalShortMessages', 'planSettings.additionalShortMessagesBlock', 'planSettings.additionalShortMessagesBlockFee', function () {
      if (Ember.isBlank(this.get('planSettings.additionalShortMessagesBlock')) || Ember.isBlank(this.get('planSettings.additionalShortMessagesBlockFee'))) {
        return 0;
      }
      return (this.get('addOns.additionalShortMessages') || 0) / (this.get('planSettings.additionalShortMessagesBlock') || 0) * (this.get('planSettings.additionalShortMessagesBlockFee') || 0);
    }),
    settingsAdditionalContactsCost: Ember.computed('addOns.additionalContacts', function () {
      if (Ember.isBlank(this.get('planSettings.additionalContactsBlock')) || Ember.isBlank(this.get('planSettings.additionalContactsBlockFee'))) {
        return 0;
      }
      return (this.get('addOns.additionalContacts') || 0) / (this.get('planSettings.additionalContactsBlock') || 0) * (this.get('planSettings.additionalContactsBlockFee') || 0);
    }),
    settingsAdditionalStorageCost: Ember.computed('addOns.additionalStorage', function () {
      if (Ember.isBlank(this.get('planSettings.additionalStorageBlock')) || Ember.isBlank(this.get('planSettings.additionalStorageBlockFee'))) {
        return 0;
      }
      return (this.get('addOns.additionalStorage') || 0) / (this.get('planSettings.additionalStorageBlock') || 0) * (this.get('planSettings.additionalStorageBlockFee') || 0);
    }),
    totalFee: Ember.computed('planSettings.planFee', 'settingAdditionalUsersCost', 'settingsAdditionalUsersDidsLocalCost', 'settingsAdditionalCallTouchPointsDidsTollFreeCost', 'settingsAdditionalCallTouchPointsDidsLocalCost', 'settingsAdditionalMinutesCost', 'settingsAdditionalShortMessagesCost', 'settingsAdditionalContactsCost', 'settingsAdditionalStorageCost', 'planSettings.accountDiscount.value', function () {
      let planFee = this.get('planSettings.planFee');
      let additionalUsersCost = this.get('settingAdditionalUsersCost');
      let additionalUsersDidsLocalCost = this.get('settingsAdditionalUsersDidsLocalCost');
      let additionalCallTouchPointsDidsTollFreeCost = this.get('settingsAdditionalCallTouchPointsDidsTollFreeCost');
      let additionalCallTouchPointsDidsLocalCost = this.get('settingsAdditionalCallTouchPointsDidsLocalCost');
      let additionalMinutesCost = this.get('settingsAdditionalMinutesCost');
      let additionalShortMessagesCost = this.get('settingsAdditionalShortMessagesCost');
      let additionalContactsCost = this.get('settingsAdditionalContactsCost');
      let additionalStorageCost = this.get('settingsAdditionalStorageCost');
      let discount = this.get('planSettings.accountDiscount.value');

      let totalFee = planFee + additionalUsersCost + additionalUsersDidsLocalCost + additionalCallTouchPointsDidsTollFreeCost + additionalCallTouchPointsDidsLocalCost + additionalMinutesCost + additionalShortMessagesCost + additionalContactsCost + additionalStorageCost;

      if (Ember.isPresent(discount)) {
        totalFee = totalFee * ((100 - discount) / 100);
      }
      if (isNaN(totalFee)) {
        return 0;
      }
      return totalFee;
    })
  });
});