define('knowme-client/routes/profile', ['exports', 'knowme-client/config/environment', 'knowme-client/mixins/routes/account-setup', 'knowme-client/mixins/routes/cable-setup'], function (exports, _environment, _accountSetup, _cableSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    channelName: 'UserChannel',
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    email: Ember.inject.service('email-connect'),
    flashMessages: Ember.inject.service(),
    ajax: Ember.inject.service('authorized-ajax'),
    uncollapseEmailIntergationSection: false,
    beforeModel: function (transition) {
      this._super(...arguments);
      let startEmailIntegration = this.controllerFor('application').get('startEmailIntegration');
      if (Ember.isPresent(startEmailIntegration) && startEmailIntegration) {
        this.set('uncollapseEmailIntergationSection', true);
        this.get('flashMessages').info("In order to be able to send and receive your emails you need to complete integration through Google or Microsoft Outlook", { destroyOnClick: true });
        this.controllerFor('application').set('startEmailIntegration', false);
      } else {
        this.set('uncollapseEmailIntergationSection', false);
      }
    },
    model: function () /*params*/{
      return this.get('session.currentUser').then(function (user) {
        return user.hasMany('roles').reload();
      }).then(function (roles) {
        let user = this.get('session.currentUser');
        if (Ember.isBlank(user.get('didAllocation.content'))) {
          this.store.createRecord('did-allocation', { allocatable: user });
        }
        return user;
      }.bind(this));
    },
    afterModel: function (model) {
      let requests = {};
      let currentUser = this.get('session.currentUser');
      if (currentUser.get('hasAdminRole')) {
        requests.roles = this.get('store').query('role', { paginate: false, sort_by: 'name', order: 'asc' });
      }
      requests.dashboardWidgets = this.get('store').query('dashboard-widget', { filter: 'all' });
      requests.usersWidgets = model.get('usersDashboardWidgets');
      requests.leadsColumnSettings = this.get('store').query('column-setting', { target_name: 'leads' });
      requests.customersColumnSettings = this.get('store').query('column-setting', { target_name: 'customers' });

      if (Ember.isPresent(currentUser.get('authentications.firstObject'))) {
        requests.signature = currentUser.get('authentications.firstObject').get('signature');
      }

      return Ember.RSVP.hash(requests).then(function (results) {
        let roles = results.roles ? results.roles.toArray() : [];
        if (!currentUser.get('hasAdminRole')) {
          roles = [currentUser.get('roles.firstObject')];
        }
        let signature = results.signature;
        if (Ember.isPresent(signature)) {} else {
          signature = this.get('store').createRecord('signature', { authentication: currentUser.get('authentications.firstObject') });
        }
        this.set('controllerData', {
          dashboardWidgets: results.dashboardWidgets,
          usersDashboardWidgets: results.usersWidgets.toArray(),
          roles: roles,
          leadsColumnSettings: results.leadsColumnSettings,
          customersColumnSettings: results.customersColumnSettings,
          signature: signature
        });
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);

      controller.set('uncollapseEmailIntergationSection', this.get('uncollapseEmailIntergationSection'));
      controller.initRoleSections(this.get('controllerData.roles').mapBy('key'));

      controller.set('howToItem', localStorage['howToItem']);
      localStorage.removeItem('howToItem');

      controller.set('howToSubItem', localStorage['howToSubItem']);
      localStorage.removeItem('howToSubItem');

      if (Ember.isPresent(controller.get('howToItem')) || Ember.isPresent(controller.get('howToSubItem'))) {
        controller.set('toggleHowTo', 'show');
      } else {
        controller.set('toggleHowTo', 'hide');
      }
      controller.setProperties(this.get('controllerData'));

      this.set('controllerData', {});
    },
    afterReceived: function (controller, payload, action) {
      controller.get('model').hasMany('devices').reload();
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.set('toggleHowTo', 'hide');
        controller.set('toggleRolePermissions', 'hide');
        controller.get('model').rollbackAttributes();
        let didAllocation = controller.get('model.didAllocation');
        if (didAllocation.get('isNew')) {
          didAllocation.set('didNumber', '');
        }
        controller.get('store').peekAll('users-dashboard-widget').toArray().forEach(function (udw) {
          if (udw.get('isNew')) {
            this.get('model.usersDashboardWidgets').removeObject(udw);
            udw.deleteRecord();
          } else {
            udw.rollbackAttributes();
          }
        });
      }
    },
    actions: {
      authenticateGoogle: function () {
        let self = this;
        let adapter = Ember.getOwner(this).lookup('adapter:application');
        var redirectUri = adapter.buildURL(null, null, null, "host");
        let currentUser = this.get('session.currentUser');

        Ember.getOwner(this).lookup('torii-provider:google-oauth2').set('redirectUri', redirectUri);

        return this.get('torii').open('google-oauth2').then(function (googleAuth) {
          var googleToken = googleAuth.authorizationCode;
          // var redirectUri = googleAuth.redirectUri;

          let headers = this.get('ajax.headers');
          $.post({
            url: `${_environment.default.APP.email_service.scheme}://${_environment.default.APP.email_service.host}/authentications/auth/google_oauth2/callback`,
            data: { code: googleToken, redirect_uri: redirectUri, user_role: currentUser.get('role.key'), account_id: currentUser.get('account.id') },
            headers: headers
          }).then(function (response) {
            if (response.status === "ok") {
              currentUser.get('authentications').reload().then(function () {
                if (Ember.isPresent(currentUser.get('authentications.firstObject'))) {
                  currentUser.get('authentications.firstObject').get('signature').then(function (signature) {
                    if (Ember.isPresent(signature)) {} else {
                      signature = self.get('store').createRecord('signature', { authentication: currentUser.get('authentications.firstObject') });
                    }
                    self.controller.set('signature', signature);
                  });
                } else {
                  self.controller.set('signature', null);
                }
              }.bind(this));
            } else {
              self.get('flashMessages').warning(response.error, { destroyOnClick: true, timeout: 5000 });
              console.log("Unable to authorize");
            }
          });
        }.bind(this), function (error) {
          console.error('Google auth failed: ', error.message);
        });
      },
      authenticateOutlook: function () {
        let self = this;
        let adapter = Ember.getOwner(this).lookup('adapter:application');
        let redirectUri = adapter.buildURL(null, null, null, "host");
        let currentUser = this.get('session.currentUser');
        return this.get('torii').open('outlook-oauth2').then(function (outlookAuth) {
          var outlookToken = outlookAuth.authorizationCode;
          let headers = this.get('ajax.headers');
          $.post({
            url: `${_environment.default.APP.email_service.scheme}://${_environment.default.APP.email_service.host}/authentications/auth/outlook_oauth2/callback`,
            data: { code: outlookToken, redirect_uri: redirectUri, user_role: currentUser.get('role.key'), account_id: currentUser.get('account.id') },
            headers: headers
          }).then(function (response) {
            if (response.status === "ok") {
              currentUser.get('authentications').reload().then(function () {
                if (Ember.isPresent(currentUser.get('authentications.firstObject'))) {
                  currentUser.get('authentications.firstObject').get('signature').then(function (signature) {
                    if (Ember.isPresent(signature)) {} else {
                      signature = self.get('store').createRecord('signature', { authentication: currentUser.get('authentications.firstObject') });
                    }
                    self.controller.set('signature', signature);
                  });
                } else {
                  self.controller.set('signature', null);
                }
              }.bind(this));
            } else {
              self.get('flashMessages').warning(response.error, { destroyOnClick: true, timeout: 5000 });
              console.log("Unable to authorize");
            }
          });
        }.bind(this), function (error) {
          console.error('Outlook auth failed: ', error.message);
        });
      },
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        model.rollbackAttributes();
      }
    }
  });
});