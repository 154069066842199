define('knowme-client/helpers/lcm', ['exports', 'ember-math-helpers/helpers/lcm'], function (exports, _lcm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _lcm.default;
    }
  });
  Object.defineProperty(exports, 'lcm', {
    enumerable: true,
    get: function () {
      return _lcm.lcm;
    }
  });
});