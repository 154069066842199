define('knowme-client/routes/account/settings/reviews/show', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      return this.get('store').find('review-link', params.id);
    },
    afterModel: function (model) {
      return Ember.RSVP.hash({
        reviewLinksContacts: this.get('store').query('review-links-contact', { paginate: false, review_link_id: model.get('id') })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller, model) {
      this._super(...arguments); // Call _super for default behavior
      this.controllerFor('account.settings.reviews').set('currentReview', model);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      willTransition: function () /*transition*/{
        this.controllerFor('account.settings.reviews').set('currentReview', null);
      }
    }
  });
});