define('knowme-client/models/file', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    filename: (0, _attr.default)(),
    shortUrl: (0, _attr.default)(),
    byteSize: (0, _attr.default)()
  });
});