define('knowme-client/routes/leads/show/opportunities/index', ['exports', 'knowme-client/mixins/routes/account-setup', 'knowme-client/mixins/routes/cable-setup', 'ember-local-storage'], function (exports, _accountSetup, _cableSetup, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // const { isPresent } = Ember;
  // const { hash } = Ember.RSVP;

  let LeadsShowOpportunitiesIndexRoute;
  // import CPNSubscriber from '../../../../mixins/routes/contact-phone-number-subscriber';


  LeadsShowOpportunitiesIndexRoute = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    lastOpportunity: (0, _emberLocalStorage.storageFor)('last-opportunity'),
    store: Ember.inject.service(),
    channelName: 'OpportunityChannel',
    model: function () /*params*/{
      let lead = this.modelFor('leads.show');
      this.set('lead', lead);
      let opportunities = lead.get('sortedOpportunities');
      if (!this.get('lastOpportunity.id')) {
        this.set('lastOpportunity.id', opportunities.get('firstObject.id'));
      }

      return opportunities;
    },
    afterModel: function (model) {
      let lastOpportunity = this.get('lastOpportunity');
      this.set('subscriptionData', { id: this.get('lead.id') });
      if (!model.isAny('id', lastOpportunity.id)) {
        lastOpportunity.clear();
        this.set('lastOpportunity.id', model.get('lastObject.id'));
      }
      return Ember.RSVP.hash({
        incentives: this.get('store').query('incentive', { paginate: false, status: 'active' })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('lead', this.get('lead'));
      // this.subscribeToContactPhoneChannel(this.get('lead.id'));
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    resetController: function () {
      this._super.apply(this, arguments);
      // this.unsubscribeFromContactPhoneChannel();
    },
    renderTemplate: function (controller, model) {
      return this.render('leads/show/opportunities/index', {
        into: 'application',
        model: model
      });
    },
    afterReceived(controller, payload) {
      if (payload.type && payload.type === 'opportunity') {
        let opportunity = controller.get("store").peekRecord('opportunity', payload.id);

        opportunity.get('lastActivity').then(function (activity) {
          if (["contact_opted_in", "stop_texting", "request_sent"].includes(activity.get('key'))) {
            controller.set('isRefreshingSMSButtons', true);
            Ember.run.later(function () {
              controller.set('isRefreshingSMSButtons', false);
            }, 5);
          }
        });
      }
    },
    actions: {
      willTransition: function (transition) {
        //Clear localStorage when transition to other route that does not belong to leads.show
        if (!transition.params["leads.show"]) {
          this.get('lastOpportunity').clear();
        }
        this.set('selectedActivity', null);
      }
    }

  });

  exports.default = LeadsShowOpportunitiesIndexRoute;
});