define('knowme-client/components/bs-datepicker', ['exports', 'ember-cli-bootstrap-datetimepicker/components/bs-datetimepicker'], function (exports, _bsDatetimepicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bsDatetimepicker.default.extend({
    // bs-contextual uses willRemoveElement
    willDestroyElement() {
      // this._super(...arguments);
      this.removeObserver('date');
      this.removeObserver('maxDate');
      this.removeObserver('minDate');
      this.removeObserver('locale');
      this.removeObserver('format');
      this.removeObserver('viewMode');
      this.removeObserver('timeZone');

      // Running the `ember` application embedded might cause the DOM to be cleaned before
      let dateTimePicker = this.$().data('DateTimePicker');
      if (dateTimePicker) {
        dateTimePicker.destroy();
      }
    }
  });
});