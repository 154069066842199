define('knowme-client/serializers/contact-phone-number-json', ['exports', 'knowme-client/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    attrs: {
      contactInfo: { serialize: false },
      calls: { serialize: false }
    }
  });
});