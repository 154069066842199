define('knowme-client/models/product', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'knowme-client/mixins/models/validations/product', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'knowme-client/mixins/models/status'], function (exports, _relationships, _attr, _model, _product, _emberCpValidationsCustomProperties, _dirty, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Product;

  Product = _model.default.extend(_product.default, _emberCpValidationsCustomProperties.default, _dirty.default, _status.default, {
    orders: (0, _relationships.hasMany)('orders', { async: true }),
    photos: (0, _relationships.hasMany)('file', { async: true }),
    files: (0, _attr.default)(),
    kind: (0, _attr.default)(),
    name: (0, _attr.default)(),
    sku: (0, _attr.default)(),
    publishResource: (0, _attr.default)(),
    isQuickBooksItem: (0, _attr.default)('boolean', { defaultValue: false }),
    incomeAccount: (0, _attr.default)(),
    quantity: (0, _attr.default)('number'),
    soldNumber: (0, _attr.default)('number'),
    price: (0, _attr.default)('number', { defaultValue: null }),
    cost: (0, _attr.default)('number', { defaultValue: 0 }),
    taxable: (0, _attr.default)('boolean', { defaultValue: null }),
    taxRate: (0, _attr.default)('number', { defaultValue: 0 }),
    maxDiscount: (0, _attr.default)('number', { defaultValue: 0 }),
    fixedPrice: (0, _attr.default)('boolean', { defaultValue: false }),
    outAfterSold: (0, _attr.default)('boolean', { defaultValue: false }),
    description: (0, _attr.default)(),
    inventory: (0, _attr.default)(),
    inventoryTitle: Ember.computed('inventory', 'quantity', 'kind', 'soldNumber', function () {
      if (['product', 'bundle'].includes(this.get('kind'))) {
        return this.get('quantity') > 0 ? this.get('quantity') - this.get('soldNumber') : 'Non-inventory';
      } else {
        return 'n/a';
      }
    }),
    maxDiscountPercentage: Ember.computed('maxDiscount', function () {
      return (this.get('maxDiscount') || 0) / 100;
    }),
    onFixedChange: Ember.observer('fixedPrice', function () {
      if (this.get('fixedPriceHasChanged')) {
        this.set('maxDiscount', 0);
      }
    }),
    isSoldOut: Ember.computed.equal('status', 'sold-out')
  });

  exports.default = Product;
});