define('knowme-client/mirage/factories/column-setting', ['exports', 'faker', 'ember-cli-mirage'], function (exports, _faker, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    key: _faker.default.company.catchPhrase,
    name: _faker.default.company.catchPhrase,
    targetName: _faker.default.company.catchPhrase
  });
});