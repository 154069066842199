define('knowme-client/controllers/sales-stages', ['exports', 'moment', 'knowme-client/mixins/date-filter', 'knowme-client/mixins/controllers/queryable', 'knowme-client/mixins/controllers/pagination'], function (exports, _moment, _dateFilter, _queryable, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _queryable.default, _pagination.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    account: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    queryParams: ['filter', 'sales_stage_id', 'user_id'],
    converted: null,
    filter: null,
    user_id: null,
    sales_stage_id: null,
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    onSearchByName: Ember.observer('dateFilterSelected', 'filter', 'sales_stage_id', 'user_id', 'query', function () {
      this.set('page', null);
    }),
    availableUsersFilter: Ember.computed('session', 'session.currentUser', function () {
      return !this.get('session').get('currentUser.isSalesPerson');
    }),
    filters: Ember.computed('accountService.customersText', function () {
      let customersText = this.get('accountService.customersText');
      return [{ id: 'leads', name: 'All Leads' }, { id: 'customers', name: `All ${customersText}` }];
    }),
    salesStage: Ember.computed('sales_stage_id', 'salesStages.length', function () {
      if (this.get('salesStages.length') > 0) {
        return this.get('salesStages').findBy('id', this.get('sales_stage_id'));
      }
    }),
    dateFilterPromptText: Ember.computed('salesStage.key', function () {
      let text = 'Filter by Date';
      if (this.get('salesStage.key') === 'appointment') {
        text = 'Filter by App. Date';
      }
      return text;
    }),
    dateFilterOptions: Ember.computed('salesStage.key', function () {
      let options = [{ id: 'today', name: 'Today Only' }, { id: 'todayAndYesterday', name: 'Since Yesterday' }, { id: 'todayAndPrevious2Days', name: 'Last 3 Days' }, { id: 'todayAndPrevious6Days', name: 'Last 7 Days' }, { id: 'todayAndPrevious29Days', name: 'Last 30 Days' }, { id: 'previousMonth', name: 'Last Month: ' + (0, _moment.default)().subtract(1, 'months').format('MMMM YYYY') }, { id: 'yearToDate', name: 'Year to Date (YTD)' }, { id: 'previousYear', name: 'Last Year: ' + (0, _moment.default)().subtract(1, 'year').format('YYYY') }];
      if (this.get('salesStage.key') === 'appointment') {
        options = [{ id: 'today', name: 'Today Only' }, { id: 'todayAndNext6Days', name: 'Today and Next 6 Days' }, { id: 'todayAndNext29Days', name: 'Today and Next 29 Days' }];
      }
      return options;
    }),
    pipelineTotal: Ember.computed('model.@each.pipeline', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('pipeline');
      }, 0);
    }),
    revenueTotal: Ember.computed('model.@each.revenue', function () {
      return this.get('model').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('revenue');
      }, 0);
    }),
    actions: {
      startListening: function () {
        this.send('pauseRefreshing');
      },
      stopListening: function () {
        this.send('continueRefreshing');
      }
    }
  });
});