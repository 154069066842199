define('knowme-client/controllers/account/settings/incentives', ['exports', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/status', 'knowme-client/mixins/controllers/queryable'], function (exports, _pagination, _status, _queryable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, _status.default, _queryable.default, {
    queryParams: ['page', 'status', 'query', 'filter', 'contact_id'],
    defaultsQueryParams: {
      status: 'active',
      filter: 'incentive'
    },
    filterOptions: [{ id: 'incentive', name: 'Filter by Incentive' }, { id: 'contact', name: 'Filter by Contact' }],
    query: null,
    contact_id: null,
    currentIncentive: null,
    filter: 'incentive',
    sorting: ['updatedAt:asc'],
    sortedModel: Ember.computed.sort('model', 'sorting'),
    filteredModel: Ember.computed.filter('model', function (resource) {
      if (Ember.isPresent(this.get('selectedResource')) && this.get('filter') !== 'incentive') {
        return this.get('selectedResource.id') === resource.get('id');
      } else {
        return true;
      }
    }).property('model', 'selectedResource'),
    actions: {
      copyIncentive: function (resource) {
        let attrs = {
          name: resource.get('name'),
          body: resource.get('body')
        };
        this.set('incetiveAttributes', attrs);
        this.transitionToRoute('account.settings.incentives.new', { queryParams: { from: resource.get('id') } });
      },
      filteredIncentivesContacts: function (resource) {
        let options = { contact_id: resource.get('id') };

        if (Ember.isPresent(this.get('status'))) {
          options.status = this.get('status');
        }

        options.paginate = false;
        return this.get('store').query('incentives-contact', options);
      },
      toggleResource: function (resource) {
        let contactId = this.get('contact_id');

        if (Ember.isPresent(contactId) && contactId === resource.get('id')) {
          this.set('contact_id', null);
        } else {
          this.set('contact_id', resource.get('id'));
        }
      }
    }
  });
});