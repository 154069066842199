define('knowme-client/controllers/chats', ['exports', 'moment', 'knowme-client/config/environment', 'knowme-client/mixins/controllers/queryable'], function (exports, _moment, _environment, _queryable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_queryable.default, {
    queryParams: ['kind'],
    chatShowController: Ember.inject.controller('chats.show'),
    applicationController: Ember.inject.controller('application'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    defaultsQueryParams: {
      kind: null
    },
    kind: null,
    cableService: Ember.inject.service('cable'),
    openNewRoomForm: false,
    confirmRemoveRoomModal: false,
    confirmLeaveRoomModal: false,
    skipRoomPushing: false,
    forwardMessageModal: false,
    messageToBeForward: null,
    enterGroupName: false,
    members: [],
    millisecondsToWait: 800,
    isWebChatWidgetCreated: Ember.computed('chatTouchPoints.length', function () {
      return this.get('chatTouchPoints.length') > 0;
    }),
    chatTouchPointMembers: Ember.computed('chatTouchPoints.@each.chatTouchPointMembers.length', function () {
      return this.get('chatTouchPoints').reduce(function (previousValue, chatTouchPoint) {
        return previousValue.pushObjects(chatTouchPoint.get('chatTouchPointMembers').toArray());
      }, Ember.A([]));
    }),
    isUserAddedToWebChat: Ember.computed('chatTouchPointMembers.@each.user', 'session.currentUser', function () {
      return this.get('chatTouchPointMembers').mapBy('user.id').includes(this.get('session.currentUser.id'));
    }),
    chatTypes: Ember.computed('applicationController.unreadRoomsCount', 'applicationController.unreadContactRoomsCount', 'isWebChatWidgetCreated', 'session.currentUser.isSalesPerson', function () {
      let isWebChatOptionDisabled = !this.get('isWebChatWidgetCreated') && this.get('session.currentUser.isSalesPerson');
      let internalChatTitle = 'Internal Chat';
      let webChatTitle = 'Web Chat';
      if (this.get('applicationController.unreadRoomsCount') > 0) {
        internalChatTitle = `Internal Chat (${this.get('applicationController.unreadRoomsCount')})`;
      }
      if (this.get('applicationController.unreadContactRoomsCount') > 0) {
        webChatTitle = `Web Chat (${this.get('applicationController.unreadContactRoomsCount')})`;
      }
      return [{
        name: 'Internal Chat',
        text: internalChatTitle,
        id: 'general'
      }, {
        disabled: isWebChatOptionDisabled,
        message: 'You can create Web Chat  Widget, in Campaigns and then Install that Widget on your online pages, which will allow contacts to reach you via Web Chat.',
        name: 'Web Chat',
        text: webChatTitle,
        id: 'support'
      }];
    }),
    ownerMenuActions: [{ label: 'Leave Chat', action: 'leaveGroup' }, { label: 'Delete Group', action: 'deleteGroup' }],
    memberMenuActions: [{ label: 'Leave Chat', action: 'leaveGroup' }],
    chatMenuActions: [{ label: 'Delete Chat', action: 'leaveGroup' }],
    setupRoomConsumer() {
      const consumer = this.get('cableService').createConsumer(_environment.default.APP.chat_service.ws);
      let currentUser = this.get('session.currentUser');
      let controller = this;
      consumer.subscriptions.create('RoomsChannel', {
        connected() {
          console.log('RoomsChannel#connected');
          this.perform('follow', { user_id: currentUser.get('id') });
        },
        received(data) {
          console.log('RoomsChannel#received');
          console.log(data);
          Ember.run.later(function () {
            if (data.payload.data.attributes.kind === controller.get('kind')) {
              controller.updateRoomStore(data);
            }
          }, 1000);
        },
        disconnected() {
          console.log("RoomsChannel#disconnected");
        }
      });
      // Set consumer in controller to link up computed props
      this.set('roomConsumer', consumer);
    },
    updateRoomStore(data) {
      let room = this.get("store").peekRecord('room', data.payload.data.id);
      switch (data.action) {
        case 'create':
          console.log('create room');
          this.get("store").pushPayload(data.modelName, data.payload);
          break;
        case 'update':
          console.log('update room', this.get('skipRoomPushing'), room.get('isDeleted'));
          if (!this.get('skipRoomPushing') && !room.get('isDeleted')) {
            this.get("store").pushPayload(data.modelName, data.payload);
          }
          break;
        case 'destroy':
          console.log('destroy room');
          if (Ember.isPresent(room) && !room.get('isDeleted')) {
            room.unloadRecord();
          }
          break;
      }
      this.set('model', this.get("store").peekAll('room').filterBy('kind', this.get('kind')));
    },
    savedRooms: Ember.computed.filter('model', function (resource) {
      return !resource.get('isNew');
    }),
    sorting: ['updatedAt:desc'],
    sortedModel: Ember.computed.sort('savedRooms', 'sorting'),
    filteredUsers: Ember.computed.filter('users', function (resource) {
      let result = resource.get('id') !== this.get('session.currentUser.id');
      if (Ember.isPresent(this.get('queryUser'))) {
        result = result && resource.get('name').toLocaleLowerCase().includes(this.get('queryUser').toLocaleLowerCase());
      }
      return result;
    }).property('queryUser'),
    filteredChats: Ember.computed.filter('sortedModel', function (resource) {
      let result = Ember.isPresent(this.get('messageToBeForward')) ? resource.get('id') !== this.get('messageToBeForward.room.id') : true;
      if (Ember.isPresent(this.get('queryChat'))) {
        result = resource.get('titleOrName').toLocaleLowerCase().includes(this.get('queryChat').toLocaleLowerCase());
      }
      return result;
    }).property('queryChat', 'messageToBeForward'),
    selectedUsersID: Ember.computed.mapBy('room.roomsUsers', 'user.id'),
    selectedsavedUsersID: Ember.computed('room.roomsUsers', function () {
      if (Ember.isPresent(this.get('room'))) {
        return this.get('room.roomsUsers').filterBy('isNew', false).mapBy('user.id');
      } else {
        return [];
      }
    }),
    disabledSaveRoom: Ember.computed('room.title', 'room.roomsUsers.length', function () {
      if (this.get('room.isNew')) {
        if (this.get('room.roomsUsers.length') > 2) {
          return Ember.isBlank(this.get('room.title'));
        } else {
          return this.get('room.roomsUsers.length') < 2;
        }
      } else {
        return Ember.isPresent(this.get('room')) && this.get('room.roomsUsers').filterBy('isNew', true).get('length') === 0;
      }
    }),
    actions: {
      fixNanoScroll: function () {
        Ember.$(window).trigger('resize');
      },
      openWebChatWidgetForm: function (e) {
        e.preventDefault();
        localStorage.setItem('prefillForm', true);
        this.transitionToRoute('campaigns.new');
      },
      newRoom: function (e) {
        e.preventDefault();
        let room = this.get('store').createRecord('room', {
          owner: this.get('session.currentUser'), members: []
        });
        this.get('store').createRecord('rooms-user', { room: room, user: this.get('session.currentUser') });
        this.set('room', room);
        this.set('openNewRoomForm', true);
      },
      addUserToRoom: function (user, e) {
        e.preventDefault();
        let room = this.get('room');
        let roomsUser = room.get('roomsUsers').findBy('user.id', user.get('id'));
        if (Ember.isPresent(roomsUser)) {
          if (roomsUser.get('isNew')) {
            roomsUser.destroyRecord();
          }
        } else {
          this.get('store').createRecord('rooms-user', { room: room, user: user });
        }
      },
      closeNewRoomForm: function () {
        this.set('openNewRoomForm', false);
        this.get('room.roomsUsers').filter(function (_model) {
          return _model.get('isNew');
        }).invoke('deleteRecord');
        this.set('room', null);
        this.set('enterGroupName', false);
      },
      addUsersToRoom: function (room) {
        if (!room.get('isGroup')) {
          let newRoom = this.get('store').createRecord('room', {
            owner: this.get('session.currentUser'), members: []
          });
          room.get('users').forEach(function (user) {
            this.get('store').createRecord('rooms-user', { room: newRoom, user: user });
          }.bind(this));
          room = newRoom;
        }

        this.set('room', room);
        this.set('openNewRoomForm', true);
      },
      saveRoom: function (room) {
        let members = room.get('roomsUsers').mapBy('user.name');
        room.set('members', members);

        room.one('didUpdate', function () {
          this.get('store').peekAll('rooms-user').filter(function (_model) {
            return _model.get('isNew');
          }).invoke('deleteRecord');
        }.bind(this));

        room.save().then(function () {
          this.send('closeNewRoomForm');
          console.log('saved', room.get('id'));
          this.transitionToRoute('chats.show', room.get('id'));
          this.get('target._routerMicrolib').refresh();
        }.bind(this));
      },
      hideConfirmRemoveModal: function () {
        this.set('roomToBeRemoved', null);
      },
      removeRoom: function (room) {
        if (this.get('confirmRemoveRoomModal')) {
          let isSelectedChatDeleted = this.get('selectedChat.id') == room.get('id');
          console.log(isSelectedChatDeleted, this.get('selectedChat.id'), room.get('id'));
          room.destroyRecord().then(function () {
            this.set('roomToBeRemoved', null);
            this.set('confirmRemoveRoomModal', false);
            if (isSelectedChatDeleted) {
              this.transitionToRoute('chats');
            } else {
              this.send('refreshModel');
            }
          }.bind(this));
        } else {
          this.set('confirmRemoveRoomModal', true);
          this.set('roomToBeRemoved', room);
        }
      },
      hideConfirmLeaveModal: function () {
        this.set('roomToBeLeaved', null);
      },
      leaveRoom: function (roomsUser) {
        if (this.get('confirmLeaveRoomModal')) {
          let room = roomsUser.get('room');
          let isSelectedChatDeleted = this.get('selectedChat.id') == room.get('id');
          console.log(isSelectedChatDeleted, this.get('selectedChat.id'), room.get('id'));
          roomsUser.destroyRecord().then(function () {
            this.set('roomToBeLeaved', null);
            this.set('confirmLeaveRoomModal', false);
            room = this.get("store").peekRecord('room', room.get('id'));
            if (Ember.isPresent(room)) {
              room.unloadRecord();
            }
            if (isSelectedChatDeleted) {
              this.transitionToRoute('chats');
            } else {
              this.send('refreshModel');
            }
          }.bind(this));
        } else {
          this.set('confirmLeaveRoomModal', true);
          this.set('roomToBeLeaved', roomsUser);
        }
      },
      closeForwardMessage: function () {
        this.set('messageToBeForward', null);
        this.get('model').filterBy('forwarded', true).forEach(function (room) {
          room.set('forwarded', false);
        });
      },
      forwardTo: function (room) {
        let message = this.get('store').createRecord('chat-message', {
          sentAt: (0, _moment.default)().toDate(),
          room: room,
          originalMessage: this.get('messageToBeForward')
        });
        // this.set('skipRoomPushing', true);
        message.save().then(function (message) {
          room.set('forwarded', true);
          // this.set('skipRoomPushing', false);
        }.bind(this));
      }
    }
  });
});