define('knowme-client/models/promo-code', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment', 'knowme-client/mixins/models/validations/promo-code', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _relationships, _moment, _promoCode, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_promoCode.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    plan: (0, _relationships.belongsTo)('plan', { async: true }),
    couponId: (0, _attr.default)(),
    name: (0, _attr.default)(),
    percentOff: (0, _attr.default)(),
    billingPeriod: (0, _attr.default)(),
    startDate: (0, _attr.default)('date', {
      defaultValue() {
        return (0, _moment.default)().toDate();
      }
    }),
    endDate: (0, _attr.default)('date'),
    published: (0, _attr.default)('boolean', { defaultValue: false })
  });
});