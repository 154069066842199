define('knowme-client/routes/wizard', ['exports', 'knowme-client/mixins/routes/cable-setup'], function (exports, _cableSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_cableSetup.default, {
    channelName: 'AccountChannel',
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function () /*transition*/{
      this._super(...arguments);
      return this.get('session.currentUser').then(function (user) {
        return user.get('account');
      }).then(function (account) {
        this.set('subscriptionData', { id: account.get('id') });

        if (Ember.isBlank(account.get('agreementAcceptedAt'))) {
          this.get('flashMessages').info("You need to accept the Knowme Client Agreement and Terms & Conditions", { destroyOnClick: true });
          return this.transitionTo('account.index').then(Ember.RSVP.Promise.reject);
        } else {
          return Ember.RSVP.Promise.resolve();
        }
      }.bind(this));
    },
    afterReceived: function (controller, data, action) {
      if (!controller.get('skipRedirectToDashboard') && this.get('session.currentUser.account.customerTypeConfirmed') && Ember.getOwner(this).lookup('controller:application').currentPath === 'wizard.client') {
        return this.transitionTo('dashboard');
      }
    }
  });
});