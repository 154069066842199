define('knowme-client/mirage/models/contact', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		lead: (0, _emberCliMirage.belongsTo)(),
		contactInfos: (0, _emberCliMirage.hasMany)(),
		lastCall: (0, _emberCliMirage.belongsTo)('call', { inverse: null }),
		calls: (0, _emberCliMirage.hasMany)(),
		lastShortMessage: (0, _emberCliMirage.belongsTo)('short-message', { inverse: null }),
		shortMessages: (0, _emberCliMirage.hasMany)()
	});
});