define('knowme-client/mirage/helpers/factory', ['exports', 'ember-inflector', 'knowme-client/mirage/factories/lead', 'knowme-client/mirage/factories/campaign', 'knowme-client/mirage/factories/ad'], function (exports, _emberInflector, _lead, _campaign, _ad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.factory = undefined;
  exports.build = build;
  exports.create = create;
  exports.createList = createList;

  const { camelize } = Ember.String;
  const { merge } = Ember;

  const factory = exports.factory = {
    server: null,
    hooks: {
      lead: {
        afterCreate: _lead.afterCreate
      },
      campaign: {
        afterCreate: _campaign.afterCreate
      },
      ad: {
        afterCreate: _ad.afterCreate
      }
    }
  };

  function build(type, overrides) {
    let server = factory.server;
    var collection = server.schema ? (0, _emberInflector.pluralize)(camelize(type)) : (0, _emberInflector.pluralize)(type);
    var currentRecords = server.db[collection];
    var sequence = currentRecords ? currentRecords.length : 0;
    if (!server.options.factories || !server.options.factories[type]) {
      throw "You're trying to create a " + type + ", but no factory for this type was found";
    }
    var OriginalFactory = server.options.factories[type];
    var Factory = OriginalFactory.extend(overrides);
    var _factory = new Factory();

    var attrs = _factory.build(sequence);
    return attrs;
  }

  let _create = function (factoryName, overrides = {}) {
    let server = factory.server,
        hooks = factory.hooks;
    let attributes = build(factoryName, overrides);
    let resource = server.schema[factoryName].create(attributes);
    let factoryHooks = hooks[factoryName];
    if (factoryHooks && factoryHooks.afterCreate) {
      factoryHooks.afterCreate(resource, { factory: factory, create: create, createList: createList, build: build });
    }
    return resource;
  };

  function create(factoryName, overrides) {
    return _create(factoryName, overrides);
  }

  function createList(factoryName, quantity, overrides, iterator) {
    let resources = [];
    for (var i = 0; i < quantity; i++) {
      var iteratorResult = iterator ? iterator() : {};
      resources.push(create(factoryName, merge(overrides, iteratorResult)));
    }
    return resources;
  }
});