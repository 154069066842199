define('knowme-client/helpers/moment-diff', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentDiff = momentDiff;
  function momentDiff(params /*, hash*/) {
    let d1, d2, units, point;

    d1 = Ember.isBlank(params[0]) ? (0, _moment.default)() : (0, _moment.default)(params[0]);
    d2 = Ember.isBlank(params[1]) ? (0, _moment.default)() : (0, _moment.default)(params[1]);
    units = Ember.isBlank(params[2]) ? params[2] : 'days';
    point = Ember.isBlank(params[3]) ? params[3] : false;
    return (0, _moment.default)(d2.startOf('day')).diff(d1.startOf('day'), units, point);
  }

  exports.default = Ember.Helper.helper(momentDiff);
});