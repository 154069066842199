define('knowme-client/models/oauth2-token', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    provider: (0, _attr.default)(),
    accessToken: (0, _attr.default)(),
    endPoint: (0, _attr.default)()
  });
});