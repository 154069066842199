define('knowme-client/serializers/incentive', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    normalize(typeClass, hash /*, prop*/) {
      hash.links = {
        incentivesContacts: Ember.getOwner(this).lookup('adapter:incentives-contact').buildURL('incentives-contact', null, null, 'findMany', { incentive_id: hash.id })
      };
      return this._super.apply(this, arguments);
    }
  });
});