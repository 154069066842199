define('knowme-client/helpers/truncate-html', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var truncateString, TruncateStringHelper;

  exports.truncateString = truncateString = function (params, hash) {
    var html = params[0] || '';
    var string = Ember.$(html).text();
    var limit = hash.limit || 80;
    var hint = Ember.isPresent(hash.hint) ? hash.hint : true;
    if (string.length > limit) {
      if (hint) {
        return Ember.String.htmlSafe(`<span class="hint--top" data-hint="${string}">${string.substr(0, limit)}...</span>`);
      } else {
        return string.substr(0, limit) + '...';
      }
    }
    return string;
  };

  TruncateStringHelper = Ember.Helper.helper(truncateString);

  exports.truncateString = truncateString;
  exports.default = TruncateStringHelper;
});