define('knowme-client/mixins/models/validations/contact-info', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		category: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		contactEmails: (0, _emberCpValidations.validator)('has-many'),
		contactPhones: (0, _emberCpValidations.validator)('has-many')
		// contactAddresses: validator('has-many')
		// contactUrls: validator('has-many')
	});
});