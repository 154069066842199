define('knowme-client/controllers/sms', ['exports', 'lodash', 'moment', 'knowme-client/mixins/date-filter', 'knowme-client/mixins/controllers/pagination'], function (exports, _lodash, _moment, _dateFilter, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _pagination.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ['type', 'filter', 'user_id'],
    filter: null,
    user_id: null,
    showUsersFilter: Ember.computed('model', function () {
      let currentUser = this.get('session.currentUser');
      return currentUser.get('hasAdminRole') || currentUser.get('isManager');
    }),
    actions: {
      refreshModelData: function () {
        this.send('refreshModel');
      }
    },
    processDateFilterParams: function (params) {
      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    }
  });
});