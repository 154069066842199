define('knowme-client/models/user', ['exports', 'ember-data/relationships', 'ember-data/attr', 'moment', 'knowme-client/models/allocatable', 'knowme-client/mixins/models/validations/user', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _relationships, _attr, _moment, _allocatable, _user, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _allocatable.default.extend(_user.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    devices: (0, _relationships.hasMany)('devices', { async: true, inverse: 'owner' }),
    usersDashboardWidgets: (0, _relationships.hasMany)('users-dashboard-widget', { async: true, inverse: 'user' }),
    roles: (0, _relationships.hasMany)('role', { async: true }),
    reportSchedules: (0, _relationships.hasMany)('report-schedule', { async: true }),
    contractAgreements: (0, _relationships.hasMany)('contract-agreement', { async: false }),
    calls: (0, _relationships.hasMany)('call', { async: true }),
    shortMessages: (0, _relationships.hasMany)('short-message', { async: true }),
    assignedCalls: (0, _relationships.hasMany)('call', { async: true, inverse: 'assignedTo' }),
    lastCall: (0, _relationships.belongsTo)('call', { async: true }),
    listenedCalls: (0, _relationships.hasMany)('call', { async: true, inverse: 'listeners' }),
    sentReminders: (0, _relationships.hasMany)('sent-reminder', { async: true }),
    authentications: (0, _relationships.hasMany)('authentication', { async: true }),
    account: (0, _relationships.belongsTo)('account', { async: true, inverse: 'users' }),
    callsCount: (0, _attr.default)('number'),
    outboundCallsCount: (0, _attr.default)('number'),
    leadsCount: (0, _attr.default)('number'),
    sentRemindersCount: (0, _attr.default)('number'),
    leadsPipeline: (0, _attr.default)('number'),
    leadsWithActionRequired: (0, _attr.default)('number'),
    leadsWithHighPriority: (0, _attr.default)('number'),
    leadsWithPastDueAction: (0, _attr.default)('number'),
    customersCount: (0, _attr.default)('number'),
    customersRevenue: (0, _attr.default)('number'),
    customersPipeline: (0, _attr.default)('number'),
    customersWithOpportunities: (0, _attr.default)('number'),
    customersWithActionRequired: (0, _attr.default)('number'),
    customersWithPastDueAction: (0, _attr.default)('number'),
    firstName: (0, _attr.default)(),
    lastName: (0, _attr.default)(),
    email: (0, _attr.default)(),
    outboundCallPhoneNumber: (0, _attr.default)(),
    mobilePhoneNumber: (0, _attr.default)(),
    code: (0, _attr.default)(),
    status: (0, _attr.default)('string', { defaultValue: "active" }),
    token: (0, _attr.default)(),
    timeZone: (0, _attr.default)('string'),
    timeZoneMapping: (0, _attr.default)('string'),
    signInCount: (0, _attr.default)(),
    lastSignInAt: (0, _attr.default)(),
    password: (0, _attr.default)('string'),
    passwordConfirmation: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    lastActivityAt: (0, _attr.default)('date'),
    isOnline: Ember.computed('lastActivityAt', function () {
      if (Ember.isBlank(this.get('lastActivityAt'))) {
        return false;
      }
      let duration = _moment.default.duration((0, _moment.default)().diff(this.get('lastActivityAt')));
      let minutes = duration.asMinutes();
      return minutes < 30;
    }),
    avatarUrl: (0, _attr.default)('string'),
    avatarThumbnailUrl: (0, _attr.default)('string'),
    avatarSmallUrl: (0, _attr.default)('string'),
    autopylotInstalled: (0, _attr.default)('boolean'),
    isFirstAdmin: (0, _attr.default)('boolean'),
    isKnowmeIqRequired: (0, _attr.default)('boolean'),
    isProfileWizardRequired: (0, _attr.default)('boolean'),
    isKnowmeIqSupport: (0, _attr.default)('boolean'),
    sameRoleDataAccess: (0, _attr.default)('boolean'),
    receiverType: (0, _attr.default)('string'),
    lockReceiverType: (0, _attr.default)('boolean'),
    receiveCallToDirectNumber: Ember.computed('receiverType', function () {
      return this.get('receiverType') === 'direct_number';
    }),
    name: Ember.computed('firstName', 'lastName', function () {
      return `${this.get('firstName')} ${this.get('lastName')}`;
    }),
    firstNameAndInitialOfLastName: Ember.computed('firstName', 'lastName', function () {
      return `${this.get('firstName')} ${Ember.isPresent(this.get('lastName')) ? this.get('lastName')[0] : ''}.`;
    }),
    fullName: Ember.computed.alias('name'),
    hasAdminRole: Ember.computed("isAdmin", "isClientAdmin", "isMasterAdmin", function () {
      return this.get('isAdmin') || this.get('isClientAdmin') || this.get('isMasterAdmin');
    }),
    isAdmin: Ember.computed("roles.@each.key", function () {
      return this.get('roles').any(function (role) {
        return role.get('key') === 'admin';
      });
    }),
    isClientAdmin: Ember.computed("roles.@each.key", function () {
      return this.get('roles').any(function (role) {
        return role.get('key') === 'client_admin';
      });
    }),
    hasManagerRole: Ember.computed("isManager", "isMasterManager", function () {
      return this.get('isManager') || this.get('isMasterManager');
    }),
    isManager: Ember.computed("roles.@each.key", function () {
      return this.get('roles').any(function (role) {
        return ['master_manager', 'manager'].includes(role.get('key'));
      });
    }),
    isMasterManager: Ember.computed("roles.@each.key", function () {
      return this.get('roles').any(function (role) {
        return 'master_manager' === role.get('key');
      });
    }),
    isMasterAdmin: Ember.computed("roles.@each.key", function () {
      return this.get('roles').any(function (role) {
        return role.get('key') === 'master_admin';
      });
    }),
    isSalesPerson: Ember.computed("roles.@each.key", function () {
      return this.get('roles').any(function (role) {
        return role.get('key') === 'sales_person';
      });
    }),
    canManagePermissions: Ember.computed.or('isMasterAdmin', 'isClientAdmin', 'isFirstAdmin'),
    isActive: Ember.computed.equal('status', 'active'),
    isInactive: Ember.computed.equal('status', 'inactive'),
    formattedOutboundCallPhoneNumber: Ember.computed('outboundCallPhoneNumber', function () {
      if (Ember.isPresent(this.get('outboundCallPhoneNumber')) && this.get('outboundCallPhoneNumber').match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
        return this.get('outboundCallPhoneNumber').replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
      } else {
        return this.get('outboundCallPhoneNumber');
      }
    }),
    formattedDidPhoneNumber: Ember.computed('didAllocation.did.phoneNumber', function () {
      if (!this.get('didAllocation.did.phoneNumber')) {
        return 'Unassigned';
      } else {
        return this.get('didAllocation.did.formattedPhoneNumber');
      }
    }),
    nonDeletedUsersDashboardWidgets: Ember.computed.filterBy('usersDashboardWidgets', 'isDeleted', false),
    sortedUsersDashboardWidgets: Ember.computed.sort('usersDashboardWidgets', 'sortDefinition'),
    sortBy: 'position:asc',
    sortDefinition: Ember.computed('sortBy', function () {
      return [this.get('sortBy')];
    }),
    role: Ember.computed('roles.@each.name', function () {
      return this.get('roles.firstObject');
    }),
    activeDevices: Ember.computed.filterBy('devices', 'status', 'active'),
    mobileAppStatus: Ember.computed('devices.length', 'activeDevices.length', function () {
      if (this.get('devices.length') === 0) {
        return 'Not installed';
      } else {
        return `Installed (${this.get('activeDevices.length') > 0 ? 'active' : 'inactive'})`;
      }
    }),
    activeDevice: Ember.computed.alias('activeDevices.firstObject'),
    onRoleChange: Ember.observer('role', function () {
      let role = this.get('role');
      if (Ember.isPresent(role)) {
        this.get('roles').clear().addObject(role);
      }
    })
  });
});