define('knowme-client/routes/account/settings/incentives', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    pagination: {},
    queryParams: {
      status: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      }
    },
    beforeModel: function (transition) {
      return this._super(...arguments).then(function () {
        if (!this.get('features.incentivesSectionAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Access Unauthorized', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        }
      }.bind(this));
    },
    model: function (params) {
      let promise = null;
      if (params.filter === 'incentive') {
        promise = this.get('store').query('incentive', params);
      } else {
        promise = this.get('store').query('contact', this.processParams(params));
      }

      promise.then(result => {
        if (params.filter === 'incentive') {
          this.set('pagination.total', result.meta.pagination.totalCount);
          this.set('pagination.limit', result.meta.pagination.page.size);
          this.set('pagination.page', result.meta.pagination.page.number);
        } else {
          this.set('pagination.total', result.meta.pagination.total);
          this.set('pagination.limit', result.meta.pagination.limit);
          this.set('pagination.page', result.meta.pagination.page);
        }
        if (params.contact_id) {
          this.set('selectedResource', result.get('firstObject'));
        } else {
          this.set('selectedResource', null);
        }
      });
      return promise;
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('selectedResource', this.get('selectedResource'));
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
    },
    processParams: function (_params) {
      let incentivesParams = Ember.copy(_params);
      let params = { filter: 'with_incentives' };

      if (Ember.isPresent(incentivesParams.contact_id)) {
        params.ids = [incentivesParams.contact_id];
        delete incentivesParams.contact_id;
      }
      if (Ember.isPresent(incentivesParams.query)) {
        params.query = incentivesParams.query;
        delete incentivesParams.query;
      }

      params.incentives = incentivesParams;
      return params;
    },
    actions: {
      reloadModel: function () {
        this.refresh();
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });
});