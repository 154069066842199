define('knowme-client/helpers/api-route-for', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.apiRouteFor = undefined;


	var apiRouteFor, ApiRouteForHelper;

	exports.apiRouteFor = apiRouteFor = function (path) {
		var route = _environment.default.APP.serverEndpoint + "/" + path;
		return route;
	};

	ApiRouteForHelper = Ember.Helper.helper(apiRouteFor);

	exports.apiRouteFor = apiRouteFor;
	exports.default = ApiRouteForHelper;
});