define('knowme-client/helpers/format-phone-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPhoneNumber = formatPhoneNumber;
  function formatPhoneNumber(params /*, hash*/) {
    let number = params[0] || '';
    if (number.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
      return number.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
    } else {
      return number;
    }
  }

  exports.default = Ember.Helper.helper(formatPhoneNumber);
});