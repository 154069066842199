define('knowme-client/mixins/models/validations/report-schedule', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		name: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		recipients: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		scheduledTime: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		timeframe: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		hasScheduledDays: (0, _emberCpValidations.validator)(function (value /*, options, model, attribute*/) {
			let result = 'You need to select at least one day';
			if (value) {
				result = true;
			}
			return result;
		})
	});
});