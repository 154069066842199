define('knowme-client/mixins/validate-belongs-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ValidateBelongsToMixin;

  ValidateBelongsToMixin = Ember.Mixin.create({
    setupIsRequired: Ember.on('init', function () {
      let _this = this;
      this.eachRelationship(function (relationship, relationshipMeta) {
        if (relationshipMeta.kind === 'belongsTo') {
          Ember.defineProperty(_this, `${relationship}IsRequired`, Ember.computed(relationship, function () {
            return _this.get(`${relationship}IdIsRequired`);
          }));
        }
      });
    })

  });

  exports.default = ValidateBelongsToMixin;
});