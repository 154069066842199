define('knowme-client/components/dashboard/contacts-satisfaction-widget', ['exports', 'lodash'], function (exports, _lodash) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		accountService: Ember.inject.service('account'),
		features: Ember.inject.service(),
		noneItem: Ember.computed('model', 'model.customerExperiencesSummary', function () {
			return _lodash.default.find(this.get('model.customerExperiencesSummary'), { 'satisfaction': 'none' });
		}),
		surveyStatuses: ['surveyRequested', 'respondedToSurvey', 'none', 'noResponse', 'callsGradedByUser', 'callsGradedByCaller'],
		mainValues: Ember.computed('features.activityUserAvailable', function () {
			if (this.get('features.activityUserAvailable')) {
				return ['veryDissatisfied', 'dissatisfied', 'neutral', 'unhappy', 'happy', 'satisfied', 'verySatisfied', 'improved', 'regressed', 'surveyRequested'];
			} else {
				return ['veryDissatisfied', 'dissatisfied', 'neutral', 'unhappy', 'happy', 'satisfied', 'verySatisfied', 'improved', 'regressed'];
			}
		}),
		extraValues: Ember.computed('features.activityUserAvailable', function () {
			if (this.get('features.activityUserAvailable')) {
				return ['respondedToSurvey', 'none', 'noResponse', 'callsGradedByUser', 'callsGradedByCaller'];
			} else {
				return ['surveyRequested', 'respondedToSurvey', 'none', 'noResponse', 'callsGradedByUser', 'callsGradedByCaller'];
			}
		})
	});
});