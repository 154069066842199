define('knowme-client/models/users-dashboard-widget', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr', 'knowme-client/mixins/dirty'], function (exports, _model, _relationships, _attr, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_dirty.default, {
    user: (0, _relationships.belongsTo)('user', { async: true, inverse: 'usersDashboardWidgets' }),
    dashboardWidget: (0, _relationships.belongsTo)('dashboard-widget', { async: true }),
    position: (0, _attr.default)('number')
  });
});