define('knowme-client/mixins/models/account-settings-definitions', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    setupDefinitions: Ember.on('init', function () {
      let _this = this;
      _environment.default.APP.SettingsKeys.forEach(key => {
        Ember.defineProperty(_this, `settings${key.camelize().capitalize()}`, Ember.computed('settings.isFulfilled', 'settings.@each.key', 'settings.length', () => {
          return _this.get('settings').findBy('key', key);
        }));
      });
    })
  });
});