define('knowme-client/models/campaign', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/validations/campaign', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _relationships, _campaign, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_campaign.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    ads: (0, _relationships.hasMany)('ad', { async: true }),
    alerts: (0, _relationships.hasMany)('alert', { async: true }),
    name: (0, _attr.default)(),
    totalCost: (0, _attr.default)('number'),
    leadsCount: (0, _attr.default)(),
    customersCount: (0, _attr.default)(),
    costPerLead: (0, _attr.default)(),
    pipeline: (0, _attr.default)('number'),
    revenue: (0, _attr.default)('number'),
    roi: (0, _attr.default)(),
    roiPercentage: (0, _attr.default)('number'),
    adsLength: Ember.computed.alias('ads.length'),
    status: (0, _attr.default)(),
    createdAt: (0, _attr.default)('date'),
    sortedAds: Ember.computed.sort('ads', 'sortProperties'),
    sortProperties: ["status:asc", "updatedAt:desc"],
    hasAnyReleasedTouchpoints: (0, _attr.default)(),
    hasAllReleasedTouchpoints: (0, _attr.default)()
  });
});