define('knowme-client/models/ad', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment', 'knowme-client/mixins/models/validations/ad', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _relationships, _moment, _ad, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_ad.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    features: Ember.inject.service(),
    campaign: (0, _relationships.belongsTo)('campaign', { async: true }),
    opportunities: (0, _relationships.hasMany)('opportunities', { async: true }),
    callTouchPoints: (0, _relationships.hasMany)('call-touch-point', { async: true }),
    formTouchPoints: (0, _relationships.hasMany)('form-touch-point', { async: true }),
    emailTouchPoints: (0, _relationships.hasMany)('email-touch-point', { async: true }),
    chatTouchPoints: (0, _relationships.hasMany)('chat-touch-point', { async: true }),
    alerts: (0, _relationships.hasMany)('alert', { async: true }),
    name: (0, _attr.default)(),
    channel: (0, _attr.default)(),
    touchPoint: (0, _attr.default)(),
    cost: (0, _attr.default)('number'),
    status: (0, _attr.default)(),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    revenue: (0, _attr.default)('number', { defaultValue: 0 }),
    leadsCount: (0, _attr.default)('number'),
    customersCount: (0, _attr.default)('number'),
    costPerLead: (0, _attr.default)('number'),
    pipeline: (0, _attr.default)('number', { defaultValue: 0 }),
    roi: (0, _attr.default)('number'),
    roiPercentage: (0, _attr.default)('number'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    leadCapture: null,
    title: Ember.computed('name', 'chatTouchPoints.length', function () {
      if (this.get('chatTouchPoints.length') > 0) {
        return `${this.get('name')} - ${this.get('chatTouchPoints.firstObject.name')}`;
      } else {
        return this.get('name');
      }
    }),
    isTFN: Ember.computed('touchPoint', function () {
      return this.get('touchPoint') === 'toll_free_number';
    }),
    started: Ember.computed('startDate', function () {
      return (0, _moment.default)().isAfter((0, _moment.default)(this.get('startDate')));
    }),
    isActive: Ember.computed.equal('status', 'active'),
    isInactive: Ember.computed.equal('status', 'inactive'),
    allowEdit: Ember.computed('callTouchPoints', function () {
      return this.get('callTouchPoints.firstObject.didAllocation.isInactive');
    })
  });
});