define('knowme-client/serializers/alert-json', ['exports', 'knowme-client/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    attrs: {
      campaign: { serialize: 'id' },
      ad: { serialize: 'id' },
      user: { serialize: 'id' }
    },
    serialize: function (snapshot /* options*/) {
      var json = this._super(...arguments);
      if (Ember.isBlank(json.user_id) && Ember.isPresent(snapshot.record.get('user'))) {
        json.user_id = snapshot.record.get('user.id');
      }
      return json;
    }
  });
});