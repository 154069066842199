define('knowme-client/routes/contact', ['exports', 'knowme-client/mixins/routes/unauthenticated-mixin'], function (exports, _unauthenticatedMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function (transition) {
      this._super(...arguments);
      if (this.get('session.isAuthenticated')) {
        return this.get('session.currentUser').then(function (user) {
          this.set('currentUser', user);
          return user.get('account');
        }.bind(this));
      }
    },
    model: function () {
      let attrs = {};
      if (this.get('session.isAuthenticated')) {
        attrs.first_name = this.get('session.currentUser.firstName');
        attrs.last_name = this.get('session.currentUser.lastName');
        attrs.email = this.get('session.currentUser.email');
        attrs.account_id_number = this.get('session.currentUser.account.idNumber');
        let fieldName = 'phone_number';
        if (this.get('session.currentUser.outboundCallPhoneNumber') === this.get('session.currentUser.mobilePhoneNumber')) {
          fieldName = 'mobile_phone_number';
        }
        attrs[fieldName] = this.get('session.currentUser.outboundCallPhoneNumber');
      }
      return Ember.Object.create(attrs);
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      controller.set('isContactFormVisible', this.get('session.isAuthenticated'));
      controller.set('isSent', false);
    }
  });
});