define('knowme-client/mirage/factories/user', ['exports', 'faker', 'ember-cli-mirage'], function (exports, _faker, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function () /*i*/{
      return _faker.default.name.firstName() + " " + _faker.default.name.lastName();
    }, // using faker
    email: function (i) {
      // and functions
      return 'person' + i + '@test.com';
    },
    firstName: function () /*i*/{
      return _faker.default.name.firstName();
    }, // using faker
    lastName: function () /*i*/{
      return _faker.default.name.lastName();
    }, // using faker
    password: "password",
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    lastActivityAt: function () {
      return _faker.default.date.past().toISOString();
    },
    callsCount: function () {
      return 0;
    },
    afterCreate(user, server) {
      server.schema.dashboardWidgets.all().models.forEach(function (dashboardWidget) {
        server.create('users-dashboard-widget', { dashboardWidget: dashboardWidget, user: user, position: 1 });
      });
    },
    client_admin: (0, _emberCliMirage.trait)({
      afterCreate(user, server) {
        let role = server.db.roles.findBy(function (x) {
          return x.key == 'client_admin';
        });
        user.update('roleIds', [role.id]);
      }
    }),
    admin: (0, _emberCliMirage.trait)({
      afterCreate(user, server) {
        let role = server.db.roles.findBy(function (x) {
          return x.key == 'admin';
        });
        user.update('roleIds', [role.id]);
      }
    }),
    master_manager: (0, _emberCliMirage.trait)({
      afterCreate(user, server) {
        let role = server.db.roles.findBy(function (x) {
          return x.key == 'master_manager';
        });
        user.update('roleIds', [role.id]);
      }
    }),
    manager: (0, _emberCliMirage.trait)({
      afterCreate(user, server) {
        let role = server.db.roles.findBy(function (x) {
          return x.key == 'manager';
        });
        user.update('roleIds', [role.id]);
      }
    }),
    sales_person: (0, _emberCliMirage.trait)({
      afterCreate(user, server) {
        let role = server.db.roles.findBy(function (x) {
          return x.key == 'sales_person';
        });
        user.update('roleIds', [role.id]);
      }
    })
  });
});