define('knowme-client/controllers/campaign/ads/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    features: Ember.inject.service(),
    openConfirmRelease: false,
    onAdTouchPointChanges: Ember.observer('model.touchPoint', function () {
      switch (this.get('model.touchPoint')) {
        case 'toll_free_number':
        case 'local_number':
          this.get('model.callTouchPoints').then(function (callTouchPoints) {
            if (callTouchPoints.get('length') === 0) {
              this.get('store').createRecord('call-touch-point', {
                ad: this.get('model'),
                didAllocation: this.get('store').createRecord('did-allocation')
              });
            }
          }.bind(this));
          break;
        case 'form':
          this.get('model.formTouchPoints').then(function (formTouchPoints) {
            if (formTouchPoints.get('length') === 0) {
              this.get('store').createRecord('form-touch-point', { ad: this.get('model') });
            }
          }.bind(this));
          break;
        case 'email':
          this.get('model.emailTouchPoints').then(function (emailTouchPoints) {
            if (emailTouchPoints.get('length') === 0) {
              this.get('store').createRecord('email-touch-point', { ad: this.get('model') });
            }
          }.bind(this));
          break;
        case 'chat':
        case 'contact_us':
          this.get('model.chatTouchPoints').then(function (chatTouchPoints) {
            if (chatTouchPoints.get('length') === 0) {
              this.get('store').createRecord('chat-touch-point', { ad: this.get('model') });
            }
          }.bind(this));
          break;
        default:
          this.get('model.callTouchPoints').then(function (callTouchPoints) {
            if (Ember.isPresent(callTouchPoints.get('firstObject.didAllocation'))) {
              callTouchPoints.get('firstObject.didAllocation').deleteRecord();
              callTouchPoints.get('firstObject').deleteRecord();
            }
          });
          this.get('model.formTouchPoints').then(function (formTouchPoints) {
            if (Ember.isPresent(formTouchPoints.get('firstObject'))) {
              formTouchPoints.get('firstObject').deleteRecord();
            }
          });
          this.get('model.emailTouchPoints').then(function (emailTouchPoints) {
            if (Ember.isPresent(emailTouchPoints.get('firstObject'))) {
              emailTouchPoints.get('firstObject').deleteRecord();
            }
          });
          this.get('model.chatTouchPoints').then(function (chatTouchPoints) {
            if (Ember.isPresent(chatTouchPoints.get('firstObject'))) {
              chatTouchPoints.get('firstObject').deleteRecord();
            }
          });
          console.log('not supported');
      }
    }),
    touchPointName: Ember.computed('model.touchPoint', function () {
      let name;
      switch (this.get('model.touchPoint')) {
        case 'toll_free_number':
          name = 'Toll Free Number';
          break;
        case 'local_number':
          name = 'Local Number';
          break;
        case 'form':
          name = 'Form';
          break;
        case 'email':
          name = 'Email';
          break;
        case 'chat':
        case 'contact_us':
          name = 'Chat';
          break;
        default:
          console.log(`not supported ${this.get('model.touchPoint')} for touchPointName`);
      }
      return name;
    }),
    touchPointComponent: Ember.computed('model.touchPoint', function () {
      let name;
      switch (this.get('model.touchPoint')) {
        case 'toll_free_number':
        case 'local_number':
          name = 'call-touch-point/resource-form';
          break;
        case 'form':
          name = 'form-touch-point/resource-form';
          break;
        case 'email':
          name = 'email-touch-point/resource-form';
          break;
        case 'chat':
        case 'contact_us':
          name = 'chat-touch-point/resource-form';
          break;
        default:
          console.log(`not supported ${this.get('model.touchPoint')} for touchPointComponent`);
      }
      return name;
    }),
    touchPointModel: Ember.computed('model.touchPoint', 'model.callTouchPoints.length', 'model.formTouchPoints.length', 'model.emailTouchPoints.length', 'model.chatTouchPoints.length', function () {
      let model;
      switch (this.get('model.touchPoint')) {
        case 'toll_free_number':
        case 'local_number':
          model = this.get('model.callTouchPoints.firstObject');
          break;
        case 'form':
          model = this.get('model.formTouchPoints.firstObject');
          break;
        case 'email':
          model = this.get('model.emailTouchPoints.firstObject');
          break;
        case 'chat':
        case 'contact_us':
          model = this.get('model.chatTouchPoints.firstObject');
          break;
        default:
          console.log(`not supported ${this.get('model.touchPoint')} for touchPointModel`);
      }
      return model;
    }),
    availableNumberOfMultiRingDids: Ember.computed('features.activeSettings.callTouchPointsMultiRingLimit', 'accountSummary.numberOfAdsWithMultiRingDids', function () {
      return this.get('features.activeSettings.callTouchPointsMultiRingLimit') - this.get('accountSummary.numberOfAdsWithMultiRingDids');
    }),
    enableMultiRingDids: Ember.computed('availableNumberOfMultiRingDids', 'touchPointModel', function () {
      return this.get('availableNumberOfMultiRingDids') > 0 || this.get('touchPointModel.hasMultiForward');
    }),
    isACallTouchPoint: Ember.computed('model.touchPoint', 'model.callTouchPoints.length', function () {
      return ['toll_free_number', 'local_number'].includes(this.get('model.touchPoint'));
    }),
    isAWebForm: Ember.computed('model.touchPoint', 'model.formTouchPoints.length', function () {
      return ['form'].includes(this.get('model.touchPoint'));
    }),
    isAEmailForm: Ember.computed('model.touchPoint', 'model.emailTouchPoints.length', function () {
      return ['email'].includes(this.get('model.touchPoint'));
    }),
    onCallTouchsPointChange: Ember.observer('model.callTouchPoints.length', function () {
      if (Ember.isPresent(this.get('model.callTouchPoints.firstObject')) && Ember.isBlank(this.get('model.callTouchPoints.firstObject.didAllocation'))) {
        this.store.createRecord('did-allocation', { allocatable: this.get('model.callTouchPoints.firstObject'), did: null });
      }
    }),
    enableSubmit: Ember.computed('model', 'model.validations.isValid', 'model.hasDirtyAttributes', 'hasTouchPointChanged', 'touchPointModel.hasDirtyAttributes', 'touchPointModel.chatTouchPointMembersHasChanged', 'touchPointModel.hasWorkTimesChanged', 'touchPointModel.chatTouchPointMembers.@each.isDeleted', function () {
      return this.get('model.validations.isValid') && (this.get('model.hasDirtyAttributes') || this.get('touchPointModel') && (this.get('touchPointModel.hasDirtyAttributes') || this.get('touchPointModel.chatTouchPointMembersHasChanged') || Ember.isPresent(this.get('touchPointModel.chatTouchPointMembers')) && this.get('touchPointModel.chatTouchPointMembers').any(function (x) {
        return x.get('isDeleted');
      }) || this.get('touchPointModel.hasWorkTimesChanged')));
    }),
    disableSubmit: Ember.computed('enableSubmit', function () {
      return !this.get('enableSubmit');
    }),
    actions: {
      submit: function () {
        this.model._internalModel.setId = function setId(id) {
          true && !(this.id === null || this.id === id || this.isNew()) && assert('A record\'s id cannot be changed once it is in the loaded state', this.id === null || this.id === id || this.isNew());

          Ember.set(this, "id", id);
          if (this._record.get('id') !== id) {
            this._record.set('id', id);
          }
        };
        this.model.save().then(function (resource) {
          if (['local_number', 'toll_free_number'].includes(resource.get('touchPoint'))) {
            resource.hasMany('callTouchPoints').reload();
          }
          if (['chat', 'contact_us'].includes(this.get('model.touchPoint'))) {
            this.get('touchPointModel').reload().then(function (ctp) {
              ctp.setupHasChangedWorkTimes();
            });
          }
          this.transitionToRoute('campaign.ads');
          this.get('target._routerMicrolib').refresh();
        }.bind(this)).catch(function (reason) {
          console.log("Errors", reason);
          var message = '';

          if (reason.errors[0].source.pointer.indexOf('forward_to_') >= 0) {
            var sourcePointer = '';
            var pointerArray = reason.errors[0].source.pointer.split('.');
            switch (pointerArray[pointerArray.length - 1]) {
              case 'forward_to_1':
                sourcePointer = 'Forward To 1';
                break;
              case 'forward_to_2':
                sourcePointer = 'Forward To 2';
                break;
              case 'forward_to_3':
                sourcePointer = 'Forward To 3';
                break;
              case 'forward_to_4':
                sourcePointer = 'Forward To 4';
                break;
            }
            message = `${sourcePointer} ${reason.errors[0].detail}`;
          } else {
            message = reason.errors[0].detail;
          }
          this.get('flashMessages').warning(message, { destroyOnClick: true, timeout: 12000 });
        }.bind(this));
      },
      release() {
        this.get('model.callTouchPoints.firstObject.didAllocation').then(function (didAllocation) {
          didAllocation.set('status', 'inactive');
          didAllocation.save().then(function () {
            this.set('openConfirmRelease', false);
            this.transitionToRoute('campaigns');
          }.bind(this));
        }.bind(this));
      },
      releaseWebForm() {
        let ftp = this.get('model.formTouchPoints.firstObject');
        ftp.set('status', 'inactive');
        ftp.save().then(function () {
          this.set('openConfirmRelease', false);
          this.transitionToRoute('campaigns');
        }.bind(this));
      },
      releaseEmailForm() {
        let ftp = this.get('model.emailTouchPoints.firstObject');
        ftp.set('status', 'inactive');
        ftp.save().then(function () {
          this.set('openConfirmRelease', false);
          this.transitionToRoute('campaigns');
        }.bind(this));
      }
    }
  });
});