define('knowme-client/helpers/to-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toArray = toArray;
  function toArray(params /*, hash*/) {
    return (params[0] || []).toArray();
  }

  exports.default = Ember.Helper.helper(toArray);
});