define('knowme-client/controllers/campaign/ads/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    isSaving: false,
    plan: Ember.computed.alias('session.account.subscription.plan'),
    isMarketingUser: Ember.computed.alias('features.isMarketingUser'),
    captureMode: 'automated',
    intl: Ember.inject.service(),
    init: function () {
      let _this = this;
      this._super(...arguments);
      this.get('flashMessages').clearMessages();
      if (!this.get('features.campaignsAutomatedCaptureAvailable')) {
        this.set('captureMode', 'manual');
      }
      Ember.run.schedule("afterRender", this, function () {
        let message = _this.get('message');
        if (_this.get('disableTollFreeDropdown') && _this.get('disableLocalDropdown') && !_this.get('features.isKnowmeIQStarter')) {
          _this.get('flashMessages').info(message, { destroyOnClick: true, timeout: 15000 });
        }
      });
    },
    onCaptureModeChange: Ember.observer('captureMode', function () {
      this.get('model').set('captureMode', this.get('captureMode'));
    }),
    onAdTouchPointChanges: Ember.observer('model.touchPoint', function () {
      switch (this.get('model.touchPoint')) {
        case 'toll_free_number':
        case 'local_number':
          if (this.get('model.callTouchPoints.length') === 0) {
            this.get('store').createRecord('call-touch-point', {
              ad: this.get('model'),
              didAllocation: this.get('store').createRecord('did-allocation')
            });
          }
          if (Ember.isPresent(this.get('model.emailTouchPoints.firstObject'))) {
            this.get('model.emailTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.formTouchPoints.firstObject'))) {
            this.get('model.formTouchPoints.firstObject').deleteRecord();
          }
          break;
        case 'form':
          if (this.get('model.formTouchPoints.length') === 0) {
            this.get('store').createRecord('form-touch-point', { ad: this.get('model') });
          }
          if (Ember.isPresent(this.get('model.callTouchPoints.firstObject'))) {
            this.get('model.callTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.emailTouchPoints.firstObject'))) {
            this.get('model.emailTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.chatTouchPoints.firstObject'))) {
            this.get('model.chatTouchPoints.firstObject').deleteRecord();
          }
          break;
        case 'email':
          if (this.get('model.emailTouchPoints.length') === 0) {
            this.get('store').createRecord('email-touch-point', { ad: this.get('model') });
          }
          if (Ember.isPresent(this.get('model.callTouchPoints.firstObject'))) {
            this.get('model.callTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.formTouchPoints.firstObject'))) {
            this.get('model.formTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.chatTouchPoints.firstObject'))) {
            this.get('model.chatTouchPoints.firstObject').deleteRecord();
          }
          break;
        case 'chat':
        case 'contact_us':
          if (this.get('model.chatTouchPoints.length') === 0) {
            this.get('store').createRecord('chat-touch-point', { ad: this.get('model') });
          }
          if (Ember.isPresent(this.get('model.callTouchPoints.firstObject'))) {
            this.get('model.callTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.formTouchPoints.firstObject'))) {
            this.get('model.formTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.emailTouchPoints.firstObject'))) {
            this.get('model.emailTouchPoints.firstObject').deleteRecord();
          }
          break;
        default:
          if (Ember.isPresent(this.get('model.callTouchPoints.firstObject.didAllocation'))) {
            this.get('model.callTouchPoints.firstObject.didAllocation').deleteRecord();
            this.get('model.callTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.formTouchPoints.firstObject'))) {
            this.get('model.formTouchPoints.firstObject').deleteRecord();
          }
          if (Ember.isPresent(this.get('model.emailTouchPoints.firstObject'))) {
            this.get('model.emailTouchPoints.firstObject').deleteRecord();
          }
          console.log('not supported');
      }
    }),
    touchPointName: Ember.computed('model.touchPoint', function () {
      let intl = this.get('intl');
      let name;
      switch (this.get('model.touchPoint')) {
        case 'toll_free_number':
        case 'local_number':
        case 'form':
        case 'email':
        case 'chat':
        case 'contact_us':
          name = intl.t(`ad.touchPoints.${this.get('model.touchPoint')}`);
          break;
        default:
          console.log(`not supported ${this.get('model.touchPoint')} for touchPointName`);
      }
      return name;
    }),
    touchPointComponent: Ember.computed('model.touchPoint', function () {
      let name;
      let messages = {
        all: 'You have used all available Numbers (Toll Free and Local numbers) in your Service plan.To get a new Number, you can take a Number off an existing ad that is no longer being used OR an Admin can purchase additional Numbers under the Upgrade section in Client Information.',
        tfn: 'You have used all available Toll Free Numbers (TFNs) in your Service plan. To get a new TFN, you can take a TFN off an existing ad that is no longer being used OR an Admin can purchase additional TFNs under the Upgrade section in Client Information.',
        local: 'You have used all available Local numbers. To get a new Local number, you can take a Local number off an existing ad that is no longer being used OR an Admin can purchase additional Local numbers under the Upgrade section in Client Information.'
      };
      this.get('flashMessages').clearMessages();
      switch (this.get('model.touchPoint')) {
        case 'toll_free_number':
          if (this.get('disableTollFreeDropdown') && !this.get('features.isKnowmeIQStarter')) {
            this.get('flashMessages').info(messages.tfn, { destroyOnClick: true, timeout: 15000 });
          }
          name = 'call-touch-point/resource-form';
          break;
        case 'local_number':
          if (this.get('disableLocalDropdown') && !this.get('features.isKnowmeIQStarter')) {
            this.get('flashMessages').info(messages.local, { destroyOnClick: true, timeout: 15000 });
          }
          name = 'call-touch-point/resource-form';
          break;
        case 'form':
          name = 'form-touch-point/resource-form';
          break;
        case 'email':
          name = 'email-touch-point/resource-form';
          break;
        case 'chat':
        case 'contact_us':
          name = 'chat-touch-point/resource-form';
          break;
        default:
          console.log(`not supported ${this.get('model.touchPoint')} for touchPointComponent`);
      }

      return name;
    }),
    touchPointModel: Ember.computed('model.touchPoint', function () {
      let model;
      switch (this.get('model.touchPoint')) {
        case 'toll_free_number':
        case 'local_number':
          model = this.get('model.callTouchPoints.firstObject');
          break;
        case 'form':
          model = this.get('model.formTouchPoints.firstObject');
          break;
        case 'email':
          model = this.get('model.emailTouchPoints.firstObject');
          break;
        case 'chat':
        case 'contact_us':
          model = this.get('model.chatTouchPoints.firstObject');
          break;
        default:
          console.log(`not supported ${this.get('model.touchPoint')} for touchPointModel`);
      }
      return model;
    }),
    availableNumberOfMultiRingDids: Ember.computed('features.activeSettings.callTouchPointsMultiRingLimit', 'accountSummary.numberOfAdsWithMultiRingDids', function () {
      return this.get('features.activeSettings.callTouchPointsMultiRingLimit') - this.get('accountSummary.numberOfAdsWithMultiRingDids');
    }),
    enableMultiRingDids: Ember.computed('availableNumberOfMultiRingDids', 'touchPointModel', function () {
      return this.get('availableNumberOfMultiRingDids') > 0;
    }),
    onTouchPointModelChange: Ember.observer('model.touchPoint', function () {
      if (Ember.isPresent(this.get('model.callTouchPoints.firstObject'))) {
        this.set('model.callTouchPoints.firstObject.didAllocation.didNumber', null);
      }
    }),
    onCallTouchsPointChange: Ember.observer('model.callTouchPoints.length', function () {
      if (Ember.isPresent(this.get('model.callTouchPoints.firstObject')) && Ember.isBlank(this.get('model.callTouchPoints.firstObject.didAllocation'))) {
        this.store.createRecord('did-allocation', { allocatable: this.get('model.callTouchPoints.firstObject'), did: null });
      }
    }),
    captureModeOptions: [{ id: 'automated', name: 'Automated' }, { id: 'manual', name: 'Manual' }],
    hasChatTouchPointWithoutMembers: Ember.computed('model.chatTouchPoints.length', 'model.chatTouchPoints.firstObject.rooms.firstObject.roomsUsers.length', function () {
      if (this.get('model.chatTouchPoints.length') === 0) {
        return false;
      }
      return this.get('model.chatTouchPoints.firstObject.rooms.firstObject.roomsUsers.length') === 0;
    }),
    disableSubmit: Ember.computed.or('isSaving', 'model.validations.isInvalid', 'hasChatTouchPointWithoutMembers'),
    actions: {
      submit: function () {
        let needRedirectToAd = Ember.isPresent(this.get('model.touchPoint')) && ['email', 'form', 'chat', 'contact_us'].includes(this.get('model.touchPoint'));
        this.set('isSaving', true);
        let controller = this;
        this.model.one('didCreate', function () {
          ['chatTouchPoint'].forEach(function (modelName) {
            controller.store.peekAll(modelName).filterBy('id', null).invoke('deleteRecord');
          });
          controller.get('store').peekAll('ads').filter(function (_model) {
            return _model.get('isNew');
          }).invoke('deleteRecord');
        });
        this.model.save().then(function (ad) {
          this.set('isSaving', false);
          if (needRedirectToAd) {
            this.transitionToRoute('campaign.ads.edit', ad.get('campaign'), ad);
            this.get('target._routerMicrolib').refresh();
          } else {
            this.transitionToRoute('campaign.ads', ad.get('campaign'));
            this.get('target._routerMicrolib').refresh();
          }
        }.bind(this)).catch(function (reason) {
          this.set('isSaving', false);
          console.log("Errors", reason);
          var message = '';

          if (reason.errors[0].source.pointer.indexOf('forward_to_') >= 0) {
            var sourcePointer = '';
            var pointerArray = reason.errors[0].source.pointer.split('.');
            switch (pointerArray[pointerArray.length - 1]) {
              case 'forward_to_1':
                sourcePointer = 'Forward To 1';
                break;
              case 'forward_to_2':
                sourcePointer = 'Forward To 2';
                break;
              case 'forward_to_3':
                sourcePointer = 'Forward To 3';
                break;
              case 'forward_to_4':
                sourcePointer = 'Forward To 4';
                break;
            }
            message = `${sourcePointer} ${reason.errors[0].detail}`;
          } else {
            message = reason.errors[0].detail;
          }
          this.get('flashMessages').warning(message, { destroyOnClick: true, timeout: 12000 });
        }.bind(this));
      }
    }
  });
});