define('knowme-client/mirage/factories/lead', ['exports', 'ember-cli-mirage', 'faker', 'lodash'], function (exports, _emberCliMirage, _faker, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    leadType: function () {
      return _faker.default.random.arrayElement(['b2b', 'b2c']);
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    afterCreate: function (lead, server) {
      // Organization
      let organization = server.create('organization', { lead: lead });
      // lead.update('organizationId', organization.id); // hack to embed organization into lead

      // Primary contact
      var primaryContact = server.create('contact', { primary: true, lead: lead });

      // Contact Info
      var contactInfo = server.create('contactInfo', { category: _faker.default.random.arrayElement(['work', 'home']), contact: primaryContact });

      // Contact Relationships
      [function () {
        server.create('contactPhoneNumber', { contactInfo: contactInfo });
      }, function () {
        server.create('contactEmail', { contactInfo: contactInfo });
      }][_faker.default.datatype.number({ min: 0, max: 1 })](); // create a contact phone number or an email
      server.create('contactAddress', { contactInfo: contactInfo });

      let account = server.schema.accounts.first();
      // Opportunities
      let campaign = _faker.default.random.arrayElement(server.schema.campaigns.all().models);

      let ad = _faker.default.random.arrayElement(campaign.ads.models);
      let opportunity = server.create('opportunity', {
        lead: lead,
        adId: ad.id,
        ad: ad,
        assignedTo: _faker.default.random.arrayElement(account.users.models),
        salesStage: _faker.default.random.arrayElement(server.schema.salesStages.all().models),
        salesAction: _faker.default.random.arrayElement(server.schema.salesActions.all().models)
      });
      let opportunities = [opportunity];

      _lodash.default.each(opportunities, function (opportunity) {
        let product = _faker.default.random.arrayElement(server.schema.products.all().models);
        // server.createList('order', faker.datatype.number({min: 1, max: 2}), {opportunity: opportunity, product: product}); // opportunity orders
      });
    }
  });
});