define('knowme-client/components/short-messages/table-body/short-message-body-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    classNames: ['body'],
    hasMultipleOpportunities: Ember.computed('seeAllLink', 'shortMessage.participantsOpportunityShortMessagesCount', function () {
      return this.get('seeAllLink') && this.get('shortMessage.participantsOpportunityShortMessagesCount') > 1;
    })
  });
});