define('knowme-client/helpers/contact-texted-first', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute([contactPhoneNumber, did]) {
      return this.get('store').query('short-message', {
        contact_phone_number_id: contactPhoneNumber.get('id'),
        did_id: did.get('id'),
        sort_by: 'created_at',
        order: 'asc',
        size: 1
      });
    }
  });
});