define('knowme-client/controllers/short-messages', ['exports', 'lodash', 'moment', 'knowme-client/mixins/controllers/components/date-filter', 'knowme-client/mixins/controllers/queryable', 'knowme-client/mixins/controllers/pagination'], function (exports, _lodash, _moment, _dateFilter, _queryable, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _queryable.default, _pagination.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    // queryParams: ['type', 'filter', 'query', 'user_id', 'ad_id', 'text_id', 'satisfaction'],
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    filter: null,
    query: null,
    userId: null,
    contactId: null,
    opportunityId: null,
    type: null,
    adId: null,
    campaigns: [],
    ads: [],
    satisfaction: null,
    satisfactionFilterOptions: ['very_dissatisfied', 'dissatisfied', 'neutral', 'satisfied', 'very_satisfied'],
    promptOption: 'prompt',
    onSearchByName: Ember.observer('query', function () {
      // reset all filter
      let query = this.get('query');
      let queryParams = this.get('queryParams');
      if (Ember.isPresent(query)) {
        _lodash.default.each(queryParams, function (key) {
          if (!_lodash.default.includes(['query'], key)) {
            this.set(key, null);
          }
        }.bind(this));
      }
    }),
    onAdSelected: Ember.observer('adId', function () {
      // reset filter user filter
      if (Ember.isPresent(this.get('adId'))) {
        this.set('userId', null);
        this.set('page', null);
      }
    }),
    onUserSelected: Ember.observer('userId', function () {
      // reset filter user filter
      if (Ember.isPresent(this.get('userId'))) {
        this.set('adId', null);
        this.set('page', null);
      }
    }),
    columnLabel: Ember.computed('session.account.subscription.plan.productKey', function () {
      let label;
      let plan = this.get('session.account.subscription.plan.productKey');
      switch (plan) {
        case 'marketing_optimizer_crm':
          label = 'Text From';
          break;
        case 'sales_accelerator_marketing_optimizer_crm':
          label = 'User or Ad Name';
          break;
        default:
          label = 'User Name';
          break;
      }
      return label;
    }),
    filteredModel: Ember.computed.filter('model', function (resource) {
      if (Ember.isPresent(this.get('selectedResource'))) {
        return this.get('selectedResource.id') === resource.get('id');
      } else {
        return true;
      }
    }).property('model', 'selectedResource'),
    showUsersFilter: Ember.computed('model', function () {
      let currentUser = this.get('session.currentUser');
      return currentUser.get('hasAdminRole') || currentUser.get('isManager');
    }),
    modelShortMessagesTotals: Ember.computed('filteredModel.@each.shortMessagesCount', function () {
      return this.get('filteredModel').mapBy('shortMessagesCount');
    }),
    modelShortMessagesTotal: Ember.computed.sum('modelShortMessagesTotals'),
    scoredOpportunities: Ember.computed('filteredModel.@each.scoredOpportunities', function () {
      return this.get('filteredModel').mapBy('scoredOpportunities');
    }),
    scoredOpportunitiesCount: Ember.computed.sum('scoredOpportunities'),
    ad: Ember.computed('ads', 'adId', function () {
      return this.get('ads').findBy('id', this.get('adId'));
    }),
    offsetClassForSearchField: Ember.computed('onlyInbound', 'showUsersFilter', 'ad', function () {
      return this.get('features.campaignsAutomatedCaptureAvailable') && this.get('showUsersFilter') ? '' : 'col-xs-offset-4';
    }),
    actions: {
      openedModal: function () {
        this.send('pauseRefreshing');
      },
      closedModal: function () {
        this.send('continueRefreshing');
      },
      toggleResource(resource) {
        let contactId = this.get('contactId');

        if (Ember.isPresent(contactId) && contactId === resource.get('id')) {
          this.set('contactId', null);
        } else {
          this.set('contactId', resource.id);
        }
      },
      refreshModelData: function () {
        this.send('refreshModel');
      },
      filteredOpportunities: function (resource) {
        let options = { filter: 'with_short_messages', contact_id: resource.get('id'), short_messages: {} };

        if (Ember.isPresent(this.get('userId'))) {
          options.short_messages.user_id = this.get('userId');
        }
        if (Ember.isPresent(this.get('adId'))) {
          options.short_messages.ad_id = this.get('adId');
        }
        if (Ember.isPresent(this.get('query'))) {
          options.short_messages.query = this.get('query');
        }
        if (Ember.isPresent(this.get('satisfaction')) && this.get('satisfaction') !== 'prompt') {
          options.short_messages.satisfaction = this.get('satisfaction');
        }
        let dateOptions = { from_date: this.get('from_date'), to_date: this.get('to_date') };
        let formattedDateOptions = this.processDateFilterParams(dateOptions);
        if (Ember.isPresent(formattedDateOptions.from_date) && Ember.isPresent(formattedDateOptions.to_date)) {
          Ember.assign(options.short_messages, formattedDateOptions);
        }

        options.paginate = false;
        return this.get('store').query('opportunity', options);
      }
    },
    processDateFilterParams: function (params) {
      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    }
  });
});