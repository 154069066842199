define('knowme-client/models/signature', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/validations/signature', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'moment'], function (exports, _model, _attr, _relationships, _signature, _emberCpValidationsCustomProperties, _dirty, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_signature.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    // relationships
    authentication: (0, _relationships.belongsTo)('authentication', { async: true }),
    //fields
    contentWidth: (0, _attr.default)(),
    body: (0, _attr.default)('string', { defaultValue: '' }),
    isEnabled: (0, _attr.default)('boolean', { defaultValue: false }),
    isDisabled: Ember.computed.not('isEnabled'),
    updatedAt: (0, _attr.default)('date'),
    updatedAtInUix: Ember.computed('updatedAt', function () {
      return (0, _moment.default)(this.get('updatedAt')).unix();
    })
  });
});