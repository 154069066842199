define('knowme-client/serializers/account-setting-json', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      account: { serialize: 'false' }
    }
  });
});