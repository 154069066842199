define('knowme-client/components/notification-message', ['exports', 'ember-cli-notifications/components/notification-message'], function (exports, _notificationMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _notificationMessage.default.extend({
    actions: {
      handleMouseEnter: function handleMouseEnter() {
        if (this.get('notification.autoClear')) {
          this.set('paused', true);
          this.get('notifications').pauseAutoClear(this.get('notification'));
        }
      },
      handleMouseLeave: function handleMouseLeave() {
        if (this.get('notification.autoClear')) {
          this.set('paused', false);
          this.get('notifications').setupAutoClear(this.get('notification'));
        }
      }
    }
  });
});