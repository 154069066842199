define('knowme-client/controllers/wizard/users', ['exports', 'knowme-client/mixins/controllers/status', 'ember-concurrency'], function (exports, _status, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_status.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    step: 'users',
    user: Ember.computed.alias('model'),
    searchDids: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(800);

      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let didsUrl = adapter.buildURL('dids');

      let didsRequest = adapter.ajax(didsUrl, 'GET', { data: { type: 'local', filter: 'unassigned', query: term } });
      return didsRequest.then(function (dids) {
        return dids.map(function (did) {
          return did.number;
        });
      });
    }),
    actions: {
      submit: function (step) {

        let controller = this;
        let resource = controller.get('model');
        let account = controller.get('session.currentUser.account');

        if (step === 'exit') {
          account.set('currentWizardStep', 'complete');
          controller.transitionToRoute('dashboard');
        } else {
          resource.save().then(function () {
            if (step === 'close') {
              account.set('currentWizardStep', 'complete');
              controller.transitionToRoute('dashboard');
            }
            if (step === 'new') {
              account.set('currentWizardStep', 'users');
            }
            controller.get('target._routerMicrolib').refresh();
          }).catch(function (reason) {
            console.log("Errors", reason);
          }.bind(this));
        }
      },
      clear: function () {
        this.get('model').rollbackAttributes();
        let originalRoleId = this.get('model').get('_internalModel._relationships').get('roles').canonicalState[0].id;
        let originalRole = this.get('store').peekRecord('role', originalRoleId);
        this.get('model').set('role', originalRole);
        this.transitionToRoute('wizard.users');
      }
    },
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.rolesHasChanged', 'model.didAllocation.didNumberHasChanged', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.rolesHasChanged') || this.get('model.didAllocation.didNumberHasChanged');
    }),
    disabledCallerId: Ember.computed('model', 'model.didAllocation.did', 'model.didAllocation.didNumberHasChanged', function () {
      return !this.get('model.isNew') && !this.get('model.didAllocation.didNumberHasChanged') && this.get('model.didAllocation.did');
    }),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'isModelTreeDirty', function () {
      return !(this.get('model.validations.isValid') && this.get('isModelTreeDirty'));
    })
  });
});