define('knowme-client/mixins/opportunity-form', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let OpportunityFormMixin;

  OpportunityFormMixin = Ember.Mixin.create({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    campaignAdSelectIsOpen: false,
    showQuoteForm: false,
    isCreatingEstimate: false,
    init: function () {
      this._super.apply(this, arguments);
      Ember.run.schedule("afterRender", this, function () {
        Ember.$("#opportunity-orders [data-toggle='tooltip']").tooltip();

        // Open time picker for appt. date when date is selected
        Ember.$("#appt-datetimepicker").on("dp.show", function () /*e*/{
          Ember.$(".day").on('click', function () {
            Ember.$("a[data-action='togglePicker']").trigger('click');
          });
        });
        Ember.$("#appt-datetimepicker").on("dp.change", function () /*e*/{
          Ember.$(".day").on('click', function () {
            Ember.$("a[data-action='togglePicker']").trigger('click');
          });
        });
      });
    },
    enableOpportunityFields: Ember.computed.filter('session.currentUser.account.opportunityFields', function (opportunityField) {
      return opportunityField.get('enable');
    }),
    didReceiveAttrs() /*options*/{
      this.get('session.currentUser.account.opportunityFields').forEach(function (field) {
        if (field.get('internal') || !field.get('enable')) {
          return;
        }
        let customAttribute = this.get('opportunity.customAttributes').findBy('name', field.get('key'));
        if (Ember.isPresent(customAttribute)) {
          customAttribute.setProperties({ 'position': field.get('position'), displayName: field.get('name') });
        } else {
          this.get('store').createRecord('custom-attribute', {
            opportunity: this.get('opportunity'),
            name: field.get('key'),
            displayName: field.get('name'),
            value: "",
            position: field.get('position')
          });
        }
      }.bind(this));
    },
    actions: {
      addOrder: function () {
        this.get('store').createRecord('order', { opportunity: this.get('opportunity') });
      },
      setRelationshipValue: function (object, relationshipName, event) {
        object.set(relationshipName, event.target.value);
      },
      selectClosed: function (selectId, state) {
        console.log(selectId, state);
      },
      confirmRemoveOrder: function (order) {
        this.set('orderToBeRemoved', order);
      },
      removeOrder: function () {
        this.get('orderToBeRemoved').destroyRecord();
      },
      openQuoteForm: function () {
        if (Ember.isPresent(this.get('lastSalesTransaction'))) {
          this.set('showQuoteForm', true);
        } else {
          let salesTransaction = this.get('store').createRecord('sales-transaction', { type: this.get('salesTransactionType'), opportunity: this.get('model'), contact: this.get('selectedContact') });
          this.set('isCreatingEstimate', true);
          salesTransaction.save().then(function () {
            this.set('showQuoteForm', true);
            this.set('isCreatingEstimate', false);
          }.bind(this));
        }
      }
    },
    opportunity: Ember.computed('model', function () {
      return this.get('model');
    }),
    priorities: Ember.computed('model', function () {
      let _priorities = [{ id: 'low', name: 'Low' }, { id: 'medium', name: 'Medium' }, { id: 'high', name: 'High' }];
      return _priorities;
    }),
    priorityPromt: Ember.computed('model', function () {
      return this.get('model.isNew') ? 'Select a Priority' : '(none)';
    }),
    priorityOptions: [{ id: "low", name: "Low" }, { id: "medium", name: "Medium" }, { id: "high", name: "High" }],
    activityOptions: Ember.computed('features.isBasicCrmOrKnowmeIQStarter', function () {
      let options = [];
      if (this.get('features.isBasicCrmOrKnowmeIQStarter')) {
        options = ["inbound_call", "outbound_call", "inbound_email", "outbound_email", "inbound_sms", "outbound_sms"];
      }
      options = options.concat(["update", "sent_call_audio", "form", "in_person", "referral", "adwords", "inbound_fax", "outbound_fax", "other"]);
      return options;
    }),
    disableAssignedToSelect: Ember.computed('currentUser.roles.@each.name', function () {
      return !this.get('session.currentUser.hasAdminRole') || !this.get('session.currentUser.isManager');
    }),
    disableSalesStageSelect: Ember.computed('model', 'model.salesStage.key', function () {
      return this.get('model.isNew') && this.get('model.salesStage.key') === 'closed';
    }),
    hasTaxableProduct: Ember.computed('model.orders.@each.productId', function () {
      return this.get('model.orders') && this.get('model.orders').any(function (order) {
        return order.get('product.taxable');
      });
    }),
    missingProduct: Ember.computed('model.nonDeletedOrders.@each.productId', 'model.nonDeletedOrders.@each.quantity', function () {
      let hasInvalidProduct = this.get('opportunity.validations.attrs.nonDeletedOrders.errors').any(function (error) {
        return error.attribute === 'product';
      });
      return this.get('model.nonDeletedOrders') && hasInvalidProduct;
    }),
    hasSoldOutProducts: Ember.computed('model.nonDeletedOrders.@each.productId', function () {
      return this.get('model.nonDeletedOrders') && this.get('model.nonDeletedOrders').any(function (order) {
        return order.get('product.isSoldOut');
      });
    }),
    missingQuantity: Ember.computed('model.nonDeletedOrders.@each.quantity', 'model.nonDeletedOrders.@each.productId', function () {
      return this.get('model.nonDeletedOrders') && this.get('model.nonDeletedOrders').any(function (order) {
        return Ember.isPresent(order.get('validations.attrs.quantity.error.message'));
      });
    }),
    maxDiscountExceeded: Ember.computed('model.nonDeletedOrders.@each.maxDiscountPercentageExceeded', function () {
      return this.get('model.nonDeletedOrders') && this.get('model.nonDeletedOrders').isAny('maxDiscountPercentageExceeded', true);
    }),
    invalidOrders: Ember.computed('model.nonDeletedOrders.@each.isValid', function () {
      return this.get('model.nonDeletedOrders') && this.get('model.nonDeletedOrders').isAny('isValid', false);
    }),
    disableAddOrder: Ember.computed.alias('invalidOrders'),
    appointmentDateIsBlank: Ember.computed('opportunity.salesStage', 'opportunity.appointmentDate', function () {
      return this.get('opportunity.salesStage.key') === 'appointment' && Ember.isBlank(this.get('opportunity.appointmentDate'));
    }),
    openedOppHasSoldOutProducts: Ember.computed('hasSoldOutProducts', function () {
      return this.get('hasSoldOutProducts') && !this.get('wasOpportunityClosed');
    }),
    ordersProductsFeedbackClass: Ember.computed('missingProduct', 'openedOppHasSoldOutProducts', function () {
      return this.get('missingProduct') || this.get('openedOppHasSoldOutProducts') ? 'glyphicon-asterisk text-danger sup' : 'glyphicon-ok text-success sup';
    }),
    ordersQuantitiesFeedbackClass: Ember.computed('missingQuantity', function () {
      return this.get('missingQuantity') ? 'glyphicon-asterisk text-danger sup' : 'glyphicon-ok text-success sup';
    }),
    wasOpportunityClosed: Ember.computed('model', 'model.salesStageHasChanged', function () {
      if (this.get('opportunity.isNew')) {
        return false;
      }

      if (this.get('opportunity.salesStageHasChanged')) {
        return this.get('opportunity').get('sortedActivities.firstObject').get('body.opportunity').salesStage.key === 'closed';
      } else {
        return this.get('opportunity.isClosed');
      }
    }),
    maxDiscountFeedbackClass: Ember.computed('maxDiscountExceeded', function () {
      return this.get('maxDiscountExceeded') ? 'glyphicon-asterisk text-danger sup' : 'glyphicon-ok text-success sup';
    }),
    opportunityPipeline: Ember.computed('model.nonDeletedOrders.@each.total', function () {
      return this.get('model.nonDeletedOrders').reduce(function (acc, order) {
        return acc + order.get('total');
      }, 0);
    }),
    disableEdit: Ember.computed('model', 'model.salesStage.key', function () {
      return !this.get('model.isNew') && this.get('model.salesStage.key') === 'closed' && !this.get('session.currentUser.hasAdminRole');
    }),
    onDueDateChange: Ember.observer('model.dueDate', function () {
      if ((0, _moment.default)(this.get('model.dueDate')).isAfter((0, _moment.default)(this.get('model.forecastDate')))) {
        this.set('model.forecastDate', this.get('model.dueDate'));
        Ember.$("#opportunity-forecast-date").focus();
      }
    })
  });

  exports.default = OpportunityFormMixin;
});