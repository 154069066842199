define('knowme-client/controllers/wizard/client', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service(),
		features: Ember.inject.service(),
		wizardController: Ember.inject.controller('wizard'),
		step: 'businessType',
		actions: {
			transitionToNextStep: function () /*account*/{
				this.set('wizardController.skipRedirectToDashboard', true);
				if (this.get('session.currentUser.isKnowmeIqRequired') && !this.get('session.currentUser.autopylotInstalled')) {
					this.transitionToRoute('wizard.profile');
				} else {
					this.transitionToRoute('wizard.products');
				}
			}
		}
	});
});