define('knowme-client/models/plan', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    subscription: (0, _relationships.hasMany)('subscription', { async: true }),
    key: (0, _attr.default)('string', { defaultValue: "" }),
    settings: (0, _attr.default)('json'),
    isBasicCrm: Ember.computed.equal('productKey', 'basic_crm'),
    isMarketingOptimizer: Ember.computed.equal('productKey', 'marketing_optimizer_crm'),
    isSalesAccelerator: Ember.computed.equal('productKey', 'sales_accelerator_crm'),
    isSalesAcceleratorAndMarketingOptimizer: Ember.computed.equal('productKey', 'sales_accelerator_marketing_optimizer_crm'),
    isStarter: Ember.computed.equal('planKey', 'starter'),
    isStarterUnlimited: Ember.computed.equal('planKey', 'starter_unlimited'),
    isKnowmeIQ: Ember.computed.equal('productKey', 'knowme_iq'),
    isKnowmeIQStarter: Ember.computed('isKnowmeIQ', 'isStarter', function () {
      return this.get('isKnowmeIQ') && this.get('isStarter');
    }),
    isKnowmeIQStarterUnlimited: Ember.computed('isKnowmeIQ', 'isStarterUnlimited', function () {
      return this.get('isKnowmeIQ') && this.get('isStarterUnlimited');
    }),
    enablePromoCode: (0, _attr.default)('boolean', { defaultValue: true }),
    productKey: Ember.computed('key', function () {
      return this.get('key').split('.')[0];
    }),
    planKey: Ember.computed('key', function () {
      return this.get('key').split('.')[1];
    })
  });
});