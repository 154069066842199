define('knowme-client/routes/leads/show/contacts/new', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    // templateName: 'leads/show/contacts',
    model: function () {
      let lead, contact;

      lead = this.modelFor('leads.show');
      contact = this.store.createRecord('contact', { lead: lead });
      this.store.createRecord('contactInfo', { category: 'work', contact: contact });
      this.set('lead', lead);
      this.set('newContact', contact);

      // let resource = this.store.createRecord('sales-stage');
      return contact;
    },
    afterModel: function () {
      return Ember.RSVP.hash({
        contactFields: this.get('store').query('contact-field', { enable: true })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('lead', this.get('lead'));
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        if (model.get('isNew')) {
          model.deleteRecord();
        }
      }
    }
  });
});