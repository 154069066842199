define('knowme-client/components/chats/audio-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hifi: Ember.inject.service(),
    tagName: '',
    playable: true,
    didReceiveAttrs() {
      this._super(...arguments);
    },
    willDestroyElement: function () {
      this._super(...arguments);
      if (Ember.isPresent(this.get('audio'))) {
        if (this.get('audio.isPlaying')) {
          this.get('audio').pause();
        }
        this.set('audio.position', 0);
      }
    },
    percentListened: Ember.computed('audio.{position,duration}', function () {
      if (Ember.isBlank(this.get('audio.position')) || Ember.isBlank(this.get('audio.duration'))) {
        return 0;
      }
      return this.get('audio.position') * 100 / this.get('audio.duration');
    }),
    duration: Ember.computed('audio.{position,duration,isPlaying,hasPlayed}', function () {
      if (Ember.isPresent(this.get('audio'))) {
        if (this.get('audio.isPlaying')) {
          return this.get('audio.position');
        }
        if (this.get('audio.hasPlayed')) {
          return this.get('audio.position') > 0 ? this.get('audio.position') : this.get('audio.duration');
        }
        return this.get('audio.duration');
      } else {
        return this.get('totalDuration');
      }
    }),
    actions: {
      play() {
        if (Ember.isPresent(this.get('audio'))) {
          this.get('audio').play();
        } else {
          this.get('hifi').play(this.get('url')).then(({ sound }) => {
            this.set('audio', sound);
          }).catch(error => {
            this.set('audio', null);
            console.log('Error', error);
          });
        }
      },
      pause() {
        this.get('audio').pause();
      }
    }
  });
});