define('knowme-client/routes/campaigns', ['exports', 'knowme-client/mixins/routes/account-setup', 'knowme-client/mixins/routes/cable-setup', 'lodash', 'moment'], function (exports, _accountSetup, _cableSetup, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let CampaignsRoute;

  CampaignsRoute = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    channelName: 'CampaignChannel',
    queryParams: {
      campaign_id: { refreshModel: true },
      channel: { refreshModel: true },
      from_date: { refreshModel: true },
      to_date: { refreshModel: true },
      page: { refreshModel: true },
      query: { refreshModel: true }
    },
    pagination: {},
    sortByMapping: {
      name: 'title',
      leads: 'leads_count',
      conversions: 'customers_count',
      roi: 'roi_percentage'
    },
    beforeModel: function (transition) {
      return this._super.apply(this, arguments).then(function () {
        if (!this.get('features.campaignsSectionAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Access Unauthorized', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        }
        if (Ember.isBlank(transition.queryParams.status)) {
          this.transitionTo({ queryParams: { status: 'active' } });
        }
      }.bind(this));
    },
    model: function (params) {
      let promise = this.get('store').query('campaign', this.processParams(params));

      promise.then(result => {
        this.set('pagination.total', result.meta.pagination.total);
        this.set('pagination.limit', result.meta.pagination.limit);
        this.set('pagination.page', result.meta.pagination.page);
        let meta = {
          adsCount: 0,
          leadsCount: 0,
          totalPipeline: 0,
          customersCount: 0,
          totalRevenue: 0,
          totalCost: 0,
          costPerLead: 0
        };

        result.forEach(function (campaign) {
          meta.adsCount += campaign.get('ads.length');
          meta.leadsCount += campaign.get('leadsCount');
          meta.customersCount += campaign.get('customersCount');
          meta.totalPipeline += campaign.get('pipeline');
          meta.totalRevenue += campaign.get('revenue');
          meta.totalCost += _lodash.default.sum(campaign.get('ads').mapBy('cost'));
        }.bind(this));
        meta.costPerLead = meta.leadsCount == 0 ? 0 : meta.totalCost / meta.leadsCount;
        this.set('meta', meta);
      });
      return promise;
    },
    afterModel(model) {
      let status = 'active';
      if (model.get('length') > 0) {
        status = model.get('firstObject.status');
      }
      let currentUser = this.get('session.currentUser');
      this.set('subscriptionData', { id: currentUser.get('account.id') });
      return Ember.RSVP.hash({
        campaignsFilterOptions: this.get('store').query('campaign', { paginate: false, sort_by: 'name', order: 'asc', status: status })
      }).then(function (results) {
        this.set('controllerData', results);
      }.bind(this));
    },
    afterReceived(controller, payload, action) {
      let params = {};
      // refresh meta data
      _lodash.default.each(controller.get('queryParams'), function (key) {
        params[key] = controller.get(key);
      });
      this.model(params).then(function (model) {
        controller.set('model', model);
      });
    },
    setupController: function (controller) {
      this._super(...arguments); // Call _super for default behavior
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));
      controller.set('meta', this.get('meta'));
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    processParams: function (_params) {
      let _this = this;
      let params = Ember.copy(_params);

      if (params.campaign_id) {
        params.ids = [params.campaign_id];
        delete params.campaign_id;
      }

      if (params.channel) {
        params.ads = { channel: params.channel };
        delete params.channel;
      }

      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'sort_by') {
          let newValue = _this.sortByMapping[value];
          if (Ember.isPresent(newValue)) {
            return newValue;
          }
        }
        if (key === 'from_date' || key === 'to_date') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    },
    actions: {
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      },
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      queryParamsDidChange: function () {
        this.refresh();
      },
      willTransition(transition) {
        this.get('store').peekAll('ad').invoke('unloadRecord');
        this.get('store').peekAll('campaign').invoke('unloadRecord');
      }
    }
  });

  exports.default = CampaignsRoute;
});