define('knowme-client/routes/account/settings/alerts', ['exports', 'lodash', 'knowme-client/mixins/routes/account-setup'], function (exports, _lodash, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      return this.get('store').query('alert', this.processParams(params));
    },
    processParams: function (_params) {
      return _lodash.default.pickBy(Ember.copy(_params), _lodash.default.identity);
    }
  });
});