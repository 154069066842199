define('knowme-client/services/notification', ['exports', 'moment', 'knowme-client/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    dataStore: Ember.inject.service('store'),
    date: null,
    context: null,
    init() {
      this._super(...arguments);
      this.get('dataStore').query('notification', {}).then(function () /*notifications*/{
        this.set('date', (0, _moment.default)());
      }.bind(this));
      let self = this;
      this.get('session').get('currentUser').then(function (currentUser) {
        self.createConsumer({ id: currentUser.get('id') });
      });
    },
    setContext(context) {
      this.set('context', context);
    },
    cableService: Ember.inject.service('cable'),
    createConsumer: function (data) {
      this._super.apply(this, arguments);
      let self = this;
      let path = `${_environment.default.APP.Websocket.protocol}://${_environment.default.APP.Websocket.host}/cable`;
      let consumer = this.get('cableService').createConsumer(path);

      const subscription = consumer.subscriptions.create('NotificationChannel', {
        connected() {
          this.perform("follow", data);
        },
        received(resp) {
          let payload = resp.payload;
          if (typeof payload === 'string') {
            payload = JSON.parse(resp.payload);
          }

          let id = payload.data.id;
          let newNotification = false;
          Ember.run.later(function () {
            if (!self.get("dataStore").peekRecord(resp.modelName, id)) {
              newNotification = true;
            }
            self.get("dataStore").pushPayload(resp.modelName, payload);
            if (newNotification) {
              let lastNotification = self.get("dataStore").peekRecord(resp.modelName, id);
              if (self.get('context')) {
                if (lastNotification.get('action') === 'assigned') {
                  lastNotification.get('notifiable').then(function (opp) {
                    opp.reload().then(function () /*res*/{
                      self.get('context').onReceiveNotification(lastNotification);
                    });
                  });
                } else {
                  self.get('context').onReceiveNotification(lastNotification);
                }
              }
            }
            // self.set('date', moment());
          }, 1000);
        },
        disconnected() {
          // console.log("disconnected");
        }
      });
    },

    notifications: Ember.computed('date', function () {
      return this.get("dataStore").peekAll('notification');
    }),
    unreadNotifications: Ember.computed.filterBy('notifications', 'isUnread', true),
    unreadNotificationsCount: Ember.computed.alias('unreadNotifications.length'),
    sorting: ['createdAt:desc'],
    sortedNotifications: Ember.computed.sort('unreadNotifications', 'sorting')
  });
});