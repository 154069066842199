define('knowme-client/models/sent-reminder', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    deliveryType: (0, _attr.default)(),
    reminderType: (0, _attr.default)(),
    timeBefore: (0, _attr.default)('number'),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
    appointmentDate: (0, _attr.default)('date'),
    deliveryDate: (0, _attr.default)('date'),
    formattedTimeBefore: Ember.computed('timeBefore', function () {
      if (this.get('timeBefore') == 60) {
        return '1 hour';
      } else if (this.get('timeBefore') < 60) {
        return `${this.get('timeBefore')} minutes`;
      } else {
        return `${this.get('timeBefore') / 60} hours`;
      }
    })
  });
});