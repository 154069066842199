define('knowme-client/controllers/checkout/success', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['code', 'session_id'],
    printThis: Ember.inject.service(),
    invoice: Ember.computed.alias('model'),
    opportunity: Ember.computed.alias('invoice.opportunity'),
    account: Ember.computed.alias('invoice.account'),
    contact: Ember.computed.alias('invoice.contact'),
    actions: {
      doPrint: function () {
        const selector = '.sales-transaction';
        const options = {
          printDelay: 500,
          importCSS: true,
          importStyle: true
        };
        this.get('printThis').print(selector, options);
      }
    }
  });
});