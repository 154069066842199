define('knowme-client/controllers/wizard/products', ['exports', 'knowme-client/mixins/controllers/product-types', 'knowme-client/mixins/controllers/product-photo', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/status'], function (exports, _productTypes, _productPhoto, _pagination, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_status.default, _productTypes.default, _pagination.default, _productPhoto.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    step: 'products',
    product: Ember.computed.alias('model'),
    unsavedPhotos: [],
    percentageMaskOptions: { suffix: " %" },
    showingEnd: Ember.computed('products.length', 'limit', 'count', function () {
      return this.get('limit') * (this.get('current') - 1) + this.get('products.length');
    }),
    onTaxableChange: Ember.observer('model.taxable', function () {
      if (!this.get('model.isNew')) {
        return;
      }
      if (this.get('model.taxable')) {
        if (Ember.isPresent(this.get('session.currentUser.account.activeSettings.taxRate'))) {
          this.set('model.taxRate', this.get('session.currentUser.account.activeSettings.taxRate'));
        }
      } else {
        this.set('model.taxRate', null);
      }
    }),
    displayInventoryField: Ember.computed('model.kind', function () {
      return ['product', 'bundle'].includes(this.get('model.kind'));
    }),
    actions: {
      submit: function (step) {
        let controller = this;
        let resource = controller.get('model');
        let account = controller.get('session.currentUser.account');
        if (step === 'exit') {
          account.set('currentWizardStep', 'complete');
          controller.transitionToRoute('dashboard');
        } else {
          if (controller.get('model.isNew')) {
            controller.get('model').set('files', this.get('productPhotos'));
          }
          resource.save().then(function () {
            controller.set('base64Data', null);
            controller.set('unsavedPhotos', []);
            if (step === 'close') {
              if (controller.get('features.usersAvailable')) {
                account.set('currentWizardStep', 'products');
                controller.transitionToRoute('wizard.users');
              } else {
                account.set('currentWizardStep', 'complete');
                controller.transitionToRoute('dashboard');
              }
            }
            if (step === 'new') {
              account.set('currentWizardStep', 'products');
            }
            controller.get('target._routerMicrolib').refresh();
          }).catch(function (reason) {
            console.log("Errors", reason);
          }.bind(this));
        }
      },
      clear: function () {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('wizard.products');
      }
    },
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', function () {
      return this.get('model.hasDirtyAttributes');
    }),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'isModelTreeDirty', function () {
      return !(this.get('model.validations.isValid') && this.get('isModelTreeDirty'));
    }),
    taxCodeOptions: [{ value: true, name: 'Taxable' }, { value: false, name: 'Non-Taxable' }]
  });
});