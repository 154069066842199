define('knowme-client/models/chat-touch-point', ['exports', 'ember-data/model', 'ember-data/attr', 'knowme-client/mixins/models/validations/chat-touch-point', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'ember-data/relationships'], function (exports, _model, _attr, _chatTouchPoint, _emberCpValidationsCustomProperties, _dirty, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const WORK_TIMES_FIELDS = ['workTimes.mon.open', 'workTimes.mon.from.h', 'workTimes.mon.from.m', 'workTimes.mon.from.ampm', 'workTimes.mon.to.h', 'workTimes.mon.to.m', 'workTimes.mon.to.ampm', 'workTimes.tue.open', 'workTimes.tue.from.h', 'workTimes.tue.from.m', 'workTimes.tue.from.ampm', 'workTimes.tue.to.h', 'workTimes.tue.to.m', 'workTimes.tue.to.ampm', 'workTimes.wed.open', 'workTimes.wed.from.h', 'workTimes.wed.from.m', 'workTimes.wed.from.ampm', 'workTimes.wed.to.h', 'workTimes.wed.to.m', 'workTimes.wed.to.ampm', 'workTimes.thu.open', 'workTimes.thu.from.h', 'workTimes.thu.from.m', 'workTimes.thu.from.ampm', 'workTimes.thu.to.h', 'workTimes.thu.to.m', 'workTimes.thu.to.ampm', 'workTimes.fri.open', 'workTimes.fri.from.h', 'workTimes.fri.from.m', 'workTimes.fri.from.ampm', 'workTimes.fri.to.h', 'workTimes.fri.to.m', 'workTimes.fri.to.ampm', 'workTimes.sat.open', 'workTimes.sat.from.h', 'workTimes.sat.from.m', 'workTimes.sat.from.ampm', 'workTimes.sat.to.h', 'workTimes.sat.to.m', 'workTimes.sat.to.ampm', 'workTimes.sun.open', 'workTimes.sun.from.h', 'workTimes.sun.from.m', 'workTimes.sun.from.ampm', 'workTimes.sun.to.h', 'workTimes.sun.to.m', 'workTimes.sun.to.ampm'];

  exports.default = _model.default.extend(_chatTouchPoint.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    ad: (0, _relationships.belongsTo)('ad', { async: true }),
    chatTouchPointMembers: (0, _relationships.hasMany)('chat-touch-point-member', { async: true }),
    // members: hasMany('user', { async: true }),
    name: (0, _attr.default)(),
    apiKey: (0, _attr.default)(),
    workTimes: (0, _attr.default)(),
    setupHasChangedWorkTimes: Ember.on('init', function () {
      let workTimes = Ember.Object.create(Ember.copy(this.get('workTimes'), true));
      Ember.defineProperty(this, 'hasWorkTimesChanged', Ember.computed(...WORK_TIMES_FIELDS, function () {
        let hasChanged = false;
        WORK_TIMES_FIELDS.forEach(function (field) {
          let parts = field.split('.');
          parts.shift();
          let key = parts.join('.');
          if (this.get(field) != workTimes.get(key)) {
            console.log(field, this.get(field), workTimes.get(key));
          }
          hasChanged = hasChanged || this.get(field) != workTimes.get(key);
        }.bind(this));
        return hasChanged;
      }));
    })
  });
});