define('knowme-client/controllers/customers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomersController;

  CustomersController = Ember.Controller.extend({
    queryParams: ['page', 'sortBy', 'order'],
    page: null,
    sortBy: null,
    order: null,
    count: null,
    current: null,
    actions: {
      sort: function (toSortBy) {
        var sortBy = this.get('sortBy') || 'name';
        var order = this.get('order');
        if (sortBy === toSortBy) {
          order = order === 'asc' ? 'desc' : 'asc';
        } else {
          sortBy = toSortBy;
          order = 'desc';
        }
        this.set('sortBy', sortBy);
        this.set('order', order);
        this.send('setOrder', sortBy, order);
      }
    }
  });

  exports.default = CustomersController;
});