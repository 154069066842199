define('knowme-client/models/customer-experience', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomerExperience;

  CustomerExperience = _model.default.extend({
    // Relationships
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
    contact: (0, _relationships.belongsTo)('contact', { async: true, inverse: 'customerExperiences' }),
    customerExperienceResource: (0, _relationships.belongsTo)('customer-experience-resource', { async: true, polymorphic: true, inverse: 'customerExperience' }),
    // Attributes
    clientName: (0, _attr.default)(),
    satisfaction: (0, _attr.default)(),
    status: (0, _attr.default)(),
    improved: (0, _attr.default)(),
    createdAt: (0, _attr.default)('date')
  });

  exports.default = CustomerExperience;
});