define('knowme-client/routes/application', ['exports', 'moment-timezone'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ApplicationRoute;

  ApplicationRoute = Ember.Route.extend({
    intl: Ember.inject.service(),
    moment: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    routeAfterAuthentication: 'dashboard',
    routeIfAlreadyAuthenticated: 'dashboard',
    controllerData: {},
    beforeModel: function (transition) {
      // define the app's runtime locale
      // For example, here you would maybe do an API lookup to resolver
      // which locale the user should be targeted and perhaps lazily
      // load translations using XHR and calling intl's `addTranslation`/`addTranslations`
      // method with the results of the XHR request
      let timezone = 'America/Los_Angeles';

      this.get('intl').setLocale('en-us');
      if (this.paramsFor('login').changedPassword !== "true") {
        timezone = this.get('session.currentUser.timeZoneMapping');
      }
      this.get('moment').changeTimeZone(timezone);

      switch (transition.targetName) {
        case 'communications':
        case 'feedback':
        case 'quote':
        case 'checkout':
        case 'checkout.canceled':
        case 'checkout.success':
        case 'reviews.new':
          this.set('controllerData', { frameless: true });
          break;
        case 'login':
          this.set('controllerData', { noHeader: true });
          break;
      }
      if (Ember.isPresent(transition.queryParams.token) && !this.get('session.tokenAuthenticated')) {
        transition.abort();
        return this.get('session').authenticate('authenticator:auth-token', transition.queryParams.token).then(function () {
          this.set('session.tokenAuthenticated', true);
          transition.retry();
        }.bind(this)).catch(function () {
          this.transitionTo('login').then(function () {
            this.get('flashMessages').warning('Invalid Authentication', { destroyOnClick: true, timeout: 12000 });
          }.bind(this));
        }.bind(this));
      }
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      logout: function () {
        return this.get('session').invalidate();
      }
    },
    sessionInvalidated() {
      if (this.paramsFor('login').changedPassword !== "true") {
        this._super(...arguments);
      }
    }
  });

  exports.default = ApplicationRoute;
});