define('knowme-client/serializers/opportunity', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let OpportunitySerializer;

  OpportunitySerializer = _application.default.extend({
    features: Ember.inject.service(),
    attrs: {
      orders: { embedded: 'always' },
      attachments: { embedded: 'always' },
      customAttributes: { embedded: 'always' },
      assignedTo: { deserialize: 'id' },
      salesAction: { deserialize: 'records' },
      salesStage: { deserialize: 'records' },
      calls: { deserialize: 'records' },
      activities: { embedded: false },
      firstActivity: { deserialize: 'id', serialize: false },
      lastActivity: { deserialize: 'id', serialize: false },
      lastSalesTransaction: { deserialize: 'id', serialize: false },
      lead: { deserialize: 'id', serialize: 'id' }
    },
    keyForRelationship(key, relationship, method) {
      let models = ['lead', 'ad', 'firstActivity', 'lastActivity', 'lastSalesTransaction', 'assignedTo'];
      if (models.includes(key) && method === 'deserialize') {
        return `${Ember.String.underscore(key)}_id`;
      }
      return this._super(...arguments);
    },
    _generateSerializedHasMany(snapshot, relationship) {
      var hasMany = [];
      Ember.get(snapshot._internalModel._relationships.get(relationship.key), 'members').forEach(function (member) {
        let memberSnapshot = member.createSnapshot();
        if (relationship.key === 'orders' && Ember.isPresent(memberSnapshot.attr('quantity')) && Ember.isPresent(memberSnapshot.belongsTo('product'))) {
          hasMany.push(memberSnapshot);
        } else {
          hasMany.push(memberSnapshot);
        }
      });
      var manyArray = Ember.A(hasMany);
      var ret = new Array(manyArray.length);

      for (var i = 0; i < manyArray.length; i++) {
        var embeddedSnapshot = manyArray[i];
        var embeddedJson = embeddedSnapshot.serialize({ includeId: true });
        this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
        ret[i] = embeddedJson;
      }

      return ret;
    },
    normalize(typeClass, hash /*, prop*/) {
      delete hash.activity;
      hash.note = null;
      hash.links = {
        orders: Ember.getOwner(this).lookup('adapter:order').buildURL('order', null, null, 'findMany', { opportunity_id: hash.id }),
        customAttributes: Ember.getOwner(this).lookup('adapter:custom-attribute').buildURL('custom-attribute', null, null, 'findMany', { opportunity_id: hash.id }),
        salesTransactions: Ember.getOwner(this).lookup('adapter:sales-transaction').buildURL('sales-transaction', null, null, 'findMany', { opportunity_id: hash.id }),
        customerExperiences: Ember.getOwner(this).lookup('adapter:customer-experience').buildURL('customer-experience', null, null, 'findMany', { opportunity_id: hash.id }),
        activities: Ember.getOwner(this).lookup('adapter:lead-activity').buildURL('lead-activity', null, null, 'findMany', { opportunity_id: hash.id }),
        assignedTo: Ember.getOwner(this).lookup('adapter:user').buildURL('user', hash.assigned_to_id, null, 'findRecord', null)
      };
      if (Ember.isPresent(hash.ad_id)) {
        hash.links.ad = Ember.getOwner(this).lookup('adapter:ad').buildURL('ad', hash.ad_id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.last_activity_id)) {
        hash.links.lastActivity = Ember.getOwner(this).lookup('adapter:lead-activity').buildURL('lead-activity', hash.last_activity_id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.first_activity_id)) {
        hash.links.firstActivity = Ember.getOwner(this).lookup('adapter:lead-activity').buildURL('lead-activity', hash.first_activity_id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.first_short_message_id)) {
        hash.links.firstShortMessage = Ember.getOwner(this).lookup('adapter:short-message').buildURL('short-message', hash.first_short_message_id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.last_short_message_id)) {
        hash.links.lastShortMessage = Ember.getOwner(this).lookup('adapter:short-message').buildURL('short-message', hash.last_short_message_id, null, 'findRecord', null);
      }
      if (Ember.isPresent(hash.last_sales_transaction_id)) {
        hash.links.lastSalesTransaction = Ember.getOwner(this).lookup('adapter:sales-transaction').buildURL('sales-transaction', hash.last_sales_transaction_id, null, 'findRecord', null);
      }
      return this._super.apply(this, arguments);
    },
    serialize: function (snapshot /*, options*/) {
      var json = this._super(...arguments);
      if (snapshot.record.get('activity') === 'update') {
        delete json.activity;
      }

      return json;
    }
  });

  exports.default = OpportunitySerializer;
});