define('knowme-client/mixins/models/validations/call-touch-point', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    forwardTo1: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { is: 12, message: "This field is invalid." }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true,
      allowBlank: true
    }), (0, _emberCpValidations.validator)('ds-error')],
    forwardTo2: [(0, _emberCpValidations.validator)('length', { is: 12, allowBlank: true, message: "This field is invalid." }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true,
      allowBlank: true
    }), (0, _emberCpValidations.validator)('ds-error')],
    forwardTo3: [(0, _emberCpValidations.validator)('length', { is: 12, allowBlank: true, message: "This field is invalid." }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true,
      allowBlank: true
    }), (0, _emberCpValidations.validator)('ds-error')],
    forwardTo4: [(0, _emberCpValidations.validator)('length', { is: 12, allowBlank: true, message: "This field is invalid." }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true,
      allowBlank: true
    }), (0, _emberCpValidations.validator)('ds-error')],
    extension: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true,
      allowBlank: true
    })],
    didAllocation: (0, _emberCpValidations.validator)('belongs-to')
  });
});