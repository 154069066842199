define('knowme-client/services/session', ['exports', 'ember-simple-auth/services/session', 'knowme-client/config/environment'], function (exports, _session, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let SessionService;

  SessionService = _session.default.extend({
    dataStore: Ember.inject.service('store'),
    // authenticatedWithGoogle: false,
    // authenticatedWithOutlook: false,
    isEmailServerRunning: _environment.default.APP.isEmailServerRunning,
    currentUser: Ember.computed('isAuthenticated', 'session.authenticated.id', 'impersonatedUser.id', function () {
      if (this.get('isAuthenticated')) {
        if (this.get('impersonatedUser')) {
          return this.get('impersonatedUser').reload();
        } else {
          if (this.get('session.authenticated.token')) {
            return this.get('dataStore').find('user', this.getTokenData(this.get('session.authenticated.token')).id);
          }
        }
      } else {
        return Ember.RSVP.reject(null);
      }
    }),
    account: Ember.computed.alias('currentUser.account'),
    authentications: Ember.computed.alias('currentUser.authentications'),
    authenticatedHeaders: Ember.computed('isAuthenticated', function () {
      return { Authorization: this.get('session.authenticated.token') };
    }),
    getTokenData(token) {
      const payload = token.split('.')[1];
      const tokenData = decodeURIComponent(window.escape(atob(payload)));

      try {
        return JSON.parse(tokenData);
      } catch (e) {
        return tokenData;
      }
    }
  });

  exports.default = SessionService;
});