define('knowme-client/routes/reports/index', ['exports', 'lodash', 'knowme-client/mixins/routes/account-setup', 'moment'], function (exports, _lodash, _accountSetup, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function formatDate(date) {
    return date.format('MM-DD-YYYY'); //FIXME: Rails expects DD-MM-YYYY instead of MM-DD-YY
  }

  let ReportsIndexRoute;

  ReportsIndexRoute = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      campaign_id: { refreshModel: true },
      adId: { refreshModel: true },
      reportTypeSelected: { refreshModel: true },
      from_date: { refreshModel: true },
      to_date: { refreshModel: true }
    },
    pagination: {},
    beforeModel: function (transition) {
      this._super(...arguments);

      return this.get('session.currentUser').then(function (user) {
        this.set('currentUser', user);
        return user.get('account');
      }.bind(this)).then(function (account) {
        return account.get('subscription');
      }.bind(this)).then(function (subscription) {
        return subscription.get('plan');
      }.bind(this)).then(function (plan) {
        return plan;
      }.bind(this));
    },
    model: function (params) {
      let promise,
          url,
          options = this.processParams(params);
      options.campaign_id = params.campaign_id;
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      switch (params.reportTypeSelected) {
        case 'leads_by_time_of_the_day':
          url = adapter.urlPrefix('reports/leads/by_time_of_day', adapter.buildURL());
          promise = adapter.ajax(url, 'GET', { data: options });
          break;
        case 'customers_by_time_of_the_day':
          url = adapter.urlPrefix('reports/customers/by_time_of_day', adapter.buildURL());
          promise = adapter.ajax(url, 'GET', { data: options });
          break;
        case 'leads_pipeline':
          options.filter = ['leads', 'pipeline_opportunity'];
          promise = this.get('store').query('lead', options);
          break;
        case 'customers_pipeline':
          options.filter = ['customers', 'customers_pipeline_opportunity'];
          promise = this.get('store').query('lead', options);
          break;
        case 'customers_and_leads_pipeline':
          options.filter = ['all', 'pipeline_opportunity'];
          promise = this.get('store').query('lead', options);
          break;
        case 'opportunity_pipeline':
          options.filter = 'non-closed';
          promise = this.get('store').query('opportunity', options);
          break;
        case 'customers_revenue':
          options.filter = ['customers', 'customers_revenue'];
          options.field = 'conversion_date';
          promise = this.get('store').query('lead', options);
          break;
        case 'unique_leads_per_campaign':
          options.paginate = false;
          options.sort_by = 'leads_count';
          options.filter = 'unique_leads_per_campaign';
          promise = this.get('store').query('campaign', options);
          break;
        case 'unique_leads_per_campaign_details':
          options.paginate = false;
          options.sort_by = 'leads_count';
          options.filter = 'unique_leads_per_campaign';
          promise = this.get('store').query('campaign', options);
          break;
        case 'outbound_calls_per_user':
          options.paginate = false;
          options.filter = ['with_outbound_calls'];
          options.fields = ['calls_count'];
          options.calls = {
            from_date: options.from_date,
            to_date: options.to_date
          };
          promise = this.get('store').query('user', options);
          break;
        case 'top_campaigns_by_customers':
          // sort by customers count
          options.sort_by = 'customers_count';
          options.paginate = false;
          promise = this.get('store').query('campaign', options);
          break;
        case 'campaign_roi_summary':
          options.paginate = false;
          promise = this.get('store').query('campaign', options);
          break;
      }

      if (promise) {
        promise.then(result => {
          if (result.meta && result.meta.pagination) {
            this.set('pagination.total', result.meta.pagination.total);
            this.set('pagination.limit', result.meta.pagination.limit);
            this.set('pagination.page', result.meta.pagination.page);
          }
        });
      }
      return promise;
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);
      return _lodash.default.mapValues(_lodash.default.omit(_lodash.default.pickBy(params, _lodash.default.identity), ['reportTypeSelected', 'campaign_id']), function (value, key) {
        if (key === 'from_date' || key === 'to_date') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    },
    afterModel: function (model, transition) {
      let promises = {};
      if (!['outbound_calls_per_user'].includes(transition.queryParams.type)) {
        promises.campaigns = this.get('store').query('campaign', { paginate: false, sort_by: 'name', order: 'asc' });
      }
      if (transition.queryParams === 'outbound_calls_per_user') {
        promises.calls = this.get('store').query('call', { paginate: false, sort_by: 'name', order: 'asc' });
      }
      return Ember.RSVP.hash(promises).then(function (response) {
        this.set('controllerData', response);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set('total', this.get('pagination.total'));
      controller.set('limit', this.get('pagination.limit'));
      controller.set('count', Math.ceil(this.get('pagination.total') / this.get('pagination.limit')));
      controller.set('current', this.get('pagination.page'));

      controller.setProperties(this.get('controllerData'));
      controller.set('modelLoaded', true);
      this.set('controllerData', {});
    },
    actions: {
      queryParamsDidChange: function () {
        this.refresh();
      },
      willTransition: function () /*transition*/{
        this.controller.set('modelLoaded', false);
      }
    }
  });

  exports.default = ReportsIndexRoute;
});