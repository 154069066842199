define('knowme-client/mirage/models/order', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    opportunity: (0, _emberCliMirage.belongsTo)(),
    product: (0, _emberCliMirage.belongsTo)(),
    attrs: ['id', 'product']
  });
});