define('knowme-client/models/contact-address', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/models/validations/contact-address', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _relationships, _emberCpValidationsCustomProperties, _contactAddress, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ContactAddress;

  ContactAddress = _model.default.extend(_emberCpValidationsCustomProperties.default, _dirty.default, {
    contactInfo: (0, _relationships.belongsTo)('contact-info', { async: true }),
    street: (0, _attr.default)(),
    street2: (0, _attr.default)(),
    city: (0, _attr.default)(),
    state: (0, _attr.default)(),
    zip: (0, _attr.default)(),
    country: (0, _attr.default)(),
    isNotFilled: Ember.computed('street', 'street2', 'city', 'state', 'zip', 'country', function () {
      return ['street', 'street2', 'city', 'state', 'zip', 'country'].every(function (field) {
        return Ember.isBlank(this.get(field));
      }.bind(this));
    })
  });

  exports.default = ContactAddress;
});