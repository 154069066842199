define('knowme-client/routes/leads/new', ['exports', 'knowme-client/mixins/routes/account-setup', 'ember-local-storage', 'lodash'], function (exports, _accountSetup, _emberLocalStorage, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsNewRoute;

  LeadsNewRoute = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    lastOpportunity: (0, _emberLocalStorage.storageFor)('last-opportunity'),
    isLeadsPage: true,
    renderTemplate: function (controller, model) {
      return this.render('leads/new', {
        into: 'application',
        model: model
      });
    },
    model: function (params) {
      let route = this;
      // build new lead
      var lead = route.store.createRecord('lead', { isConverted: params.converted === "1" });
      // build organization
      return this.get('session.currentUser').then(function (user) {
        return user.get('account');
      }).then(function (account) {
        if (account.get('isbtob')) {
          var organization = route.store.createRecord('organization', { lead: lead });
          // lead.set('organization', organization);
        }
        // build primary contact
        var contact = route.store.createRecord('contact', { primary: true, lead: lead });
        route.set('contact', contact);
        // contact.set('lead', lead);
        // build work contact info
        var contactInfo = route.store.createRecord('contactInfo', { category: 'work', contact: contact });
        // contactInfo.set('contact', contact);
        // build contact urls
        _lodash.default.each([null, 'facebook', 'twitter', 'linkedin', 'google_plus'], function (socialNetwork) {
          var contactUrl = route.store.createRecord('contactUrl', { socialNetwork: socialNetwork, contactInfo: contactInfo });
          // contactUrl.set('contactInfo', contactInfo);
        });
        return lead;
      });
    },
    afterModel: function (model, transition) {
      let currentUser, usersPromise;
      currentUser = this.get('session.currentUser');
      usersPromise = currentUser.get('hasAdminRole') ? this.get('store').query('user', { paginate: false, status: 'active' }) : [currentUser];
      return Ember.RSVP.hash({
        organizationFields: this.get('store').query('organization-field', { enable: true }),
        contactFields: this.get('store').query('contact-field', { enable: true }),
        products: this.get('store').query('product', { paginate: false, sort_by: 'name', order: 'asc', status: 'active' }),
        opportunityFields: this.get('store').query('opportunity-field', { enable: true }),
        salesActions: this.get('store').query('sales-action', { paginate: false, status: 'active' }),
        salesStages: this.get('store').query('sales-stage', { paginate: false, status: 'active' }),
        campaigns: this.get('store').query('campaign', { paginate: false, sort_by: 'name', order: 'asc' }),
        users: usersPromise
      }).then(function (results) {
        this.set('controllerData', results);
        // this.set('isLeadsPage', !transition.queryParams.converted);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
      if (controller.get('isOpportunityOppened')) {
        controller.prepareCustomAttributes();
      }
      // if(this.get('isLeadsPage')) {
      //   if(controller.get('lead.opportunities.length') === 0) {
      //     let store = this.get('store');
      //     let currentUser = this.get('session.currentUser');
      //     // build opportunity
      //     let opportunity = store.createRecord('opportunity', {
      //       lead: controller.get('lead'),
      //       assignedTo: currentUser,
      //       name: `Manual - ${moment().format('L HH:mm:ss')}`
      //     });

      //     // build opportunity order
      //     store.createRecord('order', {opportunity: opportunity});
      //     controller.get('campaigns').map(function(campaign) {
      //       campaign.get('ads').then(function(ads) {
      //         let ad = ads.findBy('name', "Manual");
      //         if(isPresent(ad)) {
      //           opportunity.set('ad', ad);
      //         }
      //       });
      //     });
      //     opportunity.set('salesStage', controller.get('salesStages').findBy('key', 'unqualified'));
      //   }
      // }
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        this.controller.set('isOpportunityOppened', false);
        if (model.get('isNew')) {
          if (Ember.isPresent(model.get('opportunities.firstObject'))) {
            model.get('opportunities.firstObject.orders').invoke('deleteRecord');
            model.get('opportunities.firstObject').deleteRecord();
          }
          model.get('contacts.firstObject.contactInfos').forEach(function (contactInfo) {
            contactInfo.get('contactEmails').invoke('deleteRecord');
            contactInfo.get('contactPhoneNumbers').invoke('deleteRecord');
            contactInfo.get('contactUrls').invoke('deleteRecord');
          });
          this.get('session.currentUser').then(function (user) {
            if (user.get('account.isbtob')) {
              model.get('organization').deleteRecord();
            }
          });
          model.deleteRecord();
        }
      }
    }
  });

  exports.default = LeadsNewRoute;
});