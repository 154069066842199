define('knowme-client/routes/promo-codes/new', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    store: Ember.inject.service(),
    model: function (queryParams) {
      return this.get('store').createRecord('promo-code', {});
    },
    afterModel: function (model) {
      return this.get('store').query('plan', { paginate: false, sort_by: 'name', order: 'asc' }).then(function (plans) {
        this.set('controllerData', { plans: plans });
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    }
  });
});