define('knowme-client/models/survey', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Survey;

  Survey = _model.default.extend({
    // Relationships
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
    contactEmail: (0, _relationships.belongsTo)('contact-email', { async: true, inverse: 'surveys' }),
    // Attributes
    status: (0, _attr.default)()
  });

  exports.default = Survey;
});