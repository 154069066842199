define('knowme-client/helpers/extract-score', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.extractScore = extractScore;
  function extractScore(params /*, hash*/) {
    let note = params[0];

    if (note.includes('Terrible')) {
      return 'Terrible';
    } else if (note.includes('Bad')) {
      return 'Bad';
    } else if (note.includes('Average')) {
      return 'Average';
    } else if (note.includes('Good')) {
      return 'Good';
    } else if (note.includes('Great')) {
      return 'Great';
    }
  }

  exports.default = Ember.Helper.helper(extractScore);
});