define('knowme-client/routes/campaigns/new', ['exports', 'moment', 'knowme-client/mixins/routes/account-setup'], function (exports, _moment, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let CampaignsNewRoute;

  CampaignsNewRoute = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    model: function () {
      // build new campaign
      return this.store.createRecord('campaign');
    },
    afterModel: function (model) {
      let captureMode = 'automated';
      if (!this.get('features.campaignsAutomatedCaptureAvailable')) {
        captureMode = 'manual';
      }
      let adapter = Ember.getOwner(this).lookup('adapter:application');

      let accountSummaryUrl = adapter.buildURL('accounts/summary');
      let accountSummaryRequest = adapter.ajax(accountSummaryUrl, 'GET');

      return Ember.RSVP.hash({
        accountSummary: accountSummaryRequest.then(function (accountSummary) {
          return accountSummary;
        }),
        ad: this.store.createRecord('ad', { campaign: model, captureMode: captureMode })
      }).then(function (results) {
        let serializer = this.get("store").serializerFor('json-api');
        serializer.camelizeKeys(results.accountSummary);
        this.set('controllerData', results);
        this.set('controllerData.captureMode', captureMode);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      //check if tfn or local numbers have enough values
      let unusedNumberOfTollFreeNumbers = controller.accountSummary.unusedNumberOfTollFreeNumbers;
      let unusedNumberOfLocalNumbers = controller.accountSummary.unusedNumberOfLocalNumbers;
      let message = '';
      let messages = {
        all: 'You have used all available Numbers (Toll Free and Local numbers) in your Service plan.To get a new Number, you can take a Number off an existing ad that is no longer being used OR an Admin can purchase additional Numbers under the Upgrade section in Client Information.',
        tfn: 'You have used all available Toll Free Numbers (TFNs) in your Service plan. To get a new TFN, you can take a TFN off an existing ad that is no longer being used OR an Admin can purchase additional TFNs under the Upgrade section in Client Information.',
        local: 'You have used all available Local numbers. To get a new Local number, you can take a Local number off an existing ad that is no longer being used OR an Admin can purchase additional Local numbers under the Upgrade section in Client Information.'
      };
      if (unusedNumberOfTollFreeNumbers === 0) {
        controller.set('disableTollFreeDropdown', true);
        message = messages.tfn;
      }
      if (unusedNumberOfLocalNumbers === 0) {
        controller.set('disableLocalDropdown', true);
        message = messages.local;
      }
      if (unusedNumberOfTollFreeNumbers === 0 && unusedNumberOfLocalNumbers === 0) {
        message = messages.all;
      }
      controller.set('message', message);

      // prefill web chat form
      if (localStorage['prefillForm']) {
        let campaign = controller.get('model');
        campaign.setProperties({
          name: 'Web Chat'
        });
        let ad = campaign.get('ads.firstObject');
        ad.setProperties({
          name: 'Web Chat',
          touchPoint: 'chat',
          channel: 'sem',
          startDate: (0, _moment.default)().toDate(),
          endDate: (0, _moment.default)().endOf('month').toDate()
        });
        localStorage.removeItem('prefillForm');
      }
      this.set('controllerData', {});
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this.controller.setProperties({ message: null, accountSummary: null, ad: null, disableTollFreeDropdown: null, disableLocalDropdown: null });
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        if (model.get('isNew')) {
          model.deleteRecord();
        }
        this.get('flashMessages').clearMessages();
        this.set('disableTollFreeDropdown', null);
        this.set('disableLocalDropdown', null);
      }
    }
  });

  exports.default = CampaignsNewRoute;
});