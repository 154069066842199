define('knowme-client/serializers/chat-message', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      owner: { deserialize: 'id', serialize: false },
      attachments: { embedded: 'always' }
    }
  });
});