define('knowme-client/helpers/is-infinity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    let n = params[0];
    return n === Infinity;
  });
});