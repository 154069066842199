define('knowme-client/helpers/any-plan-includes-feature', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.anyPlanIncludesFeature = anyPlanIncludesFeature;
  function anyPlanIncludesFeature(params /*, hash*/) {
    let product = params[0];
    let featureKey = params[1];

    for (var plan in product.settings) {
      if (Ember.isPresent(product.settings[plan])) {
        return Ember.get(product.settings[plan], featureKey);
      }
    }
    return false;
  }

  exports.default = Ember.Helper.helper(anyPlanIncludesFeature);
});