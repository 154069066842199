define('knowme-client/components/knowme-editor', ['exports', 'ember-uploader/uploaders', 'lodash'], function (exports, _uploaders, _lodash) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		session: Ember.inject.service(),
		store: Ember.inject.service(),
		ajax: Ember.inject.service('authorized-ajax'),
		classNames: ['knowme-editor'],
		readOnly: false,
		largeFileWarning: false,
		attachmentsInProgress: [],
		defaultConfig: {
			toolbar: {
				items: ['fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'link', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', '|', 'heading'],
				position: 'bottom'
			},
			language: 'en',
			image: {
				toolbar: ['imageTextAlternative',
				// 'imageStyle:full',
				// 'imageStyle:side',
				'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
				styles: ['alignLeft', 'alignCenter', 'alignRight']
			},
			table: {
				contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
			},
			licenseKey: '',
			simpleUpload: {},
			uploadAttach: {
				onClick: function () {
					// open file browser
					Ember.$('#add-attachment').click();
				}
			}
		},
		editor: null,
		// inlineAttachemnts: [],
		init() {
			this._super(...arguments);

			this.set('value', this.get('model').get(this.get('fieldName')) || '');
			// this.get('model').set(this.get('fieldName'), editor.getData());

			if (Ember.testing) {
				/*
     * If running in testing mode, we need to ensure we can easily
     * wait for the editor to initialize, since it's asynchronous.
     * Initially, tests will pause as soon as they encounter this waiter.
     * They'll periodically retry the waiter until it returns a truthy
     * value, and then the tests will resume.
     */
				Ember.Test.registerWaiter(() => {
					return this.get('isReady');
				});
			}
		},

		isReady: Ember.computed('editor', function () {
			return !!this.get('editor');
		}),

		didInsertElement() {
			this._super(...arguments);
			this._initializeEditor();
		},

		// didReceiveAttrs(){
		// },
		ckeditorConfig: Ember.computed('config', function () {
			let headers = this.get('ajax.headers');
			let url = Ember.getOwner(this).lookup('adapter:attachment').buildURL('attachments');

			let query = '';
			if (!this.get('model.isNew')) {
				let recordType = '';
				if (this.get('model').content) {
					recordType = this.get('model').content.constructor.modelName.camelize().capitalize();
				} else {
					recordType = this.get('model').constructor.modelName.camelize().capitalize();
				}
				query = `?record_id=${this.get('model.id')}&record_type=${recordType}`;
			}

			this.set('defaultConfig.simpleUpload.uploadUrl', url + query);
			this.set('defaultConfig.simpleUpload.headers', headers);

			if (Ember.isPresent(this.get('config'))) {
				return _lodash.default.defaultsDeep(this.get('config'), this.get('defaultConfig'));
			} else {
				return this.get('defaultConfig');
			}
		}),
		value: Ember.computed('_value', {
			set(_, newVal) {
				this.set('_value', newVal);
				this._updateEditorData(this.get('editor'));
			},
			get() {
				return this.get('editor').getData();
			}
		}),
		onReadOnlyChanged: Ember.observer('readOnly', function () {
			if (this.get('editor')) {
				this.get('editor').isReadOnly = this.get('readOnly');
			}
		}),
		onUidChanged: Ember.observer('uid', function () {
			if (this.get('editor')) {
				this.set('value', this.get('model').get(this.get('fieldName')) || '');
			}
		}),

		/**
   * The entry point for the BalloonEditor initialization
   * @private
   */
		_initializeEditor() {
			KnowmeEditor.create(this.element.querySelector('.editor'), this.get('ckeditorConfig')).then(editor => {
				this._setupEditor(editor);
			}).catch(error => {
				// eslint-disable-next-line
				console.error(error);
			});
		},

		/**
   * The post-setup of the editor, once the BalloonEditor.create promise resolves
   * @private
   */
		_setupEditor(editor) {
			if (!this.isDestroyed && !this.isDestroying) {
				let self = this;

				editor.conversion.elementToElement({ model: 'paragraph', view: 'div', converterPriority: 'high' }); // use div instead of p
				editor.model.schema.extend('image', {
					allowAttributes: ['data-attachment-id']
				});

				this._updateEditorData(editor); // initialize editor content
				this.set('editor', editor); // save a reference to the editor (results in tests continuing)

				editor.conversion.for('downcast').add(dispatcher => {
					// dispatcher.on( 'attribute:width:image', ( evt, data, conversionApi ) => {
					// });
					// dispatcher.on( 'attribute:uploadStatus:image', ( evt, data, conversionApi ) => {
					// });
					// dispatcher.on( 'insert:image', ( evt, data, conversionApi ) => {
					// 	if (data.item.getAttribute('uploadStatus') && data.item.getAttribute('uploadStatus') === 'complete' ) {
					// 	}
					// });
					dispatcher.on('attribute:src:image', (evt, data, conversionApi) => {
						const viewWriter = conversionApi.writer;

						const figure = conversionApi.mapper.toViewElement(data.item);
						const img = figure.getChild(0);

						if (data.attributeNewValue !== null) {
							const src = data.attributeNewValue;
							const url = new URL(src, window.location.origin);
							let parts = url.pathname.split('/');
							let attachmentId = null;
							parts.forEach(function (part) {
								if (/^\d+$/.test(part)) {
									attachmentId = part;
									return;
								}
							});

							if (url) {
								viewWriter.setAttribute('data-attachment-id', attachmentId, img);
							}
						} else {
							viewWriter.removeAttribute('data-attachment-id', img);
						}
					});
				});
				// Hook up an event listener to handle content changes

				editor.model.document.on('change', () => {
					// let figuresBefore = $('<div></div>').html(self.get('model.body')).find('figure');
					// let figuresAfter = $('<div></div>').html(editor.getData()).find('figure');
					// if (figuresBefore.length !== figuresAfter.length) {
					// 	console.log('added or removed image');
					// }
					/**
      * For some reason, this event fires several times as a result of initialization.
      * For performance and state management reasons, we want exactly one event to fire, so
      * debounce by 100ms to smooth out the rapid-fire.
      */
					Ember.run.debounce(this, '_onEditorDataChange', ...[editor, ...arguments], 100);
				});
			}
		},

		/**
   * Called whenever the editor's "change" event is fired
   * @private
   */
		_onEditorDataChange(editor) {
			this.get('model').set(this.get('fieldName'), editor.getData());
			// if an "onChange" function is passed in, invoke it
			// if (this.onChange && typeof this.onChange === 'function') {
			// 	this.onChange(editor.getData());
			// }
		},

		/**
   * Called whenever we need to update the state of the editor.
   * This happens once when the component is initialized
   */
		_updateEditorData(editor) {
			if (!editor) editor = this.get('editor');
			if (!editor) {
				Ember.run.later(this, '_updateEditorData', 100);
			} else {
				editor.setData(this.get('_value'));
			}
		},

		willDestroyElement() {
			this._super(...arguments);
			// attempt to clean up the editor
			let editor = this.get('editor');
			if (editor) {
				editor.destroy();
			}
		},

		actions: {
			// 	// An action for consumers to use in their {{yield}} content
			// 	_onSave() {
			// 		this.set('value', this.get('editor').getData());
			// 	}
			addAttachment: function (event) {
				let file = event.target.files[0];
				if (file.size >= 25 * 1024 * 1024) {
					this.set('largeFileWarning', true);
					Ember.$('#add-attachment').val('');
					return;
				}
				let attachment = Ember.Object.create({ name: file.name, fileSize: file.size, progress: 0 });
				this.get('attachmentsInProgress').pushObject(attachment);
				let url = Ember.getOwner(this).lookup('adapter:attachment').buildURL('attachment');
				let params = {};
				if (!this.get('model.isNew')) {
					let recordType = null;
					if (this.get('model').content) {
						recordType = this.get('model').content.constructor.modelName.camelize().capitalize();
					} else {
						recordType = this.get('model').constructor.modelName.camelize().capitalize();
					}
					params = { record_id: this.get('model.id'), record_type: recordType };
				}
				const uploader = _uploaders.Uploader.create({
					url: url,
					paramName: 'file'
				});
				uploader.on('progress', e => {
					attachment.set('progress', Math.round(e.percent));
				});
				let ajaxSettings = { headers: this.get('ajax.headers') };
				uploader.set('ajaxSettings', ajaxSettings);
				uploader.upload(file, params).then(res => {
					if (this.get('model.isNew')) {
						this.get('store').pushPayload('attachment', res);
						let attachment = this.get('store').peekRecord('attachment', res.data.id);
						if (Ember.isBlank(this.get("model").get('attachments'))) {
							this.get("model").set('attachments', []);
						}
						this.get("model").get('attachments').pushObject(attachment);
					} else {
						this.get("model").hasMany("attachments").reload();
					}
					this.get('attachmentsInProgress').removeObject(attachment);
				}, error => {
					// Handle failure
					this.get('flashMessages').danger(error.responseJSON.error, { destroyOnClick: true });
				});
			},
			removeAttachment: function (attachment) {
				attachment.destroyRecord().then(function () {
					if (this.get('model.isNew')) {
						this.get('model.attachments').removeObject(attachment);
					} else {
						this.get("model").hasMany("attachments").reload();
					}
				}.bind(this));
			}
		}
	});
});