define('knowme-client/helpers/eq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const eq = params => params[0] === params[1];
  exports.default = Ember.Helper.helper(eq);
});