define('knowme-client/serializers/plan', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    normalize(typeClass, hash /*, prop*/) {
      this.camelizeKeys(hash.attributes.settings);
      return this._super(...arguments);
    }
  });
});