define('knowme-client/controllers/profile', ['exports', 'lodash', 'knowme-client/mixins/how-to-items'], function (exports, _lodash, _howToItems) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_howToItems.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    ajax: Ember.inject.service('authorized-ajax'),
    isUploading: false,
    isAuthLoaded: false,
    email: Ember.inject.service('email-connect'),
    toggleHowTo: 'hide',
    toggleRolePermissions: 'hide',
    isEmailsIntegrationOpened: false,
    isDashboardSettingsOpened: false,
    isLeadColumnsOpened: false,
    initRoleSections(roles) {
      let controller = this;
      let computedDependentProperties = [];
      roles.forEach(function (role) {
        controller.set(`is${role.camelize().capitalize()}Opened`, controller.get('session.currentUser.roles.firstObject.key') === role);
        computedDependentProperties.push(`is${role.camelize().capitalize()}Opened`);
      });
    },
    visibleLeadsColumns: Ember.computed.filter('leadsColumnSettings', function (resource) {
      return resource.get('enable');
    }),
    leadsPositionOptions: Ember.computed('visibleLeadsColumns', 'visibleLeadsColumns.length', function () {
      return Array.from({ length: this.get('visibleLeadsColumns.length') }, (v, k) => k + 1);
    }),
    visibleCustomersColumns: Ember.computed.filter('customersColumnSettings', function (resource) {
      return resource.get('enable');
    }),
    customersPositionOptions: Ember.computed('visibleCustomersColumns', 'visibleCustomersColumns.length', function () {
      return Array.from({ length: this.get('visibleCustomersColumns.length') }, (v, k) => k + 1);
    }),
    addedWidgets: Ember.computed('model', 'dashboardWidgets', 'model.usersDashboardWidgets.length', 'model.usersDashboardWidgets.@each.dashboardWidget', 'model.usersDashboardWidgets.@each.isDeleted', function () {
      return this.get('model.nonDeletedUsersDashboardWidgets').mapBy('dashboardWidget');
    }),
    availableWidgets: Ember.computed.filter('dashboardWidgets', function (widget) {
      return !this.get('addedWidgets').mapBy('id').includes(widget.get('id'));
    }).property('dashboardWidgets', 'addedWidgets'),
    sorting: ['position:asc'],
    sortedUsersWidgets: Ember.computed.sort('usersDashboardWidgets', 'sorting'),
    sortedWidgets: Ember.computed('sortedUsersWidgets', function () {
      return this.get('sortedUsersWidgets').mapBy('dashboardWidgets');
    }),
    googleAuthentication: Ember.computed('session.currentUser.authentications.length', function () {
      return this.get('session.currentUser.authentications').findBy('provider', 'google');
    }),
    outlookAuthentication: Ember.computed('session.currentUser.authentications.length', function () {
      return this.get('session.currentUser.authentications').findBy('provider', 'outlook');
    }),
    authenticatedWithGoogle: Ember.computed('googleAuthentication', function () {
      return Ember.isPresent(this.get('googleAuthentication'));
    }),
    authenticatedWithOutlook: Ember.computed('outlookAuthentication', function () {
      return Ember.isPresent(this.get('outlookAuthentication'));
    }),
    visibleSignatureSection: Ember.computed.or('authenticatedWithGoogle', 'authenticatedWithOutlook'),
    uncollapseEmailIntergationSection: false,
    uncollapseGoogleSection: Ember.computed('authenticatedWithGoogle', 'authenticatedWithOutlook', function () {
      return this.get('authenticatedWithGoogle') || !this.get('authenticatedWithGoogle') && !this.get('authenticatedWithOutlook');
    }),
    uncollapseOutlookSection: Ember.computed('authenticatedWithOutlook', 'authenticatedWithGoogle', function () {
      return this.get('authenticatedWithOutlook') || !this.get('authenticatedWithGoogle') && !this.get('authenticatedWithOutlook');
    }),
    actions: {
      saveField: function (resource) {
        if (!resource.get('enable')) {
          if (resource.get('key') === 'priority') {
            let salesStagePosition = this.get(`visible${resource.get('targetName').capitalize()}Columns`).findBy('key', 'sales_stage').get('position');
            resource.set('position', salesStagePosition + 1);
          }
          if (resource.get('key') === 'due_date') {
            let actionPosition = this.get(`visible${resource.get('targetName').capitalize()}Columns`).findBy('key', 'actions').get('position');
            resource.set('position', actionPosition);
          }
        }

        resource.set('enable', !resource.get('enable'));
        resource.save().then(function (res) {
          this.send('reloadColumnSettings', res.get('targetName'));
        }.bind(this));
      },
      reloadColumnSettings: function (targetName) {
        this.get('store').query('column-setting', { target_name: targetName }).then(function (columnSettings) {
          this.set(`${targetName}ColumnSettings`, columnSettings);
        }.bind(this));
      },
      upload: function (event) {
        var downloadingImage = new Image();
        let controller = this;
        const files = event.target.files;
        if (files) {
          let url = Ember.getOwner(this).lookup('adapter:user').buildURL('users', this.get('model.id'), null, null, {});
          const uploader = Uploader.create({ url: url, method: 'PUT' });
          controller.set('isUploading', true);
          let ajaxSettings = { headers: this.get('ajax.headers') };
          uploader.set('ajaxSettings', ajaxSettings);
          uploader.upload(files[0]).then(resp => {
            controller.get("store").pushPayload('user', resp);
            downloadingImage.src = this.get('model.avatarThumbnailUrl');
            downloadingImage.onload = function () {
              controller.set('isUploading', false);
            };
          }, error => {
            controller.set('isUploading', false);
            controller.get('flashMessages').danger(error.responseJSON.error, { destroyOnClick: true });
          });
        }
      },
      toggleHowTo: function () {
        let klass = this.get('toggleHowTo') === 'hide' ? 'show' : 'hide';
        this.set('howToItem', 'my_set_up');
        this.set('toggleHowTo', klass);
      },
      toggleHowToItem: function (howToItem, howToSubItem) {
        if (Ember.isBlank(howToSubItem)) {
          if (Ember.isPresent(this.get('howToItem')) && this.get('howToItem') === howToItem) {
            this.set('howToItem', null);
          } else {
            this.set('howToItem', howToItem);
          }
        } else {
          if (Ember.isPresent(this.get('howToSubItem')) && this.get('howToSubItem') === howToSubItem) {
            this.set('howToSubItem', null);
          } else {
            this.set('howToSubItem', howToSubItem);
          }
        }
      },
      disableAuthentication: function (provider) {
        return this.get('email').disableAuthentication(provider).then((data => {
          this.get('session.currentUser').get('authentications').reload();
          this.set('signature', null);
        }).bind(this));
      },
      updateUsersDashboardList: function () {
        this.set('usersDashboardWidgets', this.get('model.usersDashboardWidgets').toArray());
      },
      dragEndUsersWidget({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);

        if (sourceList.includes(sourceItem) && targetList.includes(sourceItem)) {
          // re-order users widgets
          let position = sourceItem.get('position');
          sourceItem.set('position', targetItem.get('position'));
          targetItem.set('position', position);
          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, sourceItem);
        } else {
          // remove users widget
          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, sourceItem.get('dashboardWidget'));
          this.get('usersDashboardWidgets').removeObject(sourceItem);
          sourceItem.deleteRecord();
          sourceList.forEach(function (udw, index) {
            udw.set('position', index + 1);
          });
        }
      },
      dragEndAvailableWidget({ sourceList, sourceIndex, targetList, targetIndex }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;

        const sourceItem = sourceList.objectAt(sourceIndex);
        const targetItem = targetList.objectAt(targetIndex);
        if (sourceList.includes(sourceItem) && targetList.includes(sourceItem)) {
          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, sourceItem);
        } else {
          // add users widget
          let currentUser = this.get('session.currentUser');

          // find if deleted
          let deletedItems = this.get('store').peekAll('users-dashboard-widget').filterBy('isDeleted', true);
          let udw = _lodash.default.find(deletedItems, function (udw) {
            return udw.get('dashboardWidget.id') == sourceItem.get('id');
          });
          // restore or create new users widget
          if (udw) {
            udw.rollbackAttributes();
          } else {
            udw = this.get('store').createRecord('users-dashboard-widget', { dashboardWidget: sourceItem, user: currentUser });
          }

          // update users widget position
          if (Ember.isPresent(targetItem)) {
            let targetPosition = targetItem.get('position');
            udw.set('position', targetPosition);
            this.get('usersDashboardWidgets').forEach(function (item, index) {
              let position = item.get('position');
              if (position >= targetPosition && item != udw) {
                item.set('position', position + 1);
              }
            });
          } else {
            udw.set('position', targetList.length + 1);
          }

          sourceList.removeAt(sourceIndex);
          targetList.insertAt(targetIndex, udw);
          this.get('usersDashboardWidgets').pushObject(udw);
        }
      }
    }
  });
});