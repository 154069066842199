define('knowme-client/helpers/split', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.split = split;
  function split(params /*, hash*/) {
    return (params[0] || '').split(params[1]);
  }

  exports.default = Ember.Helper.helper(split);
});