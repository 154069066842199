define('knowme-client/components/dashboard/sales-stages-widget', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		features: Ember.inject.service(),
		numberOfMainItems: Ember.computed('features.activityUserAvailable', function () {
			return this.get('features.activityUserAvailable') ? 10 : 9;
		}),
		visibleItems: Ember.computed('model.salesStagesSummary', 'numberOfMainItems', function () {
			return this.get('model.salesStagesSummary').slice(0, this.get('numberOfMainItems'));
		}),
		hiddenItems: Ember.computed('model.salesStagesSummary', 'numberOfMainItems', function () {
			return this.get('model.salesStagesSummary').slice(this.get('numberOfMainItems'));
		}),
		emptyMainRowsNumber: Ember.computed('visibleItems', 'numberOfMainItems', function () {
			if (this.get('visibleItems.length') < this.get('numberOfMainItems')) {
				return Array.from({ length: this.get('numberOfMainItems') - this.get('visibleItems.length') }, (v, k) => k);
			} else {
				return [];
			}
		}),
		emptyRowsNumber: Ember.computed('hiddenItems', function () {
			if (this.get('hiddenItems.length') < 7) {
				return Array.from({ length: 7 - this.get('hiddenItems.length') }, (v, k) => k);
			} else {
				return [];
			}
		})
	});
});