define('knowme-client/helpers/opted-short-messages-to-ctp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute([contactPhoneNumber, opportunity]) {
      return this.get('store').query('short-message', {
        filter: 'inbound,to_call_touch_point,opted_messages',
        contact_phone_number_id: contactPhoneNumber.get('id'),
        ad_id: opportunity.get('ad.id')
      });
    }
  });
});