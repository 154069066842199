define('knowme-client/mixins/models/validations/review-link', ['exports', 'ember-cp-validations', 'moment'], function (exports, _emberCpValidations, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
		url: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('ds-error')]
	});
});