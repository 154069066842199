define('knowme-client/mixins/models/validations/ad', ['exports', 'moment', 'ember-cp-validations'], function (exports, _moment, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		name: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
		channel: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			ignoreBlank: true,
			dependentKeys: ['model.features.callTouchPointsAvailable', 'model.captureMode'],
			disabled: Ember.computed('model.features.callTouchPointsAvailable', 'model.captureMode', function () {
				return !this.get('model.features.callTouchPointsAvailable') || this.get('model.captureMode') === 'manual';
			})
		}),
		touchPoint: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			ignoreBlank: true,
			dependentKeys: ['model.features.callTouchPointsAvailable', 'model.captureMode'],
			disabled: Ember.computed('model.features.callTouchPointsAvailable', 'model.captureMode', function () {
				return !this.get('model.features.callTouchPointsAvailable') || this.get('model.captureMode') === 'manual';
			})
		}),
		endDate: [(0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			let result = 'cannot be lower than start date';
			let startDate = model.get('startDate');
			if (Ember.isBlank(startDate) || Ember.isPresent(startDate) && (0, _moment.default)(value).isSameOrAfter((0, _moment.default)(startDate))) {
				result = true;
			}
			return result;
		}, {
			dependentKeys: ['startDate']
		})],
		callTouchPoints: (0, _emberCpValidations.validator)('has-many'),
		chatTouchPoints: (0, _emberCpValidations.validator)('has-many'),
		formTouchPoints: (0, _emberCpValidations.validator)('has-many'),
		emailTouchPoints: (0, _emberCpValidations.validator)('has-many')
	});
});