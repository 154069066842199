define('knowme-client/serializers/user-json', ['exports', 'knowme-client/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    attrs: {
      roles: { serialize: 'ids' },
      usersDashboardWidgets: { serialize: 'records' },
      didAllocation: { serialize: 'records' },
      timeZone: { serialize: false },
      timeZoneMapping: { serialize: false },
      password: { deserialize: false },
      lastCall: { deserialize: 'id', serialize: false }
    },
    keyForRelationship(key, relationship, method) {
      if (key === 'didAllocation' && method === 'serialize') {
        return `${Ember.String.underscore(key)}_attributes`;
      }
      if (key === 'roles' && method === 'serialize') {
        return `${Ember.String.underscore(key)}_ids`;
      }
      // if(['lastCall', 'didAllocation'].includes(key) && (method === 'deserialize')) {
      //   return `${underscore(key)}_id`;
      // }
      return this._super(...arguments);
    },
    serialize: function (snapshot /* options*/) {
      var json = this._super(...arguments);
      if (Ember.isBlank(json.password)) {
        delete json.password;
      }
      if (Ember.isBlank(snapshot.id)) {
        json.users_dashboard_widgets_attributes = [];
      }
      return json;
    },
    _generateSerializedHasMany(snapshot, relationship) {
      var hasMany = [];
      Ember.get(snapshot._internalModel._relationships.get(relationship.key), 'members').forEach(function (member) {
        let memberSnapshot = member.createSnapshot();
        hasMany.push(memberSnapshot);
      });
      var manyArray = Ember.A(hasMany);
      var ret = new Array(manyArray.length);

      for (var i = 0; i < manyArray.length; i++) {
        var embeddedSnapshot = manyArray[i];
        var embeddedJson = embeddedSnapshot.serialize({ includeId: true });
        this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
        ret[i] = embeddedJson;
      }

      return ret;
    }
  });
});