define('knowme-client/components/calls/call-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'tr',
    actions: {
      startListening: function () {
        this.startListening();
      },
      stopListening: function () {
        this.stopListening();
      },
      setSatisfaction: function (call, satisfaction) {
        this.setSatisfaction(call, satisfaction);
      },
      confirmRemoveCall: function (call) {
        this.confirmRemoveCall(call);
      },
      callListened: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return;
        }
        let store = this.get('store');
        let currentUser = this.get('session.currentUser');
        if (!this.get('call.listened')) {
          this.set('call.listened', true);
          store.find('call', this.get('call.id')).then(function (call) {
            call.set('listened', true);
            return call.save();
          }.bind(this));
        }
        let call = this.get('call');
        let callListener = store.createRecord('callListener', { call: call, user: currentUser });
        return callListener.save().then(function () {
          call.get('listeners').reload();
        });
      },
      tagWith: function (call, tag) {
        call.set('tagList', [tag]);
      },
      removeTags: function (call) {
        call.set('tagList', []);
      },
      save: function (call) {
        this.save(call);
      }
    }
  });
});