define('knowme-client/models/lead-activity', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    key: (0, _attr.default)(),
    lead: (0, _relationships.belongsTo)('lead', { async: true }),
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true, inverse: 'activities' }),
    communication: (0, _relationships.belongsTo)('communication', { async: true }),
    // previousCommunicationActivity: belongsTo('lead-activity', { async: true }),
    owner: (0, _relationships.belongsTo)('user', { async: true }),
    body: (0, _attr.default)('json'),
    opportunityChargeMethod: Ember.computed('body', function () {
      if (Ember.isPresent(this.get('body.opportunity.chargeType'))) {
        return this.get('body.opportunity.chargeType');
      } else {
        return this.get('body.opportunity.salesStage.key') === 'closed' ? 'invoice' : 'estimate';
      }
    }),
    createdAt: (0, _attr.default)('date'),
    isACommunication: Ember.computed('key', function () {
      return this.get('key') === 'opportunity.communication';
    }),
    isACall: Ember.computed('body.communication.resourceType', function () {
      return this.get('body.communication.resourceType') === 'Call';
    }),
    isAShortMessage: Ember.computed('body.communication.resourceType', function () {
      return this.get('body.communication.resourceType') === 'ShortMessage';
    }),
    isAEmail: Ember.computed('body.communication.resourceType', function () {
      return this.get('body.communication.resourceType') === 'Email';
    }),
    call: Ember.computed('isACall', function () {
      if (this.get('isACall')) {
        return this.get('store').findRecord('call', this.get('body.communication.resourceId'));
      } else {
        return null;
      }
    }),
    shortMessage: Ember.computed('body.communication.resourceId', 'isAShortMessage', function () {
      if (this.get('isAShortMessage')) {
        return this.get('store').findRecord('short-message', this.get('body.communication.resourceId'));
      } else {
        return null;
      }
    }),
    email: Ember.computed('body.communication.resourceId', 'isAEmail', function () {
      if (this.get('isAEmail') && this.get('body.communication.resourceId')) {
        return this.get('store').findRecord('message', this.get('body.communication.resourceId'));
      } else {
        return null;
      }
    }),
    incentivesContact: Ember.computed('body.incentivesContact', function () {
      if (Ember.isPresent(this.get('body.incentivesContact'))) {
        return this.get('store').findRecord('incentives-contact', this.get('body.incentivesContact.id'));
      } else {
        return null;
      }
    }),
    reviewLinksContact: Ember.computed('body.reviewLinksContact', function () {
      if (Ember.isPresent(this.get('body.reviewLinksContact'))) {
        return this.get('store').findRecord('review-links-contact', this.get('body.reviewLinksContact.id'));
      } else {
        return null;
      }
    }),
    reviewLink: Ember.computed('body.reviewLinksContact', function () {
      if (Ember.isPresent(this.get('body.reviewLinksContact'))) {
        return this.get('store').findRecord('review-link', this.get('body.reviewLinksContact.reviewLinkId'));
      } else {
        return null;
      }
    }),
    selected: true
  });
});