define('knowme-client/mirage/models/lead', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    contacts: (0, _emberCliMirage.hasMany)(),
    opportunities: (0, _emberCliMirage.hasMany)(),
    organization: (0, _emberCliMirage.belongsTo)(),
    attrs: ['id', 'name', 'contacts', 'opportunities', 'organization']
  });
});