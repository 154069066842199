define('knowme-client/mirage/models/customer-experience', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		opportunity: (0, _emberCliMirage.belongsTo)(),
		contact: (0, _emberCliMirage.belongsTo)()
		// customerExperienceResource: belongsTo()
	});
});