define('knowme-client/adapters/campaign-event', ['exports', 'knowme-client/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    createRecordUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?campaign_id}',
    findHasManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?paginate}',
    pathForType: function () /*type*/{
      return 'events';
    },
    urlSegments: {
      campaign_id: function (type, id, snapshot /*, query*/) {
        return snapshot.belongsTo('campaign', { id: true });
      },
      paginate: function (type, id, snapshot, query) {
        if (query && Ember.isNone(query.paginate)) {
          return true;
        }
      }
    }
  });
});