define('knowme-client/controllers/account/settings/reviews', ['exports', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/queryable', 'knowme-client/mixins/controllers/status'], function (exports, _pagination, _queryable, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, _queryable.default, _status.default, {
    queryParams: ['page', 'status', 'query'],
    defaultsQueryParams: {
      status: 'active'
    },
    currentReview: null,
    confirmRemoveDefaultLink: false,
    defaultLinkToBeRemoved: null,
    actions: {
      saveDefaultField: function (reviewLink) {
        let numberOfDefaultReviewLinks = this.get('model').filterBy('default', true).get('length');
        if (reviewLink.get('default') && numberOfDefaultReviewLinks == 1) {
          // display confirmation popup
          this.send('removeLastDefaultLink', reviewLink);
        } else {
          // set new default review link
          reviewLink.set('default', !reviewLink.get('default'));
          reviewLink.save();
        }
      },
      removeLastDefaultLink: function (lastDefaultReviewLink) {
        if (this.get('confirmRemoveDefaultLink')) {
          lastDefaultReviewLink.set('default', false);
          lastDefaultReviewLink.save().then(function () {
            // close confirmation popup
            this.set('defaultLinkToBeRemoved', null);
            this.set('confirmRemoveDefaultLink', false);
          }.bind(this));
        } else {
          this.set('confirmRemoveDefaultLink', true);
          this.set('defaultLinkToBeRemoved', lastDefaultReviewLink);
        }
      },
      hideConfirmRemoveModal: function () {
        this.set('defaultLinkToBeRemoved', null);
      }
    }
  });
});