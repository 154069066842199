define('knowme-client/services/active-storage', ['exports', 'ember-active-storage/services/active-storage'], function (exports, _activeStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeStorage.default.extend({
    session: Ember.inject.service(),
    headers: Ember.computed.alias('session.authenticatedHeaders')
  });
});