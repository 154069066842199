define('knowme-client/models/account', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr', 'knowme-client/mixins/models/validations/account', 'knowme-client/mixins/models/account-settings-definitions', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _relationships, _attr, _account, _accountSettingsDefinitions, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_account.default, _accountSettingsDefinitions.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    parentAccount: (0, _relationships.belongsTo)('account', { async: true, inverse: 'accounts' }),
    accounts: (0, _relationships.hasMany)('account', { async: true, inverse: 'parentAccount' }),
    creditCard: (0, _relationships.belongsTo)('credit-card', { async: false }),
    dashboardPermissions: (0, _relationships.hasMany)('dashboard-permissions', { async: true, inverse: 'account' }),
    opportunityFields: (0, _relationships.hasMany)('opportunity-field', { async: true, inverse: 'account' }),
    organizationFields: (0, _relationships.hasMany)('organization-field', { async: true, inverse: 'account' }),
    contactFields: (0, _relationships.hasMany)('contact-field', { async: true, inverse: 'account' }),
    administrators: (0, _relationships.hasMany)('user', { async: true }),
    adminUsers: (0, _relationships.hasMany)('user', { async: true }),
    clientAdministrators: (0, _relationships.hasMany)('user', { async: true }),
    users: (0, _relationships.hasMany)('user', { async: true, inverse: 'account' }),
    contractAgreements: (0, _relationships.hasMany)('contract-agreement', { async: true }),
    subscription: (0, _relationships.belongsTo)('subscription', { async: true, inverse: 'account' }),
    settings: (0, _relationships.hasMany)('account-setting', { async: true, inverse: 'account' }),
    defaultManualAd: (0, _relationships.belongsTo)('ad', { async: true }),
    defaultCallerIdsAd: (0, _relationships.belongsTo)('ad', { async: true }),
    customOpportunityFields: (0, _attr.default)(),
    customerType: (0, _attr.default)(),
    idNumber: (0, _attr.default)(),
    name: (0, _attr.default)(),
    billingEmail: (0, _attr.default)(),
    ccBillingEmail: (0, _attr.default)(),
    billingPhone: (0, _attr.default)(),
    displayBillingPhone: (0, _attr.default)('boolean'),
    contactFirstName: (0, _attr.default)(),
    contactLastName: (0, _attr.default)(),
    contactTitle: (0, _attr.default)(),
    contactEmail: (0, _attr.default)(),
    websiteUrl: (0, _attr.default)(),
    phoneNumber: (0, _attr.default)(),
    phoneNumberExtension: (0, _attr.default)(),
    faxNumber: (0, _attr.default)(),
    addressMainStreet: (0, _attr.default)(),
    addressSecondaryStreet: (0, _attr.default)(),
    addressCity: (0, _attr.default)(),
    addressState: (0, _attr.default)(),
    addressZip: (0, _attr.default)(),
    addressCountry: (0, _attr.default)('string'),
    timeZone: (0, _attr.default)('string'),
    timeZoneMapping: (0, _attr.default)('string'),
    industry: (0, _attr.default)(),
    activeSettings: (0, _attr.default)('json'),
    currency: (0, _attr.default)('string', { defaultValue: "usd" }),
    isbtob: Ember.computed.equal('customerType', 'b2b'),
    isbtoc: Ember.computed.equal('customerType', 'b2c'),
    customerTypeConfirmed: (0, _attr.default)('boolean', { defaultValue: false }),
    termsOfService: (0, _attr.default)('boolean', { defaultValue: false }),
    agreementAcceptedAt: (0, _attr.default)('date'),
    effectiveDate: (0, _attr.default)('date'),
    currentWizardStep: (0, _attr.default)('string', { defaultValue: 'incomplete' }),
    internal: (0, _attr.default)('boolean', { defaultValue: false }),
    communicationsServiceAvailable: (0, _attr.default)('boolean', { defaultValue: false }),
    storageUsage: (0, _attr.default)(),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    status: (0, _attr.default)(),
    adminSignInCount: (0, _attr.default)('number'),
    singleUser: (0, _attr.default)('boolean', { defaultValue: false }),
    singleAdmin: (0, _attr.default)('boolean', { defaultValue: false }),
    isParent: (0, _attr.default)('boolean', { defaultValue: false }),
    isSubaccount: (0, _attr.default)('boolean', { defaultValue: false }),
    statusTitle: Ember.computed('status', function () {
      return (this.get('status') || '').capitalize();
    }),
    isActive: Ember.computed('status', function () {
      return this.get('status') === 'active';
    }),
    stripeToken: (0, _attr.default)('string'),
    billingType: (0, _attr.default)('string'),
    isAutomaticBillingType: Ember.computed('billingType', function () {
      return this.get('billingType') === 'automatic';
    }),
    hasAttachedCard: (0, _attr.default)('boolean', { defaultValue: false }),
    contactName: Ember.computed('contactFirstName', 'contactLastName', function () {
      return `${this.get('contactFirstName')} ${this.get('contactLastName')}`;
    }),
    nameOrContactName: Ember.computed.or('name', 'contactName'),
    hasReportsService: Ember.computed('subscription.plan.productKey', 'subscription.plan.isKnowmeIQStarter', function () {
      return !['basic_crm'].includes(this.get('subscription.plan.productKey')) && !this.get('subscription.plan.isKnowmeIQStarter');
    }),
    onInternalChange: Ember.observer('internal', 'subscription.id', function () {
      if (this.get('internal') && Ember.isPresent(this.get('subscription.id'))) {
        if (this.get('isNew')) {
          this.set('subscription.kind', 'trial');
        } else {
          this.get('subscription').then(function (subscription) {
            subscription.set('kind', 'trial');
          });
        }
      }
    }),
    nonDeletedDashboardPermissions: Ember.computed.filterBy('dashboardPermissions', 'isDeleted', false),
    billingTerms: { 1: 'monthly', 12: 'annually' },
    settingsAccountBillingTermPeriod: Ember.computed('settingsAccountBillingTerm.value', function () {
      return this.billingTerms[this.get('settingsAccountBillingTerm.value')];
    })
  });
});