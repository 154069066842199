define('knowme-client/authorizers/jwt', ['exports', 'ember-simple-auth-token/authorizers/token'], function (exports, _token) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _token.default.extend({
    authorize: function authorize(data, block) {
      let token = data['token'];

      if (!Ember.isEmpty(token)) {
        block('Authorization', token);
      }
    }
  });
});