define('knowme-client/components/active-storage-file-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    activeStorage: Ember.inject.service(),
    uploadProgress: 0,
    buttonName: 'Add files...',
    imageOnly: false,
    actions: {
      upload(event) {
        const files = event.target.files;
        let url = Ember.getOwner(this).lookup('adapter:application').buildURL('uploads');
        if (Ember.isPresent(files)) {
          for (var i = 0; i < files.length; i++) {
            Ember.get(this, 'activeStorage').upload(files.item(i), url, {
              onProgress: progress => {
                Ember.set(this, 'uploadProgress', progress);
              }
            }).then(blob => {
              Ember.get(this, 'onFileUploaded')(blob);
              Ember.set(this, 'uploadProgress', 0);
            });
          }
        }
      }
    }

  });
});