define('knowme-client/mixins/routes/cable-setup', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cableService: Ember.inject.service('cable'),
    subscriptionData: {},
    pausePushing: false,
    skipSubscription: false,
    pendingItems: [],
    pushPendingItems: Ember.observer('pausePushing', function () {
      if (!this.get('pausePushing') && !Ember.isEmpty(this.get('pendingItems'))) {
        console.log('push pending items...');
        let route = this;
        while (!Ember.isEmpty(this.get('pendingItems'))) {
          let data = this.get('pendingItems').popObject();
          let resp = data.resp;
          let controller = data.controller;
          Ember.run.later(function () {
            let payload = resp.payload;
            if (typeof payload === 'string') {
              payload = JSON.parse(resp.payload);
            }
            if (resp.isSimpleJSON) {
              let modelClass = route.get("store").modelFor(resp.modelName);
              let serializer = route.get("store").serializerFor(resp.modelName);
              let normalizedPayload = serializer.normalizeSingleResponse(route.get("store"), modelClass, payload, null, true);
              route.get("store").push(normalizedPayload);
            } else {
              route.get("store").pushPayload(resp.modelName, payload);
            }

            if (route.afterReceived) {
              Ember.run.debounce({ routeName: route.routeName }, () => {
                route.afterReceived(controller, typeof resp.payload === 'string' ? JSON.parse(resp.payload) : resp.payload, resp.action);
              }, 1000);
            }
          }, 1000);
        }
      }
    }),
    setupController: function (controller) {
      this._super.apply(this, arguments);
      if (!this.get('skipSubscription')) {
        let route = this;
        let path = `${_environment.default.APP.Websocket.protocol}://${_environment.default.APP.Websocket.host}/cable`;
        let consumer = this.get('cableService').createConsumer(path);
        let data = this.get('subscriptionData');

        const subscription = consumer.subscriptions.create(this.get('channelName'), {
          connected() {
            this.perform("follow", data);
          },
          received(resp) {
            if (route.get('pausePushing')) {
              console.log('skip pushing');
              route.get('pendingItems').pushObject({ resp: resp, controller: controller });
            } else {
              let payload = resp.payload;
              if (typeof payload === 'string') {
                payload = JSON.parse(resp.payload);
              }
              Ember.run.later(function () {
                if (resp.isSimpleJSON) {
                  let modelClass = controller.get("store").modelFor(resp.modelName);
                  let serializer = controller.get("store").serializerFor(resp.modelName);
                  let normalizedPayload = serializer.normalizeSingleResponse(controller.get("store"), modelClass, payload, null, true);
                  controller.get("store").push(normalizedPayload);
                } else {
                  controller.get("store").pushPayload(resp.modelName, payload);
                }

                if (route.afterReceived) {
                  const context = { routeName: route.routeName };
                  Ember.run.debounce(context, () => {
                    route.afterReceived(controller, typeof resp.payload === 'string' ? JSON.parse(resp.payload) : resp.payload, resp.action);
                  }, 3000);
                  route.afterReceived(controller, typeof resp.payload === 'string' ? JSON.parse(resp.payload) : resp.payload, resp.action);
                }
              }, 2000);
            }
          },
          disconnected() {
            console.debug("disconnected");
          }
        });
        controller.set('subscription', subscription);
        controller.set('consumer', consumer);
      }
    },
    resetController: function (controller /*, isExiting, transition*/) {
      if (this.controller.get('subscription')) {
        this.controller.get('subscription').unsubscribe();
      }
      if (this.controller.get('consumer')) {
        this.controller.get('consumer').destroy();
      }
    },
    actions: {
      pauseRefreshing: function () {
        this.set('pausePushing', true);
      },
      continueRefreshing: function () {
        this.set('pausePushing', false);
      }
    }
  });
});