define('knowme-client/mixins/controllers/validations/leads/contacts-form', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.phone', 'model.phonesCount', 'model.emailsCount'],
      disabled: Ember.computed('model.phone.length', 'model.phonesCount', 'model.emailsCount', function () {
        return Ember.isPresent(this.model) && (this.model.get('phonesCount') > 0 || this.model.get('emailsCount') > 0);
      })
    }), (0, _emberCpValidations.validator)('length', { max: 50 }), (0, _emberCpValidations.validator)('format', { type: 'email', regex: /^(.+@.+\..{2,4})+$/, allowBlank: true })],
    phone: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.email', 'model.phonesCount', 'model.emailsCount'],
      disabled: Ember.computed('model.email.length', 'model.phonesCount', 'model.emailsCount', function () {
        return Ember.isPresent(this.model) && (this.model.get('phonesCount') > 0 || this.model.get('emailsCount') > 0);
      })
    }), (0, _emberCpValidations.validator)('length', {
      // max: 20,
      is: 12,
      dependentKeys: ['model.email', 'model.phonesCount', 'model.emailsCount'],
      disabled: Ember.computed('model.email.length', 'model.phonesCount', 'model.emailsCount', function () {
        return Ember.isPresent(this.model) && (this.model.get('phonesCount') > 0 || this.model.get('emailsCount') > 0);
      })
    })]
  });
});