define('knowme-client/mixins/models/validations/did-allocation', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    didNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.allocatable.isKnowmeIqRequired', function () {
        let allocatable = this.get('model.allocatable');
        if (allocatable.get('_internalModel.modelName') === 'user' && allocatable.get('isKnowmeIqRequired')) {
          return false;
        }
        return !this.get('model.isNew');
      })
    }), (0, _emberCpValidations.validator)('ds-error')]
  });
});