define('knowme-client/controllers/login', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	const { host } = _environment.default.APP.API;

	var LoginController;

	LoginController = Ember.Controller.extend({
		queryParams: { changedPassword: 'changed_password' },
		session: Ember.inject.service('session'),
		errorMessage: null,
		loginProgress: 0,
		forgotPasswordHref: Ember.computed('model', function () {
			return `https://${host}/users/password/new`;
		}),
		init: function () {
			this._super();
			this.set('inactiveLogout', false);
			if (localStorage.getItem('inactiveLogout')) {
				this.set('inactiveLogout', true);
				localStorage.removeItem('inactiveLogout');
			}
		},
		actions: {
			submit: function () {
				var controller = this;
				var email = controller.get('email');
				var password = controller.get('password');
				return Ember.run(function () {
					return controller.get('session').authenticate('authenticator:jwt', { user: { email: email, password: password } }, {}, controller).then(function () {
						localStorage.setItem('firstTimeDashboardOpened', true);
						return controller.transitionToRoute('dashboard');
					}).catch(function (response) {
						if (response) {
							controller.set('errorMessage', response.responseJSON.error);
						} else {
							controller.set('errorMessage', 'Something went wrong, please try again.');
							console.log('Error: No response');
						}
					});
				}.bind(this));
			}
		}
	});

	exports.default = LoginController;
});