define('knowme-client/serializers/communication', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    attrs: {
      lead: { deserialize: 'id', serialize: 'id' },
      opportunity: { deserialize: 'id', serialize: 'id' },
      communicationResource: { deserialize: 'id', serialize: 'id' }
    }
  });
});