define('knowme-client/controllers/emails/compose', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    draftMessage: {},
    actions: {
      afterSend: function () {
        this.get('opportunity').get('activities').reload();
        this.send('afterSend');
      }
    }
  });
});