define('knowme-client/controllers/campaigns', ['exports', 'knowme-client/mixins/date-filter', 'knowme-client/mixins/controllers/queryable', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/status', 'lodash'], function (exports, _dateFilter, _queryable, _pagination, _status, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let CampaignsController;

  CampaignsController = Ember.Controller.extend(_dateFilter.default, _queryable.default, _pagination.default, _status.default, {
    defaultsQueryParams: {
      status: 'active'
    },
    queryParams: ['sort_by', 'order', 'query', 'campaign_id', 'channel'], // queryParams that trigger model reload
    leadsController: Ember.inject.controller('leads.index'),
    sort_by: null,
    order: null,
    campaign_id: null,
    channel: null,
    selectedLabel: null,
    channelOptions: [{ value: 'sem', text: '<span class="ad-icons inline adwords"></span> SEM' }, { value: 'mobile', text: '<span class="ad-icons inline mobile"></span> Mobile' }, { value: 'social_media', text: '<span class="ad-icons inline social-media"></span> Social Media' }, { value: 'online_digital', text: '<span class="ad-icons inline digital"></span> Digital' }, { value: 'print', text: '<span class="ad-icons inline print"></span>Print' }, { value: 'radio', text: '<span class="ad-icons inline radio"></span>Radio' }, { value: 'television', text: '<span class="ad-icons inline television"></span>Television' }, { value: 'direct_mail', text: '<span class="ad-icons inline direct-mail"></span> Direct Mail' }, { value: 'email', text: '<span class="ad-icons inline email"></span> Email' }, { value: 'telemarketing', text: '<span class="ad-icons inline telemarketing"></span> Telemarketing' }, { value: 'general', text: '<span class="ad-icons inline general"></span> General' }],
    onSearchByName: Ember.observer('query', function () {
      // reset all filter
      let query = this.get('query');
      let queryParams = this.get('queryParams');
      if (Ember.isPresent(query)) {
        _lodash.default.each(queryParams, function (key) {
          if (!_lodash.default.includes(['query'], key)) {
            this.set(key, null);
          }
        }.bind(this));
      }
    }),
    onStatusSelected: Ember.observer('status', function () {
      this.set('page', null);
    }),
    onChannelSelected: Ember.observer('channel', function () {
      this.set('page', null);
    }),
    onCampaignSelected: Ember.observer('campaign_id', function () {
      this.set('page', null);
    }),
    onDatePeriodSelected: Ember.observer('dateFilterSelected', function () {
      // reset current page
      this.set('page', null);
    }),
    actions: {
      sort: function (toSortBy) {
        var sortBy = this.get('sort_by') || 'name';
        var order = this.get('order');
        if (sortBy === toSortBy) {
          order = order === 'asc' ? 'desc' : 'asc';
        } else {
          sortBy = toSortBy;
          order = 'desc';
        }
        this.set('sort_by', sortBy);
        this.set('order', order);
        this.send('setOrder', sortBy, order);
      }
    },
    filtering: Ember.computed('from_date', 'to_date', 'campaign_id', 'channel', function () {
      return Ember.isPresent(this.get('from_date')) && Ember.isPresent(this.get('to_date')) || Ember.isPresent(this.get('campaign_id')) || Ember.isPresent(this.get('channel'));
    })
  });

  exports.default = CampaignsController;
});