define('knowme-client/models/contract-version', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend({
		contractAgreement: (0, _relationships.belongsTo)('contract-agreement'),
		body: (0, _attr.default)(),
		publishedAt: (0, _attr.default)('date')
	});
});