define('knowme-client/mixins/models/status', ['exports', 'ember-data/attr'], function (exports, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    status: (0, _attr.default)('string', { defaultValue: 'active' }),
    isActive: Ember.computed.equal('status', 'active'),
    isInactive: Ember.computed.equal('status', 'inactive')
  });
});