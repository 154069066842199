define('knowme-client/initializers/json-serializer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('serializer:user-json', 'store', 'service:store');
  }

  exports.default = { initialize };
});