define('knowme-client/routes/email-templates/new', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    model: function () {
      return this.store.createRecord('template', { account: this.get('session.currentUser.account') });
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get('model');
        if (model.get('isNew')) {
          model.deleteRecord();
        }
      }
    }
  });
});