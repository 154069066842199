define('knowme-client/models/incentive', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/validations/incentive', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'moment'], function (exports, _model, _attr, _relationships, _incentive, _emberCpValidationsCustomProperties, _dirty, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Incentive;

  Incentive = _model.default.extend(_incentive.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    // Relationships
    account: (0, _relationships.belongsTo)('account', { async: true }),
    incentivesContacts: (0, _relationships.hasMany)('incentives-contact', { async: true, inverse: 'incentive' }),
    // Attributes
    name: (0, _attr.default)(),
    body: (0, _attr.default)(),
    status: (0, _attr.default)('string', { defaultValue: "active" }),
    contactsCount: (0, _attr.default)('number'),
    respondedCount: (0, _attr.default)('number'),
    newContactsCount: (0, _attr.default)('number'),
    existingContactsCount: (0, _attr.default)('number'),
    startDate: (0, _attr.default)('date', {
      defaultValue() {
        return (0, _moment.default)().startOf('day').toDate();
      }
    }),
    idNumber: Ember.computed('id', function () {
      if (this.get('id').toString().length < 2) {
        return ('0' + this.get('id')).slice(-2);
      } else {
        return this.get('id');
      }
    }),
    status: Ember.computed('hasExpired', function () {
      return this.get('hasExpired') ? 'expired' : 'active';
    }),
    endDate: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    isInactive: Ember.computed.equal('status', 'inactive'),
    hasExpired: (0, _attr.default)('boolean')
  });

  exports.default = Incentive;
});