define('knowme-client/models/order', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr', 'knowme-client/mixins/models/validations/order', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _relationships, _attr, _order, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Order;

  Order = _model.default.extend(_order.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    opportunity: (0, _relationships.belongsTo)('opportunity', { async: true }),
    product: (0, _relationships.belongsTo)('product', { async: true }),
    quantity: (0, _attr.default)('number'),
    discount: (0, _attr.default)('string', { defaultValue: '0' }), //masked input converts it to string
    dealPrice: (0, _attr.default)('number', { defaultValue: 0 }),
    total: (0, _attr.default)('number', { defaultValue: 0 }),
    taxRate: (0, _attr.default)('number', { defaultValue: 0 }),
    taxable: (0, _attr.default)('boolean', { defaultValue: false }),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    productId: Ember.computed.alias('product.id'),
    profit: Ember.computed('calculatedTotal', 'product.cost', 'quantity', function () {
      return this.get('calculatedTotal') - this.get('product.cost') * this.get('quantity');
    }),
    onDealPriceChange: Ember.observer('dealPrice', function () {
      if (typeof this.get('dealPrice') === 'string') {
        this.set('dealPrice', parseFloat(this.get('dealPrice')));
      }
    }),
    maxDiscountPercentageExceeded: Ember.computed('discount', 'product.maxDiscountPercentage', function () {
      return parseFloat(this.get('discount')) > parseFloat(this.get('product.maxDiscountPercentage')) * 100;
    }),
    calculatedTax: Ember.computed('calculatedTotal', 'taxRate', 'taxable', function () {
      if (!this.get('taxable')) {
        return 0;
      }
      let _taxRate = parseFloat(this.get('taxRate')) || 0;

      return this.get('calculatedTotal') * _taxRate / 100;
    }),
    calculatedTotal: Ember.computed('quantity', 'dealPrice', 'discount', 'total', function () {
      let _quantity = parseFloat(this.get('quantity')) || 0,
          _price = parseFloat(this.get('dealPrice')),
          _discount = parseFloat(this.get('discount')) || 0;
      let _toDiscount = _price * _discount / 100;
      // this.set('total', _quantity * (_price - _toDiscount));
      return _quantity * (_price - _toDiscount);
    }),
    onProductChange: Ember.observer('product.id', function () {
      if (!this.get('isDeleted')) {
        this.set('dealPrice', this.get('product.price') || 0);
        this.set('discount', 0);
        this.set('taxable', this.get('product.taxable'));
        this.set('taxRate', this.get('product.taxRate'));
      }
    }),
    onQuantityChange: Ember.observer('quantity', function () {
      // needed to avoid difference between numeric and string value
      if (typeof this.get('quantity') === 'string') {
        this.set('quantity', parseFloat(this.get('quantity')));
      }
    })
  });

  exports.default = Order;
});