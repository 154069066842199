define('knowme-client/models/contact-email', ['exports', 'ember-data/relationships', 'ember-data/attr', 'knowme-client/mixins/models/validations/contact-email', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'knowme-client/models/sales-transaction-target'], function (exports, _relationships, _attr, _contactEmail, _emberCpValidationsCustomProperties, _dirty, _salesTransactionTarget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ContactEmail;

  ContactEmail = _salesTransactionTarget.default.extend(_contactEmail.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    contactInfo: (0, _relationships.belongsTo)('contact-info', { async: true }),
    surveys: (0, _relationships.hasMany)('surveys', { async: true }),
    email: (0, _attr.default)(),
    asString: Ember.computed('email', function () {
      return this.get('email');
    })
  });

  exports.default = ContactEmail;
});