define('knowme-client/components/audio-player', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    hifi: Ember.inject.service(),
    classNames: ['m-t'],
    play: false,
    emailFormVisible: false,
    addressBookVisible: false,
    disabledSendCallAudio: true,
    currentTime: Ember.computed('audio.position', function () {
      return this.formatSecondsToMinutesAndSeconds((this.get('audio.position') || 0) / 1000);
    }),
    formatSecondsToMinutesAndSeconds: function (seconds) {
      if (Ember.isBlank(seconds)) {
        return '';
      }
      seconds = parseInt(seconds);
      var calculatedMinutes = Math.floor(seconds / 60);
      var calculatedSeconds = seconds - calculatedMinutes * 60;

      var mDisplay = calculatedMinutes < 10 ? '0' + calculatedMinutes : calculatedMinutes;
      var sDisplay = calculatedSeconds < 10 ? '0' + calculatedSeconds : calculatedSeconds;

      return `${mDisplay}:${sDisplay}`;
    },
    didInsertElement: function () {
      this._super.apply(this, arguments);
      if (this.get('play')) {
        this.send('play');
      }
    },
    willDestroyElement: function () {
      this._super(...arguments);
      if (this.get('hifi.isPlaying')) {
        this.get('hifi').pause();
        this.set('hifi.position', 0);
      }
    },
    actions: {
      play: function () {
        this.startListening();
        this.get('hifi').play(this.get('url')).then(({ sound }) => {
          this.set('audio', sound);
          this.get('audio').on('audio-ended', function () {
            this.stopListening();
          }.bind(this));
          this.set('disabledSendCallAudio', false);
        }).catch(error => {
          this.set('audio', null);
          console.log('Error', error);
        });
      },
      pause: function () {
        this.stopListening();
        this.get('audio').pause();
      },
      showEmailForm: function () {
        this.set('emailFormVisible', true);
      },
      addUserEmail: function (user) {
        if (this.$('#recipients')[0].value !== '') {
          this.$('#recipients')[0].value += ', ';
        }
        this.$('#recipients')[0].value += user.get('email');
      },
      expandEmailMessage: function () {
        this.$('#message')[0].rows += 1;
      },
      downloadAudio: function (url) {
        var save = document.createElement('a');
        save.href = url;
        save.download = "CallAudio";
        save.target = '_blank';
        document.body.appendChild(save);
        save.click();
        document.body.removeChild(save);
      },
      sendAudio: function () {
        var recipients = this.$('#recipients').val();
        var message = this.$('#message').val();
        var callId = this.$('#call-id').val();
        var userId = this.get('session.currentUser.id');

        let adapter = Ember.getOwner(this).lookup('adapter:application');
        let forwardMailUrl = adapter.buildURL('mails');
        let forwardMailUrlRequest = adapter.ajax(forwardMailUrl, 'POST', { data: { call_id: callId, user_id: userId, recipients: recipients, message: message } });
        let promise = new Ember.RSVP.Promise(function (resolve /*, reject */) {
          forwardMailUrlRequest.then(function (response) {
            resolve(response);
          });
        });
        this.set('emailFormVisible', false);
        return promise;
      }
    }
  });
});