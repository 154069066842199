define('knowme-client/controllers/account/clients/edit', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			transitionToEdit: function (account) {
				account.belongsTo('subscription').reload();
				this.transitionToRoute('account.clients');
			}
		}
	});
});