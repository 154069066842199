define('knowme-client/models/sales-stage', ['exports', 'ember-data/model', 'ember-data/attr', 'knowme-client/mixins/models/validations/sales-stage', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _salesStage, _emberCpValidationsCustomProperties, _dirty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_salesStage.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    key: (0, _attr.default)(),
    name: (0, _attr.default)(),
    internal: (0, _attr.default)('boolean'),
    editable: (0, _attr.default)('boolean'),
    position: (0, _attr.default)('number'),
    funnelVisible: (0, _attr.default)('boolean'),
    action: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    status: (0, _attr.default)('string'), // options Active, Inactive
    isInternal: Ember.computed.alias('internal'),
    isEditable: Ember.computed.alias('editable')
  });
});