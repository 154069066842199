define('knowme-client/models/notification', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    notifiable: (0, _relationships.belongsTo)('notifiable', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    action: (0, _attr.default)(),
    readAt: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    isUnread: Ember.computed('readAt', function () {
      return Ember.isBlank(this.get('readAt'));
    })
  });
});