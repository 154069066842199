define('knowme-client/components/bootstrap-nano-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    openList: false,
    actions: {
      open: function () {
        this.set('openList', !this.get('openList'));
        if (this.get('openList')) {
          Ember.run.later(function () {
            this.get('scroller').nanoScroller();
          }.bind(this), 200);
        }
      }
    }
  });
});