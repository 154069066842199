define('knowme-client/helpers/is-value-present', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var isValuePresent, IsValuePresentHelper;

  exports.isValuePresent = isValuePresent = function (params) {
    var value = params[0];
    return Ember.isPresent(value);
  };

  IsValuePresentHelper = Ember.Helper.helper(isValuePresent);

  exports.isValuePresent = isValuePresent;
  exports.default = IsValuePresentHelper;
});