define('knowme-client/routes/campaign/ads/edit', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    model: function (params) {
      console.log(params.ad_id);
      let ad = this.get('store').find('ad', params.ad_id);
      return ad;
    },
    afterModel: function (model) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let didsUrl = adapter.buildURL('dids');
      let tollFreeDidsRequest = adapter.ajax(didsUrl, 'GET', { data: { type: 'toll_free', filter: 'unassigned' } });
      let localDidsRequest = adapter.ajax(didsUrl, 'GET', { data: { type: 'toll_free', filter: 'unassigned' } });
      let accountSummaryUrl = adapter.buildURL('accounts/summary');
      let accountSummaryRequest = adapter.ajax(accountSummaryUrl, 'GET');

      return Ember.RSVP.hash({
        tollFreeNumbers: tollFreeDidsRequest.then(function (dids) {
          return dids;
        }),
        localDids: localDidsRequest.then(function (dids) {
          return dids;
        }),
        accountSummary: accountSummaryRequest.then(function (accountSummary) {
          return accountSummary;
        }),
        callTouchPoints: model.get('callTouchPoints'),
        chatTouchPoints: model.get('chatTouchPoints')
      }).then(function (results) {
        let serializer = this.get("store").serializerFor('json-api');
        serializer.camelizeKeys(results.accountSummary);
        this.set('controllerData', results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get('controllerData'));
      this.set('controllerData', {});
    },
    actions: {
      willTransition: function (transition) {
        let model = this.controller.get('model');
        model.rollbackAttributes();

        // clear cached records
        if (transition.targetName === 'campaigns') {
          this.get('store').peekAll('ad').invoke('unloadRecord');
          this.get('store').peekAll('campaign').invoke('unloadRecord');
        }
      }
    }
  });
});