define('knowme-client/mirage/serializers/lead', ['exports', 'knowme-client/mirage/serializers/active-model-serializer'], function (exports, _activeModelSerializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelSerializer.default.extend({
    relationships: ['contacts', 'opportunities', 'organization'],
    include: ['contacts', 'opportunities', 'organization']
  });
});