define('knowme-client/components/bootstrap-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    prompt: "Select an Option",
    labelPath: "label",
    valuePath: "value",
    selectedValue: null,
    selectedOption: null,
    disabled: false,
    didReceiveAttrs() {
      this._super(...arguments);

      if (Ember.isPresent(this.get('selectedValue'))) {
        let selectedOption = this.get('options').findBy(this.get('valuePath'), this.get('selectedValue'));
        if (Ember.isBlank(this.get('selectedOption')) || this.get('selectedOption.text') !== selectedOption.text) {
          this.set('selectedOption', selectedOption);
        }
      }
    },
    onSelectedValueChange: Ember.observer('selectedValue', function () {
      if (Ember.isPresent(this.get('selectedValue'))) {
        this.set('selectedOption', this.get('options').findBy(this.get('valuePath'), this.get('selectedValue')));
      } else {
        this.set('selectedOption', null);
      }
    }),
    onSelectedOptionChange: Ember.observer('selectedOption', function () {
      let option = this.get('selectedOption');
      let value = Ember.isPresent(option) && Ember.isPresent(this.get('valuePath')) ? Ember.get(option, this.get('valuePath')) : null;
      this.set('selectedValue', value);
    }),
    selectedLabel: Ember.computed('selectedOption', 'selectedValue', 'labelPath', function () {
      let option = this.get('selectedOption');
      let label = Ember.isPresent(option) ? Ember.get(option, this.get('labelPath')) : null;
      return label;
    }),
    options: Ember.A([]),
    actions: {
      select: function (option = null) {
        this.set('selectedOption', option);
        if (Ember.isPresent(this.get('onSelect'))) {
          this.onSelect(option);
        }
      }
    }
  });
});