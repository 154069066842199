define('knowme-client/mirage/factories/call', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    from: function () {
      return _faker.default.phone.phoneNumberFormat(0).replace(/-/g, '');
    },
    to: function () {
      return _faker.default.phone.phoneNumberFormat(0).replace(/-/g, '');
    },
    callId: function () {
      return _faker.default.datatype.uuid();
    },
    callType: function () {
      return _faker.default.random.arrayElement(['inbound', 'outbound']);
    },
    callLength: function () {
      return _faker.default.datatype.number({ min: 4, max: 200 });
    },
    callRecordingLength: function () {
      return _faker.default.datatype.number({ min: 4, max: 200 });
    },
    callAudio: function () {
      return _faker.default.random.arrayElement([null, _faker.default.internet.url()]);
    },
    callDate: function () {
      return _faker.default.date.past().toISOString();
    },
    listened: function () {
      return _faker.default.random.arrayElement([true, false]);
    },
    status: function () {
      return _faker.default.random.arrayElement(['started', 'missed', 'answered', 'rejected']);
    },
    canBeRemoved: function () {
      return _faker.default.random.arrayElement([true, false]);
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    },
    afterCreate(call, server) {
      if (Ember.isBlank(call.contact)) {
        let contactPhoneNumber = _faker.default.random.arrayElement(server.schema.contactPhoneNumbers.all().models);
        let contact = contactPhoneNumber.contactInfo.contact;
        let opportunity = contact.lead.opportunities.models[0];
        let user = opportunity.assignedTo;
        call.update({
          opportunity: opportunity,
          contact: contact,
          contactPhoneNumber: contactPhoneNumber,
          user: user
        });
        contact.update({
          callsCount: contact.calls.length,
          lastCall: contact.calls.models[contact.calls.length - 1]
        });
        user.update({
          callsCount: user.calls.length,
          lastCall: user.calls.models[user.calls.length - 1]
        });
      }
    }
  });
});