define('knowme-client/mixins/controllers/report-schedule', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		accountService: Ember.inject.service('account'),
		features: Ember.inject.service(),
		customersText: Ember.computed.alias('accountService.customersText'),
		reportNames: Ember.computed('customersText', function () {
			let types = [{ value: 'leads_pipeline', text: 'Leads Pipeline' }, { value: 'customers_pipeline', text: `${this.get('customersText')} Pipeline` }, { value: 'customers_and_leads_pipeline', text: `${this.get('customersText')} and Leads Pipeline` }, { value: 'customers_revenue', text: `${this.get('customersText')} Revenue` }];
			if (this.get('features.activityUserAvailable')) {
				types.push({ value: 'activity_by_user', text: 'Activity by User' });
			}
			if (this.get('features.uniqueLeadsReportsAvailable')) {
				types.push({ value: 'unique_leads_per_campaign', text: 'Unique Leads Per Campaign' });
				types.push({ value: 'unique_leads_per_campaign_details', text: 'Unique Leads Per Campaign - Details' });
			}
			if (this.get('features.outboundCallsReportsAvailable')) {
				types.push({ value: 'outbound_calls_per_user', text: 'Outbound Calls Per User' });
			}
			return types;
		}),
		reportTimeframes: [{ value: 'today', text: 'Today Only' }, { value: 'todayAndYesterday', text: 'Since Yesterday' }, { value: 'todayAndPrevious2Days', text: 'Last 3 Days' }, { value: 'todayAndPrevious6Days', text: 'Last 7 Days' }, { value: 'todayAndPrevious29Days', text: 'Last 30 Days' }, { value: 'previousMonth', text: 'Last Month' }, { value: 'yearToDate', text: 'Year to Date (YTD)' }]
	});
});