define('knowme-client/serializers/account', ['exports', 'knowme-client/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    session: Ember.inject.service(),
    normalize(typeClass, hash /*, prop*/) {
      for (let key in hash.attributes['active-settings']) {
        if (hash.attributes['active-settings'][`${key}-unlimited`]) {
          hash.attributes['active-settings'][key] = Infinity;
        }
      }
      if (Ember.isPresent(hash.attributes['active-settings'])) {
        this.camelizeKeys(hash.attributes['active-settings']);
      }

      if (this.get('session.isAuthenticated')) {
        hash.relationships = hash.relationships || {};
        hash.relationships['admin-users'] = hash.relationships['admin-users'] || {};
        hash.relationships['admin-users'].links = hash.relationships.links || {};
        hash.relationships['admin-users'].links.related = Ember.getOwner(this).lookup('adapter:user').buildURL('user', null, null, 'findMany', { account_id: hash.id, filter: ['admin_users'], paginate: false });
        hash.relationships['administrators'] = hash.relationships['administrators'] || {};
        hash.relationships['administrators'].links = hash.relationships.links || {};
        hash.relationships['administrators'].links.related = Ember.getOwner(this).lookup('adapter:user').buildURL('user', null, null, 'findMany', { account_id: hash.id, filter: ['admins'], paginate: false });
        hash.relationships['client-administrators'] = hash.relationships['client-administrators'] || {};
        hash.relationships['client-administrators'].links = hash.relationships.links || {};
        hash.relationships['client-administrators'].links.related = Ember.getOwner(this).lookup('adapter:user').buildURL('user', null, null, 'findMany', { account_id: hash.id, filter: ['client_admins'], paginate: false });
        hash.relationships['default-caller-ids-ad'] = hash.relationships['default-caller-ids-ad'] || {};
        hash.relationships['default-caller-ids-ad'].links = hash.relationships.links || {};
        if (hash.relationships['default-caller-ids-ad'].data) {
          hash.relationships['default-caller-ids-ad'].links.related = Ember.getOwner(this).lookup('adapter:ad').buildURL('ad', hash.relationships['default-caller-ids-ad'].data.id, null, 'findRecord', null);
        }
        hash.relationships['default-manual-ad'] = hash.relationships['default-manual-ad'] || {};
        hash.relationships['default-manual-ad'].links = hash.relationships.links || {};
        if (hash.relationships['default-manual-ad'].data) {
          hash.relationships['default-manual-ad'].links.related = Ember.getOwner(this).lookup('adapter:ad').buildURL('ad', hash.relationships['default-manual-ad'].data.id, null, 'findRecord', null);
        }
        hash.relationships['settings'] = hash.relationships['settings'] || {};
        hash.relationships['settings'].links = hash.relationships.links || {};
        hash.relationships['settings'].links.related = Ember.getOwner(this).lookup('adapter:account-setting').buildURL('account-setting', null, null, 'findMany', { account_id: hash.id, paginate: false });
        hash.relationships['dashboard-permissions'] = hash.relationships['dashboard-permissions'] || {};
        hash.relationships['dashboard-permissions'].links = hash.relationships.links || {};
        hash.relationships['dashboard-permissions'].links.related = Ember.getOwner(this).lookup('adapter:application').buildURL('dashboard-permission', null, null, 'findMany', { account_id: hash.id, paginate: false });
        hash.relationships['opportunity-fields'] = hash.relationships['opportunity-fields'] || {};
        hash.relationships['opportunity-fields'].links = hash.relationships.links || {};
        hash.relationships['opportunity-fields'].links.related = Ember.getOwner(this).lookup('adapter:application').buildURL('opportunity-field', null, null, 'findMany', { account_id: hash.id, enable: true, paginate: false });
        hash.relationships['organization-fields'] = hash.relationships['organization-fields'] || {};
        hash.relationships['organization-fields'].links = hash.relationships.links || {};
        hash.relationships['organization-fields'].links.related = Ember.getOwner(this).lookup('adapter:application').buildURL('organization-field', null, null, 'findMany', { account_id: hash.id, enable: true, paginate: false });
        hash.relationships['contact-fields'] = hash.relationships['contact-fields'] || {};
        hash.relationships['contact-fields'].links = hash.relationships.links || {};
        hash.relationships['contact-fields'].links.related = Ember.getOwner(this).lookup('adapter:application').buildURL('contact-field', null, null, 'findMany', { account_id: hash.id, enable: true, paginate: false });
        hash.relationships['parent-account'] = hash.relationships['parent-account'] || {};
        hash.relationships['parent-account'].links = hash.relationships.links || {};
        if (hash.relationships['parent-account'].data) {
          let parentAccountId = hash.relationships['parent-account'].data.id;
          if (this.get('session.currentUser.isMasterAdmin') || this.get('session.currentUser.isClientAdmin') && this.get('session.currentUser.account.id') === parentAccountId) {
            hash.relationships['parent-account'].links.related = Ember.getOwner(this).lookup('adapter:account').buildURL('account', parentAccountId, null, 'findRecord', null);
          } else {
            hash.relationships['parent-account'].data = null;
          }
        }
      }
      return this._super.apply(this, arguments);
    }
  });
});