define('knowme-client/adapters/stripe-account', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
    pathForType: function () /*type*/{
      return 'stripe_account';
    }
  });
});