define('knowme-client/models/event-type', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let EventType;

	EventType = _model.default.extend({
		description: (0, _attr.default)()
	});

	exports.default = EventType;
});