define('knowme-client/controllers/products/index', ['exports', 'knowme-client/mixins/products'], function (exports, _products) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProductsController;

  ProductsController = Ember.Controller.extend(_products.default, {
    scroller: Ember.inject.service(),
    currentProduct: null,
    hideProductsBoxes: false,
    interval: 'annually',
    addOns: {
      usersCount: 0,
      contactsCount: 0,
      minutesCount: 0,
      textMessagesCount: 0,
      localNumbersCount: 0,
      tollNumbersCount: 0
    },
    publicProducts: Ember.computed.filter('products', function (resource) {
      return resource.isForPricing;
    }).property('products'),
    actions: {
      setSignupData: function (signupData) {
        this.set('signupData', signupData);
      },
      openSettings: function (product) {
        this.set('currentProduct', product);
        if (!this.get('hideProductsBoxes')) {
          let heightOfHeader = 90;
          Ember.run.later(this, function () {
            let target = Ember.$('#' + product.key);
            if (Ember.$('html').scrollTop() == 0 && Ember.$('body').scrollTop() > 0) {
              let scrollable = Ember.$('body');
              scrollable.animate({
                scrollTop: scrollable.scrollTop() - scrollable.offset().top + target.offset().top - (window.pageYOffset + heightOfHeader)
              }, 200);
            } else {
              this.get('scroller').scrollVertical(target, { duration: 200, offset: -1 * (window.pageYOffset + heightOfHeader) });
            }
          }, 300);
        }
      },
      toggleInteval: function () {
        if (this.get('interval') === 'annually') {
          this.set('interval', 'monthly');
        } else {
          this.set('interval', 'annually');
        }
      }
    }
  });

  exports.default = ProductsController;
});