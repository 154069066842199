define('knowme-client/mirage/factories/call-touch-point', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: _faker.default.company.catchPhrase,
    forwardTo: function () {
      return _faker.default.datatype.number({ min: 1000000000, max: 9999999999 });
    },
    extension: function () {
      return _faker.default.datatype.number({ min: 100, max: 999 });
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    }
  });
});