define('knowme-client/initializers/checkbox-reconfiguration', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.initialize = initialize;
		function initialize() /* application */{
				Ember.Checkbox.reopen({
						attributeBindings: ['readonly']
				});
		}

		exports.default = {
				name: 'checkbox-reconfiguration',
				initialize
		};
});