define('knowme-client/serializers/report-schedule', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		scheduledOnDaysAttributes: [{ field: 'scheduledOnMonday', value: 'monday' }, { field: 'scheduledOnTuesday', value: 'tuesday' }, { field: 'scheduledOnWednesday', value: 'wednesday' }, { field: 'scheduledOnThursday', value: 'thursday' }, { field: 'scheduledOnFriday', value: 'friday' }, { field: 'scheduledOnSaturday', value: 'saturday' }, { field: 'scheduledOnSunday', value: 'sunday' }],
		attrs: {
			campaign: { serialize: 'id', deserialize: 'id' },
			user: { serialize: false }
		},
		serializeAttribute: function (snapshot, json, key /*, attribute*/) {
			this._super(...arguments);
			if (Ember.isPresent(this.scheduledOnDaysAttributes.findBy('field', key))) {
				if (!Ember.isArray(json['scheduled_days'])) {
					json['scheduled_days'] = Ember.A([]);
				}
				var payloadKey = this._getMappedKey(key, snapshot.type);

				if (payloadKey === key && this.keyForAttribute) {
					payloadKey = this.keyForAttribute(key, 'serialize');
				}
				if (json[payloadKey]) {
					json['scheduled_days'].pushObject(this.scheduledOnDaysAttributes.findBy('field', key).value);
				}
				delete json[payloadKey];
			}
		},
		extractAttributes: function (modelClass, resourceHash) {
			var _this2 = this;

			var attributeKey;
			var attributes = {};

			modelClass.eachAttribute(function (key) {
				attributeKey = _this2.keyForAttribute(key, 'deserialize');
				if (resourceHash.hasOwnProperty(attributeKey)) {
					attributes[key] = resourceHash[attributeKey];
				}
				var scheduledOnDaysAttribute = this.scheduledOnDaysAttributes.findBy('field', key);
				if (Ember.isPresent(scheduledOnDaysAttribute)) {
					attributes[key] = resourceHash['scheduled_days'].includes(scheduledOnDaysAttribute.value);
				}
			}.bind(this));

			return attributes;
		}
	});
});