define('knowme-client/models/organization', ['exports', 'ember-data/relationships', 'ember-data/attr', 'knowme-client/mixins/models/validations/organization', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'knowme-client/models/customizable-resource'], function (exports, _relationships, _attr, _organization, _emberCpValidationsCustomProperties, _dirty, _customizableResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _customizableResource.default.extend(_organization.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    lead: (0, _relationships.belongsTo)('lead', { async: true }),
    name: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    faxNumber: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    website: (0, _attr.default)('string'),
    street: (0, _attr.default)('string'),
    street2: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zip: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    facebook: (0, _attr.default)('string'),
    twitter: (0, _attr.default)('string'),
    google: (0, _attr.default)('string'),
    youtube: (0, _attr.default)('string'),
    linkedin: (0, _attr.default)('string')
  });
});