define('knowme-client/components/emails/email-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    item: null,
    activeItem: null,
    hasConversation: false,
    tagName: '',
    className: '',
    openConversationParams: Ember.computed('contactId', 'opportunityId', 'singleOpportunity', function () {
      let params = { contactId: null, opportunityId: null, singleOpportunity: null };

      params.contactId = this.get('item.contact.id');

      if (Ember.isPresent(this.get('contactId'))) {
        params.opportunityId = this.get('item.opportunity.id');
      } else {
        if (this.get('item.opportunitiesCount') === 1) {
          params.singleOpportunity = true;
          params.opportunityId = this.get('item.opportunity.id');
        }
      }

      return {
        isQueryParams: true,
        values: params
      };
    }),
    closeConversationParams: Ember.computed('contactId', 'opportunityId', 'singleOpportunity', function () {
      let params = { contactId: null, opportunityId: null, singleOpportunity: null };

      if (Ember.isPresent(this.get('opportunityId')) && Ember.isBlank(this.get('singleOpportunity'))) {
        params.contactId = this.get('item.contact.id');
      }

      return {
        isQueryParams: true,
        values: params
      };
    }),
    isActive: Ember.computed('activeItem', 'item', function () {
      return this.get('activeItem.id') === this.get('item.id');
    })
  });
});