define('knowme-client/mirage/factories/order', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    quantity: function () {
      return _faker.default.datatype.number({ min: 1, max: 1000 });
    },
    price: _faker.default.commerce.price,
    discount: function () {
      return _faker.default.datatype.number({ min: 0, max: 100 });
    },
    createdAt: function () {
      return _faker.default.date.past().toISOString();
    },
    updatedAt: function () {
      return _faker.default.date.past().toISOString();
    }
  });
});