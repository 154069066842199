define('knowme-client/components/form-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['inline:m-r', 'formGroup:form-group', 'formGroupSm:form-group-sm'],
    inline: false,
    formGroup: true,
    formGroupSm: true,
    inputType: 'text',
    keyUp: 'none',
    textArea: false,
    rows: 3,
    labelText: Ember.computed('label', function () {
      if (Ember.isPresent(this.get('labelLimit')) && this.get('label').length > this.get('labelLimit')) {
        return Ember.String.htmlSafe(`<span class="hint--top" data-hint="${this.get('label')}">${this.get('label').substr(0, this.get('labelLimit'))}...</span>`);
      } else {
        return this.get('label');
      }
    }),
    fieldNameId: Ember.computed('fieldName', function () {
      let codeLength = 10;
      let code = Math.round(Math.pow(36, codeLength + 1) - Math.random() * Math.pow(36, length)).toString(36).slice(1);
      let model = this.get('model');
      let modelName = Ember.isPresent(model) ? model.constructor.modelName : 'control';
      return `${modelName}-${this.get('fieldName').dasherize()}-${code}`;
    }),
    actions: {
      capitalize: function (value) {
        let model = this.get('model');
        let field = this.get('fieldName');
        model.set(field, value.capitalize());
      },
      none: function () {}
    }
  });
});