define('knowme-client/components/km-flash-message', ['exports', 'ember-cli-flash/components/flash-message'], function (exports, _flashMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _flashMessage.default.extend({
    actions: {
      destroyFlashMessage: function () {
        this._destroyFlashMessage();
      }
    }
  });
});