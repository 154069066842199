define('knowme-client/mixins/models/validations/opportunity', ['exports', 'moment', 'ember-cp-validations'], function (exports, _moment, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 75, allowBlank: true }), (0, _emberCpValidations.validator)('ds-error')],
		nonDeletedOrders: (0, _emberCpValidations.validator)('has-many'),
		ad: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			ignoreBlank: true
		}),
		forecastDate: [(0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
			if (Ember.isBlank(value)) {
				return true;
			}
			let _forecastDate = (0, _moment.default)(value),
			    _dueDate = (0, _moment.default)(model.get('dueDate'));
			if (_forecastDate.isValid() && _dueDate.isValid() && _forecastDate.isSameOrAfter(_dueDate)) {
				return true;
			} else {
				return 'cannot be before than due date';
			}
		}, {
			dependentKeys: ['model.dueDate'],
			disabled: Ember.computed('model.dueDate', function () {
				return Ember.isBlank(this.get('model.dueDate'));
			})
		})],
		appointmentDate: [(0, _emberCpValidations.validator)('presence', {
			presence: true,
			ignoreBlank: true,
			dependentKeys: ['model.salesStage.key'],
			disabled: Ember.computed('model.salesStage.key', function () {
				return this.get('model.salesStage.key') !== 'appointment';
			})
		})]
	});
});