define('knowme-client/models/account-setting', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr', 'knowme-client/mixins/models/original-data', 'knowme-client/mixins/models/validations/account-setting'], function (exports, _model, _relationships, _attr, _originalData, _accountSetting) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend(_originalData.default, _accountSetting.default, {
		account: (0, _relationships.belongsTo)('account', { async: true, inverse: 'settings' }),
		key: (0, _attr.default)(),
		value: (0, _attr.default)('number')
	});
});