define('knowme-client/mixins/models/validations/organization', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      dependentKeys: ['lead.leadType']
    }), (0, _emberCpValidations.validator)('length', { max: 100, allowBlank: true })]
  });
});