define('knowme-client/components/promo-codes/form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'model.hasDirtyAttributes', function () {
      return this.get('model.validations.isInvalid') || !this.get('model.hasDirtyAttributes');
    }),
    actions: {
      submit: function () {
        this.get('model').save().then(function () {
          this.get('router').transitionTo('promo-codes');
        }.bind(this));
      }
    }
  });
});