define('knowme-client/models/form-touch-point', ['exports', 'ember-data/model', 'ember-data/attr', 'knowme-client/mixins/models/validations/form-touch-point', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'ember-data/relationships', 'knowme-client/config/environment'], function (exports, _model, _attr, _formTouchPoint, _emberCpValidationsCustomProperties, _dirty, _relationships, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { environment } = _environment.default;

  exports.default = _model.default.extend(_formTouchPoint.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    session: Ember.inject.service(),
    ad: (0, _relationships.belongsTo)('ad', { async: true }),
    status: (0, _attr.default)(),
    location: (0, _attr.default)(),
    email: (0, _attr.default)({ defaultValue: '. . .@leads.knowmeiq.com' }),
    isActive: Ember.computed.equal('status', 'active'),
    isInactive: Ember.computed.equal('status', 'inactive'),
    onLocationChange: Ember.observer('location', function () {
      let session = this.get('session');
      let suffix = environment === 'production' ? '' : `-${environment}`;

      this.set('email', `${session.get('currentUser.account.nameOrContactName').replace(/\./g, '').dasherize()}-${this.get('location').dasherize()}${suffix}@leads.knowmeiq.com`);
    })
  });
});