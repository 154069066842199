define('knowme-client/adapters/custom-attribute', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let AdAdapter;

  AdAdapter = _application.default.extend({
    //
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,paginate,opportunity_id}',
    pathForType: function () /*type*/{
      return 'announcements';
    },
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      opportunity_id: function (type, id, snapshot, query = {}) {
        return query.opportunity_id;
      },
      paginate: function (type, id, snapshot, query) {
        if (query && Ember.isNone(query.paginate)) {
          return true;
        }
      }
    }
  });

  exports.default = AdAdapter;
});