define('knowme-client/mixins/models/validations/contact-email', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = (0, _emberCpValidations.buildValidations)({
		email: [(0, _emberCpValidations.validator)('presence', {
			presence: true,
			ignoreBlank: true,
			dependentKeys: ['model.isDeleted'],
			disabled: Ember.computed.bool('model.isDeleted')
		}), (0, _emberCpValidations.validator)('format', { type: 'email', regex: /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i, allowBlank: true })]
	});
});