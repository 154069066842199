define('knowme-client/mirage/models/account', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		subscription: (0, _emberCliMirage.belongsTo)(),
		users: (0, _emberCliMirage.hasMany)(),
		accountSettings: (0, _emberCliMirage.hasMany)(),
		defaultCallerIdsAd: (0, _emberCliMirage.belongsTo)('ad'),
		defaultManualAd: (0, _emberCliMirage.belongsTo)('ad'),
		activeSettings: function () {
			return {};
		}
	});
});