define('knowme-client/components/navbar-partial', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    notificationService: Ember.inject.service('notification'),
    notificationPopup: Ember.inject.service('notifications'),
    intl: Ember.inject.service(),
    snd: null,
    activeTab: Ember.computed('target.target.currentPath', 'target.converted', function () {
      let namespace = this.target.target.currentPath.split('.')[0];
      let tab = namespace;
      if (namespace === 'leads' && this.target.get('converted') === '1') {
        tab = 'customers';
      }
      return tab;
    }),
    didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('snd', new Audio("/plucky.mp3"));
      this.get('notificationService').setContext(this);
    },
    onReceiveNotification(notification) {
      let self = this;

      notification.get('notifiable').then(function (notifiable) {
        let message = self.get('intl').t('notification.' + notification.get('action'), {
          opportunityName: notifiable.get('name')
        });

        self.get('notificationPopup').info(message, {
          // htmlContent: true,
          autoClear: true,
          clearDuration: 5000,
          onClick: () => /*popup*/{
            self.send('closeNotificationAndRedirect', notification);
          }
        });
      });
      if (notification.get('isUnread')) {
        self.get("snd").play();
      }
    },
    actions: {
      startEmailIntegration: function () {
        this.sendAction('startEmailIntegration');
      },
      stopMergingOpportunity: function (opportunityId) {
        this.set('isOpportunityMerging', null);
        this.get('routing').transitionTo('leads.show.opportunities.show', opportunityId);
      },
      toggleMainNav: function () /*e*/{},
      stop_impersonate: function () {
        this.get('session').set('impersonatedUser', null);
        this.get('router').transitionTo('account.clients');
      },
      back: function () {
        history.back();
      },
      openNotificationList: function () {
        let controller = this;
        Ember.run.later(function () {
          controller.get('notificatioScroller').nanoScroller();
        }, 200);
      },
      closeNotificationAndRedirect: function (notification) {
        this.send('closeNotification', notification);
        switch (notification.get('action')) {
          case 'assigned':
            let opportunity = notification.get('notifiable');
            this.get('routing').transitionTo('leads.show.opportunities.show', [opportunity.get('lead.id'), opportunity.get('id')]);
            break;
          case 'call':
            let callId = notification.get('notifiable').get('resourceId');
            this.get('store').findRecord('call', callId).then(call => {
              let callDate = (0, _moment.default)(call.get('callDate'));
              let params = {
                type: 'inbound',
                from_date: callDate.format('MM-DD-YYYY'),
                to_date: callDate.format('MM-DD-YYYY'),
                resource_id: call.get('contact.id')
              };
              localStorage.setItem('callId', callId);
              this.get('routing').transitionTo('calls', [], { queryParams: params });
            });
            break;
          case 'short_message':
            let textId = notification.get('notifiable').get('resourceId');
            this.get('store').findRecord('short-message', textId).then(function (message) {
              let params = {
                from_date: null,
                to_date: null,
                satisfaction: null,
                opportunityId: message.get('opportunity.id'),
                contact_id: message.get('contact.id')
              };
              localStorage.setItem('textId', textId);
              this.get('routing').transitionTo('short-messages', [], { queryParams: params });
            }.bind(this));
            break;
          case 'email':
            let emailId = notification.get('notifiable').get('resourceId');
            this.get('store').findRecord('message', emailId).then(function (message) {
              let params = {
                from_date: null,
                to_date: null
              };
              if (message.get('emailsCount') > 1) {
                params.contactId = message.get('contact.id');
                params.opportunityId = message.get('opportunity.id');
              }
              localStorage.setItem('emailId', emailId);
              this.get('routing').transitionTo('emails', [], { queryParams: params });
            }.bind(this));
            break;
        }
      },
      closeNotification: function (notification, event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        notification.set('readAt', (0, _moment.default)());
        notification.save();
      },
      logout: function () {
        return this.get('session').invalidate();
      }
    }
  });
});