define('knowme-client/serializers/campaign', ['exports', 'knowme-client/serializers/json-api', 'knowme-client/serializers/application'], function (exports, _jsonApi, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      ads: { embedded: 'always' }
    },
    normalize(typeClass, hash /*, prop*/) {
      hash.links = {
        ads: Ember.getOwner(this).lookup('adapter:ad').buildURL('ad', null, null, 'findMany', { campaign_id: hash.id, paginate: false })
      };
      return this._super.apply(this, arguments);
    },
    serialize: function () /*snapshot, options*/{
      var json = this._super(...arguments);
      //delete json;
      return json;
    }
  });
});