define('knowme-client/helpers/uid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.uid = uid;
  function uid() {
    return Math.random() * 1000 | 0;
  }

  exports.default = Ember.Helper.helper(uid);
});