define('knowme-client/components/contact-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    open: false,
    tagName: '',
    satisfiedScores: ['satisfied', 'very_satisfied'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    features: Ember.inject.service(),
    lastExperience: null,
    isRendering: false,
    sentAndClaimedContactsIncentives: Ember.computed.filter('sentIncentivesContacts', function (resource) {
      if (resource.get('incentivesContactTarget.id') !== this.get('contactTarget.id') || resource.get('incentivesContactTargetType') !== this.get('contactTarget').constructor.modelName.camelize().capitalize()) {
        return resource.get('claimed');
      } else {
        return true;
      }
    }),
    uniqSentAndClaimedContactsIncentives: Ember.computed.uniqBy('sentAndClaimedContactsIncentives', 'incentive.id'),
    unclaimedIncentivesContacts: Ember.computed.filter('sentIncentivesContacts', function (resource) {
      return (resource.get('incentivesContactTarget.id') !== this.get('contactTarget.id') || resource.get('incentivesContactTargetType') !== this.get('contactTarget').constructor.modelName.camelize().capitalize()) && !resource.get('claimed');
    }).property('contactTarget'),
    sentAndClaimedIncentives: Ember.computed.mapBy('sentAndClaimedContactsIncentives', 'incentive'),
    availableIncentives: Ember.computed.filter('incentives', function (resource) {
      return !this.get('sentAndClaimedIncentives').mapBy('id').includes(resource.get('id'));
    }).property('sentAndClaimedIncentives'),
    sentReviewLinks: Ember.computed.mapBy('sentReviewLinksContacts', 'reviewLink'),
    availableReviewsLinks: Ember.computed.filter('reviewLinks', function (resource) {
      return !this.get('sentReviewLinks').mapBy('id').includes(resource.get('id'));
    }).property('sentReviewLinks'),
    uniqSentReviewLinksContacts: Ember.computed.uniqBy('sentReviewLinksContacts', 'reviewLink.id'),
    hasNoPositiveScore: Ember.computed('lastExperience.satisfaction', function () {
      return Ember.isBlank(this.get('lastExperience')) || !this.get('satisfiedScores').includes(this.get('lastExperience.satisfaction'));
    }),
    incentivesAvailable: Ember.computed('contactTarget.email', 'features.isBasicCrmOrKnowmeIQStarter', function () {
      return Ember.isPresent(this.get('contactTarget.email')) && !this.get('features.isBasicCrmOrKnowmeIQStarter');
    }),
    reviewLinksAvailable: Ember.computed('features.isBasicCrmOrKnowmeIQStarter', function () {
      return !this.get('features.isBasicCrmOrKnowmeIQStarter');
    }),
    willDestroyElement: function () {
      console.log(123);
    },
    actions: {
      fixNanoScroll: function () {
        Ember.$(window).trigger('resize');
      },
      hideSection: function () {
        this.set('open', false);
      },
      showSection: function () {
        this.set('open', true);
      },
      sendSurvey: function (contactTarget, lastExperience) {
        let targetType = contactTarget.constructor.modelName.camelize().capitalize();
        if (targetType === 'ContactEmail') {
          this.send('sendSurveyEmail', contactTarget, lastExperience);
        } else {
          this.send('sendSurveyShortMessage', contactTarget, lastExperience);
        }
      },
      sendSurveyEmail: function (contactEmail, lastExperience) {
        contactEmail.get('surveys').then(function (surveys) {
          let survey = surveys.findBy('opportunity.id', this.get('opportunity.id'));

          if (Ember.isBlank(survey)) {
            survey = this.get('store').createRecord('survey', { opportunity: this.get('opportunity'), contactEmail: contactEmail });
          }
          survey.set('status', 'new');
          this.set('isSendingSurvey', true);
          survey.save().then(function () {
            if (Ember.isPresent(lastExperience)) {
              lastExperience.reload();
            }
            this.set('isSendingSurvey', false);
            this.send('hideSection');
            this.reRenderContactActions();
          }.bind(this));
        }.bind(this));
      },
      sendSurveyShortMessage: function (contactPhoneNumber, lastExperience) {
        this.set('shortMessage', this.get('store').createRecord('shortMessage'));
        this.get('shortMessage').setProperties({ body: 'send-survey', contactPhoneNumber: contactPhoneNumber, opportunity: this.get('opportunity') });
        this.set('isSendingSurvey', true);
        this.get('shortMessage').save().then(function () {
          this.set('isSendingSurvey', false);
          if (Ember.isPresent(this.get('opportunity.lead').then)) {
            this.get('opportunity.lead').then(function (lead) {
              lead.reload();
            });
          } else {
            this.get('opportunity.lead').reload();
          }
          this.reRenderContactActions();
          Ember.run(function () {
            this.send('hideSection');
            this.set('isSendingSurvey', false);
            this.get('opportunity').reload();
            this.get('opportunity.activities').reload();
            contactPhoneNumber.reload();
            if (Ember.isPresent(lastExperience)) {
              lastExperience.reload();
            }
          }.bind(this));
        }.bind(this), function (response) {
          this.set('isSendingSurvey', false);
          this.get('flashMessages').warning(response.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
        }.bind(this));
      },
      sendIncentive: function (incentive, incentivesContactTarget) {
        this.get('store').query('incentives-contact', {
          incentive_id: this.get('selectedIncentive.id'),
          target_id: incentivesContactTarget.get('id'),
          target_type: incentivesContactTarget.constructor.modelName.camelize().capitalize(),
          opportunity_id: this.get('opportunity.id')
        }).then(function (incentivesContact) {
          if (Ember.isBlank(incentivesContact)) {
            incentivesContact = this.get('store').createRecord('incentives-contact', {
              incentivesContactTarget: incentivesContactTarget,
              contact: this.get('contact'),
              incentive: incentive,
              opportunity: this.get('opportunity')
            });
          }
          this.set('isSendingIncentive', true);
          incentivesContact.save().then(function (ic) {
            this.set('isSendingIncentive', false);
            incentivesContactTarget.hasMany('incentivesContacts').reload();
            this.send('hideSection');
            this.reRenderContactActions();
            this.get('flashMessages').info(`Incentive is sent to ${this.get('contact').get('fullNameOrPhoneNumber')}`, { destroyOnClick: true, timeout: 12000 });
            ic.get('opportunity');
          }.bind(this), function () {
            this.set('isSendingIncentive', false);
          }.bind(this));
        }.bind(this));
      },
      sendReviewLink: function (reviewLink, reviewLinksContactTarget) {
        this.get('store').query('review-links-contact', {
          review_link_id: this.get('selectedReviewLink.id'),
          target_id: reviewLinksContactTarget.get('id'),
          target_type: reviewLinksContactTarget.constructor.modelName.camelize().capitalize(),
          opportunity_id: this.get('opportunity.id')
        }).then(function (reviewLinksContact) {
          if (Ember.isBlank(reviewLinksContact)) {
            reviewLinksContact = this.get('store').createRecord('review-links-contact', {
              reviewLinksContactTarget: reviewLinksContactTarget,
              contact: this.get('contact'),
              reviewLink: reviewLink,
              opportunity: this.get('opportunity')
            });
          }
          this.set('isSendingReviewLink', true);
          reviewLinksContact.save().then(function (rlc) {
            this.set('isSendingReviewLink', false);
            reviewLinksContactTarget.hasMany('reviewLinksContacts').reload();
            this.send('hideSection');
            this.reRenderContactActions();
            this.get('flashMessages').info(`Review is sent to ${this.get('contact').get('fullNameOrPhoneNumber')}`, { destroyOnClick: true, timeout: 12000 });
            rlc.get('opportunity');
          }.bind(this), function () {
            this.set('isSendingReviewLink', false);
          }.bind(this));
        }.bind(this));
      }
    }
  });
});