define('knowme-client/controllers/signup', ['exports', 'knowme-client/mixins/us-states', 'knowme-client/mixins/timezones', 'knowme-client/mixins/industries', 'knowme-client/mixins/credit-card-fields'], function (exports, _usStates, _timezones, _industries, _creditCardFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SignupController;

  SignupController = Ember.Controller.extend(_usStates.default, _timezones.default, _industries.default, _creditCardFields.default, {
    stripe: Ember.inject.service('stripe'),
    flashMessages: Ember.inject.service(),
    step: 1,
    interval: 'annually',
    currentIndustry: null,
    states: [{ id: '', name: 'Select Country' }],
    statePromt: 'State',
    codeLabel: 'Zip',
    isSaving: false,
    addressState: null,
    accountSettings: ['account.billing_term', 'additional.users', 'additional.users.dids.local', 'additional.minutes', 'additional.short_messages', 'additional.storage', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local', 'additional.contacts'],
    init() {
      this._super(...arguments);
      this.setStatesList();
      let _this = this;
      this.accountSettings.without('account.billing_term').forEach(function (key) {
        let settingKey = key;
        let _key = key.camelize().capitalize();
        Ember.defineProperty(_this, `settings${_key}Cost`, Ember.computed('plan.key', 'interval', 'promoCode.percentOff', `settings${_key}.value`, () => {
          if (_this.get('isCurrentProductBasicCRM') && ['additional.users.dids.local', 'additional.minutes', 'additional.short_messages', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local'].includes(settingKey)) {
            return 0;
          }
          var blockUnit = 1,
              unitPrice;
          if (Ember.isPresent(_this.get('planSettings')[_key.camelize() + 'Block'])) {
            blockUnit = _this.get('planSettings')[_key.camelize() + 'Block'];
            unitPrice = _this.get('planSettings')[_key.camelize() + 'BlockFee'];
          } else {
            if (['additional.users.dids.local', 'additional.call_touch_points.dids.toll_free', 'additional.call_touch_points.dids.local'].includes(settingKey)) {
              unitPrice = _this.get('planSettings')['additionalDidsFee'] || 0;
            } else {
              unitPrice = _this.get('planSettings')[_key.camelize() + 'Fee'];
            }
          }
          return parseInt(_this.get(`settings${_key}.value`), 10) / blockUnit * unitPrice;
        }));
      });
      let settingsDependendProperties = this.accountSettings.map(function (accountSetting) {
        return `settings${accountSetting.camelize().capitalize()}.value`;
      });
      let computedDependentProperties = settingsDependendProperties.concat(['plan.key', 'planSettings.planFee', 'interval', 'promoCode.percentOff']);
      Ember.defineProperty(_this, 'subscriptionFee', Ember.computed(...computedDependentProperties, function () {
        let settingsCosts = this.accountSettings.without('account.billing_term').reduce(function (previousValue, item) {
          let _key = item.camelize().capitalize();
          if (!isNaN(this.get(`settings${_key}Cost`))) {
            return previousValue + this.get(`settings${_key}Cost`);
          } else {
            return previousValue;
          }
        }.bind(this), 0);

        if (Ember.isPresent(this.get('promoCode'))) {
          return this.get('planSettings').planFee - this.get('planSettings').planFee * this.get('promoCode.percentOff') / 100 + settingsCosts;
        } else {
          return this.get('planSettings').planFee + settingsCosts;
        }
      }));
    },
    totalUsers: Ember.computed('settingsAdditionalUsers.value', 'planSettings.includedUsers', function () {
      if (this.get('planSettings.includedUsers') === 'inf') {
        return 'Unlimited';
      } else {
        return this.get('settingsAdditionalUsers.value') + this.get('planSettings.includedUsers');
      }
    }),
    totalUsersDidsLocal: Ember.computed('planSettings.includedUsersDidsLocalPerUser', 'planSettings.includedUsersDidsLocal.value', 'settingsAdditionalUsersDidsLocal.value', 'totalUsers', function () {
      if (this.get('planSettings.includedUsersDidsLocalPerUser')) {
        if (this.get('planSettings.includedUsersDidsLocal') === 'inf') {
          return '1 Knowme IQ number per user';
        } else {
          return `${this.get('totalUsers') * this.get('planSettings.includedUsersDidsLocal') + this.get('settingsAdditionalUsersDidsLocal.value')} Knowme IQ Numbers`;
        }
      } else {
        if (this.get('planSettings.includedUsersDidsLocal') === 'inf') {
          return '1 Knowme IQ number per user';
        } else {
          return `${this.get('planSettings.includedUsersDidsLocal') + this.get('settingsAdditionalUsersDidsLocal.value')} Knowme IQ Numbers`;
        }
      }
    }),
    totalCallTouchPointsDidsTollFree: Ember.computed('planSettings.includedCallTouchPointsDidsTollFree', 'settingsAdditionalCallTouchPointsDidsTollFree.value', function () {
      return this.get('planSettings.includedCallTouchPointsDidsTollFree') + this.get('settingsAdditionalCallTouchPointsDidsTollFree.value');
    }),
    totalCallTouchPointsDidsLocal: Ember.computed('planSettings.includedCallTouchPointsDidsLocalPerUser', 'planSettings.includedCallTouchPointsDidsLocal.value', 'settingsAdditionalCallTouchPointsDidsLocal.value', 'totalUsers', function () {
      if (this.get('planSettings.includedCallTouchPointsDidsLocalPerUser')) {
        return this.get('totalUsers') * this.get('planSettings.includedCallTouchPointsDidsLocal') + this.get('settingsAdditionalCallTouchPointsDidsLocal.value');
      } else {
        return this.get('planSettings.includedCallTouchPointsDidsLocal') + this.get('settingsAdditionalCallTouchPointsDidsLocal.value');
      }
    }),
    totalMinutes: Ember.computed('planSettings.includedMinutesPerUser', 'planSettings.additionalMinutesBlock', 'planSettings.includedMinutes.value', 'settingsAdditionalMinutes.value', 'totalUsers', function () {
      if (this.get('planSettings.includedMinutesPerUser')) {
        return this.get('totalUsers') * this.get('planSettings.includedMinutes') + this.get('settingsAdditionalMinutes.value');
      } else {
        return this.get('planSettings.includedMinutes') + this.get('settingsAdditionalMinutes.value');
      }
    }),
    totalShortMessages: Ember.computed('planSettings.includedShortMessagesPerUser', 'planSettings.includedShortMessages.value', 'settingsAdditionalShortMessages.value', 'totalUsers', function () {
      if (this.get('planSettings.includedShortMessagesPerUser')) {
        return this.get('totalUsers') * this.get('planSettings.includedShortMessages') + this.get('settingsAdditionalShortMessages.value');
      } else {
        return this.get('planSettings.includedShortMessages') + this.get('settingsAdditionalShortMessages.value');
      }
    }),
    totalStorage: Ember.computed('planSettings.includedStoragePerUser', 'planSettings.includedStorage.value', 'settingsAdditionalStorage.value', 'totalUsers', function () {
      if (this.get('planSettings.includedStoragePerUser')) {
        return this.get('totalUsers') * this.get('planSettings.includedStorage') + this.get('settingsAdditionalStorage.value');
      } else {
        return this.get('planSettings.includedStorage') + this.get('settingsAdditionalStorage.value');
      }
    }),
    totalContacts: Ember.computed('planSettings.includedContactsPerUser', 'planSettings.includedContacts.value', 'settingsAdditionalContacts.value', 'totalUsers', function () {
      if (this.get('planSettings.includedContactsPerUser')) {
        return this.get('totalUsers') * this.get('planSettings.includedContacts') + this.get('settingsAdditionalContacts.value');
      } else {
        return this.get('planSettings.includedContacts') + this.get('settingsAdditionalContacts.value');
      }
    }),
    isCurrentProductKnowmeIQ: Ember.computed.alias('plan.isKnowmeIQ'),
    isCurrentProductBasicCRM: Ember.computed.alias('plan.isBasicCrm'),
    isCurrentProductKnowmeIQStarted: Ember.computed('plan.planKey', 'isCurrentProductKnowmeIQ', function () {
      return this.get('isCurrentProductKnowmeIQ') && (this.get('plan.planKey') === 'starter' || this.get('plan.planKey') === 'starter_unlimited');
    }),
    isFreeAccount: Ember.computed.equal('subscriptionFee', 0),
    isCreaditCardRequired: Ember.computed.not('isFreeAccount'),
    onCountrySelect: Ember.observer('model.addressCountry', function () {
      this.set('model.addressState', null);
      this.setStatesList();
    }),
    stripeLoad: function () {
      this.get('stripe').load();
    },
    setStatesList: function () {
      if (Ember.isPresent(this.get('model.addressCountry'))) {
        if (this.get('model.addressCountry') === 'us') {
          this.set('states', this.get('usStates'));
          this.set('statePromt', 'State');
          this.set('codeLabel', 'Zip');
        } else {
          this.set('states', this.get('canadianProvinces'));
          this.set('statePromt', 'Province');
          this.set('codeLabel', 'Postal');
        }
      } else {
        this.set('states', [{ id: '', name: 'Select Country' }]);
      }
    },
    planSettings: Ember.computed('plan.key', 'interval', 'promoCode.percentOff', function () {
      return (this.get('plan.settings') || {})[this.get('interval')];
    }),
    annualFee: Ember.computed('subscriptionFee', function () {
      return this.get('subscriptionFee') * 12;
    }),
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.setting.hasDirtyAttributes', 'model.subscription.planHasChanged', 'model.subscription.hasDirtyAttributes', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.setting.hasDirtyAttributes') || this.get('model.subscription.planHasChanged') || this.get('model.subscription.hasDirtyAttributes');
    }),
    disableIAgree: Ember.computed('model', 'model.validations.isValid', 'model.subscription.validations.isValid', 'isModelTreeDirty', function () {
      return this.get('isSaving') || this.get('model.validations.isInvalid') || !this.get('isModelTreeDirty');
    }),
    disableSignUp: Ember.computed('disableIAgree', 'model.creditCard', 'model.creditCard.validations.isInvalid', function () {
      return this.get('disableIAgree') || this.get('model.creditCard.validations.isInvalid');
    }),
    actions: {
      back: function () {
        this.set('model.termsOfService', false);
        this.set('step', 1);
      },
      setState: function (addressState) {
        this.set('addressState', addressState);
        this.set('model.addressState', addressState.id);
      },
      submit: function () {
        let controller = this;
        let resource = controller.get('model');

        if (controller.get('step') === 1 && !this.get('isFreeAccount')) {
          const adapter = controller.store.adapterFor('account');
          adapter.validate(resource).then(function () {
            controller.set('step', 2);
            window.scrollTo(0, 0);
            if (Ember.isBlank(controller.get('model.creditCard'))) {
              let creditCard = controller.store.createRecord('credit-card');
              controller.set('model.creditCard', creditCard);
            }
          }).catch(function (response) {
            let messages = [];
            response.errors.forEach(function (error) {
              messages.push(`${error.source.pointer.split('/')[2].replace(/_/g, ' ').capitalize()} ${error.detail[0]}`);
            });
            controller.get('flashMessages').warning(messages.join('. '), { destroyOnClick: true, timeout: 12000 });
          });
        } else {
          controller.set('isSaving', true);
          window.scrollTo(0, 0);
          if (this.get('isCreaditCardRequired')) {
            controller.get('stripe').card.createToken({
              number: controller.get('model.creditCard.number'),
              cvc: controller.get('model.creditCard.code'),
              exp_month: controller.get('model.creditCard.month'),
              exp_year: controller.get('model.creditCard.year')
            }).then(function (response) {
              resource.set('stripeToken', response.id);
              return resource.save();
            }).then(function () {
              controller.set('isSaving', false);
              controller.get('model.settings').filterBy('isNew', true).invoke('deleteRecord');
              controller.transitionToRoute('congratulations');
            }).catch(function (response) {
              // if there was an error retrieving the token you could get it here
              controller.set('isSaving', false);
              if (response.error && response.error.type === 'card_error') {
                // show the error in the form or something
                controller.get('flashMessages').warning(response.error.message, { destroyOnClick: true });
              } else {
                let messages = [];
                response.errors.forEach(function (error) {
                  let field = error.source.pointer.split('/')[2];
                  if (field === 'credit_card') {
                    messages.push(error.detail[0]);
                  } else {
                    messages.push(`${field.replace(/_/g, ' ').capitalize()} ${error.detail[0]}`);
                  }
                });
                controller.get('flashMessages').warning(messages.join('. '), { destroyOnClick: true, timeout: 12000 });
                // controller.send('back');
              }
            });
          } else {
            resource.save().then(function () {
              controller.set('isSaving', false);
              controller.transitionToRoute('congratulations');
            }).catch(function (response) {
              controller.set('isSaving', false);
              let messages = [];
              response.errors.forEach(function (error) {
                messages.push(`${error.source.pointer.split('/')[2].replace(/_/g, ' ').capitalize()} ${error.detail[0]}`);
              });
              controller.get('flashMessages').warning(messages.join('. '), { destroyOnClick: true, timeout: 12000 });
            });
          }
        }
      }
    }
  });

  exports.default = SignupController;
});