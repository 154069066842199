define('knowme-client/models/call-listener', ['exports', 'ember-data/relationships', 'ember-data/model'], function (exports, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    //relationships
    call: (0, _relationships.belongsTo)('call', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true })
  });
});