define('knowme-client/routes/account/settings/reviews/edit', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      return this.get('store').find('review-link', params.id);
    },
    actions: {
      willTransition: function () /*transition*/{
        this.controller.get('model').rollbackAttributes();
      }
    }
  });
});