define('knowme-client/components/file-upload', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    change: function (event) {
      this._super(...arguments);
      return this.set('files', event.target.files);
    }
  });
});