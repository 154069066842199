define('knowme-client/routes/account/index', ['exports', 'knowme-client/mixins/routes/account-setup'], function (exports, _accountSetup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    beforeModel: function (transition) {
      return this._super.apply(this, arguments).then(function () {
        let account = this.get('session.currentUser.account');
        if (Ember.isPresent(account.get('agreementAcceptedAt')) && !account.get('customerTypeConfirmed')) {
          transition.abort();
          this.get('flashMessages').info("You need to confirm your information", { destroyOnClick: true });
          return this.transitionTo('wizard.client').then(Ember.RSVP.Promise.reject);
        }
        if (!this.get('features.clientInformationSectionAvailable')) {
          transition.abort();
          this.get('flashMessages').warning('Access Unauthorized', { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo('dashboard');
        }

        if (transition.queryParams.payouts_enabled) {
          // when user completed stripe steps
          let stripeAccount = this.get('store').createRecord('stripe-account');
          stripeAccount.set('status', 'active');
          return stripeAccount.save().then(function () {
            transition.abort();
            return this.transitionTo('account.index', { queryParams: { payouts_enabled: null } });
          }.bind(this));
        }
      }.bind(this));
    },
    model: function () {
      return this.get('session.currentUser').then(function (currentUser) {
        return currentUser.get('account');
      }).then(function (account) {
        return account.reload();
      }).then(function (account) {
        if (!account.get('customerTypeConfirmed')) {
          account.set('customerType', null);
        }
        if (account.get('subscription.status') === 'error') {
          let creditCard = this.get('store').createRecord('credit-card');
          account.set('creditCard', creditCard);
        }
        return account;
      }.bind(this));
    },
    afterModel: function (model) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');

      let contractVersions = this.get('store').query('contract-version', { per_page: 1, order: { created_at: 'desc' } });

      let promises = {
        contractVersions: contractVersions,
        roles: this.get('store').query('role', { paginate: false, sort_by: 'name', order: 'asc' }),
        dashboardPermissions: model.get('dashboardPermissions'),
        dashboardWidgets: this.get('store').query('dashboard-widget', { filter: 'all' }),
        stripeAccount: this.get('store').queryRecord('stripe-account', {})
      };

      if (!this.acceptAgreementIsRequired) {
        let accountSummaryUrl = adapter.buildURL('accounts/summary');
        let accountSummaryRequest = adapter.ajax(accountSummaryUrl, 'GET');
        let accountSummary = new Ember.RSVP.Promise(function (resolve) {
          accountSummaryRequest.then(function (response) {
            let serializer = this.get("store").serializerFor('json-api');
            serializer.camelizeKeys(response);
            resolve(response);
          }.bind(this));
        }.bind(this));
        promises['accountSummary'] = accountSummary;
      }

      return Ember.RSVP.hash(promises).then(function (results) {
        this.setProperties(results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments); // Call _super for default behavior
      controller.stripeLoad();
      controller.set('contractVersions', this.get('contractVersions'));
      controller.set('accountSummary', this.get('accountSummary'));
      controller.set('roles', this.get('roles'));
      controller.set('dashboardPermissions', this.get('dashboardPermissions'));
      controller.set('dashboardWidgets', this.get('dashboardWidgets'));
      controller.set('stripeAccount', this.get('stripeAccount'));

      if (Ember.isPresent(this.get('stripeAccount')) && !this.get('stripeAccount.payoutsEnabled')) {
        controller.set('isPaymentLinkSettingsOpened', true);
        this.get('flashMessages').warning('Payment Integration is not completed', { destroyOnClick: true, timeout: 12000 });
      }
      controller.set('paidInvoices', this.get('paidInvoices'));

      this.set('contractVersions', {});
      this.set('accountSummary', {});
      this.set('roles', []);
      this.set('dashboardWidgets', []);
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.get('model').rollbackAttributes();
        controller.get('model.subscription').then(function (subscription) {
          subscription.rollbackAttributes();
        });
      }
    },
    actions: {
      remove: function (user) {
        user.delete().then(() => {
          this.transitionTo('account.users.index');
        }).catch(() => {
          alert("couldn't delete user.");
        });
      },
      willTransition: function () /*transition*/{
        this.controller.get('model').rollbackAttributes();
      }
    }
  });
});