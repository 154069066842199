define('knowme-client/helpers/format-subscription-fee', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatSubscriptionFee = formatSubscriptionFee;
  function formatSubscriptionFee(params /*, hash*/) {
    let [details, interval, totalFee] = params;
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    let value = 0;
    if (Ember.isBlank(details)) {
      value = totalFee;
    } else {
      value = details.total * 1;
      if (details['percent-off']) {
        value = details['plan-fee'] - details['plan-fee'] * details['percent-off'] / 100;
        value += details['additional-total'] * 1;
      }
    }

    if (interval === 'annually') {
      return formatter.format(value * 12) + "/Year (" + formatter.format(value) + "/Month billed annually)";
    } else {
      return formatter.format(value) + "/Month";
    }
  }

  exports.default = Ember.Helper.helper(formatSubscriptionFee);
});