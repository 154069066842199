define('knowme-client/mixins/controllers/product-types', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: ['kind'],
    kind: null,
    typeOptions: [{ id: 'product', name: 'Product' }, { id: 'service', name: 'Service' }, { id: 'bundle', name: 'Bundle' }]
  });
});