define('knowme-client/mirage/models/contact-info', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		contact: (0, _emberCliMirage.belongsTo)(),
		contactPhoneNumbers: (0, _emberCliMirage.hasMany)(),
		contactEmails: (0, _emberCliMirage.hasMany)(),
		contactAddresses: (0, _emberCliMirage.hasMany)(),
		contactUrls: (0, _emberCliMirage.hasMany)()
	});
});