define('knowme-client/components/dashboard/customers-widget', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		accountService: Ember.inject.service('account'),
		session: Ember.inject.service(),
		fromDate: Ember.computed(function () {
			return (0, _moment.default)().format('MM-DD-YYYY');
		}),
		toDate: Ember.computed(function () {
			return (0, _moment.default)().format('MM-DD-YYYY');
		})
	});
});