define('knowme-client/models/reminder', ['exports', 'ember-data/model', 'ember-data/attr', 'knowme-client/mixins/models/validations/reminder', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'knowme-client/mixins/validate-belongs-to', 'ember-data/relationships'], function (exports, _model, _attr, _reminder, _emberCpValidationsCustomProperties, _dirty, _validateBelongsTo, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_reminder.default, _emberCpValidationsCustomProperties.default, _dirty.default, _validateBelongsTo.default, {
    deliveryType: (0, _attr.default)(),
    reminderType: (0, _attr.default)(),
    timeBefore: (0, _attr.default)('number'),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    appointmentOpportunities: (0, _relationships.hasMany)('opportunity', { async: true }),
    appointmentOpportunitiesCount: Ember.computed.alias('appointmentOpportunities.length'),
    formattedTimeBefore: Ember.computed('timeBefore', function () {
      if (this.get('timeBefore') == 60) {
        return '1 hour';
      } else if (this.get('timeBefore') < 60) {
        return `${this.get('timeBefore')} minutes`;
      } else {
        return `${this.get('timeBefore') / 60} hours`;
      }
    })
  });
});