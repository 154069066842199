define('knowme-client/components/forms/email-templates', ['exports', 'ember-uploader/uploaders', 'knowme-client/mixins/controllers/status'], function (exports, _uploaders, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_status.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    router: Ember.inject.service(),
    currentAuthentication: Ember.computed.alias('session.currentUser.authentications.firstObject'),
    ckeditorConfig: {
      toolbar: {
        items: ['fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'uploadAttach', 'link', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', '|', 'heading']
      }
    },
    paddingBottom: Ember.computed('model.attachments.length', function () {
      if (this.get('model.attachments.length') > 0) {
        return 10 + this.get('model.attachments.length') * 17;
      } else {
        return 0;
      }
    }),
    readOnly: Ember.computed('model', 'currentAuthentication', function () {
      return !this.get('model.isNew') && this.get('model.authentication.id') != this.get('currentAuthentication.id');
    }),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'model.hasDirtyAttributes', function () {
      return this.get('model.validations.isInvalid') || !this.get('model.hasDirtyAttributes');
    }),
    actions: {
      submit: function () {
        let attachmentsPromise = Ember.A([]);
        let controller = this;
        let attachments = this.get("model").get('attachments');
        this.get('model').save().then(function () {
          if (Ember.isPresent(attachments)) {
            attachments.forEach(function (attachment) {
              attachment.set('record', controller.get('model'));
              attachmentsPromise.pushObject(attachment.save());
            });
            return Ember.RSVP.all(attachmentsPromise).then(function (response) {
              console.log('all???');
              controller.get('router').transitionTo('email-templates.index');
              controller.get('router._router._routerMicrolib').refresh();
            });
          } else {
            this.get('router').transitionTo('email-templates.index');
            this.get('router._router._routerMicrolib').refresh();
          }
        }.bind(this));
      }
    }
  });
});