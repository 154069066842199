define('knowme-client/mixins/date-filter', ['exports', 'moment', 'lodash'], function (exports, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let DateFilterMixin;

  function formatDate(date) {
    return date.format('MM-DD-YYYY'); //FIXME: Rails expects DD-MM-YYYY instead of MM-DD-YY
  }

  DateFilterMixin = Ember.Mixin.create({
    queryParams: ['from_date', 'to_date', 'period'], // queryParams that trigger model reload
    from_date: null,
    to_date: null,
    period: null,
    customFromDate: null,
    customToDate: null,
    isWrongPeriod: false,
    dateFilterSelected: null,
    selectedLabel: null,
    dateFilterCustomSelectorDisplayed: false,
    dateFilterPromptText: 'Filter by Date',
    dateFilterOptions: [{ id: 'today', name: 'Today Only' }, { id: 'todayAndYesterday', name: 'Since Yesterday' }, { id: 'todayAndPrevious2Days', name: 'Last 3 Days' }, { id: 'todayAndPrevious6Days', name: 'Last 7 Days' }, { id: 'todayAndPrevious29Days', name: 'Last 30 Days' }, { id: 'previousMonth', name: 'Last Month: ' + (0, _moment.default)().subtract(1, 'months').format('MMMM YYYY') }, { id: 'yearToDate', name: 'Year to Date (YTD)' }, { id: 'previousYear', name: 'Last Year: ' + (0, _moment.default)().subtract(1, 'year').format('YYYY') }],
    customOption: Ember.computed('dateFilterSelected', 'from_date', 'to_date', function () {
      let custom = { id: 'custom', name: 'Custom' };
      let selectedOption = this.get('dateFilterSelected');

      if (Ember.isPresent(this.get('customFromDate')) && Ember.isPresent(this.get('customToDate'))) {
        let from = (0, _moment.default)(this.get('customFromDate'), 'MM-DD-YYYY').format('MM/DD/YY');
        let to = (0, _moment.default)(this.get('customToDate'), 'MM-DD-YYYY').format('MM/DD/YY');
        custom.name = `Custom: ${from} - ${to}`;
        if (selectedOption && selectedOption.id == 'custom') {
          selectedOption.name = `Custom: ${from} - ${to}`;
        }
      }

      return custom;
    }),
    onDatesPresent: Ember.observer('from_date', 'to_date', function () {
      if (this.get('dateFilterCustomSelectorDisplayed')) {
        return;
      }
      let selectedOption = this.get('dateFilterSelected');

      let options = this.get('dateFilterOptions');
      if (Ember.isPresent(this.get('from_date')) || Ember.isPresent(this.get('to_date'))) {
        let today = (0, _moment.default)();
        let fromDate = (0, _moment.default)(this.get('from_date'), "MM-DD-YYYY");
        let toDate = (0, _moment.default)(this.get('to_date'), "MM-DD-YYYY");
        if (Ember.isPresent(this.get('period')) && this.get('period') == 'custom') {
          let from = null;
          let to = null;

          if (Ember.isPresent(this.get('from_date'))) {
            let date = (0, _moment.default)(this.get('from_date'), 'MM-DD-YYYY');
            this.set('customFromDate', date.toDate());
            from = date.format('MM/DD/YY');
          }
          if (Ember.isPresent(this.get('to_date'))) {
            let date = (0, _moment.default)(this.get('to_date'), 'MM-DD-YYYY');
            this.set('customToDate', date.toDate());
            to = date.format('MM/DD/YY');
          }

          return this.set('dateFilterSelected', { id: 'custom', name: `Custom: ${from} - ${to}` });
        }
        let diffDays = toDate.diff(fromDate, 'days');
        let toDateIsToday = today.diff(toDate, 'days') === 0;
        let diffYears = today.diff(fromDate, 'years');
        let dateChanged = false;
        let option = null;
        switch (diffDays) {
          case 0:
            option = _lodash.default.find(options, { id: 'today' });
            break;
          case 1:
            option = _lodash.default.find(options, { id: 'todayAndYesterday' });
            break;
          case 2:
            option = _lodash.default.find(options, { id: 'todayAndPrevious2Days' });
            break;
          case 6:
            option = _lodash.default.find(options, { id: 'todayAndPrevious6Days' });
            break;
          case 29:
            option = _lodash.default.find(options, { id: 'todayAndPrevious29Days' });
            break;
          default:
            dateChanged = true;
            break;
        }
        if (!toDateIsToday && diffDays < 31) {
          option = _lodash.default.find(options, { id: 'previousMonth' });
          dateChanged = false;
        }
        if (toDateIsToday && fromDate.diff((0, _moment.default)().startOf('year')) === 0) {
          option = _lodash.default.find(options, { id: 'yearToDate' });
          dateChanged = false;
        }
        if (today.year() - toDate.year() === 1 && toDate.year() === fromDate.year() && toDate.format('DD-MM') == '31-12' && fromDate.format('DD-MM') == '01-01') {
          option = _lodash.default.find(options, { id: 'previousYear' });
          dateChanged = false;
        }
        if (option && (Ember.isBlank(selectedOption) || option.id != selectedOption.id)) {
          this.set('dateFilterSelected', option);
        }
        if (dateChanged) {
          this.set('dateFilterSelected', { id: 'custom', name: 'Custom' });
        }
      } else {
        this.set('selectedLabel', null);
        this.set('dateFilterSelected', null);
      }
    }),
    dateFilterChanged: Ember.observer('dateFilterSelected', function () {
      if (this.get('page')) {
        this.set('page', null);
      }
    }),
    customFromDateStartDate: Ember.computed('filter', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        val = (0, _moment.default)().format('L');
      }
      if (filter === 'past_due_action') {
        val = null;
      }
      return val;
    }),
    customFromDateEndDate: Ember.computed('filter', 'customToDate', function () {
      let filter = this.get('filter');
      let val = null;
      if (filter === 'action_required') {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = null;
        }
      }
      if (filter === 'past_due_action') {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = (0, _moment.default)().subtract(1, 'day').endOf('day').format('L');
        }
      }
      if (filter === null) {
        if (Ember.isPresent(this.get('customToDate'))) {
          val = (0, _moment.default)(this.get('customToDate')).format('L');
        } else {
          val = (0, _moment.default)().endOf('day').format('L');
        }
      }
      return val;
    }),
    customToDateStartDate: Ember.computed('customFromDate', function () {
      let val;
      if (Ember.isPresent(this.get('customFromDate'))) {
        val = (0, _moment.default)(this.get('customFromDate')).format('L');
      } else {
        val = null;
      }
      return val;
    }),
    customToDateEndDate: Ember.computed('customToDate', function () {
      let val;
      if (Ember.isPresent(this.get('customToDate'))) {
        val = (0, _moment.default)(this.get('customToDate')).format('L');
      } else {
        val = null;
      }
      return val;
    }),
    resetDateFilter: Ember.observer('from_date', 'to_date', function () {
      if (Ember.isEmpty(this.get('from_date')) && Ember.isEmpty(this.get('to_date'))) {
        this.set('selectedLabel', null);
        this.set('dateFilterSelected', null);
      }
    }),
    actions: {
      onSelect: function () {
        // calculate from and to
        var controller = this;
        var filter = controller.get('dateFilterSelected.id');
        this.set('dateFilterCustomSelectorDisplayed', false);

        var options = {
          // Past
          todayAndYesterday: function () {
            var fromDate = (0, _moment.default)().subtract(1, 'day').startOf('day');
            var toDate = (0, _moment.default)();
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          todayAndPrevious2Days: function () {
            var fromDate = (0, _moment.default)().subtract(2, 'day').startOf('day');
            var toDate = (0, _moment.default)();
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          todayAndPrevious6Days: function () {
            var fromDate = (0, _moment.default)().subtract(6, 'day').startOf('day');
            var toDate = (0, _moment.default)();
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          todayAndPrevious29Days: function () {
            var fromDate = (0, _moment.default)().subtract(29, 'day').startOf('day');
            var toDate = (0, _moment.default)();
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          previous3Days: function () {
            var fromDate = (0, _moment.default)().subtract(3, 'day').startOf('day');
            var toDate = (0, _moment.default)().subtract(1, 'day').endOf('day');
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          previousMonth: function () {
            var fromDate = (0, _moment.default)().subtract(1, 'months').startOf('month').startOf('day');
            var toDate = (0, _moment.default)().subtract(1, 'months').endOf('month').endOf('day');
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          yearToDate: function () {
            var fromDate = (0, _moment.default)().startOf('year');
            var toDate = (0, _moment.default)();
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          previousYear: function () {
            var fromDate = (0, _moment.default)().subtract(1, 'year').startOf('year');
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(fromDate.endOf('year')), period: null });
          },
          // Future
          todayAndNext6Days: function () {
            var fromDate = (0, _moment.default)().startOf('day');
            var toDate = (0, _moment.default)().add(6, 'day').endOf('day');
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          todayAndNext29Days: function () {
            var fromDate = (0, _moment.default)().startOf('day');
            var toDate = (0, _moment.default)().add(29, 'day').endOf('day');
            return controller.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: null });
          },
          // Others
          today: function () {
            var today = (0, _moment.default)();
            return controller.setProperties({ from_date: formatDate(today), to_date: formatDate(today), period: null });
          },
          custom: function () {
            // populate datepicker if previous is custom
            if (controller.get('period') && controller.get('period') == 'custom') {
              if (Ember.isPresent(controller.get('from_date'))) {
                let date = (0, _moment.default)(controller.get('from_date'), 'MM-DD-YYYY');
                controller.set('customFromDate', date.toDate());
              }
              if (Ember.isPresent(controller.get('to_date'))) {
                let date = (0, _moment.default)(controller.get('to_date'), 'MM-DD-YYYY');
                controller.set('customToDate', date.toDate());
              }
            }

            return controller.set('dateFilterCustomSelectorDisplayed', !controller.get('dateFilterCustomSelectorDisplayed'));
          }
        };
        // clear custom date fields
        if (filter !== 'custom') {
          controller.set('customFromDate', null);
          controller.set('customToDate', null);
        }
        var fnc = options[filter];
        if (Ember.isPresent(fnc)) {
          return fnc();
        } else {
          return controller.setProperties({ from_date: null, to_date: null, period: null });
        }
      },
      focusOut: function (elm, event) {
        let key = event.target.id;
        let val = event.target.value;
        let date = (0, _moment.default)(val, 'MM/DD/YYYY');
        let previous = this.get(key);

        if (date.isValid() && (!previous || !date.isSame((0, _moment.default)(previous)))) {
          this.set(key, date.toDate());
        }
      },
      setCustomDates: function () {
        var fromDate = this.get('customFromDate');
        if (Ember.isPresent(fromDate)) {
          fromDate = (0, _moment.default)(fromDate);
        }
        var toDate = this.get('customToDate');
        if (Ember.isPresent(toDate)) {
          toDate = (0, _moment.default)(toDate);
        }
        if (Ember.isPresent(fromDate) && Ember.isPresent(toDate) && fromDate.isAfter(toDate)) {
          this.set('isWrongPeriod', true);
        } else {
          this.set('isWrongPeriod', false);
          this.setProperties({ from_date: formatDate(fromDate), to_date: formatDate(toDate), period: 'custom' });
          let selectedOption = this.get('dateFilterSelected');

          if (Ember.isPresent(this.get('customFromDate')) && Ember.isPresent(this.get('customToDate'))) {
            let from = (0, _moment.default)(this.get('customFromDate'), 'MM-DD-YYYY').format('MM/DD/YY');
            let to = (0, _moment.default)(this.get('customToDate'), 'MM-DD-YYYY').format('MM/DD/YY');
            selectedOption.name = `Custom: ${from} - ${to}`;
            this.set('selectedLabel', null);
            this.set('dateFilterSelected', null);
            Ember.run.later(function () {
              this.set('dateFilterSelected', selectedOption);
            }.bind(this), 100);
          }
          return this.set('dateFilterCustomSelectorDisplayed', false);
        }
      },
      closeDateFilterCustomSelector: function () {
        return this.set('dateFilterCustomSelectorDisplayed', false);
      }
    }
  });

  exports.default = DateFilterMixin;
});