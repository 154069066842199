define('knowme-client/adapters/contact', ['exports', 'knowme-client/adapters/application', 'knowme-client/mixins/adapters/api-key-header'], function (exports, _application, _apiKeyHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_apiKeyHeader.default, {
    createRecordUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?lead_id,filter}',
    queryUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,fields[],filter[],user_id,page,sort_by,order,field,from_date,to_date,sales_stage_id}',
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      lead_id: function (type, id, snapshot /*, query*/) {
        return snapshot.belongsTo('lead', { id: true });
      },
      filter: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && Ember.isPresent(query.filter)) {
          return query.filter;
        }
      }
    },
    headers: Ember.computed('apiKey.code', 'session.session.content.authenticated.token', function () {
      if (this.get('apiKey.code') || this.get('session.session.content.authenticated.token')) {
        return {
          'Authorization': this.get('apiKey.code') || this.get('session.session.content.authenticated.token')
        };
      }
    })
    // handleResponse: function(status, headers, payload/*, requestData*/) {
    //   console.log(headers);
    //   if(isBlank(payload.meta)) {
    //     payload.meta = {};
    //   }
    //   payload.meta.callsTotal = parseInt(headers["x-total-calls"], 10);
    //   return this._super(...arguments);
    // }
  });
});