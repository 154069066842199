define('knowme-client/adapters/review', ['exports', 'knowme-client/adapters/application', 'knowme-client/mixins/adapters/api-key-header'], function (exports, _application, _apiKeyHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_apiKeyHeader.default, {
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,paginate}',
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      paginate: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && query.paginate === true) {
          delete query.paginate;
          return true;
        }
      }
    }
  });
});