define('knowme-client/controllers/invoices/index', ['exports', 'knowme-client/mixins/controllers/pagination', 'knowme-client/mixins/controllers/status', 'knowme-client/mixins/controllers/queryable'], function (exports, _pagination, _status, _queryable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, _status.default, _queryable.default, {
    session: Ember.inject.service(),
    queryParams: ['month', 'status'],
    invoiceDetailsModal: false,
    defaultsQueryParams: {
      month: moment().format('Y-MM-DD'),
      status: 'paid'
    },
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    monthOptions: Ember.computed('firstInvoiceDate', function () {
      if (Ember.isPresent(this.get('firstInvoiceDate'))) {
        let months = [];
        let date = moment();
        months.push({ text: date.format('MMM Y'), value: date.format('Y-MM-DD') });
        while (date.format('Y-MM') !== this.get('firstInvoiceDate').format('Y-MM')) {
          date.subtract(1, 'month');
          months.push({ text: date.format('MMM Y'), value: date.format('Y-MM-DD') });
        }
        if (!months.mapBy('value').includes(this.get('month'))) {
          this.set('month', months[0].value);
        }
        return months;
      } else {
        return [{ text: moment().format('MMM Y'), value: moment().format('Y-MM-DD') }];
      }
    }),
    actions: {
      viewInvoiceDetails: function (invoice) {
        this.set('selectedInvoice', invoice);
        this.set('invoiceDetailsModal', true);
      }
    }
  });
});