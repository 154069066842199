define('knowme-client/routes/signup', ['exports', 'knowme-client/mixins/routes/unauthenticated-mixin', 'knowme-client/mixins/products', 'knowme-client/config/environment'], function (exports, _unauthenticatedMixin, _products, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let SignUpRoute;

  SignUpRoute = Ember.Route.extend(_unauthenticatedMixin.default, _products.default, {
    queryParams: {
      product_name: { refreshModel: true },
      plan_name: { refreshModel: true }
    },
    currentProduct: null,
    beforeModel: function (transition) {
      this._super(...arguments);
      let controllers = ['products.index', 'products.show', 'five_products', 'knowme-iq-product'];
      let signupData = null;
      let controllerInstance = null;
      let controllerName = null;

      controllers.every(controller => {
        controllerName = controller;
        controllerInstance = this.controllerFor(controllerName, true);
        if (Ember.isPresent(controllerInstance)) {
          signupData = controllerInstance.get('signupData');
        }
        return Ember.isBlank(signupData);
      });

      if (Ember.isBlank(signupData)) {
        transition.abort();
        this.transitionTo('knowme-iq-product');
      } else {
        this.controllerFor(controllerName).set('signupData', null);
        this.set('signupData', signupData);
        this.set('plan', signupData.plan);
        this.set('currentIndustry', signupData.currentIndustry);
        this.set('interval', signupData.interval);
        this.set('promoCode', signupData.promoCode);
        let planDetails = this.get('products').findBy('key', this.get('plan.productKey'));
        this.set('planDetails', planDetails);
      }
    },
    model: function () {
      let plan = this.get('plan');

      let model = this.store.createRecord('account', {
        billingType: 'automatic'
      });
      if (Ember.isPresent(this.get('currentIndustry'))) {
        model.set('industry', this.get('currentIndustry'));
      }

      let subscription = this.store.createRecord('subscription', { account: model, plan: plan, kind: 'paid', promoCode: this.get('promoCode') });
      return model;
    },
    afterModel: function (model, transition) {
      let _this = this;
      let interval = this.get('signupData.interval');
      let planSettings = this.get('plan').get(`settings.${interval}`);
      _environment.default.APP.SettingsKeys.forEach(key => {
        if (planSettings[key.camelize()] == 'inf' || planSettings[key.camelize() + 'Unlimited']) {
          return;
        }
        if (key === 'additional.users.dids.local' && Ember.isBlank(planSettings['included.users.dids.local'.camelize()])) {
          return;
        }
        if (key === 'account.billing_term') {
          _this.store.createRecord('account-setting', { key: key, value: interval === 'annually' ? 12 : 1, account: model });
        } else {
          if (Ember.isPresent(_this.get(`signupData.settings${key.camelize().capitalize()}.value`))) {
            _this.store.createRecord('account-setting', { key: key, value: _this.get(`signupData.settings${key.camelize().capitalize()}.value`), account: model });
          } else {
            _this.store.createRecord('account-setting', { key: key, value: planSettings[key.camelize()], account: model });
          }
        }
      });

      let promises = {
        contracts: this.get('store').query('contract-version', { per_page: 1, order: { created_at: 'desc' } })
      };
      return Ember.RSVP.hash(promises).then(function (results) {
        this.set('contract', results.contracts.get('lastObject'));
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      controller.setProperties(this.get('signupData'));
      controller.set('plan', this.get('plan'));
      controller.set('currentIndustry', this.get('currentIndustry'));
      controller.set('planDetails', this.get('planDetails'));
      controller.set('contract', this.get('contract'));
      controller.init();
      controller.stripeLoad();
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('addressState', null);
        controller.set('step', 1);
      }
    }
  });

  exports.default = SignUpRoute;
});