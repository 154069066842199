define('knowme-client/authenticators/jwt', ['exports', 'ember-simple-auth-token/authenticators/jwt', 'knowme-client/config/environment'], function (exports, _jwt, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { configuration } = _environment.default.jwt;

  exports.default = _jwt.default.extend({
    identificationAttributeName: 'email',
    init() {
      let adapter = Ember.getOwner(this).lookup('adapter:application');

      this.tokenDataPropertyName = 'token';
      this.serverTokenEndpoint = adapter.buildURL('authentication_tokens');
      this.serverTokenRefreshEndpoint = `${this.get('serverTokenEndpoint')}/refresh`;
      this.tokenPropertyName = configuration.tokenPropertyName;
      this.tokenExpirationInvalidateSession = configuration.tokenExpirationInvalidateSession;
      this.refreshAccessTokens = configuration.refreshAccessTokens;
      this.refreshTokenPropertyName = 'token'; //'refresh_token';
      this.refreshLeeway = configuration.refreshLeeway;
      this.tokenExpireName = configuration.tokenExpireName;
      this.headers = {};
    },
    makeRequest(url, data, headers) {
      let self = this;
      return Ember.$.ajax({
        url: url,
        method: 'POST',
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json',
        headers: this.headers,
        beforeSend: (xhr, settings) => {
          if (this.headers['Accept'] === null || this.headers['Accept'] === undefined) {
            xhr.setRequestHeader('Accept', settings.accepts.json);
          }
          if (self.set('loginController')) {
            self.set('loginController.loginProgress', 10);
          }
          if (headers) {
            Object.keys(headers).forEach(key => {
              xhr.setRequestHeader(key, headers[key]);
            });
          }
        },
        xhr: function () {
          var xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              if (self.set('loginController')) {
                self.set('loginController.loginProgress', percentComplete * 100);
              }
            }
          }, false);

          return xhr;
        }
      });
    },
    authenticate(credentials, headers, loginController) {
      this.set('loginController', loginController);

      return this.makeRequest(this.serverTokenEndpoint, credentials, Ember.assign({}, this.headers, headers)).then(function (response) {
        return this.handleAuthResponse(response);
      }.bind(this));
    },
    handleTokenRefreshFail: function (refreshStatus) {
      if (refreshStatus === 401 || refreshStatus === 403) {
        return this.invalidate().then(() => {
          localStorage.setItem('inactiveLogout', true);
          this.trigger('sessionDataInvalidated');
        });
      }
    }
  });
});