define('knowme-client/mixins/controllers/opportunity-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    prepareCustomAttributes: function () {
      let controller = this;
      controller.get('opportunityFields').forEach(function (field) {
        if (field.get('internal') || !field.get('enable')) {
          return;
        }
        let customAttribute = controller.get('opportunity.customAttributes').findBy('name', field.get('key'));
        if (Ember.isPresent(customAttribute)) {
          customAttribute.setProperties({ 'position': field.get('position'), displayName: field.get('name') });
        } else {
          controller.get('store').createRecord('custom-attribute', {
            opportunity: controller.get('opportunity'),
            name: field.get('key'),
            displayName: field.get('name'),
            value: "",
            position: field.get('position')
          });
        }
      });
    }
  });
});