define('knowme-client/components/reports/leads-table', ['exports', 'lodash', 'moment'], function (exports, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    tagName: '',
    totalOpportunitiesNotConverted: Ember.computed('leads.@each.opportunitiesNotConverted', function () {
      return this.get('leads').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('opportunitiesNotConverted.length');
      }, 0);
    }),
    pipelineTotal: Ember.computed('leads.@each.pipeline', function () {
      return this.get('leads').reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get('pipeline');
      }, 0);
    })
  });
});