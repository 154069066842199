define('knowme-client/controllers/leads/show/history/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let LeadsShowHistoryIndexController;

	LeadsShowHistoryIndexController = Ember.Controller.extend({
		contact: Ember.computed('lead', 'lead.primaryContact', function () {
			return this.get('lead.primaryContact');
		}),
		actions: {
			toggleContactsSelector: function () {
				this.set('displayContactSelector', !this.get('displayContactSelector'));
			},
			selectContact: function (contact) {
				this.set('contact', contact);
			}
		}
	});

	exports.default = LeadsShowHistoryIndexController;
});