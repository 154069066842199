define('knowme-client/components/dashboard/how-to-widget', ['exports', 'knowme-client/mixins/how-to-items'], function (exports, _howToItems) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend(_howToItems.default, {
				router: Ember.inject.service(),
				actions: {
						toggleHowToItem: function (howToItem) {
								if (Ember.isPresent(this.get('howToItem')) && this.get('howToItem') === howToItem) {
										this.set('howToItem', null);
								} else {
										this.set('howToItem', howToItem);
								}
						},
						openDescriptionFor: function (item, subItem, e) {
								e.preventDefault();
								localStorage.setItem('howToItem', item);
								localStorage.setItem('howToSubItem', subItem);
								this.get('router').transitionTo('profile');
						}
				}
		});
});