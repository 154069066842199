define('knowme-client/serializers/event-type', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let EventTypeSerializer;

  EventTypeSerializer = _application.default.extend({
    attrs: {
      campaignEvent: { serialize: false }
    }
  });

  exports.default = EventTypeSerializer;
});