define("knowme-client/helpers/channel-type-class", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var channelTypeClass, ChannelTypeClassHelper;

	exports.channelTypeClass = channelTypeClass = function (params) {
		var channelType = params[0] ? params[0].underscore() : "";
		if (channelType === "") {
			channelType = 'general';
		}
		var classesByChannelType = { direct_mail: 'direct-mail', email: 'email', general: 'general', manual: 'general', mobile: 'mobile', online_digital: 'digital', print: 'print', radio: 'radio', sem: 'adwords', social_media: 'social-media', telemarketing: 'telemarketing', television: 'television' };
		return classesByChannelType[channelType];
	};

	ChannelTypeClassHelper = Ember.Helper.helper(channelTypeClass);

	exports.channelTypeClass = channelTypeClass;
	exports.default = ChannelTypeClassHelper;
});