define('knowme-client/helpers/format-file-storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatFileSize = formatFileSize;
  function formatFileSize(params /*, hash*/) {
    let size = params[0] || 0;
    let unit = params[1] || 'B';
    let units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let index = units.indexOf(unit);
    while (size > 1024) {
      size = size / 1024;
      unit = units[++index];
    }
    size = Math.round(size * 10) / 10;
    return `${size} ${unit}`;
  }

  exports.default = Ember.Helper.helper(formatFileSize);
});