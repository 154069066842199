define('knowme-client/models/report-schedule', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/validations/report-schedule', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty'], function (exports, _model, _attr, _relationships, _reportSchedule, _emberCpValidationsCustomProperties, _dirty) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend(_reportSchedule.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
		user: (0, _relationships.belongsTo)('user', { async: true }),
		campaign: (0, _relationships.belongsTo)('campaign', { async: true }),
		name: (0, _attr.default)(),
		scheduledTime: (0, _attr.default)(),
		timeframe: (0, _attr.default)(),
		recipients: (0, _attr.default)(),
		scheduledOnMonday: (0, _attr.default)('boolean'),
		scheduledOnTuesday: (0, _attr.default)('boolean'),
		scheduledOnWednesday: (0, _attr.default)('boolean'),
		scheduledOnThursday: (0, _attr.default)('boolean'),
		scheduledOnFriday: (0, _attr.default)('boolean'),
		scheduledOnSaturday: (0, _attr.default)('boolean'),
		scheduledOnSunday: (0, _attr.default)('boolean'),
		createdAt: (0, _attr.default)('date'),
		hasScheduledDays: Ember.computed('scheduledOnMonday', 'scheduledOnTuesday', 'scheduledOnWednesday', 'scheduledOnThursday', 'scheduledOnFriday', 'scheduledOnSaturday', 'scheduledOnSunday', function () {
			return this.get('scheduledOnMonday') || this.get('scheduledOnTuesday') || this.get('scheduledOnWednesday') || this.get('scheduledOnThursday') || this.get('scheduledOnFriday') || this.get('scheduledOnSaturday') || this.get('scheduledOnSunday');
		}),
		scheduledDays: Ember.computed('scheduledOnMonday', 'scheduledOnTuesday', 'scheduledOnWednesday', 'scheduledOnThursday', 'scheduledOnFriday', 'scheduledOnSaturday', 'scheduledOnSunday', function () {
			var _scheduledDays = Ember.A([]);
			if (this.get('scheduledOnMonday')) {
				_scheduledDays.pushObject('monday');
			}
			if (this.get('scheduledOnTuesday')) {
				_scheduledDays.pushObject('tuesday');
			}
			if (this.get('scheduledOnWednesday')) {
				_scheduledDays.pushObject('wednesday');
			}
			if (this.get('scheduledOnThursday')) {
				_scheduledDays.pushObject('thursday');
			}
			if (this.get('scheduledOnFriday')) {
				_scheduledDays.pushObject('friday');
			}
			if (this.get('scheduledOnSaturday')) {
				_scheduledDays.pushObject('saturday');
			}
			if (this.get('scheduledOnSunday')) {
				_scheduledDays.pushObject('sunday');
			}
			return _scheduledDays;
		}),
		formattedScheduledDays: Ember.computed('scheduledDays.[]', function () {
			return this.get('scheduledDays').invoke('capitalize').join(", ");
		})
	});
});