define('knowme-client/serializers/campaign-event', ['exports', 'knowme-client/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let CampaignEventSerializer;

  CampaignEventSerializer = _application.default.extend({
    attrs: {
      user: { serialize: false, deserialize: 'records' },
      eventType: { serialize: false, deserialize: 'records' },
      name: 'title'
    }
  });

  exports.default = CampaignEventSerializer;
});